import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useForm, useWatch, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import { Grid, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './styles.scss';
import { HTTP_OK } from 'constants/StatusCode.js';
import YupValidateEditCandidate from './ValidateEditCandidate.js';
import AlertComponent from 'components/Alert/index.js';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import CusromInputNumber from 'components/InputYupAndMui/CusromInputNumber';
import DateDay from 'components/ReactForm/DateDay';
import CustomRadioInput from 'components/InputYupAndMui/CustomRadioInputForm';
import { errorSubmit, checkMaxDateToday, limitLengthText } from 'helpers';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import { apiCreateCandidateCV } from 'api/managerCandidate';
import { USE_GET_DETAIL_CANDIDATE } from 'constants/ManagerCandidate';
import { MAX_LENGTH_25, MAX_LENGTH_50, CODE_DATA_EXISTS, MAX_LENGTH_256, MAX_LENGTH_300, ROWS_TEXT_AREA_6 } from 'constants/index.js';
import styles from './../style.module.scss';
import { alertConfirmDuplicateCandidate } from 'helpers/manageCandidate';
import AddMoreLanguage from '../AddMoreLanguage';

const EditCandidateModal = (props) => {
  const { isShowModal, setShowModal, dataListSchool, uDetailCandidate } = props;

  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(YupValidateEditCandidate()),
    mode: 'onChange',
  });

  const queryClient = useQueryClient();
  const [stateButton, setStateButton] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [stateEditButton, setStateEditButton] = useState(true);
  const [isShowAlertError, setShowAlertError] = useState(false);
  const [message, setMessage] = useState('');
  const [errorDate, setErrorDate] = useState({});
  const [idDetail, setIdDetail] = useState();
  const handleRedirect = useRef({});
  const radioListGender = [
    {
      value: 1,
      labelName: t('managerCandidate.maleGender'),
    },
    {
      value: 2,
      labelName: t('managerCandidate.femaleGender'),
    },
  ];
  const [defaultLanguage] = useState({
    candidate_languages: [{ language_id: undefined, level: '' }],
  });
  const mapLanguage = useRef({});

  const handleSubmitForm = async (dataSubmit) => {
    if (errorDate?.birthday || errorDate?.apply_date) {
      return;
    }
    const { full_name, birthday, sex, email, mobile, school, is_freelancer, candidate_languages, note } = dataSubmit;
    setStateButton(true);
    let response = {};
    const fb = new FormData();
    fb.append('id', uDetailCandidate?.id);
    full_name && fb.append('full_name', full_name);
    birthday && fb.append('birthday', moment(birthday, 'DD/MM/YYYY').format('YYYY/MM/DD'));
    email && fb.append('email', email);
    mobile && fb.append('mobile', mobile);
    school && fb.append('school', school);
    fb.append('candidate_languages', JSON.stringify(candidate_languages.filter((x) => x.language_id)));
    fb.append('is_freelancer', is_freelancer ? 1 : 0);
    fb.append('note', note ?? '');
    sex && fb.append('sex', sex);

    response = await apiCreateCandidateCV(fb);

    if (response?.data?.status === HTTP_OK) {
      if (errorSubmit(response?.data?.code)) {
        if (response?.data?.code === CODE_DATA_EXISTS) {
          alertConfirmDuplicateCandidate(t, confirmAlert, response?.data?.name_hr, () =>
            setShowModal((prev) => {
              return { ...prev, edit_candidate: false };
            }),
          );

          setStateButton(false);
          return;
        }
        setMessage(t(errorSubmit(response?.data?.code)));
      } else {
        setMessage(t('managerCandidate.editCandidateSuccess'));
      }
      setStateButton(false);
      setShowAlert(true);
      clearErrors();
      await queryClient.invalidateQueries(USE_GET_DETAIL_CANDIDATE);
    } else {
      if (errorSubmit(response?.data?.code)) {
        if (response?.data?.code === CODE_DATA_EXISTS) {
          setIdDetail(response?.data?.id);
          alertConfirmDuplicateCandidate(t, confirmAlert, response?.data?.name_hr, () =>
            handleRedirect.current?.handelClick.click(),
          );
          setStateButton(false);
          return;
        }
        setMessage(t(errorSubmit(response?.data?.code)));
        setShowAlertError(true);
        setStateButton(false);
      }
    }
  };

  // Watch value type timekeeping
  const watchSchoolIdValue = useWatch({
    control,
    name: 'school',
  });

  useEffect(() => {
    setValue('school', uDetailCandidate?.school);
    setValue('full_name', uDetailCandidate?.full_name);
    setValue('email', uDetailCandidate?.email);
    // eslint-disable-next-line
  }, [uDetailCandidate]);

  const {
    fields: candidate_languages,
    append: append,
    remove: remove,
  } = useFieldArray({
    control,
    name: 'candidate_languages',
  });

  // Watch value birthday
  const watchBirthdayValue = useWatch({
    control,
    name: 'birthday',
  });

  useEffect(() => {
    // eslint-disable-next-line
    if (!watchBirthdayValue || watchBirthdayValue == 'Invalid Date' || watchBirthdayValue?.length < 10) {
      return;
    }
    if (watchBirthdayValue?.length === 10) {
      if (checkMaxDateToday(watchBirthdayValue)) {
        setErrorDate((prev) => {
          return { ...prev, birthday: true };
        });
      } else {
        setErrorDate((prev) => {
          return { ...prev, birthday: false };
        });
      }
    }
  }, [watchBirthdayValue]);

  const watchSexValue = useWatch({
    control,
    name: 'sex',
  });

  const watchMobileValue = useWatch({
    control,
    name: 'mobile',
  });

  useEffect(() => {
    if (
      (watchSexValue || (watchMobileValue && String(watchMobileValue) !== String(uDetailCandidate?.mobile))) &&
      stateEditButton
    ) {
      setStateEditButton(false);
    }
    // eslint-disable-next-line
  }, [watchSexValue, watchMobileValue]);

  return (
    <>
      <ModalComponent
        toggle={() =>
          setShowModal((prev) => {
            return { ...prev, edit_candidate: !isShowModal };
          })
        }
        isShowModal={isShowModal}
        title={t('managerCandidate.editInforBasic')}
        classNameAdd="modal-create-candidate"
      >
        <NavLink
          to={{
            pathname: idDetail && '/admin/candidate-detail/' + idDetail,
          }}
          target="_blank"
          ref={(input) => {
            handleRedirect.current.handelClick = input;
          }}
          className={'link-to-detail'}
        />
        <Box autoComplete="off" onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="text-modal">
              {t('managerCandidate.inforBasic')}
            </Grid>
            <Grid item md={12} className="item-field">
              <CustomInputYup
                label={t('common.fullName')}
                name={`full_name`}
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                required
                onHandelChange={() => {
                  if (stateEditButton) {
                    setStateEditButton(false);
                  }
                }}
                defaultValue={uDetailCandidate?.full_name}
                maxLength={MAX_LENGTH_25}
              />
            </Grid>
            <Grid item md={12} className="item-field">
              <DateDay
                label={t('common.birthday')}
                name="birthday"
                error={errors?.birthday?.message}
                addErrors={errorDate?.birthday && t('messages.pleaseEnteraDateThatIsLessThanTheCurrentDate')}
                control={control}
                setValue={setValue}
                register={register}
                setError={setError}
                isShowModal={isShowModal}
                maxDate={moment()}
                onChangeHandle={() => {
                  if (stateEditButton) {
                    setStateEditButton(false);
                  }
                }}
                defaultValue={uDetailCandidate?.birthday}
              />
            </Grid>
            <Grid item md={12} className="item-field">
              <span className="label">{t('common.gender')}</span>
              <CustomRadioInput
                control={control}
                radioList={radioListGender}
                defaultValue={uDetailCandidate?.sex}
                name="sex"
                setValue={setValue}
              />
            </Grid>
            <Grid item md={12} className="item-field">
              <CusromInputNumber
                label={t('common.phone')}
                name={`mobile`}
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                required
                setValue={setValue}
                defaultValue={uDetailCandidate?.mobile}
                phone={true}
              />
            </Grid>
            <Grid item md={12} className="item-field">
              <CustomInputYup
                label={t('common.email')}
                name={`email`}
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                defaultValue={uDetailCandidate?.email}
                required
                maxLength={MAX_LENGTH_256}
              />
            </Grid>
            <Grid item md={12} className="item-field">
              <Autocomplete
                sx={{ width: 300 }}
                options={dataListSchool}
                autoHighlight
                className="school"
                onChange={(e, values, reason, detail) => {
                  setValue('school', values?.id);
                  if (values?.id > 0) {
                    setError('school', '');
                  }
                  if (stateEditButton) {
                    setStateEditButton(false);
                  }
                }}
                defaultValue={dataListSchool?.find((item) => Number(item?.id) === watchSchoolIdValue)}
                getOptionLabel={(option) => option?.name}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                    {limitLengthText(option.name, 60)}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors?.school?.message ? true : false}
                    label={t('managerCandidate.school')}
                    className={styles.schoolField}
                    inputProps={{
                      ...params.inputProps,
                      maxLength: MAX_LENGTH_50,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              {errors?.school?.message && <p className="error-input error">{errors?.school?.message}</p>}
            </Grid>
            <AddMoreLanguage
              Controller={Controller}
              control={control}
              errors={errors}
              candidate_languages={
                candidate_languages?.length !== 0 ? candidate_languages : (uDetailCandidate?.candidate_languages.length > 0 ? uDetailCandidate.candidate_languages : defaultLanguage.candidate_languages)
              }
              setError={setError}
              append={append}
              remove={remove}
              mapLanguage={mapLanguage}
              padding={'plt-16'}
            />
            <Grid item md={12} className="item-field">
              <span className="label mb-1">{t('common.isFreelancer')}</span>
              <Grid item md={12} className="mt-1">
                <Controller
                  control={control}
                  name="is_freelancer"
                  render={({ field }) => (
                    <Grid className="freelancer-create-job">
                      <Checkbox
                        {...field}
                        className="p-0"
                        defaultChecked={uDetailCandidate.is_freelancer ? true : false}
                      />
                      <span className="ml-1">{t('common.yesEN')}</span>
                    </Grid>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item md={12} className="item-field">
              <span className="label mb-2">{t('managerCandidate.noteCandidate')}</span>
            </Grid>
            <Grid item md={12} className="item-field">
              <CustomInputYup
                label={t('managerCandidate.enterNoteCandidate')}
                name="note"
                addClass="w-100 note pl-15 pt-10 overflow-auto"
                control={control}
                errors={errors}
                useWatch={useWatch}
                maxLength={MAX_LENGTH_300}
                multiline
                rows={ROWS_TEXT_AREA_6}
                defaultValue={uDetailCandidate?.note}
                register={register}
              />
            </Grid>
            <Grid item md={6}>
              <ButtonComponent
                text={t('managerJD.cancel')}
                addClass="button-cancel btn-bg-yellow2"
                handleClick={() =>
                  setShowModal((prev) => {
                    return { ...prev, edit_candidate: false };
                  })
                }
              />
            </Grid>
            <Grid item md={6}>
              <ButtonComponent
                text={t('common.save')}
                addClass="button-submit btn-bg-yellow1"
                type="submit"
                handleClick={handleSubmit(handleSubmitForm)}
                hasDisabled={stateButton || stateEditButton}
                isLoading={stateButton}
              />
            </Grid>
          </Grid>
        </Box>
      </ModalComponent>
      <AlertComponent
        toggle={() => {
          setShowAlert(!isShowAlert);
          setShowModal((prev) => {
            return { ...prev, edit_candidate: false };
          });
        }}
        isShowAlert={isShowAlert}
      >
        {message}
      </AlertComponent>
      <AlertComponent
        toggle={() => {
          setShowAlertError(!isShowAlertError);
        }}
        isShowAlert={isShowAlertError}
      >
        {message}
      </AlertComponent>
    </>
  );
};

export default EditCandidateModal;
