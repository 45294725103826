import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ModalFooter } from 'reactstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from 'react-query';

import { MenuItem, Grid } from '@mui/material';

import { apiCandidateAssess } from 'api/managerCandidate';
import styles from './styles.module.scss';

import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import ModalComponent from 'components/Modal';
import SelectField from 'components/ReactForm/SelectField';
import ButtonComponent from 'components/Buttons';

import { showAlert } from 'helpers/modal';

import * as Constants from 'constants/index.js';
import {
  CANDIDATE_RATING_PASS,
  CANDIDATE_RATING_FAIL,
  LIST_SCORES,
  USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL,
  USE_GET_LIST_LEADER_ASSESS_CANDIDATE,
  USE_GET_AVERAGE_ASSESS_CANDIDATE,
  USE_GET_LIST_TEST,
} from 'constants/ManagerCandidate';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from 'constants/StatusCode';
import { useListLevel } from 'hook/useMasterData';

const ModalCandidateAssessment = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { title, dataListAsset, dataLeaderScore, applyId, isShowModal, setIsShowModal, isRatingTest, dataStatusPass } =
    props;
  const [dataRating, setDataRating] = useState();
  const [dataRatingView, setDataRatingView] = useState();
  const [validateForm, setValidateForm] = useState();
  const { data: uDataLevel } = useListLevel();

  useEffect(() => {
    if (dataLeaderScore && dataLeaderScore.length) {
      let data = [];
      let dataRating = [];
      const valueRatingArr = dataLeaderScore?.filter((_item, index) => !(index % 2));
      valueRatingArr?.forEach((item, index) => {
        data.push({ ...item, key: 'rating' + index });
      });
      dataListAsset?.forEach((item, index) => {
        dataRating.push({ ...item, key: 'rating' + index });
      });
      setDataRatingView(data);
      setDataRating(dataRating);
    } else if (!dataLeaderScore && dataListAsset && dataListAsset.length) {
      let data = [];
      let dataRating = [];
      dataListAsset.forEach((item, index) => {
        data.push({ ...item, key: 'rating' + index });
      });
      dataListAsset.forEach((item) => {
        dataRating.push({ ...item });
      });
      setDataRatingView(data);
      setDataRating(data);
    } else {
      setDataRatingView([]);
      setDataRating([]);
    }
  }, [dataLeaderScore, dataListAsset]);

  useEffect(() => {
    let dataValidate = {};
    if (dataRatingView) {
      dataRatingView.forEach((item) => {
        dataValidate[item.key] = Yup.number().required(t('messages.pleaseAssessment')).nullable();
      });
    }
    dataValidate['level_assess_id'] = Yup.string().required(t('messages.pleaseSelectLevelAssess'));
    setValidateForm(dataValidate);
  }, [isRatingTest, dataRatingView, t]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    // getValues,
    reset,
  } = useForm({
    resolver: yupResolver(Yup.object().shape(validateForm)),
    defaultValues: dataRatingView?.map((item) => item.score),
    mode: 'onChange',
  });
  const handleCloseModal = () => {
    setIsShowModal(false);
    reset();
  };

  const { mutate: createCandidateAssess, isLoading } = useMutation((params) => apiCandidateAssess(applyId, params), {
    onSuccess: (response) => {
      if (response.status === HTTP_OK) {
        queryClient.refetchQueries(USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL);
        queryClient.refetchQueries(USE_GET_LIST_LEADER_ASSESS_CANDIDATE);
        queryClient.invalidateQueries([USE_GET_AVERAGE_ASSESS_CANDIDATE], { refetchActive: true });
        queryClient.invalidateQueries([USE_GET_LIST_TEST], { refetchActive: true });
        setIsShowModal(false);
        reset();
        dataStatusPass?.date_assess
          ? showAlert(t('messages.candidate.editCandidateAssessmentSuccess'))
          : showAlert(t('messages.candidate.candidateAssessmentSuccess'));
      } else {
        if (response?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
          showAlert(response?.data?.messages);
        }
      }
    },
    onError: (error) => {},
  });

  const getNoteAssess = (oldNote, newNote) => {
    if (!oldNote) return newNote;
    if (newNote === undefined) return oldNote;
    return newNote;
  };

  const onSubmitForm = async (dataForm) => {
    let dataSubmit = dataStatusPass?.date_assess ? { id: dataStatusPass.assess_id } : {};
    if (isRatingTest) {
      dataSubmit['status_post_test'] = dataForm.status_post_test;
    }
    dataSubmit['note'] = dataForm?.note;
    dataSubmit['level_assess_id'] = dataForm?.level_assess_id;

    let dataRatingNew = [];
    dataRatingView.forEach((item, index) => {
      dataRatingNew.push({
        ...dataRating[index],
        avg_point: dataForm[item.key],
        standar_point: dataRating[index].avg_point,
        note: getNoteAssess(item.note, dataForm[item.key + 'note']),
      });
    });
    dataSubmit['list_stats'] = dataRatingNew;
    createCandidateAssess(dataSubmit);
  };

  return (
    <ModalComponent
      title={title}
      isShowModal={isShowModal}
      toggle={handleCloseModal}
      classNameAdd={styles.modalCandidateRating}
      renderModalFooter={() => (
        <ModalFooter className={styles.modalFooter}>
          <ButtonComponent
            text={t('common.cancel')}
            type="button"
            addClass={classNames(styles.btn, styles.btn__cancel)}
            handleClick={() => handleCloseModal()}
          />
          <ButtonComponent
            text={t('common.save')}
            addClass={classNames(styles.btn, styles.btn__action)}
            isLoading={isLoading}
            hasDisabled={isLoading}
            handleClick={handleSubmit((data) => onSubmitForm(data))}
          />
        </ModalFooter>
      )}
    >
      <Grid container rowSpacing={2}>
        {isRatingTest && (
          <>
            <Grid item xs={12} className={styles.titleP}>
              {t('managerCandidate.test')}
            </Grid>
            <Grid item xs={12}>
              <SelectField
                name="status_post_test"
                addClass="select"
                label={t('managerCandidate.reviewTheTest')}
                error={errors?.status_post_test?.message}
                control={control}
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                SelectField={null}
                defaultValue={dataStatusPass?.status_post_test}
              >
                <MenuItem value={CANDIDATE_RATING_PASS} key={CANDIDATE_RATING_PASS}>
                  {t('common.pass')}
                </MenuItem>
                <MenuItem value={CANDIDATE_RATING_FAIL} key={CANDIDATE_RATING_FAIL}>
                  {t('common.fail')}
                </MenuItem>
              </SelectField>
            </Grid>
          </>
        )}
        {!!dataRatingView?.length && (
          <Grid item xs={12} className={styles.titleP}>
            {t('managerCandidate.indexRating')}
          </Grid>
        )}
        {dataRatingView?.map((item) => (
          <Grid container item xs={12} key={item.name} columnSpacing={2.5}>
            <Grid item xs={4}>
              <SelectField
                name={item.key}
                addClass="select"
                label={item.name}
                error={errors?.[item.key]?.message}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                SelectField={null}
                defaultValue={item.score}
              >
                {LIST_SCORES.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={8}>
              <CustomInputYup
                name={item.key + 'note'}
                label={t('managerCandidate.detailAssess')}
                maxLength={Constants.MAX_LENGTH_250}
                control={control}
                useWatch={useWatch}
                register={register}
                errors={errors}
                multiline
                defaultValue={item?.note ?? ''}
              />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} className={styles.titleP}>
          {t('managerCandidate.levelAssess')}
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name="level_assess_id"
            addClass="select"
            label={t('managerCandidate.level')}
            error={errors?.level_assess_id?.message}
            control={control}
            setValue={setValue}
            useWatch={useWatch}
            register={register}
            isShowModal={isShowModal}
            isRequired
            defaultValue={dataStatusPass?.level_assess_id}
          >
            {uDataLevel?.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} className={styles.titleP}>
          {t('managerCandidate.generalAssessment')}
        </Grid>
        <Grid item xs={12}>
          <CustomInputYup
            name={'note'}
            label={t('managerCandidate.generalAssessment')}
            placeholder={t('managerCandidate.generalAssessment')}
            maxLength={Constants.MAX_LENGTH_1000}
            control={control}
            useWatch={useWatch}
            register={register}
            errors={errors}
            multiline
            rows={7}
            defaultValue={dataStatusPass?.note ?? ''}
          />
        </Grid>
      </Grid>
    </ModalComponent>
  );
};

export default ModalCandidateAssessment;
