import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { MenuItem, Grid } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';

import { apiAddOrUpdateCost } from 'api/managerCost';

import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import SelectComponent from 'components/Select/Select.js';
import CustomInputNumber from 'components/InputYupAndMui/CusromInputNumber';

import { HTTP_OK } from 'constants/StatusCode.js';
import { MAX_LENGTH_20, MANAGE_LIST_COST_LEADER } from 'constants/index.js';
import { MONTH_BONUS, STATUS_CANDIDATE } from 'constants/MasterData';

import { getLevelName } from 'helpers/masterData';
import { showAlert } from 'helpers/modal';

const CreatOrUpdateCost = ({
  setShowModal,
  isShowModal,
  textModal,
  formAdd,
  queryClient,
  setShowAlertUpdate,
  setShowAlertCreate,
  setMessage,
  uDataLevel,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        rate: Yup.mixed().test('required', t('manageCostRecruitHrm.pleaseFillCost'), (val) => {
          return +val >= 1;
        }),
      }),
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    defaultValues: formAdd,
    mode: 'onChange',
  });

  const { mutate: submit } = useMutation(
    (values) =>
      apiAddOrUpdateCost({
        ...values,
        level_id: formAdd.level_id,
        rate: Number(values.rate),
      }),
    {
      onSuccess: (response, variables) => {
        if (response?.data?.status === HTTP_OK) {
          variables.id === null
            ? setMessage(
                t('manageCostRecruitHrm.createCost') +
                  getLevelName(formAdd.level_id, uDataLevel) +
                  t('masterData.Candidate.Successfully'),
              )
            : setMessage(
                t('manageCostRecruitHrm.updateCost') +
                  getLevelName(formAdd.level_id, uDataLevel) +
                  t('manageCostRecruitHrm.Successfully'),
              );
          variables.id === null ? setShowAlertCreate(true) : setShowAlertUpdate(true);
          queryClient.invalidateQueries(MANAGE_LIST_COST_LEADER.listCostMasterData);
          setShowModal(false);
        } else {
          if (response?.data?.message) {
            showAlert(response.data.message);
          } else {
            showAlert(t('messages.systemError'));
          }
        }
      },
      onError: (error) => {
        showAlert(t('messages.systemError'));
      },
    },
  );

  return (
    <div>
      <ModalComponent
        toggle={() => setShowModal(!isShowModal)}
        isShowModal={isShowModal}
        title={textModal.title}
        classNameAdd="modal-add-bonus"
      >
        <form className="mt-3">
          <Grid container className="add-time-bonus">
            <Grid container item xs="12" className="fieldset-group input-field mt-4 ">
              <Grid item xs="7">
                <CustomInputNumber
                  name="rate"
                  label={t('manageCostRecruitHrm.cost')}
                  maxLength={MAX_LENGTH_20}
                  control={control}
                  useWatch={useWatch}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  required
                  isInteger
                  defaultValue={formAdd.rate}
                />
              </Grid>
              <Grid item xs="5" className="text-chargeOfficial">
                {t('manageCostRecruitHrm.chargeOfficial')}
              </Grid>
            </Grid>

            <Grid container item xs="12">
              <Grid xs="3" className="text-title-modal no-padding outline-select-input">
                <Controller
                  control={control}
                  name="milestone"
                  valueDefault={formAdd.milestone}
                  render={({ field }) => (
                    <SelectComponent field={field} addClass="select flex-1" isRequired={true}>
                      {MONTH_BONUS.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </SelectComponent>
                  )}
                />
              </Grid>
              <Grid xs="5">
                <div className="text-content-modal mt-2 text-timeAfterOnboard">
                  {t('masterData.Candidate.timeAfter')}
                </div>
              </Grid>
              <Grid xs="4" className="text-title-modal no-padding outline-select-input">
                <Controller
                  control={control}
                  name="type"
                  valueDefault={formAdd.type}
                  render={({ field }) => (
                    <SelectComponent field={field} addClass="select flex-1" isRequired={true}>
                      {STATUS_CANDIDATE.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </SelectComponent>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Row className="mt-3">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModal(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100 btn-bg-yellow1"
                handleClick={handleSubmit(submit)}
                type="submit"
              />
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </div>
  );
};

export default CreatOrUpdateCost;
