/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'components/Buttons';
import SelectComponent from 'components/Select/Select.js';
import { useListSkill } from 'hook/useMasterData';
import { useListPosition } from 'hook/useMasterData';
import AddMoreQuestion from './AddMoreQuestion';
import { addOrUpdateQuestionInterview } from 'api/managerQuestionInterview';
import { useQueryClient } from 'react-query';
import {
  GET_ITEM_QUESTION_DETAIL,
  QUESTION_FORMDATA,
  QUESTION_INTERVIEW_ID,
  ACTIVE_STATUS_QUESTION,
  GET_QUESTION_INTERVIEW,
} from '../../../constants/ManagerQuestionInterview';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useGetQuestionInterviewDetail } from 'hook/useManagerQuestionInterview';
import { errorSubmit } from 'helpers';
import { CODE_DATA_EXISTS } from 'constants/index';

function CreateQuestionInterview({ listManagerQuestionInterview, dataQuestion, isLoading }) {
  const { t } = useTranslation();
  const query = useQueryClient();
  const params = new URLSearchParams(window.location.search);
  const viewEdit = params.get('id') ? true : false;
  const defaultQuestion = { content: '', level_id: undefined };
  const { data } = useGetQuestionInterviewDetail({
    id: params.get('id'),
  });
  const [defaultFormData] = useState({
    status: 1,
    position_id: -1,
    skill_id: viewEdit ? dataQuestion?.skill_id : -1,
    list_questions_1: [{ type: 1, ...defaultQuestion }],
    list_questions_2: [{ type: 2, ...defaultQuestion }],
  });
  const [formData, setFormData] = useState(structuredClone(defaultFormData));
  const [questionError, setQuestionError] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        position_id: Yup.number().min(0, t('managerQuestionInterview.required')),
        skill_id: Yup.number().test('min', t('managerQuestionInterview.required'), (item) => {
          return newSkill?.length > 0 ? item >= 0 : true;
        }),
        list_questions_1: Yup.array().of(
          Yup.object().shape({
            content: Yup.string()
              .trim()
              .test('required', t('managerQuestionInterview.minLength10'), (val) => {
                const list2 = getValues('list_questions_2')[0] || defaultQuestion;
                return !list2.content || list2.level_id === -1 || getValues('list_questions_1').length > 1
                  ? !!val
                  : true;
              })
              .test('min', t('managerQuestionInterview.minLength10'), (val) => {
                const list2 = getValues('list_questions_2')[0] || defaultQuestion;
                return !list2?.content ||
                  list2?.level_id === -1 ||
                  val?.length > 0 ||
                  getValues('list_questions_1').length > 1
                  ? val?.length >= 10
                  : true;
              })
              .test('max', t('managerQuestionInterview.maxLength1000'), (val) => {
                const list2 = getValues('list_questions_2')[0] || defaultQuestion;
                return !list2.content ||
                  list2.level_id === -1 ||
                  val?.length > 0 ||
                  getValues('list_questions_1').length > 1
                  ? val?.length <= 1000
                  : true;
              }),
            level_id: Yup.number().test('min', t('managerQuestionInterview.requiredLevel'), (val) => {
              const list2 = getValues('list_questions_2')[0] || defaultQuestion;
              return !list2.content ||
                list2.level_id === -1 ||
                getValues('list_questions_1')?.[0]?.content?.trim().length > 0 ||
                getValues('list_questions_1')?.length > 1
                ? val >= 0
                : true;
            }),
          }),
        ),
        list_questions_2: Yup.array().of(
          Yup.object().shape({
            content: Yup.string()
              .trim()
              .test('required', t('managerQuestionInterview.minLength10'), (val) => {
                const list2 = getValues('list_questions_1')[0] || defaultQuestion;
                return !list2.content || list2.level_id === -1 || getValues('list_questions_2')?.length > 1
                  ? !!val
                  : true;
              })
              .test('min', t('managerQuestionInterview.minLength10'), (val) => {
                const list2 = getValues('list_questions_1')[0] || defaultQuestion;
                return !list2?.content ||
                  list2?.level_id === -1 ||
                  val?.length > 0 ||
                  getValues('list_questions_2')?.length > 1
                  ? val?.length >= 10
                  : true;
              })
              .test('max', t('managerQuestionInterview.maxLength1000'), (val) => {
                const list2 = getValues('list_questions_1')[0] || defaultQuestion;
                return !list2.content ||
                  list2.level_id === -1 ||
                  val?.length > 0 ||
                  getValues('list_questions_2')?.length > 1
                  ? val?.length <= 1000
                  : true;
              }),
            level_id: Yup.number().test('min', t('managerQuestionInterview.requiredLevel'), (val) => {
              const list2 = getValues('list_questions_1')[0] || defaultQuestion;
              return !list2.content ||
                list2.level_id === -1 ||
                getValues('list_questions_2')?.[0]?.content?.trim().length > 0 ||
                getValues('list_questions_2')?.length > 1
                ? val >= 0
                : true;
            }),
          }),
        ),
      }),
    ),
    defaultValues: useMemo(() => formData, [dataQuestion, formData]),
    mode: 'onChange',
  });
  const {
    fields: list_questions_1,
    append: append1,
    remove: remove1,
  } = useFieldArray({
    control,
    name: 'list_questions_1',
  });
  const {
    fields: list_questions_2,
    append: append2,
    remove: remove2,
  } = useFieldArray({
    control,
    name: 'list_questions_2',
  });

  const positionId = useWatch({
    control,
    name: 'position_id',
  });

  const mapPosition = useRef({});
  const mapLevel = useRef({});
  const [loading, setLoading] = useState(true);
  const message = useRef('');
  const idQuestion = useRef(-1);
  const prepareDataActive = useRef({});
  const skillKeyWord = useRef(formData.skill_id !== -1 ? formData.skill_id : null);

  const [openModal, setOpenModal] = useState(false);
  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });

  useEffect(() => {
    reset(data);

    if (data) {
      data.skill_id = data.skill_id || -1;
      skillKeyWord.current = data.skill_id;
      setValue('skill_id', data.skill_id);
      setFormData(data);
    }
    return () => query.setQueryDefaults(GET_ITEM_QUESTION_DETAIL, null);
  }, [data]);
  useEffect(() => {
    query.setQueryDefaults(QUESTION_FORMDATA, {});
  }, []);

  const onSubmit = async (data) => {
    if (loading) {
      setLoading(false);
      if (data.skill_id === -1) data.skill_id = null;
      const listQuestions1 = data.list_questions_1.filter((item) => {
        if (!item.type && item.content?.length > 0 && item.level_id >= 0) return (item.type = 1);
        return item.content?.length > 0 && item.level_id >= 0;
      });
      const listQuestions2 = data.list_questions_2.filter((item) => {
        if (!item.type && item.content?.length > 0 && item.level_id >= 0) return (item.type = 2);
        return item.content?.length > 0 && item.level_id >= 0;
      });
      const newFormData = {
        ...data,
        list_questions: listQuestions1.concat(listQuestions2),
        status: +data.status,
      };
      delete newFormData.list_questions_1;
      delete newFormData.list_questions_2;
      prepareDataActive.current = newFormData;
      const res = await addOrUpdateQuestionInterview(newFormData);
      if (res.data.id) {
        // create not show popup detail question
        if (params.get('id')) query.setQueryDefaults(QUESTION_INTERVIEW_ID, res.data.id);
        idQuestion.current = res.data.id;
        query.refetchQueries([GET_QUESTION_INTERVIEW]);
      }
      if (errorSubmit(res?.data?.code) || res.data.status === 422) {
        if (res?.data?.code === CODE_DATA_EXISTS) {
          message.current = t('managerQuestionInterview.questionAlreadyExists');
        } else {
          message.current = t(
            res?.data?.code ? errorSubmit(res?.data?.code) : 'managerQuestionInterview.somethingWenWrong',
          );
        }
        setQuestionError(true);
      } else {
        setOpenModal(true);
      }
      setFormData({ ...data, id: idQuestion.current });
      setLoading(true);
    }
  };
  const handleCancel = () => {
    if (loading) {
      setLoading(false);
      query.setQueryDefaults(QUESTION_FORMDATA, formData);
      listManagerQuestionInterview();
      setLoading(true);
    }
  };

  const handleActiveQuestion = async () => {
    if (loading) {
      setLoading(false);
      const res = await addOrUpdateQuestionInterview({ ...prepareDataActive.current, important: true });
      if (res.data.id) formData.id = res.data.id;
      query.setQueryDefaults(QUESTION_FORMDATA, formData);
      listManagerQuestionInterview();
      setLoading(true);
    }
  };
  const newSkill = useMemo(
    () => uDataSkill?.filter((item) => item.position_id === positionId),
    [uDataSkill, positionId],
  );
  const watchSoftQuestion = useWatch({ control, name: 'list_questions_1' });
  const watchQuestionMajor = useWatch({ control, name: 'list_questions_2' });
  const skillId = useWatch({
    control,
    name: 'skill_id',
  });
  const conditionEnable = () => {
    const softQuestion = watchSoftQuestion[0];
    const questionMajor = watchQuestionMajor[0];
    return (
      isLoading ||
      !loading ||
      positionId === -1 ||
      (newSkill?.length > 0 && skillId === -1) ||
      (!newSkill && skillId === -1) ||
      ((softQuestion?.content === '' || softQuestion?.level_id === -1) &&
        (questionMajor?.content === '' || questionMajor?.level_id === -1))
    );
  };
  return (
    <Grid container className="content management-question-interview">
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent className="question-interview-success-save-modal">
          <DialogContentText id="alert-dialog-description">
            {t(`managerQuestionInterview.${params.get('id') ? 'editQuestionSuccess' : 'createQuestionSuccess'}`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn-transparent color-yellow text-font" onClick={handleCancel}>
            {t('managerQuestionInterview.done')}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={questionError} onClose={() => setQuestionError(false)}>
        <DialogContent className="question-interview-active-modal">
          <DialogContentText id="alert-dialog-description">{message.current}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="popupConflict__groupBtn p-content">
            <button
              className="groupBtn_btn"
              onClick={() => {
                query.setQueryDefaults(QUESTION_INTERVIEW_ID, null);
                setQuestionError(false);
              }}
            >
              {t('managerQuestionInterview.back')}
            </button>
            <button className="groupBtn_btn" onClick={handleActiveQuestion}>
              {t('managerQuestionInterview.activeQuestion')}
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Grid item xs={12} className="header-left color-text">
        {t(`managerQuestionInterview.${params.get('id') ? 'editQuestion' : 'createQuestion'}`)}
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} className="content-component">
          <Paper>
            <Grid container className="d-flex align-items-center justify-content-center pl-20 pr-20 pt-3 pb-3">
              <Grid item xs={6} className="header-color p-0 fw-bold header-infoGeneral">
                {t('managerQuestionInterview.infoGeneral')}
              </Grid>
              <Grid item xs={6} className="text-right p-0">
                <ButtonComponent
                  text={t('managerQuestionInterview.cancel')}
                  addClass="btn-bg-yellow2 mr-3"
                  handleClick={() => {
                    if (params.get('id') === undefined) query.setQueryDefaults(QUESTION_INTERVIEW_ID, null);
                    handleCancel();
                  }}
                />
                <ButtonComponent
                  text={t('managerQuestionInterview.save')}
                  addClass="btn-bg-yellow1"
                  hasDisabled={conditionEnable()}
                  isLoading={!loading}
                  type="submit"
                />
              </Grid>
            </Grid>
            <div className="divider" />
            <Grid className="p-20">
              <Grid item xs={12} className="header-infoRecruitment header-color">
                {t('managerQuestionInterview.infoRecruitment')}
              </Grid>
              <Grid container className="d-flex align-items-center justify-content-center">
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="position_id"
                      valueDefault={formData.position_id}
                      render={({ field }) => (
                        <SelectComponent
                          field={field}
                          addClass="select"
                          label={t('managerQuestionInterview.position')}
                          isRequired={true}
                          error={errors?.['position_id']?.message}
                          onChange={(e) => {
                            setValue('skill_id', -1);
                            setValue('position_id', e.target.value);
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: {
                                  xs: 350,
                                },
                              },
                            },
                          }}
                        >
                          {uDataPosition &&
                            uDataPosition?.map((item, index) => {
                              mapPosition.current[item.id] = item.name;
                              return (
                                <MenuItem key={index} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </SelectComponent>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} className="select-skill-test">
                    <Controller
                      control={control}
                      name="skill_id"
                      valueDefault={formData.skill_id}
                      render={({ field }) => (
                        <SelectComponent
                          field={field}
                          addClass="select"
                          label={t('managerQuestionInterview.skill')}
                          disabled={!newSkill?.length > 0}
                          error={errors?.['skill_id']?.message}
                        >
                          {newSkill &&
                            newSkill?.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </SelectComponent>
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container className="mt-4">
                  <Grid item xs={12} className="text-color status-font">
                    {t('managerQuestionInterview.status')}
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="status"
                      valueDefault={formData.status}
                      render={({ field }) => (
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          value={formData.status}
                          className="d-flex align-items-center justify-content-start flex-row radio-text"
                          {...field}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label={t('managerQuestionInterview.active')}
                            disabled={viewEdit && formData.status === ACTIVE_STATUS_QUESTION ? true : false}
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label={t('managerQuestionInterview.inactive')}
                            disabled={viewEdit && formData.status === ACTIVE_STATUS_QUESTION ? true : false}
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} className="header-color header-infoRecruitment">
                    {t('managerQuestionInterview.testInfo')}
                  </Grid>
                  <AddMoreQuestion
                    Controller={Controller}
                    control={control}
                    errors={errors}
                    type={1}
                    list_questions={
                      list_questions_1?.length !== 0 ? list_questions_1 : defaultFormData.list_questions_1
                    }
                    setFormData={setFormData}
                    formData={formData}
                    setError={setError}
                    append={append1}
                    remove={remove1}
                    mapLevel={mapLevel}
                    useWatch={useWatch}
                  />
                </Grid>
                <Grid container className="mt-3">
                  <AddMoreQuestion
                    Controller={Controller}
                    control={control}
                    errors={errors}
                    type={2}
                    list_questions={
                      list_questions_2?.length !== 0 ? list_questions_2 : defaultFormData.list_questions_2
                    }
                    setFormData={setFormData}
                    formData={formData}
                    setError={setError}
                    append={append2}
                    remove={remove2}
                    mapLevel={mapLevel}
                    useWatch={useWatch}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>
    </Grid>
  );
}
export default memo(CreateQuestionInterview);
