import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import { ModalFooter } from 'reactstrap';
import { Box, Divider, Grid, Tooltip } from '@mui/material';

import styles from './styles.module.scss';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';

import { CANDIDATE_PERMISSION } from 'constants/permission';
import { INTERVIEW_TYPE as TYPE, INTERVIEW_STATUS } from 'constants/InterviewScheduleManagement';
import { STATUS_TESTING } from 'constants/managerTest';
import { InterviewContext } from './InterviewSchedule';
import { compact, handleOnError } from 'helpers';
import CancelModal from './CancelModal';
import EditModal from './EditModal';
import ModalComponent from 'components/Modal';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import EditLayout from 'components/PermissionPage/EditLayout';
import { ThemContext } from 'layouts/Admin';

const InterviewDetailModal = (props) => {
  const { t } = useTranslation();
  const { selectedDetail, setSelectedDetail, refetchData } = props;
  const { inforAuth } = useContext(InterviewContext);
  const { isLeader, isAssigner } = useContext(ThemContext);
  const [isShowCancelModal, setIsShowCancelModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);

  const INTERVIEW_TYPE = {
    [TYPE.ONLINE]: t('interviewSchedule.detailModal.interviewType.online'),
    [TYPE.OFFLINE]: t('interviewSchedule.detailModal.interviewType.offline'),
  };

  const POST_TEST_STATUS = {
    [STATUS_TESTING.PENDING]: t('interviewSchedule.detailModal.testStatus.pending'),
    [STATUS_TESTING.STARTED]: t('interviewSchedule.detailModal.testStatus.started'),
    [STATUS_TESTING.FINISHED]: t('interviewSchedule.detailModal.testStatus.finished'),
    [STATUS_TESTING.CANCELED]: t('interviewSchedule.detailModal.testStatus.finished'),
  };
  const fields = selectedDetail
    ? [
        {
          name: 'candidate',
          label: t('interviewSchedule.detailModal.label.candidate'),
          value: (() => {
            const detailCandidateLink = (
              <a
                href={`/admin/candidate-detail/${selectedDetail.candidateId}`}
                target="_blank"
                rel="noreferrer"
              >
                {t('interviewSchedule.detailModal.linkToDetailCandidate')}
              </a>
            );
            return (
              <span>
                {selectedDetail.candidateName} ({detailCandidateLink})
              </span>
            );
          })(),
          md: 12,
          xs: 12,
        },
        {
          name: 'position',
          label: t('interviewSchedule.detailModal.label.position'),
          value: selectedDetail.positionName,
          md: 4,
          xs: 4,
        },
        {
          name: 'level',
          label: t('interviewSchedule.detailModal.label.level'),
          value: selectedDetail.levelName,
          md: 4,
          xs: 4,
        },
        {
          name: 'skill',
          label: t('interviewSchedule.detailModal.label.skill'),
          value: selectedDetail.skillName,
          md: 4,
          xs: 4,
        },
        {
          name: 'status',
          label: t('interviewSchedule.detailModal.label.status'),
          value: POST_TEST_STATUS[selectedDetail.testStatus],
          md: 6,
          xs: 6,
        },
        {
          name: 'interviewDate',
          label: t('interviewSchedule.detailModal.label.interviewDate'),
          value: moment(selectedDetail.date).format('DD/MM/YYYY'),
          md: 6,
          xs: 6,
        },
        {
          name: 'startTime',
          label: t('interviewSchedule.detailModal.label.startTime'),
          value: selectedDetail.startTime,
          md: 6,
          xs: 6,
        },
        {
          name: 'endTime',
          label: t('interviewSchedule.detailModal.label.endTime'),
          value: selectedDetail.endTime,
          md: 6,
          xs: 6,
        },
        {
          name: 'location',
          label: t('interviewSchedule.detailModal.label.location'),
          value: selectedDetail.locationName,
          md: 6,
          xs: 6,
        },
        {
          name: 'room',
          label: t('interviewSchedule.detailModal.label.room'),
          value: selectedDetail.roomName,
          md: 6,
          xs: 6,
        },
        {
          name: 'interviewType',
          label: t('interviewSchedule.detailModal.label.interviewType'),
          value: INTERVIEW_TYPE[selectedDetail.type],
          md: 6,
          xs: 6,
        },
        {
          name: 'interviewLink',
          label: t('interviewSchedule.detailModal.label.interviewLink'),
          value: selectedDetail.link ? <a href={selectedDetail.link}>Link</a> : null,
          md: 6,
          xs: 6,
        },
        {
          name: 'note',
          label: t('interviewSchedule.detailModal.label.note'),
          value: selectedDetail.note,
          md: 12,
          xs: 12,
        },
      ]
    : [];

  const isInterviewDone = selectedDetail?.statusInterview === INTERVIEW_STATUS.DONE;

  const isDisableEdit = inforAuth?.user_id !== selectedDetail?.creatorId || isInterviewDone;

  const isDisableCancel =
    (inforAuth?.user_id !== selectedDetail?.hrChargeId && !isLeader && !isAssigner) || isInterviewDone;

  const handleOpenCancelModal = (e) => {
    if (isDisableCancel) return;
    setIsShowCancelModal(true);
    e.stopPropagation();
  };

  const handleOpenEditModal = (e) => {
    if (isDisableEdit) return;
    setIsShowEditModal(true);
    e.stopPropagation();
  };

  const handleCancelSuccess = () => {
    window.history.replaceState(null, '', '/admin/interview-schedule');
    setSelectedDetail(undefined);
    setIsCancelSuccess(true);
    refetchData();
  };

  const handleSaveSuccess = () => {
    window.history.replaceState(null, '', '/admin/interview-schedule');
    setSelectedDetail(undefined);
    setIsSaveSuccess(true);
    refetchData();
  };

  const TooltipContainerInterview = ({ children, titleText }) => {
    if (isInterviewDone)
      return (
        <Tooltip title={<span>{titleText}</span>} arrow>
          {children}
        </Tooltip>
      );
    return children;
  };

  return (
    <>
      <ModalComponent
        title={t('interviewSchedule.detailModal.title')}
        isShowModal={!!selectedDetail}
        toggle={() => {
          setSelectedDetail(undefined);
          window.history.replaceState(null, '', '/admin/interview-schedule');
        }}
        classNameAdd={styles.detailModal}
        renderModalFooter={() => (
          <ModalFooter className={styles.modalFooter}>
            <EditLayout permissionName={CANDIDATE_PERMISSION}>
              <TooltipContainerInterview
                titleText={t('interviewSchedule.detailModal.notAllowCancelInterviewWhenLeaderStarted')}
              >
                <button
                  type="button"
                  className={classNames(styles.btn, styles.btn__danger, { [styles.btn__disabled]: isDisableCancel })}
                  onClick={handleOpenCancelModal}
                  disabled={isDisableCancel}
                >
                  {t('interviewSchedule.detailModal.cancelInterview')}
                </button>
              </TooltipContainerInterview>
            </EditLayout>
            <EditLayout permissionName={CANDIDATE_PERMISSION}>
              <TooltipContainerInterview
                titleText={t('interviewSchedule.detailModal.notAllowEditInterviewWhenLeaderStarted')}
              >
                <button
                  type="button"
                  className={classNames(styles.btn, styles.btn__action, { [styles.btn__disabled]: isDisableEdit })}
                  onClick={handleOpenEditModal}
                  disabled={isDisableEdit}
                >
                  {t('interviewSchedule.detailModal.edit')}
                </button>
              </TooltipContainerInterview>
            </EditLayout>
          </ModalFooter>
        )}
      >
        <Grid container className={styles.detailFields} rowSpacing={1.15} columnSpacing={2}>
          {fields.map((field) => {
            return (
              <Grid item xs={field.xs} md={field.md} key={field.name}>
                <Box className={styles.field}>
                  <Box className={styles.field__label}>{field.label}</Box>
                  <Box className={styles.field__value}>{field.value}</Box>
                  <Divider />
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Box className={styles.interviewerInfo}>
          <Box className={styles.interviewerInfo__title}>
            {t('interviewSchedule.detailModal.label.interviewerInfo')}
          </Box>
          <Grid container className={styles.interviewers} direction="column">
            {selectedDetail?.interviewers?.map((interviewer) => {
              return (
                <Box key={interviewer.user_id}>
                  <Grid item className={styles.interviewer}>
                    <Box className={styles.interviewer__avatar}>
                      <img src={interviewer?.avatar || defaultAvatar} alt={interviewer.name} onError={handleOnError} />
                    </Box>
                    <Box>
                      <Box className={styles.interviewer__name}>{interviewer.name}</Box>
                      <Box className={styles.interviewer__position}>
                        {compact([interviewer.position_name, interviewer.division_name]).join(' - ')}
                      </Box>
                    </Box>
                  </Grid>
                  <Divider />
                </Box>
              );
            })}
          </Grid>
        </Box>
      </ModalComponent>

      {selectedDetail && (
        <>
          <CancelModal
            isShowModal={isShowCancelModal}
            setIsShowModal={setIsShowCancelModal}
            selectedDetail={selectedDetail}
            onSuccess={handleCancelSuccess}
          />
          {isShowEditModal && (
            <EditModal
              isShowModal={isShowEditModal}
              setIsShowModal={setIsShowEditModal}
              selectedDetail={selectedDetail}
              onSuccess={handleSaveSuccess}
            />
          )}
        </>
      )}

      <AlertAfterSubmit toggle={() => setIsCancelSuccess(false)} isShowAlert={isCancelSuccess}>
        <p>{t('interviewSchedule.cancelModal.message.success')}</p>
      </AlertAfterSubmit>
      <AlertAfterSubmit toggle={() => setIsSaveSuccess(false)} isShowAlert={isSaveSuccess}>
        <p>{t('interviewSchedule.editModal.message.success')}</p>
      </AlertAfterSubmit>
    </>
  );
};

export default InterviewDetailModal;
