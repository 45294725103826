import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { Grid, MenuItem } from '@mui/material';

import styles from './styles.module.scss';
import { apiCandidateAssess } from 'api/managerCandidate';
import * as Constants from 'constants/index.js';
import { HTTP_OK } from 'constants/StatusCode';
import {
  USE_GET_LIST_LEADER_ASSESS_CANDIDATE,
  USE_GET_AVERAGE_ASSESS_CANDIDATE,
  USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL,
} from 'constants/ManagerCandidate';
import { useListLevel, useListPersonnel, useListContractCategory } from 'hook/useMasterData';

import ModalComponent from 'components/Modal';
import CustomInputNumber from 'components/InputYupAndMui/CusromInputNumber';
import ButtonComponent from 'components/Buttons';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import SelectField from 'components/ReactForm/SelectField';
import DateDay from 'components/ReactForm/DateDay';
import { checkInvalidDateByDiagonalLine, formatDateIgnoreDiagonalLine } from 'helpers/formatDate';
import { showAlert } from 'helpers/modal';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';

const FORM_FIELDS = {
  level_official_id: 'level_official_id',
  starting_salary: 'starting_salary',
  salary: 'salary',
  salary_bonus: 'salary_bonus',
  apply_suggest: 'apply_suggest',
  group_id: 'group_id',
  contract_category_id: 'contract_category_id',
  expect_date: 'expect_date',
  contract_term: 'contract_term',
};

const ModalOffer = (props) => {
  const { isShowModal, setShowModal, dataOfferCandidate, isLeaderInterrupt } = props;
  const { applyId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isOfferSuccess, setIsOfferSuccess] = useState(false);
  const { data: uDataLevel } = useListLevel();
  const { data: uDataPersonnel } = useListPersonnel();
  const { data: uDataContractCategory } = useListContractCategory();
  const DEFAULT_VALUES = {
    [FORM_FIELDS.expect_date]: dataOfferCandidate?.expect_date ? dataOfferCandidate.expect_date : null,
    [FORM_FIELDS.level_official_id]: dataOfferCandidate?.level_official_id ? dataOfferCandidate.level_official_id : '',
    [FORM_FIELDS.salary]: dataOfferCandidate?.salary ? dataOfferCandidate.salary : null,
    [FORM_FIELDS.starting_salary]: dataOfferCandidate?.starting_salary ? dataOfferCandidate.starting_salary : null,
    [FORM_FIELDS.group_id]: dataOfferCandidate?.group_id ? dataOfferCandidate.group_id : '',
    [FORM_FIELDS.contract_category_id]: dataOfferCandidate?.contract_category_id
      ? dataOfferCandidate.contract_category_id
      : '',
    [FORM_FIELDS.salary_bonus]: dataOfferCandidate?.salary_bonus > 0 ? dataOfferCandidate.salary_bonus : null,
    [FORM_FIELDS.apply_suggest]: dataOfferCandidate?.apply_suggest ? dataOfferCandidate.apply_suggest : null,
    [FORM_FIELDS.contract_term]: dataOfferCandidate?.contract_term ? dataOfferCandidate.contract_term : null,
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        [FORM_FIELDS.level_official_id]: Yup.number()
          .typeError(t('messages.pleaseSelectLevel'))
          .required(t('messages.pleaseSelectLevel'))
          .nullable(),
        [FORM_FIELDS.salary]: Yup.number()
          .typeError(t('messages.pleaseFillOutOfficialSalary'))
          .required(t('messages.pleaseFillOutOfficialSalary'))
          .min(Constants.MIN_SALARY, t('messages.pleaseEnterSalaryMore'))
          .nullable(),
        [FORM_FIELDS.starting_salary]: Yup.number()
          .typeError(t('messages.pleaseFillOutStartingSalary'))
          .required(t('messages.pleaseFillOutStartingSalary'))
          .min(Constants.MIN_SALARY, t('messages.pleaseEnterSalaryMore'))
          .nullable(),
        [FORM_FIELDS.salary_bonus]: Yup.string()
          .test('min', t('messages.pleaseEnterSalaryMore'), (val) => {
            if (!val) return true;
            return +val >= Constants.MIN_SALARY;
          })
          .nullable(),
        [FORM_FIELDS.group_id]: Yup.number()
          .typeError(t('messages.pleaseSelectPersonnel'))
          .required(t('messages.pleaseSelectPersonnel'))
          .nullable(),
        [FORM_FIELDS.contract_category_id]: Yup.number()
          .typeError(t('messages.pleaseSelectContract'))
          .required(t('messages.pleaseSelectContract'))
          .nullable(),
        [FORM_FIELDS.contract_term]: Yup.string().required(t('messages.pleaseFillContractTerm')).trim().nullable(),
        [FORM_FIELDS.expect_date]: Yup.string()
          .nullable()
          .required(t('messages.pleaseSelectExpectDate'))
          .test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) => {
            if (!value) return true;
            return checkInvalidDateByDiagonalLine(value);
          })
          .test('min', t('messages.pleaseEnterTheDateNowOrFuture'), (value) => {
            return (
              (!!dataOfferCandidate?.expect_date &&
                moment(dataOfferCandidate.expect_date, 'YYYY-MM-DD').isSame(
                  moment(formatDateIgnoreDiagonalLine(value), 'DD/MM/YYYY'),
                )) ||
              !moment(formatDateIgnoreDiagonalLine(value), 'DD/MM/YYYY').isBefore(moment(), 'day')
            );
          }),
      }),
    ),
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const { mutate: submitOffer, isLoading } = useMutation(
    (params) => {
      if (
        !!dataOfferCandidate?.expect_date &&
        moment(dataOfferCandidate.expect_date, 'YYYY-MM-DD').isSame(
          moment(formatDateIgnoreDiagonalLine(params.expect_date), 'DD/MM/YYYY'),
        )
      ) {
        delete params.expect_date;
      }
      if (params?.expect_date)
        params.expect_date = moment(formatDateIgnoreDiagonalLine(params.expect_date), 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );

      return apiCandidateAssess(applyId, {
        ...params,
        salary_bonus: Number(params.salary_bonus),
        is_leader_interrupt: isLeaderInterrupt ?? null,
      });
    },
    {
      onSuccess: (response) => {
        if (response.status === HTTP_OK) {
          queryClient.invalidateQueries([USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL], { refetchActive: true });
          queryClient.invalidateQueries([USE_GET_LIST_LEADER_ASSESS_CANDIDATE], { refetchActive: true });
          queryClient.invalidateQueries([USE_GET_AVERAGE_ASSESS_CANDIDATE], { refetchActive: true });
          dataOfferCandidate?.level_official_id
            ? showAlert(t('messages.candidate.editOfferSuccess'))
            : showAlert(t('messages.candidate.candidateOfferSuccess'));
          setShowModal(false);
        }
      },
      onError: (error) => {},
    },
  );

  const FooterModalOffer = () => (
    <ModalFooter className={styles.modalFooter}>
      <button type="button" className={classNames(styles.btn, styles.btn__cancel)} onClick={() => setShowModal(false)}>
        {t('common.cancel')}
      </button>
      <ButtonComponent
        text={t('common.save')}
        addClass={classNames(styles.btn, styles.btn__action)}
        handleClick={handleSubmit(submitOffer)}
        isLoading={isLoading}
        hasDisabled={isLoading}
      ></ButtonComponent>
    </ModalFooter>
  );

  return (
    <>
      <AlertAfterSubmit toggle={() => setIsOfferSuccess(false)} isShowAlert={isOfferSuccess}>
        <p>{t('messages.offerCandidateSuccess')}</p>
      </AlertAfterSubmit>
      <ModalComponent
        toggle={() => setShowModal(false)}
        isShowModal={isShowModal}
        title={t('managerCandidate.offerCandidate')}
        renderModalFooter={FooterModalOffer}
        classNameAdd={styles.modalOfferContainer}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <SelectField
              name={FORM_FIELDS.level_official_id}
              label={t('managerCandidate.chooseOfficialLevel')}
              control={control}
              required
              setValue={setValue}
              useWatch={useWatch}
              register={register}
              isShowModal={isShowModal}
              isRequired
              error={errors[FORM_FIELDS.level_official_id]?.message}
              defaultValue={DEFAULT_VALUES.level_official_id}
            >
              {uDataLevel?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <CustomInputNumber
              label={t('managerCandidate.startingSalary')}
              name={FORM_FIELDS.starting_salary}
              control={control}
              register={register}
              errors={errors}
              useWatch={useWatch}
              required
              setValue={setValue}
              maxLength={Constants.MAX_LENGTH_10}
              isInteger
              defaultValue={DEFAULT_VALUES.starting_salary}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputNumber
              label={t('managerCandidate.salaryOfficial')}
              name={FORM_FIELDS.salary}
              control={control}
              register={register}
              errors={errors}
              useWatch={useWatch}
              setValue={setValue}
              maxLength={Constants.MAX_LENGTH_10}
              isInteger
              required
              defaultValue={DEFAULT_VALUES.salary}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputNumber
              name={FORM_FIELDS.salary_bonus}
              label={t('managerCandidate.salaryBonus')}
              maxLength={Constants.MAX_LENGTH_10}
              control={control}
              useWatch={useWatch}
              register={register}
              setValue={setValue}
              errors={errors}
              isInteger
              defaultValue={DEFAULT_VALUES.salary_bonus}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputYup
              name={FORM_FIELDS.apply_suggest}
              label={t('managerCandidate.proposalToApply')}
              errors={errors}
              control={control}
              register={register}
              useWatch={useWatch}
              maxLength={Constants.MAX_LENGTH_150}
              defaultValue={DEFAULT_VALUES.apply_suggest}
            />
          </Grid>
          <Grid item xs={12}>
            {uDataPersonnel && (
              <SelectField
                name={FORM_FIELDS.group_id}
                label={t('managerCandidate.Personnel')}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                defaultValue={DEFAULT_VALUES.group_id}
                error={errors[FORM_FIELDS.group_id]?.message}
              >
                {uDataPersonnel?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Grid>
          <Grid item xs={12}>
            {uDataContractCategory && (
              <SelectField
                name={FORM_FIELDS.contract_category_id}
                label={t('managerCandidate.Contract')}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                error={errors[FORM_FIELDS.contract_category_id]?.message}
                defaultValue={DEFAULT_VALUES.contract_category_id}
              >
                {uDataContractCategory?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomInputYup
              name={FORM_FIELDS.contract_term}
              label={t('managerCandidate.contractTermExample')}
              errors={errors}
              control={control}
              register={register}
              useWatch={useWatch}
              maxLength={Constants.MAX_LENGTH_20}
              defaultValue={DEFAULT_VALUES.contract_term}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <DateDay
              label={t('managerCandidate.expectOnboardDate')}
              name={FORM_FIELDS.expect_date}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              defaultValue={DEFAULT_VALUES.expect_date}
              isShowModal={isShowModal}
              minDate={moment()}
              error={errors[FORM_FIELDS.expect_date]?.message}
              isRequired
            />
          </Grid>
        </Grid>
      </ModalComponent>
    </>
  );
};

export default ModalOffer;
