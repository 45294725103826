import React, { useRef, useState, useImperativeHandle } from 'react';
import fileUpload from 'assets/img/icons/file-upload.svg';
import { sendPost } from 'helpers/axios';
import styles from './styles.module.scss';

const AttachImage = ({ t, filesUpload, setLoading, onChangeDelete }, ref) => {
  const [listImgUpload, setListImgUpload] = useState([]);
  const currentFile = useRef(null);
  useImperativeHandle(ref, () => ({
    clearUploadFile: () => {
      setListImgUpload([]);
    },
    updateListImgUpload: (data) => {
      setListImgUpload([...data]);
    },
    setErrorFile: (data) => {
      setError('');
    },
  }));
  const [error, setError] = useState('');

  const handleUpload = async (e) => {
    setLoading(true);
    const maxFileUpload = 10240000; //10mb
    const files = e.target.files;
    const formData = new FormData();

    if (files?.length) {
      for (const file of files) {
        if (file.size > maxFileUpload) {
          setLoading(false);
          return setError(t('managerTest.uploadMaximum'));
        }
        if (!/(png|jpeg|jfif|jpg)$/.test(file.type)) {
          setLoading(false);
          return setError(t('managerTest.validImage'));
        }
        setError('');
        formData.append('file_img[]', file);

        const objectUrl = URL.createObjectURL(file);
        setListImgUpload((prev) => {
          const listOldImg = structuredClone(prev);
          listOldImg.push(objectUrl);
          return listOldImg;
        });
      }
      const res = await sendPost(`/api/question/upload-temp`, formData);
      const cloneFileUpload = structuredClone(filesUpload.current);
      cloneFileUpload.push(res.data.data);
      filesUpload.current = cloneFileUpload.flat();
    }
    setLoading(false);
  };
  const handleDeleteFile = (index) => {
    const newList = structuredClone(filesUpload.current);
    const oldList = structuredClone(listImgUpload);
    if (onChangeDelete) {
      onChangeDelete(oldList[index]);
    }
    oldList.splice(index, 1);
    setListImgUpload([...oldList]);
    newList.splice(index, 1);
    filesUpload.current = newList;
    currentFile.current.value = '';
  };

  return (
    <div className="attach-image ">
      <span className={`d-block ${styles.attachFileTitle}`}>{t('managerTest.attachImage')}</span>
      <div className="d-flex flex-wrap">
        {listImgUpload.map((item, index) => (
          <div className="file-upload position-relative d-flex justify-content-center" key={index}>
            <img src={item} alt="preview" className="w-100 " />
            <div className="close cursor center-image" onClick={handleDeleteFile.bind(this, index)} />
          </div>
        ))}
        <div className="file-upload position-relative d-flex align-items-center justify-content-center">
          <img src={fileUpload} alt="file upload" />
          <input
            type="file"
            name="file"
            onChange={handleUpload}
            ref={currentFile}
            multiple
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </div>
      </div>
      {error.length > 0 && <p className="errors">{error}</p>}
    </div>
  );
};

export default React.forwardRef(AttachImage);
