import React from 'react';

import { useGetDetailCandidate } from 'hook/useManagerCandidate';

import InfoCandidate from './components/InfoCandidate';
import InfoApply from './components/InfoApply';

const ColumnInfoCandidate = (props) => {
  const { candidateId, type } = props;

  const { data: dataCandidate, isLoading: isLoadingCandidate } = useGetDetailCandidate({
    id: candidateId,
    type: type,
  });

  return (
    <>
      <InfoCandidate data={dataCandidate} isLoading={isLoadingCandidate} />
      {dataCandidate?.list_applies.map((apply) => (
        <InfoApply data={apply} key={apply.apply_id} />
      ))}
    </>
  );
};

export default ColumnInfoCandidate;
