export const VIEW_PERMISSION = 2;
export const EDIT_PERMISSION = 3;
export const DELETE_PERMISSION = 4;

export const SYSTEM_ADMIN = 'System Admin';

export const ORDER_HR_PERMISSION = 'order_hr_permission';
export const JOB_PERMISSION = 'job_permission';
export const CANDIDATE_PERMISSION = 'candidate_permission';
export const JD_PERMISSION = 'JD_permission';
export const TEST_PERMISSION = 'test_permission';
export const INTERVIEW_QUESTIONS_PERMISSION = 'interview_questions_permission';
export const COMMISSION_PERMISSION = 'commission_permission';
export const STANDARD_PERMISSION = 'standard_permission';
export const DASHBOARD_PERMISSION = 'dashboard_recruit';
export const KPI_PERMISSION = 'recruit_performance_recruit';
export const LIST_REWARD_REF_PERMISSION = 'reward_recruit';
export const LIST_COST_RECRUIT_PERMISSION = 'cost_recruit';
export const GROUP_PERMISSION = 'group_permission';
export const MASTER_COST_PERMISSION = 'master_cost_recruit';
export const SCHOOL_RECRUIT = 'schools_recruit';
export const SOURCE_CV_RECRUIT = 'source_cv_recruit';

export const ROLE_VIEW = {
  HRM: 7,
  HR: 6,
  LEADER: 8,
  ASSIGNER_BY_LEADER: 9,
};

export const LIST_ROLE_ID = {
  RECRUIT_VIEW: 38,
};
