import React from 'react';
import { useTranslation } from 'react-i18next';

import iconHeader from 'assets/img/icons/dashboard/groupPeople.svg';
import LayoutBlock from '../components/layoutBlock';
import TotalNumberLine from '../components/TotalNumberLine';
import ListTotal from '../components/ListTotal';

import styles from './styles.module.scss';
import { getName } from 'helpers/index';
import { LIST_STATUS_JOB, STATUS_PROCESSING, STATUS_COMPLETED, STATUS_LATE_DEADLINE } from 'constants/manageJob';
import { useGetStatisticsByJob } from 'hook/useDashboard';

import Loading from 'components/Loading';

const StatisticsByJob = () => {
  const { t } = useTranslation();

  const { total_job, total_complete, total_late_deadline, total_processing, isLoading } = useGetStatisticsByJob();
  const listStatusJob = [
    { id: STATUS_PROCESSING, value: total_processing },
    { id: STATUS_COMPLETED, value: total_complete },
    { id: STATUS_LATE_DEADLINE, value: total_late_deadline },
  ];

  const formatFieldKeyList = (list) => {
    const newList = list.map((item) => {
      return { ...item, name: t(getName(LIST_STATUS_JOB, item.id)) };
    });
    return newList;
  };

  return (
    <LayoutBlock title={t('dashboard.statisticsByJob')} icon={iconHeader}>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div className={styles.totalJob}>
            <TotalNumberLine text={t('common.total')} number={total_job} className />
          </div>
          <ListTotal list={formatFieldKeyList(listStatusJob)} spacing={2} isJob />
        </>
      )}
    </LayoutBlock>
  );
};

export default StatisticsByJob;
