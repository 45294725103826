export const USE_GET_LIST_CANDIDATE = 'USE_GET_LIST_CANDIDATE';
export const USE_GET_LIST_YEAR_EXPERIENCE = 'USE_GET_LIST_YEAR_EXPERIENCE';
export const USE_GET_LIST_SCHOOL = 'USE_GET_LIST_SCHOOL';
export const USE_GET_LIST_SOURCE_CV = 'USE_GET_LIST_SOURCE_CV';
export const USE_GET_LIST_LANGUAGE = 'USE_GET_LIST_LANGUAGE';

export const USE_GET_DETAIL_CANDIDATE = 'USE_GET_DETAIL_CANDIDATE';
export const USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL = 'USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL';
export const USE_GET_LIST_LEADER_ASSESS_CANDIDATE = 'USE_GET_LIST_LEADER_ASSESS_CANDIDATE';
export const USE_GET_AVERAGE_ASSESS_CANDIDATE = 'USE_GET_AVERAGE_ASSESS_CANDIDATE';
export const USE_GET_LIST_ALL_STATUS_CANDIDATE = 'USE_GET_LIST_ALL_STATUS_CANDIDATE';
export const USE_GET_LIST_DIVISION_ACCEPT_CANDIDATE = 'USE_GET_LIST_DIVISION_ACCEPT_CANDIDATE';
export const USE_GET_ALL_DETAIL_INTERVIEW_CANDIDATE = 'USE_GET_ALL_DETAIL_INTERVIEW_CANDIDATE';
export const USE_GET_LIST_HR = 'USE_GET_LIST_HR';
export const USE_GET_LIST_LEADER_BOOK_INTERVIEW = 'USE_GET_LIST_LEADER_BOOK_INTERVIEW';

export const USE_GET_LIST_DIVISION = 'USE_GET_LIST_DIVISION';
export const USE_GET_LIST_TEST = 'USE_GET_LIST_TEST';
export const USE_GET_LIST_ASSESS = 'USE_GET_LIST_ASSESS';
export const USE_GET_ROOM_LIST = 'USE_GET_ROOM_LIST';

export const LIST_STATUS = [
  { name: 'Tạo request', id: 1 },
  { name: 'Duyệt request', id: 2 },
  { name: 'Không duyệt request', id: 3 },
  { name: 'Hủy order', id: 4 },
  { name: 'Add Job', id: 5 },
  { name: 'Từ chối order', id: 6 },
  { name: 'Xóa order khỏi job', id: 7 },
  { name: 'Hoàn thành order', id: 8 },
  { name: 'Thêm ứng viên', id: 9 },
  { name: 'Không qua sơ tuyển', id: 10, class: 'status-red' },
  { name: 'Qua sơ tuyển', id: 13, class: 'status-blue' },
  { name: 'Chờ leader duyệt CV', id: 16, class: 'status-blue' },
  { name: 'Leader không duyệt CV', id: 19, class: 'status-red' },
  { name: 'Chờ UV phản hồi lời mời', id: 22, class: 'status-blue' },
  { name: 'UV từ chối PV', id: 25, class: 'status-red' },
  { name: 'Đang chờ PV', id: 28, class: 'status-blue' },
  { name: 'Leader không tham gia PV', id: 31, class: 'status-red' },
  { name: 'Hủy PV', id: 34, class: 'status-red' },
  { name: 'Chờ leader duyệt UV', id: 37, class: 'status-blue' },
  { name: 'Chờ leader Offer', id: 40, class: 'status-blue' },
  { name: 'Leader không duyệt UV', id: 43, class: 'status-red' },
  { name: 'Chờ UV phản hồi Offer', id: 45, class: 'status-blue' },
  { name: 'Từ chối Offer', id: 46, class: 'status-red' },
  { name: 'Chờ phân bổ phòng ban', id: 49, class: 'status-green' },
  { name: 'UV đồng ý offer', id: 50, class: 'status-green' },
  { name: 'UV không onboard', id: 53, class: 'status-red' },
  { name: 'Onboard', id: 56, class: 'status-green' },
  { name: 'Kết thúc tuyển dụng', id: 60, class: 'status-red' },
  { name: 'Thay đổi người assign', id: 61 },
  // { name: 'Nhân viên Thử việc', id: 52, class: 'status-green' },
  // { name: 'Không qua thử việc', id: 55, class: 'status-red' },
  // { name: 'Nghỉ ngang thử việc', id: 58, class: 'status-red' },
  // { name: 'Nhân viên chính thức', id: 61, class: 'status-green' }, // qua thử việc
  // { name: 'Nhân viên chính thức', id: 64, class: 'status-green' }, // phân bổ
  { name: 'Hoàn thành order', id: 29 },
  { name: 'Trễ deadline', id: 30 },
];

export const LIST_YEAR_EXPERIENCE = [
  { name: 'Dưới 6 tháng', id: 1 },
  { name: '6-12 tháng', id: 2 },
  { name: '1-2 năm', id: 3 },
  { name: '2 năm', id: 4 },
  { name: '3 năm', id: 5 },
  { name: '4 năm', id: 6 },
  { name: '5 năm', id: 7 },
  { name: '6 năm', id: 8 },
  { name: '7 năm', id: 9 },
  { name: '8 năm', id: 10 },
  { name: '9 năm', id: 11 },
  { name: '10 năm', id: 12 },
  { name: 'Trên 10 năm ', id: 13 },
  { name: 'N/A', id: 14 },
];

export const LIST_APPLICATION = [
  { name: 'Thử việc full lương', id: 1 },
  { name: 'Linh động thời gian làm việc', id: 2 },
  { name: 'Cho phép làm việc từ xa', id: 3 },
];

export const LIST_SCORES = [
  { name: 'Tốt – 10 điểm', id: 10 },
  { name: 'Tốt – 9 điểm', id: 9 },
  { name: 'Tốt – 8 điểm', id: 8 },
  { name: 'Khá – 7 điểm', id: 7 },
  { name: 'Khá – 6 điểm', id: 6 },
  { name: 'Khá – 5 điểm', id: 5 },
  { name: 'Trung bình - 4 điểm', id: 4 },
  { name: 'Trung bình – 3 điểm', id: 3 },
  { name: 'Trung bình – 2 điểm', id: 2 },
  { name: 'Trung bình – 1 điểm', id: 1 },
];

export const STATUS_CANDIDATE = {
  CREATE_REQUEST: 1,
  CONFIRM_REQUEST: 2,
  CANCEL_REQUEST: 3,
  CANCEL_ODER: 4,
  ADD_JOB: 5,
  REFUSE_ODER: 6,
  DELETE_ODER_FOR_JOB: 7,
  COMPLETE_ODER: 8,
  ADD_CANDIDATE: 9,
  FAIL_PRE_QUALIFICATION: 10,
  PASS_PRE_QUALIFICATION: 13,
  WAITING_FOR_LEADER_APPROVE_CV: 16,
  CANCEL_CV: 19,
  WAIT_CANDIDATE_RESPONSE: 22,
  CANDIDATE_REFUSE_INTERVIEW: 25,
  WAITING_INTERVIEW: 28,
  LEADER_CANCEL_INTERVIEW: 31,
  HR_CANCEL_INTERVIEW: 34,
  WAITING_LEADER_CONFIRM_CANDIDATE: 37,
  LEADER_RATED_AND_HAS_NOT_CONFIRMED_OFFER: 40,
  LEADER_REFUSE_CANDIDATE: 43,
  WAITING_CANDIDATE_RESPONSE_OFFER: 45,
  REFUSE_OFFER: 46,
  // OFFER_SUCCESS: 49,
  WAIT_FOR_ALLOCATION: 49,
  CANDIDATE_AGREE_OFFER: 50,
  CANDIDATE_CANCEL_ONBOARD: 53,
  ONBOARD: 56,
  END_PROCESS_RECRUIT: 60,
  CHANGE_ASSIGNER: 61,
  // PASS_PROBATION: 52,
  // NOT_PASS_PROBATION: 55,
  // CEASE_PROBATION: 58,
  // CLICK_BUTTON_COMPLETE_PROBATION: 61,
  // OFFICIAL_STAFF: 64,
  ORDER_SUCCESS: 29,
  LATE_DEADLINE: 30,
};
export const TRAINING = 2;
export const OFFICIAL_STAFF = 1;

export const NOT_HAS_COMMIT = 0;
export const HAS_COMMIT = 1;

export const indefinite_contract = 2;
export const MALE = 1;
export const FEMALE = 2;
export const SEX_THIRD = 3;
export const INDEX_FIRST = 0;
export const STATUS_NOT_PASS_PRE_QUALIFICATION = 1;
export const STATUS_PASS_PRE_QUALIFICATION = 2;

export const ACTIVE_LIST_APPLIES = 1;

export const TYPE_CANDIDATE_LEADER = 1;
export const TYPE_CANDIDATE_HR = 2;

export const CANDIDATE_NOT_RATED_YET = 0;
export const CANDIDATE_RATING_PASS = 1;
export const CANDIDATE_RATING_FAIL = 2;
export const CANDIDATE_RATING_WAIT_OFFER = 3;

export const LIST_STATUS_TEST = [
  { name: 'Chưa làm bài', id: 1 },
  { name: 'Đang làm bài', id: 2 },
  { name: 'Đã hoàn thành', id: 3 },
  { name: 'Đã hoàn thành', id: 4 },
];

export const STATUS_TEST = {
  INCOMPLETE: [1, 2],
  COMPLETE: [3, 4],
};

export const STATUS_HAVE_NOT_STARTED_TEST = 1;

export const LEADER_ASSESS_INTERVIEW_NOT_YET = 0;
export const LEADER_ASSESS_INTERVIEW_PASS = 1;
export const LEADER_ASSESS_INTERVIEW_FAIL = 2;

export const LEADER_ASSESS_INTERVIEW_STATUS = [
  { name: 'Chưa đánh giá', id: LEADER_ASSESS_INTERVIEW_NOT_YET },
  { name: 'Pass', id: LEADER_ASSESS_INTERVIEW_PASS },
  { name: 'Fail', id: LEADER_ASSESS_INTERVIEW_FAIL },
];

export const SOURCE_CV_TEXT = ['thuê ngoài', 'cộng tác viên', 'headhunt'];

export const HR_TAB_INFO = 'HR_TAB_INFO';
export const HR_TAB_TEST = 'HR_TAB_TEST';
export const HR_TAB_QUESTION = 'HR_TAB_QUESTION';
export const HR_TAB_HISTORY_INTERVIEW = 'HR_TAB_HISTORY_INTERVIEW';
export const LEADER_TAB_INFO = 'LEADER_TAB_INFO';
export const LEADER_TAB_TEST = 'LEADER_TAB_TEST';
export const LEADER_TAB_QUESTION = 'LEADER_TAB_QUESTION';
export const LEADER_TAB_HISTORY_INTERVIEW = 'LEADER_TAB_HISTORY_INTERVIEW';

export const TAB_COMPARE_CANDIDATE = 'TAB_COMPARE_CANDIDATE';
export const TAB_COMPARE_INTERVIEW = 'TAB_COMPARE_INTERVIEW';

export const PROGRESS_BAR_INDEX = 100;
