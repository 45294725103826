export const BG_LEVEL = [
  '#2ECC71',
  '#FEA628',
  '#223354',
  '#FFF8EE',
  '#E74C3C',
  '#222433',
  '#0038FF',
  '#526487',
  '#FF91AF',
  '#D0FF14',
];
export const BG_POSITION = [
  '#FEA628',
  '#223354',
  '#526487',
  '#222433',
  '#2ECC71',
  '#0038FF',
  '#E74C3C',
  '#FF91AF',
  '#DA1884',
  '#5959c1',
  '#7C0A02',
  '#FFF8ED',
  '#008000',
  '#8DB600',
  '#00FFFF',
  '#D0FF14',
  '#8F9779',
  '#E9D66B',
];
export const BG_CONTRACT_CATEGORY_CONTRACT = [
  '#FEA628',
  '#E74C3C',
  '#223354',
  '#FFF8ED',
  '#2ECC71',
  '#0038FF',
  '#E74C3C',
  '#008000',
  '#8DB600',
  '#FBCEB1',
  '#00FFFF',
  '#7FFFD4',
  '#D0FF14',
  '#4B5320',
  '#8F9779',
  '#E9D66B',
];
export const BG_CONTRACT_CATEGORY_EMPLOYEES = ['#FEA628', '#2ECC71', '#223354', '#E74C3C', '#0038FF', '#E74C3C'];

export const BG_TIMEKEEPING_TIME_LATE_OT = ['#E74C3C', '#3DB2FF'];
export const COLOR_TOOLTIP_TIMEKEEPING_TIME_LATE_OT = '#223354';
export const BG_TOOLTIP_TIMEKEEPING_TIME_LATE_OT = '#F3F7FB';

export const COLOR_LATE_OT_COMPANY_TIMEKEEPING = '#223354';
export const BG_LATE_OT_COMPANY_TIMEKEEPING = [
  '#FEA628',
  '#223354',
  '#66D997',
  '#FFDBA9',
  '#8D8D8D',
  '#222433',
  '#FE7BB0',
  '#0057FF',
  '#526487',
  '#E9D66B',
  '#00FFFF',
  '#7FFFD4',
];
