import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import iconUpdateWishes from 'assets/img/icons/candidate/updateWhisesCandidate.svg';
import styles from './styles.module.scss';
import { getNameReturnHollow } from 'helpers/index';
import { formatSalary } from 'helpers/format';
import { getLevelName } from 'helpers/masterData';
import { useGetListDivision } from 'hook/useDivision';
import { useListPersonnel, useListContractCategory } from 'hook/useMasterData';
import EditLayout from 'components/PermissionPage/EditLayout';
import { CANDIDATE_PERMISSION } from 'constants/permission';
import { STATUS_CANDIDATE } from 'constants/ManagerCandidate';

import { customDate } from 'helpers/formatDate';

const InfoCandidate = ({ uApplyCandidate, setIsShowModal, uDataLevel, isHr }) => {
  const { t } = useTranslation();
  const { data: uListDivision } = useGetListDivision();
  const { data: uDataContractCategory } = useListContractCategory();
  const { data: uDataPersonnel } = useListPersonnel();
  return (
    <Grid container spacing={1} className={styles.containerInfoCandidate}>
      <Grid md={12} lg={12} className={styles.containerInfo}>
        <span className={styles.candidateName}>{uApplyCandidate?.full_name}</span>
        {isHr &&
        uApplyCandidate.status > STATUS_CANDIDATE.WAIT_FOR_ALLOCATION &&
        uApplyCandidate.status <= STATUS_CANDIDATE.CANDIDATE_AGREE_OFFER ? (
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button onClick={() => setIsShowModal(true)} className={styles.buttonEditInformation}>
              <img className="imgIconUpdateWishes" alt="updateWishesCandidate" src={iconUpdateWishes} />
            </button>
          </EditLayout>
        ) : (
          ''
        )}
      </Grid>
      <Grid md={12} lg={12} className={styles.containerInfo}>
        <span className={styles.titleItem}>{t('managerCandidate.jobName')}</span>
        <span className={styles.contentItem}>{uApplyCandidate?.job_name}</span>
      </Grid>
      <Grid md={12} lg={12} className={styles.containerInfo}>
        <span className={styles.titleItem}>{t('managerJob.HRChargeOf')}</span>
        <span className={styles.contentItem}>{uApplyCandidate?.hr_info?.name}</span>
      </Grid>

      {uApplyCandidate?.level_official_id && (
        <>
          <Grid md={12} lg={12} className={styles.containerInfo}>
            <span className={styles.titleItem}>{t('common.division')}</span>
            <span className={styles.contentItem}>
              {getNameReturnHollow(uListDivision, uApplyCandidate?.division_id)}
            </span>
          </Grid>
          <Grid md={12} lg={12} className={styles.containerInfo}>
            <span className={styles.titleItem}>{t('managerCandidate.officialLevel')}</span>
            <span className={styles.contentItem}>
              {uApplyCandidate?.level_official_id
                ? getLevelName(uApplyCandidate?.level_official_id, uDataLevel)
                : t('common.notDataContract')}
            </span>
          </Grid>
          <Grid md={12} lg={12} className={styles.containerInfo}>
            <span className={styles.titleItem}>{t('managerCandidate.startingSalary')}</span>
            <span className={styles.contentItem}>{formatSalary(Math.ceil(uApplyCandidate?.starting_salary))}</span>
          </Grid>
          <Grid md={12} lg={12} className={styles.containerInfo}>
            <span className={styles.titleItem}>{t('managerCandidate.salaryOfficial')}</span>
            <span className={styles.contentItem}>{formatSalary(Math.ceil(uApplyCandidate?.salary))}</span>
          </Grid>
          <Grid md={12} lg={12} className={styles.containerInfo}>
            <span className={styles.titleItem}>{t('managerCandidate.personnel')}</span>
            <span className={styles.contentItem}>
              {uApplyCandidate?.group_id
                ? getLevelName(uApplyCandidate?.group_id, uDataPersonnel)
                : t('common.notDataContract')}
            </span>
          </Grid>
          <Grid md={12} lg={12} className={styles.containerInfo}>
            <span className={styles.titleItem}>{t('managerCandidate.contract')}</span>
            <span className={styles.contentItem}>
              {uApplyCandidate?.contract_category_id
                ? getLevelName(uApplyCandidate?.contract_category_id, uDataContractCategory)
                : t('common.notDataContract')}
            </span>
          </Grid>

          <Grid md={12} lg={12} className={styles.containerInfo}>
            <span className={styles.titleItem}>{t('managerCandidate.startContract')}</span>
            <span className={styles.contentItem}>{customDate(uApplyCandidate?.start_date, 'DD/MM/YYYY')}</span>
          </Grid>
          <Grid md={12} lg={12} className={styles.containerInfo}>
            <span className={styles.titleItem}>{t('managerCandidate.endContract')}</span>
            <span className={styles.contentItem}>{customDate(uApplyCandidate?.end_date, 'DD/MM/YYYY')}</span>
          </Grid>
          <Grid md={12} lg={12} className={styles.containerInfo}>
            <span className={styles.titleItem}>{t('managerCandidate.trainCommit')}</span>
            <span className={styles.contentItem}>
              {uApplyCandidate?.has_commit ? t('managerCandidate.hasCommit') : t('managerCandidate.notHasCommit')}
            </span>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default InfoCandidate;
