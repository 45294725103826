import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip, ClickAwayListener } from '@mui/material';

import './styles.scss';
import styles from './styles.module.scss';
import squareIcon from 'assets/img/icons/candidate/square.svg';
import noteAssessIcon from 'assets/img/icons/candidate/noteAssess.svg';
import avatarDefault from 'assets/img/icons/default-avatar.svg';
import { HiPencil } from 'react-icons/hi';
import { ThemContext } from 'layouts/Admin';

import DemoRadar from '../../RattingApply/chartRada';
import ModalOffer from '../Components/ModalOffer';
import ModalCandidateAssessment from '../ModalCandidateAssessment';
import EditLayout from 'components/PermissionPage/EditLayout';

import { getLevelName } from 'helpers/masterData';
import { customDate } from 'helpers/formatDate';
import { handleOnError, getName } from 'helpers/index';
import { formatSalary } from 'helpers/format';
import { useGetApplyCandidate } from 'hook/useCandidateApply';
import { useListLevel, useListPersonnel, useListContractCategory } from 'hook/useMasterData';

import {
  CANDIDATE_RATING_PASS,
  CANDIDATE_RATING_FAIL,
  CANDIDATE_RATING_WAIT_OFFER,
  TYPE_CANDIDATE_LEADER,
  STATUS_CANDIDATE,
} from 'constants/ManagerCandidate';
import { CANDIDATE_PERMISSION } from 'constants/permission';

const DetailApplyLeader = ({ dataStatusPass, applyId, uGetListAssess }) => {
  const { t } = useTranslation();
  const { dataInfoUser } = useContext(ThemContext);

  const [showModalOffer, setShowModalOffer] = useState(false);
  const [showModalCandidateAssessment, setShowModalCandidateAssessment] = useState(false);
  const [openNoteAssess, setOpenNoteAssess] = useState([]);

  const dataLeaderPass = dataStatusPass?.user_info;
  const dataLeaderScore = dataStatusPass?.list_stats;
  const dataAssignerScore = dataStatusPass?.user_assigned_info;
  const { data: uApplyCandidate } = useGetApplyCandidate(applyId, {
    type: TYPE_CANDIDATE_LEADER,
  });

  const { data: uDataLevel } = useListLevel();
  const { data: uDataContractCategory } = useListContractCategory();
  const { data: uDataPersonnel } = useListPersonnel();

  useEffect(() => {
    if (dataLeaderScore) {
      setOpenNoteAssess(Array(dataLeaderScore?.length).fill(false));
    }
  }, [dataLeaderScore]);

  const CountAvgScore = () => {
    let sumScoreAvg = 0;
    let countScoreAvg = 0;
    for (let e = 0; e < dataStatusPass?.list_stats.length; e++) {
      const element = dataStatusPass?.list_stats[e];
      if (element.type === 'score_avg') {
        const sumType = element.score * element.coefficient;
        sumScoreAvg += sumType;
        countScoreAvg += element.coefficient;
      }
    }
    const result = sumScoreAvg / countScoreAvg;
    return (
      <Grid container className={styles.contentScore}>
        <div className="div-ratting-index">
          <span className={styles.avgScorePass}>{t('managerCandidate.avgPoint')}</span>
        </div>
        <div className={styles.scoreAvg}>
          <span className="avg-score">{result.toFixed(1)}</span>
          <span className="avg-score-stand">
            /<CountAvgScoreStandard />
          </span>
        </div>
      </Grid>
    );
  };

  const CountAvgScoreStandard = () => {
    let sumScoreAvg = 0;
    let countScoreAvg = 0;
    for (let e = 0; e < dataStatusPass?.list_stats.length; e++) {
      const element = dataStatusPass?.list_stats[e];
      if (element.type === 'score_stand') {
        const sumType = element.score * element.coefficient;
        sumScoreAvg += sumType;
        countScoreAvg += element.coefficient;
      }
    }
    const result = sumScoreAvg / countScoreAvg;
    return <span> {result.toFixed(1)}</span>;
  };

  return (
    <>
      {dataStatusPass?.result !== CANDIDATE_RATING_WAIT_OFFER && (
        <div className={styles.bodyDetailLeader}>
          <div
            className={`${styles.divDetailLeader} ${
              dataStatusPass?.result !== CANDIDATE_RATING_PASS ? styles.divDetailLeaderFail : ''
            }`}
          >
            <Grid container className={styles.detailLeaderPass}>
              <Grid container>
                <div className={styles.boxInfoHeader}>
                  <div className={styles.boxInfoUser}>
                    <img
                      className={styles.avatarLeaderPass}
                      alt="avatar"
                      src={dataLeaderPass?.avatar ? dataLeaderPass.avatar : avatarDefault}
                      onError={handleOnError}
                    />
                    <div>
                      <div className="div-name-leaderPass">{dataLeaderPass?.name}</div>
                      <div className="div-name-division-leaderPass">
                        {dataLeaderPass?.position_name} - {dataStatusPass?.division_name}
                      </div>
                    </div>
                  </div>

                  {dataStatusPass?.date_offer ? (
                    <div className="styleDateAssess">
                      {t('managerCandidate.dateAssess')}: {customDate(dataStatusPass?.date_offer, 'DD/MM/YYYY')}
                    </div>
                  ) : null}
                  {dataStatusPass?.result === CANDIDATE_RATING_PASS &&
                    dataStatusPass?.salary &&
                    dataInfoUser.user_id === dataLeaderPass.user_id &&
                    (uApplyCandidate.status === STATUS_CANDIDATE.WAITING_CANDIDATE_RESPONSE_OFFER ||
                      uApplyCandidate.status === STATUS_CANDIDATE.WAIT_FOR_ALLOCATION) && (
                      <EditLayout permissionName={CANDIDATE_PERMISSION}>
                        <div className="circle-edit-offer-button color-button" onClick={() => setShowModalOffer(true)}>
                          <HiPencil />
                        </div>
                      </EditLayout>
                    )}
                </div>
              </Grid>
              {dataStatusPass?.result !== CANDIDATE_RATING_WAIT_OFFER && (
                <div
                  className={`${styles.leaderRatePass} ${
                    dataStatusPass?.result === CANDIDATE_RATING_FAIL ? styles.leaderRateFail : ''
                  }`}
                >
                  {dataStatusPass?.result === CANDIDATE_RATING_PASS
                    ? t('managerCandidate.browse')
                    : t('managerCandidate.doNotBrowse')}
                </div>
              )}
            </Grid>
            {dataStatusPass?.result === CANDIDATE_RATING_PASS && dataStatusPass?.salary && (
              <>
                <Grid container pr={1} pl={2}>
                  <Grid xs={4}>
                    <span className="title-offer-leaderPass">{t('managerCandidate.officialLevel')}</span>
                  </Grid>
                  <Grid xs={3.5}>
                    <span className="title-offer-leaderPass">{t('managerCandidate.startingSalary')}</span>
                  </Grid>
                  <Grid xs={4.5}>
                    <span className="title-offer-leaderPass">{t('managerCandidate.Personnel')}</span>
                  </Grid>
                </Grid>
                <Grid container pr={1} pl={2}>
                  <Grid xs={4}>
                    <span className="content-offer-leaderPass">
                      {getLevelName(dataStatusPass?.level_official_id, uDataLevel)}
                    </span>
                  </Grid>
                  <Grid xs={3.5}>
                    <span className="content-offer-leaderPass">{formatSalary(dataStatusPass?.starting_salary)}</span>
                  </Grid>
                  <Grid xs={4.5}>
                    <span className="content-offer-leaderPass">
                      {getLevelName(dataStatusPass?.group_id, uDataPersonnel)}
                    </span>
                  </Grid>
                </Grid>
                <Grid container pr={1} pl={2} className="mt-10">
                  <Grid xs={4}>
                    <span className="title-offer-leaderPass w-full">{t('managerCandidate.expectUVOnboardDate')}</span>
                  </Grid>
                  <Grid xs={3.5}>
                    <span className="title-offer-leaderPass">{t('managerCandidate.salaryOfficial')}</span>
                  </Grid>
                  <Grid xs={4.5}>
                    <span className="title-offer-leaderPass">{t('managerCandidate.Contract')}</span>
                  </Grid>
                </Grid>
                <Grid container pr={1} pl={2}>
                  <Grid xs={4}>
                    <span className="content-offer-leaderPass">
                      {customDate(dataStatusPass?.expect_date, 'DD/MM/YYYY')}
                    </span>
                  </Grid>
                  <Grid xs={3.5}>
                    <span className="content-offer-leaderPass">{formatSalary(dataStatusPass?.salary)}</span>
                  </Grid>
                  <Grid className="div-content-salary-leaderPass " xs={4.5} pr={2}>
                    <span className="content-offer-leaderPass">
                      {getLevelName(dataStatusPass?.contract_category_id, uDataContractCategory)}
                    </span>
                  </Grid>
                </Grid>
                <Grid container pr={1} pl={2} className="mt-10">
                  <Grid xs={4}>
                    <span className="title-offer-leaderPass">{t('managerCandidate.proposalToApply')}</span>
                  </Grid>
                  <Grid xs={3.5}>
                    <span className="title-offer-leaderPass">{t('managerCandidate.salaryBonus')}</span>
                  </Grid>
                  <Grid xs={4.5}>
                    <span className="title-offer-leaderPass">{t('managerCandidate.contractTerm')}</span>
                  </Grid>
                </Grid>
                <Grid container pr={1} pl={2}>
                  <Grid xs={4} pr={2} className="apply-suggest-tooltip">
                    <Tooltip
                      title={<span className="tooltip-item">{dataStatusPass?.apply_suggest}</span>}
                      PopperProps={{
                        sx: {
                          maxWidth: 300,
                          '& 	.MuiTooltip-tooltip': {
                            marginTop: '20px !important',
                            backgroundColor: '#F3F7FB !important',
                          },
                        },
                      }}
                      followCursor
                    >
                      <span className="content-offer-leaderPass">{dataStatusPass?.apply_suggest}</span>
                    </Tooltip>
                  </Grid>
                  <Grid xs={3.5}>
                    <span className="content-offer-leaderPass">{formatSalary(dataStatusPass?.salary_bonus)}</span>
                  </Grid>
                  <Grid className="div-content-salary-leaderPass " xs={4.5} pr={2}>
                    <span className="content-offer-leaderPass">{dataStatusPass?.contract_term}</span>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
          {dataStatusPass.user_assigned_id ? (
            <Grid container className={styles.detailLeaderPass}>
              <Grid container>
                <div className={styles.boxInfoHeader}>
                  <div className={styles.boxInfoUser}>
                    <img
                      className={styles.avatarLeaderPass}
                      alt="avatar"
                      src={dataAssignerScore?.avatar ? dataAssignerScore.avatar : avatarDefault}
                      onError={handleOnError}
                    />
                    <div>
                      <div className="div-name-leaderPass">
                        {dataAssignerScore?.name} -
                        {t('managerCandidate.authorityName', { nameLeader: dataLeaderPass?.name })}
                      </div>
                      <div className="div-name-division-leaderPass">
                        {dataAssignerScore?.position_name} - {dataStatusPass?.division_name}
                      </div>
                    </div>
                  </div>

                  {dataStatusPass?.date_assess ? (
                    <div className="styleDateAssessAssigner">
                      {t('managerCandidate.dateAssess')}: {customDate(dataStatusPass?.date_assess, 'DD/MM/YYYY')}
                    </div>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={styles.detailLeaderPass}>
              <Grid container>
                <div className={styles.boxInfoAccess}>
                  {dataStatusPass?.date_assess ? (
                    <div className="styleDateAssessAssigner">
                      {t('managerCandidate.dateAssess')}: {customDate(dataStatusPass?.date_assess, 'DD/MM/YYYY')}
                    </div>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          )}
          {!!dataStatusPass?.list_stats.length && (
            <Grid container className="body-leaderPass">
              <Grid xs={6} className="chart-radar-leaderPass">
                <DemoRadar list_stats={dataStatusPass?.list_stats} />
              </Grid>
              {(!dataStatusPass.user_assigned_id
                ? dataInfoUser.user_id === dataLeaderPass.user_id
                : dataInfoUser.user_id === dataStatusPass.user_assigned_id) &&
                uApplyCandidate.status > STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE &&
                uApplyCandidate.status < STATUS_CANDIDATE.CANDIDATE_AGREE_OFFER && (
                  <EditLayout permissionName={CANDIDATE_PERMISSION}>
                    <div
                      className="circle-edit-offer-button color-button "
                      onClick={() => setShowModalCandidateAssessment(true)}
                    >
                      <HiPencil />
                    </div>
                  </EditLayout>
                )}
              <Grid xs={6} className={styles.standardIndexContainer}>
                <div className={styles.groupStandardIndex}>
                  {dataLeaderScore &&
                    dataLeaderScore.map((item, index) => {
                      if (index % 2 === 0)
                        return (
                          <Grid container className="div-content-score">
                            <Grid item xs={10} className={styles.nameStandardIndex}>
                              <img alt="square" className="icon-square" src={squareIcon} />
                              <span>{item.name}</span>
                              {item?.note && (
                                <ClickAwayListener
                                  onClickAway={() =>
                                    setOpenNoteAssess(
                                      openNoteAssess.map((item, subIndex) => {
                                        if (subIndex === index) return false;
                                        return item;
                                      }),
                                    )
                                  }
                                >
                                  <div className="d-inline">
                                    <Tooltip
                                      open={openNoteAssess.length > 0 ? openNoteAssess[index] : false}
                                      title={
                                        <div className={styles.noteAssessContainer}>
                                          <div className={styles.title}>{item.name}</div>
                                          <div className={styles.content}>{item.note}</div>
                                        </div>
                                      }
                                    >
                                      <img
                                        alt={noteAssessIcon}
                                        src={noteAssessIcon}
                                        className={styles.noteAssessBtn}
                                        onClick={() =>
                                          setOpenNoteAssess(
                                            openNoteAssess.map((item, subIndex) => {
                                              if (subIndex === index) return !item;
                                              return item;
                                            }),
                                          )
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                </ClickAwayListener>
                              )}
                            </Grid>
                            <Grid item xs={2} className="div-score-index">
                              <span className="span-score-index-avg">{item.score}</span>
                              <span className="span-score-index-stand">/{dataLeaderScore.at(index + 1).score}</span>
                            </Grid>
                          </Grid>
                        );
                      return null;
                    })}
                  <CountAvgScore />
                </div>
              </Grid>
            </Grid>
          )}
          {(!dataStatusPass.user_assigned_id
            ? dataInfoUser.user_id === dataLeaderPass.user_id
            : dataInfoUser.user_id === dataStatusPass.user_assigned_id) &&
            uApplyCandidate.status > STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE &&
            uApplyCandidate.status < STATUS_CANDIDATE.CANDIDATE_AGREE_OFFER &&
            !dataStatusPass?.list_stats.length &&
            dataStatusPass?.note && (
              <EditLayout permissionName={CANDIDATE_PERMISSION}>
                <div
                  className="circle-edit-offer-button color-button"
                  onClick={() => setShowModalCandidateAssessment(true)}
                >
                  <HiPencil />
                </div>
              </EditLayout>
            )}
          <div className={styles.containerNoteAssess}>
            <span className={styles.generalAssessment}>{t('managerCandidate.assessLevel')}</span>
            <span className={styles.contentGeneralAssessment}>
              {getName(uDataLevel, dataStatusPass?.level_assess_id)}
            </span>
          </div>
          {dataStatusPass?.note && (
            <div className={styles.containerNoteAssess}>
              <div className={styles.generalAssessment}>{t('managerCandidate.generalAssessment')}</div>
              <div className={styles.contentGeneralAssessment}>{dataStatusPass?.note}</div>
            </div>
          )}
        </div>
      )}
      {dataStatusPass?.result === CANDIDATE_RATING_WAIT_OFFER &&
        (dataStatusPass?.note || !!dataStatusPass?.list_stats.length) && (
          <div className={styles.bodyDetailLeader}>
            <div
              className={`${styles.divDetailLeader} ${
                dataStatusPass?.result !== CANDIDATE_RATING_PASS ? styles.divDetailLeaderFail : ''
              }`}
            >
              <Grid container className={styles.detailLeaderPass}>
                <Grid container>
                  <div className={styles.boxInfoHeader}>
                    {!dataStatusPass.user_assigned_id ? (
                      <div className={styles.boxInfoUser}>
                        <img
                          className={styles.avatarLeaderPass}
                          alt="avatar"
                          src={dataLeaderPass?.avatar ? dataLeaderPass.avatar : avatarDefault}
                          onError={handleOnError}
                        />
                        <div>
                          <div className="div-name-leaderPass">{dataLeaderPass?.name}</div>
                          <div className="div-name-division-leaderPass">
                            {dataLeaderPass?.position_name} - {dataStatusPass?.division_name}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.boxInfoUser}>
                        <img
                          className={styles.avatarLeaderPass}
                          alt="avatar"
                          src={dataAssignerScore?.avatar ? dataAssignerScore.avatar : avatarDefault}
                          onError={handleOnError}
                        />
                        <div>
                          <div className="div-name-leaderPass">
                            {dataAssignerScore?.name} -
                            {t('managerCandidate.authorityName', { nameLeader: dataLeaderPass?.name })}
                          </div>
                          <div className="div-name-division-leaderPass">
                            {dataAssignerScore?.position_name} - {dataStatusPass?.division_name}
                          </div>
                        </div>
                      </div>
                    )}
                    {dataStatusPass?.date_assess ? (
                      <div className="styleDateAssessAssigner">
                        {t('managerCandidate.dateAssess')}: {customDate(dataStatusPass?.date_assess, 'DD/MM/YYYY')}
                      </div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </div>
            {!!dataStatusPass?.list_stats.length && (
              <Grid container className="body-leaderPass">
                <Grid xs={6} className="chart-radar-leaderPass">
                  <DemoRadar list_stats={dataStatusPass?.list_stats} />
                </Grid>
                {(!dataStatusPass.user_assigned_id
                  ? dataInfoUser.user_id === dataLeaderPass.user_id
                  : dataInfoUser.user_id === dataStatusPass.user_assigned_id) &&
                  uApplyCandidate.status > STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE &&
                  uApplyCandidate.status < STATUS_CANDIDATE.CANDIDATE_AGREE_OFFER && (
                    <EditLayout permissionName={CANDIDATE_PERMISSION}>
                      <div
                        className="circle-edit-offer-button color-button "
                        onClick={() => setShowModalCandidateAssessment(true)}
                      >
                        <HiPencil />
                      </div>
                    </EditLayout>
                  )}
                <Grid xs={6} className={styles.standardIndexContainer}>
                  <div className={styles.groupStandardIndex}>
                    {dataLeaderScore &&
                      dataLeaderScore.map((item, index) => {
                        if (index % 2 === 0)
                          return (
                            <Grid container className="div-content-score">
                              <Grid item xs={10} className={styles.nameStandardIndex}>
                                <img alt="square" className="icon-square" src={squareIcon} />
                                <span>{item.name}</span>
                                {item?.note && (
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      setOpenNoteAssess(
                                        openNoteAssess.map((item, subIndex) => {
                                          if (subIndex === index) return false;
                                          return item;
                                        }),
                                      )
                                    }
                                  >
                                    <div className="d-inline">
                                      <Tooltip
                                        open={openNoteAssess.length > 0 ? openNoteAssess[index] : false}
                                        title={
                                          <div className={styles.noteAssessContainer}>
                                            <div className={styles.title}>{item.name}</div>
                                            <div className={styles.content}>{item.note}</div>
                                          </div>
                                        }
                                      >
                                        <img
                                          alt={noteAssessIcon}
                                          src={noteAssessIcon}
                                          className={styles.noteAssessBtn}
                                          onClick={() =>
                                            setOpenNoteAssess(
                                              openNoteAssess.map((item, subIndex) => {
                                                if (subIndex === index) return !item;
                                                return item;
                                              }),
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                  </ClickAwayListener>
                                )}
                              </Grid>
                              <Grid item xs={2} className="div-score-index">
                                <span className="span-score-index-avg">{item.score}</span>
                                <span className="span-score-index-stand">/{dataLeaderScore.at(index + 1).score}</span>
                              </Grid>
                            </Grid>
                          );
                        return null;
                      })}
                    <CountAvgScore />
                  </div>
                </Grid>
              </Grid>
            )}
            {(!dataStatusPass.user_assigned_id
              ? dataInfoUser.user_id === dataLeaderPass.user_id
              : dataInfoUser.user_id === dataStatusPass.user_assigned_id) &&
              uApplyCandidate.status > STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE &&
              uApplyCandidate.status < STATUS_CANDIDATE.CANDIDATE_AGREE_OFFER &&
              !dataStatusPass?.list_stats.length &&
              dataStatusPass?.note && (
                <EditLayout permissionName={CANDIDATE_PERMISSION}>
                  <div
                    className="circle-edit-offer-button color-button"
                    onClick={() => setShowModalCandidateAssessment(true)}
                  >
                    <HiPencil />
                  </div>
                </EditLayout>
              )}
            <div className={styles.containerNoteAssess}>
              <span className={styles.generalAssessment}>{t('managerCandidate.assessLevel')}</span>
              <span className={styles.contentGeneralAssessment}>
                {getName(uDataLevel, dataStatusPass?.level_assess_id)}
              </span>
            </div>
            {dataStatusPass?.note && (
              <div className={styles.containerNoteAssess}>
                <div className={styles.generalAssessment}>{t('managerCandidate.generalAssessment')}</div>
                <div className={styles.contentGeneralAssessment}>{dataStatusPass?.note}</div>
              </div>
            )}
          </div>
        )}
      {showModalOffer && (
        <ModalOffer
          isShowModal={showModalOffer}
          setShowModal={setShowModalOffer}
          isLeaderInterrupt={uApplyCandidate?.is_leader_interrupt}
          dataOfferCandidate={dataStatusPass}
        />
      )}
      {showModalCandidateAssessment && (
        <ModalCandidateAssessment
          isShowModal={showModalCandidateAssessment}
          setIsShowModal={setShowModalCandidateAssessment}
          title={t('managerCandidate.candidateAssetment')}
          dataListAsset={uGetListAssess?.data?.list_stats}
          applyId={uApplyCandidate?.id}
          dataLeaderScore={dataLeaderScore}
          dataStatusPass={dataStatusPass}
        />
      )}
    </>
  );
};
export default DetailApplyLeader;
