/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from 'react-query';
import { HTTP_OK } from 'constants/StatusCode';
import { sendGet } from 'helpers/axios';
import { managerDecentralization } from 'constants/index';

export function useGetListManagerDecentralization(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerDecentralization.getListManagerDecentralization, params], async () => {
    const response = await sendGet(`/api/group-role/list`, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return {
    data: response?.data,
    error,
    isLoading,
    totalMembers: response?.total_member,
    totalPage: response?.data?.total || 0,
  };
}

export function useGetListGroupRole(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerDecentralization.getListGroupRole, params], async () => {
    const response = await sendGet(`/api/group-role/list-user`, params);
    if (response.status === HTTP_OK) {
      return response?.data;
    }
    return null;
  });
  return { ...response, error, isLoading, totalPage: response?.total || 0 };
}

export function useCheckUserRole(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerDecentralization.checkUserRole, params], async () => {
    const response = await sendGet(`/api/group-role/user/check-group`, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListAllDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerDecentralization.allDivision, params], async () => {
    const response = await sendGet(`/api/group-role/division`, params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListAllDivisionPosition(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerDecentralization.allDivisionPosition, params], async () => {
    const response = await sendGet(`/api/group-role/division-position`, params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListCommunityAddMemberCustom(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerDecentralization.communityAddMemberCustom, params], async () => {
    const response = await sendGet(`/api/group-role/add-member-custom`, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}
export function useGroupRoleDetail(id) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerDecentralization.communityAddMemberCustom], async () => {
    const response = await sendGet(`/api/group-role/detail`, { group_role_id: id });
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}
