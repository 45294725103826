import moment from 'moment';
import { INVALID_DATE } from 'constants/index';

const regexDate = /^(((0)[1-9])|[1-2]\d|(3)[0-1])(\/)(((0)[1-9])|((1)[0-2]))(\/)\d{4}$/g;

export const customDate = (date, format) => {
  if (!date) return null;
  if (moment.isMoment(date)) {
    return date.format(format);
  }
  if (!format) {
    format = 'DD/MM/YYYY - HH:mm';
  }
  return moment(date).format(format);
};

export const getFormattedDate = (date, format) => {
  if (!date) return;
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  return month + format + day + format + year;
};

export const sendDate = (date, format) => {
  if (!date) return;
  if (!format) {
    format = 'DD/MM/YYYY';
  }
  return moment(date).format(format);
};

export const dateResponse = (date) => {
  if (!date) return;

  return moment(date, 'DD/MM/YYYY');
};

export const getStartOfMonth = (date) => {
  return moment(date).startOf('month').format('MM/DD/YYYY');
};

export const getEndOfMonth = (date) => {
  return moment(date).endOf('month').format('MM/DD/YYYY');
};

export const getDayOfWeek = (date) => {
  if (!date) return;

  return moment(date).day();
};

export const getMessageErrorFormatDate = (date, message) => {
  return date && moment(date, 'YYYY/MM/DD').format('YYYY/MM/DD') === INVALID_DATE ? message : null;
};

export const checkInvalidDateByDiagonalLine = (dateInvalid) => {
  const dateTemp = dateInvalid?.replaceAll(/\D+/g, '');
  if (dateTemp?.length !== 8) return false;
  const dateAfterFormat = dateTemp.slice(0, 2) + '/' + dateTemp.slice(2, 4) + '/' + dateTemp.slice(4);
  if (new RegExp(regexDate).test(dateAfterFormat)) {
    return moment(dateAfterFormat, 'DD/MM/YYYY').format('DD/MM/YYYY') === dateAfterFormat;
  } else return false;
};

export const formatDateIgnoreDiagonalLine = (dateInvalid) => {
  if (!dateInvalid || dateInvalid.length !== 10) return;
  const dateTemp = dateInvalid?.replaceAll(/\D+/g, '');
  const dateAfterFormat = dateTemp.slice(0, 2) + '/' + dateTemp.slice(2, 4) + '/' + dateTemp.slice(4);
  return dateAfterFormat;
};

export const getMessageErrorDateInFilter = (date, messageInvalidDate, messageDateFuture) => {
  return date && moment(date, 'YYYY/MM/DD').format('YYYY/MM/DD') === INVALID_DATE
    ? messageInvalidDate
    : moment(date, 'YYYY/MM/DD').isAfter(moment(), 'day')
    ? messageDateFuture
    : null;
};
