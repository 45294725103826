import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import $ from 'jquery';

import { Grid, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';

import './styles.scss';
import { HTTP_OK } from 'constants/StatusCode.js';
import ValidateEditApply from './ValidateEditApply';
import AlertComponent from 'components/Alert/index.js';
import { DEFAULT_PAGE_MUI } from 'constants/index.js';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import DateDay from 'components/ReactForm/DateDay';
import CustomRadioInput from 'components/InputYupAndMui/CustomRadioInputForm';
import SelectField from 'components/ReactForm/SelectField';
import { errorSubmit, checkMaxDateToday } from 'helpers';
import { useListSkillAddStandardIndex, useListUsers } from 'hook/useMasterData';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import AutoCompleteCustom from 'components/AutoCompleteCustom/AutoComplete.js';
import iconAttchmentCV from 'assets/img/icons/attchment-cv.svg';
import iconCvDefault from 'assets/img/icons/cv-default.svg';
import { apiAddApplyInfo, apiUploadCV } from 'api/managerCandidate';
import { USE_GET_DETAIL_CANDIDATE, SOURCE_CV_TEXT } from 'constants/ManagerCandidate';
import { MAX_LENGTH_30, MAX_LENGTH_250, DEFAULT_LIMIT_10, LOADING_SM, MAX_LENGTH_50 } from 'constants/index.js';
import { LIST_YEAR_EXPERIENCE } from 'constants/ManagerCandidate';
import { customDate } from 'helpers/formatDate.js';
import Loading from 'components/Loading';

const AddApplyModal = (props) => {
  const { isShowModal, setShowModal, uDataPosition, dataListSchool, uDataLevel, uDataListSourceCV, uDetailCandidate } =
    props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [stateButton, setStateButton] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [isShowAlertError, setShowAlertError] = useState(false);
  const [message, setMessage] = useState('');
  const [attchmentCV, setAttchmentCV] = useState();
  const [namePresenter, setNamePresenter] = useState('');
  const [dataUser, setDataUser] = useState([]);
  const [sourceCvText, setSourceCvText] = useState('');
  const [errorDate, setErrorDate] = useState({});
  const [filter, setFilter] = useState({ page: DEFAULT_PAGE_MUI, limit: DEFAULT_LIMIT_10, key_word: '' });
  const { data: uDataSkill } = useListSkillAddStandardIndex();
  const { data: uDataUsers, isLoading: isLoadingDataUsers } = useListUsers({
    key_word: filter.key_word,
    page: filter.page + 1,
    per_page: filter.limit,
  });

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(ValidateEditApply(sourceCvText)),
    mode: 'onChange',
  });
  useEffect(() => {
    if (uDataUsers?.data) {
      setDataUser((prev) => {
        if (filter.clear_data) {
          return [...uDataUsers?.data];
        }
        return [...prev, ...uDataUsers?.data];
      });
    }
    // eslint-disable-next-line
  }, [uDataUsers]);

  const radioListPrequalification = [
    {
      value: 1,
      labelName: t('managerCandidate.throughPrequalification'),
    },
    {
      value: 0,
      labelName: t('managerCandidate.didNotPassPrequalification'),
    },
  ];

  const handleSubmitForm = async (dataSubmit) => {
    if (errorDate?.birthday || errorDate?.apply_date) {
      return;
    }
    const {
      source_cv,
      presenter_id,
      apply_date,
      link_cv_online,
      position_id,
      skill_id,
      level_id,
      pass_prequalification,
      experience,
    } = dataSubmit;
    setStateButton(true);
    let response = {};
    const fb = new FormData();
    fb.append('candidate_id', uDetailCandidate?.id);
    namePresenter && fb.append('presenter_name', namePresenter);
    presenter_id && fb.append('presenter_id', presenter_id);
    fb.append('apply_date', moment(apply_date, 'DD/MM/YYYY').format('YYYY/MM/DD'));
    link_cv_online && fb.append('link_cv_online', link_cv_online);
    fb.append('position_id', position_id);
    fb.append('experience', experience);
    skill_id && fb.append('skill_id', skill_id ?? null);
    fb.append('level_id', level_id);
    fb.append('source_cv', source_cv ?? source_cv);
    attchmentCV && fb.append('cv_offline_info', JSON.stringify(attchmentCV));
    fb.append('pass_prequalification', pass_prequalification);

    response = await apiAddApplyInfo(fb);

    if (response?.data?.status === HTTP_OK) {
      if (errorSubmit(response?.data?.code)) {
        setMessage(t(errorSubmit(response?.data?.code)));
      } else {
        setMessage(t('managerCandidate.addInfoApplySuccess'));
      }
      setStateButton(false);
      setShowAlert(true);
      clearErrors();
      await queryClient.invalidateQueries(USE_GET_DETAIL_CANDIDATE);
    } else {
      if (errorSubmit(response?.data?.code)) {
        setMessage(t(errorSubmit(response?.data?.code)));
        setShowAlertError(true);
        setStateButton(false);
      }
    }
  };

  const { mutate: uploadCV, isLoading: isLoadingUploadCV } = useMutation((formData) => apiUploadCV(formData), {
    onSuccess: (response) => {
      setAttchmentCV(response.data?.data);
      setError('link_cv_online', '');
      setValue('is_file_upload', true);
    },
    onError: () => {
      setMessage(t('messages.systemError'));
      setShowAlertError(true);
      setAttchmentCV();
      setValue('is_file_upload', false);
    },
  });

  const onUploadAttchmentCV = (event) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size / 1024 / 1024 >= 25) {
        $('#attchment-cv').val('');
        setMessage(t('messages.pleaseUploadFileUnder25mb'));
        setShowAlertError(true);
        setAttchmentCV();
        setValue('is_file_upload', false);
        return;
      }

      var regex = new RegExp('(.*?).(docx|doc|pdf|jpg|jpeg|png|xlsx|csv)$');
      if (!regex.test(event.target.files[0].name.toLowerCase())) {
        setMessage(t('messages.pleaseUploadImagesInTheCorrectFormat'));
        setShowAlertError(true);
        setAttchmentCV();
        setValue('is_file_upload', false);
        return;
      }

      const formData = new FormData();
      formData.append('file_cv', event.target.files[0]);
      uploadCV(formData);
    }
  };

  // Watch value type timekeeping
  const watchTypePositionIdValue = useWatch({
    control,
    name: 'position_id',
  });

  // Watch value type source_cv
  const watchTypeSourceCvValue = useWatch({
    control,
    name: 'source_cv',
  });

  useEffect(() => {
    setValue(
      'is_skill',
      uDataSkill?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))?.length > 0
        ? true
        : false,
    );
    // eslint-disable-next-line
  }, [watchTypePositionIdValue]);

  // Watch value birthday
  const watchApplyDateValue = useWatch({
    control,
    name: 'apply_date',
  });

  useEffect(() => {
    // eslint-disable-next-line
    if (!watchApplyDateValue || watchApplyDateValue == 'Invalid Date' || watchApplyDateValue?.length < 10) {
      return;
    }
    if (watchApplyDateValue?.length === 10) {
      if (checkMaxDateToday(watchApplyDateValue)) {
        setErrorDate((prev) => {
          return { ...prev, apply_date: true };
        });
      } else {
        setErrorDate((prev) => {
          return { ...prev, apply_date: false };
        });
      }
    }
  }, [watchApplyDateValue]);

  const onHandleChangeSelect = (value) => {
    let data = uDataListSourceCV?.filter((item) => (item.id === value ? item.name : null));
    if (data?.length && SOURCE_CV_TEXT.indexOf(data[0].name.toLowerCase()) > -1) {
      setSourceCvText(value);
    } else {
      setSourceCvText('');
    }
  };

  return (
    <>
      <ModalComponent
        toggle={() =>
          setShowModal((prev) => {
            return { ...prev, add_apply: !isShowModal };
          })
        }
        isShowModal={isShowModal}
        title={t('managerCandidate.addInfoApply')}
        classNameAdd="modal-create-candidate"
      >
        <Box autoComplete="off" onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={3}>
            <Grid item xs={12} className="text-modal">
              {t('managerCandidate.inforBasic')}
            </Grid>
            <Grid item xs={12} className="text-modal">
              <div className="infor-basic">
                <p>
                  {t('common.fullName')} : <span>{uDetailCandidate?.full_name}</span>
                </p>
                <p>
                  {t('common.birthday')} : <span>{customDate(uDetailCandidate?.birthday, 'DD/MM/YYYY')}</span>
                </p>
                <p>
                  {t('common.phone')} : <span>{uDetailCandidate?.mobile}</span>
                </p>
                <p>
                  {t('common.email')} : <span>{uDetailCandidate?.email}</span>
                </p>
                <p>
                  {t('managerCandidate.school')} :{' '}
                  <span>{dataListSchool?.find((item) => item.id === uDetailCandidate?.school)?.name}</span>
                </p>
                <p>
                  {t('common.gender')} :{' '}
                  <span>{uDetailCandidate?.sex === 1 ? t('common.male') : t('common.female')}</span>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} className="text-modal item-field">
              {t('managerCandidate.inforApply')}
            </Grid>
            <Grid item md={6} className="item-field">
              <SelectField
                name="position_id"
                label={t('masterData.StandardIndex.position')}
                error={errors?.position_id?.message}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: {
                        xs: 350,
                      },
                    },
                  },
                }}
              >
                {uDataPosition?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item md={6} className="item-field">
              <SelectField
                name="skill_id"
                label={t('masterData.StandardIndex.skill')}
                error={errors?.skill_id?.message}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                disabled={
                  watchTypePositionIdValue &&
                  uDataSkill?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))?.length > 0
                    ? false
                    : true
                }
                isRequired={
                  watchTypePositionIdValue &&
                  uDataSkill?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))?.length > 0
                    ? true
                    : false
                }
              >
                {uDataSkill &&
                  uDataSkill
                    ?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))
                    ?.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
              </SelectField>
            </Grid>
            <Grid item md={6} className="item-field">
              <SelectField
                name="level_id"
                label={t('masterData.StandardIndex.levelApply')}
                error={errors?.level_id?.message}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
              >
                {uDataLevel?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item md={6} className="item-field">
              <SelectField
                name="experience"
                label={t('managerJD.experience')}
                error={errors?.experience?.message}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: {
                        xs: 335,
                      },
                    },
                  },
                }}
              >
                {LIST_YEAR_EXPERIENCE?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item md={12} className="item-field">
              <SelectField
                name="source_cv"
                label={t('managerCandidate.sourceCv')}
                error={errors?.source_cv?.message}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                onHandleChangeSelect={onHandleChangeSelect}
              >
                {uDataListSourceCV?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            {sourceCvText && (
              <Grid item md={12} className="item-field">
                <CustomInputYup
                  label={t('managerCandidate.Outsourcing/contributor/headhunt')}
                  name={`source_cv_text`}
                  maxLength={MAX_LENGTH_50}
                  errors={errors}
                  control={control}
                  register={register}
                  useWatch={useWatch}
                  required
                />
              </Grid>
            )}
            {watchTypeSourceCvValue === 1 && (
              <Grid item md={12} className="item-field">
                <AutoCompleteCustom
                  label={t('managerCandidate.fullNameIntroduce')}
                  options={dataUser}
                  setFilter={setFilter}
                  setValueField={setValue}
                  register={register}
                  name="presenter_id"
                  total={uDataUsers?.total}
                  isLoading={isLoadingDataUsers}
                  error={errors?.presenter_id?.message}
                  setDataUser={setDataUser}
                  maxLength={MAX_LENGTH_30}
                  required={watchTypeSourceCvValue === 1 ? true : false}
                  setNamePresenter={setNamePresenter}
                  namePresenter={namePresenter}
                />
              </Grid>
            )}

            <Grid item md={12} className="item-field">
              <DateDay
                label={t('managerCandidate.dateApply')}
                name="apply_date"
                error={errors?.apply_date?.message}
                addErrors={errorDate?.apply_date && t('messages.pleaseEnteraDateThatIsLessThanTheCurrentDate')}
                control={control}
                setValue={setValue}
                register={register}
                setError={setError}
                defaultValue={null}
                isShowModal={isShowModal}
                maxDate={moment()}
                isRequired
              />
            </Grid>
            <Grid item xs={12} className="text-modal">
              {t('managerCandidate.infoCV')}
            </Grid>
            <Grid item md={12} className="item-field link-cv-input">
              <CustomInputYup
                label={t('managerCandidate.linkAttchment')}
                name={`link_cv_online`}
                control={control}
                register={register}
                errors={errors}
                addErrors={!attchmentCV && errors?.name?.link_cv_online}
                useWatch={useWatch}
                required={attchmentCV ? false : true}
                maxLength={MAX_LENGTH_250}
              />
            </Grid>
            <Grid item xs={12} className="text-modal item-field">
              <input
                id={'attchment-cv'}
                type="file"
                name="attchment_cv"
                style={{ display: 'none' }}
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.xlsx,.csv"
                onChange={(event) => onUploadAttchmentCV(event)}
              />
              <label className="upload-file-cv" htmlFor={'attchment-cv'}>
                {<img src={iconAttchmentCV} alt="camera" width={'18px'} height="18px" />}{' '}
                {t('managerCandidate.upFileCvAttchment')}
                <label className="label-CV" htmlFor={'attchment-cv'}>
                  {t('managerCandidate.typeFileAccept')}
                </label>
              </label>
              {isLoadingUploadCV && <Loading type={LOADING_SM} addClass="loading-upload-cv" />}
              {!isLoadingUploadCV && attchmentCV && (
                <div className="info-cv-upload">
                  <div>
                    <img src={iconCvDefault} alt="camera" width={'60px'} height="60px" />
                  </div>
                  <div className="cv-name">
                    <p className="title">{attchmentCV.name}</p>
                    <p>{attchmentCV.size + ' kb'}</p>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} className="text-modal">
              {t('managerCandidate.prequalification')}
            </Grid>
            <Grid item xs={12} className="text-modal item-field">
              <CustomRadioInput
                control={control}
                defaultValue={1}
                radioList={radioListPrequalification}
                name="pass_prequalification"
                setValue={setValue}
              />
            </Grid>
            <Grid item md={6}>
              <ButtonComponent
                text={t('managerJD.cancel')}
                addClass="button-cancel btn-bg-yellow2"
                handleClick={() =>
                  setShowModal((prev) => {
                    return { ...prev, add_apply: false };
                  })
                }
              />
            </Grid>
            <Grid item md={6}>
              <ButtonComponent
                text={t('common.add')}
                addClass="button-submit btn-bg-yellow1"
                type="submit"
                handleClick={handleSubmit(handleSubmitForm)}
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Grid>
          </Grid>
        </Box>
      </ModalComponent>
      <AlertComponent
        toggle={() => {
          setShowAlert(!isShowAlert);
          setShowModal((prev) => {
            return { ...prev, add_apply: false };
          });
        }}
        isShowAlert={isShowAlert}
      >
        {message}
      </AlertComponent>
      <AlertComponent
        toggle={() => {
          setShowAlertError(!isShowAlertError);
        }}
        isShowAlert={isShowAlertError}
      >
        {message}
      </AlertComponent>
    </>
  );
};

export default AddApplyModal;
