import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import ReactLoading from 'react-loading';

import { CssAutoCompleteField } from './constant';

function CustomAutoComplete({
  valueAutoFill,
  control,
  name,
  label,
  setValue,
  setError,
  defaultValue,
  addItemsDelete,
  handleChange,
  limitTags,
  keyClose,
  handleSearch,
  isLoading,
  onBlur,
  addDeleteAllItem,
  CustomLabelOption,
  errors,
  classNameSelect,
}) {
  const [tags, setTags] = useState({
    tags: defaultValue || [],
  });

  const [selectedValue, setSelectedValue] = useState(defaultValue ? defaultValue : []);

  const onTagsChange = (e, values, reason, detail) => {
    setTags({
      tags: values,
    });
    // Get value deleted when delete each item
    if (reason === 'removeOption' && addItemsDelete) {
      addItemsDelete(detail.option.user_id || detail.option.id);
    }

    // Delete all item when click clear all
    if (reason === 'clear' && addDeleteAllItem) {
      const result = tags?.tags?.map((item) => item?.user_id || item?.id);
      addDeleteAllItem(result);
    }

    // Func added when change
    if (handleChange) {
      handleChange(values);
    }
    setSelectedValue(values);
  };
  useEffect(() => {
    let transformed = '';
    if (name === 'division_id' || name === 'position_id' || name === 'contract_category') {
      transformed = tags?.tags?.map((item) => item?.id);
    } else {
      transformed = tags?.tags?.map((item) => item?.user_id);
    }
    setValue(name, transformed);
    setError(name, { type: 'custom', message: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  // Set tags to [] when have "keyClose" --> set default value to []
  useEffect(() => {
    if (keyClose !== undefined && keyClose === false) {
      setTags({
        tags: [],
      });
    }
  }, [keyClose]);
  return (
    <div>
      <Controller
        render={() => (
          <Autocomplete
            key={keyClose}
            multiple
            limitTags={limitTags ? limitTags : 2}
            options={valueAutoFill?.data || valueAutoFill || []}
            getOptionLabel={(option) => {
              if (valueAutoFill?.length || valueAutoFill?.data?.length !== 0) {
                return option?.name;
              }
            }}
            renderOption={(props, option) => {
              return <li {...props}> {CustomLabelOption ? CustomLabelOption({ option }) : option?.name}</li>;
            }}
            onChange={(e, values, reason, detail) => onTagsChange(e, values, reason, detail)}
            defaultValue={tags?.tags}
            filterOptions={(options) =>
              options?.filter(
                (optionFilter) =>
                  !selectedValue.find((el) => {
                    if (el.user_id) {
                      return el.user_id === optionFilter.user_id;
                    } else if (el.id) {
                      return el.id === optionFilter.id;
                    }
                    return false;
                  }),
              )
            }
            loading={isLoading}
            loadingText={<ReactLoading type="spinningBubbles" color={'#ffdba9'} height={25} width={25} />}
            renderInput={(params) => (
              <CssAutoCompleteField
                {...params}
                className={classNameSelect}
                label={label}
                onChange={(e) => {
                  if (handleSearch) {
                    handleSearch(e.target.value);
                  }
                }}
                onBlur={onBlur && onBlur}
                labelspace={tags?.tags?.length === 0 ? '3px' : 0}
              />
            )}
          />
        )}
        control={control}
        name={name}
      ></Controller>
      <p className="error-input error">{errors[`${name}`]?.message}</p>
    </div>
  );
}

export default CustomAutoComplete;
