import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Grid } from '@mui/material';
import CalendarIcon from '@mui/icons-material/Event';
import iconExport from 'assets/img/icons/export.svg';
import styles from './styles.module.scss';
import StatisticsByOrder from './blocks/StatisticsByOrder';
import StatisticsByJob from './blocks/StatisticsByJob';
import StatisticsBySourceCv from './blocks/StatisticsBySourceCv';
import StatisticsCandidateByLevel from './blocks/StatisticsCandidateByLevel';
import StatisticsCandidateByPosition from './blocks/StatisticsCandidateByPosition';
import StatisticsByCandidateOffer from './blocks/StatisticsByCandidateOffer';
import ModalExport from './components/ModalExport';
import { customDate } from 'helpers/formatDate';
import ButtonComponent from 'components/Buttons';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20 } from 'constants/index.js';
import StatisticsByRecruiter from './blocks/StatisticsByRecruiter';

const Dashboard = () => {
  const { t } = useTranslation();
  const [filterDate, setFilterDate] = useState({ from_time: null, to_time: null });
  const [filter, setFilter] = useState({ position_id: null, division_id: null });
  const [yearFilter, setYearFilter] = useState(new Date());
  const [isOpenModalExport, setOpenModalExport] = useState(false);
  const [candidateOfferFilter, setCandidateOfferFilter] = useState({
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT_20,
  });

  const onChangeFromTime = (date) => {
    setFilterDate({ ...filterDate, from_time: customDate(date, 'YYYY-MM-DD') });
  };

  const onChangeToTime = (date) => {
    setFilterDate({ ...filterDate, to_time: customDate(date, 'YYYY-MM-DD') });
  };

  const disabledDateFrom = (date) => {
    if (filterDate.to_time) {
      return date && moment(date).isAfter(moment(filterDate.to_time, 'YYYY-MM-DD'), 'day');
    }
    return date && moment(date).isAfter(moment(), 'day');
  };

  const disabledDateTo = (date) => {
    if (filterDate.from_time) {
      return (
        date &&
        (moment(date).isBefore(moment(filterDate.from_time, 'YYYY-MM-DD'), 'day') ||
          moment(date).isAfter(moment(), 'day'))
      );
    }
    return date && moment(date).isAfter(moment(), 'day');
  };

  return (
    <Grid container className={styles.container} rowGap={2.5} columnSpacing={2.5}>
      <Grid item xs={12} className={styles.headerPage}>
        <span className={styles.titleHeader}>{t('common.dashboard')}</span>
        <div className={styles.groupBtnHeader}>
          <DatePicker
            placeholder={t('dashboard.fromLabel')}
            size="middle"
            onChange={(date) => onChangeFromTime(date)}
            inputReadOnly
            format="DD/MM/YYYY"
            className="date-picker-ant-custom mr-10"
            suffixIcon={<CalendarIcon />}
            disabledDate={disabledDateFrom}
          />
          <DatePicker
            placeholder={t('dashboard.toLabel')}
            size="middle"
            onChange={(date) => onChangeToTime(date)}
            inputReadOnly
            format="DD/MM/YYYY"
            className="date-picker-ant-custom mr-10"
            suffixIcon={<CalendarIcon />}
            disabledDate={disabledDateTo}
          />
          <ButtonComponent
            text={t('common.export')}
            imgIcon={iconExport}
            addClass={styles.buttonExport}
            handleClick={() => setOpenModalExport(true)}
          />
        </div>
      </Grid>
      <Grid container item xs={9} rowGap={2}>
        <Grid item container xs={12} columnSpacing={2.5} className={styles.orderAndLevelBlock}>
          <Grid item xs={9}>
            <StatisticsCandidateByLevel yearFilter={yearFilter} setYearFilter={setYearFilter} />
          </Grid>
          <Grid item xs={3}>
            <StatisticsByOrder filterDate={filterDate} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.jobBlock}>
          <StatisticsByJob />
        </Grid>
      </Grid>
      <Grid item xs={3} className={styles.sourceCvBlock}>
        <StatisticsBySourceCv filterDate={filterDate} />
      </Grid>
      <Grid item xs={12} className={styles.lastBlock}>
        <StatisticsCandidateByPosition filterDate={filterDate} filter={filter} setFilter={setFilter} />
      </Grid>
      <Grid item xs={12}>
        <StatisticsByRecruiter filter={filterDate} />
      </Grid>
      <Grid item xs={12} className={styles.lastBlock}>
        <StatisticsByCandidateOffer
          filterDate={filterDate}
          filter={candidateOfferFilter}
          setFilter={setCandidateOfferFilter}
        />
      </Grid>
      <ModalExport
        isOpen={isOpenModalExport}
        setOpen={setOpenModalExport}
        filterDate={filterDate}
        filter={filter}
        yearFilter={yearFilter}
      />
    </Grid>
  );
};

export default Dashboard;
