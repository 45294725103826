import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import axios from 'axios';
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledDropdown,
  Media,
} from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';

import styles from './styles.module.scss';
import classNames from 'classnames';

import configs from 'config';
import Notification from 'components/NotificationAgain';
import storageFC from 'helpers/storage';
import HomeMini from 'components/HomeMini';
import { ThemContext } from 'layouts/Admin';
import logoutIcon from 'assets/img/icons/logout.svg';
import avatarDefault from 'assets/img/icons/default-avatar.svg';
import { handleOnError } from 'helpers';

const Header = () => {
  const { t } = useTranslation();
  const { dataInfoUser } = useContext(ThemContext);
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const logout = () => {
    confirmAlert({
      title: t('common.areYouSureYouWantToSignOut'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.confirm'),
          className: 'btn-alert-ok',
          onClick: async () => {
            axios
              .post(
                `${configs.API_DOMAIN_SSO}/api/logout`,
                {
                  token: storageFC.getToken(),
                },
                {
                  headers: { Authorization: `Bearer ${storageFC.getToken()}` },
                },
              )
              .then((res) => {
                storageFC.setToken('');
                window.location.href = configs.API_DOMAIN_LOGIN;
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
      ],
    });
  };

  return (
    <>
      <div className={classNames(`header bg-menu ${styles.boxMenu}`)}>
        <Container fluid className={styles.menu}>
          <div className="header-body">
            <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="lg">
              <>
                <NavbarBrand to="/" tag={Link}>
                  <div className={styles.logo}>
                    <img alt="logo" src={require('../../assets/img/icons/logo.png').default} />
                    <h4>Recruit</h4>
                  </div>
                </NavbarBrand>
                <button className="navbar-toggler" id="navbar-collapse-main">
                  <span className="navbar-toggler-icon" />
                </button>
                <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                  <Nav className="ml-auto" navbar>
                    <UncontrolledDropdown nav>
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <img
                            alt="..."
                            src={dataInfoUser?.avatar ? dataInfoUser?.avatar : avatarDefault}
                            onError={handleOnError}
                          />
                        </span>
                      </Media>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <Notification toggleDrawer={toggleDrawer} state={state} />
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <HomeMini />
                    </UncontrolledDropdown>
                  </Nav>
                  <NavItem className={styles.logOut}>
                    {storageFC.getToken() && (
                      <span className={styles.logout} onClick={logout}>
                        <img alt={logoutIcon} src={logoutIcon} />
                      </span>
                    )}
                  </NavItem>
                </UncontrolledCollapse>
              </>
            </Navbar>
          </div>
        </Container>
      </div>
      <div className={styles.paddingTop}></div>
    </>
  );
};

export default Header;
