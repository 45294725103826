import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { TextFieldDate as TextFieldDateDay, TextFieldNoneDate as TextFieldNoneDateDay } from './style.js';
import styles from './styles.module.scss';

const FORMAT = 'DD/MM/YYYY';

const DateDay = (props) => {
  const {
    label,
    addClass,
    valueDefault,
    onChangeHandle,
    name,
    error,
    isRequired,
    placeholder,
    sizeDate,
    max,
    min,
    isReadOnly,
    isPickOnly,
    defaultOpen,
    ..._props
  } = props;
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(defaultOpen || false);

  useEffect(() => {
    setValue(valueDefault);
  }, [valueDefault]);

  const handleChangeDate = (newValue) => {
    onChangeHandle(newValue);
    setValue(newValue);
  };

  return (
    <>
      <div className={`${addClass} ${open ? styles.openCalenderTime : ''}`}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            fullWidth
            name={name}
            open={open}
            inputFormat="dd/MM/yyyy"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={value}
            {..._props}
            label={
              <React.Fragment>
                {placeholder && !value ? placeholder : label}
                {isRequired && label ? <sup>*</sup> : ''}
              </React.Fragment>
            }
            maxDate={max ? new Date(`${max}`) : moment('31/12/2222', 'DD/MM/yyyy').toDate()}
            minDate={
              min?.toString() === 'Invalid Date' || min === null || min === undefined
                ? moment('1/1/1900', 'DD/MM/yyyy').toDate()
                : new Date(`${min}`)
            }
            onChange={handleChangeDate}
            inputProps={{
              style: sizeDate ? { fontSize: '14px' } : { padding: '14px 15px 14px 15px', fontSize: '14px' },
              readOnly: isReadOnly ? true : false,
              onKeyDown: (event) => {
                if (isPickOnly && event.target.value !== moment(value).format(FORMAT)) event.preventDefault();
                return event;
              },
            }}
            renderInput={(params) => {
              if (label) {
                return (
                  <TextFieldDateDay
                    {...params}
                    error={error ? true : false}
                    size={sizeDate ? sizeDate : ''}
                    value={value ? moment(value).format(FORMAT) : ''}
                    placeholder={placeholder}
                  />
                );
              }
              return (
                <TextFieldNoneDateDay
                  {...params}
                  error={error ? true : false}
                  size={sizeDate ? sizeDate : ''}
                  value={value ? moment(value).format(FORMAT) : ''}
                  placeholder={placeholder}
                  onClick={() => {
                    if (isPickOnly) setOpen(true);
                  }}
                />
              );
            }}
          />
        </LocalizationProvider>
      </div>
      {error && <p className="error-input error">{error}</p>}
    </>
  );
};

export default DateDay;
