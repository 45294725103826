import React from 'react';
import styles from './styles.module.scss';
import Avatar from '@mui/material/Avatar';

import defaultAvatar from 'assets/img/icons/default-avatar.svg';

const InfoCard = (props) => {
  const { avatar, name, position, division } = props;

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.avatar}>
        <Avatar src={avatar ? avatar : defaultAvatar} />
      </div>
      <div>
        {name && <span className={styles.name}>{name}</span>}
        {(position || division) && <span className={styles.position}>{position + ' - ' + division}</span>}
      </div>
    </div>
  );
};

export default InfoCard;
