import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ROLE_VIEW } from 'constants/permission';

const RadioGroupContainer = styled.div`
  .MuiFormGroup-root {
    flex-wrap: nowrap;
  }
  .MuiFormControlLabel-label {
    width: max-content;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }
  .Mui-checked {
    color: #fea628 !important;
  }
`;

const RadioGroupRole = (props) => {
  const { setViewRole, viewRole } = props;
  const { t } = useTranslation();

  return (
    <RadioGroupContainer>
      <RadioGroup row value={viewRole} onChange={(e, value) => setViewRole(+value)}>
        <FormControlLabel value={ROLE_VIEW.HRM} control={<Radio />} label={t('managerDecentralization.role.HRM')} />
        <FormControlLabel value={ROLE_VIEW.HR} control={<Radio />} label={t('managerDecentralization.role.HR')} />
        <FormControlLabel
          value={ROLE_VIEW.LEADER}
          control={<Radio />}
          label={t('managerDecentralization.role.leader')}
        />
        <FormControlLabel
          value={ROLE_VIEW.ASSIGNER_BY_LEADER}
          control={<Radio />}
          label={t('managerDecentralization.role.assignerByLeader')}
        />
      </RadioGroup>
    </RadioGroupContainer>
  );
};

export default RadioGroupRole;
