import { SOURCE_CV_TEXT, CANDIDATE_RATING_PASS, CANDIDATE_RATING_FAIL } from 'constants/ManagerCandidate';
import { getName } from 'helpers/index';
import { t } from 'i18next';

export const getNameSourceCV = (dataCV, sourceId) => {
  if (!sourceId) return 'N/A';
  return getName(dataCV, sourceId);
};

export const getNameSourceCVOther = (dataJob) => {
  if (dataJob?.source_cv_text) {
    return dataJob.source_cv_text;
  }

  if (dataJob?.presenter_id) {
    return dataJob.presenter_name;
  }

  return 'N/A';
};

export const getTitleSourceCVText = (dataCV, dataJob) => {
  const nameSource = getNameSourceCV(dataCV, dataJob?.source_cv);
  if (!nameSource) return 'N/A';

  if (SOURCE_CV_TEXT.indexOf(nameSource.toLowerCase()) > -1) {
    if (!nameSource) return 'N/A';
    if (nameSource.toLowerCase() === 'cộng tác viên') return 'Tên ' + nameSource.toLowerCase();
    return 'Tên nguồn ' + nameSource.toLowerCase();
  } else if (dataJob?.presenter_id) {
    return t('managerCandidate.presenter');
  } else {
    return null;
  }
};

export const getTextLabelResultAssess = (result) => {
  if (result === CANDIDATE_RATING_PASS) return t('managerCandidate.browse');
  if (result === CANDIDATE_RATING_FAIL) return t('managerCandidate.doNotBrowse');
  return t('managerCandidate.remindReview');
};

export const getScoreAvg = (listStats, keyField) => {
  if (!listStats) return;
  let sumScore = 0;
  let sumCoefficient = 0;
  for (let i = 0; i < listStats.length; i++) {
    sumScore += listStats.at(i)[keyField] * listStats.at(i).coefficient;
    sumCoefficient += listStats.at(i).coefficient;
  }
  return sumScore / sumCoefficient;
};

export const alertConfirmDuplicateCandidate = (t, confirmAlert, nameHr, functionAlert) => {
  if (nameHr) {
    confirmAlert({
      title: t('managerCandidate.thisCandidateIsAlreadyInTheSystemHr', { nameHr: nameHr }),
      buttons: [
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
        },
      ],
    });
  } else {
    confirmAlert({
      title: t('managerCandidate.thisCandidateIsAlreadyInTheSystem'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('managerCandidate.seeCandidate'),
          className: 'btn-alert-ok',
          onClick: async () => {
            functionAlert();
          },
        },
      ],
    });
  }
};
