import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import styles from './styles.module.scss';
import iconInfo from 'assets/img/icons/info.png';

import { customDate } from 'helpers/formatDate';
import { getTextGender, getName } from 'helpers/index';
import { useGetListSchool } from 'hook/useManagerCandidate';

import Loading from 'components/Loading';

const InfoCandidate = (props) => {
  const { data, isLoading } = props;
  const { t } = useTranslation();

  const { data: uListSchool } = useGetListSchool();

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={styles.header}>
        <img alt={iconInfo} src={iconInfo} />
        <span className={styles.titleHeader}>{t('managerCandidate.infoCandidate')}</span>
      </Grid>
      <div className="divider w-100"></div>
      {isLoading && (
        <Grid item xs={12}>
          <Loading />
        </Grid>
      )}
      {!isLoading && (
        <>
          <Grid container item xs={12} className={styles.rowContainer}>
            <Grid container item xs={6} className={styles.itemContainer}>
              <Grid item xs={4} className={styles.titleItem}>
                {t('common.fullName')}
              </Grid>
              <Grid item xs={8} className={styles.contentItem}>
                {data?.full_name}
              </Grid>
            </Grid>
            <Grid container item xs={6} className={styles.itemContainer}>
              <Grid item xs={5} className={styles.titleItem}>
                {t('common.email')}
              </Grid>
              <Grid item xs={7} className={styles.email}>
                {data?.email}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.backgroundHightLight}>
            <Grid container item xs={6} className={styles.itemContainer}>
              <Grid item xs={4} className={styles.titleItem}>
                {t('common.birthday')}
              </Grid>
              <Grid item xs={8} className={styles.contentItem}>
                {data?.birthday ? customDate(data.birthday, 'DD/MM/YYYY') : t('common.notDataContract')}
              </Grid>
            </Grid>
            <Grid container item xs={6} className={styles.itemContainer}>
              <Grid item xs={5} className={styles.titleItem}>
                {t('common.phone')}
              </Grid>
              <Grid item xs={7} className={styles.contentItem}>
                {data?.mobile}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.rowContainer}>
            <Grid container item xs={6} className={styles.itemContainer}>
              <Grid item xs={4} className={styles.titleItem}>
                {t('common.sex')}
              </Grid>
              <Grid item xs={8} className={styles.contentItem}>
                {getTextGender(data?.sex)}
              </Grid>
            </Grid>
            <Grid container item xs={6} className={styles.itemContainer}>
              <Grid item xs={5} className={styles.titleItem}>
                {t('common.school')}
              </Grid>
              <Grid item xs={7} className={styles.contentItem}>
                {getName(uListSchool, data?.school)}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default InfoCandidate;
