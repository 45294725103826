import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import iconQuestion from 'assets/img/icons/candidate/question.png';

import Loading from 'components/LoadingMemo';

import styles from './styles.module.scss';
import { LOADING_SM } from 'constants/index';

const StatusQuestion = ({ answer, totalQuestion, type, isActive = false, isLoading, candidateId, applyId }) => {
  const { t } = useTranslation();

  return (
    <Grid spacing={1} xs={3} className={styles.boxStatusHeader}>
      <div className={isActive ? styles.boxActive : ''}>
        <NavLink
          to={{
            pathname: `/admin/candidate-detail/${candidateId}/apply-detail/${applyId}/questions`,
            // state: dataId,
          }}
        >
          <Grid className="border-content-question">
            <Grid container spacing={1} className="styleInfoMui">
              <img className="img-icon" src={iconQuestion} alt={iconQuestion} width="16.67px" height="16.67px" />
              <p className="p-content-question">{t('managerDecentralization.questionInterview')}</p>
            </Grid>
            {<Loading type={LOADING_SM} addClass={styles.statusLoading} isLoading={isLoading} />}
            {!isLoading && (
              <p className="p-content-status">
                {answer || totalQuestion
                  ? `${answer}/${totalQuestion}`
                  : t('managerDecentralization.positionNotQuestionInterview')}
              </p>
            )}
          </Grid>
        </NavLink>
      </div>
    </Grid>
  );
};

export default StatusQuestion;
