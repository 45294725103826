import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import styles from './styles.module.scss';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';
import { useGetListDivision } from 'hook/useDivision';
import { getLevelName, getPositionName, getSkillName, getDivisionName } from 'helpers/masterData';
import { formatSalary } from 'helpers/format';
import { getName, getColorStatus } from 'helpers/index';

import { LIST_STATUS, STATUS_CANDIDATE } from 'constants/ManagerCandidate';
import TotalAvgPoint from './TotalAvgPoint';

const InfoInterview = (props) => {
  const { children, data } = props;
  const { t } = useTranslation();

  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();
  const { data: uDataDivision } = useGetListDivision();

  return (
    <Grid container className={styles.infoInterviewContainer}>
      <TotalAvgPoint listAssess={data?.list_assess} />
      <Grid item container xs={12} className={styles.infoApplyContainer}>
        <Grid item xs={6}>
          <span>{t('managerCandidate.position')}:&nbsp;</span>
          <span>{getPositionName(data?.position_id, uDataPosition)}</span>
        </Grid>
        <Grid item xs={6}>
          <span>{t('managerCandidate.status')}:&nbsp;</span>
          <span className={`${getColorStatus(LIST_STATUS, data?.status)}`}>{getName(LIST_STATUS, data?.status)}</span>
        </Grid>
        <Grid item xs={6}>
          <span>
            {data?.status === STATUS_CANDIDATE.ONBOARD
              ? t('managerCandidate.officialLevel')
              : t('managerCandidate.applyLevel')}
            :&nbsp;
          </span>
          <span>{getLevelName(data?.level_id, uDataLevel)}</span>
        </Grid>
        <Grid item xs={6}>
          <span>{t('managerCandidate.officialSalary')}:&nbsp;</span>
          <span>
            {data?.salary_reality ? formatSalary(Math.ceil(data.salary_reality)) : t('common.notDataContract')}
          </span>
        </Grid>
        <Grid item xs={6}>
          <span>{t('managerCandidate.skill')}:&nbsp;</span>
          <span>{getSkillName(data?.skill_id, uDataSkill)}</span>
        </Grid>
        <Grid item xs={6}>
          <span>{t('managerCandidate.division')}:&nbsp;</span>
          <span>{getDivisionName(data?.division_id, uDataDivision)}</span>
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};

export default InfoInterview;
