import React, { useState, useEffect } from 'react';
import { InputBase, ButtonBase } from '@mui/material';
import { useTranslation } from 'react-i18next';

import iconLoading from 'assets/img/icons/loading.png';

import styles from './styles.module.scss';

import ModalCommon from 'components/ModalCommon/ModalCommon';
import SearchComponent from 'components/Search';

import * as Constants from 'constants/index.js';
import { getValueInputSearchAll } from 'helpers/modal';
import { setDataAllModalWithStatus, handleCheck } from 'helpers/modal';

export default function Search({
  isModals,
  setIsModals,
  setFilter,
  filter,
  dataPosition,
  dataLevel,
  dataDivision,
  dataSkill,
  uDataPosition,
  uDataLevel,
  uDataSkill,
  uDataDivision,
  handleReset,
}) {
  const { t } = useTranslation();

  const [filterDivision, setFilterDivision] = useState([]);
  const [filterPosition, setFilterPosition] = useState([]);
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterSkill, setFilterSkill] = useState([]);

  useEffect(() => {
    if (uDataPosition) setFilterPosition(setDataAllModalWithStatus(uDataPosition));
  }, [uDataPosition]);

  const queryPosition = () => {
    let datas = [];
    filterPosition.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      position_id: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    filterPosition?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    dataPosition?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, position: false };
    });
    setFilterPosition(dataPosition);
  };

  const togglePosition = () => {
    setIsModals((prev) => {
      return { ...prev, position: !prev.position };
    });
    if (filter?.position_id) {
      filterPosition?.forEach((item) => {
        Number(filter?.position_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterPosition([...dataPosition]);
    } else {
      filterPosition?.forEach((item) => {
        item.status = false;
      });
      setFilterPosition([...dataPosition]);
    }
  };

  const changeSelectPosition = (item) => {
    setFilterPosition(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const handleFilterPosition = (query) => {
    const filter = dataPosition.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterPosition(filter);
  };

  // ================================= level ======================

  useEffect(() => {
    if (uDataLevel) setFilterLevel(setDataAllModalWithStatus(uDataLevel));
  }, [uDataLevel]);

  const handleFilterLevel = (query) => {
    const filter = dataLevel.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterLevel(filter);
  };

  const changeSelectLevel = (item) => {
    setFilterLevel(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const queryLevel = () => {
    let datas = [];
    filterLevel.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      level_id: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    filterLevel?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    dataLevel?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, level: false };
    });
    setFilterLevel(dataLevel);
  };

  const toggleLevel = () => {
    setIsModals((prev) => {
      return { ...prev, level: !prev.level };
    });
    if (filter?.level_id) {
      filterLevel?.forEach((item) => {
        Number(filter?.level_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterLevel([...dataLevel]);
    } else {
      filterLevel?.forEach((item) => {
        item.status = false;
      });
      setFilterLevel([...dataLevel]);
    }
  };

  // ================================= level ======================

  // ================================= skill ======================
  useEffect(() => {
    if (uDataSkill) setFilterSkill(setDataAllModalWithStatus(uDataSkill));
  }, [uDataSkill]);

  const handleFilterSkill = (query) => {
    const filter = dataSkill.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterSkill(filter);
  };

  const changeSelectSkill = (item) => {
    setFilterSkill(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const querySkill = () => {
    let datas = [];
    filterSkill.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      skill_id: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    dataSkill?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    filterSkill?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, skill: false };
    });
    setFilterSkill(dataSkill);
  };

  const toggleSkill = () => {
    setIsModals((prev) => {
      return { ...prev, skill: !prev.skill };
    });
    if (filter?.skill_id) {
      filterSkill?.forEach((item) => {
        Number(filter?.skill_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterSkill([...dataSkill]);
    } else {
      filterSkill?.forEach((item) => {
        item.status = false;
      });
      setFilterSkill([...dataSkill]);
    }
  };

  // ================================= skill ======================

  // ================================= division ======================
  useEffect(() => {
    if (uDataDivision) setFilterDivision(setDataAllModalWithStatus(uDataDivision));
  }, [uDataDivision]);
  const handleFilterDivision = (query) => {
    const filter = dataDivision.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterDivision(filter);
  };

  const changeSelectDivision = (item) => {
    setFilterDivision(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const queryDivision = () => {
    let datas = [];
    filterDivision.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      division_order_id: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    filterDivision?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    dataDivision?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, division: false };
    });
    setFilterDivision(dataDivision);
  };

  const toggleDivision = () => {
    setIsModals((prev) => {
      return { ...prev, division: !prev.division };
    });
    if (filter?.division_order_id) {
      filterDivision?.forEach((item) => {
        Number(filter?.division_order_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterDivision([...dataDivision]);
    } else {
      filterDivision?.forEach((item) => {
        item.status = false;
      });
      setFilterDivision([...dataDivision]);
    }
  };
  // ================================= division ======================

  const handleResetFilter = () => {
    handleReset();
  };

  return (
    <div className={styles.searchPopup}>
      <InputBase
        disabled
        type="text"
        name="position_id"
        className="input-select-multi mt-2"
        onClick={() => {
          setIsModals(() => {
            return { position: true };
          });
        }}
        value={getValueInputSearchAll(filterPosition)}
        placeholder={t('masterData.StandardIndex.position')}
      />
      <ModalCommon
        items={filterPosition}
        onSubmit={queryPosition}
        onClick={changeSelectPosition}
        toggle={togglePosition}
        modal={isModals.position}
        title={t('masterData.StandardIndex.choosePosition')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('masterData.StandardIndex.position')}
            addClass="input-search-box"
            handleSearch={handleFilterPosition}
            maxLength={Constants.MAX_LENGTH_50}
            size={'small'}
          />
        }
      />

      <InputBase
        disabled
        type="text"
        name="level_id"
        className="input-select-multi mt-2"
        onClick={() => {
          setIsModals(() => {
            return { level: true };
          });
        }}
        value={getValueInputSearchAll(filterLevel)}
        placeholder={t('referralBonus.officialLevel')}
      />
      <ModalCommon
        items={filterLevel}
        onSubmit={queryLevel}
        onClick={changeSelectLevel}
        toggle={toggleLevel}
        modal={isModals.level}
        title={t('referralBonus.chooseOfficialLevel')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('referralBonus.officialLevel')}
            addClass="input-search-box"
            handleSearch={handleFilterLevel}
            maxLength={Constants.MAX_LENGTH_50}
            size={'small'}
          />
        }
      />

      <InputBase
        disabled
        type="text"
        name="skill_id"
        className="input-select-multi mt-2"
        onClick={() => {
          setIsModals(() => {
            return { skill: true };
          });
        }}
        value={getValueInputSearchAll(filterSkill)}
        placeholder={t('masterData.StandardIndex.skill')}
      />
      <ModalCommon
        items={filterSkill}
        onSubmit={querySkill}
        onClick={changeSelectSkill}
        toggle={toggleSkill}
        modal={isModals.skill}
        title={t('masterData.StandardIndex.chooseSkill')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('masterData.StandardIndex.skill')}
            addClass="input-search-box"
            handleSearch={handleFilterSkill}
            maxLength={Constants.MAX_LENGTH_50}
            size={'small'}
          />
        }
      />

      <InputBase
        disabled
        type="text"
        name="division_id"
        className="input-select-multi mt-2"
        onClick={() => {
          setIsModals(() => {
            return { division: true };
          });
        }}
        value={getValueInputSearchAll(filterDivision)}
        placeholder={t('referralBonus.division')}
      />
      <ModalCommon
        items={filterDivision}
        onSubmit={queryDivision}
        onClick={changeSelectDivision}
        toggle={toggleDivision}
        modal={isModals.division}
        title={t('referralBonus.chooseDivision')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('referralBonus.division')}
            addClass="input-search-box"
            handleSearch={handleFilterDivision}
            maxLength={Constants.MAX_LENGTH_50}
            size={'small'}
          />
        }
      />

      <ButtonBase onClick={() => handleResetFilter()} className={styles.reset} size="lg">
        <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
        {t('common.reset')}
      </ButtonBase>
    </div>
  );
}
