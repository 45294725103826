import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { TimePicker, Input } from 'antd';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import styles from './styles.module.scss';

const TimePickerCustom = (props) => {
  const { error, name, placeholder, value, format, handleChangeTime, setError, setValue, step, disabled } = props;

  const [open, setOpen] = useState(false);
  const [valueInput, setValueInput] = useState('');

  useEffect(() => {
    if (value && value.isValid()) {
      setValue && setValue(name, value);
    }
    // eslint-disable-next-line
  }, [value, disabled]);

  const handleSelectTime = (time) => {
    if (moment(time, 'HH:mm').isValid()) {
      setValueInput(moment(time).format('HH:mm'));
    }
    handleChangeTime(time);
    setError(name, '');
  };

  const handleInputTimePicker = (e) => {
    if (disabled) return;
    setValueInput(e.target.value);
    if (moment(e.target.value, 'HH:mm').isValid()) {
      setValue(name, moment(e.target.value, 'HH:mm'));
    } else {
      setValue(name, null);
    }
    setError(name, '');
  };

  const handleOpenTimePicker = () => {
    setOpen(true);
  };

  const handleCloseTimePicker = () => {
    setOpen(false);
  };

  return (
    <>
      <TimePicker
        {...props}
        disabled={disabled}
        minuteStep={step}
        id={name}
        open={open}
        name={name}
        className={!error ? styles.selectTime : styles.selectTimeError}
        placeholder={placeholder}
        format={format}
        value={moment(value).isValid() ? value : null}
        onSelect={handleSelectTime}
        suffixIcon={
          <div
            onClick={() => {
              handleOpenTimePicker();
            }}
          >
            <AccessTimeIcon />
          </div>
        }
        clearIcon={
          <div
            onClick={() => {
              handleSelectTime(null);
              setValueInput('');
            }}
          >
            <HighlightOffIcon />
          </div>
        }
        showNow={false}
        inputRender={(props) => {
          return (
            <Input
              autoComplete="off"
              id={props.name}
              className={styles.inputTime}
              placeholder={props.placeholder}
              onFocus={handleOpenTimePicker}
              onBlur={() => {
                handleCloseTimePicker();
                setValueInput('');
              }}
              value={valueInput || props.value}
              onChange={handleInputTimePicker}
            />
          );
        }}
      />
      <p className="error-input error">{error}</p>
    </>
  );
};

export default TimePickerCustom;
