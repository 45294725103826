import { Route, Switch } from 'react-router-dom';
import InterviewSchedule from './InterviewSchedule';

const Index = () => {
  return (
    <>
      <Switch>
        <Route path="/admin/interview-schedule" exact render={() => <InterviewSchedule />} />
        <Route path="/admin/interview-schedule/:interviewDate/:interviewId" exact render={() => <InterviewSchedule />} />
      </Switch>
    </>
  );
};

export default Index;
