export const ACTIVE = 1;
export const INACTIVE = 0;

// ========= manage test =========
export const TYPE_QUESTION_TEST = {
  GUIDE: 1,
  CHECKBOX: 2,
  RADIO: 3,
};

// ========= testing =========
export const STATUS_TESTING = {
  PENDING: 1,
  STARTED: 2,
  FINISHED: 3,
  CANCELED: 4,
};
