import { useQuery } from 'react-query';
import { apiGetApplyCandidate } from 'api/managerCandidate';
import * as Constants from 'constants/ManagerCandidate';
import { HTTP_OK } from 'constants/StatusCode';
import { HTTP_INTERNAL_SERVER_ERROR } from 'constants/StatusCode';

export function useGetApplyCandidate(applyId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL, { params, apply_id: applyId }],
    async () => {
      const response = await apiGetApplyCandidate(applyId, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: !!applyId },
  );

  if (response?.status === HTTP_INTERNAL_SERVER_ERROR) {
    return { error, isLoading, data: null };
  }
  return { ...response, error, isLoading, data: { ...response?.data } };
}
