import React from 'react';

import './../styles.scss';

import BodyStatusTest from './BodyStatusTest';

import { useGetListTest } from 'hook/useDetailTest';

const HrTest = ({ applyId }) => {
  const { data: dataListTest } = useGetListTest({ applyId: applyId });

  return (
    <div>
      <BodyStatusTest dataListTest={dataListTest} isHr />
    </div>
  );
};

export default HrTest;
