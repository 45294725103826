import React, { useCallback, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import {
  ButtonBase,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  TablePagination,
  Paper,
} from '@mui/material';
import classNames from 'classnames';
import { debounce } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import iconExport from 'assets/img/icons/export.svg';
import iconLoading from 'assets/img/icons/loading.png';

import styles from './styles.module.scss';
import stylesTable from 'styles/table.module.scss';

import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import SearchComponent from 'components/Search';
import ModalCommon from 'components/ModalCommon/ModalCommon';

import { ZERO } from 'constants/MasterData';
import {
  STATUS_PROCESSING,
  STATUS_COMPLETED,
  STATUS_UNCOMPLETED,
  STATUS_PENDING,
  LIST_STATUS_KPI,
} from 'constants/kpi';
import { ALL, DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20, PER_PAGE_OPTION_20 } from 'constants/index';

import { ThemContext } from 'layouts/Admin';
import { getColorStatus, getName, sumValueOfArrayObject } from 'helpers/index';
import { getSTT, displayLabelTablePagination } from 'helpers/table';
import { handleCheck, getValueInputSearchAll } from 'helpers/modal';
import { customDate } from 'helpers/formatDate';
import { useGetListKPI } from '../../../hook/useManagerKPI';
import { useGetListHRNoKPI } from 'hook/useManagerKPI';
import ModalCreateKPi from './ModalCreateKPi.js';
import ModalDetailKPI from './ModalDetailKPI';
import ModalExportKPI from './ModalExportKPI';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import { KPI_PERMISSION } from 'constants/permission';
import EditLayout from 'components/PermissionPage/EditLayout';

const CHANGE_DATE_FILTER = {
  NEXT: 1,
  PREVIOUS: -1,
};

const FILTER_DEFAULT = {
  status: null,
  key_word: null,
  page: DEFAULT_PAGE_MUI,
  per_page: DEFAULT_LIMIT_20,
  date: moment().startOf('month'),
};

const IconExport = () => <img src={iconExport} alt={iconExport} />;

const ListManagerKpi = () => {
  const { t } = useTranslation();
  const { kpiId } = useParams();
  const STATUS_KPI_DEFAULT = [
    { name: t('common.all'), value: ALL, status: false },
    { name: t('managerKPI.status.processing'), value: STATUS_PROCESSING, status: false },
    { name: t('managerKPI.status.completed'), value: STATUS_COMPLETED, status: false },
    { name: t('managerKPI.status.uncompleted'), value: STATUS_UNCOMPLETED, status: false },
    { name: t('managerKPI.status.pending'), value: STATUS_PENDING, status: false },
  ];

  const [isModals, setIsModals] = useState({ status: false });
  const [isModalCreateKPI, setModalCreateKPI] = useState(false);
  const [statusKpi, setStatusKpi] = useState(STATUS_KPI_DEFAULT);
  const [filterStartKPI, setFilterStartKPI] = useState(STATUS_KPI_DEFAULT);
  const [filter, setFilter] = useState(FILTER_DEFAULT);
  const [isReset, setIsReset] = useState(false);
  const [isFullKPI, setIsFullKPI] = useState(false);
  const [showDetailKPI, setShowDetailKPI] = useState({ active: false, id: null });
  const [searchNameHr, setSearchNameHr] = useState();
  const [dateCreateKPI, setDateCreateKPI] = useState(filter.date);
  const { isHRM } = useContext(ThemContext);

  const [showExportKPI, setShowExportKPI] = useState(false);

  const { data: dataListHRNotKPI, isLoading: isLoadingHRNoKPI } = useGetListHRNoKPI({
    name: searchNameHr ?? null,
    date: moment(dateCreateKPI).format('MM/YYYY'),
  });

  const { data: dataListKPI, isLoading: isLoadingKPI } = useGetListKPI({
    ...filter,
    page: filter.page + 1,
    date: isHRM ? filter.date.format('MM-YYYY') : null,
    year: !isHRM ? filter.date.format('YYYY') : null,
  });

  useEffect(() => {
    if (kpiId) setShowDetailKPI({ active: true, id: kpiId });
  }, [kpiId]);

  useEffect(() => {
    setDateCreateKPI(filter.date);
  }, [filter]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeCurrentDate = useCallback(
    debounce((value) => {
      const dateSearch = moment(filter.date).add(value, isHRM ? 'month' : 'year');
      setFilter({
        ...filter,
        date: dateSearch,
        page: DEFAULT_PAGE_MUI,
      });
    }),
  );

  const handleReset = () => {
    setIsReset(true);
    setFilter({ ...FILTER_DEFAULT, date: filter.date });
    setStatusKpi(STATUS_KPI_DEFAULT);
    setFilterStartKPI(STATUS_KPI_DEFAULT);
  };

  const openModalCreateKPI = () => {
    if (isLoadingHRNoKPI) return;
    if (dataListHRNotKPI?.length && !searchNameHr) setModalCreateKPI(true);
    else setIsFullKPI(true);
  };

  const exportListKPI = () => {
    setShowExportKPI(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        key_word: value,
        page: DEFAULT_PAGE_MUI,
      });
    }, 500),
  );

  const changeSelectStatus = (item) => {
    setStatusKpi(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const handleChangeSelectedStatus = () => {
    setIsModals((prev) => {
      return { ...prev, status: !prev.status };
    });
    if (filter?.status) {
      filterStartKPI?.forEach((item) => {
        Number(filter?.status) === Number(item?.value) ? (item.status = true) : (item.status = false);
      });
      setFilterStartKPI([...filterStartKPI]);
    } else {
      filterStartKPI?.forEach((item) => {
        item.status = false;
      });
      setFilterStartKPI([...filterStartKPI]);
    }
  };

  const onSubmitSearchStatus = () => {
    let status;
    statusKpi.forEach((it) => {
      if (it.status && it.value && it.value !== ALL) status = it.value;
    });
    setFilterStartKPI(statusKpi);
    setFilter({
      ...filter,
      status: status,
      page: DEFAULT_PAGE_MUI,
    });
    setIsModals((prev) => {
      return { ...prev, status: false };
    });
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const getFormatFilterDate = () => {
    if (!isHRM) return 'YYYY';
    return `[${t('common.month')}] M / YYYY`;
  };

  const getTotalKPIInYear = () => {
    return sumValueOfArrayObject(dataListKPI?.data, 'total') + '/' + sumValueOfArrayObject(dataListKPI?.data, 'target');
  };

  return (
    <div className={styles.managerKpi}>
      <Grid container className={styles.headerContainer}>
        <div className={styles.headTitle}>
          <p className={styles.title}>{t('manageKPI.manageKPI')}</p>
          <div className={styles.schedule}>
            <div className={styles.schedule__navigator}>
              <button className={styles.previous} onClick={() => handleChangeCurrentDate(CHANGE_DATE_FILTER.PREVIOUS)}>
                <i className="fas fa-angle-left" />
              </button>
              <div className={styles.currentDate}>{filter.date.format(getFormatFilterDate())}</div>
              <button className={styles.next} onClick={() => handleChangeCurrentDate(CHANGE_DATE_FILTER.NEXT)}>
                <i className="fas fa-angle-right" />
              </button>
            </div>
          </div>
        </div>
        {!isHRM && (
          <div className={styles.totalKPIContainer}>
            <span className={styles.textTotal}>{t('managerKPI.totalInYear')}</span>
            <span className={styles.textTotal}>{getTotalKPIInYear()}</span>
          </div>
        )}
      </Grid>

      <Grid container className="pt-2" gap={2}>
        <Grid item flex={10}>
          <div className="table-border-show">
            <Paper>
              <TableContainer className={stylesTable.tableCommon}>
                <Table aria-label="sticky table">
                  <TableHead className="asset-header">
                    <TableRow className="asset-header-row headTable">
                      <TableCell>{t('common.STT')}</TableCell>
                      {isHRM && <TableCell>{t('manageKPI.fullNameHR')}</TableCell>}
                      {!isHRM && <TableCell>{t('common.month')}</TableCell>}
                      <TableCell>{t('manageKPI.KPI')}</TableCell>
                      <TableCell>{t('manageKPI.actualData')}</TableCell>
                      <TableCell>{t('manageKPI.deadline')}</TableCell>
                      <TableCell>{t('common.status')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingKPI && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Loading addClass="mt-3 mb-3" />
                        </TableCell>
                      </TableRow>
                    )}

                    {!isLoadingKPI &&
                      dataListKPI?.data?.map((item, index) => (
                        <TableRow
                          key={item.id}
                          className={stylesTable.rowTable}
                          onClick={() => setShowDetailKPI({ active: true, id: item.id })}
                        >
                          <TableCell className={stylesTable.sttCell}>
                            {getSTT(index + filter.page * filter.per_page)}
                          </TableCell>
                          {isHRM && <TableCell className={stylesTable.normalCell}>{item.user_info.name}</TableCell>}
                          {!isHRM && (
                            <TableCell className={stylesTable.normalCell}>
                              {customDate(item.deadline, 'MM/YYYY')}
                            </TableCell>
                          )}
                          <TableCell className={stylesTable.normalCell}>{item.target}</TableCell>
                          <TableCell className={stylesTable.normalCell}>
                            {item.total}/{item.target}
                          </TableCell>
                          <TableCell className={stylesTable.normalCell}>
                            {customDate(item.deadline, 'DD/MM/YYYY')}
                          </TableCell>
                          <TableCell
                            className={classNames(styles.status, styles[getColorStatus(LIST_STATUS_KPI, item?.status)])}
                          >
                            {t(getName(LIST_STATUS_KPI, item?.status))}
                          </TableCell>
                        </TableRow>
                      ))}

                    {(!dataListKPI && !isLoadingKPI) ||
                      (dataListKPI?.total === 0 && (
                        <tr>
                          <td colSpan={6} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {dataListKPI?.total > 0 && isHRM && (
                <TablePagination
                  rowsPerPageOptions={PER_PAGE_OPTION_20}
                  component="div"
                  count={dataListKPI?.total}
                  rowsPerPage={filter?.per_page}
                  page={filter.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagination"
                  labelDisplayedRows={displayLabelTablePagination}
                />
              )}
            </Paper>
          </div>
        </Grid>

        {isHRM && (
          <Grid item flex={2} className={styles.filter}>
            {!moment(filter.date).isBefore(moment(), 'month') && (
              <EditLayout permissionName={KPI_PERMISSION}>
                <ButtonComponent
                  addClass={classNames(`button-add-menu-right ${styles.buttonCreate}`)}
                  icon={<AddIcon />}
                  text={t('manageKPI.createKPI')}
                  handleClick={openModalCreateKPI}
                />
              </EditLayout>
            )}

            <ButtonComponent
              addClass="button-add-menu-right"
              icon={<IconExport />}
              text={t('common.export')}
              handleClick={exportListKPI}
            />

            <div className="info-menu-right-no-image selected mt-3">
              <div className="info-menu-righ-title">{t('managerKPI.totalHR')}</div>
              <div className="info-menu-righ-total">{dataListKPI ? dataListKPI.data.length : 0}</div>
            </div>

            <SearchComponent
              txtPlaceholder={t('common.hr')}
              addClass={styles.searchHr}
              handleSearch={handleSearch}
              isReset={isReset}
              setIsReset={setIsReset}
            />

            <InputBase
              disabled
              type="text"
              name="status"
              className="input-select-multi"
              onClick={() => {
                setIsModals(() => {
                  return { status: true };
                });
              }}
              value={getValueInputSearchAll(filterStartKPI)}
              placeholder={t('common.status')}
            />

            <div className="btn-reset">
              <ButtonBase onClick={() => handleReset()} className="reset mt-3" size="lg">
                <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                {t('common.reset')}
              </ButtonBase>
            </div>
          </Grid>
        )}
      </Grid>

      <ModalCommon
        items={statusKpi}
        onSubmit={onSubmitSearchStatus}
        onClick={changeSelectStatus}
        toggle={handleChangeSelectedStatus}
        modal={isModals.status}
        title={t('managerOrderHr.status')}
        footer={t('common.select')}
      />
      {isModalCreateKPI && (
        <ModalCreateKPi
          isModalCreateKPI={isModalCreateKPI}
          setModalCreateKPI={setModalCreateKPI}
          setSearchNameHr={setSearchNameHr}
          listHrNoKPI={dataListHRNotKPI}
          isLoadingHRNoKPI={isLoadingHRNoKPI}
          deadline={moment(filter.date)}
          setDateCreateKPI={setDateCreateKPI}
        />
      )}
      <ModalDetailKPI showDetailKPI={showDetailKPI} setShowDetailKPI={setShowDetailKPI} isHRM={isHRM} />
      <ModalExportKPI isShowModal={showExportKPI} setShowModal={setShowExportKPI} />
      <AlertAfterSubmit toggle={() => setIsFullKPI(false)} isShowAlert={isFullKPI}>
        <p className={styles.fullKPIAlert}>
          {t('managerKPI.fullKPIThisMonth', { month: moment(filter.date).format('M') })}
        </p>
      </AlertAfterSubmit>
    </div>
  );
};

export default ListManagerKpi;
