import { Grid, MenuItem } from '@mui/material';
import SelectComponent from 'components/Select/Select.js';
import React, { useCallback } from 'react';
import { MdDelete } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import InputField from 'components/Input/InputField';
import { useGetListLanguage } from 'hook/useManagerCandidate';
const AddMoreLanguage = ({ remove, candidate_languages, control, Controller, errors, append, mapLanguage, padding }) => {
  const { t } = useTranslation();
  const { data: uDataListLanguage } = useGetListLanguage();
  const addMoreLanguage = () => {
    append({ language_id: undefined, level: '' });
  };

  const handleDeleteLanguage = (index) => {
    if (candidate_languages.length > 1) remove(index);
  };
  
  const RenderListLanguage = useCallback(
    () => (
      <>
        {candidate_languages.map((item, index) => ( 
          <Grid container key={index} className="mt-4" md={12}>
            <Grid item md={5} className="item-field">
              <Controller
                control={control}
                name={`candidate_languages.${index}.language_id`}
                defaultValue={item.language_id}
                render={({ field }) => (
                  <SelectComponent
                    field={field}
                    addClass="select flex-1"
                    label={t('managerCandidate.language')}
                  >
                    {uDataListLanguage &&
                      uDataListLanguage?.map((item, index) => {
                        mapLanguage.current[item.id] = item.name;
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </SelectComponent>
                )}
              />
            </Grid>
            <Grid item md={7} className="item-field pl-4">
              <Grid container>
                <Grid item md={10} className="relative z-5">
                  <Controller
                    control={control}
                    name={`candidate_languages.${index}.level`}
                    defaultValue={item.level}
                    render={({ field }) => (
                      <InputField
                        field={field}
                        label={t('managerCandidate.enterLevel')}
                        variant="outlined"
                        onHandleInput={() => {}}
                        error={errors?.[`candidate_languages`]?.[index]?.['level']?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  className="d-flex align-items-center justify-content-end btn-delete-question position-relative"
                >
                  <div
                    className="circle-delete-button color-button m-0"
                    onClick={handleDeleteLanguage.bind(this, index)}
                  >
                    <MdDelete />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </>
    ),
    [candidate_languages, uDataListLanguage, errors],
  );
  return (
    <Grid container className={padding ?? 'plt-24'}>
      <Grid item md={12}>
        <span className="label">{t('managerCandidate.language')}</span>
      </Grid>
      <RenderListLanguage />
      <Grid item>
        <div className="p-3" onClick={addMoreLanguage}>
          <BsPlus /> {t('managerCandidate.addLanguage')}
        </div>
        {errors?.candidate_languages?.message && <p className="error-input error">{errors?.candidate_languages?.message}</p>}
      </Grid>
    </Grid>
  );
};
export default AddMoreLanguage;
