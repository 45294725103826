import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';

import ModalComponent from 'components/Modal';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';

import { ModalFooter } from 'reactstrap';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as Constants from 'constants/index.js';
import { useMutation } from 'react-query';

const FORM_FIELDS = {
  reason: 'note',
};

const ModalReason = (props) => {
  const { t } = useTranslation();
  const { title, label, isShowModal, setIsShowModal, id, onSuccess, returnAfterClose, newStatus, api } = props;

  const DEFAULT_VALUES = {
    [FORM_FIELDS.reason]: '',
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        [FORM_FIELDS.reason]: Yup.string().trim().required(t('common.pleaseEnter10To500Characters')),
      }),
    ),
    defaultValues: DEFAULT_VALUES,
    mode: 'onSubmit',
  });

  const handleCloseModal = () => {
    setIsShowModal(false);
    reset();
    returnAfterClose();
  };

  const { mutate: submitReason } = useMutation(
    async (values) => {
      await api({ id: id, note: values[FORM_FIELDS.reason], status: newStatus });
    },
    {
      onSuccess: async () => {
        setIsShowModal(false);
        onSuccess();
        reset();
      },
      onError: () => {
        // setError(FORM_FIELDS.reason, { type: 'custom', message: t('common.anUnknownError') });
      },
    },
  );

  const validateAndSubmit = (values) => {
    if (values[FORM_FIELDS.reason].length < 10 || values[FORM_FIELDS.reason].length > 500) {
      setError(FORM_FIELDS.reason, { type: 'custom', message: t('common.pleaseEnter10To500Characters') });
      return;
    }
    submitReason(values);
  };

  return (
    <ModalComponent
      title={title}
      isShowModal={isShowModal}
      toggle={handleCloseModal}
      renderModalFooter={() => (
        <ModalFooter className={styles.modalFooter}>
          <button type="button" className={classNames(styles.btn, styles.btn__cancel)} onClick={handleCloseModal}>
            {t('modalOrder.cancel')}
          </button>
          <button
            type="button"
            className={classNames(styles.btn, styles.btn__action)}
            onClick={handleSubmit(validateAndSubmit)}
          >
            {t('modalOrder.send')}
          </button>
        </ModalFooter>
      )}
    >
      <form>
        <CustomInputYup
          name={FORM_FIELDS.reason}
          label={label}
          placeholder={label}
          maxLength={Constants.MAX_LENGTH_500}
          control={control}
          useWatch={useWatch}
          register={register}
          errors={errors}
          defaultValue={DEFAULT_VALUES[FORM_FIELDS.note]}
          required
          multiline
          rows={7}
        />
      </form>
    </ModalComponent>
  );
};

export default ModalReason;
