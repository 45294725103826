import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';

import { Grid, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import styles from './styles.module.scss';
import stylesPopupAreYouSure from 'components/PopupAreYouSure/styles.module.scss';
import { errorSubmit } from 'helpers/index';
import { sendPost } from 'helpers/axios';
import { uniqueArr } from 'helpers/index';
import { useListSkill, useListPosition, useListLevel } from 'hook/useMasterData';

import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Buttons/index';
import FormCreateQuestion from './FormCreateQuestion';
import Popup from 'components/popup/index';
import { CODE_DATA_EXISTS, managerTest } from 'constants/index';
import ListPreviewQuestion from './ListPreviewQuestion';
import BoxItemAddQuestion from './BoxItemAddQuestion';
import SelectedGroupIds from './SelectedGroupIds';

const CreateGroupTest = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const breadcrumb = [
    { link: '/admin/master-data/manager-group-test', name: t('managerTest.managerGroupTestBr') },
    { link: '/admin/master-data/manager-group-test/create', name: t('managerTest.createQuestion') },
  ];
  const query = new useQueryClient();
  const mapPosition = useRef({});
  const mapLevel = useRef({});
  const message = useRef('');
  const counterQUestion = useRef({ type: 'counter', value: 1 });
  const filesUpload = useRef([]);
  const actionType = useRef(null);
  const refUploadFile = useRef();
  const tabFormData = useRef('checkbox');
  const [loading, setLoading] = useState(false);
  const [addQuestionSuccess, setAddQuestionSuccess] = useState(false);
  const [addQuestionError, setAddQuestionError] = useState(false);
  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });
  const { data: uDataLevel } = useListLevel({ key_word: '' });
  const [formData] = useState({ group_question: [{ 'content-answer': '' }], contentQuestion: '' });
  const [list_question_ids, setListQuestionIds] = useState([]);
  const [listQuestionPreview, setListQuestionPreview] = useState([]);
  const [createQuestion, setCreateQuestion] = useState(true);
  const [isShowBtnQuestion, setIsShowBtnQuestion] = useState(false);

  const [isEditQuestion, setEditQuestion] = useState(false);
  const [isDisableSave, setIsDisable] = useState(true);
  const [imgDelete, setImgDelete] = useState([]);

  const onChangeDeleteImg = (index) => {
    setImgDelete((prev) => {
      const newData = structuredClone(prev);
      newData.push(index);
      return newData;
    });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    resetField,
    register,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        position_id: Yup.mixed().test('required', t('managerTest.positionRequired'), (val) => {
          return actionType.current === 'submit' ? val && +val >= 0 : true;
        }),
        skill_id: Yup.mixed().test('required', t('managerTest.skillRequired'), (val) => {
          return actionType.current === 'submit' && newSkill.length > 0 ? val && +val >= 0 : true;
        }),
        level_id: Yup.mixed().test('required', t('managerTest.levelRequired'), (val) => {
          return actionType.current === 'submit' ? val && +val >= 0 : true;
        }),
        contentQuestion: Yup.string().test('minmax', t('managerTest.requiredContentQuestion'), (val) => {
          return actionType.current === 'submit' && list_question_ids.length > 0
            ? true
            : val.trim().length >= 10 && val.trim().length <= 200;
        }),
        group_question: Yup.array()
          .of(
            Yup.object().shape({
              'content-answer': Yup.string().test('min', t('managerTest.minContentAnswer'), (val) => {
                return tabFormData.current === 'guide' || actionType.current === 'submit'
                  ? true
                  : val && val.trim().length > 0;
              }),
            }),
          )
          .test('min', t('managerTest.minArrContentAnswer'), (val) => {
            return (actionType.current === 'submit' && list_question_ids.length > 0) || tabFormData.current === 'guide'
              ? true
              : val.length >= 2;
          }),
      }),
    ),
    defaultValues: formData,
    mode: 'onChange',
  });
  const {
    fields: group_question,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'group_question',
  });

  const positionId = useWatch({
    control,
    name: 'position_id',
  });

  const skillId = useWatch({
    control,
    name: 'skill_id',
  });

  const levelId = useWatch({
    control,
    name: 'level_id',
  });

  const newSkill = useMemo(
    () => uDataSkill?.filter((item) => item.position_id === positionId),
    [uDataSkill, positionId],
  );
  const resetForm = () => {
    resetField('group_question');
    resetField('contentQuestion');
    refUploadFile.current.clearUploadFile();
    setError('group_question', null);
  };

  const handleError = (res) => {
    if (errorSubmit(res?.data?.code) || res.data.status === 422 || res.data.status === 500) {
      if (res?.data?.code === CODE_DATA_EXISTS || res.data.status === 422) {
        message.current = t('managerTest.questionAlreadyExists');
      } else {
        message.current = t(res?.data?.code ? errorSubmit(res?.data?.code) : 'managerTest.somethingWenWrong');
      }
      setAddQuestionError(true);
      setLoading(false);
      return true;
    }
  };

  const onSubmit = async (data) => {
    if (!loading) {
      setLoading(true);
      if (actionType.current === 'add') {
        const group_question = getValues('group_question');
        const contentQuestion = getValues('contentQuestion');
        const tab = {
          guide: 1,
          checkbox: 2,
          radio: 3,
        };
        const payload = {
          type: tab[tabFormData.current],
          content: contentQuestion,
          img_bonus: [],
        };

        // add image old in list question
        if (counterQUestion.current.id) {
          listQuestionPreview.forEach((item) => {
            if (counterQUestion.current.id === item.id) {
              if (typeof item.img_bonus === 'string') {
                JSON.parse(item.img_bonus)?.forEach((pathImg) => {
                  payload.img_bonus.push(pathImg);
                });
              } else {
                item.img_bonus?.forEach((pathImg) => {
                  payload.img_bonus.push(pathImg);
                });
              }
            }

            // check deleted
            if (imgDelete) {
              payload.img_bonus?.forEach((pathImg) => {
                if (imgDelete.includes(pathImg)) {
                  const index = payload.img_bonus.indexOf(pathImg);
                  delete payload.img_bonus[index];
                }
              });
            }
          });
        }
        payload.img_bonus = [...payload.img_bonus, ...filesUpload.current];
        payload.img_bonus = uniqueArr(payload.img_bonus);

        if (tab[tabFormData.current] > 1) {
          payload.list_answer = group_question.map((item) => item['content-answer']);
        }
        if (counterQUestion.current.id) {
          payload.id = counterQUestion.current.id;
        }
        const res = await sendPost(`/api/question/save`, payload);
        if (payload.img_bonus) {
          payload.img_bonus = payload.img_bonus.map((itemImage) =>
            itemImage.replace('temp', itemImage.slice(itemImage.lastIndexOf('.') + 1)),
          );
          filesUpload.current = filesUpload.current.map((itemImage) =>
            itemImage.replace('temp', itemImage.slice(itemImage.lastIndexOf('.') + 1)),
          );
        }
        if (handleError(res)) return;
        filesUpload.current = [];
        const resId = res.data.id ? res.data.id : payload.id;
        payload.id = res.data.id ? res.data.id : counterQUestion.current.id;
        if (counterQUestion.current.type === 'edit') {
          const newData = structuredClone(listQuestionPreview);
          const newIds = structuredClone(list_question_ids);
          newData[counterQUestion.current.value - 1] = payload;
          newIds[counterQUestion.current.value - 1] = resId;
          setListQuestionPreview([...newData]);
          setListQuestionIds([...newIds]);
          resetForm();
          counterQUestion.current = { value: listQuestionPreview.length + 1, type: 'counter' };
          setLoading(false);
          return setCreateQuestion(false);
        }
        resetForm();
        setListQuestionIds((prev) => {
          const newData = structuredClone(prev);
          newData.push(resId);
          return newData;
        });
        setListQuestionPreview((prev) => {
          const newData = structuredClone(prev);
          newData.push(payload);
          return newData;
        });
        setCreateQuestion(false);
      } else {
        const payload = {
          level_id: data.level_id,
          position_id: data.position_id,
          skill_id: data.skill_id <= 0 ? null : data.skill_id,
          list_question_ids: list_question_ids,
        };
        if (!list_question_ids.length) {
          message.current = t('messages.manageGroupTest.pleaseEnterAtLeastOneQuestion');
          setAddQuestionError(true);
          setLoading(false);
          return;
        }
        const res = await sendPost(`/api/group-question/save`, payload);
        if (handleError(res)) return;
        setAddQuestionSuccess(true);
      }
      filesUpload.current = [];
      setLoading(false);
      setIsShowBtnQuestion(true);
    }
  };

  const handleSubmitData = (data) => {
    if (actionType.current === 'submit' && (getValues('contentQuestion') || counterQUestion.value)) {
      confirmAlert({
        title: t('managerTest.confirmSaveManagerTest', {
          type_name: isEditQuestion ? t('common.update') : t('common.add'),
        }),
        overlayClassName: 'confirm-saveManager-test',
        buttons: [
          {
            label: t('common.back'),
            className: 'btn-alert-cancel text-left',
          },
          {
            label: t('managerTest.save'),
            className: 'btn-alert-ok',
            onClick: async () => {
              onSubmit(data);
            },
          },
        ],
      });
    } else {
      onSubmit(data);
    }
  };

  const handleUpdateQuestion = (index) => {
    if (group_question.length - 1 === index) {
      append({ content: '' });
      setError('group_question', null);
    } else {
      remove(index);
    }
  };
  const FormCreateQuestionComp = useCallback(
    ({ errors }) => (
      <FormCreateQuestion
        Controller={Controller}
        t={t}
        handleUpdateQuestion={handleUpdateQuestion}
        group_question={group_question}
        control={control}
        errors={errors}
        tabFormData={tabFormData}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [group_question],
  );

  const handleAppendQuestion = () => {
    actionType.current = 'add';
  };

  const handleDeleteQuestion = (index) => {
    setListQuestionPreview((prev) => {
      const newData = structuredClone(prev);
      newData.splice(index, 1);
      return newData;
    });
    setListQuestionIds((prev) => {
      const newData = structuredClone(prev);
      newData.splice(index, 1);
      return newData;
    });
  };

  const handleActionSubmit = () => {
    actionType.current = 'submit';
  };

  const handleEditQuestion = useCallback(
    (item, index) => {
      setCreateQuestion(true);
      counterQUestion.current = { value: index, type: 'edit', id: item.id };
      tabFormData.current = item.type === 1 ? 'guide' : item.type === 2 ? 'checkbox' : 'radio';
      if (item.content?.length > 9) {
        clearErrors('contentQuestion');
      }
      setValue('contentQuestion', item.content);
      if (item.list_answer) {
         item.list_answer.forEach((value) => {
           if (value.trim().length > 0) return clearErrors('group_question');
         });
        setValue(
          'group_question',
          item.list_answer.map((item) => ({ 'content-answer': item })),
        );
      } else {
        setValue('group_question', []);
      }
      if (item.img_bonus.length) refUploadFile.current.updateListImgUpload(item.img_bonus);
      else refUploadFile.current.updateListImgUpload([]);
      refUploadFile.current.setErrorFile('');
    },
    [setValue, clearErrors],
  );

  const handleCreateQuestion = () => {
    counterQUestion.current = { type: 'counter', value: 1 };
    setCreateQuestion(true);
    setEditQuestion(false);
    setIsShowBtnQuestion(false);
    resetForm();
    refUploadFile.current.setErrorFile('');
  };

  const handleSetEditQuestion = () => {
    setEditQuestion(true);
    setIsShowBtnQuestion(true);
  };

  useEffect(() => {
    if (!!listQuestionPreview.length && positionId && levelId) {
      if (newSkill?.length && skillId) setIsDisable(false);
      if (!newSkill?.length) setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [listQuestionPreview, positionId, newSkill, skillId, levelId]);

  return (
    <Grid container className="content manager-group-test manager-test pb-5">
      <Popup
        active={addQuestionSuccess}
        handleCancel={() => setAddQuestionSuccess(false)}
        footer={
          <>
            <button
              className={`btn-transparent color-yellow ${stylesPopupAreYouSure.btnOk}`}
              onClick={() => {
                query.refetchQueries(managerTest.USE_LIST_MANAGER_GROUP_TEST);
                setAddQuestionSuccess(false);
                history.push(`/admin/master-data/manager-group-test`);
              }}
            >
              {t('managerTest.done')}
            </button>
          </>
        }
        messages={t('managerTest.addQuestionSuccess')}
      />
      <Popup
        active={addQuestionError}
        handleCancel={() => setAddQuestionError(false)}
        footer={
          <>
            <button
              className={`btn-transparent color-yellow ${stylesPopupAreYouSure.btnOk}`}
              onClick={() => {
                // query.refetchQueries(managerJob.USE_LIST_APPLIES_AVAILABLE);
                setAddQuestionError(false);
              }}
            >
              {t('managerTest.done')}
            </button>
          </>
        }
        messages={message.current}
      />

      <Breadcrumb data={breadcrumb} />

      <Grid item xs={12} className="content-component">
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Grid item xs={12} className="d-flex align-items-center justify-content-between title-outside">
            <h3 className={styles.titlePage}>{t('managerTest.createQuestionTest')}</h3>
          </Grid>
          <Paper className="p-20">
            <Grid item xs={12} className="d-flex align-items-center justify-content-between">
              <h4 className={styles.infoQuestionTest}>{t('managerTest.infoQuestionTest')}</h4>
              <div className="fnc-btn">
                <Button
                  text={t('managerTest.cancel')}
                  addClass="btn-bg-yellow2 mr-3 w-92 h-36 btn-cancel-create-test"
                  handleClick={() => {
                    history.push(breadcrumb[0].link);
                  }}
                />
                <Button
                  text={t('managerTest.create')}
                  addClass="btn-bg-yellow1 w-92 h-36"
                  type="submit"
                  handleClick={handleActionSubmit}
                  isLoading={loading}
                  hasDisabled={loading || isDisableSave}
                />
              </div>
            </Grid>
            <div className="divider mt-15" />
            <SelectedGroupIds
              t={t}
              Controller={Controller}
              control={control}
              formData={formData}
              errors={errors}
              uDataPosition={uDataPosition}
              setValue={setValue}
              mapPosition={mapPosition}
              newSkill={newSkill}
              uDataLevel={uDataLevel}
              mapLevel={mapLevel}
              register={register}
            />
            {listQuestionPreview.length > 0 && (
              <Grid item xs={12} className="mt-15">
                <span className={styles.infoGeneral}>{t('managerTest.infoTest')}</span>
                <ListPreviewQuestion
                  t={t}
                  listQuestionPreview={listQuestionPreview}
                  handleDeleteQuestion={handleDeleteQuestion}
                  handleEditQuestion={handleEditQuestion}
                  enableActionQuestion={true}
                  setEditQuestion={handleSetEditQuestion}
                  filesUpload={filesUpload}
                />
              </Grid>
            )}
            <BoxItemAddQuestion
              Controller={Controller}
              createQuestion={createQuestion}
              counterQUestion={counterQUestion}
              control={control}
              formData={formData}
              errors={errors}
              loading={loading}
              handleAppendQuestion={handleAppendQuestion}
              t={t}
              filesUpload={filesUpload}
              refUploadFile={refUploadFile}
              setLoading={setLoading}
              FormCreateQuestionComp={FormCreateQuestionComp}
              nextQuestion={listQuestionPreview.length + 1}
              isEditQuestion={isEditQuestion}
              handleCreateQuestion={handleCreateQuestion}
              onChangeDeleteImg={onChangeDeleteImg}
            />
            <div className="d-inline-block">
              {isShowBtnQuestion ? (
                <div
                  className="append-question d-flex align-items-center position-relative mt-20"
                  onClick={handleCreateQuestion}
                >
                  <button type="button" className="create-question position-absolute z-9" />
                  <AddIcon />
                  <span>{t('managerTest.addQuestion')}</span>
                </div>
              ) : (
                <div className="disabled-append-question d-flex align-items-center position-relative mt-20">
                  <button type="button" className="create-question position-absolute z-9" />
                  <AddIcon />
                  <span>{t('managerTest.addQuestion')}</span>
                </div>
              )}
            </div>
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
};

export default CreateGroupTest;
