import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { apiSaveTakeTest } from 'api/testing';

import '../ManagerGroupTest/styles.scss';
import ListPreviewQuestion from './ListPreviewQuestion';
import Header from './Header';

import Popup from 'components/popup/index';
import Loading from 'components/Loading';

import { showAlert } from 'helpers/modal';
import { useListSkill, useListLevel, useListPosition } from 'hook/useMasterData';
import { useGetTestingInfo } from 'hook/useManagerGroupTest';

import { STATUS_TESTING } from 'constants/managerTest';
import { HTTP_OK } from 'constants/StatusCode';
import classNames from 'classnames';
import { TYPE_QUESTION_TEST } from 'constants/managerTest';

const StartTesting = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [isDoing, setIsDoing] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [done, setDone] = useState(false);
  const [timeEnd, setTimeEnd] = useState(false);
  const [dataQuestion, setDataQuestion] = useState([]);

  const { data, isLoading, code } = useGetTestingInfo({ identity_id: id });
  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });
  const { data: uDataLevel } = useListLevel({ key_word: '' });

  const ref = useRef({});

  const mapPosition = useRef({});
  const mapLevel = useRef({});
  const mapSkill = useRef({});

  useEffect(() => {
    if (data) {
      if (data.status === STATUS_TESTING.FINISHED || data.status === STATUS_TESTING.CANCELED) {
        history.push(`/user/testing/${id}/start/notify`);
      } else if (data.status === STATUS_TESTING.PENDING) {
        history.push(`/user/testing/${id}`);
      } else {
      }
    }

    if (data && data?.list_questions) {
      setDataQuestion(JSON.parse(data?.list_questions));
    }
  }, [data, history, id]);

  useEffect(() => {
    if (code && !isLoading) {
      history.push(`${window.location.pathname.replace(/\/$/, '')}/notify`);
    }
  }, [code, history, isLoading]);

  useCallback(() => {
    if (uDataSkill) {
      uDataSkill.map((item) => {
        mapSkill.current[item.id] = item.name;
        return item;
      });
    }
  }, [uDataSkill])();

  useCallback(() => {
    if (uDataLevel) {
      uDataLevel.map((item) => {
        mapLevel.current[item.id] = item.name;
        return item;
      });
    }
  }, [uDataLevel])();

  useCallback(() => {
    if (uDataPosition) {
      uDataPosition.map((item) => {
        mapPosition.current[item.id] = item.name;
        return item;
      });
    }
  }, [uDataPosition])();

  const { mutate: saveTakeTest } = useMutation(
    (formData) =>
      apiSaveTakeTest({
        identity_id: id,
        list_answers: formData,
      }),
    {
      onSuccess: (response) => {
        if (response.status === HTTP_OK) {
          history.push(`/user/testing/${id}/start/notify`);
        } else {
          if (response?.data?.message) {
            showAlert(response.data.message);
          } else {
            showAlert(t('messages.systemError'));
          }
          setDone(false);
          setIsDoing(false);
          setTimeEnd(false);
          setIsSubmit(false);
        }
      },
      onError: (error) => {
        setDone(false);
        setIsDoing(false);
        setTimeEnd(false);
        setIsSubmit(false);
        showAlert(t('messages.systemError'));
      },
    },
  );

  const getDataTesting = (item) => {
    switch (item.type) {
      case TYPE_QUESTION_TEST.GUIDE:
        if (item.answer_text !== null) return item;
        break;
      case TYPE_QUESTION_TEST.CHECKBOX:
        if (item.list_answer_id !== null) return item;
        break;
      case TYPE_QUESTION_TEST.RADIO:
        if (item.answer_id !== null) return item;
        break;
      default:
        return null;
    }
    return null;
  };

  const submitFormTesting = () => {
    const formData = ref.current?.formData?.filter((item) => getDataTesting(item));
    saveTakeTest(formData);
    setIsSubmit(true);
  };

  useEffect(() => {
    // const formData = ref.current?.formData?.filter((item) => item.answer_id !== null && item.answer_text !== null);
    const formData = ref.current?.formData?.filter((item) => getDataTesting(item));
    if (timeEnd && formData.length > 0 && !isSubmit) {
      saveTakeTest(formData);
      setIsSubmit(true);
    }
  }, [timeEnd, isSubmit, saveTakeTest]);

  const handleSubmitTest = () => {
    const isDoing = ref.current?.formData?.find(
      (item) => item.answer_id === null || item.answer_text === null || item.list_answer_id === null,
    );
    if (!isDoing) {
      setDone(true);
    } else {
      setIsDoing(true);
    }
  };

  const getMessagePopup = (message) => {
    return (
      <div>
        {message.split('\n').map((str, index) => (
          <p key={index}>{str}</p>
        ))}
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="h-100vh d-flex align-items-center justify-content-center">
          <Loading />
        </div>
      ) : (
        <div className="info-testing">
          <Header
            t={t}
            data={data}
            mapPosition={mapPosition}
            mapSkill={mapSkill}
            mapLevel={mapLevel}
            handleSubmitTest={handleSubmitTest}
            setTimeEnd={setTimeEnd}
          />
          <Grid container className="container">
            <ListPreviewQuestion
              t={t}
              listQuestionPreview={dataQuestion?.map((item) => ({
                ...item,
                img_bonus: item.img_bonus,
                list_answer: item.list_answer,
              }))}
              ref={ref}
              keyLocal={`datatestingtest_${id}`}
              pageEdit={false}
              viewEdit={false}
            />
          </Grid>

          <Popup
            active={isDoing}
            handleCancel={() => setIsDoing(false)}
            className="popup-testing-start popup-testing-start-isDoing"
            footer={
              <>
                <button
                  className="btn-transparent color-yellow fs-16 btn-done-testing mr-0 btn-testing-cancel"
                  onClick={() => {
                    setIsDoing(false);
                  }}
                >
                  {t('messages.cancel')}
                </button>

                <button
                  className="btn-transparent color-yellow fs-16 btn-done-testing"
                  onClick={() => {
                    // setIsDoing(false);
                    submitFormTesting();
                  }}
                  disabled={isSubmit}
                >
                  {t('common.ok')}
                  <i className={classNames(`fa fa-spinner fa-pulse fa-3x fa-fw ${isSubmit ? '' : 'hidden'}`)} />
                </button>
              </>
            }
            messages={getMessagePopup(t('managerTest.submitFormTesting'))}
          />
          <Popup
            active={done}
            handleCancel={() => setDone(false)}
            className="popup-testing-start"
            footer={
              <>
                <button
                  className="btn-transparent color-yellow fs-16 btn-done-testing mr-0 btn-testing-cancel"
                  onClick={() => {
                    setDone(false);
                  }}
                >
                  {t('messages.cancel')}
                </button>

                <button
                  className="btn-transparent color-yellow fs-16 btn-done-testing"
                  onClick={() => {
                    // setDone(false);
                    submitFormTesting();
                  }}
                  disabled={isSubmit}
                >
                  {t('common.ok')}
                  <i className={classNames(`fa fa-spinner fa-pulse fa-3x fa-fw ${isSubmit ? '' : 'hidden'}`)} />
                </button>
              </>
            }
            messages={getMessagePopup(t('managerTest.submitFormTested'))}
          />
          <Popup
            active={timeEnd}
            className="popup-testing-start"
            footer={
              <>
                <button
                  className="btn-transparent color-yellow fs-16 btn-done-testing"
                  onClick={() => {
                    history.push(`/user/testing/${id}/start/notify`);
                  }}
                >
                  {t('common.ok')}
                </button>
              </>
            }
            messages={
              <div>
                <p>{t('managerTest.homeworkTimeIsOver')}</p>
                <p>{t('managerTest.sendPostTest')}</p>
              </div>
            }
          />
        </div>
      )}
    </>
  );
};

export default StartTesting;
