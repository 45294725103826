import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import moment from 'moment';

import styles from './styles.module.scss';
import { chunk, compact } from 'helpers';
import * as Constants from 'constants/InterviewScheduleManagement';
import { getInterviewList, getLocations } from 'api/interviewScheduleManagement';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import InterviewDetailModal from './InterviewDetailModal';
import { useListPosition, useListLevel, useListSkill } from 'hook/useMasterData';
import { useInforAuth } from 'hook/useAuth';
import { getValidHttpsUrl, handleOnError } from 'helpers/index';
import AlertAfterSubmit from 'components/AlertAfterSubmit';

const CHANGE_MONTH = {
  NEXT: 1,
  PREVIOUS: -1,
};

export const InterviewContext = createContext();

const InterviewSchedule = () => {
  const { t } = useTranslation();
  const { interviewDate, interviewId } = useParams();
  const [currentDate, setCurrentDate] = useState(moment().startOf('month'));
  const [dates, setDates] = useState([]);
  const [selectedDateKey, setSelectedDateKey] = useState();
  const [selectedDetail, setSelectedDetail] = useState();
  const [isOpenPopupNotFound, setOpenPopupNotFound] = useState(false);

  const selectedDate = useMemo(() => {
    return dates.find((date) => date.key === selectedDateKey);
  }, [dates, selectedDateKey]);

  const DAY_LIST = [
    { key: 1, label: t('common.monday') },
    { key: 2, label: t('common.tuesday') },
    { key: 3, label: t('common.wednesday') },
    { key: 4, label: t('common.thursday') },
    { key: 5, label: t('common.friday') },
    { key: 6, label: t('common.saturday') },
    { key: 0, label: t('common.sunday') },
  ];

  const { data: interviewList, refetch: refetchInterviewList } = useQuery(
    [Constants.GET_INTERVIEW_LIST, { currentDate }],
    async () => {
      const { startDate, endDate } = getDateRange(currentDate);
      const res = await getInterviewList({
        from_date: moment(interviewDate, 'YYYY-MM-DD').isBefore(moment(startDate))
          ? interviewDate
          : startDate.format('YYYY-MM-DD'),
        to_date: moment(interviewDate, 'YYYY-MM-DD').isAfter(moment(endDate))
          ? interviewDate
          : endDate.format('YYYY-MM-DD'),
      });
      return res.data?.data;
    },
  );
  const { data: locations } = useQuery(Constants.GET_LOCATIONS, async () => {
    const res = await getLocations();
    return res.data?.data;
  });
  const { data: positions } = useListPosition();
  const { data: levels } = useListLevel();
  const { data: skills } = useListSkill();
  const { data: inforAuth } = useInforAuth();

  const getDateRange = (currentDate) => {
    const startOfMonth = moment(currentDate).startOf('month');
    const endOfMonth = moment(currentDate).endOf('month');
    // get Monday in the first week
    let startDate = moment(startOfMonth).day(1);
    if (startDate.isAfter(startOfMonth)) {
      startDate = moment(startOfMonth).day(1 - 7);
    }
    // get Sunday in the last week
    let endDate = moment(endOfMonth).day(0);
    if (endDate.isBefore(endOfMonth)) {
      endDate = moment(endOfMonth).day(0 + 7);
    }
    return { startDate, endDate };
  };

  useEffect(() => {
    if (interviewDate && interviewId && interviewList) {
      const item = interviewList?.list_data?.[interviewDate]?.find((item) => item.id === +interviewId);
      if (!item) {
        setOpenPopupNotFound(true);
        return;
      }
      const data = {
        id: item.id,
        apply_id: item.apply_id,
        startTime: formatTime(item.start_time),
        endTime: formatTime(item.end_time),
        candidateName: item.apply_info.candidate.full_name,
        candidateId: item.apply_info.candidate.id,
        positionName: getPositionNameById(item.apply_info.position_id),
        levelName: getLevelNameById(item.apply_info.level_id),
        skillName: getSkillNameById(item.apply_info.skill_id),
        locationName: getLocationNameById(item.room.location_id),
        locationId: item.room.location_id,
        roomName: item.room.name,
        roomId: item.room.id,
        interviewers: item.interviewer_ids
          ? item.interviewer_ids.split(',').map((id) => {
              return interviewList?.list_users[id];
            })
          : [],
        link: getValidHttpsUrl(item.link),
        testStatus: item.apply_info.post_test_status,
        date: moment(item.date, 'YYYY-MM-DD'),
        type: item.type,
        note: item.note,
        title: item.title,
        ms_event_id: item.ms_event_id,
        creatorId: item.user_id,
        hrChargeId: item.apply_info.user_assigned_id,
        statusInterview: item.status,
      };
      setSelectedDetail(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewDate, interviewId, interviewList]);

  useEffect(() => {
    const dateObjectsTemp = [];
    const { startDate, endDate } = getDateRange(currentDate);
    for (let date = moment(startDate); date.isSameOrBefore(endDate); date.add(1, 'day')) {
      const dateTemp = moment(date);
      const items = interviewList?.list_data[dateTemp.format('YYYY-MM-DD')];
      dateObjectsTemp.push({
        date: dateTemp,
        key: dateTemp.format('DD/M'),
        label: dateTemp.format('DD/M'),
        items: items || [],
        isOutOfMonth: dateTemp.month() !== currentDate.month(),
      });
    }
    setDates(dateObjectsTemp);
  }, [currentDate, interviewList]);

  const formatSelectedDate = (date) => {
    const day = moment(date).day();
    const dayMeta = DAY_LIST.find((e) => e.key === day);
    return moment(date).format(`[${dayMeta.label}], DD/MM/YYYY`);
  };

  const formatTime = (time, inputFormat = 'HH:mm:ss', outputFormat = 'H:mm') => {
    if (!time) return;
    return moment(time, inputFormat).format(outputFormat);
  };

  const getPositionNameById = (id) => {
    if (!id) return '';
    return positions?.find((e) => e.id === id)?.name || '';
  };
  const getLevelNameById = (id) => {
    if (!id) return '';
    return levels?.find((e) => e.id === id)?.name || '';
  };
  const getSkillNameById = (id) => {
    if (!id) return '';
    return skills?.find((e) => e.id === id)?.name || '';
  };
  const getLocationNameById = (id) => {
    if (!id) return '';
    return locations?.find((e) => e.id === id)?.name || '';
  };

  const handleSelectDate = (date) => {
    setSelectedDateKey(date.key);
  };

  const handleChangeCurrentDate = (num) => {
    setCurrentDate(moment(currentDate).add(num, 'month'));
  };

  const renderScheduleTable = () => {
    const chunkedDates = chunk(dates, 7);

    return (
      <div>
        <div className={styles.header}>
          <div className={styles.header__row}>
            {DAY_LIST.map((day) => (
              <div key={day.key} className={styles.cell}>
                <span>{day.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.body}>
          {chunkedDates.map((week, i) => (
            <div className={styles.body__row} key={i}>
              {week.map((date) => (
                <div
                  key={date.key}
                  className={classNames([
                    styles.cell,
                    { [styles.outOfMonth]: date.isOutOfMonth },
                    { [styles.isSelected]: date.key === selectedDateKey },
                    { [styles.today]: moment().isSame(date.date, 'date') },
                  ])}
                  onClick={() => handleSelectDate(date)}
                >
                  <span className={styles.cell__label}>{date.label}</span>
                  {date.items?.length > 0 && (
                    <>
                      <div className={styles.cell__items}>
                        <ul>
                          {date.items.map((item, idx) => (
                            <li key={idx}>
                              {`${formatTime(item.start_time)} ${getPositionNameById(item.apply_info.position_id)}`}
                            </li>
                          ))}
                        </ul>
                      </div>
                      {date.items.length > 3 && (
                        <div className={styles.cell__hasMore}>
                          <div>
                            <span>.....</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderInterviewItem = (item) => {
    const data = {
      id: item.id,
      apply_id: item.apply_id,
      startTime: formatTime(item.start_time),
      endTime: formatTime(item.end_time),
      candidateName: item.apply_info.candidate.full_name,
      candidateId: item.apply_info.candidate.id,
      positionName: getPositionNameById(item.apply_info.position_id),
      levelName: getLevelNameById(item.apply_info.level_id),
      skillName: getSkillNameById(item.apply_info.skill_id),
      locationName: getLocationNameById(item.room.location_id),
      locationId: item.room.location_id,
      roomName: item.room.name,
      roomId: item.room.id,
      interviewers: item.interviewer_ids
        ? item.interviewer_ids.split(',').map((id) => {
            return interviewList?.list_users[id];
          })
        : [],
      link: getValidHttpsUrl(item.link),
      testStatus: item.apply_info.post_test_status,
      date: moment(item.date, 'YYYY-MM-DD'),
      type: item.type,
      note: item.note,
      title: item.title,
      ms_event_id: item.ms_event_id,
      hrChargeId: item.apply_info.user_assigned_id,
      statusInterview: item.status,
      creatorId: item.user_id,
    };

    return (
      <div className={styles.list__item} onClick={() => setSelectedDetail(data)}>
        <div className={styles.time}>{compact([data.startTime, data.endTime]).join(' - ')}</div>
        <div className={styles.candidate}>{data.candidateName}</div>
        <div className={styles.position}>
          {compact([data.positionName, data.levelName, data.skillName]).join(' - ')}
        </div>
        <div className={styles.room}>
          <i className="fas fa-map-marker-alt" /> {data.roomName}
        </div>
        <div className={styles.interviewers}>
          {data.interviewers.map(
            (e) =>
              e && (
                <div className={styles.interviewer} key={e.user_id} title={e.name}>
                  <img src={e.avatar || defaultAvatar} alt={e.name} onError={handleOnError} />
                </div>
              ),
          )}
        </div>
        <div className={styles.link}>
          {data.link ? (
            <a href={getValidHttpsUrl(data.link)} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
              {t('interviewSchedule.interviewLinkTitle')}
            </a>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  return (
    <InterviewContext.Provider value={{ locations, positions, skills, levels, inforAuth }}>
      <>
        <AlertAfterSubmit
          isShowAlert={isOpenPopupNotFound}
          toggle={() => {
            window.history.replaceState(null, '', '/admin/interview-schedule');
            setOpenPopupNotFound(false);
          }}
        >
          <p>{t('interviewSchedule.scheduleNotFound')}</p>
        </AlertAfterSubmit>
        <div className={styles.container}>
          <div className={styles.schedule}>
            <div className={styles.schedule__navigator}>
              <button className={styles.previous} onClick={() => handleChangeCurrentDate(CHANGE_MONTH.PREVIOUS)}>
                <i className="fas fa-angle-left" />
              </button>
              <div className={styles.currentDate}>{currentDate.format(`[${t('common.month')}] M / YYYY`)}</div>
              <button className={styles.next} onClick={() => handleChangeCurrentDate(CHANGE_MONTH.NEXT)}>
                <i className="fas fa-angle-right" />
              </button>
            </div>
            <div className={styles.schedule__calendar}>{renderScheduleTable()}</div>
          </div>
          <div className={styles.interviewList}>
            {selectedDate && (
              <>
                <div className={styles.selectedDate}>
                  <span>{formatSelectedDate(selectedDate.date)}</span>
                </div>
                <div className={styles.list}>
                  {selectedDate.items.map((item) => (
                    <React.Fragment key={item.id}>{renderInterviewItem(item)}</React.Fragment>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>

        <InterviewDetailModal
          selectedDetail={selectedDetail}
          setSelectedDetail={setSelectedDetail}
          refetchData={refetchInterviewList}
        />
      </>
    </InterviewContext.Provider>
  );
};

export default InterviewSchedule;
