import React, { useMemo, useState, useContext, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid } from '@mui/material';
import classNames from 'classnames';

import './styles.scss';
import styles from './styles.module.scss';

import AverageRating from '../RattingApply';
import ModalToDivision from './ModalToDivision';
import ModalEvaluateAllotmentDivision from './ModalEvaluateAllotmentDivision';
import ModalBookInterview from './ModalBookInterview';

import { apiCandidateChangeStatusApply } from 'api/managerCandidate';

import AlertAfterSubmit from 'components/AlertAfterSubmit';
import ModalRefuse from 'components/ModalRefuse';
import Loading from 'components/Loading';
import ButtonComponent from 'components/Buttons';
import PopupAreYouSure from 'components/PopupAreYouSure';

import StatusQuestion from './Components/StatusQuestion';
import StatusTest from './Components/StatusTest';
import StatusHistoryInterView from './Components/StatusHistoryInterView';
import StatusInterView from './Components/StatusInterView';
import DesiredCandidate from './Components/DesiredCandidate';
import BaseInformation from './Components/BaseInformation';
import InfoCandidate from './InfoCandidate/InfoCandidate';

import { useGetListLeaderBookInterview } from 'hook/useManagerCandidate';
import { useGetAverageAssesCandidate } from 'hook/useDetailApplyLeader';
import { useGetApplyCandidate } from 'hook/useCandidateApply';
import { useGetListTest } from 'hook/useDetailTest';
import { useGetHistoryInterView } from 'hook/useHistoryInterView';
import { useListLevel, useListPosition, useListSkill } from 'hook/useMasterData';
import { useGetListDivision } from 'hook/useDivision';
import { useGetQuestionsInterview } from 'hook/useManagerQuestionInterview';

import { getName, getNameStatus } from 'helpers/index';
import { getSkillName, getPositionName, getLevelName } from 'helpers/masterData';

import { LOADING_SM } from 'constants/index';
import {
  LIST_STATUS,
  LIST_STATUS_TEST,
  STATUS_CANDIDATE,
  TYPE_CANDIDATE_HR,
  USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL,
} from 'constants/ManagerCandidate';
import * as CANDIDATE from 'constants/ManagerCandidate';
import EditLayout from 'components/PermissionPage/EditLayout';
import { CANDIDATE_PERMISSION } from 'constants/permission';
import { ThemContext } from 'layouts/Admin';
import ModalHrEvaluate from './ModalHrEvaluate';
import CandidateEvaluation from './Components/CandidateEvaluation';

function LayoutHr({ children, tab }) {
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const { applyId } = useParams();
  const { dataInfoUser } = useContext(ThemContext);

  const { data: uDataPosition } = useListPosition();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataLevel } = useListLevel();
  const { data: uListDivision } = useGetListDivision();

  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalCancelOffer, setShowModalCancelOffer] = useState(false);
  const [showModalHrEvaluate, setShowModalHrEvaluate] = useState(false);
  const [showModalCancelInterView, setShowModalCancelInterView] = useState(false);
  const [showModalBookInterview, setShowModalBookInterview] = useState(false);
  const [showModalReasonForRefusal, setShowModalReasonForRefusal] = useState(false);
  const [showModalAllocationToDivision, setShowModalAllocationToDivision] = useState(false);

  const [showModalCandidateCancelOnboard, setShowModalCandidateCancelOnboard] = useState(false);
  const [confirmCandidateOnboard, setConfirmCandidateOnboard] = useState(false);

  const [isCancelSuccess, setIsCancelSuccess] = useState();

  const { data: uApplyCandidate, isLoading: isLoadingApplyCandidate } = useGetApplyCandidate(applyId, {
    type: TYPE_CANDIDATE_HR,
  });

  const { data: listLeaderAutoFill } = useGetListLeaderBookInterview({
    apply_id: uApplyCandidate.apply_id,
    job_id: uApplyCandidate.job_id,
  });

  const { data: uAverageAssess } = useGetAverageAssesCandidate(applyId);

  const { data: dataListTest } = useGetListTest({ applyId: applyId });
  const { data: uHistoryInterview } = useGetHistoryInterView({ apply_id: applyId, type: TYPE_CANDIDATE_HR });
  const { data: dataQuestionsResponse, isLoading: isLoadingQuestionInterview } = useGetQuestionsInterview(applyId);

  useEffect(() => {
    if (uApplyCandidate?.candidate && applyId) {
      const pathArr = window.location.pathname.split('/');
      const tailURL = pathArr[pathArr.length - 1];
      window.history.replaceState(
        null,
        '',
        `/admin/candidate-detail/${uApplyCandidate?.candidate}/apply-detail/${applyId}/${!!+tailURL ? '' : tailURL}`,
      );
    }
  }, [uApplyCandidate, applyId]);

  const getContentHistory = () => {
    if (uHistoryInterview?.length !== undefined) return uHistoryInterview.length + ' ' + t('managerCandidate.mark');
    return 0 + ' ' + t('managerCandidate.mark');
  };

  const getBaseInfo = (data) => {
    if (!data) return;
    return {
      apply_id: data.id,
      fullName: data?.full_name,
      positionName: data?.position_id ? getPositionName(data.position_id, uDataPosition) : t('common.notDataContract'),
      skillName: data?.skill_id ? getSkillName(data.skill_id, uDataSkill) : t('common.notDataContract'),
      levelName: data?.level_id ? getLevelName(data.level_id, uDataLevel) : t('common.notDataContract'),
    };
  };

  const { mutate: changeStatusCandidate, isLoading: isLoadingOfferSuccess } = useMutation(
    async (status) => {
      await apiCandidateChangeStatusApply({
        applyId: applyId,
        status: status,
        user_id: dataInfoUser.id,
      });
    },
    {
      onSuccess: async () => {
        QueryClient.refetchQueries(USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL);
        setConfirmCandidateOnboard(false);
      },
      onError: () => {},
    },
  );

  const ButtonCandidateResponse = () => {
    return (
      <Grid container className="button-accept-interview">
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button
              className="button-refuse-interview"
              onClick={() => {
                setShowModalCancel(true);
              }}
            >
              {t('managerCandidate.uvRejectPV')}
            </button>
          </EditLayout>
        </Grid>
        <Grid xs={0.6}></Grid>
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button className="button-book-interview" onClick={() => setShowModalBookInterview(true)}>
              {t('managerCandidate.scheduleAppointments')}
            </button>
          </EditLayout>
        </Grid>
      </Grid>
    );
  };

  const ButtonCandidateFeedback = () => {
    return (
      <Grid container className="button-accept-interview">
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button
              className="button-refuse-interview"
              onClick={() => {
                setShowModalCancelOffer(true);
              }}
            >
              {t('managerCandidate.candidateCancelOffer')}
            </button>
          </EditLayout>
        </Grid>
        <Grid xs={0.6}></Grid>
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <ButtonComponent
              addClass="button-book-interview accept-offer-btn"
              handleClick={() => {
                changeStatusCandidate(STATUS_CANDIDATE.WAIT_FOR_ALLOCATION);
              }}
              hasDisabled={isLoadingOfferSuccess}
              isLoading={isLoadingOfferSuccess}
              text={t('managerCandidate.candidateAcceptOffer')}
            />
          </EditLayout>
        </Grid>
      </Grid>
    );
  };

  const ButtonHrEvaluate = () => {
    return (
      <Grid container className="button-accept-interview">
        <Grid xs={12}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button
              className="button-hr-evaluate"
              onClick={() => {
                setShowModalHrEvaluate(true);
              }}
            >
              {t('managerCandidate.candidateAssetment')}
            </button>
          </EditLayout>
        </Grid>
      </Grid>
    );
  };

  const ButtonCancelInterView = () => {
    return (
      <Grid xs={12} className="button-accept-interview">
        <EditLayout permissionName={CANDIDATE_PERMISSION}>
          <button
            className="button-refuse-interview"
            onClick={() => {
              setShowModalCancelInterView(true);
            }}
          >
            {t('managerCandidate.cancelInterview')}
          </button>
        </EditLayout>
      </Grid>
    );
  };

  const ButtonAllocationToDivison = () => {
    return (
      <Grid className="button-accept-interview">
        <Grid xs={12}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button onClick={() => setShowModalAllocationToDivision(true)} className="button-book-interview">
              {t('modalOrder.allocateToDivision')}
            </button>
          </EditLayout>
        </Grid>
      </Grid>
    );
  };

  const ButtonToCandidateOnboard = () => {
    return (
      <Grid container className="button-accept-interview">
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button
              className="button-refuse-interview accept-onboard-btn"
              onClick={() => {
                setShowModalCandidateCancelOnboard(true);
              }}
            >
              {t('managerCandidate.candidateNotOnBoard')}
            </button>
          </EditLayout>
        </Grid>
        <Grid xs={0.6}></Grid>
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <ButtonComponent
              addClass="button-book-interview accept-onboard-btn"
              handleClick={() => {
                setConfirmCandidateOnboard(true);
              }}
              hasDisabled={isLoadingOfferSuccess}
              isLoading={isLoadingOfferSuccess}
              text={t('managerCandidate.candidateOnBoard')}
            />
          </EditLayout>
        </Grid>
      </Grid>
    );
  };

  const getTextStatusTest = (listQuestion, timeForTest, statusTest) => {
    if (!listQuestion) return t('managerCandidate.positionNotHaveTest');
    if (!timeForTest) return t('managerCandidate.positionNotHaveTest');
    return getName(LIST_STATUS_TEST, statusTest);
  };

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => {
    if (isLoadingOfferSuccess)
      return (
        <div className={styles.loadingIcon}>
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <ButtonComponent
          addClass={classNames(styles.popupBtn, styles.popupBtn__cancel)}
          text={t('common.cancel')}
          handleClick={onClickCancel}
        />
        <ButtonComponent
          addClass={classNames(styles.popupBtn, styles.popupBtn__ok)}
          text={t('common.ok')}
          handleClick={onClickOk}
        />
      </>
    );
  };

  return (
    <div>
      <PopupAreYouSure
        active={confirmCandidateOnboard}
        handleCancel={() => setConfirmCandidateOnboard(false)}
        messages={t('managerCandidate.confirmOnboardCandidate')}
        footer={
          <FooterPopupConfirm
            onClickCancel={() => setConfirmCandidateOnboard(false)}
            onClickOk={() => changeStatusCandidate(STATUS_CANDIDATE.ONBOARD)}
          />
        }
      />
      <Grid container className="detail-candidate">
        <Grid item className="header-back d-flex">
          {uApplyCandidate?.candidate && (
            <NavLink
              to={{
                pathname: `/admin/candidate-detail/${uApplyCandidate?.candidate}`,
              }}
              className={'link-back'}
            >
              <ArrowBackIosIcon />
            </NavLink>
          )}
          <div className="title-back">{t('managerCandidate.detail')}</div>
        </Grid>
        <Grid item container spacing={2} className="pt-0 ">
          <Grid item xs={9} className="main-content">
            <div className="parent d-flex">
              <StatusInterView
                dataCandidateApply={uApplyCandidate}
                txtContent={getNameStatus(
                  LIST_STATUS,
                  uApplyCandidate?.status,
                  uApplyCandidate?.division_id,
                  uListDivision,
                )}
                isActive={tab === CANDIDATE.HR_TAB_INFO}
                type="hr"
                isLoading={isLoadingApplyCandidate}
                candidateId={uApplyCandidate?.candidate}
                applyId={applyId}
                uListDivision={uListDivision}
              />
              <StatusTest
                dataCandidateApply={uApplyCandidate}
                txtStatus={getTextStatusTest(
                  dataListTest?.post_test?.list_questions,
                  dataListTest?.post_test?.time_for_test,
                  dataListTest?.post_test?.status,
                )}
                type="hr"
                isLoading={isLoadingApplyCandidate}
                candidateId={uApplyCandidate?.candidate}
                applyId={applyId}
                isActive={tab === CANDIDATE.HR_TAB_TEST}
              />
              <StatusQuestion
                dataCandidateApply={uApplyCandidate}
                answer={dataQuestionsResponse?.total_question_has_ask}
                totalQuestion={dataQuestionsResponse?.total_question}
                type="hr"
                isLoading={isLoadingQuestionInterview}
                candidateId={uApplyCandidate?.candidate}
                applyId={applyId}
                isActive={tab === CANDIDATE.HR_TAB_QUESTION}
              />
              <StatusHistoryInterView
                txtContent={getContentHistory()}
                dataCandidateApply={uApplyCandidate}
                type="hr"
                isLoading={isLoadingApplyCandidate}
                candidateId={uApplyCandidate?.candidate}
                applyId={applyId}
                isActive={tab === CANDIDATE.HR_TAB_HISTORY_INTERVIEW}
              />
            </div>
            <div>{children}</div>
          </Grid>
          <Grid item xs={3}>
            {useMemo(
              () => isLoadingApplyCandidate && <Loading addClass="mt-0 mb-1" type={LOADING_SM} />,
              [isLoadingApplyCandidate],
            )}
            {uApplyCandidate && (
              <>
                {uApplyCandidate?.status === STATUS_CANDIDATE.WAIT_CANDIDATE_RESPONSE && <ButtonCandidateResponse />}
                {uApplyCandidate?.status === STATUS_CANDIDATE.WAITING_INTERVIEW && <ButtonCancelInterView />}
                {uApplyCandidate?.status >= STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE && !uApplyCandidate?.evaluate_content && (
                  <ButtonHrEvaluate />
                )}
                {uApplyCandidate?.status === STATUS_CANDIDATE.WAITING_CANDIDATE_RESPONSE_OFFER && (
                  <ButtonCandidateFeedback />
                )}

                {uApplyCandidate?.status === STATUS_CANDIDATE.WAIT_FOR_ALLOCATION && <ButtonAllocationToDivison />}
                {uApplyCandidate?.status === STATUS_CANDIDATE.CANDIDATE_AGREE_OFFER && <ButtonToCandidateOnboard />}
                <Grid container spacing={1} className="button-accept-interview"></Grid>
                <Grid className="profileBoxInfo">
                  <InfoCandidate
                    uApplyCandidate={uApplyCandidate}
                    uDataLevel={uDataLevel}
                    isShowModal={showModalAllocationToDivision}
                    setIsShowModal={setShowModalAllocationToDivision}
                    onSuccess={() => setIsCancelSuccess(true)}
                    isHr
                  />
                </Grid>

                {uApplyCandidate?.status >= STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE && (
                  <div>
                    <DesiredCandidate applyDetail={uApplyCandidate} />
                    <CandidateEvaluation applyDetail={uApplyCandidate} />
                    <AverageRating list_stats={uAverageAssess} />
                  </div>
                )}

                <BaseInformation isLoadingApplyCandidate={isLoadingApplyCandidate} uApplyCandidate={uApplyCandidate} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <ModalRefuse
        title={t('managerDecentralization.reasonReject')}
        label={t('managerCandidate.reasonForTheRefusal')}
        isShowModal={showModalCancel}
        setIsShowModal={setShowModalCancel}
        listApply={{ id: applyId }}
        status={STATUS_CANDIDATE.CANDIDATE_REFUSE_INTERVIEW}
        required={false}
        confirmMessage={t('managerCandidate.candidateRefuseInterviewSuccess')}
      />
      <ModalRefuse
        title={t('managerDecentralization.reasonReject')}
        label={t('managerCandidate.reasonForTheRefusal')}
        isShowModal={showModalCancelOffer}
        setIsShowModal={setShowModalCancelOffer}
        listApply={{ id: applyId }}
        status={STATUS_CANDIDATE.REFUSE_OFFER}
        confirmMessage={t('managerCandidate.candidateRefuseOfferSuccess')}
      />
      {showModalHrEvaluate && (
        <ModalHrEvaluate
          title={t('managerCandidate.hrCandidateAssetment')}
          label={t('managerCandidate.enterCandidateEvaluationContent')}
          isShowModal={showModalHrEvaluate}
          setIsShowModal={setShowModalHrEvaluate}
          listApply={{ id: applyId }}
          confirmMessage={t('managerCandidate.hrEvaluatesCandidatesSuccess')}
        />
      )}
      <ModalRefuse
        title={t('interviewSchedule.cancelModal.ReasonCancelingInterview')}
        label={t('interviewSchedule.cancelModal.reason.placeholder')}
        isShowModal={showModalCancelInterView}
        setIsShowModal={setShowModalCancelInterView}
        listApply={{ id: applyId }}
        status={STATUS_CANDIDATE.HR_CANCEL_INTERVIEW}
        required
        confirmMessage={t('managerCandidate.cancelInterviewSuccess')}
      />
      <ModalRefuse
        title={t('managerDecentralization.reasonRejectCancelOnboard')}
        label={t('managerCandidate.reasonForTheRefusalCancelOnboard')}
        isShowModal={showModalCandidateCancelOnboard}
        setIsShowModal={setShowModalCandidateCancelOnboard}
        listApply={{ id: applyId }}
        status={STATUS_CANDIDATE.CANDIDATE_CANCEL_ONBOARD}
        confirmMessage={t('managerCandidate.confirmCandidateNoOnboardSuccess')}
      />
      {showModalAllocationToDivision && uApplyCandidate && (
        <ModalToDivision
          title={t('modalOrder.allocateToDivision')}
          isShowModal={showModalAllocationToDivision}
          setIsShowModal={setShowModalAllocationToDivision}
          onSuccess={() => setIsCancelSuccess(true)}
          dataApplyCandidate={uApplyCandidate}
        />
      )}
      {showModalBookInterview && (
        <ModalBookInterview
          isShowModal={showModalBookInterview}
          setShowModal={setShowModalBookInterview}
          candidateData={getBaseInfo(uApplyCandidate)}
          listLeaderAutoFill={listLeaderAutoFill}
        />
      )}

      <ModalEvaluateAllotmentDivision
        isShowModal={showModalReasonForRefusal}
        setIsShowModal={setShowModalReasonForRefusal}
        title={t('managerDecentralization.reasonReject')}
        applyId={uApplyCandidate?.id}
        user_id={dataInfoUser?.id}
      />
      {/* <ModalSettingDivision
        isShowModal={showModalSettingDivision}
        setIsShowModal={setShowModalSettingDivision}
        title={t('modalOrder.allocateToDivision')}
        applyId={uApplyCandidate?.id}
        dataDivision={uListDivision}
        onSuccess={() => setIsCancelSuccess(true)}
      /> */}
      {/* <ModalCandidateAssessment
        isShowModal={showModalCandidateAssessment}
        setIsShowModal={setShowModalCandidateAssessment}
        title={t('managerDecentralization.reasonReject')}
        uGetListAssess={uGetListAssess}
      /> */}
      <AlertAfterSubmit
        toggle={() => setIsCancelSuccess(false)}
        isShowAlert={isCancelSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('managerCandidate.successfulDistribution')}</p>
      </AlertAfterSubmit>
    </div>
  );
}

export default LayoutHr;
