import React, { useState, useEffect } from 'react';
import { useIsFetching, useQueryClient } from 'react-query';

import './../styles.scss';

import ListQuestion from './ListQuestion';

import { useGetApplyCandidate } from 'hook/useCandidateApply';
import { useListLevel } from 'hook/useMasterData';

import { TYPE_CANDIDATE_HR } from 'constants/ManagerCandidate';
import { USE_INFO_USER } from 'constants/Auth';
import { TYPE_ONESIGNAL_QUESTION_INTERVIEW } from 'constants/index';
import { GET_QUESTION_INTERVIEW } from 'constants/ManagerQuestionInterview';

const HrQuestion = ({ applyId }) => {
  const queryClient = useQueryClient();
  const [profile, setProfile] = useState();
  const isFetching = useIsFetching({ queryKey: [USE_INFO_USER] });

  const { data: uDataLevel } = useListLevel({ key_word: '' });
  const { data: uApplyCandidate } = useGetApplyCandidate(applyId, {
    type: TYPE_CANDIDATE_HR,
  });

  useEffect(() => {
    if (isFetching) return;
    const profileResponse = queryClient.getQueryData([USE_INFO_USER]);
    if (profileResponse?.data?.data) {
      setProfile(profileResponse?.data?.data);
    }
  }, [isFetching, queryClient]);

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;
  React.useEffect(() => {
    OneSignal.push(function () {
      OneSignal.on('notificationDisplay', function (event) {
        console.log('vao cap 1', event);
        if (
          event.data.type === TYPE_ONESIGNAL_QUESTION_INTERVIEW ||
          event.data.type === Number.parseInt(TYPE_ONESIGNAL_QUESTION_INTERVIEW)
        ) {
          console.log('vao cap 2', event);
          // queryClient.refetchQueries(GET_QUESTION_INTERVIEW);
          queryClient.invalidateQueries([GET_QUESTION_INTERVIEW], { refetchActive: true });
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <div>
      <ListQuestion applyId={applyId} profile={profile} dataLevel={uDataLevel} status={uApplyCandidate?.status} />
    </div>
  );
};

export default HrQuestion;
