import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { checkInvalidDateByDiagonalLine } from 'helpers/formatDate';

export const YupValidateEditApply = (sourceCvText) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    experience: Yup.string().required(t('messages.experienceRequired')),
    source_cv: Yup.string().required(t('messages.sourceCVRequired')),
    apply_date: Yup.string()
      .required(t('messages.applyDateRequired'))
      .test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) => checkInvalidDateByDiagonalLine(value)),
    position_id: Yup.string().required(t('messages.positionRequired')),
    presenter_id: Yup.string()
      .nullable()
      .when('source_cv', {
        is: (sourceCv) => Number(sourceCv) === 1,
        then: Yup.string().required(t('messages.presenterRequired')).nullable(),
      }),
    skill_id: Yup.string()
      .nullable()
      .when(['position_id', 'is_skill'], {
        is: (idPosition, isSkill) => idPosition > 0 && isSkill,
        then: Yup.string().required(t('messages.skillRequired')).nullable(),
      }),
    source_cv_text: sourceCvText && Yup.string().required(t('common.pleaseEnter1To50Characters')).nullable(),
    link_cv_online: Yup.string()
      .when('is_file_upload', {
        is: (isFileUpload) => !isFileUpload,
        then: Yup.string().required(t('messages.linkCVRequired')),
      })
      .nullable(),
    level_id: Yup.string().required(t('messages.levelRequired')),
  });
  return validationSchema;
};

export default YupValidateEditApply;
