import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useQueryClient, useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import classNames from 'classnames';

import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import styles from './styles.module.scss';

import defaultAvatar from 'assets/img/icons/default-avatar.svg';
// import iconChecked from 'assets/img/icons/checked.png';

import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';
import DateMonthComponent from 'components/DateTime/DateMonth';
import CustomInputNumber from 'components/InputYupAndMui/CusromInputNumber';
import SearchComponent from 'components/Search';
import ButtonComponent from 'components/Buttons';
import AlertAfterSubmit from 'components/AlertAfterSubmit';

import { showAlert } from 'helpers/modal';

import { MAX_LENGTH_50 } from 'constants/index';
import { apiCreateKPI } from 'api/managerKPI';
import { USE_LIST_KPI, USE_LIST_HR_NO_KPI, MIN_KPI_FOR_HR, MAX_KPI_FOR_HR } from 'constants/kpi';
import { HTTP_OK } from 'constants/StatusCode';

const ModalCreateKPi = ({
  isModalCreateKPI,
  setModalCreateKPI,
  setSearchNameHr,
  listHrNoKPI,
  isLoadingHRNoKPI,
  deadline,
  setDateCreateKPI,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isCreateSuccess, setIsCreateSuccess] = useState(false);

  const [form, setForm] = useState({
    deadline: deadline,
    hr_id: [],
  });

  const [disableBtn, setDisableBtn] = useState(true);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        target: Yup.number()
          .typeError(t('managerKPI.pleaseEnterKPI'))
          .required(t('managerKPI.pleaseEnterKPI'))
          .min(MIN_KPI_FOR_HR, t('managerKPI.pleaseEnterKPI'))
          .max(MAX_KPI_FOR_HR, t('managerKPI.pleaseEnterKPILessOrEqual100'))
          .nullable(),
      }),
    ),
    mode: 'onChange',
  });

  useEffect(() => {
    if (form.hr_id.length && form.deadline) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [form]);

  const onChangeFromTime = (dateSearch) => {
    setForm({ ...form, deadline: dateSearch, hr_id: [] });
    setDateCreateKPI(dateSearch);
  };

  const handleSearchHR = (value) => {
    setSearchNameHr(value);
  };

  const handleClickHR = (id) => {
    if (form.hr_id.includes(id)) {
      setForm({ ...form, hr_id: form.hr_id.filter((hrId) => hrId !== id) });
    } else {
      setForm({ ...form, hr_id: [...form.hr_id, id] });
    }
  };

  const { mutate: createKpiHR, isLoading: isLoadingCreateKpi } = useMutation((params) => apiCreateKPI(params), {
    onSuccess: (response) => {
      if (response.status === HTTP_OK) {
        setIsCreateSuccess(true);
        setForm({ deadline: form.deadline, hr_id: [] });
        queryClient.invalidateQueries([USE_LIST_HR_NO_KPI], { refetchActive: true });
        queryClient.invalidateQueries([USE_LIST_KPI], { refetchActive: true });
      } else {
        if (response?.data?.message) {
          showAlert(response.data.message);
        } else {
          showAlert(t('messages.systemError'));
        }
      }
    },
    onError: (error) => {
      showAlert(t('messages.systemError'));
    },
  });

  const submitCreateKPI = () => {
    createKpiHR({ ...form, deadline: moment(form.deadline).format('MM/YYYY'), target: getValues('target') });
  };

  const handleCloseModalCreateKpi = () => {
    setModalCreateKPI(false);
    setSearchNameHr(null);
    setDateCreateKPI(deadline);
  };

  return (
    <div>
      <AlertAfterSubmit
        toggle={() => {
          setIsCreateSuccess(false);
          handleCloseModalCreateKpi();
        }}
        isShowAlert={isCreateSuccess}
        classNameAdd={styles.Alert}
      >
        <p>{t('managerKPI.createKPISuccess')}</p>
      </AlertAfterSubmit>
      <ModalComponent
        isShowModal={isModalCreateKPI}
        title={t('manageKPI.createKPI')}
        toggle={() => handleCloseModalCreateKpi()}
        classNameAdd=""
      >
        <div className={styles.modalCreateEditKPI}>
          <Grid container className="pt-2" spacing={3}>
            <Grid item md={12}>
              <DateMonthComponent
                addClass="input-search-box"
                valueDefault={form.deadline}
                onChangeHandle={onChangeFromTime}
                min={moment()}
              />
            </Grid>
            <Grid item md={12}>
              <CustomInputNumber
                name="target"
                label={t('manageKPI.KPI')}
                control={control}
                useWatch={useWatch}
                register={register}
                setValue={setValue}
                errors={errors}
                required
                isInteger
              />
            </Grid>
            <Grid item md={12}>
              <SearchComponent
                txtPlaceholder={t('manageKPI.nameHR')}
                addClass={styles.searchHr}
                defaultValue={''}
                handleSearch={handleSearchHR}
                size={'medium'}
                maxLength={MAX_LENGTH_50}
              />
            </Grid>
            <Grid item md={12}>
              <p className={styles.titleListHR}>{t('manageKPI.listHR')}</p>
              {isLoadingHRNoKPI && <Loading />}

              {!isLoadingHRNoKPI && (
                <ul className={styles.listUser}>
                  {listHrNoKPI?.map((item) => (
                    <li
                      className={classNames(styles.boxItem, { [styles.isActive]: form.hr_id.includes(item.user_id) })}
                      key={item.user_id}
                      onClick={() => handleClickHR(item.user_id)}
                    >
                      <Avatar alt={item.avatar} src={item?.avatar ? item.avatar : defaultAvatar} />
                      <div className={styles.info}>
                        <p className={styles.name}>{item.user_name}</p>
                        <p className={styles.emailPosition}>
                          {item.email} - {item.position_name}{' '}
                        </p>
                      </div>
                      <div>{/* <img src={iconChecked} alt={iconChecked}/> */}</div>
                    </li>
                  ))}
                </ul>
              )}
              {!isLoadingHRNoKPI && !listHrNoKPI?.length && <div className="error-no-data"> {t('common.notData')}</div>}
            </Grid>
            <Grid item xs={12}>
              <ButtonComponent
                text={t('common.create')}
                addClass="btn-bg-yellow1 mr-3 w-100"
                handleClick={handleSubmit(submitCreateKPI)}
                hasDisabled={disableBtn || isLoadingCreateKpi}
                isLoading={isLoadingCreateKpi}
              />
            </Grid>
          </Grid>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ModalCreateKPi;
