import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const DoneTested = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('popstate', () => {
      history.go(1);
    });
  }, [history]);

  return (
    <div className="info-testing">
      <div className="done-tested d-flex align-items-center justify-content-center h-100vh flex-column">
        <span className="d-block fw-bold">{t('managerTest.thankYouForJoinTest')}</span>
        <span className="d-block mt-70">{t('managerTest.sendPostTest')}</span>
      </div>
    </div>
  );
};

export default DoneTested;
