import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';

import { COLOR_CHECKBOX_NOT_ACTIVE, COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';

const FilterOrderByDivision = ({ handleChange, listOrders, isLoading, value }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-3 division-filter-table">
      <FormControl>
        <FormLabel className="title-filter">{t('managerOrderHr.division')}</FormLabel>
        <RadioGroup value={value} name="radio-buttons-group" onChange={(event) => handleChange(event.target.value)}>
          <div className="row-division">
            <FormControlLabel
              value={0}
              control={
                <Radio
                  sx={{
                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                    '&.Mui-checked': {
                      color: COLOR_CHECKBOX_CUSTOM,
                    },
                  }}
                />
              }
              label={t('managerOrderHr.all')}
              className="radio"
            />
            <span className="total-order">{listOrders?.total_records}</span>
          </div>
          {!isLoading &&
            Array.isArray(listOrders?.list_total_by_division) &&
            listOrders.list_total_by_division.map((item, index) => {
              return (
                <div className="row-division" key={index}>
                  <FormControlLabel
                    value={item.id}
                    control={
                      <Radio
                        sx={{
                          color: COLOR_CHECKBOX_NOT_ACTIVE,
                          '&.Mui-checked': {
                            color: COLOR_CHECKBOX_CUSTOM,
                          },
                        }}
                      />
                    }
                    label={item.name}
                    className="radio"
                  />
                  <span className="total-order">{item.total_order}</span>
                </div>
              );
            })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default FilterOrderByDivision;
