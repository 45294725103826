import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';

import { Checkbox, Radio, TextareaAutosize } from '@mui/material';

import PreviewImage from 'components/PreviewImage';

import styles from './styles.module.scss';

const ListPreviewQuestion = ({
  listQuestionPreview,
  t,
  handleEditQuestion,
  handleDeleteQuestion,
  enableActionQuestion,
  setEditQuestion,
  viewEdit,
  pageEdit,
  filesUpload,
}) => {
  const [previewImage, setShowPreview] = useState('');

  const refListQuestiontion = useRef();
  const maxHeightListQuestion = useCallback(() => {
    const lis = refListQuestiontion.current.querySelectorAll('li[data-screen="1"]');
    let maxHeight = 0;
    const offsetTopQuestionItem = 30;
    for (const item of lis) {
      maxHeight += item.scrollHeight;
    }
    if (lis.length >= 3) {
      refListQuestiontion.current.style.maxHeight = `${maxHeight + offsetTopQuestionItem}px`;
    }
  }, [refListQuestiontion]);

  useEffect(() => {
    if (refListQuestiontion.current) {
      maxHeightListQuestion();
    }
  }, [maxHeightListQuestion, listQuestionPreview, refListQuestiontion]);

  const onHandleEditQuestion = (event, item, index) => {
    filesUpload.current = [];
    handleEditQuestion(event, item, index);
    setEditQuestion(true);
  };

  return (
    <div className="list-test" ref={refListQuestiontion}>
      <ul className="pl-0">
        {listQuestionPreview.map((item, index) => (
          <li className="d-flex align-items-start item-test" data-screen={index <= 1 ? 1 : 0} key={index}>
            <div className="content-l">
              <div className={styles.indexQuestion}>
                {t('managerTest.question')} {index + 1}: &nbsp;
              </div>
              <div className={styles.boxTitleQuestion}>
                <div className={styles.question}>{item.content}</div>
                {enableActionQuestion && (viewEdit || !pageEdit) && (
                  <div className="content-r d-flex align-items-center justify-content-center">
                    <div
                      className={`edit-delete-button color-button`}
                      onClick={onHandleEditQuestion.bind(this, item, index + 1)}
                    >
                      <HiPencil />
                    </div>
                    <div
                      className="edit-delete-button color-button m-0"
                      onClick={handleDeleteQuestion.bind(this, index)}
                    >
                      <MdDelete />
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex flex-wrap img-question">
                {(typeof item.img_bonus === 'string' ? JSON.parse(item.img_bonus) : item.img_bonus)?.map(
                  (_item, _index) => (
                    <img src={_item} alt={_item} key={_index} onClick={(event, url = _item) => setShowPreview(url)} />
                  ),
                )}
              </div>
              {item.type === 2 || item.type === 3 ? (
                <div className="list-answer">
                  {(typeof item?.list_answer === 'string' ? JSON.parse(item?.list_answer) : item?.list_answer)?.map(
                    (_item, _index) => (
                      <div className="d-flex align-items-start item-question" key={_index}>
                        <div className="checkbox mr-12">
                          {item.type === 2 ? <Checkbox disabled className="p-0" /> : <Radio disabled className="p-0" />}
                        </div>
                        <span className="question">{_item}</span>
                      </div>
                    ),
                  )}
                </div>
              ) : (
                <TextareaAutosize
                  minRows={5}
                  className="type-guide w-100 item-question pl-10 pt-10"
                  readOnly
                  placeholder={t('managerTest.answerOfYou')}
                />
              )}
            </div>
          </li>
        ))}
      </ul>

      <PreviewImage toggle={() => setShowPreview('')} isShowAlert={!!previewImage}>
        <img src={previewImage} alt={previewImage} />
      </PreviewImage>
    </div>
  );
};

export default ListPreviewQuestion;
