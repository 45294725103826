import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import iconInterView from 'assets/img/icons/candidate/interView.png';

import styles from './styles.module.scss';

import Loading from 'components/LoadingMemo';

import { LOADING_SM } from 'constants/index';

const StatusHistoryInterView = (props) => {
  const { t } = useTranslation();
  const { txtContent, isActive, isLoading, candidateId, applyId } = props;

  return (
    <Grid spacing={1} xs={3} className={styles.boxStatusHeader}>
      <div className={isActive ? styles.boxActive : ''}>
        <NavLink
          to={{
            pathname: `/admin/candidate-detail/${candidateId}/apply-detail/${applyId}/history`,
          }}
        >
          <Grid className="border-content-interview">
            <Grid container spacing={1} className="styleInfoMui">
              <img className="img-icon" src={iconInterView} alt={iconInterView} />
              <p className="p-content-interview">{t('managerCandidate.interviewHistory')}</p>
            </Grid>
            {<Loading type={LOADING_SM} addClass={styles.statusLoading} isLoading={isLoading} />}
            {!isLoading && <p className="p-content-status">{txtContent}</p>}
          </Grid>
        </NavLink>
      </div>
    </Grid>
  );
};

export default StatusHistoryInterView;
