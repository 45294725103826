/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, MenuItem } from '@mui/material';
import { useListLevel } from 'hook/useMasterData';
import SelectComponent from 'components/Select/Select.js';
import React, { useCallback } from 'react';
import { MdDelete } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import InputField from 'components/Input/InputField';

const AddMoreQuestion = ({ type, remove, list_questions, control, Controller, errors, append, mapLevel }) => {
  const { t } = useTranslation();
  const { data: uDataLevel } = useListLevel({ key_word: '' });
  const addMoreQuestion = () => {
    append({ type: type, content: '', level_id: undefined });
  };
  const handleDeleteQuestion = (index) => {
    if (list_questions.length > 1) remove(index);
  };
  
  const RenderListQuestion = useCallback(
    () => (
      <>
        {list_questions.map((item, index) => (
          <Grid container key={index} className="w-100" spacing={4}>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={`list_questions_${type}.${index}.content`}
                valueDefault={item.content}
                render={({ field }) => (
                  <InputField
                    field={field}
                    label={t('managerQuestionInterview.contentQuestion')}
                    variant="outlined"
                    onHandleInput={() => {}}
                    isRequired={true}
                    error={errors?.[`list_questions_${type}`]?.[index]?.['content']?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={10} className="relative z-5">
                  <Controller
                    control={control}
                    name={`list_questions_${type}.${index}.level_id`}
                    valueDefault={item.level_id}
                    render={({ field }) => (
                      <SelectComponent
                        field={field}
                        addClass="select flex-1"
                        label={t('managerQuestionInterview.levelQuestion')}
                        isRequired={true}
                        error={errors?.[`list_questions_${type}`]?.[index]?.['level_id']?.message}
                      >
                        {uDataLevel &&
                          uDataLevel?.map((item, index) => {
                            mapLevel.current[item.id] = item.name;
                            return (
                              <MenuItem key={index} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </SelectComponent>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  className="d-flex align-items-center justify-content-end btn-delete-question position-relative"
                >
                  <div
                    className="circle-delete-button color-button m-0"
                    onClick={handleDeleteQuestion.bind(this, index)}
                  >
                    <MdDelete />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </>
    ),
    [list_questions, uDataLevel, errors],
  );
  return (
    <Grid container className="box-more-quetion container-list-question w-100 p-20">
      <Grid item xs={12} className="header-question">
        {t(`managerQuestionInterview.${type === 1 ? 'softQuestion' : 'questionMajor'}`)}
      </Grid>
      <RenderListQuestion />
      {list_questions.length < 15 && (
        <Grid item className="mt-3">
          <div className="cursor p-3 text-content" onClick={addMoreQuestion}>
            <BsPlus /> {t('managerQuestionInterview.addContentQuestion')}
          </div>
        </Grid>
      )}
    </Grid>
  );
};
export default AddMoreQuestion;
