import { Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@mui/material';
import ModalComponent from 'components/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'components/Buttons';
import { sendDelete } from 'helpers/axios';
import { USE_LIST_QUESTIONINTERVIEW, QUESTION_INTERVIEW_ID } from 'constants/ManagerQuestionInterview';
import { INTERVIEW_QUESTIONS_PERMISSION } from 'constants/permission';

import { useHistory } from 'react-router-dom';
import EditLayout from 'components/PermissionPage/EditLayout';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';
const PreviewQuestionInterview = ({
  previewQuestion,
  setPreviewQuestion,
  mapPosition,
  mapLevel,
  mapSkill,
  questionId,
  formData,
  query,
  notAllowPermissionDelete,
  setDeleteQuestionSuccess,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const loading = useRef(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [newData, setNewData] = useState({ softQuestion: {}, majorQuestion: {} });
  useEffect(() => {
    const formatData = (data) => {
      const newData = {};
      for (const item of data) {
        if (!newData[mapLevel[item.level_id]]) newData[mapLevel[item.level_id]] = [];
        newData[mapLevel[item.level_id]].push(item.content);
      }
      return newData;
    };
    setNewData({
      softQuestion:
        formData.list_questions_1?.[0]?.content?.length > 0
          ? formatData(
              formData.list_questions_1.sort(function (a, b) {
                return a.level_id - b.level_id;
              }),
            )
          : {},
      majorQuestion:
        formData.list_questions_2?.[0]?.content?.length > 0
          ? formatData(
              formData.list_questions_2.sort(function (a, b) {
                return a.level_id - b.level_id;
              }),
            )
          : {},
    });
  }, [formData, mapLevel]);
  const handleDelete = async () => {
    if (loading.current) {
      loading.current = false;
      await sendDelete(`/api/question-interview/delete`, { id: questionId });
      query.refetchQueries(USE_LIST_QUESTIONINTERVIEW);
      setPreviewQuestion(false);
      setDeleteQuestionSuccess(true);
      loading.current = true;
    }
  };

  const handleEdit = () => {
    history.push(`/admin/manager-question-interview?redirect=create&id=${questionId}`);
    query.setQueryDefaults(QUESTION_INTERVIEW_ID, questionId);
  };

  return (
    <>
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogContent className="question-interview-popup-delete-text-confirm">
          <DialogContentText id="alert-dialog-description">
            <span>{t(`managerQuestionInterview.confirmDelete`)}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div class="w-100 d-flex align-items-center justify-content-end">
            <button
              className="btn-transparent question-interview-button-color-delete"
              onClick={() => setConfirmDelete(false)}
            >
              {t('managerQuestionInterview.cancelDeleteQuestion')}
            </button>
            <button className="btn-transparent color-yellow" onClick={handleDelete}>
              {t('managerQuestionInterview.ok')}
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <ModalComponent
        isShowModal={previewQuestion}
        title={t('managerQuestionInterview.detailQuestionInterview')}
        toggle={() => {
          setPreviewQuestion(!previewQuestion);
        }}
        classNameAdd="no-padding-modal-body preview-question-interview"
      >
        <Grid container className="header">
          <Grid item xs={12}>
            <h4 className="header-color">Câu hỏi {mapPosition[formData.position_id]}</h4>
          </Grid>
          <Grid container className="align-items-end mt--5px">
            <Grid item xs={8} className="d-flex align-items-center map-question">
              <Grid item xs={4}>
                <span>Vị trí: </span>
                <span className="fw-500">{mapPosition[formData.position_id]}</span>
              </Grid>
              <Grid item xs={4}>
                <span>Kỹ năng: </span>
                <span className="fw-500">{mapSkill[formData.skill_id]}</span>
              </Grid>
              <Grid item xs={4}>
                <span>Trạng thái: </span>
                <span className="fw-500">{+formData.status ? 'Active' : 'Inactive'}</span>
              </Grid>
            </Grid>
            <Grid item xs={4} className="d-flex align-items-center justify-content-end">
              <DeleteLayout permissionName={INTERVIEW_QUESTIONS_PERMISSION}>
                <ButtonComponent
                  text={t('managerQuestionInterview.delete')}
                  addClass="btn-bg-yellow2 mr-15"
                  handleClick={() => setConfirmDelete(true)}
                  hasDisabled={notAllowPermissionDelete}
                />
              </DeleteLayout>

              <EditLayout permissionName={INTERVIEW_QUESTIONS_PERMISSION}>
                <ButtonComponent
                  text={t('managerQuestionInterview.edit')}
                  addClass="btn-bg-yellow1"
                  handleClick={handleEdit}
                />
              </EditLayout>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className="content">
          {Object.keys(newData.softQuestion).length > 0 && (
            <Grid item xs={12}>
              <h4 className="title fw-500">{t('managerQuestionInterview.softQuestion')}</h4>
            </Grid>
          )}
          <Grid item xs={12}>
            {Object.keys(newData.softQuestion).map((key, index) => (
              <React.Fragment key={index}>
                <div className="level">
                  <span>Level: </span>
                  <span className="fw-500">{key}</span>
                </div>
                <ul className="p-0 list-question-by-level">
                  {newData.softQuestion[key].map((item, index) => (
                    <li className="d-block text-color" key={index}>
                      <span className="fw-500">Câu {index + 1}: </span>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </Grid>
          {Object.keys(newData.majorQuestion).length > 0 && (
            <Grid item xs={12}>
              <h4 className="title fw-500">{t('managerQuestionInterview.majorQuestion')}</h4>
            </Grid>
          )}
          <Grid item xs={12}>
            {Object.keys(newData.majorQuestion).map((key, index) => (
              <React.Fragment key={index}>
                <div className="level">
                  <span>Level: </span>
                  <span className="fw-500">{key}</span>
                </div>
                <ul className="p-0 list-question-by-level">
                  {newData.majorQuestion[key].map((item, index) => (
                    <li className="d-block text-color" key={index}>
                      <span className="fw-500">Câu {index + 1}: </span>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </ModalComponent>
    </>
  );
};

export default PreviewQuestionInterview;
