import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import classNames from 'classnames';

import { ModalFooter } from 'reactstrap';

import styles from './styles.module.scss';

import { cancelInterview } from 'api/interviewScheduleManagement';
import { HTTP_OK } from 'constants/StatusCode';
import { MAX_LENGTH_100, MAX_LENGTH_10 } from 'constants/index';
import ModalComponent from 'components/Modal';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';

const FORM_FIELDS = {
  reason: 'canceled_reason',
};

const CancelModal = (props) => {
  const { t } = useTranslation();
  const { isShowModal, setIsShowModal, selectedDetail, onSuccess } = props;

  const DEFAULT_VALUES = {
    [FORM_FIELDS.reason]: '',
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        [FORM_FIELDS.reason]: Yup.string().trim()
          .required(t('messages.pleaseEnterTenToOneHundredCharacters'))
          .min(MAX_LENGTH_10, t('messages.pleaseEnterTenToOneHundredCharacters')),
      }),
    ),
    mode: 'onChange',
  });

  const handleCloseModal = () => {
    setIsShowModal(false);
    reset();
  };

  const submit = async (values) => {
    const payload = {
      id: selectedDetail.id,
      canceled_reason: values[FORM_FIELDS.reason],
      ms_event_id: selectedDetail.ms_event_id,
      apply_id: selectedDetail.apply_id,
      type: selectedDetail.type,
    };
    try {
      const res = await cancelInterview(payload);
      if (res?.status === HTTP_OK) {
        setIsShowModal(false);
        reset();
        onSuccess();
      } else {
        if (res?.data?.messages) {
          Object.entries(res.data.messages).entries(([key, message]) => {
            setError(key, { type: 'custom', message });
          });
        } else {
          setError(FORM_FIELDS.reason, { type: 'custom', message: t('common.anUnknownError') });
        }
      }
    } catch (error) {}
  };

  return (
    <ModalComponent
      title={t('interviewSchedule.cancelModal.title')}
      isShowModal={isShowModal}
      toggle={handleCloseModal}
      renderModalFooter={() => (
        <ModalFooter className={styles.modalFooter}>
          <button type="button" className={classNames(styles.btn, styles.btn__cancel)} onClick={handleCloseModal}>
            {t('interviewSchedule.cancelModal.cancel')}
          </button>
          <button type="button" className={classNames(styles.btn, styles.btn__action)} onClick={handleSubmit(submit)}>
            {t('interviewSchedule.cancelModal.send')}
          </button>
        </ModalFooter>
      )}
      classNameAdd={styles.cancelModal}
    >
      <form>
        <CustomInputYup
          maxLength={MAX_LENGTH_100}
          name={FORM_FIELDS.reason}
          label={t('interviewSchedule.cancelModal.reason.placeholder')}
          placeholder={t('interviewSchedule.cancelModal.reason.placeholder')}
          control={control}
          useWatch={useWatch}
          register={register}
          errors={errors}
          defaultValue={DEFAULT_VALUES[FORM_FIELDS.note]}
          required
          multiline
          rows={7}
        />
      </form>
    </ModalComponent>
  );
};

export default CancelModal;
