import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { useGetDetailCandidate, useGetListSchool, useGetAllDetailInterviewCandidate } from 'hook/useManagerCandidate';
import { getTextGender, getName } from 'helpers/index';
import { customDate } from 'helpers/formatDate';

import InfoInterview from './components/InfoInterview';
import AssessTable from './components/AssessTable';
import Loading from 'components/Loading';
import { CANDIDATE_RATING_WAIT_OFFER, CANDIDATE_NOT_RATED_YET } from 'constants/ManagerCandidate';

const ColumnInfoInterview = (props) => {
  const { candidateId, type } = props;
  const { t } = useTranslation();

  const { data: uListSchool } = useGetListSchool();
  const { data: dataCandidate, isLoading: isLoadingCandidate } = useGetDetailCandidate({
    id: candidateId,
    type: type,
    basic: true,
  });
  const { data: dataInterview, isLoading: isLoadingInterview } = useGetAllDetailInterviewCandidate({
    type: type,
    candidate_id: candidateId,
  });

  return (
    <Grid container className={styles.columnInterviewContainer}>
      {isLoadingCandidate && (
        <Grid item xs={12}>
          <Loading />
        </Grid>
      )}
      {!isLoadingCandidate && (
        <Grid container item xs={12} className={styles.infoCandidateContainer}>
          <Grid container item xs={5.8} className={styles.itemContainer}>
            <Grid item xs={4} className={styles.titleItem}>
              {t('common.fullName')}
            </Grid>
            <Grid item xs={8} className={styles.contentItem}>
              {dataCandidate?.full_name}
            </Grid>
          </Grid>
          <Grid item xs={0.2}></Grid>
          <Grid container item xs={6} className={styles.itemContainer}>
            <Grid item xs={5} className={styles.titleItem}>
              {t('common.sex')}
            </Grid>
            <Grid item xs={7} className={styles.contentItem}>
              {getTextGender(dataCandidate?.sex)}
            </Grid>
          </Grid>
          <Grid container item xs={5.8} className={styles.itemContainer}>
            <Grid item xs={4} className={styles.titleItem}>
              {t('common.birthday')}
            </Grid>
            <Grid item xs={8} className={styles.contentItem}>
              {dataCandidate?.birthday ? customDate(dataCandidate.birthday, 'DD/MM/YYYY') : t('common.notDataContract')}
            </Grid>
          </Grid>
          <Grid item xs={0.2}></Grid>
          <Grid container item xs={6} className={styles.itemContainer}>
            <Grid item xs={5} className={styles.titleItem}>
              {t('common.school')}
            </Grid>
            <Grid item xs={7} className={styles.contentItem}>
              {getName(uListSchool, dataCandidate?.school)}
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isLoadingInterview &&
        dataInterview?.map((apply) => (
          <InfoInterview data={apply} key={apply.apply_id}>
            {apply?.list_assess?.map((assess) => {
              if (
                (assess?.result === CANDIDATE_RATING_WAIT_OFFER || assess?.result === CANDIDATE_NOT_RATED_YET) &&
                !assess?.note &&
                !JSON.parse(assess?.list_stats)?.length
              )
                return <></>;
              return <AssessTable data={assess} key={assess.user_id} />;
            })}
          </InfoInterview>
        ))}
    </Grid>
  );
};

export default ColumnInfoInterview;
