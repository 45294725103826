import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPencil } from 'react-icons/hi';
import { NavLink, useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';

import { Grid, Tooltip } from '@mui/material';

import iconCvDefault from 'assets/img/icons/cv-default.svg';
import iconDownload from 'assets/img/icons/download.png';
import iconPreview from 'assets/img/icons/candidate/preview-icon.svg';

import styles from './styles.module.scss';
import { ThemContext } from 'layouts/Admin';
import { apiCandidateChangeStatusApply, apiDownloadCV } from 'api/managerCandidate';

import ButtonComponent from 'components/Buttons';

import storageFC from 'helpers/storage';
import { customDate } from 'helpers/formatDate';
import { getName, getNameFile, getValidHttpsUrl, getColorStatus } from 'helpers/index';
import { getPositionName, getSkillName, getDivisionName, getLevelName } from 'helpers/masterData';
import { formatSalary } from 'helpers/format';
import {
  INDEX_FIRST,
  LIST_STATUS,
  USE_GET_DETAIL_CANDIDATE,
  STATUS_CANDIDATE,
  LIST_YEAR_EXPERIENCE,
} from 'constants/ManagerCandidate';
import { LOADING_SM } from 'constants/index';
import { getNameSourceCV, getTitleSourceCVText, getNameSourceCVOther } from 'helpers/manageCandidate';
import DetailStatusCard from 'components/DetailStatusCard';
import { useListPersonnel, useListContractCategory } from 'hook/useMasterData';
import Popup from 'components/popup';
import { Button } from 'reactstrap';
import { HTTP_OK } from 'constants/StatusCode';
import Loading from 'components/Loading';
import EditLayout from 'components/PermissionPage/EditLayout';
import { CANDIDATE_PERMISSION } from 'constants/permission';
import ModalRefuse from 'components/ModalRefuse';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import PreviewCV from '../../components/PreviewCV';

const ListApplyJobOrder = ({
  listApplyOrder,
  candidateId,
  dataPosition,
  dataListSourceCV,
  dataSkill,
  dataLevel,
  dataDivision,
  notAllowPermissionUpdate,
  setShowModal,
  setApply,
  auth,
  type,
}) => {
  const { t } = useTranslation();
  const { dataInfoUser, isAssigner } = useContext(ThemContext);
  const history = useHistory();
  const QueryClient = useQueryClient();
  const [statusSubmit, setStatusSubmit] = useState(0);
  const [showPopupApprove, setShowPopupApprove] = useState(false);
  const [candidateConfirmed, setCandidateConfirmed] = useState({ id: null, applyId: null, jobId: null });
  const [showModalRefuseCv, setShowModalRefuseCv] = useState(false);
  const [refuseCvSuccess, setRefuseCvSuccess] = useState(false);
  const [urlFilePreview, setUrlFilePreview] = useState(null);
  const { data: uDataContractCategory } = useListContractCategory();
  const { data: uDataPersonnel } = useListPersonnel();

  const getExt = (str) => {
    if (str) {
      let arr = str?.split('.');
      return arr[arr?.length - 1];
    }
    return '';
  };

  const downloadCV = (linkCV, name) => {
    fetch(apiDownloadCV(linkCV), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${storageFC.getToken()}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const { mutate: submitApproveCV, isLoading } = useMutation(
    async (status) => {
      await apiCandidateChangeStatusApply({
        applyId: listApplyOrder?.at(0).id,
        status: status,
        user_id: auth.id,
      });
    },
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries([USE_GET_DETAIL_CANDIDATE], { refetchActive: true });
      },
      onError: () => {
        // setError(FORM_FIELDS.reason, { type: 'custom', message: t('common.anUnknownError') });
      },
    },
  );

  const alertConfirm = (status, txtStatus) => {
    confirmAlert({
      title: t('managerCandidate.confirmChangeStatus', { txtStatus }),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.yes'),
          className: 'btn-alert-ok',
          onClick: async () => {
            setStatusSubmit(status);
            if (status === STATUS_CANDIDATE.CANCEL_CV) setShowModalRefuseCv(true);
            else submitApproveCV(status);
          },
        },
      ],
    });
  };

  const formatFieldKeyList = (list) => {
    return list.map((item) => {
      const { user_info, status } = item;
      return {
        status_id: status,
        status: getName(LIST_STATUS, status),
        name: user_info.name,
        avatar: user_info.avatar,
      };
    });
  };

  const { mutate: changeStatusCandidate, isLoading: isLoadingChangeStatus } = useMutation(
    (status) =>
      apiCandidateChangeStatusApply({
        applyId: candidateConfirmed.applyId,
        status: status,
        user_id: dataInfoUser.user_id,
        is_leader_interrupt: true,
      }),
    {
      onSuccess: (response, variables) => {
        if (response.status === HTTP_OK) {
          QueryClient.invalidateQueries([USE_GET_DETAIL_CANDIDATE], { refetchActive: true });
          setShowPopupApprove(false);
          if (variables === STATUS_CANDIDATE.WAIT_CANDIDATE_RESPONSE)
            history.push({
              pathname: `/admin/candidate-detail/${candidateConfirmed.id}/apply-detail/${candidateConfirmed.applyId}`,
              state: {
                id: candidateConfirmed.applyId,
                apply_id: candidateConfirmed.applyId,
                job_id: candidateConfirmed.jobId,
                candidate_id: candidateConfirmed.id,
              },
            });
        }
      },
      onError: () => {},
    },
  );

  const FooterPopupApproveCandidate = () => {
    if (isLoadingChangeStatus)
      return (
        <div className={styles.loadingIcon}>
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <Button
          className={classNames(styles.popupBtn, styles.popupBtn__cancel)}
          onClick={() => setShowPopupApprove(false)}
        >
          {t('modalOrder.cancel')}
        </Button>
        <Button
          className={classNames(styles.popupBtn, styles.popupBtn__cancel)}
          onClick={() => changeStatusCandidate(STATUS_CANDIDATE.CANCEL_CV)}
        >
          {t('modalOrder.notApproveCv')}
        </Button>
        <Button
          className={classNames(styles.popupBtn, styles.popupBtn__ok)}
          onClick={() => changeStatusCandidate(STATUS_CANDIDATE.WAIT_CANDIDATE_RESPONSE)}
        >
          {t('modalOrder.approveCVAndViewAssess')}
        </Button>
      </>
    );
  };

  const checkAllowEditApply = (item, index) => {
    return (
      index === INDEX_FIRST &&
      type === 'hr' &&
      (item?.status === STATUS_CANDIDATE.PASS_PRE_QUALIFICATION ||
        item?.status === STATUS_CANDIDATE.FAIL_PRE_QUALIFICATION)
    );
  };

  return (
    <div className="list-apply">
      <Popup
        active={showPopupApprove}
        handleCancel={() => setShowPopupApprove(false)}
        messages={t('modalOrder.approveCVAndViewAssessCandidate')}
        footer={<FooterPopupApproveCandidate />}
        className={styles.popupApproveCandidate}
      />
      <AlertAfterSubmit isShowAlert={refuseCvSuccess} toggle={() => setRefuseCvSuccess(false)}>
        <p>{t('managerCandidate.refuseCvSuccess')}</p>
      </AlertAfterSubmit>
      {listApplyOrder.map((item, index) => {
        const isAllowEdit = checkAllowEditApply(item, index);
        return (
          <Grid container className="box-info-apply" key={index}>
            <Grid item container className="header-box-info-apply">
              <Grid item container xs={12} className="box-info-apply-wap">
                <Grid item container xs={3.6} rowSpacing={1}>
                  <Grid item xs={3.5} className="info-title">
                    {t('managerCandidate.jobName')}
                  </Grid>
                  <Grid item xs={8.5} className="text-info-custom job-name">
                    <Tooltip
                      title={
                        <div className={styles.tooltipJobName}>
                          {item?.job_name ? item.job_name : t('common.notDataContract')}
                        </div>
                      }
                      followCursor
                    >
                      <span className="cursor">{item?.job_name ? item.job_name : t('common.notDataContract')}</span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3.5} className="info-title">
                    {t('managerCandidate.HRCharge')}
                  </Grid>
                  <Grid item xs={8.5} className="text-info-custom">
                    {item?.user_info?.name}
                  </Grid>
                </Grid>
                <Grid item container xs={3.8} rowSpacing={1}>
                  <Grid item xs={4.5} className="info-title">
                    {t('managerCandidate.dateApply')}
                  </Grid>
                  <Grid item xs={7.5} className="text-info-custom">
                    {item.apply_date ? customDate(item.apply_date, 'DD/MM/YYYY') : t('common.notDataContract')}
                  </Grid>
                  <Grid item xs={4.5} className="info-title">
                    {t('managerCandidate.status')}
                  </Grid>
                  <Grid item xs={7.5} className={`${getColorStatus(LIST_STATUS, item?.status)} text-info-custom`}>
                    <Tooltip
                      title={
                        (isAssigner || type === 'hr') && (
                          <DetailStatusCard
                            list={formatFieldKeyList(item.list_action_leader)}
                            fieldKey={'status'}
                            fieldKeyClass={'status_id'}
                          />
                        )
                      }
                      arrow={isAssigner || type === 'hr'}
                    >
                      <span>{getName(LIST_STATUS, item?.status)}</span>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item container xs={3.8} rowSpacing={1}>
                  <Grid item xs={6} className="info-title">
                    {t('managerCandidate.dayInterview')}
                  </Grid>
                  <Grid item xs={6} className="text-info-custom">
                    {item.interview_date ? customDate(item.interview_date, 'DD/MM/YYYY') : t('common.notDataContract')}
                  </Grid>
                  <Grid item xs={6} className="info-title">
                    {t('managerCandidate.trainCommit')}
                  </Grid>
                  <Grid item xs={6} className="text-info-custom">
                    {item?.has_commit ? t('managerCandidate.hasCommit') : t('managerCandidate.notHasCommit')}
                  </Grid>
                </Grid>
              </Grid>
              <div className="button-detail-apply-info header-box-info-apply box-info-apply-wap">
                <NavLink
                  to={{
                    pathname: `/admin/candidate-detail/${candidateId}/apply-detail/${item.id}`,
                    state: {
                      id: item.apply_id,
                      apply_id: item.apply_id,
                      job_id: item.job_id,
                      candidate_id: item.candidate_id,
                    },
                  }}
                  className="a-custom"
                >
                  {t('managerCandidate.detailDown')}
                </NavLink>
              </div>
            </Grid>
            <Grid item container className="body-box-info-apply" pt={2}>
              <Grid item container xs={12}>
                <Grid item container xs={3.6} rowSpacing={2}>
                  <Grid item container xs={12}>
                    <Grid item xs={3.5} className="info-title">
                      {t('managerCandidate.position')}
                    </Grid>
                    <Grid item xs={8.5} className="text-info-custom">
                      {item?.position_id
                        ? getPositionName(item.position_id, dataPosition)
                        : t('common.notDataContract')}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={3.5} className="info-title">
                      {t('managerCandidate.skill')}
                    </Grid>
                    <Grid item xs={8.5} className="text-info-custom">
                      {item?.skill_id ? getSkillName(item.skill_id, dataSkill) : t('common.notDataContract')}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={3.5} className="info-title">
                      {t('managerCandidate.applyLevel')}
                    </Grid>
                    <Grid item xs={8.5} className="text-info-custom">
                      {item?.level_id ? getLevelName(item.level_id, dataLevel) : t('common.notDataContract')}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={3.5} className="info-title">
                      {t('managerCandidate.experience')}
                    </Grid>
                    <Grid item xs={8.5} className="text-info-custom">
                      {getName(LIST_YEAR_EXPERIENCE, item?.experience)}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} className="d-flex">
                    <Grid item xs={3.5} className="info-title">
                      {t('managerCandidate.linkCVOnl')}
                    </Grid>
                    <Grid item xs={8.5}>
                      <a
                        className="detail-candidate-text-link"
                        href={getValidHttpsUrl(item?.link_cv_online)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item?.link_cv_online ? item.link_cv_online : t('common.notDataContract')}
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={3.8} rowSpacing={2}>
                  <Grid item container xs={12}>
                    <Grid item xs={4.5} className="info-title">
                      {t('managerCandidate.sourceCv')}
                    </Grid>
                    <Grid item xs={7.5} className="text-info-custom">
                      {getNameSourceCV(dataListSourceCV, item?.source_cv)}
                    </Grid>
                  </Grid>
                  {getTitleSourceCVText(dataListSourceCV, item) && (
                    <Grid item container xs={12}>
                      <Grid item xs={4.5} className="info-title">
                        {getTitleSourceCVText(dataListSourceCV, item)}
                      </Grid>
                      <Grid item xs={7.5} className="text-info-custom">
                        {getNameSourceCVOther(item)}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item container xs={12}>
                    <Grid item xs={4.5} className="info-title">
                      {t('managerCandidate.Personnel')}
                    </Grid>
                    <Grid item xs={7.5} className="text-info-custom">
                      {getName(uDataPersonnel, item?.group_id)}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4.5} className="info-title">
                      {t('managerCandidate.division')}
                    </Grid>
                    <Grid item xs={7.5} className="text-info-custom">
                      {getDivisionName(item?.division_id, dataDivision)}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4.5} className="info-title">
                      {t('managerCandidate.officialLevel')}
                    </Grid>
                    <Grid item xs={7.5} className="text-info-custom">
                      {item?.level_id ? getLevelName(item?.level_official_id, dataLevel) : t('common.notDataContract')}
                    </Grid>
                  </Grid>
                  {!getTitleSourceCVText(dataListSourceCV, item) && (
                    <Grid item container xs={12}>
                      &nbsp;
                    </Grid>
                  )}
                </Grid>
                <Grid container item xs={4.6} rowSpacing={2}>
                  <Grid item container xs={12}>
                    <Grid item xs={5} className="info-title">
                      {t('managerCandidate.startingSalary')}
                    </Grid>
                    <Grid item xs={7} className="text-info-custom">
                      {item?.starting_salary
                        ? formatSalary(Math.ceil(item?.starting_salary))
                        : t('common.notDataContract')}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={5} className="info-title">
                      {t('managerCandidate.salaryOfficial')}
                    </Grid>
                    <Grid item xs={7} className="text-info-custom">
                      {item?.salary ? formatSalary(Math.ceil(item?.salary)) : t('common.notDataContract')}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={5} className="info-title">
                      {t('managerCandidate.contract')}
                    </Grid>
                    <Grid item xs={7} className="text-info-custom">
                      {item?.contract_category_id
                        ? getLevelName(item?.contract_category_id, uDataContractCategory)
                        : t('common.notDataContract')}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={5} className="info-title">
                      {t('managerCandidate.startContract')}
                    </Grid>
                    <Grid item xs={7} className="text-info-custom">
                      {item.start_date ? customDate(item.start_date, 'DD/MM/YYYY') : t('common.notDataContract')}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={5} className="info-title">
                      {t('managerCandidate.endContract')}
                    </Grid>
                    <Grid item xs={7} className="text-info-custom">
                      {item.end_date ? customDate(item.end_date, 'DD/MM/YYYY') : t('common.notDataContract')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {isAllowEdit && (
                <div className="box-position-button-edit">
                  <div className="position-button-edit">
                    <div className="edit-apply-button color-silver">
                      <EditLayout permissionName={CANDIDATE_PERMISSION}>
                        <HiPencil
                          onClick={() => {
                            if (!notAllowPermissionUpdate) {
                              setShowModal((prev) => {
                                return { ...prev, edit_apply: true };
                              });
                              setApply({ ...item });
                            }
                          }}
                        />
                      </EditLayout>
                    </div>
                  </div>
                </div>
              )}
              <Grid item md={12}>
                <hr />
              </Grid>

              <Grid item container className={`contentCV ${item?.cv_offline_info ? 'fix-padding-bottom' : ''}`}>
                <Grid item md={8}>
                  {item?.cv_offline_info && (
                    <div className="d-flex">
                      <div className="fixParent md-8">
                        <img src={iconCvDefault} alt="pdf" width="70" height="70" className="pdfThumb" />
                        <div className="fix"></div>
                        <div className="fixBottom"></div>
                      </div>
                      <div className="md-4">
                        <div className="profileContentInfo md-12 cv-text-name">
                          {getNameFile(JSON.parse(item?.cv_offline_info)?.name)}
                        </div>
                        <div className="profileContentInfo sub-description-cv">
                          {getExt(JSON.parse(item?.cv_offline_info)?.name).toUpperCase()}{' '}
                          {JSON.parse(item?.cv_offline_info)?.size + ' kB'} | {t('managerCandidate.updatedAt')}:{' '}
                          {customDate(JSON.parse(item?.cv_offline_info)?.updated_date, 'DD/MM/YYYY')}
                        </div>
                        <div className={styles.groupButtonCV}>
                          <div className="editDeleteCV">
                            <img
                              className="icon-download"
                              alt={iconDownload}
                              src={iconDownload}
                              onClick={() =>
                                downloadCV(
                                  JSON.parse(item?.cv_offline_info)?.url,
                                  JSON.parse(item?.cv_offline_info)?.name,
                                )
                              }
                            />
                          </div>
                          {getExt(JSON.parse(item?.cv_offline_info)?.name) !== 'csv' && (
                            <div className={styles.previewButton}>
                              <img
                                className="icon-download"
                                alt={iconPreview}
                                src={iconPreview}
                                onClick={() => setUrlFilePreview(JSON.parse(item?.cv_offline_info)?.url)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item md={4} className="flex-end">
                  {index === INDEX_FIRST &&
                    type === 'leader' &&
                    item.status === STATUS_CANDIDATE.WAITING_FOR_LEADER_APPROVE_CV && (
                      <>
                        {!item.is_leader_interrupt && (
                          <>
                            <EditLayout permissionName={CANDIDATE_PERMISSION}>
                              <ButtonComponent
                                addClass="approve-btn"
                                handleClick={() =>
                                  alertConfirm(
                                    STATUS_CANDIDATE.WAIT_CANDIDATE_RESPONSE,
                                    t('managerCandidate.approveCVLowercase'),
                                  )
                                }
                                isLoading={isLoading && statusSubmit === STATUS_CANDIDATE.WAIT_CANDIDATE_RESPONSE}
                                text={t('managerCandidate.approveCV')}
                              />
                            </EditLayout>
                            <EditLayout permissionName={CANDIDATE_PERMISSION}>
                              <ButtonComponent
                                addClass="not-approve-btn"
                                handleClick={() =>
                                  alertConfirm(STATUS_CANDIDATE.CANCEL_CV, t('managerCandidate.notApproveCVLowercase'))
                                }
                                isLoading={isLoading && statusSubmit === STATUS_CANDIDATE.CANCEL_CV}
                                text={t('managerCandidate.notApproveCV')}
                              />
                            </EditLayout>
                            <ModalRefuse
                              title={t('managerDecentralization.reasonReject')}
                              label={t('managerCandidate.reasonForTheRefusal')}
                              isShowModal={showModalRefuseCv}
                              setIsShowModal={setShowModalRefuseCv}
                              listApply={item}
                              status={STATUS_CANDIDATE.CANCEL_CV}
                              onSuccess={() => {
                                QueryClient.invalidateQueries([USE_GET_DETAIL_CANDIDATE], {
                                  refetchActive: true,
                                });
                                setRefuseCvSuccess(true);
                              }}
                              required={false}
                            />
                          </>
                        )}
                        {item.is_leader_interrupt && (
                          <EditLayout permissionName={CANDIDATE_PERMISSION}>
                            <ButtonComponent
                              addClass="approve-btn"
                              handleClick={() => {
                                setCandidateConfirmed({ id: candidateId, applyId: item.apply_id, jobId: item.job_id });
                                setShowPopupApprove(true);
                              }}
                              isLoading={isLoadingChangeStatus}
                              text={t('common.confirm')}
                            />
                          </EditLayout>
                        )}
                      </>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <PreviewCV open={!!urlFilePreview} onClose={() => setUrlFilePreview(null)} urlFileCv={urlFilePreview} />
    </div>
  );
};

export default ListApplyJobOrder;
