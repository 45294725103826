import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from 'reactstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import classNames from 'classnames';
import moment from 'moment';

import { Grid, Box } from '@mui/material';

import styles from './styles.module.scss';
import { apiUpdateExpect } from 'api/managerCandidate';
import * as Constants from 'constants/index.js';
import { USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL } from 'constants/ManagerCandidate';
import { checkInvalidDateByDiagonalLine } from 'helpers/formatDate';

import ModalComponent from 'components/Modal';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import DateDay from 'components/ReactForm/DateDay';
import CustomInputNumber from 'components/InputYupAndMui/CusromInputNumber';

const FORM_FIELDS = {
  salary: 'salary',
  onBoardDate: 'onBoardDate',
  note: 'note',
};

const ModalDesired = (props) => {
  const { isShowModal, setShowModal, onSuccess, dataDesired } = props;
  const { t } = useTranslation();
  const { applyId } = useParams();
  const query = useQueryClient();

  const DEFAULT_VALUES = {
    [FORM_FIELDS.salary]: dataDesired?.salary_expect,
    [FORM_FIELDS.onBoardDate]: dataDesired?.date_work_expect,
    [FORM_FIELDS.note]: dataDesired?.expect_other,
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        [FORM_FIELDS.salary]: Yup.number()
          .required(t('messages.pleaseFillOutSalary'))
          .min(Constants.MIN_SALARY, t('messages.pleaseEnterSalaryMore'))
          .nullable()
          .typeError(t('messages.pleaseFillOutSalary')),
        [FORM_FIELDS.onBoardDate]: Yup.string()
          .required(t('messages.pleaseSelectOnBoardDate'))
          .test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) =>
            checkInvalidDateByDiagonalLine(value),
          )
          .nullable(),
      }),
    ),
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const { mutate: submitDesired } = useMutation(
    async (data) => {
      return apiUpdateExpect(applyId, {
        salary_expect: data.salary,
        date_work_expect: data.onBoardDate,
        expect_other: data.note,
      });
    },
    {
      onSuccess: async () => {
        setShowModal(false);
        onSuccess();
        query.invalidateQueries([USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL], { refetchActive: true });
      },
      onError: () => {
        // setError(FORM_FIELDS.reason, { type: 'custom', message: t('common.anUnknownError') });
      },
    },
  );

  return (
    <ModalComponent
      toggle={() => setShowModal(false)}
      isShowModal={isShowModal}
      title={t('managerCandidate.desiredOfCandidate')}
      renderModalFooter={() => (
        <ModalFooter className={styles.modalFooter}>
          <button type="button" className={classNames(styles.btn, styles.btn__cancel)} onClick={handleCloseModal}>
            {t('modalOrder.cancel')}
          </button>
          <button
            type="button"
            className={classNames(styles.btn, styles.btn__action)}
            onClick={handleSubmit(submitDesired)}
          >
            {t('modalOrder.add')}
          </button>
        </ModalFooter>
      )}
    >
      <Box autoComplete="off" className={styles.boxContainer}>
        <form>
          <Grid xs={12}>
            <CustomInputNumber
              name={FORM_FIELDS.salary}
              label={t('managerCandidate.salary')}
              maxLength={Constants.MAX_LENGTH_10}
              control={control}
              useWatch={useWatch}
              register={register}
              setValue={setValue}
              errors={errors}
              required
              defaultValue={DEFAULT_VALUES[FORM_FIELDS.salary]}
              isInteger
            />
          </Grid>
          <Grid xs={12} className={styles.gridItem}>
            <DateDay
              label={t('managerCandidate.startDate')}
              name={FORM_FIELDS.onBoardDate}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              defaultValue={DEFAULT_VALUES[FORM_FIELDS.onBoardDate]}
              isRequired
              minDate={moment()}
              error={errors[FORM_FIELDS.onBoardDate]?.message}
            />
          </Grid>
          <Grid xs={12} className={styles.gridItem}>
            <CustomInputYup
              name={FORM_FIELDS.note}
              label={t('managerCandidate.ortherDesired')}
              maxLength={Constants.MAX_LENGTH_1000}
              control={control}
              useWatch={useWatch}
              register={register}
              errors={errors}
              defaultValue={DEFAULT_VALUES[FORM_FIELDS.note]}
              multiline
              rows={7}
            />
          </Grid>
        </form>
      </Box>
    </ModalComponent>
  );
};

export default ModalDesired;
