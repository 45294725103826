import { useQuery } from 'react-query';
import * as Constants from 'constants/ManagerCandidate';
import { apiGetListTest } from 'api/managerCandidate';
import { HTTP_OK } from 'constants/StatusCode';

export function useGetListTest(params) {
  const { data: response, error, isLoading } = useQuery([Constants.USE_GET_LIST_TEST, params], async () => {
    const response = await apiGetListTest(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  const result = { ...response, error, isLoading, data: response };
  return result;
}
