import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const Index = ({ data, onChangeUrl, ...props }) => {
  const length = data.length;

  return (
    <Grid container>
      {!onChangeUrl && (
        <ul className="p-0 d-flex base-breadcrumb">
          {data.map((item, index) => (
            <li key={index} className="d-block">
              {length !== index + 1 ? <Link to={item.link}>{item.name}</Link> : <div>{item.name}</div>}
              <span>&gt;</span>
            </li>
          ))}
        </ul>
      )}

      {onChangeUrl && (
        <ul className="p-0 d-flex base-breadcrumb">
          {data.map((item, index) => (
            <li key={index} className="d-block" {...props}>
              {length !== index + 1 ? (
                <span className="link-breadcrumb" onClick={(event) => onChangeUrl(item.link)}>
                  {item.name}
                </span>
              ) : (
                <div className={length === 1 ? 'link-breadcrumb' : ''}>{item.name}</div>
              )}
              <span>&gt;</span>
            </li>
          ))}
        </ul>
      )}
    </Grid>
  );
};

export default Index;
