/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ListManagerQuestionInterview from './ListManagerQuestionInterview';
import CreateQuestionInterview from './CreateQuestionInterview';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetQuestionInterviewDetail } from 'hook/useManagerQuestionInterview';

const Index = () => {
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const [currentPage, setCurrentPage] = useState(
    params.get('redirect') === 'create' ? 'CreateQuestionInterview' : 'listManagerQuestionInterview',
  );
  const [idQuestion, setIdQuestion] = useState();
  const { data: dataQuestion, isLoading } = useGetQuestionInterviewDetail({
    id: idQuestion,
  });
  useEffect(() => {
    if (currentPage !== params.get('redirect')) {
      setCurrentPage(params.get('redirect') === 'create' ? 'CreateQuestionInterview' : 'listManagerQuestionInterview');
    }
  }, [search]);
  const Page = {
    listManagerQuestionInterview: (
      <ListManagerQuestionInterview
        setIdQuestion={setIdQuestion}
        createQuestion={(id) => {
          history.push(`/admin/manager-question-interview?redirect=create${typeof id === 'number' ? '&id=' + id : ''}`);
        }}
      />
    ),
    CreateQuestionInterview: (
      <CreateQuestionInterview
        isLoading={isLoading}
        dataQuestion={dataQuestion}
        listManagerQuestionInterview={() => history.push('/admin/manager-question-interview')}
      />
    ),
  };

  return <>{Page[currentPage]}</>;
};
export default Index;
