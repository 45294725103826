import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import styles from './styles.module.scss';

const Index = ({ active, handleCancel, messages, footer, className, ...props }) => {
  return (
    <Dialog open={active} onClose={handleCancel} {...props} className={`${styles.popupComponent} ${className}`}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{messages}</DialogContentText>
      </DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Dialog>
  );
};

export default Index;
