import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Grid, Tooltip } from '@mui/material';

import styles from './styles.module.scss';
import iconInfo from 'assets/img/icons/info.png';
import iconCvDefault from 'assets/img/icons/cv-default.svg';
import iconDownload from 'assets/img/icons/download.png';
import iconPreview from 'assets/img/icons/candidate/preview-icon.svg';

import storageFC from 'helpers/storage';
import { customDate } from 'helpers/formatDate';
import { getName, getColorStatus, getNameFile, getValidHttpsUrl } from 'helpers/index';
import { formatSalary } from 'helpers/format';
import { getPositionName, getSkillName, getLevelName, getDivisionName } from 'helpers/masterData';
import { getNameSourceCV, getTitleSourceCVText, getNameSourceCVOther } from 'helpers/manageCandidate';
import {
  useListLevel,
  useListSkill,
  useListPosition,
  useListContractCategory,
  useListPersonnel,
} from 'hook/useMasterData';
import { useGetListSourceCV } from 'hook/useManagerCandidate';
import { useGetListDivision } from 'hook/useDivision';
import { apiDownloadCV } from 'api/managerCandidate';

import { LIST_STATUS, LIST_YEAR_EXPERIENCE } from 'constants/ManagerCandidate';
import PreviewCV from 'views/pages/ManagerCandidate/components/PreviewCV';

const InfoApply = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();
  const { data: uDataListSourceCV } = useGetListSourceCV();
  const { data: uListDivision } = useGetListDivision();
  const { data: uDataContractCategory } = useListContractCategory();
  const { data: uDataPersonnel } = useListPersonnel();

  const [urlFilePreview, setUrlFilePreview] = useState(null);

  const getExt = (str) => {
    if (str) {
      let arr = str?.split('.');
      return arr[arr?.length - 1];
    }
    return '';
  };

  const downloadCV = (linkCV, name) => {
    fetch(apiDownloadCV(linkCV), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${storageFC.getToken()}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={styles.header}>
        <img alt={iconInfo} src={iconInfo} />
        <span className={styles.titleHeader}>{t('managerCandidate.infoApply')}</span>
      </Grid>
      <Grid container item xs={12} className={styles.backgroundHightLight}>
        <Grid container item xs={6} className={styles.itemHeaderContainer}>
          <Grid item xs={6} className={styles.titleItem}>
            {t('managerCandidate.jobName')}
          </Grid>
          <Tooltip
            title={
              <div className={styles.tooltipJobName}>
                {data?.job_name ? data.job_name : t('common.notDataContract')}
              </div>
            }
            arrow
          >
            <Grid item xs={6} className={classNames(styles.contentItem, styles.jobName)}>
              {data?.job_name ? data.job_name : t('common.notDataContract')}
            </Grid>
          </Tooltip>
        </Grid>
        <Grid container item xs={6} className={styles.itemHeaderContainer}>
          <Grid item xs={6} className={styles.titleItem}>
            {t('managerCandidate.dateApply')}
          </Grid>
          <Grid item xs={6} className={styles.contentItem}>
            {data?.apply_date ? customDate(data.apply_date, 'DD/MM/YYYY') : t('common.notDataContract')}
          </Grid>
        </Grid>
        <Grid container item xs={6} className={styles.itemHeaderContainer}>
          <Grid item xs={6} className={styles.titleItem}>
            {t('managerCandidate.HRCharge')}
          </Grid>
          <Grid item xs={6} className={styles.contentItem}>
            {data?.user_info?.name}
          </Grid>
        </Grid>
        <Grid container item xs={6} className={styles.itemHeaderContainer}>
          <Grid item xs={6} className={styles.titleItem}>
            {t('managerCandidate.status')}
          </Grid>
          <Grid item xs={6} className={`${getColorStatus(LIST_STATUS, data?.status)} ${styles.contentItem}`}>
            {getName(LIST_STATUS, data?.status)}
          </Grid>
        </Grid>
        <Grid container item xs={6} className={styles.itemHeaderContainer}>
          <Grid item xs={6} className={styles.titleItem}>
            {t('managerCandidate.dayInterview')}
          </Grid>
          <Grid item xs={6} className={styles.contentItem}>
            {data?.interview_date ? customDate(data.interview_date, 'DD/MM/YYYY') : t('common.notDataContract')}
          </Grid>
        </Grid>
        <Grid container item xs={6} className={styles.itemHeaderContainer}>
          <Grid item xs={6} className={styles.titleItem}>
            {t('managerCandidate.trainCommit')}
          </Grid>
          <Grid item xs={6} className={styles.contentItem}>
            {data?.has_commit ? t('managerCandidate.hasCommit') : t('managerCandidate.notHasCommit')}
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} columnSpacing={1} className={styles.bodyContainer}>
        <Grid container item xs={6}>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7} className={styles.titleItem}>
              {t('managerCandidate.position')}
            </Grid>
            <Grid item xs={5} className={styles.contentItem}>
              {getPositionName(data.position_id, uDataPosition)}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7} className={styles.titleItem}>
              {t('managerCandidate.skill')}
            </Grid>
            <Grid item xs={5} className={styles.contentItem}>
              {getSkillName(data?.skill_id, uDataSkill)}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7} className={styles.titleItem}>
              {t('managerCandidate.applyLevel')}
            </Grid>
            <Grid item xs={5} className={styles.contentItem}>
              {getLevelName(data?.level_id, uDataLevel)}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7} className={styles.titleItem}>
              {t('managerCandidate.experience')}
            </Grid>
            <Grid item xs={5} className={styles.contentItem}>
              {getName(LIST_YEAR_EXPERIENCE, data?.experience)}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7} className={styles.titleItem}>
              {t('managerCandidate.startingSalary')}
            </Grid>
            <Grid item xs={5} className={styles.contentItem}>
              {data?.starting_salary ? formatSalary(Math.ceil(data.starting_salary)) : t('common.notDataContract')}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7} className={styles.titleItem}>
              {t('managerCandidate.salaryOfficial')}
            </Grid>
            <Grid item xs={5} className={styles.contentItem}>
              {data?.salary ? formatSalary(Math.ceil(data.salary)) : t('common.notDataContract')}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7} className={styles.titleItem}>
              {t('managerCandidate.contract')}
            </Grid>
            <Grid item xs={5} className={styles.contentItem}>
              {getName(uDataContractCategory, data?.contract_category_id)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7.5} className={styles.titleItem}>
              {t('managerCandidate.sourceCv')}
            </Grid>
            <Grid item xs={4.5} className={classNames(styles.contentItem, styles.textOver)}>
              {getNameSourceCV(uDataListSourceCV, data?.source_cv)}
            </Grid>
          </Grid>
          {getTitleSourceCVText(uDataListSourceCV, data) && (
            <Grid container item xs={12} className={styles.itemContainer}>
              <Grid item xs={7.5} className={styles.titleItem}>
                {getTitleSourceCVText(uDataListSourceCV, data)}
              </Grid>
              <Grid item xs={4.5} className={styles.contentItem}>
                {getNameSourceCVOther(data)}
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7.5} className={styles.titleItem}>
              {t('managerCandidate.Personnel')}
            </Grid>
            <Grid item xs={4.5} className={styles.contentItem}>
              {getName(uDataPersonnel, data?.group_id)}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7.5} className={styles.titleItem}>
              {t('managerCandidate.division')}
            </Grid>
            <Grid item xs={4.5} className={styles.contentItem}>
              {getDivisionName(data?.division_id, uListDivision)}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7.5} className={styles.titleItem}>
              {t('managerCandidate.officialLevel')}
            </Grid>
            <Grid item xs={4.5} className={styles.contentItem}>
              {data?.level_official_id ? getName(uDataLevel, data.level_official_id) : t('common.notDataContract')}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7.5} className={styles.titleItem}>
              {t('managerCandidate.startContract')}
            </Grid>
            <Grid item xs={4.5} className={styles.contentItem}>
              {data?.start_date ? customDate(data.start_date, 'DD/MM/YYYY') : t('common.notDataContract')}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={7.5} className={styles.titleItem}>
              {t('managerCandidate.endContract')}
            </Grid>
            <Grid item xs={4.5} className={styles.contentItem}>
              {data?.end_date ? customDate(data.end_date, 'DD/MM/YYYY') : t('common.notDataContract')}
            </Grid>
          </Grid>
          {!getTitleSourceCVText(uDataListSourceCV, data) && (
            <Grid item xs={12} className={styles.itemContainer}></Grid>
          )}
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={12} className={styles.itemContainer}>
            <Grid item xs={3} className={styles.titleItem}>
              {t('managerCandidate.linkCVOnl')}
            </Grid>
            <Grid item xs={9} className={styles.link}>
              <a href={getValidHttpsUrl(data?.link_cv_online)} target="_blank" rel="noreferrer">
                {data?.link_cv_online ? data.link_cv_online : t('common.notDataContract')}
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="divider w-100"></div>
      <Grid container item xs={12}>
        {data?.cv_offline_info && (
          <div className={styles.CVContainer}>
            <img src={iconCvDefault} alt={iconCvDefault} className={styles.iconCV} />
            <div className="md-4">
              <div className={styles.titleCV}>{getNameFile(JSON.parse(data?.cv_offline_info)?.name)}</div>
              <div className={styles.contentCV}>
                {getExt(JSON.parse(data?.cv_offline_info)?.name).toUpperCase()}{' '}
                {JSON.parse(data?.cv_offline_info)?.size + ' kB'} | {t('managerCandidate.updatedAt')}:{' '}
                {customDate(JSON.parse(data?.cv_offline_info)?.updated_date, 'DD/MM/YYYY')}
              </div>
              <div className={styles.groupButtonCV}>
                <div className="editDeleteCV">
                  <img
                    className={styles.iconDownload}
                    alt={iconDownload}
                    src={iconDownload}
                    onClick={() =>
                      downloadCV(JSON.parse(data?.cv_offline_info)?.url, JSON.parse(data?.cv_offline_info)?.name)
                    }
                  />
                </div>
                {getExt(JSON.parse(data?.cv_offline_info)?.name) !== 'csv' && (
                  <div className={styles.previewButton}>
                    <img
                      className={styles.iconDownload}
                      alt={iconPreview}
                      src={iconPreview}
                      onClick={() => setUrlFilePreview(JSON.parse(data?.cv_offline_info)?.url)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Grid>
      <PreviewCV open={!!urlFilePreview} onClose={() => setUrlFilePreview(null)} urlFileCv={urlFilePreview} />
    </Grid>
  );
};

export default InfoApply;
