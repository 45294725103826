import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import styles from './styles.module.scss';

import avatarDefault from 'assets/img/icons/default-avatar.svg';

import { apiNotificationLeaderAssets } from 'api/managerCandidate';

import { showAlert } from 'helpers/modal';
import { CANDIDATE_NOT_RATED_YET } from 'constants/ManagerCandidate';
import { HTTP_OK } from 'constants/StatusCode';
import { handleOnError } from 'helpers/index';

import AlertComponent from 'components/AlertAfterSubmit/index.js';
import { CANDIDATE_PERMISSION } from 'constants/permission';
import EditLayout from 'components/PermissionPage/EditLayout';

const DetailApplyLeader = ({ dataStatus, applyId, type, userId }) => {
  const { t } = useTranslation();
  const dataLeader = dataStatus?.user_info;

  const [btnDisable, setBtnDisable] = useState(true);
  const [isShowAlertNotification, setShowAlertNotification] = useState(false);

  useEffect(() => {
    if (dataStatus?.result === CANDIDATE_NOT_RATED_YET) {
      setBtnDisable(false);
    }
  }, [dataStatus]);

  const { mutate: submitNotification, isLoading } = useMutation(
    () =>
      apiNotificationLeaderAssets({
        leader_id: dataStatus.user_id,
        user_id: userId,
        apply_id: Number(applyId),
      }),
    {
      onSuccess: (response) => {
        if (response.status === HTTP_OK) {
          setShowAlertNotification(true);
        } else {
          if (response?.data?.message) {
            showAlert(response.data.message);
          } else {
            showAlert(t('messages.systemError'));
          }
        }
      },
      onError: (error) => {
        showAlert(t('messages.systemError'));
      },
    },
  );

  const handelSubmitNotification = () => {
    submitNotification();
  };

  return (
    <Grid container className="div-leader-evaluate-detail-apply">
      <AlertComponent toggle={() => setShowAlertNotification(false)} isShowAlert={isShowAlertNotification}>
        {t('managerCandidate.remindReviewSuccess')}
      </AlertComponent>
      <div className="div-avatar-leader">
        <img
          className="avatar-leader-notRate"
          alt="avatar"
          src={dataLeader?.avatar ? dataLeader.avatar : avatarDefault}
          onError={handleOnError}
        />
      </div>
      <div>
        <div className="div-name-leader">{dataLeader?.name}</div>
        <div className="div-name-division">
          {dataLeader?.position_name} - {dataStatus?.division_name}
        </div>
      </div>
      <div className={styles.divButtonReviewPrompt}>
        <EditLayout permissionName={CANDIDATE_PERMISSION}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={btnDisable || isLoading}
            onClick={handelSubmitNotification}
          >
            {t('managerCandidate.remindReview')}{' '}
            <i
              className={classNames(
                `fa fa-spinner fa-pulse fa-3x fa-fw ${isLoading ? '' : 'hidden'} ${styles.iconLoading}`,
              )}
            />
          </Button>
        </EditLayout>
      </div>
    </Grid>
  );
};
export default DetailApplyLeader;
