import React, { useState } from 'react';

import { Grid, Tab, Tabs } from '@mui/material';

import { useGetListSchool } from 'hook/useManagerCandidate';
import { useGetListSourceCV } from 'hook/useManagerCandidate';
import { useGetListAppliesAvailable } from 'hook/useManagerJob';

import { LIST_YEAR_EXPERIENCE } from 'constants/ManagerCandidate';
import { QUANTITY_CANDIDATE, APPLIES_AVAILABLE, CREATE_NEW_APPLIES } from 'constants/manageJob';
import ModalComponent from 'components/Modal';
import CreateCandidateModal from './CreateCandidateModal';
import CreateAppliesAvailable from './CreateAppliesAvailable';

const DEFAULT_FILTER = {
  full_name: null,
  level_id: null,
  position_id: null,
  skill_id: null,
};

const ModalAddApplies = ({
  openAddApplies,
  setOpenAddApplies,
  t,
  data,
  mapPosition,
  mapSkill,
  mapLevel,
  useListLevel,
  useListPosition,
  useListSkill,
  query,
  handleOpenDetailJob,
}) => {
  const [filterApplie, setFilterApplie] = useState(DEFAULT_FILTER);
  const [tabActive, setTabActive] = useState('appliesAvailable');
  const { data: dataAppliesAvailable, isLoading: isLoadingAppliesAvailable } = useGetListAppliesAvailable({
    ...filterApplie,
    id: data.id,
  });
  const { data: uDataListSourceCV } = useGetListSourceCV();
  const { data: uDataListSchool } = useGetListSchool();
  const handleTab = (e, newValue) => {
    setTabActive(newValue);
  };

  const handleRest = () => {
    setFilterApplie(DEFAULT_FILTER);
  }

  return (
    <>
      <ModalComponent
        isShowModal={openAddApplies}
        title={t('managerJob.addApplies')}
        toggle={() => {
          setOpenAddApplies(false);
          handleOpenDetailJob(QUANTITY_CANDIDATE);
        }}
        classNameAdd={`no-padding-modal-body modal-add-applies detail-job manager-job ${
          tabActive === CREATE_NEW_APPLIES && 'modal-create-applies'
        }`}
      >
        {tabActive === CREATE_NEW_APPLIES && <div className="job-name-add-applies">{data.jobName}</div>}
        <div className="body-add-applies-container">
          <Grid container>
            <Tabs value={tabActive} onChange={handleTab}>
              <Tab label={t('managerJob.appliesAvailable')} value={APPLIES_AVAILABLE} />
              <Tab label={t('managerJob.createNewApplies')} value={CREATE_NEW_APPLIES} onClick={handleRest} />
            </Tabs>
          </Grid>
          {tabActive === 'appliesAvailable' ? (
            <CreateAppliesAvailable
              setOpenAddApplies={setOpenAddApplies}
              useListPosition={useListPosition}
              useListLevel={useListLevel}
              useListSkill={useListSkill}
              dataAppliesAvailable={dataAppliesAvailable}
              mapPosition={mapPosition}
              mapSkill={mapSkill}
              mapLevel={mapLevel}
              setFilter={setFilterApplie}
              t={t}
              id={data.id}
              query={query}
              filter={filterApplie}
              handleOpenDetailJob={handleOpenDetailJob}
              isLoadingAppliesAvailable={isLoadingAppliesAvailable}
            />
          ) : (
            <CreateCandidateModal
              isShowModal={true}
              setShowModal={setOpenAddApplies}
              dataPosition={useListPosition}
              dataSkill={useListSkill}
              dataLevel={useListLevel}
              dataListSchool={uDataListSchool}
              dataExperience={LIST_YEAR_EXPERIENCE}
              dataListSourceCV={uDataListSourceCV}
              detailJob={data}
              handleOpenDetailJob={handleOpenDetailJob}
            />
          )}
        </div>
      </ModalComponent>
    </>
  );
};

export default ModalAddApplies;
