/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import moment from 'moment';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { Grid, MenuItem } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './styles.module.scss';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import CusromInputNumber from 'components/InputYupAndMui/CusromInputNumber';
import SelectField from 'components/ReactForm/SelectField';
import DateDay from 'components/ReactForm/DateDay';
import { apiDistributeDivision, apiUpdateDistributeDivision } from 'api/managerCandidate';
import { checkInvalidDateByDiagonalLine, formatDateIgnoreDiagonalLine } from 'helpers/formatDate';
import { useListLevel, useListPersonnel, useListContractCategory } from 'hook/useMasterData';
import { useGetListLeaderAssessCandidate } from 'hook/useDetailApplyLeader';
import { useGetListDivisionAccept } from 'hook/useDivision';

import { MAX_LENGTH_10, MIN_SALARY } from 'constants/index.js';
import {
  USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL,
  indefinite_contract,
  NOT_HAS_COMMIT,
  HAS_COMMIT,
  USE_GET_LIST_LEADER_ASSESS_CANDIDATE,
  TYPE_CANDIDATE_HR,
} from 'constants/ManagerCandidate';
import CustomCheckboxYup from 'components/InputYupAndMui/CustomCheckboxYup';

const FORM_FIELDS = {
  division_id: 'division_id',
  level_official_id: 'level_official_id',
  salary: 'salary',
  starting_salary: 'starting_salary',
  group_id: 'group_id',
  contract_category_id: 'contract_category_id',
  start_date: 'start_date',
  end_date: 'end_date',
  status: 'status',
};

const ModalToDivision = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { title, isShowModal, setIsShowModal, onSuccess, dataApplyCandidate } = props;
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [hasCommit, setHasCommit] = useState(dataApplyCandidate?.has_commit ? dataApplyCandidate.has_commit : false);
  const [divisionSelected, setDivisionSelected] = useState();

  const { applyId } = useParams();

  const { data: uDataLevel } = useListLevel();
  const { data: listDivisionAccept } = useGetListDivisionAccept({ apply_id: applyId });
  const { data: uLeaderAssess } = useGetListLeaderAssessCandidate(applyId, TYPE_CANDIDATE_HR);
  const { data: uDataContractCategory } = useListContractCategory();
  const { data: uDataPersonnel } = useListPersonnel();

  const DEFAULT_VALUES = {
    [FORM_FIELDS.division_id]: dataApplyCandidate?.division_id ? dataApplyCandidate.division_id : null,
    [FORM_FIELDS.level_official_id]: dataApplyCandidate?.level_official_id ? dataApplyCandidate.level_official_id : '',
    [FORM_FIELDS.salary]: dataApplyCandidate?.salary ? dataApplyCandidate.salary : null,
    [FORM_FIELDS.starting_salary]: dataApplyCandidate?.starting_salary ? dataApplyCandidate.starting_salary : null,
    [FORM_FIELDS.group_id]: dataApplyCandidate?.group_id ? dataApplyCandidate.group_id : '',
    [FORM_FIELDS.contract_category_id]: dataApplyCandidate?.contract_category_id
      ? dataApplyCandidate.contract_category_id
      : '',
    [FORM_FIELDS.start_date]: dataApplyCandidate?.start_date ? dataApplyCandidate.start_date : null,
    [FORM_FIELDS.end_date]: dataApplyCandidate?.end_date ? dataApplyCandidate.end_date : null,
  };

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        [FORM_FIELDS.division_id]: Yup.number().required(t('messages.pleaseSelectDivision')).nullable(),
        [FORM_FIELDS.level_official_id]: Yup.number()
          .typeError(t('messages.pleaseSelectLevel'))
          .required(t('messages.pleaseSelectLevel'))
          .nullable(),
        [FORM_FIELDS.starting_salary]: Yup.number()
          .typeError(t('messages.pleaseFillOutStartingSalary'))
          .required(t('messages.pleaseFillOutStartingSalary'))
          .min(MIN_SALARY, t('messages.pleaseEnterSalaryMore'))
          .nullable(),
        [FORM_FIELDS.salary]: Yup.number()
          .typeError(t('messages.pleaseFillOutOfficialSalary'))
          .required(t('messages.pleaseFillOutOfficialSalary'))
          .min(MIN_SALARY, t('messages.pleaseEnterSalaryMore'))
          .nullable(),
        [FORM_FIELDS.group_id]: Yup.number()
          .typeError(t('messages.pleaseSelectPersonnel'))
          .required(t('messages.pleaseSelectPersonnel'))
          .nullable(),
        [FORM_FIELDS.contract_category_id]: Yup.number()
          .typeError(t('messages.pleaseSelectContract'))
          .required(t('messages.pleaseSelectContract'))
          .nullable(),
        [FORM_FIELDS.start_date]: Yup.string()
          .nullable()
          .required(t('messages.pleaseSelectStartContractDate'))
          .test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) => {
            return checkInvalidDateByDiagonalLine(value);
          })
          .test('min', t('messages.pleaseEnterTheDateNowOrFuture'), (value) => {
            return (
              (!!dataApplyCandidate?.start_date &&
                moment(dataApplyCandidate.start_date, 'YYYY-MM-DD').isSame(
                  moment(formatDateIgnoreDiagonalLine(value), 'DD/MM/YYYY'),
                )) ||
              !moment(formatDateIgnoreDiagonalLine(value), 'DD/MM/YYYY').isBefore(moment(), 'day')
            );
          }),
        [FORM_FIELDS.end_date]: Yup.string()
          .nullable()
          .when(['contract_category_id'], {
            is: (typeContract) => {
              return Number(typeContract) !== indefinite_contract;
            },
            then: Yup.string()
              .nullable()
              .required(t('messages.pleaseSelectEndContractDate'))
              .test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) =>
                checkInvalidDateByDiagonalLine(value),
              )
              .test('min', t('messages.pleaseEnterEndDateAfterStartDate'), (value) => {
                return !moment(getValues('start_date'), 'DD/MM/YYYY').isAfter(
                  moment(formatDateIgnoreDiagonalLine(value), 'DD/MM/YYYY'),
                );
              }),
          }),
      }),
    ),
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const contract_category_id = useWatch({
    control,
    name: 'contract_category_id',
  });

  useEffect(() => {
    if (contract_category_id === indefinite_contract) {
      clearErrors(FORM_FIELDS.end_date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract_category_id]);

  const { mutate: distributeDivision, isLoading } = useMutation(
    async (values) => {
      const payload = {
        division_id: values[FORM_FIELDS.division_id],
        apply_id: Number(applyId),
        salary: Number(values[FORM_FIELDS.salary]) ? Number(values[FORM_FIELDS.salary]) : null,
        starting_salary: values[FORM_FIELDS.starting_salary],
        start_date: values[FORM_FIELDS.start_date],
        end_date: contract_category_id !== indefinite_contract ? values[FORM_FIELDS.end_date] : null,
        level_official_id: values[FORM_FIELDS.level_official_id],
        contract_category_id: values[FORM_FIELDS.contract_category_id],
        group_id: values[FORM_FIELDS.group_id],
        status: hasCommit ? HAS_COMMIT : NOT_HAS_COMMIT,
      };
      if (dataApplyCandidate?.division_id) {
        await apiUpdateDistributeDivision(applyId, payload);
      } else {
        await apiDistributeDivision(payload);
      }
    },
    {
      onSuccess: async () => {
        queryClient.refetchQueries(USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL);
        queryClient.refetchQueries(USE_GET_LIST_LEADER_ASSESS_CANDIDATE);
        setIsShowModal(false);
        onSuccess();
        reset();
      },
      onError: () => {
        // setError(FORM_FIELDS.reason, { type: 'custom', message: t('common.anUnknownError') });
      },
    },
  );

  useEffect(() => {
    if (divisionSelected && uLeaderAssess) {
      const dataAssess = uLeaderAssess?.find((item) => item.division_id === divisionSelected);
      setValue(FORM_FIELDS.level_official_id, dataAssess.level_official_id);
      setValue(FORM_FIELDS.starting_salary, dataAssess.starting_salary);
      setValue(FORM_FIELDS.salary, dataAssess.salary);
      setValue(FORM_FIELDS.group_id, dataAssess.group_id);
      setValue(FORM_FIELDS.contract_category_id, dataAssess.contract_category_id);
      clearErrors(FORM_FIELDS.level_official_id);
      clearErrors(FORM_FIELDS.group_id);
      clearErrors(FORM_FIELDS.contract_category_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionSelected, uLeaderAssess]);

  const handleCloseModal = () => {
    setIsShowModal(false);
    reset();
  };

  const handleChangeStartDate = (date) => {
    setDateStart(date);
  };
  const handleChangeEndDate = (date) => {
    setDateEnd(date);
  };

  return (
    <>
      <ModalComponent
        title={title}
        isShowModal={isShowModal}
        toggle={handleCloseModal}
        renderModalFooter={() => (
          <ModalFooter className={styles.modalFooter}>
            <ButtonComponent
              addClass={classNames(styles.btn, styles.btn__action)}
              handleClick={handleSubmit(distributeDivision)}
              hasDisabled={isLoading}
              isLoading={isLoading}
              text={t('managerCandidate.complete')}
            />
          </ModalFooter>
        )}
        classNameAdd={styles.ModalToDivision}
      >
        <Grid container spacing={3}>
          <Grid item md={12} className={styles.item}>
            <SelectField
              name={FORM_FIELDS.division_id}
              label={t('managerCandidate.choosedDivision')}
              control={control}
              required
              setValue={setValue}
              useWatch={useWatch}
              register={register}
              isShowModal={isShowModal}
              isRequired
              error={errors[FORM_FIELDS.division_id]?.message}
              defaultValue={DEFAULT_VALUES.division_id}
            >
              {listDivisionAccept &&
                listDivisionAccept?.map((item) => (
                  <MenuItem value={item.id} key={item.id} onClick={() => setDivisionSelected(item.id)}>
                    {item.name}
                  </MenuItem>
                ))}
            </SelectField>
          </Grid>
          <Grid item md={12} className={styles.item}>
            <SelectField
              name={FORM_FIELDS.level_official_id}
              label={t('managerCandidate.chooseOfficialLevel')}
              control={control}
              required
              setValue={setValue}
              useWatch={useWatch}
              register={register}
              isShowModal={isShowModal}
              isRequired
              error={errors[FORM_FIELDS.level_official_id]?.message}
              defaultValue={DEFAULT_VALUES.level_official_id}
            >
              {uDataLevel?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item md={12} className="item-field">
            <CusromInputNumber
              label={t('managerCandidate.startingSalary')}
              name={FORM_FIELDS.starting_salary}
              control={control}
              register={register}
              errors={errors}
              useWatch={useWatch}
              required
              setValue={setValue}
              maxLength={MAX_LENGTH_10}
              isInteger
              defaultValue={DEFAULT_VALUES.starting_salary}
            />
          </Grid>
          <Grid item md={12} className="item-field">
            <CusromInputNumber
              label={t('managerCandidate.salaryOfficial')}
              name={FORM_FIELDS.salary}
              control={control}
              register={register}
              errors={errors}
              useWatch={useWatch}
              setValue={setValue}
              maxLength={MAX_LENGTH_10}
              isInteger
              required
              defaultValue={DEFAULT_VALUES.salary}
            />
          </Grid>
          <Grid item md={12} className={styles.item}>
            {uDataPersonnel && (
              <SelectField
                name={FORM_FIELDS.group_id}
                label={t('managerCandidate.Personnel')}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                error={errors[FORM_FIELDS.group_id]?.message}
                defaultValue={DEFAULT_VALUES.group_id}
              >
                {uDataPersonnel?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Grid>
          <Grid item md={12} className={styles.item}>
            {uDataContractCategory && (
              <SelectField
                name={FORM_FIELDS.contract_category_id}
                label={t('managerCandidate.Contract')}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                error={errors[FORM_FIELDS.contract_category_id]?.message}
                defaultValue={DEFAULT_VALUES.contract_category_id}
              >
                {uDataContractCategory?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Grid>

          <Grid item md={12} className="item-field">
            <DateDay
              label={t('managerCandidate.startContract')}
              name={FORM_FIELDS.start_date}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              isShowModal={isShowModal}
              minDate={moment()}
              maxDate={dateEnd}
              isRequired
              defaultValue={DEFAULT_VALUES.start_date}
              onChangeHandle={(event) => handleChangeStartDate(event)}
              error={errors[FORM_FIELDS.start_date]?.message}
            />
          </Grid>
          <Grid item md={12} className="item-field">
            <DateDay
              label={t('managerCandidate.endContract')}
              name={FORM_FIELDS.end_date}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              isShowModal={isShowModal}
              defaultValue={DEFAULT_VALUES.end_date}
              minDate={dateStart || moment()}
              disabled={contract_category_id === indefinite_contract}
              isRequired={contract_category_id !== indefinite_contract}
              onChangeHandle={(event) => handleChangeEndDate(event)}
              error={errors[FORM_FIELDS.end_date]?.message}
            />
          </Grid>
          <Grid item md={12} className="item-field">
            <CustomCheckboxYup
              control={control}
              name={FORM_FIELDS.status}
              label={t('managerCandidate.candidateHasCommitmentToTraining')}
              onClick={(e) => setHasCommit(e.target.checked)}
              defaultValue={hasCommit}
            />
          </Grid>
        </Grid>
      </ModalComponent>
    </>
  );
};

export default ModalToDivision;
