import React from 'react';
import { InputBase } from '@mui/material';
import * as Constants from 'constants/index.js';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import { useTranslation } from 'react-i18next';

const FilterOrderByStatus = ({ setIsModals, setFilter, data, setData, isModals, filter, changeSelect }) => {
  const { t } = useTranslation();

  const getValueInput = () => {
    return data
      .map((item, index) => (item.status && item.value !== Constants.ALL ? (index !== 1 ? ' ' : '') + item.name : ''))
      .filter((it) => it);
  };

  const handleSubmitSearch = () => {
    let datas = [];
    data.forEach((it) => {
      it.status && it.value && datas.push(it.value);
    });
    setFilter({
      ...filter,
      status: datas,
      page: Constants.DEFAULT_PAGE_MUI,
    });
    setIsModals((prev) => {
      return { ...prev, status: false };
    });
  };

  const handleChangeSelected = () => {
    setIsModals((prev) => {
      return { ...prev, status: !prev.status };
    });
    if (filter?.status) {
      data?.forEach((item) => {
        Number(filter?.status) === Number(item?.value) ? (item.status = true) : (item.status = false);
      });
      setData([...data]);
    } else {
      data?.forEach((item) => {
        item.status = false;
      });
      setData([...data]);
    }
  };

  return (
    <>
      <InputBase
        disabled
        type="text"
        name="status"
        className="input-select-multi mt-3"
        onClick={() => {
          setIsModals(() => {
            return { status: true };
          });
        }}
        value={getValueInput()}
        placeholder={t('masterData.StandardIndex.status')}
      />
      <ModalCommon
        items={data}
        onSubmit={handleSubmitSearch}
        onClick={changeSelect}
        toggle={handleChangeSelected}
        modal={isModals.status}
        title={t('masterData.StandardIndex.chooseStatus')}
        footer={t('common.select')}
      />
    </>
  );
};

export default FilterOrderByStatus;
