import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { VIEW_PERMISSION } from 'constants/permission';
import NotAcceptPermission from 'views/pages/NotAcceptPermission';

const PrivateRoute = (props) => {
  const { path, component, ThemContext, permissionName } = props;
  const { hasPermission } = useContext(ThemContext);
  return (
    <Route path={path}>
      {hasPermission(permissionName, VIEW_PERMISSION) ? React.createElement(component) : <NotAcceptPermission />}
    </Route>
  );
};

export default PrivateRoute;
