/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from 'react-query';

import * as Constants from './../constants/ManagerQuestionInterview';
import { HTTP_OK } from 'constants/StatusCode';
import { apiGetQuestionInterview } from 'api/managerQuestionInterview';
import { apiGetQuestionInterviewDetail } from 'api/managerQuestionInterview';
import { apiGetQuestionsInterview } from 'api/managerQuestionInterview';

export function useGetListQuestionInterview(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_QUESTIONINTERVIEW, params], async () => {
    const response = await apiGetQuestionInterview(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return { ...response, error, isLoading, total_questions: response?.data?.total_questions };
}

export function useGetQuestionInterviewDetail(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.GET_ITEM_QUESTION_DETAIL, params],
    async () => {
      if (!params.id) return { data: null };
      const response = await apiGetQuestionInterviewDetail(params);
      if (response.status === HTTP_OK) {
        const item = response.data.data;
        const list_questions = JSON.parse(item.list_questions);
        return {
          data: {
            status: item.status,
            position_id: item.position_id,
            skill_id: item.skill_id,
            list_questions_1: list_questions.filter((item) => item.type === 1),
            list_questions_2: list_questions.filter((item) => item.type === 2),
            id: item.id,
          },
        };
      }
      return null;
    },
    {
      enabled: !!params.id,
    },
  );

  return { ...response, error, isLoading };
}

export function useGetQuestionsInterview(applyId) {
  const {
    data: response,
    error,
    isLoading,
    isFetching,
  } = useQuery(
    [Constants.GET_QUESTION_INTERVIEW, applyId],
    async () => {
      const response = await apiGetQuestionsInterview(applyId);
      if (response.status === HTTP_OK) {
        return response;
      }
      return null;
    },
    {
      enabled: !!applyId,
    },
  );
  return { ...response, error, isLoading, isFetching };
}
