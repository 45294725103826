import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import styles from './styles.module.scss';
import { getScoreAvg } from 'helpers/manageCandidate';

const TotalAvgPoint = (props) => {
  const { listAssess } = props;
  const { t } = useTranslation();

  const getTotalScoreAvg = (keyField) => {
    let sumScore = 0;
    let totalLeaderAssess = 0;
    for (let i = 0; i < listAssess.length; i++) {
      if (JSON.parse(listAssess[i].list_stats)) {
        sumScore += getScoreAvg(JSON.parse(listAssess[i].list_stats), keyField);
        totalLeaderAssess += 1;
      }
    }
    if (!sumScore) return null;
    return sumScore / totalLeaderAssess;
  };

  return (
    <Grid item container xs={12} className={styles.totalAverageScore}>
      <Grid item xs={12}>
        {t('managerCandidate.totalAverageScore')}
      </Grid>
      <Grid item xs={12}>
        {!listAssess?.length && <>&nbsp;</>}
        {!!listAssess?.length && !getTotalScoreAvg('standar_point') && <>&nbsp;</>}
        {!!listAssess?.length &&
          getTotalScoreAvg('standar_point') &&
          getTotalScoreAvg('avg_point').toFixed(1) + '/' + getTotalScoreAvg('standar_point').toFixed(1)}
      </Grid>
    </Grid>
  );
};

export default TotalAvgPoint;
