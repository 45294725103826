import React, { useContext } from 'react';
import { ThemContext } from 'layouts/Admin';
import ManagerOrderHr from './ManagerOrderHr/index';
import ManagerOrderLeader from './ManagerOrderLeader/index';

const ManagerOrder = () => {
  const { isLeader, isAssigner } = useContext(ThemContext);
  if (isLeader || isAssigner) return <ManagerOrderLeader />;
  return <ManagerOrderHr />
};

export default ManagerOrder;
