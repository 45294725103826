import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import moment from 'moment';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { TextFieldDate as TextFieldDateDay, TextFieldNoneDate as TextFieldNoneDateDay } from '../DateTime/style.js';
import { CssDateField } from 'components/InputYupAndMui/constant.js';
import { customDate } from 'helpers/formatDate.js';

const DateDay = (props) => {
  const {
    label,
    defaultValue,
    onChangeHandle,
    name,
    error,
    isRequired,
    placeholder,
    sizeDate,
    setValue,
    control,
    register,
    isShowModal,
    minDate,
    maxDate,
    disabled,
    isReadOnly,
    setError,
    addErrors,
  } = props;
  const [valueDate, setValueDate] = useState(defaultValue);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, customDate(defaultValue, 'DD/MM/YYYY'));
      setValueDate(defaultValue);
    } else {
      if (isShowModal !== undefined && !isShowModal) {
        setValue(name, null);
        setValueDate(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDate = (newValue) => {
    setError && setError(name, '');
    setValue(name, customDate(newValue, 'DD/MM/YYYY'));
    setValueDate(newValue);
    if (onChangeHandle) {
      onChangeHandle(newValue);
    }
  };

  // Watch value of input date
  const valueWatch = useWatch({
    control,
    name: name,
  });

  return (
    <>
      <CssDateField labelspace={valueWatch ? 0 : '-1px'}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            render={({ field }) => (
              <DesktopDatePicker
                fullWidth
                name={name}
                open={open}
                inputFormat="dd/MM/yyyy"
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={valueDate}
                label={
                  <React.Fragment>
                    {placeholder && !valueDate ? placeholder : label}
                    {/* {isRequired && label ? <sup>*</sup> : ''} */}
                  </React.Fragment>
                }
                onChange={handleChangeDate}
                inputProps={{
                  maxLength: 10,
                  style: sizeDate ? { fontSize: '14px' } : { padding: '14px 15px 14px 15px', fontSize: '14px' },
                  readOnly: isReadOnly ? true : false,
                  disabled: isReadOnly || disabled ? true : false,
                }}
                renderInput={(params) => {
                  if (label) {
                    return (
                      <TextFieldDateDay
                        {...params}
                        {...register(name)}
                        error={error || addErrors ? true : false}
                        required={isRequired}
                      />
                    );
                  }
                  return (
                    <TextFieldNoneDateDay {...params} {...register(name)} error={error || addErrors ? true : false} />
                  );
                }}
                maxDate={maxDate ? new Date(`${maxDate}`) : moment('31/12/2222', 'DD/MM/yyyy').toDate()}
                minDate={
                  minDate?.toString() === 'Invalid Date' || minDate === null || minDate === undefined
                    ? moment('1/1/1900', 'DD/MM/yyyy').toDate()
                    : new Date(`${minDate}`)
                }
                disabled={disabled}
              />
            )}
            control={control}
            name={name}
          />
        </LocalizationProvider>
      </CssDateField>
      <p className="error-input error">{error}</p>
      {addErrors && <p className="error-input error">{addErrors}</p>}
    </>
  );
};

export default DateDay;
