import {
  getListStandardIndex,
  getListUser,
  apiGetListSchool,
  getListSkill,
  getListPersonnel,
  getListContractCategory,
  getListBonus,
  getListPosition,
  getListLevel,
  getListHrCharge,
  apiGetListSourceCV,
} from 'api/masterData.js';
import * as Constants from 'constants/MasterData.js';
import { USE_GET_LIST_SCHOOL, USE_GET_LIST_SOURCE_CV } from 'constants/ManagerCandidate';

import { systemRecruit } from 'constants/index';
import { sendGet } from 'helpers/axios';
import { useQuery, useQueryClient } from 'react-query';
import { HTTP_OK } from 'constants/StatusCode';

export function useListBonus(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_BONUS, params], async () => {
    const response = await getListBonus(params);
    return response.data;
  });
  return { ...response, error, isLoading };
}

export function useListHrCharge(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_HR_CHARGE, params], async () => {
    let response = {};
    response = await getListHrCharge({ system_id: systemRecruit });
    return response.data;
  });
  return { ...response, error, isLoading };
}

export function useListLevel(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_LEVEL, params], async () => {
    let response = {};
    response = await getListLevel({ system_id: systemRecruit });
    return response.data;
  });
  return { ...response, error, isLoading };
}

export function useListPosition(params, enabled) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_POSITION, params],
    async () => {
      let response = {};
      response = await getListPosition({ system_id: systemRecruit });
      return response.data;
    },
    { enabled: enabled !== undefined ? enabled : true },
  );
  return { ...response, error, isLoading };
}

export function useListSkill(params, enabled) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_SKILL, params],
    async () => {
      let response = {};
      response = await getListSkill({ system_id: systemRecruit, ...params });
      return response.data;
    },
    { enabled: enabled !== undefined ? enabled : true },
  );
  return { ...response, error, isLoading };
}

export function useListPersonnel(params, enabled) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_Personnel, params],
    async () => {
      let response = {};
      response = await getListPersonnel({ system_id: systemRecruit, ...params });
      return response.data;
    },
    { enabled: enabled !== undefined ? enabled : true },
  );
  return { ...response, error, isLoading };
}

export function useListContractCategory(params, enabled) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_ContractCategory, params],
    async () => {
      let response = {};
      response = await getListContractCategory({ system_id: systemRecruit, ...params });
      return response.data;
    },
    { enabled: enabled !== undefined ? enabled : true },
  );
  return { ...response, error, isLoading };
}

export function useListSkillAddStandardIndex(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_SKILL_STANDARD_INDEX, params], async () => {
    const response = await getListSkill(params);
    return response.data;
  });
  return { ...response, error, isLoading };
}

export function useListStandardIndex(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_STANDARD_INDEX, params], async () => {
    const response = await getListStandardIndex(params);
    return response.data;
  });
  return { ...response, error, isLoading };
}

export function useListUsers(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_USERS, params], async () => {
    const response = await getListUser(params);
    return response.data;
  });
  return { ...response, error, isLoading };
}

export function useListHr(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_HR, params], async () => {
    const response = await sendGet(`/api/masterdata/hr/get-list`, params);
    return response.data;
  });
  return { ...response, error, isLoading };
}

export function useGetListSchool(params) {
  const queryClient = useQueryClient();
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([USE_GET_LIST_SCHOOL, params], async () => {
    let response = {};
    if (queryClient.getQueryData(USE_GET_LIST_SCHOOL)) {
      response = queryClient.getQueryData(USE_GET_LIST_SCHOOL);
    } else {
      response = await apiGetListSchool(params);
    }
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListSourceCV(params) {
  const queryClient = useQueryClient();
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([USE_GET_LIST_SOURCE_CV, params], async () => {
    let response = {};
    if (queryClient.getQueryData(USE_GET_LIST_SOURCE_CV)) {
      response = queryClient.getQueryData(USE_GET_LIST_SOURCE_CV);
    } else {
      response = await apiGetListSourceCV(params);
    }
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}
