/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import './styles.scss';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20, PER_PAGE_OPTION_20 } from 'constants/index.js';
import { useListSkill, useListPosition, useListLevel } from 'hook/useMasterData';
import * as Constants from 'constants/index.js';
import { managerTest } from 'constants/index';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { useGetListPostTest } from 'hook/useManagerGroupTest';
import SearchForm from './SearchForm';
import { FIELD_KEY, SEARCH_ITEM_KEY } from './SearchForm/SearchForm';

const initialFilter = {
  [FIELD_KEY.POSITION]: undefined,
  [FIELD_KEY.LEVEL]: undefined,
  [FIELD_KEY.SKILL]: undefined,
  page: DEFAULT_PAGE_MUI,
  limit: DEFAULT_LIMIT_20,
};

const ManagerTest = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQueryClient();
  const [filter, setFilter] = useState(initialFilter);

  const { data: listPostTest, isLoading, total_records } = useGetListPostTest({ ...filter, page: filter.page + 1 });

  const { data: positions } = useListPosition();
  const { data: levels } = useListLevel();
  const { data: skills } = useListSkill();
  const [dataPosition, setDataPosition] = useState([]);
  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);

  query.refetchQueries(managerTest.USE_LIST_GET_POST_TEST_DETAIL);

  useEffect(() => {
    if (levels) {
      const level = levels.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataLevel([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...level,
      ]);
    }
  }, [levels, t]);

  useEffect(() => {
    if (skills) {
      const skill = skills.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataSkill([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...skill,
      ]);
    }
  }, [skills, t]);

  useEffect(() => {
    if (positions) {
      const position = positions.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataPosition([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...position,
      ]);
    }
  }, [positions, t]);

  const positionsMapper = useMemo(() => {
    if (!positions) return {};
    return positions?.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item.name,
      };
    }, {});
  }, [positions]);

  const levelsMapper = useMemo(() => {
    if (!levels) return {};
    return levels?.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item.name,
      };
    }, {});
  }, [levels]);

  const skillsMapper = useMemo(() => {
    if (!skills) return {};
    return skills?.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item.name,
      };
    }, {});
  }, [skills]);

  const mapper = {
    [FIELD_KEY.POSITION]: positionsMapper,
    [FIELD_KEY.LEVEL]: levelsMapper,
    [FIELD_KEY.SKILL]: skillsMapper,
  };

  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${0}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({
      ...filter,
      page: Constants.DEFAULT_PAGE_MUI,
      limit: +event.target.value,
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const redirectDetail = (id) => {
    history.push(`/admin/manager-test/detail/${id}`);
  };

  const handleReset = () => {
    setFilter(initialFilter);
  };

  const configs = [
    {
      name: SEARCH_ITEM_KEY.CREATE_BUTTON,
      onClick: () => {
        history.push(`/admin/manager-test/create`);
      },
    },
    {
      name: SEARCH_ITEM_KEY.TOTAL,
      label: t('managerTest.totalTest'),
      value: total_records,
    },
    { name: SEARCH_ITEM_KEY.POSITION },
    { name: SEARCH_ITEM_KEY.LEVEL },
    { name: SEARCH_ITEM_KEY.SKILL },
    {
      name: SEARCH_ITEM_KEY.RESET_BUTTON,
      onClick: handleReset,
    },
  ];

  const renderTable = () => {
    return (
      <div className="table-border-show">
        <Paper>
          <TableContainer className="table-list">
            <Table aria-label="sticky table">
              <TableHead className="asset-header">
                <TableRow className="asset-header-row">
                  <TableCell>{t('managerTest.STT')}</TableCell>
                  <TableCell>{t('managerTest.title')}</TableCell>
                  <TableCell>{t('managerTest.position')}</TableCell>
                  <TableCell>{t('managerTest.skill')}</TableCell>
                  <TableCell>{t('managerTest.level')}</TableCell>
                  <TableCell>{t('managerTest.status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Loading addClass="mt-3 mb-3" />
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading &&
                  Array.isArray(listPostTest) &&
                  listPostTest.map((item, index) => {
                    return (
                      <TableRow key={index} onClick={() => redirectDetail(item.id)}>
                        <TableCell className="stt-table cursor">{getSTT(index + filter.page * filter.limit)}</TableCell>
                        <TableCell className="cell-table-title cursor">{item.title}</TableCell>
                        <TableCell className="cell-table cursor">
                          {mapper[FIELD_KEY.POSITION][item.position_id]}
                        </TableCell>
                        <TableCell className="cell-table cursor">{mapper[FIELD_KEY.SKILL][item.skill_id]}</TableCell>
                        <TableCell className="cell-table cursor">{mapper[FIELD_KEY.LEVEL][item.level_id]}</TableCell>
                        <TableCell className="cell-table cursor">{item.status ? 'Active' : 'Inactive'}</TableCell>
                      </TableRow>
                    );
                  })}

                {total_records === 0 && (
                  <tr>
                    <td colSpan={9} className="error-no-data">
                      {t('common.notData')}
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {total_records > 0 && (
            <TablePagination
              rowsPerPageOptions={PER_PAGE_OPTION_20}
              component="div"
              count={total_records || 0}
              rowsPerPage={filter?.limit}
              page={filter.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="table-pagination"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
            />
          )}
        </Paper>
      </div>
    );
  };

  return (
    <Grid container gap={2} className="manager-test">
      <Grid item className="w-100 title">
        {t('managerTest.managerTest')}
      </Grid>
      <Grid item flex={10}>
        {renderTable()}
      </Grid>
      <Grid item flex={2}>
        <SearchForm
          configs={configs}
          dataPosition={dataPosition}
          dataSkill={dataSkill}
          dataLevel={dataLevel}
          setDataPosition={setDataPosition}
          setDataSkill={setDataSkill}
          setDataLevel={setDataLevel}
          filter={filter}
          setFilter={setFilter}
        />
      </Grid>
    </Grid>
  );
};

export default ManagerTest;
