import { STATUS_CANCELED } from 'constants/manageJob';
import { STATUS_PROCESSING } from 'constants/manageJob';
import { STATUS_COMPLETED } from 'constants/manageJob';

export const getClassBox = (status) => {
  if (status === STATUS_PROCESSING) return 'job-blue';
  if (status === STATUS_COMPLETED) return 'job-green';
  if (status === STATUS_CANCELED) return 'job-canceled';
  return 'job-deadline';
};

export const getTextStatus = (t, status) => {
  if (status === STATUS_PROCESSING) return t('common.statusProcessing');
  else if (status === STATUS_COMPLETED) return t('common.statusCompleted');
  else if (status === STATUS_CANCELED) return t('common.statusCanceled');
  else return t('manageKPI.lateDeadline');
};
