import { Route, Switch } from 'react-router-dom';
import ManagerOrderLeader from './ManagerOrderLeader';

const Index = () => {
  return (
    <>
      <Switch>
        <Route path="/admin/manager-order" exact render={() => <ManagerOrderLeader />} />
        <Route path="/admin/manager-order/:orderId" exact render={() => <ManagerOrderLeader />} />
      </Switch>
    </>
  );
};

export default Index;
