import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { MenuItem, Grid } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { MdDelete } from 'react-icons/md';
import { IoIosAdd } from 'react-icons/io';
import { addOrUpdateLBonus } from 'api/masterData';
import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import InputField from 'components/Input/InputField.js';
import SelectComponent from 'components/Select/Select.js';
import { HTTP_OK } from 'constants/StatusCode.js';
import { MAX_LENGTH_10, MIN_SALARY } from 'constants/index.js';
import {
  USE_LIST_BONUS,
  MONTH_BONUS,
  STATUS_CANDIDATE,
  DEFAULT_TYPE_CANDIDATE,
  DEFAULT_MONTH_BONUS,
} from 'constants/MasterData';
import { getLevelName } from 'helpers/masterData';
import { showAlert } from 'helpers/modal';
import { useEffect } from 'react';

const CreateOrUpdateBonus = ({
  setShowModal,
  isShowModal,
  textModal,
  formAdd,
  queryClient,
  dataBonus,
  setShowAlertUpdate,
  setShowAlertCreate,
  setMessage,
  uDataLevel,
}) => {
  const { t } = useTranslation();

  const [stateButton, setStateButton] = useState(false);
  const [errorMonth, setErrorMonth] = useState([]); // array of index month

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        milestone: Yup.array().of(
          Yup.object().shape({
            bonus: Yup.mixed()
              .test('required', t('masterData.Candidate.pleaseFillBonus'), (val) => {
                return val?.length >= 1;
              })
              .test('minSalary', t('managerCandidate.minSalary'), (val) => {
                return val && +val > MIN_SALARY;
              }),
          }),
        ),
      }),
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    defaultValues: useMemo(() => formAdd, [formAdd, dataBonus]),
    mode: 'onChange',
  });
  const {
    fields: milestone,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'milestone',
  });

  const onclickAddTimeOrder = () => {
    append({ type: DEFAULT_TYPE_CANDIDATE, bonus: null, month_number: DEFAULT_MONTH_BONUS });
  };

  const handleKeyPress = (e) => {
    /^[^\d]+$/.test(e.key) && e.preventDefault();
  };

  const handleDeleteBonus = (index) => {
    if (milestone.length > 0) remove(index);
  };

  const { mutate: submit } = useMutation(
    (values) =>
      addOrUpdateLBonus({
        ...values,
        level_id: formAdd.level_id,
      }),
    {
      onSuccess: (response, variables) => {
        if (response?.data?.status === HTTP_OK) {
          variables.id === null
            ? setMessage(
                t('masterData.Candidate.createBonus') +
                  getLevelName(formAdd.level_id, uDataLevel) +
                  t('masterData.Candidate.Successfully'),
              )
            : setMessage(
                t('masterData.Candidate.updateBonus') +
                  getLevelName(formAdd.level_id, uDataLevel) +
                  t('masterData.Candidate.Successfully'),
              );
          variables.id === null ? setShowAlertCreate(true) : setShowAlertUpdate(true);
          queryClient.invalidateQueries(USE_LIST_BONUS);
          setShowModal(false);
          setStateButton(false);
        } else {
          if (response?.data?.message) {
            showAlert(response.data.message);
          } else {
            showAlert(t('messages.systemError'));
          }
        }
      },
      onError: (error) => {
        showAlert(t('messages.systemError'));
      },
    },
  );
  const restForm = () => {
    setValue('milestone', [
      {
        month_number: 1,
        type: 2,
        bonus: '',
      },
    ]);
  };

  const watchMilestone = useWatch({
    control,
    name: 'milestone',
  });

  useEffect(() => {
    const listMilestone = getValues('milestone');
    const listMonth = listMilestone?.map((milestone) => `${milestone.month_number}-${milestone.type}`);

    if (Array.from(new Set(listMonth))?.length === listMonth?.length) setErrorMonth([]);

    listMilestone?.forEach((milestone1, index1) => {
      let pattern = `${milestone1.month_number}-${milestone1.type}`;

      listMilestone?.forEach((milestone2, index2) => {
        if (
          index1 !== index2 &&
          milestone1.type === milestone2.type &&
          milestone1.month_number === milestone2.month_number &&
          !errorMonth?.includes(pattern)
        )
          setErrorMonth([...errorMonth, pattern]);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMilestone]);

  const RenderListBonus = useCallback(
    ({ errors, errorMonth }) => (
      <>
        {milestone.map((item, index) => {
          let month_number = getValues(`milestone.${index}.month_number`);
          let type = getValues(`milestone.${index}.type`);
          return (
            <Grid container className="add-time-bonus">
              <Grid container item xs="12" className="mt-text">
                <Grid item xs="10" className="text-title-modal no-padding">
                  {t('masterData.Candidate.timeOrder')} {index + 1}
                </Grid>
                {milestone.length > 1 ? (
                  <Grid item xs="2" className="no-padding">
                    <div className="delete-button" onClick={handleDeleteBonus.bind(this, index)}>
                      <MdDelete />
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs="2" className="no-padding">
                    <div className="delete-button" onClick={restForm}>
                      <MdDelete />
                    </div>
                  </Grid>
                )}
              </Grid>
              <Grid item xs="12" className="fieldset-group input-field mt-4 ">
                <Controller
                  control={control}
                  name={`milestone.${index}.bonus`}
                  maxLength={MAX_LENGTH_10}
                  valueDefault={item.bonus}
                  render={({ field }) => (
                    <InputField
                      maxLength={MAX_LENGTH_10}
                      field={field}
                      onKeyPress={handleKeyPress}
                      label={
                        <React.Fragment>
                          {t('masterData.Candidate.bonuses')}
                          {<sup>*</sup>}
                        </React.Fragment>
                      }
                      variant="outlined"
                      error={errors?.[`milestone`]?.[index]?.['bonus']?.message}
                      register={register}
                    />
                  )}
                />
              </Grid>
              <Grid container item xs="12">
                <Grid xs="3" className="text-title-modal no-padding outline-select-input">
                  <Controller
                    control={control}
                    name={`milestone.${index}.month_number`}
                    valueDefault={item.month_number}
                    render={({ field }) => (
                      <SelectComponent
                        field={field}
                        addClass="select flex-1"
                        isRequired={true}
                        error={
                          errorMonth.includes(`${month_number}-${type}`) &&
                          t('masterData.Candidate.conflictWithExistingTime')
                        }
                      >
                        {MONTH_BONUS.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </SelectComponent>
                    )}
                  />
                </Grid>
                <Grid xs="5">
                  <div className="text-content-modal mt-2 text-timeAfterOnboard">
                    {t('masterData.Candidate.timeAfter')}
                  </div>
                </Grid>
                <Grid xs="4" className="text-title-modal no-padding outline-select-input">
                  <Controller
                    control={control}
                    name={`milestone.${index}.type`}
                    valueDefault={item.type}
                    render={({ field }) => (
                      <SelectComponent field={field} addClass="select flex-1" isRequired={true}>
                        {STATUS_CANDIDATE.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </SelectComponent>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [milestone],
  );

  return (
    <>
      <ModalComponent
        toggle={() => setShowModal(!isShowModal)}
        isShowModal={isShowModal}
        title={textModal.title}
        classNameAdd="modal-add-bonus"
      >
        <form className="mt-3">
          <RenderListBonus milestone={milestone} errors={errors} errorMonth={errorMonth} />
          <Row>
            <div className="add-button" onClick={onclickAddTimeOrder}>
              <IoIosAdd />
            </div>
            <div className="text-content-modal">{t('masterData.Candidate.addTimeOrder')}</div>
          </Row>
          <Row className="mt-3">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModal(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100 btn-bg-yellow1"
                handleClick={handleSubmit(submit)}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </>
  );
};

export default CreateOrUpdateBonus;
