import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';

import AddIcon from '@mui/icons-material/Add';
import { Grid, Paper } from '@mui/material';

import styles from './styles.module.scss';
import stylesPopupAreYouSure from 'components/PopupAreYouSure/styles.module.scss';

import { errorSubmit } from 'helpers/index';
import { sendPost, sendDelete } from 'helpers/axios';
import { useGetListGroupQuestionDetail } from 'hook/useManagerGroupTest';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';
import { CODE_DATA_EXISTS, managerTest } from 'constants/index';
import { TEST_PERMISSION } from 'constants/permission';

import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Buttons/index';
import FormCreateQuestion from './FormCreateQuestion';
import Popup from 'components/popup/index';
import ListPreviewQuestion from './ListPreviewQuestion';
import BoxItemAddQuestion from './BoxItemAddQuestion';
import SelectedGroupIds from './SelectedGroupIds';
import Loading from 'components/Loading';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import PopupAreYouSure from 'components/PopupAreYouSure';
import EditLayout from 'components/PermissionPage/EditLayout';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';

import { getPositionName } from 'helpers/masterData';
import { getSkillName } from 'helpers/masterData';
import { getLevelName } from 'helpers/masterData';
import { uniqueArr } from 'helpers/index';

const PrepareData = () => {
  const { id } = useParams();
  const { data: questionDetail, isLoading } = useGetListGroupQuestionDetail({ id });

  return (
    <>
      {!isLoading ? (
        <DetailGroupTest questionDetail={questionDetail} isLoading={isLoading} id={id} />
      ) : (
        <div className="h-100vh d-flex align-items-center justify-content-center">
          <Loading />
        </div>
      )}
    </>
  );
};

const DetailGroupTest = ({ isLoading, questionDetail, id }) => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [pageEdit, setPageEdit] = useState(params.get('view') === 'edit');
  const detailView = Boolean(id);
  const { t } = useTranslation();

  const query = useQueryClient();
  const breadcrumb = useMemo(
    () => [
      { link: '/admin/master-data/manager-group-test', name: t('managerTest.managerGroupTestBr') },
      { link: `/admin/master-data/manager-group-test/detail/${id}`, name: t('managerTest.detailGroupTest') },
    ],
    [id, t],
  );
  if (pageEdit) {
    breadcrumb[2] = { link: window.location.pathname, name: t('managerTest.editQuestionTestBrc') };
  } else {
    breadcrumb.splice(2, 1);
  }
  const mapPosition = useRef({});
  const mapLevel = useRef({});
  const mapSkill = useRef({});
  const message = useRef('');
  const isDetelePostTest = useRef(false);
  const filesUpload = useRef(questionDetail.img_bonus || []);
  const tabFormData = useRef('checkbox');
  const [loading, setLoading] = useState(false);
  const [updateQuestionSuccess, setUpdateQuestionSuccess] = useState(false);
  const [updateQuestionError, setUpdateQuestionError] = useState(false);
  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });
  const { data: uDataLevel } = useListLevel({ key_word: '' });
  const [formData] = useState({
    id: questionDetail.id,
    position_id: questionDetail?.position_id,
    skill_id: questionDetail?.skill_id,
    level_id: questionDetail?.level_id,
    group_question: [{ 'content-answer': '' }],
    contentQuestion: '',
  });
  // const [list_question_ids, setListQuestionIds] = useState([]);
  const [listQuestionPreview, setListQuestionPreview] = useState(questionDetail?.list_questions || []);
  const [deleteQuestionSuccess, setDeleteQuestionSuccess] = useState(false);
  const [deleteQuestionError, setDeleteQuestionError] = useState(false);
  const [confirmDeleteQuestion, setConfirmDeleteQuestion] = useState(false);
  const [createQuestion, setCreateQuestion] = useState(true);
  const counterQUestion = useRef({ type: 'counter', value: 1 });
  const refUploadFile = useRef();
  const actionType = useRef(null);

  const [isEditQuestion, setEditQuestion] = useState(false);
  const [isAddQuestion, setAddQuestion] = useState(false);
  const [isShowBtnQuestion, setIsShowBtnQuestion] = useState(pageEdit);
  const [isDisableSave, setIsDisable] = useState(pageEdit);
  const [imgDelete, setImgDelete] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    resetField,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        position_id: Yup.mixed().test('required', t('managerTest.positionRequired'), (val) => {
          return actionType.current === 'submit' ? val && +val >= 0 : true;
        }),
        skill_id: Yup.mixed().test('required', t('managerTest.skillRequired'), (val) => {
          return actionType.current === 'submit' ? (newSkill?.length ? val && +val >= 0 : true) : true;
        }),
        level_id: Yup.mixed().test('required', t('managerTest.levelRequired'), (val) => {
          return actionType.current === 'submit' ? val && +val >= 0 : true;
        }),
        contentQuestion: Yup.string().test('minmax', t('managerTest.requiredContentQuestion'), (val) => {
          return actionType.current === 'submit' ? true : val.trim().length >= 10 && val.trim().length <= 200;
        }),
        group_question: Yup.array()
          .of(
            Yup.object().shape({
              'content-answer': Yup.string().test('min', t('managerTest.minContentAnswer'), (val) => {
                return tabFormData.current === 'guide' || actionType.current === 'submit'
                  ? true
                  : val && val.trim().length > 0;
              }),
            }),
          )
          .test('min', t('managerTest.minArrContentAnswer'), (val) => {
            return actionType.current === 'submit' || tabFormData.current === 'guide' ? true : val.length >= 2;
          }),
      }),
    ),
    defaultValues: formData,
    mode: 'onChange',
  });
  const {
    fields: group_question,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'group_question',
  });

  const positionId = useWatch({
    control,
    name: 'position_id',
  });

  const skillId = useWatch({
    control,
    name: 'skill_id',
  });

  const levelId = useWatch({
    control,
    name: 'level_id',
  });

  const newSkill = useMemo(
    () => uDataSkill?.filter((item) => item.position_id === positionId),
    [uDataSkill, positionId],
  );

  const onChangeDeleteImg = (index) => {
    setImgDelete((prev) => {
      const newData = structuredClone(prev);
      newData.push(index);
      return newData;
    });
  };

  useEffect(() => {
    if (uDataLevel) {
      uDataLevel.map((item) => {
        mapLevel.current[item.id] = item.name;
        return item;
      });
    }
  }, [uDataLevel]);

  useEffect(() => {
    if (uDataSkill) {
      uDataSkill.map((item) => {
        mapSkill.current[item.id] = item.name;
        return item;
      });
    }
  }, [uDataSkill]);

  useEffect(() => {
    if (uDataPosition) {
      uDataPosition.map((item) => {
        mapPosition.current[item.id] = item.name;
        return item;
      });
    }
  }, [uDataPosition]);

  const resetForm = () => {
    resetField('group_question');
    resetField('contentQuestion');
    refUploadFile.current.clearUploadFile();
    setError('group_question', null);
  };

  const onSubmit = async (data) => {
    if (!loading) {
      setLoading(true);
      if (actionType.current === 'add') {
        const group_question = getValues('group_question');
        const contentQuestion = getValues('contentQuestion');
        const tab = {
          guide: 1,
          checkbox: 2,
          radio: 3,
        };
        const payload = {
          type: tab[tabFormData.current],
          content: contentQuestion,
          img_bonus: [],
        };

        // add image old in list question
        if (counterQUestion.current.id) {
          listQuestionPreview.forEach((item) => {
            if (counterQUestion.current.id === item.id) {
              if (typeof item.img_bonus === 'string') {
                JSON.parse(item.img_bonus)?.forEach((pathImg) => {
                  payload.img_bonus.push(pathImg);
                });
              } else {
                item.img_bonus?.forEach((pathImg) => {
                  payload.img_bonus.push(pathImg);
                });
              }

              // check deleted
              if (imgDelete) {
                payload.img_bonus?.forEach((pathImg) => {
                  if (imgDelete.includes(pathImg)) {
                    const index = payload.img_bonus.indexOf(pathImg);
                    delete payload.img_bonus[index];
                  }
                });
              }
              payload.img_bonus = uniqueArr(payload.img_bonus);
            }
          });
        }

        payload.img_bonus = [...payload.img_bonus, ...filesUpload.current];

        if (tab[tabFormData.current] > 1) {
          payload.list_answer = group_question.map((item) => item['content-answer']);
        }
        if (counterQUestion.current.type === 'edit') {
          payload.id = counterQUestion.current.id;
        } else {
          payload.group_id = id;
        }
        const res = await sendPost(`/api/question/save`, payload);
        payload.list_answer = JSON.stringify(payload.list_answer);
        payload.id = res.data.id ? res.data.id : counterQUestion.current.id;
        if (payload.img_bonus) {
          payload.img_bonus = payload.img_bonus.map((itemImage) =>
            itemImage.replace('temp', itemImage.slice(itemImage.lastIndexOf('.') + 1)),
          );
          filesUpload.current = filesUpload.current.map((itemImage) =>
            itemImage.replace('temp', itemImage.slice(itemImage.lastIndexOf('.') + 1)),
          );
        }
        payload.img_bonus = JSON.stringify(payload.img_bonus || []);
        filesUpload.current = [];
        if (counterQUestion.current.type === 'edit') {
          const newData = structuredClone(listQuestionPreview);
          newData[counterQUestion.current.value - 1] = payload;
          setListQuestionPreview([...newData]);
          resetForm();
          counterQUestion.current = { value: listQuestionPreview.length + 1, type: 'counter' };
          setLoading(false);

          query.refetchQueries([managerTest.USE_LIST_MANAGER_DETAIL_QUESTION, { id: id }]);

          return setCreateQuestion(false);
        }
        resetForm();
        // setListQuestionIds((prev) => {
        //   const newData = structuredClone(prev);
        //   newData.push(res.data.id);
        //   return newData;
        // });
        payload.id = res.data.id;
        setListQuestionPreview((prev) => {
          const newData = structuredClone(prev);
          newData.push(payload);
          return newData;
        });
        setCreateQuestion(false);
      } else {
        const payload = {
          level_id: data.level_id,
          position_id: data.position_id,
          skill_id: data.skill_id <= 0 ? null : data.skill_id,
          id: +id,
        };
        // if (list_question_ids.length > 0) {
        //   payload.list_question_ids = list_question_ids;
        // }
        // if (isDetelePostTest.current) {
        //   payload.list_question_ids = listQuestionPreview.map((item) => item.id);
        // }
        const listQuestionId = [];
        listQuestionPreview.forEach((item) => {
          if (item.id) return listQuestionId.push(item.id);
        });
        payload.list_question_ids = listQuestionId;
        const res = await sendPost(`/api/group-question/save`, payload);
        if (errorSubmit(res?.data?.code) || res.data.status === 422) {
          if (res?.data?.code === CODE_DATA_EXISTS || res.data.status === 422) {
            message.current = t('managerTest.questionAlreadyExists');
          } else {
            message.current = t(res?.data?.code ? errorSubmit(res?.data?.code) : 'managerTest.somethingWenWrong');
          }
          setUpdateQuestionError(true);
        } else {
          setUpdateQuestionSuccess(true);
        }
      }
      setLoading(false);
      setIsShowBtnQuestion(true);
      filesUpload.current = [];
    }
  };

  const handleSubmitData = (data) => {
    if (actionType.current === 'submit' && (getValues('contentQuestion') || counterQUestion.value)) {
      confirmAlert({
        title: t('managerTest.confirmSaveManagerTest', {
          type_name: isEditQuestion ? t('common.update') : t('common.add'),
        }),
        overlayClassName: 'confirm-saveManager-test',
        buttons: [
          {
            label: t('common.back'),
            className: 'btn-alert-cancel text-left',
          },
          {
            label: t('managerTest.save'),
            className: 'btn-alert-ok',
            onClick: async () => {
              onSubmit(data);
            },
          },
        ],
      });
    } else {
      onSubmit(data);
    }
  };

  const handleUpdateQuestion = (index) => {
    if (group_question.length - 1 === index) {
      append({ content: '' });
    } else {
      remove(index);
    }
  };

  const FormCreateQuestionComp = useCallback(
    ({ errors }) => (
      <FormCreateQuestion
        Controller={Controller}
        t={t}
        handleUpdateQuestion={handleUpdateQuestion}
        group_question={group_question}
        control={control}
        errors={errors}
        tabFormData={tabFormData}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [group_question],
  );

  const handleAppendQuestion = async () => {
    actionType.current = 'add';
  };

  const handleDeleteQuestion = (index) => {
    isDetelePostTest.current = true;
    setListQuestionPreview((prev) => {
      const newData = structuredClone(prev);
      newData.splice(index, 1);
      return newData;
    });
    // setListQuestionIds((prev) => {
    //   const newData = structuredClone(prev);
    //   newData.splice(index, 1);
    //   return newData;
    // });
  };

  const handleDeleteGroupQuestion = useCallback(async () => {
    if (!loading) {
      setLoading(true);
      const res = await sendDelete(`/api/group-question/delete`, { id });
      if (res.status === 200) {
        setDeleteQuestionSuccess(true);
      } else {
        setDeleteQuestionError(false);
      }
      setLoading(false);
      setConfirmDeleteQuestion(false);
    }
  }, [loading, id]);

  const handleEditQuestion = useCallback(
    (item, index) => {
      setCreateQuestion(true);
      counterQUestion.current = { value: index, type: 'edit', id: item.id };
      tabFormData.current = item.type === 1 ? 'guide' : item.type === 2 ? 'checkbox' : 'radio';
      if (item.content?.length > 9) {
        clearErrors('contentQuestion');
      }
      setValue('contentQuestion', item.content);
      if (item.list_answer) {
        item.list_answer.forEach((value) => {
          if (value.trim().length > 0) return clearErrors('group_question');
        });
        setValue(
          'group_question',
          item.list_answer.map((item) => ({ 'content-answer': item })),
        );
      } else {
        setValue('group_question', []);
      }
      if (item.img_bonus.length) refUploadFile.current.updateListImgUpload(item.img_bonus);
      else refUploadFile.current.updateListImgUpload([]);
      refUploadFile.current.setErrorFile('');
    },
    [setValue, clearErrors],
  );

  const handleConfirmDeleteQuestion = useCallback(() => {
    if (pageEdit) {
      setPageEdit(false);
      setIsShowBtnQuestion(false);
      setEditQuestion(false);
      setAddQuestion(false);
      history.push(window.location.pathname);
      setListQuestionPreview(questionDetail?.list_questions);
    } else {
      setConfirmDeleteQuestion(true);
    }
  }, [history, pageEdit, questionDetail]);

  const handleDeleteQuestionSuccess = useCallback(() => {
    if (!loading) {
      setLoading(true);
      query.refetchQueries(managerTest.USE_LIST_MANAGER_POST_TEST);
      query.refetchQueries(managerTest.USE_LIST_MANAGER_DETAIL_QUESTION);
      setDeleteQuestionSuccess(false);
      history.push(breadcrumb[0].link);
      setLoading(false);
    }
  }, [breadcrumb, history, query, loading]);

  const handleRedirectEditQuestion = useCallback(() => {
    if (pageEdit) {
      actionType.current = 'submit';
      return;
    }
    history.push(breadcrumb[1].link + '?view=edit');
    setPageEdit(true);
    setIsShowBtnQuestion(true);
  }, [history, breadcrumb, pageEdit]);

  const handleChangeUrl = (url) => {
    setPageEdit(false);
    setIsShowBtnQuestion(false);
    setCreateQuestion(false);
    history.push(url);
  };

  const handleCreateQuestion = () => {
    counterQUestion.current = { type: 'counter', value: 1 };
    setCreateQuestion(true);
    setEditQuestion(false);
    setAddQuestion(true);
    setIsShowBtnQuestion(false);
    resetForm();
    refUploadFile.current.setErrorFile('');
  };

  const handleSetEditQuestion = () => {
    setEditQuestion(true);
    setAddQuestion(false);
    setIsShowBtnQuestion(true);
  };

  useEffect(() => {
    if (!!listQuestionPreview.length && positionId && levelId) {
      if (newSkill?.length && skillId) setIsDisable(false);
      if (!newSkill?.length) setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [listQuestionPreview, positionId, newSkill, skillId, levelId]);

  return (
    <Grid container className="content manager-group-test manager-test pb-5">
      <AlertAfterSubmit isShowAlert={deleteQuestionSuccess} toggle={handleDeleteQuestionSuccess}>
        <p>{t('managerTest.deleteQuestionSuccess')}</p>
      </AlertAfterSubmit>
      <PopupAreYouSure
        active={confirmDeleteQuestion}
        handleCancel={() => setConfirmDeleteQuestion(false)}
        footer={
          <>
            <button className={stylesPopupAreYouSure.btnCancel} onClick={() => setConfirmDeleteQuestion(false)}>
              {t('common.cancel')}
            </button>
            <button className={stylesPopupAreYouSure.btnOk} onClick={handleDeleteGroupQuestion}>
              {t('common.ok')}
            </button>
          </>
        }
        messages={t('managerTest.confirmDeleteQuestionTest')}
      />
      <Popup
        active={deleteQuestionError}
        handleCancel={() => setDeleteQuestionError(false)}
        footer={
          <>
            <button
              className="btn-transparent color-yellow fs-16"
              onClick={() => {
                // query.refetchQueries(managerJob.USE_LIST_APPLIES_AVAILABLE);
                setDeleteQuestionError(false);
              }}
            >
              {t('managerTest.done')}
            </button>
          </>
        }
        messages={t('managerTest.somethingWenWrong')}
      />
      <Popup
        active={updateQuestionError}
        handleCancel={() => setUpdateQuestionError(false)}
        footer={
          <>
            <button
              className="btn-transparent color-yellow fs-16"
              onClick={() => {
                setUpdateQuestionError(false);
              }}
            >
              {t('managerTest.done')}
            </button>
          </>
        }
        messages={message.current}
      />
      <Popup
        active={updateQuestionSuccess}
        handleCancel={() => setUpdateQuestionSuccess(true)}
        footer={
          <>
            <button
              className={`btn-transparent color-yellow ${stylesPopupAreYouSure.btnOk}`}
              onClick={() => {
                query.refetchQueries(managerTest.USE_LIST_MANAGER_DETAIL_QUESTION);
                setUpdateQuestionSuccess(false);
                history.push(window.location.pathname);
                setPageEdit(false);
                setIsShowBtnQuestion(false);
                setCreateQuestion(false);
              }}
            >
              {t('managerTest.done')}
            </button>
          </>
        }
        messages={t('managerTest.updateQuestionSuccess')}
      />

      {/* <AlertAfterSubmit
        isShowAlert={updateQuestionSuccess}
        toggle={() => setUpdateQuestionSuccess(false)}
        link={window.location.pathname}
      >
        <p>{t('managerTest.updateQuestionSuccess')}</p> */}

      {/* </AlertAfterSubmit> */}
      <Breadcrumb data={breadcrumb} onChangeUrl={handleChangeUrl} />
      <Grid item xs={12} className="content-component">
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Grid item xs={12} className="d-flex align-items-center justify-content-between title-outside">
            <h3 className={styles.titlePage}>
              {pageEdit ? t('managerTest.editGroupTest') : t('managerTest.detailGroupTest')}
            </h3>
          </Grid>
          <Paper className="p-20">
            <Grid item xs={12} className="d-flex align-items-center justify-content-between">
              <h4 className={styles.infoQuestionTest}>{t('managerTest.infoQuestionTest')}</h4>
              <div className="fnc-btn flex-button">
                <DeleteLayout permissionName={TEST_PERMISSION}>
                  <Button
                    text={t(`managerTest.${pageEdit ? 'cancel' : 'deleteQuestion'}`)}
                    addClass="btn-bg-yellow2 mr-3 h-36 width-92"
                    handleClick={handleConfirmDeleteQuestion}
                    hasDisabled={isLoading}
                  />
                </DeleteLayout>
                <EditLayout permissionName={TEST_PERMISSION}>
                  <Button
                    text={t(`managerTest.${pageEdit ? 'save' : 'editQuestion'}`)}
                    addClass="btn-bg-yellow1 h-36 width-92 btn-cancel-manage-test"
                    hasDisabled={isLoading || isDisableSave}
                    type={pageEdit ? 'submit' : 'button'}
                    handleClick={handleRedirectEditQuestion}
                  />
                </EditLayout>
              </div>
            </Grid>
            <div className="divider mt-15 ml-mr-20" />
            {pageEdit ? (
              <SelectedGroupIds
                t={t}
                Controller={Controller}
                control={control}
                formData={formData}
                errors={errors}
                uDataPosition={uDataPosition}
                setValue={setValue}
                mapPosition={mapPosition}
                newSkill={newSkill}
                uDataLevel={uDataLevel}
                mapLevel={mapLevel}
              />
            ) : (
              <div className="mt-20">
                <div className={styles.infoGeneral}>{t('masterData.StandardIndex.infomation')}</div>
                <Grid item xs={12} className="d-flex align-items-center">
                  <Grid item xs={3}>
                    <span className={styles.titleItem}>{t('managerTest.position')}</span>
                  </Grid>
                  <span className={styles.infoItem}>{getPositionName(questionDetail?.position_id, uDataPosition)}</span>
                </Grid>
                <Grid item xs={12} className="d-flex align-items-center">
                  <Grid item xs={3}>
                    <span className={styles.titleItem}>{t('managerTest.skill')}</span>
                  </Grid>
                  <span className={styles.infoItem}>{getSkillName(questionDetail?.skill_id, uDataSkill)}</span>
                </Grid>
                <Grid item xs={12} className="d-flex align-items-center">
                  <Grid item xs={3}>
                    <span className={styles.titleItem}>{t('masterData.StandardIndex.levelRecruit')}</span>
                  </Grid>
                  <span className={styles.infoItem}>{getLevelName(questionDetail?.level_id, uDataLevel)}</span>
                </Grid>
              </div>
            )}
            <Grid item xs={12} className="mt-15">
              <span className={styles.infoGeneral}>{t('managerTest.infoTest')}</span>
              <ListPreviewQuestion
                t={t}
                listQuestionPreview={listQuestionPreview.map((item) => ({
                  ...item,
                  img_bonus: typeof item.img_bonus === 'string' ? JSON.parse(item.img_bonus) : item.img_bonus,
                  list_answer: typeof item.list_answer === 'string' ? JSON.parse(item.list_answer) : item.list_answer,
                }))}
                handleDeleteQuestion={handleDeleteQuestion}
                handleEditQuestion={handleEditQuestion}
                enableActionQuestion={pageEdit}
                setEditQuestion={handleSetEditQuestion}
                viewEdit={pageEdit}
                pageEdit={pageEdit}
                filesUpload={filesUpload}
              />
            </Grid>

            <div
              className={`${styles.boxQuestion} ${
                isEditQuestion || isAddQuestion ? styles.showQuestion : styles.hiddenQuestion
              } `}
            >
              <BoxItemAddQuestion
                Controller={Controller}
                createQuestion={createQuestion}
                counterQUestion={counterQUestion}
                control={control}
                formData={formData}
                errors={errors}
                loading={loading}
                handleAppendQuestion={handleAppendQuestion}
                t={t}
                filesUpload={filesUpload}
                refUploadFile={refUploadFile}
                setLoading={setLoading}
                FormCreateQuestionComp={FormCreateQuestionComp}
                nextQuestion={listQuestionPreview.length + 1}
                isEditQuestion={isEditQuestion}
                handleCreateQuestion={handleCreateQuestion}
                onChangeDeleteImg={onChangeDeleteImg}
              />
            </div>
            <div className="d-inline-block">
              {isShowBtnQuestion ? (
                <div
                  className="append-question d-flex align-items-center position-relative mt-20"
                  onClick={handleCreateQuestion}
                >
                  <button type="button" className="create-question position-absolute z-9" />
                  <AddIcon />
                  <span>{t('managerTest.addQuestion')}</span>
                </div>
              ) : (
                (pageEdit || !detailView) && (
                  <div className="disabled-append-question d-flex align-items-center position-relative mt-20">
                    <button type="button" className="create-question position-absolute z-9" />
                    <AddIcon />
                    <span>{t('managerTest.addQuestion')}</span>
                  </div>
                )
              )}
            </div>
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
};

export default PrepareData;
