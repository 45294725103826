import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { checkInvalidDateByDiagonalLine } from 'helpers/formatDate';
import { MAX_LENGTH_3, MAX_LENGTH_100 } from 'constants/index.js';

export const YupValidate = (sourceCvText) => {
  const { t } = useTranslation();
  const regexPhone = /^[0-9]{10,11}$/;
  const regexFullName =
    /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý ]+$/;
  const regexEmail = /^[a-z0-9][a-z0-9_.]{3,64}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/g;

  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .required(t('messages.pleaseEnterThreeToTwentyfiveCharacters'))
      .min(MAX_LENGTH_3, t('messages.pleaseEnterThreeToTwentyfiveCharacters'))
      .matches(regexFullName, t('messages.pleaseOnlyEnterTextNotNumberAndCharacters'))
      .trim(),
    birthday: Yup.string().test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) =>
      value ? checkInvalidDateByDiagonalLine(value) : true,
    ),
    mobile: Yup.string()
      .required(t('messages.pleaseEnterPhoneNumber'))
      .test('valid', t('messages.phoneNumberIsNotValid'), (val) => {
        return !!new RegExp(regexPhone).test(val?.trim());
      }),
    email: Yup.string()
      .required(t('messages.emailRequired'))
      .test('valid', t('messages.emailNumberIsNotValid'), (val) => {
        return !!new RegExp(regexEmail).test(val?.toLowerCase());
      })
      .trim(),
    school: Yup.string(),
    candidate_languages:  Yup.array().of(
      Yup.object().shape({
        language_id: Yup.number(),
        level: Yup.string().trim().max(MAX_LENGTH_100, t('common.pleaseEnterLessThanHundredCharacters'))
      })
    ).test('unique', t('messages.languageUnique'), function (value) {
      const ids = value.map(item => item.language_id).filter(item => item !== undefined);
      const uniqueIds = new Set(ids);
      return ids.length === uniqueIds.size;
    }),
    note: Yup.string().trim(),
    position_id: Yup.string().required(t('messages.positionRequired')),
    skill_id: Yup.string()
      .nullable()
      .when(['position_id', 'is_skill'], {
        is: (idPosition, isSkill) => idPosition > 0 && isSkill,
        then: Yup.string().required(t('messages.skillRequired')).nullable(),
      }),
    experience: Yup.string().required(t('messages.experienceRequired')),
    source_cv: Yup.string().required(t('messages.sourceCVRequired')),
    apply_date: Yup.string()
      .required(t('messages.applyDateRequired'))
      .test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) => checkInvalidDateByDiagonalLine(value)),
    presenter_id: Yup.string().when('source_cv', {
      is: (sourceCv) => Number(sourceCv) === 1,
      then: Yup.string().required(t('messages.presenterRequired')),
    }),
    source_cv_text: sourceCvText && Yup.string().required(t('common.pleaseEnter1To50Characters')),
    link_cv_online: Yup.string().when('is_file_upload', {
      is: (isFileUpload) => !isFileUpload,
      then: Yup.string().required(t('messages.linkCVRequired')).trim(),
    }),
    level_id: Yup.string().required(t('messages.levelRequired')),
  });
  return validationSchema;
};

export default YupValidate;
