import React from 'react';
import { useTranslation } from 'react-i18next';

import { Line } from 'react-chartjs-2';

import styles from './styles.module.scss';
import iconHeader from 'assets/img/icons/dashboard/chartUp.svg';
import { useGetStatisticsByLevel } from 'hook/useDashboard';
import { useListLevel } from 'hook/useMasterData';
import { getName } from 'helpers/index';

import LayoutBlock from '../components/layoutBlock';
import DateYear from 'components/DateTime/Year';

const StatisticsCandidateByLevel = ({ yearFilter, setYearFilter }) => {
  const { t } = useTranslation();

  const { data: listLevel } = useListLevel();
  const { data: dataStatisticsLevel } = useGetStatisticsByLevel({ year: yearFilter.getFullYear() });

  function getGradient(ctx) {
    let gradient = ctx.createLinearGradient(0, 100, 0, 600);
    gradient.addColorStop(0, 'rgba(131, 207, 255, 0.4)');
    gradient.addColorStop(0.5, 'rgba(131, 207, 255, 0.07)');
    return gradient;
  }

  const formatFieldKeyList = (list) => {
    if (!list || !listLevel) return [];

    // xóa phần thừa
    const listLevelId = listLevel.map((level) => level.id);
    const newList = Object.values(list)
      .filter((item) => listLevelId.includes(item.level_id))
      .map((item) => {
        return { ...item, name: getName(listLevel, item.level_id), value: item.data };
      });

    // thêm phần thiếu
    const newListLevelId = newList.map((item) => item.level_id);
    listLevel.map((level) => {
      if (!newListLevelId.includes(level.id)) {
        newList.push({ level_id: level.id, name: level.name, value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
      }
      return level;
    });
    return newList;
  };

  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; //months
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'name',
        pointBackgroundColor: '#2D99FF',
        data: dataStatisticsLevel?.candidate_total,
        listLevel: formatFieldKeyList(dataStatisticsLevel?.candidate_level),
        backgroundColor: (context) => getGradient(context.chart?.ctx),
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // fix aspect ratio when responsive
    legend: { display: false },
    tooltips: {
      callbacks: {
        title: function (tooltipItem) {
          const label = t('common.total');
          const total = tooltipItem.at(0).value;
          return `${label}: ${total}`;
        },
        label: function (tooltipItem, dataChart) {
          const listTotalPerLevel = [];
          dataChart.datasets[tooltipItem.datasetIndex].listLevel.forEach((item) => {
            const label = item.name;
            const value = item.value[tooltipItem.index];
            let percentTotal = ((value * 100) / tooltipItem.yLabel).toFixed(0);
            if (!tooltipItem.yLabel) percentTotal = 0;
            listTotalPerLevel.push(`${label}: ${value} (${percentTotal}%)`);
          });
          return listTotalPerLevel;
        },
      },
      backgroundColor: '#F3F7FB',
      borderColor: '#F3F7FB',
      borderWidth: 1,
      titleFontColor: '#223354',
      titleFontStyle: 'bold',
      displayColors: false,
      bodyFontColor: '#223354',
    },
    scales: {
      yAxes: [
        {
          gridLines: { display: false },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: { borderDash: [3, 2] },
        },
      ],
    },
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 4,
        borderColor: '#2D99FF',
      },
      point: {
        radius: 5,
        borderWidth: 1,
        borderColor: '#FFFFFF',
        hoverRadius: 6,
        hoverBackgroundColor: '#FFFFFF',
      },
    },
  };

  return (
    <LayoutBlock title={t('dashboard.statisticsCandidateByLevel')} icon={iconHeader}>
      <div className={styles.yearPickerContainer}>
        <div className={styles.yearPicker}>
          <DateYear valueDefault={yearFilter} sizeDate="small" onChangeHandle={(newYear) => setYearFilter(newYear)} />
        </div>
      </div>
      <div className={styles.lineChartContainer}>
        <div className={styles.bodyChart}>
          <span className={styles.labelYAxis}>{t('dashboard.numberOfCandidate')}</span>
          <Line data={data} options={options} />
        </div>
      </div>
    </LayoutBlock>
  );
};

export default StatisticsCandidateByLevel;
