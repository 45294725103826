import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

import { Grid, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Loading from 'components/Loading';
import { useGetListDivision } from 'hook/useDivision';
import { getLevelName, getPositionName, getSkillName, getDivisionName } from 'helpers/masterData';
import { getNameStatus, getColorStatus } from 'helpers/index';
import { displayLabelTablePagination } from 'helpers/table';
import { customDate } from 'helpers/formatDate';

import { ThemContext } from 'layouts/Admin';
import * as Constants from 'constants/index.js';
import { LIST_STATUS } from 'constants/ManagerCandidate';
import { ZERO } from 'constants/MasterData';
import DetailStatusCard from 'components/DetailStatusCard';

const ListCandidateOffer = ({
  type,
  dataCandidates,
  isLoadingDataStandardIndex,
  dataPosition,
  dataLevel,
  dataSkill,
  handleChangePage,
  handleChangeRowsPerPage,
  filter
}) => {
  const { t } = useTranslation();

  const { isAssigner } = useContext(ThemContext);
  const { data: uListDivision } = useGetListDivision();

  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${ZERO}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  const formatFieldKeyList = (list, idDiv) => {
    return list.map((item) => {
      const { user_info, status } = item;
      return {
        status_id: status,
        status: getNameStatus(LIST_STATUS, status, idDiv, uListDivision),
        name: user_info?.name,
        avatar: user_info?.avatar,
      };
    });
  };

  return (
    <>
      <Grid container className="pt-0 mt-4 statistics-candidate-offer">
        <div className="table-border-show">
          <Paper>
            <TableContainer className="scroll-custom table-container">
              <Table aria-label="sticky table" className="table-scroll">
                <TableHead className="asset-header">
                  <TableRow className="asset-header-row headTable">
                    <TableCell className="stt">{t('common.STT')}</TableCell>
                    <TableCell className="field-cell-w150">{t('managerCandidate.name')}</TableCell>
                    <TableCell>{t('managerCandidate.email')}</TableCell>
                    <TableCell>{t('masterData.StandardIndex.position')}</TableCell>
                    <TableCell>{t('managerCandidate.HRCharge')}</TableCell>
                    <TableCell>{t('managerCandidate.levelOffer')}</TableCell>
                    <TableCell>{t('managerCandidate.division')}</TableCell>
                    <TableCell>{t('managerCandidate.status')}</TableCell>
                    <TableCell>{t('masterData.StandardIndex.skill')}</TableCell>
                    {/* <TableCell>{t('masterData.StandardIndex.levelApply')}</TableCell>
                    <TableCell>{t('managerCandidate.applyDate')}</TableCell> */}
                    <TableCell>{t('managerCandidate.confirmOfferDate')}</TableCell>
                    {type !== 'leader' && <TableCell>{t('managerCandidate.divisionOrder')}</TableCell>}
                    <TableCell>{t('managerCandidate.refusalReason')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingDataStandardIndex && (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Loading addClass="mt-3 mb-3" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!isLoadingDataStandardIndex &&
                    dataCandidates?.data &&
                    dataCandidates?.data.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className="table-content id-standard stt">
                            {getSTT(index + filter.page * filter.per_page)}
                          </TableCell>
                          <TableCell className="table-content id-standard field-cell-w150">
                            <p className="name">{item?.full_name}</p>
                          </TableCell>
                          <TableCell className="table-content id-standard field-cell-w150">
                            <p className="email">{item?.email}</p>
                          </TableCell>
                          <TableCell className="table-content field-cell-w90">
                            {item.position_id
                              ? getPositionName(item?.position_id, dataPosition)
                              : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content field-cell-w150">
                            <p>{item?.user_info?.name}</p>
                          </TableCell>
                          <TableCell className="table-content field-cell-w150">
                            {item.level_assess_id
                              ? getLevelName(item?.level_assess_id, dataLevel)
                              : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content field-cell-w100">
                            <span>{getDivisionName(item?.division_id, uListDivision)}</span>
                          </TableCell>

                          <TableCell
                            className={`${getColorStatus(LIST_STATUS, item?.status)} table-content field-cell-w150`}
                          >
                            <Tooltip
                              title={
                                (isAssigner || type === 'hr') && (
                                  <DetailStatusCard
                                    list={formatFieldKeyList(item.list_action_leader, item.division_id)}
                                    fieldKey={'status'}
                                    fieldKeyClass={'status_id'}
                                  />
                                )
                              }
                              arrow={isAssigner || type === 'hr'}
                            >
                              <span>{getNameStatus(LIST_STATUS, item?.status, item.division_id, uListDivision)}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell className="table-content field-cell-w90">
                            {item.skill_id ? getSkillName(item?.skill_id, dataSkill) : t('common.notDataContract')}
                          </TableCell>
                          {/* <TableCell className="table-content field-cell-w90">
                            {item.level_id ? getLevelName(item?.level_id, dataLevel) : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content field-cell-w100">
                            {customDate(item.interview_date, 'DD/MM/YYYY')}
                          </TableCell> */}
                          <TableCell className="table-content field-cell-w100">
                            {customDate(item.date_waiting_res_offer, 'DD/MM/YYYY')}
                          </TableCell>
                          {type !== 'leader' && (
                            <>
                              {item.division_order ? (
                                <Tooltip
                                  title={
                                    <div className="tooltip-division-order">
                                      {item.division_order?.map((val, index) => (
                                        <div className="text-division" key={index}>
                                          {getDivisionName(val, uListDivision)}
                                        </div>
                                      ))}
                                    </div>
                                  }
                                  arrow
                                  placement="bottom-start"
                                  PopperProps={{
                                    sx: {
                                      '& 	.MuiTooltip-tooltipArrow': {
                                        top: '-30px !important',
                                      },
                                    },
                                  }}
                                >
                                  <TableCell className="table-content text-division field-cell-w90">
                                    <span>
                                      {item.division_order
                                        ?.map((val) => getDivisionName(val, uListDivision))
                                        .join(', ')}
                                    </span>
                                  </TableCell>
                                </Tooltip>
                              ) : (
                                <TableCell className="field-cell-w90" />
                              )}
                            </>
                          )}
                          {
                            <>
                              {item.refusal_reason ? (
                                <Tooltip
                                  title={<div className="tooltip-division-order">{item.refusal_reason}</div>}
                                  arrow
                                  placement="bottom-start"
                                  PopperProps={{
                                    sx: {
                                      '& 	.MuiTooltip-tooltipArrow': {
                                        top: '-30px !important',
                                      },
                                    },
                                  }}
                                >
                                  <TableCell className="table-content text-division field-cell-w150">
                                    <span>{item.refusal_reason}</span>
                                  </TableCell>
                                </Tooltip>
                              ) : (
                                <TableCell className="field-cell-w90" />
                              )}
                            </>
                          }
                        </TableRow>
                      );
                    })}
                  {dataCandidates?.total === ZERO && (
                    <tr>
                      <td colSpan={10} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {dataCandidates?.total > ZERO && (
              <TablePagination
                rowsPerPageOptions={Constants.PER_PAGE_OPTION_20}
                component="div"
                count={dataCandidates?.total}
                rowsPerPage={filter?.per_page}
                page={filter.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
                labelDisplayedRows={displayLabelTablePagination}
              />
            )}
          </Paper>
        </div>
      </Grid>
    </>
  );
};

export default ListCandidateOffer;
