import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { ModalFooter } from 'reactstrap';
import { useMutation, useQueryClient } from 'react-query';
import classNames from 'classnames';

import { MenuItem } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import styles from './styles.module.scss';

import ModalComponent from 'components/Modal';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import SelectField from 'components/ReactForm/SelectField';

import { sendPut } from 'helpers/axios';
import * as Constants from 'constants/index.js';
import { USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL } from 'constants/ManagerCandidate';

const FORM_FIELDS = {
  reason: 'note',
};

const ModalEvaluateAllotmentDivision = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { title, label, isShowModal, setIsShowModal, applyId, userId } = props;
  const DEFAULT_VALUES = {
    [FORM_FIELDS.reason]: '',
  };
  const TYPEOFREASONREFUSAL = [
    { id: 1, name: t('managerCandidate.notPassProbation') },
    { id: 2, name: t('managerCandidate.takeBreakFromWork') },
  ];
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(Yup.object().shape({})),
    defaultValues: DEFAULT_VALUES,
    mode: 'onSubmit',
  });

  const handleCloseModal = () => {
    setIsShowModal(false);
    // returnAfterClose();
  };

  const { mutate: submitRefuse } = useMutation(
    async (values) => {
      return sendPut(`/api/candidate/apply/${applyId}/assess-probationary`, {
        refusal_reason: values[FORM_FIELDS.reason],
        user_id: userId,
        reject_type: getValues('division_id'),
      });
    },
    {
      onSuccess: async () => {
        queryClient.refetchQueries(USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL);
        setIsShowModal(false);
        reset();
      },
      onError: () => {
        // setError(FORM_FIELDS.reason, { type: 'custom', message: t('common.anUnknownError') });
      },
    },
  );

  return (
    <ModalComponent
      title={title}
      isShowModal={isShowModal}
      toggle={handleCloseModal}
      renderModalFooter={() => (
        <ModalFooter className={styles.modalFooter}>
          <button type="button" className={classNames(styles.btn, styles.btn__cancel)} onClick={handleCloseModal}>
            {t('modalOrder.cancel')}
          </button>
          <button
            type="button"
            className={classNames(styles.btn, styles.btn__action)}
            onClick={handleSubmit(submitRefuse)}
          >
            {t('managerCandidate.plus')}
          </button>
        </ModalFooter>
      )}
    >
      <form>
        <SelectField
          name="division_id"
          label={t('modalOrder.typeOfReasonForRefusal')}
          control={control}
          required
          setValue={setValue}
          useWatch={useWatch}
          register={register}
          isShowModal={isShowModal}
          isRequired
        >
          {TYPEOFREASONREFUSAL?.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </SelectField>
        <CustomInputYup
          name={FORM_FIELDS.reason}
          label={t('managerCandidate.reason')}
          placeholder={label}
          maxLength={Constants.MAX_LENGTH_500}
          control={control}
          useWatch={useWatch}
          register={register}
          errors={errors}
          defaultValue={DEFAULT_VALUES[FORM_FIELDS.note]}
          required
          multiline
          rows={7}
        />
      </form>
    </ModalComponent>
  );
};

export default ModalEvaluateAllotmentDivision;
