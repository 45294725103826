import React from 'react';

import { Grid } from '@mui/material';

import { MdDelete } from 'react-icons/md';

import styles from './styles.module.scss';
import ButtonComponent from 'components/Buttons';
import InputField from 'components/Input/InputField';
import AttachImage from './AttachImage';
import { MAX_LENGTH_200 } from 'constants/index';

const BoxItemAddQuestion = ({
  Controller,
  createQuestion,
  counterQUestion,
  control,
  formData,
  errors,
  loading,
  handleAppendQuestion,
  t,
  filesUpload,
  refUploadFile,
  setLoading,
  FormCreateQuestionComp,
  nextQuestion,
  isEditQuestion,
  handleCreateQuestion,
  onChangeDeleteImg,
}) => {
  return (
    <Grid item xs={12} className={`box-item-add-question ${createQuestion ? '' : 'd-none'}`}>
      <div className={styles.indexCreateQuestion}>
        <div>
          {t('managerTest.question')} {isEditQuestion ? counterQUestion.current.value : nextQuestion}
        </div>
        <div className="edit-delete-button color-button" onClick={handleCreateQuestion}>
          <MdDelete />
        </div>
      </div>
      <div>
        <Controller
          control={control}
          name="contentQuestion"
          valueDefault={formData.contentQuestion}
          maxLength={MAX_LENGTH_200}
          render={({ field }) => (
            <InputField
              field={field}
              maxLength={MAX_LENGTH_200}
              label={t('managerTest.contentQuestion')}
              variant="outlined"
              isRequired={true}
              onHandleInput={(e) => {}}
              error={errors?.['contentQuestion']?.message}
            />
          )}
        />
      </div>

      <AttachImage
        t={t}
        filesUpload={filesUpload}
        setLoading={setLoading}
        ref={refUploadFile}
        onChangeDelete={onChangeDeleteImg}
      />
      <div className="answer">
        <span className={styles.titleAnswer}>{t('managerTest.answer')}</span>
      </div>
      <FormCreateQuestionComp errors={errors} />

      <ButtonComponent
        text={isEditQuestion ? t('common.update') : t('common.add')}
        addClass="btn-bg-yellow1 btn-submit-add-question ml-0 mt-25-i"
        hasDisabled={loading}
        isLoading={loading}
        type="submit"
        handleClick={handleAppendQuestion}
      />
    </Grid>
  );
};

export default BoxItemAddQuestion;
