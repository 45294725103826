import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import { Grid, Button, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DatePicker } from 'antd';

import CalendarIcon from '@mui/icons-material/Event';
import styles from './styles.module.scss';
import stylesTable from 'styles/table.module.scss';
import { customDate } from 'helpers/formatDate';
import { LOADING_SM } from 'constants/index';
import { LIST_STATUS_KPI, USE_LIST_KPI, USE_LIST_HR_NO_KPI, TAB_OFFICIAL, TAB_OTHERS } from 'constants/kpi';
import { LIST_STATUS } from 'constants/ManagerCandidate';
import { getColorStatus, getName } from 'helpers/index';
import { getSTT } from 'helpers/table';
import { useGetDetailKPI } from 'hook/useManagerKPI';
import { useGetListDivision } from 'hook/useDivision';
import { useListPosition, useListLevel, useListSkill } from 'hook/useMasterData';
import { apiDeleteKPI } from 'api/managerKPI';

import ModalComponent from 'components/Modal';
import PopupAreYouSure from 'components/PopupAreYouSure';
import Loading from 'components/Loading';
import ButtonComponent from 'components/Buttons';
import ModalEditKPI from './ModalEditKPI';
import { KPI_PERMISSION } from 'constants/permission';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';
import EditLayout from 'components/PermissionPage/EditLayout';

const ModalDetailKPI = (props) => {
  const { showDetailKPI, setShowDetailKPI, isHRM } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: dataDetailKPI } = useGetDetailKPI(showDetailKPI.id);
  const { data: uListPosition } = useListPosition();
  const { data: uListSkill } = useListSkill();
  const { data: uListLevel } = useListLevel();
  const { data: uListDivision } = useGetListDivision();

  const [showPopupDeleteKPI, setShowPopupDeleteKPI] = useState(false);
  const [showPopupEditKPI, setShowPopupEditKPI] = useState(false);
  const [tabActive, setTabActive] = useState(TAB_OFFICIAL);
  const [dateFilter, setDateFilter] = useState({ startDate: null, endDate: null });
  const [listOfficial, setListOfficial] = useState([]);

  useEffect(() => {
    if (dataDetailKPI) setListOfficial(dataDetailKPI?.kpi_info?.list_official);
  }, [dataDetailKPI]);

  useEffect(() => {
    if (dateFilter.startDate && dateFilter.endDate) {
      setListOfficial(
        dataDetailKPI?.kpi_info.list_official?.filter(
          (item) =>
            !moment(dateFilter.startDate).isAfter(moment(item.date_official, 'DD/MM/YYYY'), 'day') &&
            !moment(dateFilter.endDate).isBefore(moment(item.date_official, 'DD/MM/YYYY'), 'day'),
        ),
      );
    } else {
      setListOfficial(dataDetailKPI?.kpi_info.list_official);
    }
  }, [dateFilter, dataDetailKPI]);

  const { mutate: deleteKPI, isLoading: isLoadingDelete } = useMutation(() => apiDeleteKPI(showDetailKPI.id), {
    onSuccess: (response) => {
      if (response.status === 200) {
        queryClient.invalidateQueries([USE_LIST_HR_NO_KPI], { refetchActive: true });
        queryClient.invalidateQueries([USE_LIST_KPI], { refetchActive: true });
        setShowPopupDeleteKPI(false);
        setShowDetailKPI(false);
        window.history.replaceState(null, '', '/admin/manager-kpi');
      }
    },
  });

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => {
    if (isLoadingDelete)
      return (
        <div className={styles.loadingIcon}>
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <ButtonComponent
          addClass={classNames(styles.btnConfirm, styles.btnConfirm__cancel)}
          text={t('common.cancel')}
          handleClick={onClickCancel}
        />
        <ButtonComponent
          addClass={classNames(styles.btnConfirm, styles.btnConfirm__ok)}
          text={t('common.ok')}
          handleClick={onClickOk}
        />
      </>
    );
  };

  const handleShowPopupEditKPI = () => {
    setShowPopupEditKPI(true);
    setShowDetailKPI({ ...showDetailKPI, active: false });
  };

  const handleChangeTab = (tabValue) => {
    setTabActive(tabValue);
    setDateFilter({ startDate: null, endDate: null });
    setListOfficial(dataDetailKPI?.kpi_info?.list_official);
  };

  const TableCandidate = ({ tab, listCandidate }) => (
    <div className="table-border-show">
      <TableContainer className={classNames(stylesTable.tableCommon, styles.tableContainer)}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className="headTable">
              <TableCell>{t('common.STT')}</TableCell>
              <TableCell>{t('common.fullName')}</TableCell>
              <TableCell>{t('managerKPI.position')}</TableCell>
              <TableCell>{t('managerKPI.skill')}</TableCell>
              <TableCell>{t('managerKPI.level')}</TableCell>
              {tab === TAB_OFFICIAL && <TableCell>{t('managerKPI.division')}</TableCell>}
              {tab === TAB_OFFICIAL && <TableCell>{t('managerKPI.laborContractDate')}</TableCell>}
              {tab === TAB_OTHERS && <TableCell>{t('managerKPI.statusText')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {!listCandidate?.length && (
              <tr>
                <td colSpan={7} className="error-no-data">
                  {t('common.notData')}
                </td>
              </tr>
            )}
            {listCandidate?.map((candidate, index) => (
              <TableRow key={index}>
                <TableCell className={stylesTable.sttCell}>{getSTT(index)}</TableCell>
                <TableCell className={stylesTable.normalCell}>{candidate.full_name}</TableCell>
                <TableCell className={stylesTable.normalCell}>
                  {getName(uListPosition, candidate.position_id)}
                </TableCell>
                <TableCell className={stylesTable.normalCell}>{getName(uListSkill, candidate.skill_id)}</TableCell>
                <TableCell className={styles.levelCell}>{getName(uListLevel, candidate.level_id)}</TableCell>
                {tab === TAB_OFFICIAL && (
                  <TableCell className={stylesTable.normalCell}>
                    {getName(uListDivision, candidate.division_id)}
                  </TableCell>
                )}
                {tab === TAB_OFFICIAL && (
                  <TableCell className={stylesTable.normalCell}>{candidate.date_official}</TableCell>
                )}
                {tab === TAB_OTHERS && (
                  <TableCell className={`${getColorStatus(LIST_STATUS, candidate.status)}`}>
                    {getName(LIST_STATUS, candidate.status)}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const disabledStartDate = (date) => {
    if (dateFilter.endDate) {
      return (
        date &&
        (moment(date).isAfter(moment(dateFilter.endDate, 'YYYY-MM-DD'), 'day') ||
          moment(date).isBefore(moment(dataDetailKPI?.kpi_info?.deadline).startOf('month'), 'day'))
      );
    }
    return (
      date &&
      (moment(date).isAfter(moment(dataDetailKPI?.kpi_info?.deadline), 'day') ||
        moment(date).isBefore(moment(dataDetailKPI?.kpi_info?.deadline).startOf('month'), 'day'))
    );
  };

  const disabledEndDate = (date) => {
    if (dateFilter.startDate) {
      return (
        date &&
        (moment(date).isBefore(moment(dateFilter.startDate, 'YYYY-MM-DD'), 'day') ||
          moment(date).isAfter(moment(dataDetailKPI?.kpi_info?.deadline), 'day'))
      );
    }
    return (
      date &&
      (moment(date).isAfter(moment(dataDetailKPI?.kpi_info?.deadline), 'day') ||
        moment(date).isBefore(moment(dataDetailKPI?.kpi_info?.deadline).startOf('month'), 'day'))
    );
  };

  return (
    <>
      <ModalComponent
        title={t('managerKPI.detailKPI')}
        isShowModal={showDetailKPI.active}
        toggle={() => {
          setDateFilter({ startDate: null, endDate: null });
          setShowDetailKPI({ ...showDetailKPI, active: false });
          window.history.replaceState(null, '', '/admin/manager-kpi');
        }}
        classNameAdd={styles.modalDetailKPI}
      >
        <Grid container gap={2}>
          <Grid container item xs={12} className={styles.headerInfo}>
            <Grid item xs={8} className={styles.titleGroup}>
              <span className={styles.titleDetail}>{t('managerKPI.infoDetailKPI')}</span>
              <span className={styles.creatorName}>
                {t('managerKPI.creatorKPI') + ': ' + dataDetailKPI?.hrm_info?.name}
              </span>
            </Grid>
            <Grid item xs={4} className={styles.buttonGroup}>
              {isHRM &&
                !dataDetailKPI?.kpi_info?.list_official?.length &&
                !dataDetailKPI?.kpi_info?.list_others?.length && (
                  <DeleteLayout permissionName={KPI_PERMISSION}>
                    <Button
                      variant="contained"
                      className={classNames(styles.btn, styles.btn__delete)}
                      onClick={() => setShowPopupDeleteKPI(true)}
                    >
                      {t('managerKPI.delete')}
                    </Button>
                  </DeleteLayout>
                )}

              {isHRM && !moment(dataDetailKPI?.kpi_info?.deadline, 'YYYY-MM-DD').isBefore(moment().startOf('month')) && (
                <EditLayout permissionName={KPI_PERMISSION}>
                  <Button
                    variant="contained"
                    className={classNames(styles.btn, styles.btn__edit)}
                    onClick={() => handleShowPopupEditKPI()}
                  >
                    {t('managerKPI.edit')}
                  </Button>
                </EditLayout>
              )}
            </Grid>
          </Grid>

          <Grid container rowSpacing={2} item xs={12} className={styles.mainInfo}>
            <Grid item xs={12} className={styles.title}>
              <span>{t('managerKPI.mainInfo')}</span>
            </Grid>
            <Grid item xs={12} className={styles.rowInfo}>
              <span className={styles.titleRow}>{t('managerKPI.createKPIDate')}</span>
              <span className={styles.contentRow}>
                {customDate(dataDetailKPI?.kpi_info?.created_at, 'HH:mm DD/MM/YYYY')}
              </span>
            </Grid>
            <Grid item xs={12} className={styles.rowInfo}>
              <span className={styles.titleRow}>{t('managerKPI.implementKPIMonth')}</span>
              <span className={styles.contentRow}>{customDate(dataDetailKPI?.kpi_info?.deadline, 'MM/YYYY')}</span>
            </Grid>
            <Grid item xs={12} className={styles.rowInfo}>
              <span className={styles.titleRow}>{t('managerKPI.deadline')}</span>
              <span className={styles.contentRow}>{customDate(dataDetailKPI?.kpi_info?.deadline, 'DD/MM/YYYY')}</span>
            </Grid>
            <Grid item xs={12} className={styles.rowInfo}>
              <span className={styles.titleRow}>{t('managerKPI.KPIInMonth')}</span>
              <span className={styles.contentRow}>{dataDetailKPI?.kpi_info?.target}</span>
            </Grid>
            <Grid item xs={12} className={styles.rowInfo}>
              <span className={styles.titleRow}>{t('managerKPI.actualData')}</span>
              <span className={styles.contentRow}>
                {dataDetailKPI?.kpi_info?.total + '/' + dataDetailKPI?.kpi_info?.target}
              </span>
            </Grid>
            <Grid item xs={12} className={styles.rowInfo}>
              <span className={styles.titleRow}>{t('managerKPI.statusText')}</span>
              <span
                className={classNames(
                  styles.contentRow,
                  styles[getColorStatus(LIST_STATUS_KPI, dataDetailKPI?.kpi_info?.status)],
                )}
              >
                {t(getName(LIST_STATUS_KPI, dataDetailKPI?.kpi_info?.status))}
              </span>
            </Grid>
            {isHRM && (
              <Grid item xs={12} className={styles.rowInfo}>
                <span className={styles.titleRow}>{t('managerKPI.hrCharge')}</span>
                <span className={styles.contentRow}>{dataDetailKPI?.hr_info?.name}</span>
              </Grid>
            )}
          </Grid>

          {!!(dataDetailKPI?.kpi_info?.list_official?.length || dataDetailKPI?.kpi_info?.list_others?.length) && (
            <Grid container item xs={12} className={styles.tableCandidate}>
              <TabContext value={tabActive}>
                <Grid item xs={12}>
                  <TabList onChange={(e, tabValue) => handleChangeTab(tabValue)}>
                    <Tab label={t('managerKPI.officialCandidate')} value={TAB_OFFICIAL} />
                    <Tab label={t('managerKPI.others')} value={TAB_OTHERS} />
                  </TabList>
                </Grid>
                <TabPanel value={TAB_OFFICIAL}>
                  <Grid container item xs={12}>
                    <div className={styles.dateFilterGroup}>
                      <DatePicker
                        placeholder={t('managerKPI.startDate')}
                        size="middle"
                        onChange={(date) => setDateFilter({ ...dateFilter, startDate: date })}
                        inputReadOnly
                        format="DD/MM/YYYY"
                        className="date-picker-ant-custom mr-10 w-140"
                        suffixIcon={<CalendarIcon />}
                        disabledDate={disabledStartDate}
                      />
                      <DatePicker
                        placeholder={t('managerKPI.endDate')}
                        size="middle"
                        onChange={(date) => setDateFilter({ ...dateFilter, endDate: date })}
                        inputReadOnly
                        format="DD/MM/YYYY"
                        className="date-picker-ant-custom w-140"
                        suffixIcon={<CalendarIcon />}
                        disabledDate={disabledEndDate}
                      />
                    </div>
                    <TableCandidate tab={TAB_OFFICIAL} listCandidate={listOfficial} />
                  </Grid>
                </TabPanel>
                <TabPanel value={TAB_OTHERS}>
                  <Grid container item xs={12}>
                    <TableCandidate tab={TAB_OTHERS} listCandidate={dataDetailKPI?.kpi_info?.list_others} />
                  </Grid>
                </TabPanel>
              </TabContext>
            </Grid>
          )}
        </Grid>
      </ModalComponent>
      <PopupAreYouSure
        active={showPopupDeleteKPI}
        handleCancel={() => setShowPopupDeleteKPI(false)}
        messages={t('messages.areYouSureDeleteThisKPI')}
        footer={<FooterPopupConfirm onClickCancel={() => setShowPopupDeleteKPI(false)} onClickOk={() => deleteKPI()} />}
      />
      <ModalEditKPI
        isShowModal={showPopupEditKPI}
        setShowModal={setShowPopupEditKPI}
        showDetailKPI={showDetailKPI}
        setShowDetailKPI={setShowDetailKPI}
        infoKPI={dataDetailKPI}
      />
    </>
  );
};

export default ModalDetailKPI;
