import React, { useContext } from 'react';

import styles from './styles.module.scss';
import { ThemContext } from 'layouts/Admin';
import { EDIT_PERMISSION } from 'constants/permission';

const EditLayout = (props) => {
  const { children, permissionName } = props;
  const { hasPermission } = useContext(ThemContext);

  if (
    (Array.isArray(permissionName) && permissionName.every((item) => hasPermission(item, EDIT_PERMISSION))) ||
    (!Array.isArray(permissionName) && hasPermission(permissionName, EDIT_PERMISSION))
  )
    return children;

  return (
    <div className={styles.container}>
      {children}
      <div className={styles.disableLayer}></div>
    </div>
  );
};

export default EditLayout;
