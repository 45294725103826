import { sendGet, sendPost, sendPut } from 'helpers/axios';
import config from '../config';

export const apiGetListCandidate = (params) => sendGet(`/api/candidate/get-list`, params);
export const apiGetListYearExperience = (params) => sendGet(`/api/get-year-experience`, params);
export const apiGetListSchool = (params) => sendGet(`/api/schools/get-by-keyword`, params);
export const apiGetListSourceCV = (params) => sendGet(`/api/source-cv/get-all`, params);
export const apiGetListLanguage = (params) => sendGet(`/api/language/get-all`, params);
export const apiCreateCandidateCV = (params) => sendPost(`/api/candidate/save`, params);
export const apiGetDetailCandidate = (params) => sendGet(`/api/candidate/get-detail`, params);
export const apiGetApplyCandidate = (applyId, params) => sendGet(`/api/candidate/apply/${applyId}/detail`, params);
export const apiGetListDivisionAccept = (params) => sendGet(`/api/candidate/apply/list-division-accept`, params);
export const apiGetHistoryInterView = (params) => sendGet(`/api/candidate/apply/history`, params);
export const apiGetListLeaderAssesCandidate = (applyId, type) =>
  sendGet(`/api/candidate/apply/${applyId}/get-list-assess`, { type });
export const apiGetAverageAssesCandidate = (applyId) => sendGet(`/api/candidate/apply/${applyId}/average-asset`);
export const apiGetListDivision = () => sendGet(`api/division/get-list`);
export const apiGetListTest = (params) => sendGet(`/api/candidate/post-test/${params.applyId}/detail`);
export const apiPostDivision = (params) => sendPost(`/api/candidate/apply/distribute-division`, params);
export const apiAddApplyInfo = (params) => sendPost(`/api/candidate/apply/add-apply-info`, params);
export const apiUpdateApplyInfo = (params) => sendPost(`/api/candidate/apply/update-apply-info`, params);
export const apiDownloadCV = (link) => `${config.API_DOMAIN_RECRUIT}/api/candidate/download-cv?link=${link}`;
export const apiGetListAssess = (applyId) => sendGet(`api/candidate/apply/get-stats/${applyId}`);
export const apiCandidateChangeStatusApply = (params) =>
  sendPut(`/api/candidate/apply/${params.applyId}/status`, params);
export const apiCandidateAssess = (applyId, params) => sendPost(`api/candidate/apply/${applyId}/assess`, params);
export const apiGetRoomsListByLocationId = (params) => sendGet(`/api/interview/list-rooms`, params);
export const apiCreateScheduleInterview = (params) => sendPost(`/api/interview/findMeetingTimes`, params);
export const apiUpdateExpect = (applyId, params) => sendPut(`api/candidate/apply/update-expect/${applyId}`, params);
export const apiDistributeDivision = (params) => sendPost(`api/candidate/apply/distribute-division`, params);
export const apiUpdateDistributeDivision = (applyId, params) =>
  sendPut(`api/candidate/apply/distribute-division/${applyId}`, params);
export const apiListAllStatusCandidate = (params) => sendGet(`/api/candidate/list-status`, params);
export const apiGetAllInterviewCandidate = (params) => sendGet(`api/candidate/list-detail-applies`, params);
export const apiUploadCV = (params) => sendPost(`/api/candidate/upload-temp`, params);
export const apiNotificationLeaderAssets = (params) => sendPost('api/notification/leader-assess', params);
export const apiGetListHr = (params) => sendGet('/api/get-list-position-hr', params);
export const apiAssignHr = (params) => sendPost('api/candidate/assign', params);
export const apiSyncPostTest = (params) => sendPut(`api/candidate/apply/${params.applyId}/sync-post-test`);
export const apiImportEmployees = (params) => sendPost('/api/candidate/import', params);
export const apiGetListLeaderBookInterview = (params) => sendGet('/api/interview/list-user-auto-fill', params);
