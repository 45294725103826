import React from 'react';
import { useTranslation } from 'react-i18next';

import iconHeader from 'assets/img/icons/dashboard/groupPeople.svg';
import LayoutBlock from '../components/layoutBlock';
import TopHonors from '../components/TopHonors';
import ListTotal from '../components/ListTotal';

import styles from './styles.module.scss';
import { useGetStatisticsBySourceCv } from 'hook/useDashboard';

import Loading from 'components/Loading';

const StatisticsBySourceCv = ({ filterDate }) => {
  const { t } = useTranslation();

  const { data: dataStatisticsSourceCv, isLoading } = useGetStatisticsBySourceCv(filterDate);

  return (
    <LayoutBlock title={t('dashboard.statisticsBySourceCv')} icon={iconHeader} classNameAdd={styles.blockSourceCv}>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <TopHonors
            top1={dataStatisticsSourceCv.at(0)}
            top2={dataStatisticsSourceCv.at(1)}
            top3={dataStatisticsSourceCv.at(2)}
          />
          <div className={styles.listTotalSourceCv}>
            <ListTotal list={dataStatisticsSourceCv} spacing={2} />
          </div>
        </>
      )}
    </LayoutBlock>
  );
};

export default StatisticsBySourceCv;
