import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import {
  Checkbox,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { useGetListOrderAvailable } from 'hook/useManagerJob';
import { sendPost } from 'helpers/axios';
import { customDate } from 'helpers/formatDate';
import { getLevelName } from 'helpers/masterData';
import { LIST_STATUS_ORDER, INFO_ORDER } from 'constants/manageJob';
import { managerJob, MAX_LENGTH_50 } from 'constants/index';
import { PROCESSING, DEADLINE_LATE } from 'constants/ManagerOrder';
import { HTTP_EXPECTATION_FAILED, HTTP_OK } from 'constants/StatusCode';

import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search/index.js';
import SelectComponent from 'components/Select/Select.js';
import SelectMultipleComponent from 'components/Select/MultipleSelect.js';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import DateDayComponent from 'components/DateTime/DateDay';
import { getMessageErrorFormatDate } from 'helpers/formatDate';

const ModalAddOrder = ({
  openAddJob,
  setOpenAddJob,
  handleOpenDetailJob,
  t,
  jobId,
  mapPosition,
  mapSkill,
  mapLevel,
  useListLevel,
  useListPosition,
  useListSkill,
  query,
}) => {
  const [filter, setFilter] = useState({
    keyword: null,
    position_id: null,
    level_id: null,
    skill_id: null,
    status: null,
    from_time: null,
    to_time: null,
  });

  const [addOrderItem, setAddOrderItem] = useState([]);
  const [valueSelect, setValueSelect] = useState([]);

  const [addJobSuccess, setAddJobSuccess] = useState(false);
  const [addOrderError, setAddOrderError] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { data: listOrderAvailable, isLoading: orderLoading } = useGetListOrderAvailable({
    id: jobId,
    ...filter,
    from_time: filter.to_time && filter.from_time && moment(filter.from_time, 'YYYY/MM/DD').format('YYYY-MM-DD'),
    to_time: filter.to_time && filter.from_time && moment(filter.to_time, 'YYYY/MM/DD').format('YYYY-MM-DD'),
  });

  const listStatus = [
    { name: t('managerOrderHr.processing'), id: PROCESSING },
    { name: t('managerOrderHr.deadlineLate'), id: DEADLINE_LATE },
  ];

  const handleSearch = (value, name) => {
    setFilter({ ...filter, [name]: value });
  };

  const handleAddOrder = (item) => {
    if (addOrderItem.includes(item.id)) {
      setAddOrderItem((prev) => {
        prev.splice(prev.indexOf(item.id), 1);
        return [...prev];
      });
    } else {
      setAddOrderItem((prev) => [...prev, item.id]);
    }
  };

  const handleAddJob = async () => {
    if (loading) {
      setLoading(false);
      const res = await sendPost(`/api/job/add-orders`, { list_order_ids: addOrderItem.join(','), job_id: jobId });
      if (res.data.status === HTTP_OK) {
        setAddJobSuccess(true);
        query.invalidateQueries([managerJob.USE_LIST_MANAGER_JOB], { refetchActive: true });
      }
      if (res.data.status === HTTP_EXPECTATION_FAILED) {
        setMessage(res.data.messages);
        setAddOrderError(true);
      }
      setLoading(true);
    }
  };

  const getNameStatusOrder = (status) => {
    if (status) {
      return t(LIST_STATUS_ORDER?.find((item) => item?.status === status)?.name);
    }
    return t('common.notDataContract');
  };

  const handleDoneAddJob = () => {
    if (loading) {
      setLoading(false);
      setAddJobSuccess(false);
      setOpenAddJob(false);
      handleOpenDetailJob(INFO_ORDER);
      query.refetchQueries(managerJob.USE_LIST_ORDER_BY_JOB);
      setLoading(true);
    }
  };

  const handleSearchKeyword = (value) => {
    setFilter({ ...filter, keyword: value });
  };

  const handleChangeValue = (event) => {
    const {
      target: { value },
    } = event;
    setValueSelect(typeof value === 'string' ? value.split(',') : value);
    handleSearch(value, 'level_id');
  };

  return (
    <>
      <AlertAfterSubmit toggle={handleDoneAddJob} isShowAlert={addJobSuccess}>
        <p>{t(`managerJob.addJobSuccess`)}</p>
      </AlertAfterSubmit>
      <AlertAfterSubmit toggle={() => setAddOrderError(!addOrderError)} isShowAlert={addOrderError}>
        <p>{message}</p>
      </AlertAfterSubmit>
      <ModalComponent
        isShowModal={openAddJob}
        title={t('managerJob.addOrder')}
        toggle={() => {
          setOpenAddJob(false);
          handleOpenDetailJob(INFO_ORDER);
        }}
        classNameAdd="modal-add-job detail-job manager-job"
      >
        <div className="add-job">
          <div className="filter-job">
            <div className="search">
              <SearchComponent
                txtPlaceholder={t('managerJob.search')}
                addClass="input-search-box search color-icon"
                handleSearch={(val) => handleSearchKeyword(val)}
                maxLength={MAX_LENGTH_50}
              />
            </div>
            <div className="position">
              <SelectComponent
                label={t('managerJob.position')}
                onHandleChangeSelect={(e) => {
                  handleSearch(e.target.value, 'position_id');
                }}
                addClass="select"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: {
                        xs: 350,
                      },
                    },
                  },
                }}
              >
                <MenuItem value="">{t('managerJob.None')}</MenuItem>
                {useListPosition &&
                  useListPosition?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </SelectComponent>
            </div>
            <div className="level">
              <SelectMultipleComponent
                label={t('managerJob.level')}
                onHandleChangeSelect={(event) => handleChangeValue(event)}
                isRequired={false}
                valueDefault={valueSelect}
                setValueSelect={setValueSelect}
                renderValue={(value) =>
                  value.length > 1 ? (
                    <span>
                      {value.length} {t('managerJob.levels')}
                    </span>
                  ) : (
                    getLevelName(value, useListLevel)
                  )
                }
              >
                {useListLevel &&
                  useListLevel?.map((item, index) => {
                    return (
                      <MenuItem key={`level_${index}`} value={item.id}>
                        <Checkbox checked={valueSelect.includes(item.id)} />
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </SelectMultipleComponent>
            </div>
            <div className="skill">
              <SelectComponent
                label={t('managerJob.skill')}
                onHandleChangeSelect={(e) => {
                  handleSearch(e.target.value, 'skill_id');
                }}
                disabled={!filter.position_id}
              >
                <MenuItem value="">{t('managerJob.None')}</MenuItem>
                {useListSkill &&
                  useListSkill
                    ?.filter((item) => Number(item.position_id) === Number(filter.position_id))
                    ?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
              </SelectComponent>
            </div>
            <div className="status">
              <SelectComponent
                label={t('managerJob.status')}
                onHandleChangeSelect={(e) => {
                  handleSearch(e.target.value, 'status');
                }}
              >
                <MenuItem value="">{t('managerJob.None')}</MenuItem>
                {listStatus.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </SelectComponent>
            </div>
            <div className="deadline">
              <DateDayComponent
                label={t('managerOrderHr.startDate')}
                addClass="input-search-box"
                valueDefault={filter?.from_time}
                max={filter?.to_time ? filter.to_time : null}
                onChangeHandle={(dateSearch) => {
                  setFilter({ ...filter, from_time: dateSearch });
                }}
                sizeDate="medium"
                error={getMessageErrorFormatDate(filter?.from_time, t('managerOrderHr.pleaseEnterTheCorrectFormat'))}
              />
            </div>
            <div className="deadline">
              <DateDayComponent
                label={t('managerOrderHr.endDate')}
                addClass="input-search-box"
                valueDefault={filter?.to_time}
                min={filter?.from_time ? filter.from_time : null}
                onChangeHandle={(dateSearch) => {
                  setFilter({ ...filter, to_time: dateSearch });
                }}
                sizeDate="medium"
                error={getMessageErrorFormatDate(filter?.to_time, t('managerOrderHr.pleaseEnterTheCorrectFormat'))}
              />
            </div>
          </div>
          <Paper className="mt-22">
            <TableContainer className="table-list table-container">
              <Table aria-label="sticky table" stickyHeader className="table-scroll-addorder">
                <TableHead className="asset-header headerTable">
                  <TableRow className="asset-header-row">
                    <TableCell></TableCell>
                    <TableCell>{t('managerJob.orderName')}</TableCell>
                    <TableCell>{t('managerJob.peopleOrder')}</TableCell>
                    <TableCell>{t('managerJob.position')}</TableCell>
                    <TableCell>{t('managerJob.level')}</TableCell>
                    <TableCell>{t('managerJob.skill')}</TableCell>
                    <TableCell>{t('managerJob.quantityApplied')}</TableCell>
                    <TableCell className="status-deadline-row">{t('managerJob.deadline')}</TableCell>
                    <TableCell className="status-deadline-row">{t('managerJob.status')}</TableCell>
                    <TableCell>{t('managerJob.linkOrder')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderLoading && (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Loading addClass="mt-3 mb-3" />
                      </TableCell>
                    </TableRow>
                  )}
                  {listOrderAvailable &&
                    listOrderAvailable.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            className="table-content id-standard cursor"
                            onClick={handleAddOrder.bind(this, item)}
                          >
                            <div className="d-flex checkbox-order align-items-center">
                              <Checkbox checked={addOrderItem.includes(item.id)} />
                            </div>
                          </TableCell>
                          <TableCell className="cell-table-title cursor info-order-text-name-order">
                            {item.name}
                          </TableCell>
                          <TableCell className="cell-table cursor">{item.user_info?.name}</TableCell>
                          <TableCell className="cell-table cursor">{mapPosition[item.position_id]}</TableCell>
                          <Tooltip
                            title={
                              <div className="tooltip-division-order">
                                {item?.level_id?.map((val, index) => (
                                  <div className="text-division" key={index}>
                                    {getLevelName(val, useListLevel)}
                                  </div>
                                ))}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                            PopperProps={{
                              sx: {
                                '& 	.MuiTooltip-tooltipArrow': {
                                  top: '-30px !important',
                                  '&::before': {
                                    backgroundColor: 'blue',
                                  },
                                },
                              },
                            }}
                          >
                            <TableCell className="cell-table cursor text-level-hr">
                              <span>
                                {item.level_id
                                  ? item.level_id.map((val) => getLevelName(val, useListLevel)).join(', ')
                                  : t('common.notDataContract')}
                              </span>
                            </TableCell>
                          </Tooltip>
                          <TableCell className="cell-table cursor">{mapSkill[item.skill_id]}</TableCell>
                          <TableCell className="cell-table cursor text-center">
                            {item.total_recruited + '/' + item.target}
                          </TableCell>
                          <TableCell className="cell-table cursor text-center">
                            {customDate(item.deadline, 'DD/MM/YYYY')}
                          </TableCell>
                          <TableCell className="cell-table cursor text-center">
                            {getNameStatusOrder(item.status)}
                          </TableCell>
                          <TableCell className="table-content text-center">
                            <NavLink
                              to={`/admin/manager-order/${item.id}`}
                              target="_blank"
                              className="link-order"
                            >
                              {t('managerJob.linkOrder')}
                            </NavLink>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {listOrderAvailable?.length === 0 && (
                    <tr>
                      <td colSpan={9} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Grid container spacing={2} className="btn-submit mt-3">
            <Grid item xs={6} className="button-cancel">
              <ButtonComponent
                text={t('managerJob.cancel')}
                addClass="btn-bg-yellow2 button-w"
                handleClick={() => {
                  setOpenAddJob(false);
                  handleOpenDetailJob(INFO_ORDER);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ButtonComponent
                text={t('managerJob.add')}
                addClass="btn-bg-yellow1 button-w"
                handleClick={handleAddJob}
                hasDisabled={!loading || addOrderItem.length === 0}
                isLoading={!loading}
              />
            </Grid>
          </Grid>
        </div>
      </ModalComponent>
    </>
  );
};

export default ModalAddOrder;
