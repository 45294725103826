import { useQuery } from 'react-query';

import { HTTP_OK } from 'constants/StatusCode';
import { apiGetListReferralBonus } from 'api/referralBonus';
import { USE_LIST_REFERRAL_BONUS } from 'constants/ReferralBonus';

export function useListReferralBonus(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [USE_LIST_REFERRAL_BONUS, params],
    async () => {
      const response = await apiGetListReferralBonus(params);
      if (response?.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    {
      enabled: params.month ? true : false,
    },
  );

  return { ...response, error, isLoading };
}
