import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchComponent from 'components/Search';

const SearchOrder = ({ isReset, setIsReset, handleSearch, maxLength }) => {
  const { t } = useTranslation();

  return (
    <div className="filter-search">
      <SearchComponent
        txtPlaceholder={t('managerOrderHr.search')}
        addClass="input-search-box search-hr"
        isReset={isReset}
        setIsReset={setIsReset}
        handleSearch={handleSearch}
        maxLength={maxLength}
      />
    </div>
  );
};

export default SearchOrder;
