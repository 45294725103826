import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CreateGroupTest from './CreateGroupTest';
import DetailGroupTest from './DetailGroupTest';
import ManagerGroupTest from './ManagerGroupTest';

const index = () => {
  return (
    <>
      <Switch>
        <Route path="/admin/master-data/manager-group-test" exact render={() => <ManagerGroupTest />} />
        <Route path="/admin/master-data/manager-group-test/detail/:id" exact render={() => <DetailGroupTest />} />
        <Route path="/admin/master-data/manager-group-test/create" exact render={() => <CreateGroupTest />} />
      </Switch>
    </>
  );
};

export default index;
