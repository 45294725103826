import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { ModalFooter } from 'reactstrap';
import classNames from 'classnames';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import ButtonComponent from 'components/Buttons';

import styles from './styles.module.scss';
import ModalComponent from 'components/Modal';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import AlertComponent from 'components/AlertAfterSubmit/index.js';

import { sendPut } from 'helpers/axios';
import { USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL } from 'constants/ManagerCandidate';
import { MAX_LENGTH_300 } from 'constants/index';

const FORM_FIELDS = {
  content: 'content',
};
const ModalHrEvaluate = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { title, label, isShowModal, setIsShowModal, onSuccess, listApply, required, confirmMessage, defaultValue } = props;
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  
  const DEFAULT_VALUES = {
    [FORM_FIELDS.content]: defaultValue ?? undefined,
  };

  const { mutate: submitRefuse, isLoading } = useMutation(
    (values) =>
      sendPut(`/api/candidate/apply/${listApply?.id}/evaluate`, {
        evaluate_content: values[FORM_FIELDS.content],
      }),
    {
      onSuccess: async () => {
        queryClient.refetchQueries(USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL);
        confirmMessage && setMessage(confirmMessage);
        confirmMessage && setShowAlert(true);
        setIsShowModal(false);
        onSuccess && onSuccess();
        reset();
      },
      onError: () => {
        setError(FORM_FIELDS.content, { type: 'custom', message: t('common.anUnknownError') });
      },
    },
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        [FORM_FIELDS.content]: Yup.string().trim()
            .required(t('common.thisIsObligation'))
            .max(MAX_LENGTH_300, t('common.pleaseEnterLess300Characters')),
      }),
    ),
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const handleCloseModal = () => {
    setIsShowModal(false);
    reset();
  };

  const validateAndSubmit = (values) => {
    submitRefuse(values);
    reset();
  };
  
  return (
    <>
      <ModalComponent
        title={title}
        isShowModal={isShowModal}
        toggle={handleCloseModal}
        renderModalFooter={() => (
          <ModalFooter className={styles.modalFooter}>
            <button type="button" className={classNames(styles.btn, styles.btn__cancel)} onClick={handleCloseModal}>
              {t('common.cancel')}
            </button>
            <ButtonComponent
              addClass={classNames(styles.btn, styles.btn__action)}
              handleClick={handleSubmit(validateAndSubmit)}
              isLoading={isLoading}
              hasDisabled={isLoading}
              text={t('managerCandidate.evaluation')}
            />
          </ModalFooter>
        )}
        classNameAdd={styles.cancelModal}
      >
        <form>
          <CustomInputYup
            name={FORM_FIELDS.content}
            label={label}
            control={control}
            useWatch={useWatch}
            register={register}
            errors={errors}
            defaultValue={defaultValue}
            required={required}
            multiline
            rows={7}
          />
        </form>
      </ModalComponent>
      <AlertComponent toggle={() => setShowAlert(false)} isShowAlert={isShowAlert}>
        <p>{message}</p>
      </AlertComponent>
    </>
  );
};

export default ModalHrEvaluate;
