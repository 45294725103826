import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';

import { Grid, Avatar } from '@mui/material';

import styles from './styles.module.scss';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';

import ModalComponent from 'components/Modal';
import CustomInputNumber from 'components/InputYupAndMui/CusromInputNumber';
import ButtonComponent from 'components/Buttons';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';

import { customDate } from 'helpers/formatDate';
import { apiEditKPI } from 'api/managerKPI';
import { USE_LIST_KPI, USE_GET_DETAIL_KPI, MIN_KPI_FOR_HR, MAX_KPI_FOR_HR } from 'constants/kpi';
import { HTTP_OK } from 'constants/StatusCode';

const ModalEditKPI = ({ isShowModal, setShowModal, setShowDetailKPI, showDetailKPI, infoKPI }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isEditSuccess, setIsEditSuccess] = useState(false);
  const [KPI, setKPI] = useState();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        target: Yup.number()
          .typeError(t('managerKPI.pleaseEnterKPI'))
          .required(t('managerKPI.pleaseEnterKPI'))
          .min(MIN_KPI_FOR_HR, t('managerKPI.pleaseEnterKPI'))
          .max(MAX_KPI_FOR_HR, t('managerKPI.pleaseEnterKPILessOrEqual100'))
          .nullable(),
      }),
    ),
    mode: 'onChange',
  });

  const { mutate: editKPI, isLoading: isLoadingEditKPI } = useMutation(
    (params) =>
      apiEditKPI({
        target: params.target,
        deadline: customDate(infoKPI?.kpi_info?.deadline, 'MM/YYYY'),
        id: showDetailKPI.id,
        hr_id: infoKPI?.hr_info?.user_id,
      }),
    {
      onSuccess: (response) => {
        if (response.status === HTTP_OK) {
          setIsEditSuccess(true);
          setShowModal(false);
          setShowDetailKPI({ ...showDetailKPI, active: true });
          queryClient.invalidateQueries([USE_LIST_KPI], { refetchActive: true });
          queryClient.invalidateQueries([USE_GET_DETAIL_KPI], { refetchActive: true });
        }
      },
    },
  );

  return (
    <>
      <AlertAfterSubmit toggle={() => setIsEditSuccess(false)} isShowAlert={isEditSuccess}>
        <p>{t('managerKPI.editKPISuccess')}</p>
      </AlertAfterSubmit>
      {isShowModal && (
        <ModalComponent
          isShowModal={isShowModal}
          title={t('managerKPI.editKPI')}
          toggle={() => {
            setShowModal(false);
            setShowDetailKPI({ ...showDetailKPI, active: true });
          }}
          classNameAdd={styles.modalEditKPI}
        >
          <Grid container gap={3}>
            <Grid item xs={12} className={styles.infoHR}>
              <div className={styles.avatar}>
                <Avatar src={infoKPI?.hr_info?.avatar ?? defaultAvatar} />
              </div>
              <div>
                <span className={styles.name}>{infoKPI?.hr_info?.name}</span>
                <span className={styles.emailAndPosition}>
                  {infoKPI?.hr_info?.email + ' - ' + infoKPI?.hr_info?.position_name}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <CustomInputYup
                name="deadline"
                label={customDate(infoKPI?.kpi_info?.deadline, 'MM/YYYY')}
                control={control}
                useWatch={useWatch}
                register={register}
                setValue={setValue}
                errors={errors}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputNumber
                name="target"
                label={t('manageKPI.KPI')}
                defaultValue={infoKPI?.kpi_info?.target}
                control={control}
                useWatch={useWatch}
                register={register}
                setValue={setValue}
                errors={errors}
                required
                isInteger
                onHandelChange={(value) => setKPI(value)}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonComponent
                text={t('common.save')}
                addClass="btn-bg-yellow1 mr-3 w-100"
                handleClick={handleSubmit(editKPI)}
                isLoading={isLoadingEditKPI}
                hasDisabled={Number(KPI) === infoKPI?.kpi_info?.target}
              />
            </Grid>
          </Grid>
        </ModalComponent>
      )}
    </>
  );
};

export default ModalEditKPI;
