import { NavLink as NavLinkRRD } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState, useContext } from 'react';
import { NavItem, NavLink, Nav, Container } from 'reactstrap';
import classNames from 'classnames';
import { matchPath } from 'react-router';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import styles from './styles.module.scss';
import { ThemContext } from 'layouts/Admin';
import { VIEW_PERMISSION } from 'constants/permission';

const Header = (props) => {
  const { t } = useTranslation();
  const { hasPermission } = useContext(ThemContext);
  const { routes } = props;
  const [openMenuMasterData, setOpenMenuMasterData] = useState(
    window.location.href.indexOf('master-data') > 0 ? true : false,
  );
  const handleClick = () => {
    setOpenMenuMasterData(!openMenuMasterData);
  };

  const checkLinkActive = (data) => {
    if (!!matchPath(window.location.pathname, data?.layout + data?.path)) return true;
    if (data?.links) return data.links.some((link) => !!matchPath(window.location.pathname, link));
    return false;
  };

  const displayBySubMenu = useCallback(
    (item) => {
      return item.subMenu.some((_item) => hasPermission(_item?.permission, VIEW_PERMISSION));
    },
    [hasPermission],
  );

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.subMenu) {
        let isActive = false;
        prop?.subMenu?.forEach((element) => {
          if (
            window.location.href.indexOf(element.path) > 0 ||
            (element.path.indexOf(':') > 0 &&
              window.location.href.indexOf(element.path.slice(0, element.path.indexOf(':'))) > 0)
          ) {
            isActive = true;
          }
        });
        return (
          <>
            {displayBySubMenu(prop) && (
              <ListItemButton
                onClick={handleClick}
                className={`${styles.dropDownMenu} ${isActive && styles.activeMasterData} ${styles.masterDataButton}`}
              >
                <ListItemIcon className={styles.iconMenuDropdown}>
                  {prop.icon && prop.iconActive && (
                    <img className={styles.iconMenu} src={isActive ? prop.iconActive : prop.icon} alt="" />
                  )}
                </ListItemIcon>
                <ListItemText className={styles.menuDropdown} primary={t(prop.name)} />
                {openMenuMasterData ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </ListItemButton>
            )}
            <Collapse in={openMenuMasterData} timeout="auto" unmountOnExit>
              <List component="div" className={`${styles.dropDownMenu} ${styles.customSubMenu}`} disablePadding>
                {prop?.subMenu?.map((menuChil, keyChil) => {
                  return (
                    <>
                      {hasPermission(menuChil?.permission, VIEW_PERMISSION) && (
                        <NavItem key={keyChil}>
                          <NavLink
                            to={menuChil.layout + menuChil.path}
                            tag={NavLinkRRD}
                            className={`${styles.linkMenu} ${checkLinkActive(menuChil) ? styles.activeMenuChil : ''}`}
                          >
                            <ListItemButton sx={{ pl: 4 }} className={styles.itemButtonMenu}>
                              <ListItemIcon className={styles.iconMenuChil}>
                                {menuChil.icon && menuChil.iconActive && (
                                  <img
                                    className={styles.iconMenuChilrent}
                                    src={checkLinkActive(menuChil) ? menuChil.iconActive : menuChil.icon}
                                    alt=""
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText className={styles.menuDropdown} primary={t(menuChil.name)} />
                            </ListItemButton>
                          </NavLink>
                        </NavItem>
                      )}
                    </>
                  );
                })}
              </List>
            </Collapse>
          </>
        );
      }
      return (
        <>
          {hasPermission(prop?.permission, VIEW_PERMISSION) && (
            <NavItem className={`${styles.dropDownMenu} ${checkLinkActive(prop) ? styles.active : ''}`} key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                className={`${prop.class} ${styles.linkMenu} ${checkLinkActive(prop) ? styles.active : ''}`}
              >
                <ListItemButton sx={{ pl: 4 }} className={styles.itemButtonMenu}>
                  <ListItemIcon>
                    {prop.icon && prop.iconActive && (
                      <img
                        className={styles.iconMenu}
                        src={checkLinkActive(prop) ? prop.iconActive : prop.icon}
                        alt=""
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText className={styles.menuDropdown} primary={prop.name} />
                </ListItemButton>
              </NavLink>
            </NavItem>
          )}
        </>
      );
    });
  };

  return (
    <>
      <div className={classNames({ [`bg-menu-slide`]: routes?.length ? true : false }, styles.boxMenu)}>
        <Container fluid className={styles.menu}>
          <div className="header-body">
            <div className="navbar-top navbar-horizontal navbar-dark" expand="lg">
              <Nav navbar className={`${styles.ulMenu}`}>
                {createLinks(routes)}
              </Nav>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.paddingTop}></div>
    </>
  );
};

export default Header;
