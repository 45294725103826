import { useQuery } from 'react-query';
import { apiGetListDivision } from 'api/managerCandidate';
import { apiGetListDivisionAccept } from 'api/managerCandidate';

import * as Constants from 'constants/ManagerCandidate';
import { HTTP_OK } from 'constants/StatusCode';

export function useGetListDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_DIVISION, params], async () => {
    const response = await apiGetListDivision(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  const result = { ...response, error, isLoading, data: response };
  return result;
}

export function useGetListDivisionAccept(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_DIVISION_ACCEPT_CANDIDATE, params], async () => {
    const response = await apiGetListDivisionAccept(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  const result = { ...response, error, isLoading, data: response?.data };
  return result;
}
