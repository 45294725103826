import React from 'react';
import { useTranslation } from 'react-i18next';

import { getMessageErrorFormatDate } from 'helpers/formatDate';
import DateDayComponent from 'components/DateTime/DateDay';

const FilterOrderByDeadline = (props) => {
  const { t } = useTranslation();
  const { fromTime, toTime, onChangeFromTime, onChangeToTime } = props;

  return (
    <>
      <DateDayComponent
        label={t('managerOrderHr.startDate')}
        addClass="input-search-box mt-3"
        valueDefault={fromTime}
        max={toTime ? toTime : null}
        onChangeHandle={onChangeFromTime}
        sizeDate="small"
        error={getMessageErrorFormatDate(fromTime, t('managerOrderHr.pleaseEnterTheCorrectFormat'))}
      />
      <DateDayComponent
        label={t('managerOrderHr.endDate')}
        addClass="input-search-box mt-3"
        valueDefault={toTime}
        min={fromTime ? fromTime : null}
        onChangeHandle={onChangeToTime}
        sizeDate="small"
        error={getMessageErrorFormatDate(toTime, t('managerOrderHr.pleaseEnterTheCorrectFormat'))}
      />
    </>
  );
};

export default FilterOrderByDeadline;
