import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import { Grid, FormControlLabel, Radio, RadioGroup, Avatar } from '@mui/material';
import { useMutation } from 'react-query';

import styles from './styles.module.scss';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';

import { MAX_LENGTH_50 } from 'constants/index';
import { TYPE_TIME_REPORT, TYPE_QUANTITY_CANDIDATE } from 'constants/kpi';
import { customDate } from 'helpers/formatDate';
import { showAlert } from 'helpers/modal';

import { useGetListHrHasRecruit } from 'hook/useManagerKPI';
import { apiExportKpiOneHR, apiExportKpiMultiHR } from 'api/managerKPI';

import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import DateMonthComponent from 'components/DateTime/DateMonth';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import AlertAfterSubmit from 'components/AlertAfterSubmit';

const ModalExportKPI = (props) => {
  const { isShowModal, setShowModal } = props;
  const { t } = useTranslation();

  const [showTimeReport, setShowTimeReport] = useState(TYPE_TIME_REPORT.PER_MONTH);
  const [selectTypeHr, setSelectTypeHr] = useState(TYPE_QUANTITY_CANDIDATE.PERSONAL);
  const [nameHrSearch, setNameHrSearch] = useState();
  const [hrIdsPicked, setHrIdsPicked] = useState({ user_id: null, name: null });
  const [isExportSuccess, setIsExportSuccess] = useState(false);
  const [formData, setFormData] = useState({
    start_month: moment(),
    end_month: moment(),
  });
  const { data: listHr, isLoading: isLoadingListHr } = useGetListHrHasRecruit(
    {
      name: nameHrSearch,
      start_month: moment(formData.start_month).format('MM/YYYY'),
      end_month: moment(formData.end_month).format('MM/YYYY'),
    },
    isShowModal,
  );

  const idAllHrRecruit = listHr?.data?.map((item) => item.user_id);

  const handleClickHR = (hr) => {
    if (hr.disable) return;
    if (hrIdsPicked.user_id === hr.user_id) setHrIdsPicked({ user_id: null, name: null });
    else setHrIdsPicked(hr);
  };
  const onHandleStartMonth = (value) => {
    setHrIdsPicked({ user_id: null, name: null });
    if (showTimeReport !== TYPE_TIME_REPORT.CUSTOM) {
      setFormData({ ...formData, start_month: value, end_month: value });
    } else {
      setFormData({ ...formData, start_month: value });
    }
  };
  const onHandleEndMonth = (value) => {
    setHrIdsPicked({ user_id: null, name: null });
    setFormData({ ...formData, end_month: value });
  };

  const { mutate: handleExportKPI, isLoading: exportLoading } = useMutation(
    () =>
      selectTypeHr === TYPE_QUANTITY_CANDIDATE.PERSONAL
        ? apiExportKpiOneHR({
            user_id: [hrIdsPicked.user_id],
            start_month: customDate(formData.start_month, 'MM-YYYY'),
            end_month: customDate(formData.end_month, 'MM-YYYY'),
          })
        : apiExportKpiMultiHR({
            user_id: idAllHrRecruit,
            start_month: customDate(formData.start_month, 'MM-YYYY'),
            end_month: customDate(formData.end_month, 'MM-YYYY'),
          }),
    {
      onSuccess: (res) => {
        if (res?.status === 200) {
          const encodedUri = URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          if (selectTypeHr === TYPE_QUANTITY_CANDIDATE.PERSONAL && showTimeReport === TYPE_TIME_REPORT.PER_MONTH) {
            link.setAttribute(
              'download',
              t('managerKPI.statisticalOneMonthKPI', {
                name: hrIdsPicked.user_name,
                monthStart: moment(formData.start_month).format('MM-YYYY'),
              }) + '.xlsx',
            );
          } else if (selectTypeHr === TYPE_QUANTITY_CANDIDATE.PERSONAL && showTimeReport === TYPE_TIME_REPORT.CUSTOM) {
            link.setAttribute(
              'download',
              t('managerKPI.statisticalOneNameKPI', {
                name: hrIdsPicked.user_name,
                monthStart: moment(formData.start_month).format('MM-YYYY'),
                monthEnd: moment(formData.end_month).format('MM-YYYY'),
              }) + '.xlsx',
            );
          } else if (selectTypeHr === TYPE_QUANTITY_CANDIDATE.ALL_HR && showTimeReport === TYPE_TIME_REPORT.PER_MONTH) {
            link.setAttribute(
              'download',
              t('managerKPI.statisticalAllKPIOneMonth', {
                monthStart: moment(formData.start_month).format('MM-YYYY'),
              }) + '.xlsx',
            );
          } else {
            link.setAttribute(
              'download',
              t('managerKPI.statisticalAllKPI', {
                monthStart: moment(formData.start_month).format('MM-YYYY'),
                monthEnd: moment(formData.end_month).format('MM-YYYY'),
              }) + '.xlsx',
            );
          }
          document.body.appendChild(link);
          link.click();
          link.remove();
          setShowModal(false);
          setIsExportSuccess(true);
        } else {
          showAlert(t('messages.systemError'));
        }
      },
      onError: (error) => {
        if (error?.message) {
          showAlert(error?.message);
        } else if (error?.data?.message) {
          showAlert(error?.data?.message);
        } else {
          showAlert(t('common.anUnknownError'));
        }
      },
    },
  );

  return (
    <>
      <AlertAfterSubmit toggle={() => setIsExportSuccess(false)} isShowAlert={isExportSuccess}>
        <p>{t('managerKPI.exportKPISuccess')}</p>
      </AlertAfterSubmit>
      <ModalComponent
        title={t('managerKPI.exportReport')}
        isShowModal={isShowModal}
        toggle={() => setShowModal(false)}
        classNameAdd={styles.modalExportKPI}
      >
        <Grid container>
          <Grid item xs={12} className={styles.titleReport}>
            {t('managerKPI.timeReport')}
          </Grid>
          <Grid container item xs={12}>
            <RadioGroup row className={styles.radioGroup} defaultValue={TYPE_TIME_REPORT.PER_MONTH}>
              <Grid item xs={5}>
                <FormControlLabel
                  value={TYPE_TIME_REPORT.PER_MONTH}
                  onChange={(e) => {
                    setShowTimeReport(Number(e.target.value));
                  }}
                  control={<Radio />}
                  label={t('managerKPI.perMonth')}
                />
              </Grid>
              <Grid item xs={7}>
                <FormControlLabel
                  value={TYPE_TIME_REPORT.CUSTOM}
                  onChange={(e) => {
                    setShowTimeReport(Number(e.target.value));
                  }}
                  control={<Radio />}
                  label={t('managerKPI.timeCustom')}
                />
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid container item xs={12} columnSpacing={2} className={styles.datePickerGroup}>
            <Grid item xs={showTimeReport === TYPE_TIME_REPORT.CUSTOM ? 6 : 12}>
              <DateMonthComponent
                addClass="input-search-box"
                valueDefault={formData.start_month}
                onChangeHandle={onHandleStartMonth}
                placeholder={t('managerKPI.monthPerYear')}
                max={showTimeReport === TYPE_TIME_REPORT.CUSTOM ? formData.end_month : moment()}
              />
            </Grid>
            {showTimeReport === TYPE_TIME_REPORT.CUSTOM && (
              <Grid item xs={6}>
                <DateMonthComponent
                  addClass="input-search-box"
                  valueDefault={formData.end_month}
                  onChangeHandle={onHandleEndMonth}
                  placeholder={t('managerKPI.monthPerYear')}
                  min={formData.start_month}
                  max={moment()}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className={styles.titleReport}>
            {t('managerKPI.selectHRToExportReport')}
          </Grid>
          <Grid container item xs={12}>
            <RadioGroup row className={styles.radioGroup} defaultValue={TYPE_QUANTITY_CANDIDATE.PERSONAL}>
              <Grid item xs={5}>
                <FormControlLabel
                  value={TYPE_QUANTITY_CANDIDATE.PERSONAL}
                  onChange={(e) => {
                    setSelectTypeHr(Number(e.target.value));
                  }}
                  control={<Radio />}
                  label={t('managerKPI.accordingPersonal')}
                />
              </Grid>
              <Grid item xs={7}>
                <FormControlLabel
                  value={TYPE_QUANTITY_CANDIDATE.ALL_HR}
                  onChange={(e) => {
                    setSelectTypeHr(Number(e.target.value));
                  }}
                  control={<Radio />}
                  label={t('managerKPI.allHr')}
                />
              </Grid>
            </RadioGroup>
          </Grid>
          {selectTypeHr === TYPE_QUANTITY_CANDIDATE.PERSONAL && (
            <Grid container item xs={12}>
              <Grid item xs={12} className={styles.searchNameHr}>
                <SearchComponent
                  txtPlaceholder={t('managerCandidate.nameHR')}
                  maxLength={MAX_LENGTH_50}
                  size="medium"
                  addClass={styles.searchHr}
                  handleSearch={(value) => setNameHrSearch(value)}
                />
              </Grid>
              <Grid item xs={12} className={styles.titleReport}>
                {t('managerKPI.listHR')}
              </Grid>
              <Grid item xs={12}>
                {isLoadingListHr && <Loading />}
                {!isLoadingListHr && (
                  <div className={styles.listHr}>
                    {listHr &&
                      listHr?.data?.map((item) => (
                        <div
                          key={item.user_id}
                          onClick={() => handleClickHR(item)}
                          className={classNames(styles.rowItem, {
                            [styles.isPickedRow]: hrIdsPicked.user_id === item.user_id,
                          })}
                        >
                          <div className={styles.avatar}>
                            <Avatar src={item.avatar ? item.avatar : defaultAvatar} />
                          </div>
                          <div>
                            <span className={styles.name}>{item.user_name}</span>
                            <span className={styles.emailAndPosition}>{item.email + ' - ' + item.position_name}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                {!isLoadingListHr && !listHr?.data?.length && (
                  <div className="error-no-data"> {t('common.notData')}</div>
                )}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <ButtonComponent
              text={t('managerKPI.exportReport')}
              addClass="btn-bg-yellow1 mr-3 w-100"
              handleClick={handleExportKPI}
              hasDisabled={!hrIdsPicked.user_id && selectTypeHr !== TYPE_QUANTITY_CANDIDATE.ALL_HR}
              isLoading={exportLoading}
            />
          </Grid>
        </Grid>
      </ModalComponent>
    </>
  );
};

export default ModalExportKPI;
