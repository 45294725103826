import { Grid, Tab, Tabs } from '@mui/material';
import InputField from 'components/Input/InputField';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { MdDelete } from 'react-icons/md';
import { MAX_LENGTH_200 } from 'constants/index';

const FormCreateQuestion = ({ group_question, t, control, errors, Controller, handleUpdateQuestion, tabFormData }) => {
  const [tabActive, setTabActive] = useState(tabFormData.current || 'checkbox');
  const handleTab = (e, newtab) => {
    setTabActive(newtab);
    tabFormData.current = newtab;
  };

  return (
    <>
      <Tabs value={tabActive} onChange={handleTab} className="tab-option-question">
        <Tab label={t('managerTest.opGuide')} value="guide" className="width-132 height-36 mr-10" />
        <Tab label={t('managerTest.opCheckbox')} value="checkbox" className="width-132 height-36 mr-10" />
        <Tab label={t('managerTest.opRadio')} value="radio" className="width-132 height-36" />
      </Tabs>
      <div className="group-question">
        {group_question.map((item, index) => (
          <React.Fragment key={index}>
            {(tabActive === 'checkbox' || tabActive === 'radio') && (
              <div className="question-item d-flex align-items-center">
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={`group_question.${index}.content-answer`}
                    valueDefault={item.content}
                    maxLength={MAX_LENGTH_200}
                    render={({ field }) => (
                      <InputField
                        placeholder={t('managerTest.inputAnswerTest')}
                        maxLength={MAX_LENGTH_200}
                        field={field}
                        label={
                          <React.Fragment>
                            {t('managerTest.contentAnswer')}
                            {<sup>*</sup>}
                          </React.Fragment>
                        }
                        variant="outlined"
                        onHandleInput={() => {}}
                        error={errors?.group_question?.[index]?.['content-answer']?.message}
                      />
                    )}
                  />
                </Grid>
                <div
                  onClick={handleUpdateQuestion.bind(this, index)}
                  className={`action d-flex cursor align-items-center justify-content-center ${
                    group_question.length - 1 === index ? 'add' : 'delete'
                  }`}
                >
                  {group_question.length - 1 === index ? <AddIcon /> : <MdDelete />}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
        {!group_question?.length && (tabActive === 'checkbox' || tabActive === 'radio') && (
          <div className="question-item d-flex align-items-center">
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`group_question.0.content-answer`}
                valueDefault={''}
                maxLength={MAX_LENGTH_200}
                render={({ field }) => (
                  <InputField
                    field={field}
                    maxLength={MAX_LENGTH_200}
                    label={
                      <React.Fragment>
                        {t('managerTest.contentAnswer')}
                        {<sup>*</sup>}
                      </React.Fragment>
                    }
                    variant="outlined"
                    onHandleInput={() => {}}
                    error={errors?.group_question?.[0]?.['content-answer']?.message}
                  />
                )}
              />
            </Grid>
            <div
              onClick={handleUpdateQuestion.bind(this, -1)}
              className={`action d-flex cursor align-items-center justify-content-center ${'add'}`}
            >
              {<AddIcon />}
            </div>
          </div>
        )}
        {errors?.group_question?.message && (tabActive === 'checkbox' || tabActive === 'radio') && (
          <p className="errors">{errors?.group_question?.message}</p>
        )}
      </div>
    </>
  );
};

export default FormCreateQuestion;
