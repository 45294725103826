import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useMutation, useQueryClient } from 'react-query';
import { HiPencil } from 'react-icons/hi';

import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { IoMdClose } from 'react-icons/io';

import styles from './styles.module.scss';
import LeaderAssignToAnother from './LeaderAssignToAnother.js';
import stylesModalCommon from 'components/ModalCommon/styles.module.scss';
import DetailStatusCard from 'components/DetailStatusCard';
import { ThemContext } from 'layouts/Admin';
import { apiCandidateChangeStatusApply } from 'api/managerCandidate';
import { getLevelName, getPositionName, getSkillName, getDivisionName, getTextFreelancer } from 'helpers/masterData';
import { customDate } from 'helpers/formatDate';
import { formatSalary } from 'helpers/format';
import { useGetDetailLeaderOrder } from 'hook/useManagerOrder';
import { useGetListDivision } from 'hook/useDivision';
import iconCheckCircle from 'assets/img/icons/check_green_circle.svg';
import { LOADING_SM } from 'constants/index';
import {
  LIST_STATUS,
  LENGTH_NAME_ASSIGNER,
  MALE,
  WAIT_BOD_ACCEPT,
  PROCESSING,
  DEADLINE_LATE,
  CANCEL_ORDER,
  COMPLETED,
  BOD_DONT_ACCEPT,
  USE_DETAIL_LEADER_ORDER,
} from 'constants/ManagerOrder';
import { STATUS_CANDIDATE } from 'constants/ManagerCandidate';
import { ORDER_HR_PERMISSION, CANDIDATE_PERMISSION } from 'constants/permission';
import Loading from 'components/Loading';
import InfoCard from 'components/InfoCard';
import Popup from 'components/popup';
import classNames from 'classnames';
import { HTTP_OK } from 'constants/StatusCode';
import EditLayout from 'components/PermissionPage/EditLayout';

const ModalOrder = ({
  modal,
  toggle,
  className,
  title,
  id,
  dataPosition,
  dataLevel,
  dataSkill,
  openModalCancel,
  openModalComplete,
}) => {
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const history = useHistory();
  const { dataInfoUser, isAssigner } = useContext(ThemContext);
  const { data: dataDetailOrder, isLoading } = useGetDetailLeaderOrder({ id });
  const { data: uListDivision } = useGetListDivision();

  const [openApproveCandidate, setOpenPopupApproveCandidate] = useState(false);
  const [openModalAssignUser, setOpenModalAssignUser] = useState(false);
  const [candidateConfirmed, setCandidateConfirmed] = useState({ id: null, applyId: null, jobId: null });

  const getClassStatus = (value) => {
    switch (value) {
      case WAIT_BOD_ACCEPT:
        return 'wait';
      case BOD_DONT_ACCEPT:
        return 'error';
      case PROCESSING:
        return 'processing';
      case DEADLINE_LATE:
        return 'lateDeadline';
      case CANCEL_ORDER:
        return 'cancel';
      default:
        return 'completed';
    }
  };

  const status = [
    { name: t('managerOrderHr.waitBodAccept'), value: WAIT_BOD_ACCEPT, status: false },
    { name: t('managerOrderHr.bodDontAccept'), value: BOD_DONT_ACCEPT, status: false },
    { name: t('managerOrderHr.processing'), value: PROCESSING, status: false },
    { name: t('managerOrderHr.deadlineLate'), value: DEADLINE_LATE, status: false },
    { name: t('managerOrderHr.cancelOrder'), value: CANCEL_ORDER, status: false },
    { name: t('managerOrderHr.completed'), value: COMPLETED, status: false },
  ];

  const getColorStatusUV = (value) => {
    return LIST_STATUS?.find((item) => item.id === value)?.class;
  };

  const getName = (listItem, value) => {
    if (value) {
      return listItem?.find((item) => item.id === value)?.name;
    }
    return 'N/A';
  };

  const handleOpenModalCancel = () => {
    toggle();
    openModalCancel(true);
  };

  const handleOpenModalComplete = () => {
    toggle();
    openModalComplete(true);
  };

  const handleClickButtonConfirmInterrupt = (id, applyId, jobId) => {
    setOpenPopupApproveCandidate(true);
    setCandidateConfirmed({ id, applyId, jobId });
  };

  const formatFieldKeyList = (list) => {
    return list?.map((item) => {
      const { user_info } = item;
      return {
        status_id: item.status,
        status: getName(LIST_STATUS, item.status),
        name: user_info.name,
        avatar: user_info.avatar,
      };
    });
  };

  const { mutate: changeStatusCandidate, isLoading: isLoadingChangeStatus } = useMutation(
    (status) =>
      apiCandidateChangeStatusApply({
        applyId: candidateConfirmed.applyId,
        status: status,
        user_id: dataInfoUser.user_id,
        is_leader_interrupt: true,
      }),
    {
      onSuccess: (response, variables) => {
        if (response.status === HTTP_OK) {
          QueryClient.invalidateQueries([USE_DETAIL_LEADER_ORDER], { refetchActive: true });
          setOpenPopupApproveCandidate(false);
          if (variables === STATUS_CANDIDATE.WAIT_CANDIDATE_RESPONSE)
            history.push({
              pathname: `/admin/candidate-detail/${candidateConfirmed.id}/apply-detail/${candidateConfirmed.applyId}`,
              state: {
                id: candidateConfirmed.applyId,
                apply_id: candidateConfirmed.applyId,
                job_id: candidateConfirmed.jobId,
                candidate_id: candidateConfirmed.id,
              },
            });
        }
      },
      onError: () => {},
    },
  );

  const FooterPopupApproveCandidate = () => {
    if (isLoadingChangeStatus)
      return (
        <div className={styles.loadingIcon}>
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <Button
          className={classNames(styles.popupBtn, styles.popupBtn__cancel)}
          onClick={() => setOpenPopupApproveCandidate(false)}
        >
          {t('modalOrder.cancel')}
        </Button>
        <Button
          className={classNames(styles.popupBtn, styles.popupBtn__cancel)}
          onClick={() => changeStatusCandidate(STATUS_CANDIDATE.CANCEL_CV)}
        >
          {t('modalOrder.notApproveCv')}
        </Button>
        <Button
          className={classNames(styles.popupBtn, styles.popupBtn__ok)}
          onClick={() => changeStatusCandidate(STATUS_CANDIDATE.WAIT_CANDIDATE_RESPONSE)}
        >
          {t('modalOrder.approveCVAndViewAssess')}
        </Button>
      </>
    );
  };

  return (
    <div>
      <Popup
        active={openApproveCandidate}
        handleCancel={() => setOpenPopupApproveCandidate(false)}
        messages={t('modalOrder.approveCVAndViewAssessCandidate')}
        footer={<FooterPopupApproveCandidate />}
        className={styles.popupApproveCandidate}
      />
      {openModalAssignUser && (
        <LeaderAssignToAnother
          openModal={openModalAssignUser}
          setOpenModal={setOpenModalAssignUser}
          detailOrder={dataDetailOrder}
        />
      )}
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={`${styles.modalCommon} modal-dialog-centered manager-order-leader ${className}`}
      >
        <div className={stylesModalCommon.headModal}>
          <div className="modal-title-head">{title}</div>
          <div className={stylesModalCommon.boxClose} onClick={toggle}>
            <div className={stylesModalCommon.iconClose}>
              <IoMdClose />
            </div>
          </div>
        </div>
        <ModalBody className={styles.modalBody}>
          <Grid container className="px-4 pb-4 pt-3">
            <Grid item xs={8.5} className={styles.mainContent}>
              <div className="header-content-left d-flex align-items-center justify-content-between">
                <span className={styles.orderName}>{dataDetailOrder?.name}</span>
                {dataDetailOrder?.status === WAIT_BOD_ACCEPT && (
                  <EditLayout permissionName={ORDER_HR_PERMISSION}>
                    <Button className={styles.cancelButton} onClick={handleOpenModalCancel}>
                      {t('modalOrder.cancelOrder')}
                    </Button>
                  </EditLayout>
                )}
                {(dataDetailOrder?.status === PROCESSING || dataDetailOrder?.status === DEADLINE_LATE) &&
                  dataDetailOrder?.total_recruited >= 1 && (
                    <EditLayout permissionName={ORDER_HR_PERMISSION}>
                      <Button className={styles.completeButton} onClick={handleOpenModalComplete}>
                        {t('modalOrder.completed')}
                      </Button>
                    </EditLayout>
                  )}
              </div>
              <div className="divider w-100" />
              <div className="title-mid-content">
                <span className={styles.mainInfo}>{t('modalOrder.mainInfo')}</span>
              </div>
              <Grid container className="mid-content">
                <Grid item xs={3} className={styles.titleItem}>
                  {t('modalOrder.createDate')}
                </Grid>
                <Grid item xs={9} className={styles.contentItem}>
                  {customDate(dataDetailOrder?.created_at, 'HH:mm DD/MM/YYYY')}
                </Grid>
                <Grid item xs={3} className={styles.titleItem}>
                  {t('modalOrder.endDate')}
                </Grid>
                <Grid item xs={9} className={styles.contentItem}>
                  {customDate(dataDetailOrder?.deadline, 'DD/MM/YYYY')}
                </Grid>
                <Grid item xs={3} className={styles.titleItem}>
                  {t('modalOrder.status')}
                </Grid>
                <Grid item xs={9} className={`${getClassStatus(dataDetailOrder?.status)} ${styles.contentItem}`}>
                  {status.find((el) => el.value === Number(dataDetailOrder?.status))?.name}
                </Grid>
                <Grid item xs={3} className={styles.titleItem}>
                  {t('modalOrder.position')}
                </Grid>
                <Grid item xs={9} className={styles.contentItem}>
                  {dataDetailOrder?.position_id
                    ? getPositionName(dataDetailOrder?.position_id, dataPosition)
                    : t('common.notDataContract')}
                </Grid>
                <Grid item xs={3} className={styles.titleItem}>
                  {t('common.isFreelancer')}
                </Grid>
                <Grid item xs={9} className={styles.contentItem}>
                  {getTextFreelancer(dataDetailOrder?.is_freelancer, t('common.yesEN'), t('common.noEN'))}
                </Grid>
                {dataDetailOrder?.skill_id && (
                  <>
                    <Grid item xs={3} className={styles.titleItem}>
                      {t('modalOrder.skill')}
                    </Grid>
                    <Grid item xs={9} className={styles.contentItem}>
                      {getSkillName(dataDetailOrder?.skill_id, dataSkill)}
                    </Grid>
                  </>
                )}
                <Grid item xs={3} className={styles.titleItem}>
                  {t('modalOrder.level')}
                </Grid>
                <Grid item xs={9} className={styles.contentItem}>
                  {dataDetailOrder?.level_id
                    ? dataDetailOrder.level_id.map((item) => getLevelName(item, dataLevel)).join(', ')
                    : t('common.notDataContract')}
                </Grid>
                <Grid item xs={3} className={styles.titleItem}>
                  {t('modalOrder.salary')}
                </Grid>
                <Grid item xs={9} className={styles.contentItem}>
                  {dataDetailOrder && formatSalary(dataDetailOrder.max_salary)}
                </Grid>
                <Grid item xs={3} className={styles.titleItem}>
                  {t('modalOrder.totalRecruit')}
                </Grid>
                <Grid item xs={9} className={styles.contentItem}>
                  {(dataDetailOrder?.total_recruited ? dataDetailOrder?.total_recruited : 0) +
                    '/' +
                    dataDetailOrder?.target}
                </Grid>
                <Grid item xs={3} className={styles.titleItem}>
                  {t('modalOrder.userAssignByLeader')}
                </Grid>
                <Tooltip
                  title={
                    dataDetailOrder?.user_assigned_infos?.map((item) => item?.name).join(', ').length >
                      LENGTH_NAME_ASSIGNER && (
                      <div className="tooltip-assigner">
                        {dataDetailOrder?.user_assigned_infos
                          ? dataDetailOrder?.user_assigned_infos.map((item) => item?.name).join(', ')
                          : t('common.notDataContract')}
                      </div>
                    )
                  }
                  arrow={
                    dataDetailOrder?.user_assigned_infos?.map((item) => item?.name).join(', ').length >
                    LENGTH_NAME_ASSIGNER
                  }
                  placement="bottom-start"
                  PopperProps={{
                    sx: {
                      maxWidth: 700,
                      '& 	.MuiTooltip-tooltipArrow': {
                        top: '-30px !important',
                        '&::before': {
                          backgroundColor: 'blue',
                        },
                      },
                      '& 	.MuiTooltip-tooltip': {
                        marginTop: '20px !important',
                      },
                    },
                  }}
                >
                  <Grid
                    item
                    xs={8.5}
                    className={classNames(styles.contentItem, styles.titleAssignerName, styles.pointerAssigner)}
                  >
                    {dataDetailOrder?.user_assigned_infos
                      ? dataDetailOrder?.user_assigned_infos.map((item) => item?.name).join(', ')
                      : t('common.notDataContract')}
                  </Grid>
                </Tooltip>
                {dataDetailOrder?.owner_info.user_id === dataInfoUser.user_id && (
                  <Grid item xs={0.5}>
                    <EditLayout permissionName={ORDER_HR_PERMISSION}>
                      <div className="circle-assign-button color-button" onClick={() => setOpenModalAssignUser(true)}>
                        <HiPencil />
                      </div>
                    </EditLayout>
                  </Grid>
                )}
                <Grid item xs={3} className={styles.titleItem}>
                  {t('modalOrder.note')}
                </Grid>
                <Grid item xs={9} className={styles.contentItem}>
                  {dataDetailOrder?.description ? (
                    <div
                      className="detailOrder-leader-text-note"
                      dangerouslySetInnerHTML={{ __html: dataDetailOrder?.description }}
                    />
                  ) : (
                    t('common.notDataContract')
                  )}
                </Grid>
              </Grid>
              <div className={styles.candidateInfo}>
                <span>{t('modalOrder.infoCandidate')}</span>
              </div>
              <div className="">
                <Paper>
                  <TableContainer className={styles.tableContainer}>
                    <Table aria-label="sticky table" stickyHeader>
                      <TableHead className="asset-header">
                        <TableRow className="asset-header-row headTable">
                          <TableCell>{t('modalOrder.nameCandidate')}</TableCell>
                          <TableCell>{t('modalOrder.gender')}</TableCell>
                          <TableCell>{t('modalOrder.status')}</TableCell>
                          <TableCell>{t('modalOrder.division')}</TableCell>
                          <TableCell>{t('modalOrder.HRCharge')}</TableCell>
                          <TableCell>{t('modalOrder.linkUV')}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isLoading && (
                          <TableRow>
                            <TableCell colSpan={8}>
                              <Loading addClass="mt-3 mb-3" />
                            </TableCell>
                          </TableRow>
                        )}
                        {!isLoading &&
                          Array.isArray(dataDetailOrder?.list_applies) &&
                          dataDetailOrder?.list_applies.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell className="table-content name-create">{item.full_name}</TableCell>
                                <TableCell className="table-content same-color">
                                  {item.sex === MALE ? t('modalOrder.male') : t('modalOrder.female')}
                                </TableCell>
                                <Tooltip
                                  title={
                                    isAssigner && (
                                      <DetailStatusCard
                                        list={formatFieldKeyList(item?.list_action_leader)}
                                        fieldKey={'status'}
                                        fieldKeyClass={'status_id'}
                                      />
                                    )
                                  }
                                  arrow
                                >
                                  <TableCell className={`${getColorStatusUV(item?.status)}`}>
                                    {!item.is_leader_interrupt && getName(LIST_STATUS, item?.status)}
                                  </TableCell>
                                </Tooltip>
                                <TableCell className="table-content">
                                  {getDivisionName(item?.division_id, uListDivision)}
                                </TableCell>
                                <TableCell className="table-content same-color">{item.hr_assigned.name}</TableCell>
                                <TableCell className="table-content link-detail-UV">
                                  <Link to={`/admin/candidate-detail/${item.candidate_id}`} target="_blank">
                                    Xem chi tiết
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  {item.is_leader_interrupt && (
                                    <EditLayout permissionName={[ORDER_HR_PERMISSION, CANDIDATE_PERMISSION]}>
                                      <Button
                                        className={styles.confirmBtn}
                                        onClick={() =>
                                          handleClickButtonConfirmInterrupt(
                                            item.candidate_id,
                                            item.apply_id,
                                            dataDetailOrder?.job_id,
                                          )
                                        }
                                      >
                                        {t('common.confirm')}
                                      </Button>
                                    </EditLayout>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        {dataDetailOrder?.list_applies.length === 0 && (
                          <tr>
                            <td colSpan={8} className="error-no-data">
                              {t('common.notData')}
                            </td>
                          </tr>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </Grid>
            <Grid item xs={3.5} className={styles.subContent}>
              <div className={styles.subContentTop}>
                <span className={styles.subTitle}>{t('modalOrder.creator')}</span>
                <InfoCard
                  avatar={dataDetailOrder?.owner_info?.avatar}
                  name={dataDetailOrder?.owner_info?.name}
                  position={dataDetailOrder?.owner_info?.position_name}
                  division={dataDetailOrder?.owner_info?.division_name}
                />
                <span className={styles.subTitle}>{t('modalOrder.approver')}</span>
                {dataDetailOrder?.assigned_info ? (
                  <InfoCard
                    avatar={dataDetailOrder.assigned_info?.avatar}
                    name={dataDetailOrder.assigned_info?.name}
                    position={dataDetailOrder.assigned_info?.position_name}
                    division={dataDetailOrder.assigned_info?.division_name}
                  />
                ) : (
                  <div className={styles.spaceDiv}></div>
                )}
              </div>
              <div className="divider w-100" />
              <div>
                <span className={styles.subTitle}>{t('modalOrder.timeline')}</span>
                <div className={styles.timeLine}>
                  <Timeline>
                    {dataDetailOrder?.list_status.map((item, index) => {
                      return (
                        <TimelineItem key={index}>
                          <TimelineOppositeContent sx={{ display: 'none' }} />
                          <TimelineSeparator>
                            <TimelineDot className={styles.timelineDot}>
                              <img src={iconCheckCircle} alt="" />
                            </TimelineDot>
                            <TimelineConnector className={styles.connector} />
                          </TimelineSeparator>
                          <TimelineContent className={styles.timelineContent}>
                            <span className={styles.timeCreate}>{customDate(item.created_at, 'HH:mm DD/MM/YYYY')}</span>
                            <span className={styles.action}>
                              {(item?.full_name ? item.full_name + ' - ' : '') +
                                LIST_STATUS.find((el) => el.id === Number(item.status))?.name}
                            </span>
                            {item?.note && <span className={styles.noteReason}>{item.note}</span>}
                            <InfoCard
                              avatar={
                                item?.user_assigned_info ? item.user_assigned_info?.avatar : item.user_info?.avatar
                              }
                              name={item?.user_assigned_info ? item.user_assigned_info?.name : item.user_info?.name}
                              position={
                                item?.user_assigned_info
                                  ? item.user_assigned_info?.position_name
                                  : item.user_info?.position_name
                              }
                              division={
                                item?.user_assigned_info
                                  ? item.user_assigned_info?.division_name
                                  : item.user_info?.division_name
                              }
                            />
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                </div>
              </div>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalOrder;
