import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import $ from 'jquery';
import _groupBy from 'lodash/groupBy';

import { MenuItem } from '@mui/material';

import './styles.scss';
import Validate from './Validate';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/ModalDoubleCol';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import CustomInputYupNumber from 'components/InputYupAndMui/CusromInputNumber';
import { HTTP_OK } from 'constants/StatusCode.js';
import AlertComponent from 'components/Alert/index.js';
import SelectField from 'components/ReactForm/SelectField';
import { MAX_LENGTH_30, CODE_DATA_EXISTS } from 'constants/index.js';
import { addOrUpdateLStandardIndex } from 'api/masterData';
import {
  ONE_POINT,
  TWO_POINT,
  THREE_POINT,
  FOUR_POINT,
  FIVE_POINT,
  SIX_POINT,
  SEVEN_POINT,
  EIGHT_POINT,
  NINE_POINT,
  TEN_POINT,
  USE_LIST_STANDARD_INDEX,
} from 'constants/MasterData';
import { useListSkillAddStandardIndex } from 'hook/useMasterData';
import { errorSubmit } from 'helpers';

const DATA_STANDARD_INDEX = [
  {
    id: 1,
    name: ONE_POINT,
  },
  {
    id: 2,
    name: TWO_POINT,
  },
  {
    id: 3,
    name: THREE_POINT,
  },
  {
    id: 4,
    name: FOUR_POINT,
  },
  {
    id: 5,
    name: FIVE_POINT,
  },
  {
    id: 6,
    name: SIX_POINT,
  },
  {
    id: 7,
    name: SEVEN_POINT,
  },
  {
    id: 8,
    name: EIGHT_POINT,
  },
  {
    id: 9,
    name: NINE_POINT,
  },
  {
    id: 10,
    name: TEN_POINT,
  },
];

const CreateOrUpdateModal = (props) => {
  const { isShowModal, setShowModal, dataPosition, dataLevel, dataEdit, setIsNewOrUpdateData } = props;
  const [formData, setFormData] = useState(
    dataEdit
      ? {
          id: dataEdit?.id,
          list_stats: JSON.parse(dataEdit?.list_stats),
          position_id: dataEdit?.position_id,
          skill_id: dataEdit?.skill_id,
          level_id: dataEdit?.level_id,
        }
      : {
          list_stats: [{ name: '', avg_point: '', coefficient: '' }],
          position_id: '',
          skill_id: '',
          level_id: '',
        },
  );
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(Validate()),
    mode: 'onChange',
    defaultValues: formData,
  });

  const queryClient = useQueryClient();
  const [stateButton, setStateButton] = useState(false);

  const [avgPoint, setAvgPoint] = useState(null);
  const [isShowAlert, setShowAlert] = useState(false);
  const [isShowAlertError, setShowAlertError] = useState(false);
  const [message, setMessage] = useState('');
  const { data: uDataSkill } = useListSkillAddStandardIndex();

  const listSkills = _groupBy(uDataSkill, 'position_id');

  useEffect(() => {
    if (isShowModal === false) {
      clearErrors();
      setValue('list_stats', [{ name: '', avg_point: '', coefficient: '' }]);
      setValue('position_id', '');
      setValue('skill_id', '');
      setValue('level_id', '');
    } else {
      if (dataEdit) {
        let data = {
          id: dataEdit.id,
          list_stats: JSON.parse(dataEdit?.list_stats),
          position_id: dataEdit?.position_id,
          skill_id: dataEdit?.skill_id,
          level_id: dataEdit.level_id,
        };
        setFormData({ ...data });
      } else {
        let data = {
          list_stats: [{ name: '', avg_point: '', coefficient: '' }],
          position_id: '',
          skill_id: '',
          level_id: '',
        };
        setFormData({ ...data });
      }
    }
    // eslint-disable-next-line
  }, [isShowModal]);

  const avgIndex = (data) => {
    let total = 0;
    let totalCoefficient = 0;

    data?.forEach((item) => {
      if (item.avg_point !== null) {
        total += Number(item.avg_point) * Number(item.coefficient);
        totalCoefficient += Number(item.coefficient);
      }
    });
    let avgIndex = total / totalCoefficient;

    setAvgPoint(avgIndex ? avgIndex.toFixed(1) + ' ' + t('masterData.StandardIndex.point') : 'N/A');
  };
  const {
    fields: list_stats,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'list_stats',
  });
  const onclickAddIndex = () => {
    append({ name: '', avg_point: '', coefficient: '' });
    $('#list-standard-index').scrollTop($('#list-standard-index')[0].scrollHeight);
  };
  const onclickDeleteIndex = (index) => {
    if (list_stats.length > 1) remove(index);
  };

  const handleSubmitForm = async (dataSubmit) => {
    setStateButton(true);
    let response = {};
    let dataListStats = [];
    dataSubmit?.list_stats?.forEach((item) => {
      dataListStats.push({ ...item, avg_point: Number(item.avg_point), coefficient: Number(item.coefficient) });
    });
    response = await addOrUpdateLStandardIndex({
      list_stats: dataListStats,
      position_id: Number(dataSubmit?.position_id),
      skill_id: Number(dataSubmit?.skill_id),
      level_id: Number(dataSubmit.level_id),
      id: Number(dataEdit?.id),
    });

    if (response?.data?.status === HTTP_OK) {
      if (errorSubmit(response?.data?.code)) {
        if (response?.data?.code === CODE_DATA_EXISTS) {
          setMessage(t('masterData.StandardIndex.thereIsAStatCreatedThatCoincidesWithThisStat'));
        } else {
          setMessage(t(errorSubmit(response?.data?.code)));
        }
        setShowAlertError(true);
        setStateButton(false);
        return;
      } else {
        !formData.id
          ? setMessage(t('masterData.StandardIndex.createIndexSuccessfully'))
          : setMessage(t('masterData.StandardIndex.updateIndexSuccessfully'));
      }
      setStateButton(false);
      setShowAlert(true);
      setIsNewOrUpdateData(true);
      clearErrors();
      await queryClient.invalidateQueries(USE_LIST_STANDARD_INDEX);
    } else {
      if (errorSubmit(response?.data?.code)) {
        if (response?.data?.code === CODE_DATA_EXISTS) {
          setMessage(t('masterData.StandardIndex.thereIsAStatCreatedThatCoincidesWithThisStat'));
        } else {
          setMessage(t(errorSubmit(response?.data?.code)));
        }
        setShowAlertError(true);
        setStateButton(false);
      }
    }
    setStateButton(false);
  };

  // Watch value type timekeeping
  const watchTypePositionIdValue = useWatch({
    control,
    name: 'position_id',
  });
  const skillId = useWatch({
    control,
    name: 'skill_id',
  });
  const levelId = useWatch({
    control,
    name: 'level_id',
  });

  // Watch value type timekeeping
  const watchStandardIndexValue = useWatch({
    control,
    name: 'list_stats',
  });

  useEffect(() => {
    if (watchStandardIndexValue) {
      avgIndex(watchStandardIndexValue);
    }
    // eslint-disable-next-line
  }, [watchStandardIndexValue]);

  useEffect(() => {
    setValue(
      'is_skill',
      uDataSkill?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))?.length > 0
        ? true
        : false,
    );
    if (!watchTypePositionIdValue || !listSkills[watchTypePositionIdValue]) {
      setValue('skill_id', null);
    } else if (formData?.skill_id && watchTypePositionIdValue === formData.position_id) {
      let skill = listSkills[watchTypePositionIdValue].find((item) => item.id === formData?.skill_id);
      if (skill) setValue('skill_id', skill.id);
      else setValue('skill_id', null);
    } else {
      setValue('skill_id', listSkills[watchTypePositionIdValue][0].id);
    }
    // eslint-disable-next-line
  }, [watchTypePositionIdValue]);

  const newSkill = useMemo(
    () => uDataSkill?.filter((item) => item.position_id === watchTypePositionIdValue),
    [uDataSkill, watchTypePositionIdValue],
  );

  const conditionEnable = () => {
    const stats = watchStandardIndexValue?.[0];
    return (
      watchTypePositionIdValue === '' ||
      levelId === '' ||
      (newSkill?.length > 0 && skillId === '') ||
      stats?.name === undefined ||
      stats?.avg_point === '' ||
      stats?.coefficient === ''
    );
  };
  return (
    <>
      <ModalComponent
        toggle={() => setShowModal(!isShowModal)}
        isShowModal={isShowModal}
        title={
          dataEdit?.id
            ? t('masterData.StandardIndex.updateStandardIndex')
            : t('masterData.StandardIndex.createStandardIndex')
        }
        classNameAdd="modal-create-update-standard-index"
      >
        <form className="mt-3" onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="text-modal">{t('masterData.StandardIndex.infomation')}</div>
          <Row className="mt-4">
            <Col className="md-6">
              <div className="fieldset-group">
                <SelectField
                  name="position_id"
                  label={t('masterData.StandardIndex.position')}
                  error={errors?.position_id?.message}
                  defaultValue={formData?.position_id}
                  control={control}
                  required
                  setValue={setValue}
                  useWatch={useWatch}
                  register={register}
                  isShowModal={isShowModal}
                  isRequired
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: {
                          xs: 350,
                        },
                      },
                    },
                  }}
                >
                  {dataPosition &&
                    dataPosition.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectField>
              </div>
            </Col>
            <Col className="md-6">
              <div className="fieldset-group">
                <SelectField
                  name="skill_id"
                  label={t('masterData.StandardIndex.skill')}
                  error={errors?.skill_id?.message}
                  defaultValue={formData?.skill_id}
                  control={control}
                  setValue={setValue}
                  useWatch={useWatch}
                  register={register}
                  isShowModal={isShowModal}
                  disabled={
                    watchTypePositionIdValue &&
                    uDataSkill?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))?.length > 0
                      ? false
                      : true
                  }
                  isRequired={
                    watchTypePositionIdValue &&
                    uDataSkill?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))?.length > 0
                      ? true
                      : false
                  }
                >
                  {uDataSkill &&
                    uDataSkill
                      ?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))
                      ?.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                </SelectField>
              </div>
            </Col>
          </Row>
          <Row className="mt-4 pb-4 top-list-index">
            <Col className="md-6">
              <div className="fieldset-group">
                <SelectField
                  name="level_id"
                  label={t('masterData.StandardIndex.level')}
                  error={errors?.level_id?.message}
                  defaultValue={formData?.level_id}
                  control={control}
                  required
                  setValue={setValue}
                  useWatch={useWatch}
                  register={register}
                  isShowModal={isShowModal}
                  isRequired
                >
                  {dataLevel &&
                    dataLevel.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </SelectField>
              </div>
            </Col>
            <Col className="md-6">
              <div className="fieldset-group avg-point">
                <div className="avg-point-title">{t('masterData.StandardIndex.normalPoint')}</div>
                <div className="avg-point-content">{avgPoint}</div>
              </div>
            </Col>
          </Row>
          <div className="title-list-index pt-4">
            <div className="text-modal">{t('masterData.StandardIndex.infomationIndex')}</div>
            &nbsp;
            <div className="text-modal-error">{'(' + t('masterData.StandardIndex.maxTenIndex') + ')'}</div>
          </div>
          <div id="list-standard-index" className="list-standard-index">
            {list_stats?.map((i, ind) => {
              const fieldName = `list_stats[${ind}]`;
              return (
                <Row className="mt-4 row-standard-index" key={i?.id}>
                  <Col md={4}>
                    <div className="fieldset-group">
                      <CustomInputYup
                        defaultValue={i?.name}
                        label={t('masterData.StandardIndex.index')}
                        name={`${fieldName}.name`}
                        control={control}
                        register={register}
                        errors={errors}
                        addErrors={errors?.list_stats?.[ind]?.name?.message}
                        useWatch={useWatch}
                        required
                        maxLength={MAX_LENGTH_30}
                      />
                    </div>
                  </Col>
                  <Col md={4} className="md-5">
                    <div className="fieldset-group">
                      <SelectField
                        name={`${fieldName}.avg_point`}
                        label={t('masterData.StandardIndex.standardPoint')}
                        defaultValue={i?.avg_point ? Number(i?.avg_point) : ''}
                        control={control}
                        required
                        setValue={setValue}
                        useWatch={useWatch}
                        register={register}
                        error={errors?.list_stats?.[ind]?.avg_point?.message}
                        isShowModal={isShowModal}
                        isRequired
                      >
                        {DATA_STANDARD_INDEX &&
                          DATA_STANDARD_INDEX.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </SelectField>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="fieldset-group">
                      <CustomInputYupNumber
                        defaultValue={i?.coefficient}
                        label={t('masterData.StandardIndex.coefficient')}
                        name={`${fieldName}.coefficient`}
                        control={control}
                        register={register}
                        errors={errors}
                        addErrors={errors?.list_stats?.[ind]?.coefficient?.message}
                        useWatch={useWatch}
                        required
                        setValue={setValue}
                        maxLength={MAX_LENGTH_30}
                        decimalSeparator={true}
                        isInteger
                      />
                    </div>
                  </Col>
                  <Col md={1}>
                    <div className="delete-button" onClick={onclickDeleteIndex.bind(this, ind)}>
                      <MdDelete />
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
          {list_stats?.length < 10 ? (
            <Row>
              <div className="add-master-stats" onClick={onclickAddIndex}>
                <IoIosAdd />
              </div>
              <div className="text-content-modal">{t('masterData.StandardIndex.addIndex')}</div>
            </Row>
          ) : (
            ''
          )}
          <Row className="mt-4">
            <Col className="md-6">
              <ButtonComponent
                text={t('managerDecentralization.cancel')}
                addClass="button-cancel btn-bg-yellow2"
                handleClick={() => {
                  setShowModal(false);
                }}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={t('common.save')}
                addClass="button-submit btn-bg-yellow1"
                type="submit"
                handleClick={handleSubmit(handleSubmitForm)}
                hasDisabled={conditionEnable()}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </form>
      </ModalComponent>
      <AlertComponent
        toggle={() => {
          setShowAlert(!isShowAlert);
          setShowModal(false);
        }}
        isShowAlert={isShowAlert}
      >
        {message}
      </AlertComponent>
      <AlertComponent
        toggle={() => {
          setShowAlertError(!isShowAlertError);
        }}
        isShowAlert={isShowAlertError}
      >
        {message}
      </AlertComponent>
    </>
  );
};

export default CreateOrUpdateModal;
