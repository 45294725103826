import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { styled } from '@mui/system';

import * as COLOR from 'constants/ColorForm.js';
import styles from './style.module.scss';

const SelectFieldComponent = (props) => {
  const {
    onHandleChangeSelect,
    name,
    valueDefault,
    error,
    label,
    children,
    addClass,
    isRequired,
    sizeSelect,
    disabled,
    field = {},
    ..._props
  } = props;

  const [valueSelect, setValueSelect] = useState(valueDefault);

  useEffect(() => {
    setValueSelect(valueDefault);
  }, [valueDefault]);

  const handleChange = (event) => {
    onHandleChangeSelect(event);
  };

  const LabelSelectFieldCss = styled(InputLabel)({
    '&': {
      fontSize: '14px',
    },
    '&:hover': {
      color: COLOR.GREEN,
    },
    '&.Mui-focused': {
      color: `${COLOR.GREEN} !important`,
    },
  });

  const LabelSelectFieldNoneCss = styled(InputLabel)({
    '&': {
      display: 'none',
    },
  });

  return (
    <>
      <div className={classNames(`${styles.selectComponent} ${sizeSelect ? styles.sizeSelect : ''} ${addClass}`)}>
        {label ? (
          <FormControl fullWidth className={styles.selectLabel}>
            <LabelSelectFieldCss
              id={'demo-simple-select-label' + name}
              size={sizeSelect ? sizeSelect : ''}
              error={error ? true : false}
            >
              {label} {isRequired ? <span className={styles.supRequired}> *</span> : ''}
            </LabelSelectFieldCss>
            <Select
              value={valueSelect}
              labelId={'demo-simple-select-label' + name}
              onChange={handleChange}
              label={label}
              name={name}
              error={error ? true : false}
              disabled={disabled}
              {...field}
              {..._props}
            >
              {children}
            </Select>
          </FormControl>
        ) : (
          <FormControl fullWidth className={styles.selectNoneLabel}>
            <LabelSelectFieldNoneCss
              id={'demo-simple-select-label' + name}
              size={sizeSelect ? sizeSelect : ''}
              error={error ? true : false}
            ></LabelSelectFieldNoneCss>
            <Select
              value={valueSelect}
              labelId={'demo-simple-select-label' + name}
              onChange={handleChange}
              label="."
              name={name}
              error={error ? true : false}
              disabled={disabled}
              {...field}
              {..._props}
            >
              {children}
            </Select>
          </FormControl>
        )}
      </div>
      <p className="error-input error">{error}</p>
    </>
  );
};

export default SelectFieldComponent;
