import { FormControlLabel, Radio, RadioGroup, Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { WHEAT } from 'constants/ColorForm';
import { BG_POSITION } from 'constants/ColorMap';

function CustomRadioInput({ control, radioList, defaultValue, name, setValue }) {
  return (
    <Controller
      render={({ field }) => (
        <RadioGroup {...field}>
          <Grid container spacing={2}>
            {radioList.map((item, index) => (
              <Grid item md={index % 2 ? 5.8 : 6.2}>
                <FormControlLabel
                  key={index}
                  value={item.value}
                  onChange={(e) => setValue(name, e.target.value)}
                  control={
                    <Radio
                      disabled={item?.disabled}
                      sx={{
                        color: WHEAT,
                        '&.Mui-checked': {
                          color: BG_POSITION[0],
                        },
                      }}
                    />
                  }
                  label={item.labelName}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      )}
      name={name}
      control={control}
      defaultValue={`${defaultValue}`}
    />
  );
}

export default CustomRadioInput;
