import NumberFormat from 'react-number-format';

export function FormatNumber({ value, decimalScale, noDecimalSeparator }) {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      renderText={(formattedValue) => <div>{formattedValue}</div>}
      decimalScale={decimalScale ? decimalScale : null}
      decimalSeparator={noDecimalSeparator ? null : '.'}
    />
  );
}

export const formatNumberCurrency = (value) => {
  if (!value) return value;

  let _value = value.toString().replace(/,/g, '');
  // _value = value.toString().replace(/\./g, '');
  return new Intl.NumberFormat('vi-VN').format(_value).replace(/\./g, '.');
};

export const formatNumberCurrencyUnit = (value) => {
  if (!value) return value;
  let _value = value.toString().replace(/,/g, '');
  return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'VND' }).format(_value).replace(/\./g, '.');
};

export const formatCurrencyNumber = (value) => {
  if (!value) return value;
  return value.toString().replace(/\./g, '');
};

export const formatNumber = (value) => {
  if (!value) return value;

  let _value = value.toString().replace(/,/g, '');
  return new Intl.NumberFormat('vi-VN').format(_value).replace(/\./g, ',');
};

export const convertNumberToMillion = (value) => {
  return Math.round((value / 1000000) * 100) / 100 + 'M';
};

export const formatSalary = (value) => {
  if (!value) return;
  return Number(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatNullToZero = (value) => {
  if (!value) {
    return 0;
  }
  return value;
};

export const formatNumberOne = (number) => {
  return number < 10 ? `0${number}` : number;
};

export const formatNumberOfVi = (value) => {
  if (!value) return;
  return new Intl.NumberFormat('vi').format(+value);
};
