/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import moment from 'moment';

import { ButtonBase, Dialog, DialogActions, DialogContent, DialogContentText, Grid, InputBase } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import AddIcon from '@mui/icons-material/Add';

import './styles.scss';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20 } from 'constants/index.js';
import { useListSkill, useListPosition, useListLevel } from 'hook/useMasterData';
import ButtonComponent from 'components/Buttons';
import * as Constants from 'constants/index.js';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import Loading from 'components/Loading';
import { displayLabelTablePagination } from 'helpers/table';
import { getPositionName, getSkillName } from '../../../helpers/masterData';
import { useGetListQuestionInterview } from 'hook/useManagerQuestionInterview';
import { QUESTION_FORMDATA, QUESTION_INTERVIEW_ID } from '../../../constants/ManagerQuestionInterview';
import { INTERVIEW_QUESTIONS_PERMISSION } from 'constants/permission';

import iconLoading from 'assets/img/icons/loading.png';
import PreviewQuestionInterview from './PreviewQuestionInterview';
import { permissions } from 'components/Permission/Index';
import { useInforAuth } from 'hook/useAuth';
import EditLayout from 'components/PermissionPage/EditLayout';

const ListManagerQuestionInterview = ({ createQuestion, setIdQuestion }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(DEFAULT_PAGE_MUI);
  const query = useQueryClient();
  const [filter, setFilter] = useState({
    level_id: null,
    position_id: null,
    skill_id: null,
    page: DEFAULT_PAGE_MUI,
    limit: DEFAULT_LIMIT_20,
  });
  const mapLevel = useRef({});
  const mapSkill = useRef({});
  const mapPosition = useRef({});
  const questionDataPreview = query.getQueryDefaults(QUESTION_FORMDATA);
  const [previewQuestion, setPreviewQuestion] = useState(false);
  const [formData, setFormData] = useState(questionDataPreview || {});
  const questionInterviewId = useRef(query.getQueryDefaults(QUESTION_INTERVIEW_ID));
  const {
    data: listManagerQuestionInterview,
    isLoading: isLoadingManagerQuestionInterview,
    total_questions,
  } = useGetListQuestionInterview({
    ...filter,
    page: filter.page + 1,
  });
  const [isModals, setIsModals] = useState({ position: false, skill: false, level: false });

  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });
  const { data: uDataLevel } = useListLevel({ key_word: '' });

  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [deleteQuestionSuccess, setDeleteQuestionSuccess] = useState(false);

  const { permission } = useInforAuth();
  const { notAllowPermissionUpdate, notAllowPermissionDelete } = useMemo(
    () => permissions(permission, 'interview_questions_permission'),
    [permission],
  );

  useEffect(() => {
    if (uDataSkill) {
      const skill = uDataSkill.map((item) => {
        mapSkill.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataSkill([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...skill,
      ]);
    }
  }, [uDataSkill, t]);

  useEffect(() => {
    if (uDataLevel) {
      for (const item of uDataLevel) {
        mapLevel.current[item.id] = item.name;
      }
    }
  }, [uDataLevel]);

  useEffect(() => {
    if (questionInterviewId.current && 'id' in formData) {
      setPreviewQuestion(true);
    }
  }, [questionInterviewId, formData]);

  useEffect(() => {
    if (uDataPosition) {
      const position = uDataPosition.map((item) => {
        mapPosition.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataPosition([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...position,
      ]);
    }
  }, [uDataPosition, t]);

  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${0}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  const changeSelectPosition = (item) => {
    setDataPosition(function (listStatus) {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  const changeSelectSkill = (item) => {
    setDataSkill(function (listStatus) {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  function handleCheck(items, index) {
    items.forEach(function (item, i) {
      if (index === i) {
        item.status = !item.status;
      } else {
        item.status = false;
      }
    });

    return [...items];
  }

  const getCreatedAt = (item) => {
    return moment(item.created_at).format('DD/MM/YYYY');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, limit: +event.target.value });
    setPage(DEFAULT_PAGE_MUI);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const openModalDetail = (item) => {
    questionInterviewId.current = item.id;
    const list_questions = JSON.parse(item.list_questions);
    setFormData({
      status: item.status,
      position_id: item.position_id,
      skill_id: item.skill_id,
      list_questions_1: list_questions.filter((item) => item.type === 1),
      list_questions_2: list_questions.filter((item) => item.type === 2),
      id: item.id,
    });
    setIdQuestion(item.id);
    setPreviewQuestion(true);
  };

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataSkill?.forEach((item) => {
      item.status = false;
    });
    setFilter({
      position_id: null,
      skill_id: null,
      page: DEFAULT_PAGE_MUI,
      limit: DEFAULT_LIMIT_20,
    });
    setDataPosition([...dataPosition]);
    setDataSkill([...dataSkill]);
  };

  return (
    <Grid container gap={2} className="content management-question-interview">
      {previewQuestion && (
        <PreviewQuestionInterview
          previewQuestion={previewQuestion}
          setPreviewQuestion={setPreviewQuestion}
          mapPosition={mapPosition.current}
          mapLevel={mapLevel.current}
          mapSkill={mapSkill.current}
          questionId={questionInterviewId.current}
          formData={formData}
          query={query}
          notAllowPermissionDelete={notAllowPermissionDelete}
          setDeleteQuestionSuccess={setDeleteQuestionSuccess}
        />
      )}
      <Dialog open={deleteQuestionSuccess} onClose={() => setDeleteQuestionSuccess(false)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(`managerQuestionInterview.deleteSuccess`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div class="w-100 d-flex align-items-center justify-content-end">
            <button className="btn-transparent color-yellow fs-18" onClick={() => setDeleteQuestionSuccess(false)}>
              {t('managerQuestionInterview.done')}
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Grid container className="pt-0">
        <Grid item xs sm md lg xl className="header-left color-text">
          {t('managerQuestionInterview.managerQuestionInterview')}
        </Grid>
      </Grid>
      <Grid item flex={10} className="content-component">
        <div className="table-border-show">
          <Paper>
            <TableContainer className="table-list">
              <Table aria-label="sticky table">
                <TableHead className="asset-header">
                  <TableRow className="asset-header-row">
                    <TableCell>{t('managerQuestionInterview.STT')}</TableCell>
                    <TableCell>{t('managerQuestionInterview.position')}</TableCell>
                    <TableCell>{t('managerQuestionInterview.skill')}</TableCell>
                    <TableCell>{t('managerQuestionInterview.quantityQuestion')}</TableCell>
                    <TableCell>{t('managerQuestionInterview.createdAt')}</TableCell>
                    <TableCell>{t('managerQuestionInterview.status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingManagerQuestionInterview && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Loading addClass="mt-3 mb-3" />
                      </TableCell>
                    </TableRow>
                  )}
                  {listManagerQuestionInterview &&
                    listManagerQuestionInterview?.list_data.map((item, index) => {
                      return (
                        <TableRow key={index} onClick={() => openModalDetail(item)}>
                          <TableCell className="table-content id-standard cursor stt">
                            {getSTT(index + filter.page * filter.limit)}
                          </TableCell>
                          <TableCell className="table-content cursor status">
                            {item.position_id
                              ? getPositionName(item?.position_id, dataPosition)
                              : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content cursor status">
                            {item.skill_id ? getSkillName(item?.skill_id, dataSkill) : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content cursor status">
                            {JSON.parse(item?.list_questions || '[]').length + ' câu'}
                          </TableCell>
                          <TableCell className="table-content cursor status">{getCreatedAt(item)}</TableCell>
                          <TableCell className="table-content text-center cursor">
                            <div className="col-action d-flex status">{item.status ? 'Active' : 'Inactive'}</div>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {listManagerQuestionInterview?.total_records === 0 && (
                    <tr>
                      <td colSpan={9} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {listManagerQuestionInterview?.total_records > 0 && (
              <TablePagination
                rowsPerPageOptions={Constants.PER_PAGE_OPTION_20}
                component="div"
                count={listManagerQuestionInterview?.total_records}
                rowsPerPage={filter?.limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
                labelDisplayedRows={displayLabelTablePagination}
              />
            )}
          </Paper>
        </div>
      </Grid>
      <Grid item flex={2} className="filter">
        <EditLayout permissionName={INTERVIEW_QUESTIONS_PERMISSION}>
          <ButtonComponent
            addClass="button-add-menu-right"
            icon={<AddIcon />}
            hasDisabled={notAllowPermissionUpdate}
            text={t('managerQuestionInterview.btnCreateQuestionInterview')}
            handleClick={() => {
              query.setQueryDefaults(QUESTION_INTERVIEW_ID, null);
              createQuestion();
            }}
          />
        </EditLayout>

        <div className="info-menu-right-no-image selected mt-3">
          <div className="info-menu-righ-title">{t('managerQuestionInterview.totalQuestion')}</div>
          <div className="info-menu-righ-total">{total_questions}</div>
        </div>

        <InputBase
          disabled
          type="text"
          name="position_id"
          className="input-select-multi mt-3"
          onClick={() => {
            setIsModals(() => {
              return { position: true };
            });
          }}
          value={
            dataPosition &&
            (!dataPosition[0]?.status
              ? dataPosition
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('managerQuestionInterview.all'))
          }
          placeholder={t('managerQuestionInterview.position')}
        />
        <ModalCommon
          items={dataPosition}
          onSubmit={() => {
            let datas = [];
            dataPosition.forEach((it) => {
              it.status && it.id && datas.push(it.id);
            });
            setPage(Constants.DEFAULT_PAGE_MUI);
            setFilter({
              ...filter,
              position_id: !datas.join() ? null : datas.join(),
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, position: false };
            });
          }}
          onClick={changeSelectPosition}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, position: !prev.position };
            });
            if (filter?.position_id) {
              dataPosition?.forEach((item) => {
                Number(filter?.position_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
              });
              setDataPosition([...dataPosition]);
            } else {
              dataPosition?.forEach((item) => {
                item.status = false;
              });
              setDataPosition([...dataPosition]);
            }
          }}
          modal={isModals.position}
          title={t('managerQuestionInterview.choosePosition')}
          footer={t('managerQuestionInterview.selected')}
        />
        <InputBase
          disabled
          type="text"
          name="skill_id"
          className="input-select-multi mt-3"
          onClick={() => {
            setIsModals(() => {
              return { skill: true };
            });
          }}
          value={
            dataSkill &&
            (!dataSkill[0]?.status
              ? dataSkill
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('managerQuestionInterview.all'))
          }
          placeholder={t('managerQuestionInterview.skill')}
        />
        <ModalCommon
          items={dataSkill}
          onSubmit={() => {
            let datas = [];
            dataSkill.forEach((it) => {
              it.status && it.id && datas.push(it.id);
            });
            setPage(Constants.DEFAULT_PAGE_MUI);
            setFilter({
              ...filter,
              skill_id: !datas.join() ? null : datas.join(),
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, skill: false };
            });
          }}
          onClick={changeSelectSkill}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, skill: !prev.skill };
            });
            if (filter?.skill_id) {
              dataSkill?.forEach((item) => {
                Number(filter?.skill_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
              });
              setDataSkill([...dataSkill]);
            } else {
              dataSkill?.forEach((item) => {
                item.status = false;
              });
              setDataSkill([...dataSkill]);
            }
          }}
          modal={isModals.skill}
          title={t('managerQuestionInterview.chooseSkill')}
          footer={t('managerQuestionInterview.selected')}
        />

        <ButtonBase onClick={handleReset} className="reset mt-3" size="lg">
          <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
          {t('common.reset')}
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

export default React.memo(ListManagerQuestionInterview);
