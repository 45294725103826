import { IS_FREELANCER } from 'constants/manageJob';

export const parseJson = (item) => {
  let data = item ? JSON.parse(item) : null;
  return data;
};

export const getLevelName = (level_id, dataLevel) => {
  if ((level_id, dataLevel)) {
    let level = dataLevel.find((c) => Number(c.id) === Number(level_id));
    if (level?.name) {
      return level?.name;
    }
    return 'N/A';
  }
  return 'N/A';
};

export const getDivisionName = (level_id, dataLevel) => {
  if ((level_id, dataLevel)) {
    let level = dataLevel.find((c) => Number(c.id) === Number(level_id));
    if (level?.name) {
      return level?.name;
    }
    return 'N/A';
  }
  return 'N/A';
};

export const getSkillName = (skill_id, dataSkill) => {
  if ((skill_id, dataSkill)) {
    let skill = dataSkill.find((c) => Number(c.id) === Number(skill_id));
    if (skill?.name) {
      return skill?.name;
    }
    return 'N/A';
  }
  return 'N/A';
};

export const getPositionName = (position_id, dataPosition) => {
  if ((position_id, dataPosition)) {
    let position = dataPosition.find((c) => Number(c.id) === Number(position_id));
    if (position?.name) {
      return position?.name;
    }
    return 'N/A';
  }
  return 'N/A';
};

export const getTypeInterView = (type_interview, dataTypeInterView) => {
  if ((type_interview, dataTypeInterView)) {
    let typeInterview = dataTypeInterView.find((c) => Number(c.id) === Number(type_interview));
    if (typeInterview?.name) {
      return typeInterview?.name;
    }
    return 'N/A';
  }
  return 'N/A';
};

export const getGroupName = (group_id, dataGroup) => {
  if ((group_id, dataGroup)) {
    let group = dataGroup.find((c) => Number(c.id) === Number(group_id));
    if (group?.name) {
      return group.name;
    }
  }
  return 'N/A';
};

export const getContractCategory = (contract_category_id, dataContractCategory) => {
  if ((contract_category_id, dataContractCategory)) {
    let contractCategory = dataContractCategory.find((c) => Number(c.id) === Number(contract_category_id));
    if (contractCategory?.name) {
      return contractCategory.name;
    }
  }
  return 'N/A';
};

export const getTextFreelancer = (status, yes, no) => {
  return status === IS_FREELANCER ? yes : no;
};
