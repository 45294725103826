import $ from 'jquery';
import moment from 'moment';
import { t } from 'i18next';

import avatarDefault from 'assets/img/icons/default-avatar.svg';

import { toasts } from '../index';
import { sendCustom } from 'helpers/axios';
import { getDivisionName } from 'helpers/masterData';

import * as Constants from 'constants/index.js';
import { MALE, FEMALE, STATUS_CANDIDATE } from 'constants/ManagerCandidate';

export const DEFAULT_NOT_REQURED = -1;

export const isNotNullObject = (object) => {
  return Object.entries(object).length !== 0;
};

export const handleOnError = (e) => {
  e.target.src = avatarDefault;
};

export const isEmptyObject = (object) => {
  if (!object) return true;
  return Object.entries(object).toString() === '' || Object.entries(object).toString() === null;
};

export const isNumberAnode = (value) => {
  if (Number(value) !== 'NaN' && !value.includes('.') && value >= 0) return true;
  return false;
};

export const isFloatAnode = (value) => {
  if (/^(\d*)([.]\d{0,1})?$/.test(value)) return true;
  return false;
};

export const isNumberCurrency = (value) => {
  let _value = value.toString().replace(/,/g, '');
  _value = _value.toString().replace(/./g, '');
  if (/^(\d*)([,]\d{0,1})?$/.test(_value)) return true;
  return false;
};

export const getStrError = (value) => {
  if (!value) return;
  if (typeof value === 'string') {
    return value;
  }
  return value[0];
};

export const handleGetFirstCharacter = (str) => {
  let resultStr = '';
  str?.split(' ').map((item) => (resultStr += item.charAt(0).toUpperCase()));
  return resultStr;
};

export const checkValidate = (dataForm, dataValidate) => {
  let dataError = {};

  dataValidate.forEach((elements) => {
    elements.validate.forEach((validate) => {
      // check required
      if (validate?.required) {
        if (!dataForm[elements.name]) {
          dataError[elements.name_error] = validate.message;
          return;
        }
      }

      // check length
      if (validate?.length) {
        let str = dataForm[elements.name];
        if (typeof str === 'string' && str.length > validate.length) {
          dataError[elements.name_error] = validate.message;
          return;
        }
      }
    });
  });

  return dataError;
};

export const checkErrorResponse = (dataResponseMsg, dataValidate) => {
  let dataError = {};
  if (!dataResponseMsg) return dataError;

  dataValidate.forEach((elements) => {
    dataError[elements.name_error] = getStrError(dataResponseMsg?.[elements.name_error]);
  });

  return dataError;
};

export const removeSpecialCharacters = (value) => {
  return value.replace(/[&\\#@^|;_`,+()$~%.'":*?/1234567890<>={}-]/g, '');
};

export const removeSpecial = (value) => {
  if (!value) return value;
  return value.replace(/[&\\#@^|;_`,+()$~%.'":*?/!<>={}-]/g, '');
};

export const handleExportExcel = async (url, params, fileName, messageError) => {
  try {
    const res = await sendCustom({
      url,
      params,
      responseType: 'blob',
    });

    if (res.status === 422) {
      return toasts.error(messageError ? messageError : 'Vui lòng kiểm tra lại điều kiện lọc dữ liệu');
    }

    const blob = new Blob([res.data], { type: res.data.type });
    const link = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('download', fileName);
    a.setAttribute('href', link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {}
};

export const isZooming = () => {
  let px_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
  var newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
  if (newPx_ratio === px_ratio) {
    var newWidth = $('table').width();
    $('.top').width(newWidth);
  }
};

export const handleScrollTopTable = () => {
  $('.wrapper-top').scroll(function () {
    $('.scroll-custom').scrollLeft($('.wrapper-top').scrollLeft());
  });
  $('.scroll-custom').scroll(function () {
    $('.wrapper-top').scrollLeft($('.scroll-custom').scrollLeft());
  });
};

export const errorSubmit = (code) => {
  switch (code) {
    case Constants.CODE_DATA_EXISTS:
      return 'common.dataAlreadyExists';
    case Constants.CODE_AN_UNKNOWN_ERROR:
      return 'common.anUnknownError';
    case Constants.CODE_MISSING_DATA:
      return 'common.missingData';
    case Constants.CODE_DATA_DOES_NOT_EXIST:
      return 'common.dataDoesNotExist';
    case Constants.CODE_DATA_NOT_AVAILABLE:
      return 'common.dataNotAvailable';
    default:
      return '';
  }
};

export const checkMaxDateToday = (valueDate) => {
  if (valueDate?.length === 10) {
    var today = new moment();
    var value = new moment(valueDate, 'DD/MM/YYYY');
    if (today < value) {
      return true;
    } else {
      return false;
    }
  }
};

export const chunk = (array, size) => {
  return array.reduce((arr, item, idx) => {
    return idx % size === 0 ? [...arr, [item]] : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};

export const compact = (array) => {
  return array.filter(Boolean);
};

export const uniqWith = (arr, fn) =>
  arr.filter((element, index) => arr.findIndex((step) => fn(element, step)) === index);

export const getColorStatus = (data, value) => {
  return data?.find((item) => item.id === value)?.class;
};

export const getName = (listItem, value) => {
  if (value) {
    return listItem?.find((item) => item.id === value)?.name;
  }
  return 'N/A';
};

export const getNameStatus = (listStatus, value, idDiv, listDiv) => {
  if (value === STATUS_CANDIDATE.CANDIDATE_AGREE_OFFER || value === STATUS_CANDIDATE.ONBOARD) {
    return listStatus.find((item) => item.id === value)?.name + ' - ' + getDivisionName(idDiv, listDiv) + ' Division';
  } else if (value) {
    return listStatus?.find((item) => item.id === value)?.name;
  } else return 'N/A';
};

export const getText = (text) => {
  if (text) {
    return text;
  }
  return 'N/A';
};

export const getTextGender = (index) => {
  if (index === MALE) {
    return t('managerCandidate.male');
  }
  if (index === FEMALE) {
    return t('managerCandidate.female');
  }
  return t('managerCandidate.sexThird');
};

export const getCandidateLanguages = (listItem, dataListLanguage, displayLevel = true) => {
  let candidateLanguages = '';
  if (listItem) {
    candidateLanguages = listItem.map((data) => {
      return dataListLanguage?.find((item) => item.id === data.language_id)?.name + (displayLevel ? ` (${getText(data.level)})` : '');
    }).join(", ");
  }
  return getText(candidateLanguages);
};

export const getNameReturnHollow = (listItem, value) => {
  if (value) {
    return listItem?.find((item) => item.id === value)?.name;
  }
  return '';
};

export const getNameFile = (str) => {
  if (str) {
    let lastIndexOfDot = str.lastIndexOf('.');
    if (lastIndexOfDot === -1) return str;
    return str.slice(0, lastIndexOfDot);
  }
  return '';
};

export const isArrayContainAnotherArray = (bigArray, smallArray) => {
  if (!smallArray?.length) return false;
  return smallArray.every((element) => bigArray.includes(element));
};

export const uniqueArr = (data) => {
  if (!data || !data.length) return [];

  const arr = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i])
      if (!arr.includes(data[i])) {
        arr.push(data[i]);
      }
  }
  return arr;
};

export const sumValueOfArrayObject = (array, key) => {
  if (!Array.isArray(array) || !array.length) return 0;
  return array.map((item) => item[key]).reduce((prev, next) => prev + next);
};

export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getValidHttpsUrl = (string) => {
  if (!string) return;
  if (isValidHttpUrl(string)) return string;
  return 'https://' + string;
};

export const limitLengthText = (str, maxLength) => {
  if (!str) return '';
  if (str.length > maxLength) return str.slice(0, maxLength) + "...";
  return str;
};
