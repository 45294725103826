import React, {useContext} from 'react';
import { MdDelete } from 'react-icons/md';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { getName, getColorStatus, getTextGender } from 'helpers/index';
import { formatNumberOne } from 'helpers/format';
import { useGetListDivision } from 'hook/useDivision';
import { QUANTITY_CANDIDATE } from 'constants/manageJob';
import { LIST_STATUS } from 'constants/ManagerOrder';
import Loading from 'components/Loading';
import DetailStatusCard from 'components/DetailStatusCard';
import { JOB_PERMISSION, CANDIDATE_PERMISSION, ORDER_HR_PERMISSION } from 'constants/permission';
import EditLayout from 'components/PermissionPage/EditLayout';
import { ThemContext } from 'layouts/Admin';

const QuantityCandidate = ({
  t,
  listApplies,
  appliesLoading,
  setOpenAddApplies,
  handleCloseDetailJob,
  handleClickDeleteCandidate,
}) => {
  const { data: uListDivision } = useGetListDivision();
  const { dataInfoUser, isHRM } = useContext(ThemContext);

  const formatFieldKeyList = (list) => {
    return list.map((item) => {
      const { user_info, status } = item;
      return {
        status_id: status,
        status: getName(LIST_STATUS, status),
        name: user_info.name,
        avatar: user_info.avatar,
      };
    });
  };

  return (
    <div className="info-order">
      <div className="d-flex align-items-center justify-content-between mt-10">
        <span className="fw-bold total-order">
          {t('managerJob.totalApplies')}: {listApplies?.length}
        </span>
        <div className="add-order">
          <EditLayout permissionName={[JOB_PERMISSION, CANDIDATE_PERMISSION, ORDER_HR_PERMISSION]}>
            <button
              className="btn-add-order d-flex align-items-center justify-content-center"
              onClick={() => {
                setOpenAddApplies((prev) => !prev);
                handleCloseDetailJob(QUANTITY_CANDIDATE);
              }}
            >
              <AddIcon />
            </button>
          </EditLayout>
        </div>
      </div>
      <Paper className="mt-22">
        <TableContainer className="table-list table-container table-candidate">
          <Table aria-label="sticky table" stickyHeader className="table-scroll-quantitycandidate">
            <TableHead className="asset-header">
              <TableRow className="asset-header-row">
                <TableCell>{t('managerJob.STT')}</TableCell>
                <TableCell>{t('managerJob.applieName')}</TableCell>
                <TableCell>{t('managerJob.sex')}</TableCell>
                <TableCell>{t('managerJob.status')}</TableCell>
                <TableCell>{t('managerJob.division')}</TableCell>
                <TableCell>{t('managerJob.HRChargeOf')}</TableCell>
                <TableCell>{t('managerJob.linkApplie')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appliesLoading && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Loading addClass="mt-3 mb-3" />
                  </TableCell>
                </TableRow>
              )}
              {listApplies &&
                listApplies.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="stt-table cursor">{formatNumberOne(index + 1)}</TableCell>
                      <TableCell className="cell-table-title cursor">{item.full_name}</TableCell>
                      <TableCell className="cell-table cursor">{getTextGender(item.sex)}</TableCell>
                      <TableCell className={`${getColorStatus(LIST_STATUS, item.status)}`}>
                        <Tooltip
                          title={
                            <DetailStatusCard
                              list={formatFieldKeyList(item.list_action_leader)}
                              fieldKey={'status'}
                              fieldKeyClass={'status_id'}
                            />
                          }
                          arrow
                        >
                          <span>{getName(LIST_STATUS, item.status)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell className="cell-table cursor">{getName(uListDivision, item.division_id)}</TableCell>
                      <TableCell className="cell-table-title cursor">{item.assigned_info?.name}</TableCell>
                      {isHRM || dataInfoUser?.user_id === item.assigned_info?.id ? (
                        <TableCell className="cell-table link-cell">
                          <a
                            href={`${window.location.origin}/admin/candidate-detail/${item.candidate_id}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {t('managerJob.watchDetail')}
                          </a>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                      {item.can_be_remove_from_job && (dataInfoUser?.user_id === item.assigned_info?.id || isHRM) ? (
                        <EditLayout permissionName={[JOB_PERMISSION, CANDIDATE_PERMISSION, ORDER_HR_PERMISSION]}>
                          <TableCell className="table-content text-center">
                            <div
                              className="edit-delete-button color-button m-0"
                              onClick={() => handleClickDeleteCandidate(item.apply_id)}
                            >
                              <MdDelete />
                            </div>
                          </TableCell>
                        </EditLayout>
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  );
                })}

              {listApplies?.length === 0 && (
                <tr>
                  <td colSpan={9} className="error-no-data">
                    {t('common.notData')}
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default QuantityCandidate;
