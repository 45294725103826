import configs from 'config';

export const TYPE_EXPORT_PDF = 'type_export_pdf';
export const DEFAULT_LIMIT = 16;
export const DEFAULT_LIMIT_12 = 12;
export const DEFAULT_LIMIT_16 = 16;
export const DEFAULT_LIMIT_20 = 20;
export const DEFAULT_LIMIT_10 = 10;
export const DEFAULT_LIMIT_9 = 9;
export const DEFAULT_LIMIT_5 = 5;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_MUI = 0;
export const DEFAULT_OFFSET = 1;
export const DEFAULT_LIMIT_LARGE = 20;

// permissions
export const HR_PERMISSION = 0;
export const LEADER_PERMISSION = 1;
export const HRM_PERMISSION = 2;
export const ARR_HR_POSITION = [configs.POSITION_ID_HR, configs.POSITION_ID_HRM, configs.POSITION_ID_HR_ASSISTANT];
export const ARR_HRM_POSITION = [configs.POSITION_ID_HRM];
export const ARR_LEADER_POSITION = [configs.POSITION_ID_LEADER, configs.POSITION_ID_VICE_LEADER];

export const POSITION_ID_HR = configs.POSITION_ID_HR;
export const POSITION_ID_HRM = configs.POSITION_ID_HRM;
export const POSITION_ID_LEADER = configs.POSITION_ID_LEADER;
export const POSITION_ID_VICE_LEADER = configs.POSITION_ID_VICE_LEADER;

export const LOADING_SM = 'sm';
export const LOADING_MD = 'md';

export const ALL = 'all';

export const SEND_EMAIL = 1;
export const NOT_SEND_EMAIL = 0;

export const NOT_IS_ONBOARD = 0;

export const MIN_SALARY = 100000;
export const STEP_CANDIDATE_BOOKING_MINUTES = 15;

export const MIN_LENGTH_1 = 1;
export const MIN_LENGTH_2 = 2;
export const MIN_LENGTH_5 = 5;
export const MIN_LENGTH_7 = 7;
export const MIN_LENGTH_100 = 100;

export const MAXIMUM = 99999;
export const MAX_LENGTH_3 = 3;
export const MAX_LENGTH_7 = 7;
export const MAX_LENGTH_10 = 10;
export const MAX_LENGTH_12 = 12;
export const MAX_LENGTH_15 = 15;
export const MAX_LENGTH_20 = 20;
export const MAX_LENGTH_25 = 25;
export const MAX_LENGTH_30 = 30;
export const MAX_LENGTH_40 = 40;
export const MAX_LENGTH_45 = 45;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_60 = 60;
export const MAX_LENGTH_100 = 100;
export const MAX_LENGTH_150 = 150;
export const MAX_LENGTH_200 = 200;
export const MAX_LENGTH_250 = 250;
export const MAX_LENGTH_255 = 255;
export const MAX_LENGTH_256 = 256;
export const MAX_LENGTH_300 = 300;
export const MAX_LENGTH_500 = 500;
export const MAX_LENGTH_1000 = 1000;
export const MAX_LENGTH_5000 = 5000;

export const ROWS_TEXT_AREA_3 = 3;
export const ROWS_TEXT_AREA_6 = 6;

export const MAX_MINUTES_LATE = 480;

export const PER_PAGE_OPTION = [16, 30, 50];
export const PER_PAGE_OPTION_JOB = [12, 24, 36];
export const PER_PAGE_OPTION_20 = [20, 40, 60];
export const PER_PAGE_OPTION_16 = [16, 30, 60];
export const PER_PAGE_OPTION_10 = [10, 12, 15, 20];
export const PER_PAGE_OPTION_9 = [9, 12, 15, 20];
export const PER_PAGE_OPTION_10_12_15 = [10, 12, 15];

export const DATA_TYPE_NUMBER = 'number';
export const DATA_TYPE_CURRENCY = 'currency';
export const DATA_TYPE_FLOAT = 'float';

export const FORMAT_IMAGES = ['JPG', 'JPEG', 'PNG', 'JFIF', 'jpg', 'jpeg', 'png', 'jfif'];

export const TOTAL_WORK = 168;
export const VALIDATE_NOT_ZERO = 0;
export const NOT_DATA = 0;
export const OPTION_ALL = 0;

export const ROLE_NOT_VIEW = 'none';
export const ROLE_VIEW = 'view';

export const INVALID_DATE = 'Invalid date';

export const DISABLE_ROLE = 'disable';
export const DISABLE_BUTTON_ROLE = 'disable-button';

export const NOT_UPDATE = 'Chưa cập nhật';
export const STATUS_N_A = 'N/A';
export const managerDecentralization = {
  getListManagerDecentralization: 'getListManagerDecentralization',
  createManagerDecentralization: 'createManagerDecentralization',
  getListGroupRole: 'getListGroupRole',
  checkUserRole: 'checkUserRole',
  communityAddMemberCustom: 'communityAddMemberCustom',
  allDivisionPosition: 'allDivisionPosition',
  allDivision: 'allDivision',
};

export const EXPORT = 'export';

export const CODE_DATA_EXISTS = 1;
export const CODE_AN_UNKNOWN_ERROR = 2;
export const CODE_MISSING_DATA = 3;
export const CODE_DATA_DOES_NOT_EXIST = 4;
export const CODE_DATA_NOT_AVAILABLE = 5;
export const systemRecruit = 2;
export const LIST_COST_CONFIRM_NEED_BEFORE_HRM_CONFIRM = 5;

export const managerJob = {
  USE_LIST_MANAGER_JOB: 'USE_LIST_MANAGER_JOB',
  USE_DETAIL_JOB: 'USE_DETAIL_JOB',
  USE_LIST_ORDER_BY_JOB: 'USE_LIST_ORDER_BY_JOB',
  USE_LIST_ORDER_AVAILABLE: 'USE_LIST_ORDER_AVAILABLE',
  USE_LIST_APPLIES_AVAILABLE_BY_JOB: 'USE_LIST_APPLIES_AVAILABLE_BY_JOB',
  USE_LIST_APPLIES_AVAILABLE: 'USE_LIST_APPLIES_AVAILABLE',
};

export const managerTest = {
  USE_LIST_MANAGER_GROUP_TEST: 'USE_LIST_MANAGER_GROUP_TEST',
  USE_LIST_MANAGER_DETAIL_QUESTION: 'USE_LIST_MANAGER_DETAIL_QUESTION',
  USE_LIST_MANAGER_POST_TEST: 'USE_LIST_MANAGER_POST_TEST',
  USE_LIST_GET_POST_TEST_DETAIL: 'USE_LIST_GET_POST_TEST_DETAIL',
  USE_TESTING_INFO: 'USE_TESTING_INFO',
};

export const TYPE_ONESIGNAL_QUESTION_INTERVIEW = '1';

export const USE_GET_NOTIFICATIONS = 'USE_GET_NOTIFICATIONS';
export const SECRET_JWT_REQUEST_USERS = 'amela-secret';
export const USE_TOTAL_GET_NOTIFICATIONS = 'USE_TOTAL_GET_NOTIFICATIONS';

export const SYSTEM_ME = 1;
export const SYSTEM_BO = 2;
export const SYSTEM_RECRUIT = 3;
export const SYSTEM_WORKFLOW = 4;
export const SYSTEM_WIKI = 5;
export const SYSTEM_PROJECT = 6;

export const MANAGE_LIST_COST_LEADER = {
  getListCostLeader: 'getListCostLeader',
  getListCostHrm: 'getListCostHrm',
  exportCostHrm: 'exportCostHrm',
  listCostMasterData: 'listCostMasterData',
};
