import { Grid } from '@mui/material';
import React from 'react';

import TimerCountDown from './TimerCountDown';

import Button from '@mui/material/Button';
import { STATUS_TESTING } from 'constants/managerTest';

const Header = ({ data, t, mapPosition, mapLevel, mapSkill, handleSubmitTest, setTimeEnd }) => {
  return (
    <div className="header w-100">
      <Grid container className="container align-items-center h-inherit">
        <div className="w-50 header-testing-top">
          <span className="title fw-bold">{data?.title}</span>
          <div className="testing-header-row">
            <span>{t('managerTest.nameCandidate')}: </span>
            <span className="name-candidate fw-bold">{data?.full_name}</span>
          </div>
          <div className="d-flex">
            <div className="position mr-30">
              <span>{t('managerTest.position')}:</span>
              <span className="name-candidate fw-bold"> {mapPosition.current[data?.position_id]}</span>
            </div>
            <div className="skill mr-30">
              <span>{t('managerTest.skill')}:</span>
              <span className="name-candidate fw-bold"> {mapSkill.current[data?.skill_id]}</span>
            </div>
            <div className="level">
              <span>{t('managerTest.level')}:</span>
              <span className="name-candidate fw-bold"> {mapLevel.current[data?.level_id]}</span>
            </div>
          </div>
        </div>
        <div className="timer-for-test w-50 d-flex align-items-center h-100 justify-content-end">
          <div>
            <span className="d-block">{t('managerTest.titleForTimeTest')}</span>
            <TimerCountDown
              start_time={data?.start_time}
              time_for_test={data?.time_for_test}
              setTimeEnd={setTimeEnd}
              timeNow={data?.time_now}
            />
          </div>
          {data?.status === STATUS_TESTING.STARTED && (
            <>
              <div className="line" />
              <Button className="btn-bg-yellow h-45 pl-15 pr-15 btn-submit-testing" onClick={handleSubmitTest}>
                {t('managerTest.submitTest')}
              </Button>
            </>
          )}
        </div>
      </Grid>
    </div>
  );
};

export default Header;
