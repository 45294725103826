import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import { Grid } from '@mui/material';

import { apiInsertQuestionInterView } from 'api/managerQuestionInterview';

import styles from './styles.module.scss';
import AlertSubmit from 'components/Alert';
import Loading from 'components/Loading';

import { useGetQuestionsInterview } from 'hook/useManagerQuestionInterview';

import { TYPE_SOFT_QUESTION } from 'constants/ManagerQuestionInterview';
import { GET_QUESTION_INTERVIEW } from 'constants/ManagerQuestionInterview';
import { HTTP_OK } from 'constants/StatusCode';
import { STATUS_CANDIDATE } from 'constants/ManagerCandidate';
import classNames from 'classnames';
import EditLayout from 'components/PermissionPage/EditLayout';
import { CANDIDATE_PERMISSION } from 'constants/permission';

const ListQuestion = ({ applyId, profile, dataLevel, status }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [dataSoftQuestion, setDataSoftQuestion] = useState([]);
  const [dataMajorQuestion, setDataMajorQuestion] = useState([]);
  const [messageAlert, setMessageAlert] = useState('');
  const [disableCheckQuestion, setDisableCheckQuestion] = useState(true);
  const [numberLoading, setNumberLoading] = useState(-1);
  const [typeLoading, setTypeLoading] = useState(-1);
  const [keyQuestion, setKeyQuestion] = useState(-1);
  const [dataQuestionUpdate, setDataQuestionUpdate] = useState();
  const {
    data: dataQuestionsResponse,
    isLoading: isLoadingQuestionInterview,
    isFetching,
  } = useGetQuestionsInterview(applyId);

  useEffect(() => {
    if (status === STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE) {
      setDisableCheckQuestion(false);
    }
  }, [status]);

  useEffect(() => {
    if (!isFetching) {
      setKeyQuestion(-1);
      setNumberLoading(-1);
      setTypeLoading(-1);
    }
  }, [isFetching]);

  useEffect(() => {
    if (dataQuestionsResponse && dataLevel) {
      let dataNew = [];
      if (dataQuestionsResponse?.list_question_interview && dataQuestionsResponse.list_question_interview.length) {
        dataNew = Array.isArray(dataQuestionsResponse.list_question_interview)
          ? dataQuestionsResponse.list_question_interview
          : JSON.parse(dataQuestionsResponse.list_question_interview);
      } else if (dataQuestionsResponse?.list_questions) {
        dataNew = JSON.parse(dataQuestionsResponse?.list_questions);
      } else {
      }

      if (dataNew.length) {
        let mapLevel = [];
        let softQuestion = [];
        let majorQuestion = [];
        let dataUpdate = [];

        for (const item of dataLevel) {
          mapLevel[item.id] = item.name;
        }

        for (const item of dataNew) {
          if (mapLevel[item.level_id]) {
            if (item.type === TYPE_SOFT_QUESTION) {
              if (!softQuestion[mapLevel[item.level_id]]) softQuestion[mapLevel[item.level_id]] = [];
              softQuestion[mapLevel[item.level_id]].push({ ...item, key_update: dataUpdate.length });
            } else {
              if (!majorQuestion[mapLevel[item.level_id]]) majorQuestion[mapLevel[item.level_id]] = [];
              majorQuestion[mapLevel[item.level_id]].push({ ...item, key_update: dataUpdate.length });
            }
          }
          let data = {
            type: item.type,
            content: item.content,
            level_id: item.level_id,
          };
          if (item.user_id) data.user_id = item.user_id;
          dataUpdate.push(data);
        }
        setDataSoftQuestion(softQuestion);
        setDataMajorQuestion(majorQuestion);
        setDataQuestionUpdate(dataUpdate);
      }
    }
  }, [dataQuestionsResponse, dataLevel]);

  const { mutate: insetUPdateQuestionInterview } = useMutation(
    (params) => apiInsertQuestionInterView(params.id, params.data),
    {
      onSuccess: (response) => {
        if (response.status === HTTP_OK) {
          queryClient.invalidateQueries([GET_QUESTION_INTERVIEW], { refetchActive: true });
        } else {
        }
      },
      onError: (error) => {
        setMessageAlert(t('common.anUnknownError'));
        setKeyQuestion(-1);
        setNumberLoading(-1);
        setTypeLoading(-1);
      },
    },
  );

  const handleChangeCheckBox = (event, itemCheckBox, key, index, typeQuestion) => {
    if (disableCheckQuestion) {
      return;
    }
    if (profile) {
      if (!event.target.checked) {
        if (profile.user_id === itemCheckBox.user_id) {
          delete dataQuestionUpdate[itemCheckBox.key_update].user_id;
          setKeyQuestion(key);
          setNumberLoading(index);
          setTypeLoading(typeQuestion);
          insetUPdateQuestionInterview({
            id: applyId,
            data: { list_question_interview: dataQuestionUpdate },
          });
        } else {
        }
      } else {
        dataQuestionUpdate[itemCheckBox.key_update].user_id = profile.user_id;
        setKeyQuestion(key);
        setNumberLoading(index);
        setTypeLoading(typeQuestion);

        insetUPdateQuestionInterview({
          id: applyId,
          data: { list_question_interview: dataQuestionUpdate },
        });
      }
    }
  };

  const showCheckBoxQuestion = (dataSoftMajorQuestion) => {
    return Object.keys(dataSoftMajorQuestion).map((key, index_) => {
      const typeQuestion = dataSoftMajorQuestion[key]?.at(0).type;
      return (
        <React.Fragment key={index_}>
          <p className={styles.titleLeave}>
            {t('managerJob.level')}: {key}
          </p>
          <div className={styles.groupCheckbox}>
            <FormGroup>
              {dataSoftMajorQuestion[key].map((item, index) => (
                <>
                  <EditLayout permissionName={CANDIDATE_PERMISSION}>
                    <FormControlLabel
                      control={
                        key === keyQuestion && index === numberLoading && typeLoading === typeQuestion ? (
                          <Checkbox
                            disabled={true}
                            icon={
                              <i className={classNames(`fa fa-spinner fa-pulse fa-3x fa-fw ${styles.iconLoading}`)} />
                            }
                          />
                        ) : (
                          <Checkbox
                            checked={!!item?.user_id}
                            onChange={(event) => handleChangeCheckBox(event, item, key, index, typeQuestion)}
                            disabled={disableCheckQuestion}
                          />
                        )
                      }
                      label={`${t('manageKPI.sentence')} ${index + 1}: ${item.content}`}
                    />
                  </EditLayout>

                  {item?.user_info && (
                    <FormHelperText>
                      {t('manageKPI.whoAsked')}: {item.user_info.name}
                    </FormHelperText>
                  )}
                </>
              ))}
            </FormGroup>
          </div>
        </React.Fragment>
      );
    });
  };

  return (
    <div>
      <div className={styles.listQuestion}>
        <h4 className={styles.title}>{t('manageKPI.questionInformationPV')}</h4>

        {isLoadingQuestionInterview ? (
          <Loading addClass="mt-3 mb-3" />
        ) : (
          <>
            <Grid item container className="content">
              {Object.keys(dataSoftQuestion).length > 0 && (
                <>
                  <Grid item xs={12}>
                    <p className={styles.typeQuestion}>{t('managerQuestionInterview.softQuestion')}</p>
                  </Grid>
                  <Grid item xs={12}>
                    {showCheckBoxQuestion(dataSoftQuestion)}
                  </Grid>
                </>
              )}
            </Grid>

            <Grid item container className="content">
              {Object.keys(dataMajorQuestion).length > 0 && (
                <>
                  <Grid item xs={12}>
                    <p className={styles.typeQuestion}>{t('managerQuestionInterview.majorQuestion')}</p>
                  </Grid>
                  <Grid item xs={12}>
                    {showCheckBoxQuestion(dataMajorQuestion)}
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </div>
      <AlertSubmit toggle={() => setMessageAlert('')} isShowAlert={!!messageAlert}>
        {messageAlert}
      </AlertSubmit>
    </div>
  );
};

export default ListQuestion;
