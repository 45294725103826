import React from 'react';

const NotAcceptPermission = () => {
  return (
    <div className="page-not-allow-accept-permission text-center">
      <div>Bạn không có quyền truy cập</div>
    </div>
  );
};

export default NotAcceptPermission;
