import React from 'react';

import { Grid } from '@mui/material';

import iconTop1 from 'assets/img/icons/dashboard/top1.svg';
import iconTop2 from 'assets/img/icons/dashboard/top2.svg';
import iconTop3 from 'assets/img/icons/dashboard/top3.svg';
import styles from './styles.module.scss';

const TopHonors = (props) => {
  const { top1, top2, top3 } = props;
  return (
    <Grid container className={styles.container}>
      <Grid item xs={4} className={styles.columnTop}>
        <img src={iconTop2} alt={iconTop2} className={styles.imgMedal} />
        <div className={styles.name}>{top2?.name}</div>
        <div className={styles.value}>{top2?.value}</div>
      </Grid>
      <Grid item xs={4} className={styles.columnTop}>
        <img src={iconTop1} alt={iconTop1} className={styles.imgMedal} />
        <div className={styles.name}>{top1?.name}</div>
        <div className={styles.value}>{top1?.value}</div>
      </Grid>
      <Grid item xs={4} className={styles.columnTop}>
        <img src={iconTop3} alt={iconTop3} className={styles.imgMedal} />
        <div className={styles.name}>{top3?.name}</div>
        <div className={styles.value}>{top3?.value}</div>
      </Grid>
    </Grid>
  );
};

export default TopHonors;
