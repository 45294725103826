import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import iconUpdateWishes from 'assets/img/icons/candidate/updateWhisesCandidate.svg';
import EditLayout from 'components/PermissionPage/EditLayout';
import { CANDIDATE_PERMISSION } from 'constants/permission';
import ModalHrEvaluate from '../ModalHrEvaluate';

const CandidateEvaluation = ({ applyDetail, isLeader }) => {
  const { t } = useTranslation();
  const [showModalHrEvaluate, setShowModalHrEvaluate] = useState(false);

  return (
    applyDetail?.evaluate_content && (
      <Grid className="bodyCandidateEvaluation">
        {showModalHrEvaluate && (
          <ModalHrEvaluate
            title={t('managerCandidate.hrCandidateAssetment')}
            label={t('managerCandidate.enterCandidateEvaluationContent')}
            isShowModal={showModalHrEvaluate}
            setIsShowModal={setShowModalHrEvaluate}
            listApply={{ id: applyDetail.id }}
            confirmMessage={t('managerCandidate.hrEvaluatesCandidatesSuccess')}
            defaultValue={applyDetail.evaluate_content}
          />
        )}
        <Grid xs={12} container className="titleCandidateEvaluation">
          <div className="titleInfoWishes">{t('managerCandidate.hrCandidateAssetment')}</div>
          {!isLeader && (
            <EditLayout permissionName={CANDIDATE_PERMISSION}>
              <button className="buttonSettingDesired" onClick={() => setShowModalHrEvaluate(true)}>
                <img className="imgIconUpdateWishes" alt="updateWishesCandidate" src={iconUpdateWishes} />
              </button>
            </EditLayout>
          )}
          <Grid className="bodyContentCandidateEvaluation" xs={12}>
            <div className="titleCandidateEvaluation">{applyDetail.evaluate_content}</div>
          </Grid>
        </Grid>
      </Grid>
    )
  );
};
export default CandidateEvaluation;
