import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Checkbox, Grid, MenuItem } from '@mui/material';

import { sendPost } from 'helpers/axios';
import { errorSubmit, getTextGender } from 'helpers/index';
import { customDate } from 'helpers/formatDate';
import { managerJob } from 'constants/index';
import { QUANTITY_CANDIDATE } from 'constants/manageJob';
import { CODE_DATA_EXISTS } from 'constants/index';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import SearchComponent from 'components/Search/index.js';
import SelectComponent from 'components/Select/Select.js';
import { getLevelName } from 'helpers/masterData';
import SelectMultipleComponent from 'components/Select/MultipleSelect.js';
import ButtonComponent from 'components/Buttons';
import Popup from 'components/popup/index';
import Loading from 'components/Loading';

const CreateAppliesAvailable = ({
  t,
  useListPosition,
  useListLevel,
  useListSkill,
  setOpenAddApplies,
  dataAppliesAvailable,
  mapSkill,
  mapPosition,
  mapLevel,
  id,
  setFilter,
  filter,
  query,
  handleOpenDetailJob,
  isLoadingAppliesAvailable,
}) => {
  const [addApplesItem, setAppliesItem] = useState([]);
  const [addApplieSuccess, setApplieSuccess] = useState(false);
  const [addApplieError, setApplieError] = useState(false);
  const [loading, setLoading] = useState(true);
  const message = useRef('');
  const idApplie = useRef(-1);
  const [valueSelect, setValueSelect] = useState([]);

  const handleSearch = (val, name) => {
    setFilter({ ...filter, [name]: val });
  };

  const handleAddAplies = (item) => {
    if (addApplesItem.includes(item.apply_id)) {
      setAppliesItem((prev) => {
        prev.splice(prev.indexOf(item.apply_id), 1);
        return [...prev];
      });
    } else {
      setAppliesItem((prev) => [...prev, item.apply_id]);
    }
  };

  const handleAddApliesExecute = async () => {
    if (loading) {
      setLoading(false);
      const res = await sendPost(`/api/job/add-applies`, { job_id: id, list_apply_ids: addApplesItem.join(',') });
      if (res.data.id) idApplie.current = res.data.id;
      if (errorSubmit(res?.data?.code) || res.data.status === 422) {
        if (res?.data?.code === CODE_DATA_EXISTS || res.data.status === 422) {
          message.current = t('managerJob.jobAlreadyExists');
        } else {
          message.current = t(res?.data?.code ? errorSubmit(res?.data?.code) : 'managerJob.somethingWenWrong');
        }
        setApplieError(true);
      } else {
        setApplieSuccess(true);
      }
      setLoading(true);
    }
  };

  const handleDoneAddApliesExecute = () => {
    if (loading) {
      setApplieSuccess(false);
      setOpenAddApplies(false);
      handleOpenDetailJob(QUANTITY_CANDIDATE);
      query.refetchQueries(managerJob.USE_LIST_APPLIES_AVAILABLE);
    }
  };

  const handleWatchApplie = () => {
    window.open(`${window.location.origin}/admin/candidate-detail/${idApplie.current}`);
    setApplieError(false);
  };

  const checkSkill = (skillId) => {
    if (skillId) {
      return ' - ' + mapSkill[skillId];
    }
    return '';
  };
  const handleChangeValue = (event) => {
    const {
      target: { value },
    } = event;
    setValueSelect(typeof value === 'string' ? value.split(',') : value);
    handleSearch(value, 'level_id');
  };
  return (
    <div className="create-applies-available mt-4">
      <AlertAfterSubmit toggle={handleDoneAddApliesExecute} isShowAlert={addApplieSuccess}>
        <p>{t(`managerJob.addApplieSuccess`)}</p>
      </AlertAfterSubmit>
      <Popup
        active={addApplieError}
        handleCancel={() => setApplieError(false)}
        className="add-applies"
        footer={
          <>
            <button className="btn-transparent applie-cancel fs-16" onClick={() => setApplieError(false)}>
              {t('managerJob.CANCEL')}
            </button>
            <button className="btn-transparent color-yellow fs-16" onClick={handleWatchApplie}>
              {t('managerJob.watchApplie')}
            </button>
          </>
        }
        messages={message.current}
      />
      <Grid container>
        <Grid item xs={12}>
          <SearchComponent
            txtPlaceholder={t('managerJob.search')}
            addClass="input-search"
            handleSearch={(val) => {
              handleSearch(val, 'full_name');
            }}
            maxLength={50}
          />
        </Grid>
        <Grid container className="mt-4" spacing={2}>
          <Grid item xs={4}>
            <SelectComponent
              addClass="w-100"
              label={t('managerJob.position')}
              onHandleChangeSelect={(e) => {
                handleSearch(e.target.value, 'position_id');
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: {
                      xs: 350,
                    },
                  },
                },
              }}
            >
              <MenuItem value="">{t('managerJob.None')}</MenuItem>
              {useListPosition &&
                useListPosition?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </SelectComponent>
          </Grid>
          <Grid item xs={4}>
            <SelectMultipleComponent
              addClass="w-100"
              label={t('managerJob.level')}
              onHandleChangeSelect={(event) => handleChangeValue(event)}
              isRequired={false}
              valueDefault={valueSelect}
              setValueSelect={setValueSelect}
              renderValue={(value) =>
                value.length > 1 ? (
                  <span>
                    {value.length} {t('managerJob.levels')}
                  </span>
                ) : (
                  getLevelName(value, useListLevel)
                )
              }
            >
              {useListLevel &&
                useListLevel?.map((item, index) => {
                  return (
                    <MenuItem key={`level_${index}`} value={item.id}>
                      <Checkbox checked={valueSelect.includes(item.id)} />
                      {item.name}
                    </MenuItem>
                  );
                })}
            </SelectMultipleComponent>
          </Grid>
          <Grid item xs={4}>
            <SelectComponent
              addClass="w-100"
              label={t('managerJob.skill')}
              onHandleChangeSelect={(e) => {
                handleSearch(e.target.value, 'skill_id');
              }}
            >
              <MenuItem value="">{t('managerJob.None')}</MenuItem>
              {useListSkill &&
                useListSkill?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </SelectComponent>
          </Grid>
        </Grid>
        <Grid item xs={12} className="list-applies-available mt-4">
          <ul className="pl-0">
            {isLoadingAppliesAvailable && <Loading />}
            {!isLoadingAppliesAvailable &&
              dataAppliesAvailable?.map((item, index) => (
                <li key={index} className="d-block cursor" onClick={handleAddAplies.bind(this, item)}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="content-left">
                      <NavLink
                        to={{
                          pathname: `/admin/candidate-detail/${item.id}`,
                        }}
                        target="_blank"
                      >
                        <span className="name d-block">{item.full_name}</span>
                      </NavLink>
                      <span className="info-applie">
                        {mapPosition[item.position_id] +
                        ' - ' +
                        mapLevel[item.level_id] +
                        checkSkill(item.skill_id) +
                        '/ ' +
                        getTextGender(item.sex) +
                        ' - ' +
                        (item.birthday
                          ? customDate(item.birthday, 'DD/MM/YYYY')
                          : t('common.notDataContract'))}
                      </span>
                    </div>
                    <div className="content-right">
                      <Checkbox checked={addApplesItem.includes(item.apply_id)} />
                    </div>
                  </div>
                  <div className="divider w-100 d-block" />
                </li>
              ))}
            {!isLoadingAppliesAvailable && dataAppliesAvailable?.length === 0 && (
              <div className="error-no-data">{t('common.notData')}</div>
            )}
          </ul>
        </Grid>
        <Grid container className="mt-3" spacing={2}>
          <Grid item xs={6}>
            <ButtonComponent
              text={t('managerJob.cancel')}
              addClass="btn-bg-yellow2 mr-3 w-100"
              handleClick={() => {
                setOpenAddApplies(false);
                handleOpenDetailJob(QUANTITY_CANDIDATE);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ButtonComponent
              text={t('managerJob.add')}
              addClass="btn-bg-yellow1 w-100"
              hasDisabled={addApplesItem.length === 0 || !loading}
              isLoading={!loading}
              handleClick={handleAddApliesExecute}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateAppliesAvailable;
