/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from 'react-query';

import { HTTP_OK } from 'constants/StatusCode';
import { sendGet } from 'helpers/axios';
import { managerTest } from 'constants/index';

export function useGetListGroupTest(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerTest.USE_LIST_MANAGER_GROUP_TEST, params], async () => {
    const response = await sendGet(`/api/group-question/get-list`, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return {
    ...response,
    data: response?.data?.list_data,
    error,
    isLoading,
    total_records: response?.data?.total_records,
    total_questions: response?.data?.total_questions,
  };
}
export function useGetListPostTest(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerTest.USE_LIST_MANAGER_POST_TEST, params], async () => {
    const response = await sendGet(`/api/post-test/get-list`, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return {
    ...response,
    data: response?.data?.list_data,
    error,
    isLoading,
    total_records: response?.data?.total_records,
  };
}
export function useGetListGroupQuestionDetail(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [managerTest.USE_LIST_MANAGER_DETAIL_QUESTION, params],
    async () => {
      const response = await sendGet(`/api/group-question/get-detail`, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { cacheTime: 0 },
  );
  return {
    ...response,
    data: response?.data,
    error,
    isLoading,
  };
}

export function useGetListQuestion(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerTest.USE_LIST_GET_LIST_QUESTION, params], async () => {
    const response = await sendGet(`/api/question/get-list`, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return {
    ...response,
    data: response?.data,
    error,
    isLoading,
  };
}

export function useGetPostTestDetail(params, options) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [managerTest.USE_LIST_GET_POST_TEST_DETAIL, params],
    async () => {
      const response = await sendGet(`/api/post-test/get-detail`, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { ...options, cacheTime: 0 },
  );
  return {
    ...response,
    data: response?.data,
    error,
    isLoading,
  };
}

export function useGetTestingInfo(params, options) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [managerTest.USE_TESTING_INFO, params],
    async () => {
      const response = await sendGet(`/api/take-a-test/get-info`, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { ...options },
  );
  return {
    ...response,
    data: response?.data,
    error,
    isLoading,
    code: response?.code,
  };
}
