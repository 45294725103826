import { Route, Switch } from 'react-router-dom';
import ManagerOrderHr from './ManagerOrderHr';

const Index = () => {
  return (
    <>
      <Switch>
        <Route path="/admin/manager-order" exact render={() => <ManagerOrderHr />} />
        <Route path="/admin/manager-order/:orderId" exact render={() => <ManagerOrderHr />} />
      </Switch>
    </>
  );
};

export default Index;
