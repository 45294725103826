import { sendGet, sendPost, sendPut, sendDelete, sendPostForExport } from 'helpers/axios';

export const apiGetListKPI = (params) => sendGet('/api/recruit-performance/get-list', params);
export const apiGetListHRNoKPI = (params) => sendGet('/api/get-list-position-hr', params);
export const apiGetListHrHasRecruited = (params) => sendGet('/api/recruit-performance/get-hr-has-recruited', params);
export const apiGetDetailKPI = (id) => sendGet(`api/recruit-performance/show/${id}`);
export const apiCreateKPI = (params) => sendPost('/api/recruit-performance/create', params);
export const apiEditKPI = (params) => sendPut(`/api/recruit-performance/update/${params.id}`, params);
export const apiDeleteKPI = (id) => sendDelete(`/api/recruit-performance/delete/${id}`);
export const apiExportKpiOneHR = (params) => sendPostForExport('/api/recruit-performance/export-hr-month', params);
export const apiExportKpiMultiHR = (params) => sendPostForExport('/api/recruit-performance/multi-hr/export', params);
