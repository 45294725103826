import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import $ from 'jquery';
import _groupBy from 'lodash/groupBy';

import { Grid, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import '../ManagerCandidate/styles.scss';
import { HTTP_OK } from 'constants/StatusCode.js';
import Validate from '../ManagerCandidate/Validate';
import { DEFAULT_PAGE_MUI, CODE_DATA_EXISTS, DEFAULT_LIMIT_10 } from 'constants/index.js';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import CusromInputNumber from 'components/InputYupAndMui/CusromInputNumber';
import DateDay from 'components/ReactForm/DateDay';
import CustomRadioInput from 'components/InputYupAndMui/CustomRadioInputForm';
import SelectField from 'components/ReactForm/SelectField';
import { errorSubmit, checkMaxDateToday } from 'helpers';
import { useListUsers } from 'hook/useMasterData';
import ButtonComponent from 'components/Buttons';
import AutoCompleteCustom from 'components/AutoCompleteCustom/AutoComplete.js';
import iconAttchmentCV from 'assets/img/icons/attchment-cv.svg';
import iconCvDefault from 'assets/img/icons/cv-default.svg';
import { apiCreateCandidateCV, apiUploadCV } from 'api/managerCandidate';
import { USE_GET_LIST_CANDIDATE } from 'constants/ManagerCandidate';
import { MAX_LENGTH_30, MAX_LENGTH_25, MAX_LENGTH_250, MAX_LENGTH_50, LOADING_SM } from 'constants/index.js';
import styles from '../ManagerCandidate/style.module.scss';
import { QUANTITY_CANDIDATE } from 'constants/manageJob';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import { SOURCE_CV_TEXT } from 'constants/ManagerCandidate';
import Loading from 'components/Loading';
import { alertConfirmDuplicateCandidate } from 'helpers/manageCandidate';

const CreateCandidateModal = (props) => {
  const {
    isShowModal,
    setShowModal,
    dataListSchool,
    dataSkill,
    dataPosition,
    dataLevel,
    dataExperience,
    dataListSourceCV,
    detailJob,
    handleOpenDetailJob,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const DEFAULT_VALUES = {
    position_id: Number(detailJob?.position_id),
    skill_id: Number(detailJob?.skill_id),
  };

  const queryClient = useQueryClient();
  const [stateButton, setStateButton] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [isShowAlertError, setShowAlertError] = useState(false);
  const [message, setMessage] = useState('');
  const [attchmentCV, setAttchmentCV] = useState();
  const [dataUser, setDataUser] = useState([]);
  const [sourceCvText, setSourceCvText] = useState('');
  const [namePresenter, setNamePresenter] = useState('');
  const [errorDate, setErrorDate] = useState({});
  const [filter, setFilter] = useState({ page: DEFAULT_PAGE_MUI, limit: DEFAULT_LIMIT_10, key_word: '' });
  const { data: uDataUsers, isLoading: isLoadingDataUsers } = useListUsers({
    key_word: filter.key_word,
    page: filter.page + 1,
    per_page: filter.limit,
  });

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(Validate(sourceCvText)),
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  useEffect(() => {
    if (uDataUsers?.data) {
      setDataUser((prev) => {
        if (filter.clear_data) {
          return [...uDataUsers?.data];
        }
        return [...prev, ...uDataUsers?.data];
      });
    }
    // eslint-disable-next-line
  }, [uDataUsers]);

  const radioListGender = [
    {
      value: 1,
      labelName: t('managerCandidate.maleGender'),
    },
    {
      value: 2,
      labelName: t('managerCandidate.femaleGender'),
    },
  ];

  const radioListPrequalification = [
    {
      value: 1,
      labelName: t('managerCandidate.throughPrequalification'),
      disabled: false,
    },
    {
      value: 0,
      labelName: t('managerCandidate.didNotPassPrequalification'),
      disabled: true,
    },
  ];

  const handleSubmitForm = async (dataSubmit) => {
    if (errorDate?.birthday || errorDate?.apply_date) {
      return;
    }
    const {
      full_name,
      birthday,
      sex,
      email,
      mobile,
      school,
      experience,
      source_cv,
      presenter_id,
      apply_date,
      link_cv_online,
      position_id,
      skill_id,
      level_id,
      pass_prequalification,
      source_cv_text,
    } = dataSubmit;
    setStateButton(true);
    let response = {};
    const fb = new FormData();
    fb.append('full_name', full_name);
    namePresenter && fb.append('presenter_name', namePresenter);
    birthday && fb.append('birthday', moment(birthday, 'DD/MM/YYYY').format('YYYY/MM/DD'));
    fb.append('email', email);
    fb.append('mobile', mobile);
    fb.append('school', school);
    fb.append('experience', experience);
    fb.append('sex', sex);
    fb.append('apply_date', moment(apply_date, 'DD/MM/YYYY').format('YYYY/MM/DD'));
    link_cv_online && fb.append('link_cv_online', link_cv_online);
    fb.append('position_id', position_id);
    skill_id && fb.append('skill_id', skill_id);
    fb.append('level_id', level_id);
    fb.append('source_cv', source_cv ?? source_cv);
    attchmentCV && fb.append('cv_offline_info_temp', JSON.stringify(attchmentCV));
    attchmentCV && fb.append('cv_offline_info', JSON.stringify(attchmentCV));
    fb.append('pass_prequalification', pass_prequalification);
    presenter_id && !sourceCvText && fb.append('presenter_id', presenter_id);
    sourceCvText && fb.append('source_cv_text', source_cv_text);
    fb.append('job_id', detailJob.id);

    response = await apiCreateCandidateCV(fb);

    if (response?.data?.status === HTTP_OK) {
      setMessage(t('managerJob.createCandidateSuccess'));
      setStateButton(false);
      setShowAlert(true);
      clearErrors();
      await queryClient.invalidateQueries(USE_GET_LIST_CANDIDATE);
    } else {
      if (errorSubmit(response?.data?.code)) {
        if (response?.data?.code === CODE_DATA_EXISTS) {
          alertConfirmDuplicateCandidate(t, confirmAlert, response?.data?.name_hr, () =>
            history.push(`/admin/candidate-detail/${response.data?.id}`),
          );

          setStateButton(false);
          return;
        }
        setMessage(t(errorSubmit(response?.data?.code)));
        setShowAlertError(true);
        setStateButton(false);
      }
    }
  };

  const { mutate: uploadCV, isLoading: isLoadingUploadCV } = useMutation((formData) => apiUploadCV(formData), {
    onSuccess: (response) => {
      setAttchmentCV(response.data?.data);
      setError('link_cv_online', '');
      setValue('is_file_upload', true);
    },
    onError: () => {
      setMessage(t('messages.systemError'));
      setShowAlertError(true);
      setAttchmentCV();
      setValue('is_file_upload', false);
    },
  });

  const onUploadAttchmentCV = (event) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size / 1024 / 1024 >= 25) {
        $('#attchment-cv').val('');
        setMessage(t('messages.pleaseUploadFileUnder25mb'));
        setShowAlertError(true);
        setAttchmentCV();
        setValue('is_file_upload', false);
        return;
      }

      var regex = new RegExp('(.*?).(docx|doc|pdf|jpg|jpeg|png|xlsx|csv)$');
      if (!regex.test(event.target.files[0].name.toLowerCase())) {
        setMessage(t('messages.pleaseUploadImagesInTheCorrectFormat'));
        setShowAlertError(true);
        setAttchmentCV();
        setValue('is_file_upload', false);
        return;
      }

      const formData = new FormData();
      formData.append('file_cv', event.target.files[0]);
      uploadCV(formData);
    }
  };

  // Watch value type timekeeping
  const watchTypePositionIdValue = useWatch({
    control,
    name: 'position_id',
  });

  // Watch value type source_cv
  const watchTypeSourceCvValue = useWatch({
    control,
    name: 'source_cv',
  });

  const listSkills = _groupBy(dataSkill, 'position_id');

  useEffect(() => {
    setValue(
      'is_skill',
      dataSkill?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))?.length > 0 ? true : false,
    );
    if (!watchTypePositionIdValue || !listSkills[watchTypePositionIdValue]) {
      setValue('skill_id', null);
    } else if (detailJob?.skill_id && watchTypePositionIdValue === detailJob?.position_id) {
      let skill = listSkills[watchTypePositionIdValue].find((item) => item.id === detailJob?.skill_id);
      if (skill) setValue('skill_id', skill.id);
      else setValue('skill_id', null);
    } else {
      setValue('skill_id', listSkills[watchTypePositionIdValue][0].id);
    }
    // eslint-disable-next-line
  }, [watchTypePositionIdValue]);

  // Watch value type timekeeping
  const watchSchoolIdValue = useWatch({
    control,
    name: 'school',
  });

  // Watch value birthday
  const watchBirthdayValue = useWatch({
    control,
    name: 'birthday',
  });

  // Watch value birthday
  const watchApplyDateValue = useWatch({
    control,
    name: 'apply_date',
  });

  useEffect(() => {
    // eslint-disable-next-line
    if (!watchApplyDateValue || watchApplyDateValue == 'Invalid Date' || watchApplyDateValue?.length < 10) {
      return;
    }
    if (watchApplyDateValue?.length === 10) {
      if (checkMaxDateToday(watchApplyDateValue)) {
        setErrorDate((prev) => {
          return { ...prev, apply_date: true };
        });
      } else {
        setErrorDate((prev) => {
          return { ...prev, apply_date: false };
        });
      }
    }
  }, [watchApplyDateValue]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!watchBirthdayValue || watchBirthdayValue == 'Invalid Date' || watchBirthdayValue?.length < 10) {
      return;
    }
    if (watchBirthdayValue?.length === 10) {
      if (checkMaxDateToday(watchBirthdayValue)) {
        setErrorDate((prev) => {
          return { ...prev, birthday: true };
        });
      } else {
        setErrorDate((prev) => {
          return { ...prev, birthday: false };
        });
      }
    }
  }, [watchBirthdayValue]);

  // Watch value type source_cv
  const watchPresenterIdValue = useWatch({
    control,
    name: 'presenter_id',
  });

  const onHandleChangeSelect = (value) => {
    let data = dataListSourceCV?.filter((item) => (item.id === value ? item.name : null));
    if (data?.length && SOURCE_CV_TEXT.indexOf(data[0].name.toLowerCase()) > -1) {
      setSourceCvText(value);
    } else {
      setSourceCvText('');
    }
  };

  return (
    <>
      <Box autoComplete="off" onSubmit={handleSubmit(handleSubmitForm)} className="mt-4">
        <Grid container spacing={3}>
          <Grid item md={12} className="item-field">
            <CustomInputYup
              label={t('common.fullName')}
              name={`full_name`}
              control={control}
              register={register}
              errors={errors}
              useWatch={useWatch}
              required
              maxLength={MAX_LENGTH_25}
              invalidPattern={/[0-9!@#\\$%\\^&\\*()_\\+-={};'`~:",\\.<>\\|\\?\\\\\\/\]\\[]+/}
            />
          </Grid>
          <Grid item md={12} className="item-field">
            <DateDay
              label={t('common.birthday')}
              name="birthday"
              error={errors?.birthday?.message}
              addErrors={errorDate?.birthday && t('messages.pleaseEnteraDateThatIsLessThanTheCurrentDate')}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              isShowModal={isShowModal}
              defaultValue={null}
              maxDate={moment()}
              isRequired
            />
          </Grid>
          <Grid item md={12} className="item-field radio-gender-group">
            <span className="label-gender">{t('common.gender')}</span>
            <CustomRadioInput
              control={control}
              radioList={radioListGender}
              defaultValue={1}
              name="sex"
              setValue={setValue}
            />
          </Grid>
          <Grid item md={12} className="item-field">
            <CusromInputNumber
              label={t('common.phone')}
              name={`mobile`}
              control={control}
              register={register}
              errors={errors}
              useWatch={useWatch}
              required
              setValue={setValue}
              phone={true}
            />
          </Grid>
          <Grid item md={12} className="item-field">
            <CustomInputYup
              label={t('common.email')}
              name={`email`}
              control={control}
              register={register}
              errors={errors}
              useWatch={useWatch}
              required
            />
          </Grid>
          <Grid item md={12} className="item-field">
            <Autocomplete
              options={dataListSchool}
              autoHighlight
              className="school"
              onChange={(e, values, reason, detail) => {
                setValue('school', values?.id);
                clearErrors('school');
              }}
              defaultValue={dataListSchool?.find((item) => item?.id === watchSchoolIdValue)}
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={styles.schoolField}
                  error={errors?.school?.message ? true : false}
                  label={t('managerCandidate.school')}
                  required
                  inputProps={{
                    ...params.inputProps,
                    maxLength: MAX_LENGTH_50,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {errors?.school?.message && <p className="error-input error">{errors?.school?.message}</p>}
          </Grid>
          <Grid item xs={12} className="text-modal item-field">
            {t('managerCandidate.inforApply')}
          </Grid>
          <Grid item md={6} className="item-field">
            <SelectField
              name="position_id"
              label={t('masterData.StandardIndex.position')}
              error={errors?.position_id?.message}
              control={control}
              required
              setValue={setValue}
              useWatch={useWatch}
              register={register}
              isShowModal={isShowModal}
              defaultValue={Number(detailJob?.position_id)}
              isRequired
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: {
                      xs: 335,
                    },
                  },
                },
              }}
            >
              {dataPosition?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item md={6} className="item-field">
            <SelectField
              name="skill_id"
              label={t('masterData.StandardIndex.skill')}
              error={errors?.skill_id?.message}
              control={control}
              required
              setValue={setValue}
              useWatch={useWatch}
              register={register}
              isShowModal={isShowModal}
              defaultValue={detailJob?.skill_id ?? ''}
              disabled={
                watchTypePositionIdValue &&
                dataSkill?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))?.length > 0
                  ? false
                  : true
              }
              isRequired={
                watchTypePositionIdValue &&
                dataSkill?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))?.length > 0
                  ? true
                  : false
              }
            >
              {dataSkill &&
                dataSkill
                  ?.filter((el) => Number(el.position_id) === Number(watchTypePositionIdValue))
                  ?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
            </SelectField>
          </Grid>
          <Grid item md={6} className="item-field">
            <SelectField
              name="level_id"
              label={t('masterData.StandardIndex.levelApply')}
              error={errors?.level_id?.message}
              control={control}
              required
              setValue={setValue}
              useWatch={useWatch}
              register={register}
              isShowModal={isShowModal}
              isRequired
            >
              {dataLevel?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item md={6} className="item-field">
            <SelectField
              name="experience"
              label={t('managerJD.experience')}
              error={errors?.experience?.message}
              control={control}
              required
              setValue={setValue}
              useWatch={useWatch}
              register={register}
              isShowModal={isShowModal}
              isRequired
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: {
                      xs: 335,
                    },
                  },
                },
              }}
            >
              {dataExperience?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item md={12} className="item-field">
            <SelectField
              name="source_cv"
              label={t('managerCandidate.sourceCv')}
              error={errors?.source_cv?.message}
              control={control}
              required
              setValue={setValue}
              useWatch={useWatch}
              register={register}
              isShowModal={isShowModal}
              isRequired
              onHandleChangeSelect={onHandleChangeSelect}
            >
              {dataListSourceCV?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          {sourceCvText && (
            <Grid item md={12} className="item-field">
              <CustomInputYup
                label={t('managerCandidate.Outsourcing/contributor/headhunt')}
                name={`source_cv_text`}
                maxLength={MAX_LENGTH_50}
                errors={errors}
                control={control}
                register={register}
                useWatch={useWatch}
                required
              />
            </Grid>
          )}
          {watchTypeSourceCvValue === 1 && (
            <Grid item md={12} className="item-field">
              <AutoCompleteCustom
                label={t('managerCandidate.fullNameIntroduce')}
                options={dataUser}
                setFilter={setFilter}
                setValueField={setValue}
                register={register}
                name="presenter_id"
                total={uDataUsers?.total}
                isLoading={isLoadingDataUsers}
                error={!Number.isInteger(watchPresenterIdValue) && errors?.presenter_id?.message}
                setDataUser={setDataUser}
                maxLength={MAX_LENGTH_30}
                required={watchTypeSourceCvValue === 1 ? true : false}
                setNamePresenter={setNamePresenter}
                namePresenter={namePresenter}
              />
            </Grid>
          )}
          <Grid item md={12} className="item-field">
            <DateDay
              label={t('managerCandidate.dateApply')}
              name="apply_date"
              error={errors?.apply_date?.message}
              addErrors={errorDate?.apply_date && t('messages.pleaseEnteraDateThatIsLessThanTheCurrentDate')}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              isShowModal={isShowModal}
              defaultValue={null}
              maxDate={moment()}
              isRequired
            />
          </Grid>
          <Grid item xs={12} className="text-modal">
            {t('managerCandidate.infoCV')}
          </Grid>
          <Grid item md={12} className="item-field link-cv-input">
            <CustomInputYup
              label={t('managerCandidate.linkAttchment')}
              name={`link_cv_online`}
              control={control}
              register={register}
              errors={errors}
              addErrors={!attchmentCV && errors?.name?.link_cv_online}
              useWatch={useWatch}
              required={attchmentCV ? false : true}
              maxLength={MAX_LENGTH_250}
            />
          </Grid>
          <Grid item xs={12} className="item-field upload-CV">
            <input
              id={'attchment-cv'}
              type="file"
              name="attchment_cv"
              style={{ display: 'none' }}
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.xlsx,.csv"
              onChange={(event) => onUploadAttchmentCV(event)}
            />
            <label className="upload-file-cv" htmlFor={'attchment-cv'}>
              {<img src={iconAttchmentCV} alt="camera" width={'18px'} height="18px" />}{' '}
              {t('managerCandidate.upFileCvAttchment')}
              <label className="label-CV" htmlFor={'attchment-cv'}>
                {t('managerCandidate.typeFileAccept')}
              </label>
            </label>
            {isLoadingUploadCV && <Loading type={LOADING_SM} addClass="loading-upload-cv" />}
            {!isLoadingUploadCV && attchmentCV && (
              <div className="info-cv-upload">
                <div>
                  <img src={iconCvDefault} alt="camera" width={'60px'} height="60px" />
                </div>
                <div className="cv-name">
                  <p className="title">{attchmentCV.name}</p>
                  <p>{(attchmentCV.size / 1024).toFixed(2) + ' kb'}</p>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} className="text-modal">
            {t('managerCandidate.prequalification')}
          </Grid>
          <Grid item xs={12} className="text-modal item-field">
            <CustomRadioInput
              control={control}
              radioList={radioListPrequalification}
              defaultValue={1}
              name="pass_prequalification"
              setValue={setValue}
            />
          </Grid>
          <Grid item md={6}>
            <ButtonComponent
              text={t('managerJD.cancel')}
              addClass="btn-bg-yellow2 w-100"
              handleClick={() => {
                setShowModal(false);
                handleOpenDetailJob(QUANTITY_CANDIDATE);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <ButtonComponent
              text={t('common.add')}
              addClass="button-submit btn-bg-yellow1 w-100"
              type="submit"
              handleClick={handleSubmit(handleSubmitForm)}
              hasDisabled={stateButton}
              isLoading={stateButton}
            />
          </Grid>
        </Grid>
      </Box>
      <AlertAfterSubmit
        toggle={() => {
          setShowAlert(!isShowAlert);
          setShowModal(false);
          handleOpenDetailJob(QUANTITY_CANDIDATE);
        }}
        isShowAlert={isShowAlert}
      >
        <p>{message}</p>
      </AlertAfterSubmit>
      <AlertAfterSubmit
        toggle={() => {
          setShowAlertError(!isShowAlertError);
        }}
        isShowAlert={isShowAlertError}
      >
        <p>{message}</p>
      </AlertAfterSubmit>
    </>
  );
};

export default CreateCandidateModal;
