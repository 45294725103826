import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { checkInvalidDateByDiagonalLine } from 'helpers/formatDate';
import { MAX_LENGTH_3, MAX_LENGTH_100 } from 'constants/index.js';

export const YupValidateEditCandidate = () => {
  const { t } = useTranslation();
  const regexPhone = /^[0-9]{10,11}$/;
  const regexEmail = /^[a-z0-9][a-z0-9_.]{3,64}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/g;
  const regexFullName =
    /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý ]+$/;

  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .required(t('messages.pleaseEnterThreeToTwentyfiveCharacters'))
      .min(MAX_LENGTH_3, t('messages.pleaseEnterThreeToTwentyfiveCharacters'))
      .matches(regexFullName, t('messages.pleaseOnlyEnterTextNotNumberAndCharacters'))
      .trim(),
    birthday: Yup.string().test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) => 
       value ? checkInvalidDateByDiagonalLine(value) : true,
    ),
    mobile: Yup.string()
      .required(t('messages.pleaseEnterPhoneNumber'))
      .test('valid', t('messages.phoneNumberIsNotValid'), (val) => {
        return !!new RegExp(regexPhone).test(val?.trim());
      }),
    email: Yup.string()
      .required(t('messages.emailRequired'))
      .test('valid', t('messages.emailNumberIsNotValid'), (val) => {
        return !!new RegExp(regexEmail).test(val.toLowerCase());
      })
      .trim(),
    // .email(t('messages.emailNumberIsNotValid'))
    school: Yup.string(),
    candidate_languages:  Yup.array().of(
      Yup.object().shape({
        language_id: Yup.number(),
        level: Yup.string().trim().max(MAX_LENGTH_100, t('common.pleaseEnterLessThanHundredCharacters'))
      })
    ).test('unique', t('messages.languageUnique'), function (value) {
      const ids = value.map(item => item.language_id).filter(item => item !== undefined);
      const uniqueIds = new Set(ids);
      return ids.length === uniqueIds.size;
    }),
    note: Yup.string().trim(),
  });
  return validationSchema;
};

export default YupValidateEditCandidate;
