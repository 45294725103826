import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import styles from './styles.module.scss';
import { customDate } from 'helpers/formatDate';
import { getName, getTextGender, getText, getCandidateLanguages } from 'helpers/index';
import { getTextFreelancer } from 'helpers/masterData';

const InfoCandidate = ({ dataDetailCandidate, dataListSchool, dataListLanguage }) => {
  const { t } = useTranslation();

  return (
    <Grid className="profileBoxInfo">
      <Grid container item xs={12} className={styles.rowContainer}>
        <Grid container item xs={7} className={styles.itemContainer}>
          <Grid item xs={4} className={styles.titleItem}>
            {t('common.fullName')}
          </Grid>
          <Grid item xs={8} className={styles.contentItem}>
            {dataDetailCandidate?.full_name}
          </Grid>
        </Grid>
        <Grid container item xs={5} className={styles.itemContainer}>
          <Grid item xs={5} className={styles.titleItem}>
            {t('common.email')}
          </Grid>
          <Grid item xs={7} className={styles.email}>
            {dataDetailCandidate?.email}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={styles.backgroundHightLight}>
        <Grid container item xs={7} className={styles.itemContainer}>
          <Grid item xs={4} className={styles.titleItem}>
            {t('common.birthday')}
          </Grid>
          <Grid item xs={8} className={styles.contentItem}>
            {dataDetailCandidate?.birthday
              ? customDate(dataDetailCandidate.birthday, 'DD/MM/YYYY')
              : t('common.notDataContract')}
          </Grid>
        </Grid>
        <Grid container item xs={5} className={styles.itemContainer}>
          <Grid item xs={5} className={styles.titleItem}>
            {t('common.phone')}
          </Grid>
          <Grid item xs={7} className={styles.contentItem}>
            {dataDetailCandidate?.mobile}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={styles.rowContainer}>
        <Grid container item xs={7} className={styles.itemContainer}>
          <Grid item xs={4} className={styles.titleItem}>
            {t('common.sex')}
          </Grid>
          <Grid item xs={8} className={styles.contentItem}>
            {getTextGender(dataDetailCandidate?.sex)}
          </Grid>
        </Grid>
        <Grid container item xs={5} className={styles.itemContainer}>
          <Grid item xs={5} className={styles.titleItem}>
            {t('common.school')}
          </Grid>
          <Grid item xs={7} className={styles.contentItem}>
            {getName(dataListSchool, dataDetailCandidate?.school)}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={styles.rowContainer}>
        <Grid container item xs={7} className={styles.itemContainer}>
          <Grid item xs={4} className={styles.titleItem}>
            {t('managerCandidate.language')}
          </Grid>
          <Grid item xs={8} className={styles.contentItem}>
            {getCandidateLanguages(dataDetailCandidate?.candidate_languages, dataListLanguage)}
          </Grid>
        </Grid>
        <Grid container item xs={5} className={styles.itemContainer}>
          <Grid item xs={5} className={styles.titleItem}>
            {t('managerCandidate.candidateType')}
          </Grid>
          <Grid item xs={7} className={styles.contentItem}>
            {getTextFreelancer(dataDetailCandidate?.is_freelancer, t('managerCandidate.candidateFreelancer'), t('managerCandidate.candidateNotFreelancer'))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={styles.rowContainer}>
        <Grid container item xs={7} className={styles.itemContainer}>
          <Grid item xs={4} className={styles.titleItem}>
            {t('managerCandidate.noteCandidate')}
          </Grid>
          <Grid item xs={8} className={styles.contentItem}>
            {getText(dataDetailCandidate?.note)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InfoCandidate;
