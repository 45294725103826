import { MenuItem } from '@mui/material';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { HTTP_OK, HTTP_CONFLICT } from 'constants/StatusCode.js';

import './style.scss';
import Validate from './Validate';
import AlertComponent from 'components/Alert/index.js';
import SelectField from 'components/ReactForm/SelectField';
import { apiCreateOrUpdateJD } from 'api/managerJD';
import { USE_LIST_JD, ACTIVE, DEACTIVE, CODE_DUPLICATE, TYPE_INTERVIEW } from 'constants/ManagerJD';
import ModalComponent from 'components/ModalDoubleCol';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import { errorSubmit } from 'helpers';
import { MAX_LENGTH_30, MAX_LENGTH_5000, MAX_LENGTH_100 } from 'constants/index.js';
import ButtonComponent from 'components/Buttons';
import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import CusromInputNumber from 'components/InputYupAndMui/CusromInputNumber';

/* eslint-disable */
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  ],
};
/* eslint-enable */
const QuillComp = ({ handleReactQuillState, control, useWatch, errors }) => {
  const content = useWatch({ control, name: 'content' });
  return (
    <>
      <ReactQuill value={content} modules={modules} onChange={handleReactQuillState} className="ql-container" />
      <p className="error">{errors.content && errors.content?.message}</p>
    </>
  );
};
const CreateOrUpdateModal = (props) => {
  const { isShowModal, setShowModal, dataPosition, dataLevel, formAdd, setIsNewOrUpdateData } = props;
  const [formData] = useState({
    id: formAdd?.id ? formAdd?.id : null,
    title: formAdd?.id ? formAdd?.title : '',
    level_id: formAdd?.id ? formAdd?.level_id : null,
    position_id: formAdd?.id ? formAdd?.position_id : null,
    type_interview: formAdd?.id ? formAdd?.type_interview : null,
    experience: formAdd?.id ? formAdd?.experience : '',
    limit: formAdd?.id ? formAdd?.limit : '',
    salary: formAdd?.id ? formAdd?.salary : '',
    address: formAdd?.id ? formAdd?.address : '',
    status: formAdd?.id ? formAdd?.status : ACTIVE,
    content: formAdd?.id ? formAdd?.content : '',
  });
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(Validate()),
    mode: 'onChange',
    defaultValues: formData,
  });

  const queryClient = useQueryClient();
  const [stateButton, setStateButton] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (formAdd?.id) {
      reset();
      setValue('status', formAdd?.status);
      setValue('content', formAdd?.content);
    }
    // eslint-disable-next-line
  }, [formAdd]);

  const handleReactQuillState = (content, delta, source, editor) => {
    const text = editor.getHTML(content);
    const replaceText = text.replace(/<\/?[^>]+(>|$)/g, '');

    if (replaceText.length > MAX_LENGTH_5000) setValue('content', text.slice(0, 5000));
    else setValue('content', text);
    if (
      replaceText.length >= MAX_LENGTH_100 &&
      replaceText.length <= MAX_LENGTH_5000 &&
      replaceText.trim().length > 0
    ) {
      clearErrors('content');
    }
  };

  const alertConfirmDuplicate = (dataCreateJD) => {
    confirmAlert({
      title: t('managerJD.positionAndLevelDuplicate'),
      buttons: [
        {
          label: t('managerJD.back'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('managerJD.activeThisJD'),
          className: 'btn-alert-ok',
          onClick: async () => {
            await apiCreateOrUpdateJD(dataCreateJD);
            setShowModal(false);
            queryClient.invalidateQueries(USE_LIST_JD);
          },
        },
      ],
    });
  };

  const handleSubmitForm = async (dataSubmit) => {
    setStateButton(true);
    let response = {};

    response = await apiCreateOrUpdateJD({
      ...dataSubmit,
      id: formAdd?.id,
    });

    if (response?.data?.status === HTTP_OK) {
      if (errorSubmit(response?.data?.code)) {
        setMessage(t(errorSubmit(response?.data?.code)));
      } else {
        formData.id === null
          ? setMessage(t('managerJD.createSuccessfully'))
          : setMessage(t('managerJD.updateSuccessfully'));
      }
      setShowAlert(true);
      setStateButton(false);
      await queryClient.invalidateQueries(USE_LIST_JD);
    }
    if (response?.data?.status === HTTP_CONFLICT && response?.data?.code === CODE_DUPLICATE) {
      alertConfirmDuplicate({
        ...dataSubmit,
        id: formAdd?.id,
        important: true,
      });
    }
    setStateButton(false);
  };

  return (
    <>
      <ModalComponent
        toggle={() => {
          setShowModal(!isShowModal);
          formData?.id && setIsNewOrUpdateData(true);
        }}
        isShowModal={isShowModal}
        title={formAdd?.id ? t('managerJD.editJd') : t('managerJD.createJDIndex')}
        classNameAdd="modal-create-update-JD modal-body-custom"
        classNameBody="body-custom"
      >
        <form>
          <div className="header">
            <div className="text-modal text-header-create-JD">{t('managerJD.infoJD')}</div>
            <div className="header-left">
              <ButtonComponent
                text={t('managerJD.cancel')}
                addClass="button-cancel-custom btn-bg-yellow2"
                handleClick={() => {
                  setShowModal(false);
                  formData?.id && setIsNewOrUpdateData(true);
                }}
              />
              <ButtonComponent
                text={t('managerJD.save')}
                addClass="button-submit-custom btn-bg-yellow1"
                type="submit"
                handleClick={handleSubmit(handleSubmitForm)}
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </div>
          </div>
          <div className="modal-body-custom">
            <div>
              <div className="text-modal text-header-body-modal">{t('managerJD.information')}</div>
              <Row className="mt-4">
                <Col className="md-6">
                  <div className="fieldset-group">
                    <CustomInputYup
                      name="title"
                      label={t('managerJD.titleRecruit')}
                      control={control}
                      errors={errors}
                      defaultValue={formData?.title}
                      required
                      useWatch={useWatch}
                      register={register}
                      maxLength={MAX_LENGTH_100}
                    />
                  </div>
                </Col>
                <Col className="md-6">
                  <div className="fieldset-group">
                    <SelectField
                      addClass="select"
                      control={control}
                      required
                      setValue={setValue}
                      useWatch={useWatch}
                      register={register}
                      isShowModal={isShowModal}
                      isRequired
                      name="type_interview"
                      label={t('managerJD.typeInterview')}
                      error={errors?.type_interview?.message}
                      defaultValue={formData?.type_interview}
                    >
                      {TYPE_INTERVIEW?.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </SelectField>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="md-6">
                  <div className="fieldset-group">
                    <SelectField
                      name="position_id"
                      addClass="select"
                      defaultValue={formData?.position_id}
                      label={t('managerJD.positionRecruit')}
                      error={errors?.position_id?.message}
                      control={control}
                      required
                      setValue={setValue}
                      useWatch={useWatch}
                      register={register}
                      isShowModal={isShowModal}
                      isRequired
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: {
                              xs: 350,
                            },
                          },
                        },
                      }}
                    >
                      {dataPosition &&
                        dataPosition?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </SelectField>
                  </div>
                </Col>
                <Col className="md-6">
                  <div className="fieldset-group">
                    <SelectField
                      name="level_id"
                      addClass="select"
                      defaultValue={formData?.level_id}
                      label={t('managerJD.levelRecruit')}
                      error={errors?.level_id?.message}
                      control={control}
                      required
                      setValue={setValue}
                      useWatch={useWatch}
                      register={register}
                      isShowModal={isShowModal}
                      isRequired
                    >
                      {dataLevel &&
                        dataLevel?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </SelectField>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="md-6">
                  <div className="fieldset-group">
                    <CustomInputYup
                      name="experience"
                      label={t('managerJD.experience')}
                      control={control}
                      errors={errors}
                      defaultValue={formData?.experience}
                      required
                      useWatch={useWatch}
                      register={register}
                      maxLength={MAX_LENGTH_30}
                    />
                  </div>
                </Col>
                <Col className="md-6">
                  <div className="fieldset-group">
                    <CusromInputNumber
                      name="limit"
                      label={t('managerJD.limitRecruit')}
                      defaultValue={formData?.limit}
                      required
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      useWatch={useWatch}
                      register={register}
                      maxLength={MAX_LENGTH_30}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="md-6">
                  <div className="fieldset-group">
                    <CustomInputYup
                      name="salary"
                      label={t('managerJD.salary')}
                      defaultValue={formData?.salary}
                      required
                      control={control}
                      errors={errors}
                      useWatch={useWatch}
                      register={register}
                      maxLength={MAX_LENGTH_30}
                    />
                  </div>
                </Col>
                <Col className="md-6">
                  <div className="fieldset-group">
                    <CustomInputYup
                      name="address"
                      label={t('managerJD.address')}
                      defaultValue={formData?.address}
                      required
                      control={control}
                      errors={errors}
                      useWatch={useWatch}
                      register={register}
                      maxLength={MAX_LENGTH_100}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="modal-status">
              <div className="text-modal text-status">{t('managerJD.status')}</div>
              <Row className="">
                <Controller
                  control={control}
                  name="status"
                  render={() => (
                    <RadioGroup
                      name="status"
                      defaultValue={Number(formData?.status) === Number(ACTIVE) ? ACTIVE : DEACTIVE}
                      className="modal-radio-managerJD"
                    >
                      <FormControlLabel
                        value={ACTIVE}
                        onChange={(e) => setValue('status', e.target.value)}
                        control={
                          <Radio
                            sx={{
                              color: COLOR_CHECKBOX_NOT_ACTIVE,
                              '&.Mui-checked': {
                                color: COLOR_CHECKBOX_CUSTOM,
                              },
                            }}
                          />
                        }
                        className="modal-status-custom"
                        label={t('managerJD.active')}
                      />
                      <FormControlLabel
                        value={DEACTIVE}
                        onChange={(e) => setValue('status', e.target.value)}
                        control={
                          <Radio
                            sx={{
                              color: COLOR_CHECKBOX_NOT_ACTIVE,
                              '&.Mui-checked': {
                                color: COLOR_CHECKBOX_CUSTOM,
                              },
                            }}
                          />
                        }
                        label={t('managerJD.inactive')}
                      />
                    </RadioGroup>
                  )}
                />
              </Row>
            </div>
            <hr />
            <div>
              <div className="text-modal text-header-body-modal mb-10">{t('managerJD.mainInfo')}</div>
              <QuillComp
                handleReactQuillState={handleReactQuillState}
                control={control}
                useWatch={useWatch}
                register={register}
                errors={errors}
              />
            </div>
          </div>
        </form>
      </ModalComponent>
      <AlertComponent
        toggle={() => {
          setShowAlert(!isShowAlert);
          setShowModal(false);
          clearErrors();
          reset();
          formData?.id && setIsNewOrUpdateData(true);
        }}
        isShowAlert={isShowAlert}
      >
        {message}
      </AlertComponent>
    </>
  );
};

export default CreateOrUpdateModal;
