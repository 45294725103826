import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import { Avatar, Box, Divider, Grid, MenuItem } from '@mui/material';
import { ModalFooter } from 'reactstrap';

import styles from './styles.module.scss';

import { useListUsers as useGetInterviewersList } from 'hook/useMasterData';
import { useGetRoomsList } from 'hook/useManagerCandidate';
import { saveInterview } from 'api/interviewScheduleManagement';
import { customDate, checkInvalidDateByDiagonalLine } from 'helpers/formatDate';
import { ROWS_TEXT_AREA_3, MAX_LENGTH_50, MAX_LENGTH_150, DEFAULT_PAGE_MUI } from 'constants/index';
import { HTTP_OK } from 'constants/StatusCode';
import { INTERVIEW_TYPE as TYPE } from 'constants/InterviewScheduleManagement';
import ModalComponent from 'components/Modal';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import DateDay from 'components/ReactForm/DateDay';
import TimePickerCustom from 'components/DateTime/TimePickerCustom';
import CustomAutoComplete from 'components/InputYupAndMui/CustomAutoComplete';
import SelectField from 'components/ReactForm/SelectField';
import { InterviewContext } from './InterviewSchedule';
import CustomRadioInputForm from 'components/InputYupAndMui/CustomRadioInputForm';
import Loading from 'components/Loading';
import ButtonComponent from 'components/Buttons';

const FORM_FIELDS = {
  title: 'title',
  interviewer: 'interviewer_ids',
  interviewDate: 'date',
  startTime: 'start_time',
  endTime: 'end_time',
  location: 'location',
  room: 'room',
  interviewType: 'type',
  interviewLink: 'link',
  note: 'note',
};

const EditModal = (props) => {
  const { t } = useTranslation();
  const { isShowModal, setIsShowModal, selectedDetail, onSuccess } = props;
  const { locations } = useContext(InterviewContext);
  const [showLinkField, setShowLinkField] = useState(!!selectedDetail.link);
  const [interviewerKeyword, setInterviewerKeyword] = useState('');
  const [timeInterview, setTimeInterview] = useState({
    date: selectedDetail.date,
    start_time: moment(selectedDetail.startTime, 'HH:mm'),
    end_time: moment(selectedDetail.endTime, 'HH:mm'),
  });
  const [locationId, setLocationId] = useState(selectedDetail.locationId);

  const { data: interviewersList } = useGetInterviewersList({
    key_word: interviewerKeyword,
    page: DEFAULT_PAGE_MUI,
  });
  const handleGetDateTime = (keyField) => {
    if (locationId && timeInterview.date && timeInterview.start_time && timeInterview.end_time) {
      return (
        moment(timeInterview.date, 'DD/MM/YYYY').format('YYYY-MM-DD') +
        ' ' +
        customDate(timeInterview[keyField], 'HH:mm:ss')
      );
    }
  };

  const { data: roomsList, isLoading: isLoadingRoom } = useGetRoomsList({
    location_id: timeInterview.start_time && timeInterview.end_time && locationId,
    startTime: handleGetDateTime('start_time'),
    endTime: handleGetDateTime('end_time'),
  });

  const DEFAULT_VALUES = {
    [FORM_FIELDS.title]: selectedDetail.title,
    [FORM_FIELDS.interviewer]: selectedDetail.interviewers,
    [FORM_FIELDS.interviewDate]: selectedDetail.date,
    [FORM_FIELDS.startTime]: moment(selectedDetail.startTime, 'HH:mm'),
    [FORM_FIELDS.endTime]: moment(selectedDetail.endTime, 'HH:mm'),
    [FORM_FIELDS.location]: selectedDetail.locationId + '',
    [FORM_FIELDS.room]: selectedDetail.roomId + '',
    [FORM_FIELDS.interviewType]: `${selectedDetail.type || TYPE.OFFLINE}`,
    [FORM_FIELDS.interviewLink]: selectedDetail.link || '',
    [FORM_FIELDS.note]: selectedDetail.note || '',
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        [FORM_FIELDS.title]: Yup.string().required(t('messages.pleaseEnterOneToFiftyCharacters')),
        [FORM_FIELDS.interviewer]: Yup.array().min(1, t('messages.pleaseEnterInterviewerName')),
        [FORM_FIELDS.interviewDate]: Yup.string()
          .required(t('messages.pleaseEnterInterviewDate'))
          .test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) => {
            return checkInvalidDateByDiagonalLine(value);
          })
          .nullable(),
        [FORM_FIELDS.location]: Yup.string().required(t('messages.pleaseEnterLocation')),
        [FORM_FIELDS.room]: Yup.string().required(t('messages.pleaseEnterRoom')).nullable(),
        [FORM_FIELDS.interviewLink]: showLinkField && Yup.string().required(t('messages.pleaseEnterInterviewLink')),
        [FORM_FIELDS.startTime]: Yup.string()
          .required(t('messages.pleaseEnterInterviewTime'))
          .test('min', t('messages.pleaseEnterInterviewTimeMoreNow'), (val) => {
            return timeInterview.date === moment().format('DD/MM/YYYY') ? moment().isBefore(moment(val)) : true;
          })
          .nullable(),
        [FORM_FIELDS.endTime]: Yup.string()
          .required(t('messages.pleaseEnterInterviewTime'))
          .test('min', t('messages.pleaseEnterInterviewTimeMoreStartTime'), (val) => {
            return timeInterview.start_time ? moment(val).isAfter(moment(timeInterview.start_time)) : true;
          })
          .nullable(),
      }),
    ),
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const watchStartTime = useWatch({
    control,
    name: FORM_FIELDS.startTime,
  });

  const watchEndTime = useWatch({
    control,
    name: FORM_FIELDS.endTime,
  });

  const watchDate = useWatch({
    control,
    name: FORM_FIELDS.interviewDate,
  });

  useEffect(() => {
    setTimeInterview({ ...timeInterview, start_time: watchStartTime });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStartTime]);

  useEffect(() => {
    setTimeInterview({ ...timeInterview, end_time: watchEndTime });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEndTime]);

  useEffect(() => {
    if (
      !(
        moment(watchStartTime).isSame(moment(selectedDetail.startTime, 'HH:mm'), 'minutes') &&
        moment(watchEndTime).isSame(moment(selectedDetail.endTime, 'HH:mm'), 'minutes') &&
        moment(watchDate, 'DD/MM/YYYY').isSame(moment(selectedDetail.date, 'DD/MM/YYYY'), 'day')
      )
    ) {
      setValue(FORM_FIELDS.room, null);
    }
    if (
      timeInterview.date === moment().format('DD/MM/YYYY') &&
      watchStartTime &&
      moment(watchStartTime).isValid() &&
      moment(watchStartTime).isBefore(moment())
    ) {
      setError(FORM_FIELDS.startTime, {
        type: 'required',
        message: t('messages.pleaseEnterInterviewTimeMoreNow'),
      });
    }
    if (watchDate && timeInterview.date !== moment().format('DD/MM/YYYY')) {
      setError(FORM_FIELDS.startTime, {
        type: 'required',
        message: null,
      });
    }
    if (watchStartTime && watchEndTime && moment(watchStartTime).isValid()) {
      const month = watchStartTime.month();
      const date = watchStartTime.date();
      watchEndTime.set('month', month);
      watchEndTime.set('date', date);
      if (watchEndTime && watchStartTime && watchEndTime.isBefore(watchStartTime)) {
        setError(FORM_FIELDS.endTime, {
          type: 'required',
          message: t('messages.pleaseEnterInterviewTimeMoreStartTime'),
        });
      } else {
        setError(FORM_FIELDS.endTime, '');
      }
    } else {
      if (watchEndTime && watchStartTime && watchEndTime.isBefore(watchStartTime)) {
        setError(FORM_FIELDS.endTime, {
          type: 'required',
          message: t('messages.pleaseEnterInterviewTimeMoreStartTime'),
        });
      }
    }
    // eslint-disable-next-line
  }, [watchStartTime, watchEndTime, watchDate]);

  const fields = {
    info: [
      {
        name: 'candidate',
        label: t('interviewSchedule.detailModal.label.fullNameCandidate'),
        value: (() => {
          const detailCandidateLink = (
            <a
              href={`/admin/candidate-detail/${selectedDetail.candidateId}`}
              target="_blank"
              rel="noreferrer"
            >
              {t('interviewSchedule.detailModal.linkToDetailCandidate')}
            </a>
          );
          return (
            <span>
              {selectedDetail.candidateName} ({detailCandidateLink})
            </span>
          );
        })(),
        md: 12,
        xs: 12,
      },
      {
        name: 'position',
        label: t('interviewSchedule.detailModal.label.position'),
        value: selectedDetail.positionName,
        md: 4,
        xs: 4,
      },
      {
        name: 'level',
        label: t('interviewSchedule.detailModal.label.level'),
        value: selectedDetail.levelName,
        md: 4,
        xs: 4,
      },
      {
        name: 'skill',
        label: t('interviewSchedule.detailModal.label.skill'),
        value: selectedDetail.skillName,
        md: 4,
        xs: 4,
      },
    ],
  };

  const interviewTypeOptions = [
    { value: TYPE.OFFLINE, labelName: t('interviewSchedule.editModal.interviewType.offline') },
    { value: TYPE.ONLINE, labelName: t('interviewSchedule.editModal.interviewType.online') },
  ];

  const handleCloseModal = () => {
    setIsShowModal(false);
    reset();
  };

  const { mutate: submit, isLoading: isLoadingSubmit } = useMutation(
    (values) => {
      const payload = {
        id: selectedDetail.id,
        title: values[FORM_FIELDS.title],
        apply_id: selectedDetail.apply_id,
        attendees: values[FORM_FIELDS.interviewer],
        date: moment(values[FORM_FIELDS.interviewDate], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        start_time: moment(values[FORM_FIELDS.startTime]).format('HH:mm'),
        end_time: moment(values[FORM_FIELDS.endTime]).format('HH:mm'),
        room_id: values[FORM_FIELDS.room],
        type: values[FORM_FIELDS.interviewType],
        link: +values[FORM_FIELDS.interviewType] === TYPE.ONLINE ? values[FORM_FIELDS.interviewLink] : null,
        note: values[FORM_FIELDS.note],
        ms_event_id: selectedDetail.ms_event_id,
        status: selectedDetail.status,
      };
      return saveInterview(payload);
    },
    {
      onSuccess: (response) => {
        if (response?.status === HTTP_OK) {
          setIsShowModal(false);
          reset();
          onSuccess();
        } else {
          response?.data?.messages &&
            Object.entries(response.data.messages).forEach(([key, message]) => {
              setError(key, { type: 'custom', message });
            });
        }
      },
    },
  );

  const handleChangeRadio = (name, value) => {
    if (Number(value) === TYPE.ONLINE) setShowLinkField(true);
    if (Number(value) === TYPE.OFFLINE) setShowLinkField(false);
    setValue(name, value);
  };

  const CustomLabelOption = ({ option }) => (
    <div key={option.user_id} className={styles.customLabel}>
      <div className={styles.avatarLabel}>
        <Avatar src={option.avatar} />
      </div>
      <div className={styles.infoLabel}>
        <div className={styles.name}>{option.name}</div>
        <div>{option.email}</div>
      </div>
    </div>
  );

  const addNameObjectKey = (arr) => {
    if (!arr) return;
    return arr.filter((object) => {
      object.name = object.user_name;
      return object;
    });
  };

  const handleSetValueDate = (name, value) => {
    setTimeInterview({ ...timeInterview, [name]: value });
    setValue(name, value);
  };

  const checkFirstRenderRoomName = () => {
    if (!timeInterview.start_time || !timeInterview.end_time) return false;
    if (
      locationId === selectedDetail.locationId &&
      timeInterview.date === selectedDetail.date.format('DD/MM/YYYY') &&
      timeInterview.start_time.format('HH:mm') === moment(selectedDetail.startTime, 'HH:mm').format('HH:mm') &&
      timeInterview.end_time.format('HH:mm') === moment(selectedDetail.endTime, 'HH:mm').format('HH:mm')
    )
      return true;
    return false;
  };

  return (
    <ModalComponent
      title={t('interviewSchedule.editModal.title')}
      isShowModal={isShowModal}
      toggle={handleCloseModal}
      renderModalFooter={() => (
        <ModalFooter className={styles.modalFooter}>
          <button type="button" className={classNames(styles.btn, styles.btn__cancel)} onClick={handleCloseModal}>
            {t('interviewSchedule.editModal.cancel')}
          </button>
          <ButtonComponent
            text={t('interviewSchedule.editModal.save')}
            addClass={classNames(styles.btn, styles.btn__action)}
            handleClick={handleSubmit(submit)}
            isLoading={isLoadingSubmit}
            hasDisabled={isLoadingSubmit}
          />
        </ModalFooter>
      )}
      classNameAdd={styles.editModal}
    >
      <Box className={styles.editModal__info}>
        <Box className={styles.title}>{t('interviewSchedule.editModal.info')}</Box>
        <Grid container className={styles.detailFields} rowSpacing={1.15} columnSpacing={2}>
          {fields.info.map((field) => {
            return (
              <Grid item xs={field.xs} md={field.md} key={field.name}>
                <Box className={styles.field}>
                  <Box className={styles.field__label}>{field.label}</Box>
                  <Box className={styles.field__value}>{field.value}</Box>
                  <Divider />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box className={styles.editModal__form}>
        <Box className={styles.title}>{t('interviewSchedule.editModal.form')}</Box>
        <form>
          <Grid container rowSpacing={1.15} columnSpacing={2}>
            <Grid item xs={12} md={12}>
              <CustomInputYup
                name={FORM_FIELDS.title}
                label={t('interviewSchedule.editModal.label.title')}
                control={control}
                useWatch={useWatch}
                register={register}
                errors={errors}
                required
                maxLength={MAX_LENGTH_50}
                defaultValue={DEFAULT_VALUES[FORM_FIELDS.title]}
              />
            </Grid>
            <Grid item xs={12} md={12} className={styles.interviewer}>
              <CustomAutoComplete
                name={FORM_FIELDS.interviewer}
                label={t('managerCandidate.enterTagName') + '*'}
                control={control}
                setValue={setValue}
                setError={setError}
                register={register}
                errors={errors}
                valueAutoFill={addNameObjectKey(interviewersList)}
                CustomLabelOption={CustomLabelOption}
                handleSearch={(keyword) => setInterviewerKeyword(keyword)}
                defaultValue={DEFAULT_VALUES[FORM_FIELDS.interviewer]}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <DateDay
                label={t('managerCandidate.dateInterview')}
                name={FORM_FIELDS.interviewDate}
                control={control}
                error={errors[FORM_FIELDS.interviewDate]?.message}
                setValue={handleSetValueDate}
                register={register}
                setError={setError}
                isShowModal={isShowModal}
                isRequired
                minDate={moment()}
                defaultValue={DEFAULT_VALUES[FORM_FIELDS.interviewDate]}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePickerCustom
                placeholder={t('interviewSchedule.detailModal.label.startTime')}
                name={FORM_FIELDS.startTime}
                value={watchStartTime}
                format="HH:mm"
                setValue={setValue}
                setError={setError}
                handleChangeTime={(time) => {
                  handleSetValueDate(
                    FORM_FIELDS.startTime,
                    moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null,
                  );
                }}
                error={errors[FORM_FIELDS.startTime]?.message}
                disabled={!timeInterview.date}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePickerCustom
                placeholder={t('interviewSchedule.detailModal.label.endTime')}
                name={FORM_FIELDS.endTime}
                value={watchEndTime}
                format="HH:mm"
                setValue={setValue}
                setError={setError}
                handleChangeTime={(time) => {
                  handleSetValueDate(
                    FORM_FIELDS.endTime,
                    moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null,
                  );
                }}
                error={errors[FORM_FIELDS.endTime]?.message}
                disabled={!timeInterview.date}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <SelectField
                name={FORM_FIELDS.location}
                label={t('interviewSchedule.detailModal.label.location')}
                error={errors[FORM_FIELDS.location]?.message}
                control={control}
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                defaultValue={DEFAULT_VALUES[FORM_FIELDS.location]}
              >
                {locations?.map((item) => (
                  <MenuItem value={item.id} key={item.id} onClick={() => setLocationId(item.id)}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12} md={12}>
              <SelectField
                name={FORM_FIELDS.room}
                label={t('interviewSchedule.detailModal.label.room')}
                error={errors[FORM_FIELDS.room]?.message}
                control={control}
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                defaultValue={DEFAULT_VALUES[FORM_FIELDS.room]}
                disabled={!locationId || !timeInterview.start_time || !timeInterview.end_time}
                onClick={() => {
                  if (!locationId && timeInterview.start_time && timeInterview.end_time) {
                    setError(FORM_FIELDS.location, { type: 'custom', message: t('messages.pleaseEnterLocation') });
                  }
                }}
              >
                {isLoadingRoom && <Loading />}
                {!isLoadingRoom &&
                  roomsList?.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                {checkFirstRenderRoomName() && (
                  <MenuItem value={selectedDetail.roomId}>{selectedDetail.roomName}</MenuItem>
                )}
              </SelectField>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className={styles.title}>{t('interviewSchedule.editModal.label.interviewType')}</Box>
              <CustomRadioInputForm
                name={FORM_FIELDS.interviewType}
                control={control}
                setValue={handleChangeRadio}
                radioList={interviewTypeOptions}
                defaultValue={DEFAULT_VALUES[FORM_FIELDS.interviewType]}
              />
            </Grid>
            {showLinkField && (
              <Grid item xs={12} md={12}>
                <CustomInputYup
                  name={FORM_FIELDS.interviewLink}
                  label={t('interviewSchedule.editModal.label.interviewLink')}
                  control={control}
                  useWatch={useWatch}
                  register={register}
                  errors={errors}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <CustomInputYup
                maxLength={MAX_LENGTH_150}
                name={FORM_FIELDS.note}
                label={t('interviewSchedule.editModal.label.note')}
                control={control}
                useWatch={useWatch}
                register={register}
                errors={errors}
                defaultValue={DEFAULT_VALUES[FORM_FIELDS.note]}
                multiline
                rows={ROWS_TEXT_AREA_3}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </ModalComponent>
  );
};

export default EditModal;
