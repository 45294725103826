import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';

import { Button, Grid, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Loading from 'components/Loading';
import { useGetListDivision } from 'hook/useDivision';
import { getLevelName, getPositionName, getSkillName, getDivisionName } from 'helpers/masterData';
import { getNameStatus, getColorStatus, isArrayContainAnotherArray, getCandidateLanguages } from 'helpers/index';
import { displayLabelTablePagination } from 'helpers/table';
import { customDate } from 'helpers/formatDate';

import { ThemContext } from 'layouts/Admin';
import * as Constants from 'constants/index.js';
import { LIST_STATUS } from 'constants/ManagerCandidate';
import { ZERO } from 'constants/MasterData';
import DetailStatusCard from 'components/DetailStatusCard';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import { MdDelete } from 'react-icons/md';
import { STATUS_CANDIDATE } from 'constants/ManagerCandidate';
import PopupAreYouSure from 'components/PopupAreYouSure';
import { sendDelete } from 'helpers/axios';

const ListCandidate = ({
  type,
  dataCandidates,
  isLoadingDataStandardIndex,
  handleCountCheckedBox,
  filter,
  dataPosition,
  dataLevel,
  dataSkill,
  dataListLanguage,
  handleChangePage,
  handleChangeRowsPerPage,
  candidateCheckedBox,
  handleCheckedAll,
  refetchDataCandidate,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { dataInfoUser, isHRM, isAssigner } = useContext(ThemContext);
  const { data: uListDivision } = useGetListDivision();
  const [showAlert, setShowAlert] = useState({ candidateName: '', active: false });
  const [isDeleteCandidate, setIsDeleteCandidate] = useState(false);
  const [isDeleted, setIsDeleted] = useState({ status: 200, active: false });
  const [candidate, setCandidate] = useState({});

  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${ZERO}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  $(function scroll() {
    $('.wrapper-top').scroll(function () {
      $('.scroll-custom').scrollLeft($('.wrapper-top').scrollLeft());
    });
    $('.scroll-custom').scroll(function () {
      $('.wrapper-top').scrollLeft($('.scroll-custom').scrollLeft());
    });
  });

  const formatFieldKeyList = (list, idDiv) => {
    return list.map((item) => {
      const { user_info, status } = item;
      return {
        status_id: status,
        status: getNameStatus(LIST_STATUS, status, idDiv, uListDivision),
        name: user_info?.name,
        avatar: user_info?.avatar,
      };
    });
  };

  const handleClickRowCandidate = (item) => {
    if ((type === 'hr' && !isHRM && item.hr_id === dataInfoUser.user_id) || type === 'leader' || isHRM)
      history.push(`/admin/candidate-detail/${item.id}`);
    else setShowAlert({ candidateName: item.full_name, active: true });
  };

  const handleClickDeleteButton = (item) => {
    setCandidate(item);
    setIsDeleteCandidate(true);
  };

  const handleCancel = () => {
    setIsDeleteCandidate(false);
  };

  const handleDeleteCandidate = async () => {
    const res = await sendDelete(`/api/candidate/delete`, { id: candidate.id });
    setIsDeleteCandidate(false);
    setIsDeleted({ status: res?.data?.status, active: true });
    refetchDataCandidate();
  };

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => (
    <>
      <Button
        sx={{
          fontWeight: 400,
          fontSize: 14,
          lineHeight: `16px`,
          letterSpacing: 1.25,
          textTransform: `uppercase`,
          color: `#8d8d8d`,
        }}
        onClick={onClickCancel}
      >
        {t('managerJob.cancelVN')}
      </Button>
      <Button
        sx={{
          fontWeight: 400,
          fontSize: 14,
          lineHeight: `16px`,
          letterSpacing: 1.25,
          textTransform: `uppercase`,
          color: `#fea628`,
        }}
        onClick={onClickOk}
      >
        {t('managerJob.delete')}
      </Button>
    </>
  );

  return (
    <>
      <AlertAfterSubmit
        isShowAlert={showAlert.active}
        toggle={() => setShowAlert({ candidateName: '', active: false })}
      >
        <p>{t('managerCandidate.candidateIsInChargeOfAnotherHR', { candidateName: showAlert.candidateName })}</p>
      </AlertAfterSubmit>
      <PopupAreYouSure
        active={isDeleteCandidate}
        handleCancel={() => handleCancel()}
        messages={
          <span
            dangerouslySetInnerHTML={{ __html: t('managerCandidate.deleteCandidate', { name: candidate?.full_name }) }}
          ></span>
        }
        footer={<FooterPopupConfirm onClickCancel={() => handleCancel()} onClickOk={() => handleDeleteCandidate()} />}
      />

      <AlertAfterSubmit
        isShowAlert={isDeleted.active}
        toggle={() => setIsDeleted({ candidateName: '', active: false })}
      >
        <p>{isDeleted.status === 200 ? t('managerCandidate.successDelete') : t('managerCandidate.failDelete')}</p>
      </AlertAfterSubmit>
      <div className="wrapper-top">
        <p className="top">
          <span>{t('common.export')}</span>
        </p>
      </div>
      <Grid container className="pt-0">
        <div className="table-border-show">
          <Paper>
            <TableContainer className="scroll-custom">
              <Table aria-label="sticky table" className="table-scroll">
                <TableHead className="asset-header">
                  <TableRow className="asset-header-row headTable">
                    <TableCell className="table-content id-standard checkbox-permanent">
                      {isHRM && type === 'hr' && (
                        <input
                          type="checkbox"
                          className="checkbox"
                          onChange={() => handleCheckedAll(dataCandidates?.data.map(({ id }) => id))}
                          checked={isArrayContainAnotherArray(
                            candidateCheckedBox,
                            dataCandidates?.data.map(({ id }) => id),
                          )}
                        />
                      )}
                    </TableCell>
                    <TableCell className="stt">{t('common.STT')}</TableCell>
                    <TableCell>{t('managerCandidate.name')}</TableCell>
                    <TableCell>{t('masterData.StandardIndex.position')}</TableCell>
                    <TableCell>{t('managerCandidate.status')}</TableCell>
                    <TableCell>{t('masterData.StandardIndex.skill')}</TableCell>
                    <TableCell>{t('masterData.StandardIndex.levelApply')}</TableCell>
                    <TableCell>{t('managerCandidate.language')}</TableCell>
                    <TableCell>{t('managerCandidate.applyDate')}</TableCell>
                    <TableCell>{t('managerCandidate.dayApply')}</TableCell>
                    {type !== 'leader' && <TableCell>{t('managerCandidate.divisionOrder')}</TableCell>}
                    <TableCell>{t('managerCandidate.HRCharge')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingDataStandardIndex && (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Loading addClass="mt-3 mb-3" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!isLoadingDataStandardIndex &&
                    dataCandidates?.data &&
                    dataCandidates?.data.map((item, index) => {
                      return (
                        <TableRow key={index} onClick={() => handleClickRowCandidate(item)}>
                          <TableCell className="table-content id-standard checkbox-permanent">
                            {((type === 'hr' && !isHRM && item.hr_id === dataInfoUser.user_id) ||
                              type === 'leader' ||
                              isHRM) && (
                              <input
                                type="checkbox"
                                className="checkbox"
                                onChange={() => handleCountCheckedBox(item.id)}
                                onClick={(e) => e.stopPropagation()}
                                checked={candidateCheckedBox?.includes(item.id)}
                              />
                            )}
                          </TableCell>
                          <TableCell className="table-content id-standard stt">
                            {getSTT(index + filter.page * filter.per_page)}
                          </TableCell>
                          <TableCell className="table-content id-standard field-cell-w150">
                            <p className="name">{item?.full_name}</p>
                          </TableCell>
                          <TableCell className="table-content field-cell-w80">
                            {item.position_id
                              ? getPositionName(item?.position_id, dataPosition)
                              : t('common.notDataContract')}
                          </TableCell>
                          <TableCell
                            className={`${getColorStatus(LIST_STATUS, item?.status)} table-content field-cell-w150`}
                          >
                            <Tooltip
                              title={
                                (isAssigner || type === 'hr') && (
                                  <DetailStatusCard
                                    list={formatFieldKeyList(item.list_action_leader, item.division_id)}
                                    fieldKey={'status'}
                                    fieldKeyClass={'status_id'}
                                  />
                                )
                              }
                              arrow={isAssigner || type === 'hr'}
                            >
                              <span>{getNameStatus(LIST_STATUS, item?.status, item.division_id, uListDivision)}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell className="table-content field-cell-w80">
                            {item.skill_id ? getSkillName(item?.skill_id, dataSkill) : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content field-cell-w80">
                            {item.level_id ? getLevelName(item?.level_id, dataLevel) : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content field-cell-w80">
                            {getCandidateLanguages(item?.candidate_languages, dataListLanguage, false)}
                          </TableCell>
                          <TableCell className="table-content field-cell-w80">
                            {customDate(item.interview_date, 'DD/MM/YYYY')}
                          </TableCell>
                          <TableCell className="table-content field-cell-w80">
                            {customDate(item.apply_date, 'DD/MM/YYYY')}
                          </TableCell>
                          {type !== 'leader' && (
                            <>
                              {item.division_order ? (
                                <Tooltip
                                  title={
                                    <div className="tooltip-division-order">
                                      {item.division_order?.map((val, index) => (
                                        <div className="text-division" key={index}>
                                          {getDivisionName(val, uListDivision)}
                                        </div>
                                      ))}
                                    </div>
                                  }
                                  arrow
                                  placement="bottom-start"
                                  PopperProps={{
                                    sx: {
                                      '& 	.MuiTooltip-tooltipArrow': {
                                        top: '-30px !important',
                                      },
                                    },
                                  }}
                                >
                                  <TableCell className="table-content text-division field-cell-w80">
                                    <span>
                                      {item.division_order
                                        ?.map((val) => getDivisionName(val, uListDivision))
                                        .join(', ')}
                                    </span>
                                  </TableCell>
                                </Tooltip>
                              ) : (
                                <TableCell className="field-cell-w80" />
                              )}
                            </>
                          )}
                          <TableCell className="table-content field-cell-w150">
                            <p>{item?.user_info?.name}</p>
                          </TableCell>
                          <TableCell className="table-content field-cell-w50 id-standard stt">
                            {((type === 'hr' && !isHRM && item.hr_id === dataInfoUser.user_id) ||
                              type === 'leader' ||
                              isHRM) &&
                              (item.status === STATUS_CANDIDATE.FAIL_PRE_QUALIFICATION ||
                                item.status === STATUS_CANDIDATE.PASS_PRE_QUALIFICATION) && (
                                <MdDelete
                                  size={25}
                                  onClick={(e) => {
                                    handleClickDeleteButton(item);
                                    e.stopPropagation();
                                  }}
                                />
                              )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {dataCandidates?.total === ZERO && (
                    <tr>
                      <td colSpan={10} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {dataCandidates?.total > ZERO && (
              <TablePagination
                rowsPerPageOptions={Constants.PER_PAGE_OPTION_20}
                component="div"
                count={dataCandidates?.total}
                rowsPerPage={filter?.per_page}
                page={filter.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
                labelDisplayedRows={displayLabelTablePagination}
              />
            )}
          </Paper>
        </div>
      </Grid>
    </>
  );
};

export default ListCandidate;
