import { useQuery } from 'react-query';

import { USE_INFO_USER } from 'constants/Auth';
import { getInforUser } from '../api/auth';

export function useInforAuth() {
  const systemRecruit = 2;
  const {
    data: response,
    isLoading,
    error,
  } = useQuery(USE_INFO_USER, async () => {
    const response = await getInforUser({ system_id: systemRecruit });

    return response;
  });
  const data = response?.data;

  return {
    ...data,
    isLoading,
    error,
    permission: data?.data?.hr_permission,
    positionId: data?.data?.position_id,
    status: response?.status,
  };
}
