import React from 'react';
import { t } from 'i18next';

import { Tooltip } from '@mui/material';

import Acc1 from 'assets/img/icons/recruit-acc1.svg';
import Acc2 from 'assets/img/icons/recruit-acc2.svg';
import Acc3 from 'assets/img/icons/recruit-acc3.svg';

import { STATUS_PROCESSING, STATUS_COMPLETED } from 'constants/manageJob';
import { useListLevel } from 'hook/useMasterData';

import { getClassBox } from 'helpers/manageJob';
import { getTextStatus } from 'helpers/manageJob';
import { formatNullToZero } from 'helpers/format';
import { getLevelName } from 'helpers/masterData';

const getImageAcc = (status) => {
  if (status === STATUS_PROCESSING) return <img src={Acc1} alt={Acc1} />;
  if (status === STATUS_COMPLETED) return <img src={Acc2} alt={Acc2} />;
  return <img src={Acc3} alt={Acc3} />;
};

const BoxJobItem = ({ item, mapPosition, mapSkill }) => {
  const { data: uDataLevel } = useListLevel();
  return (
    <div className={`box-job-item ${getClassBox(item.status)}`}>
      <div className="header d-flex align-items-center">
        <div className="avatar">{getImageAcc(item.status)}</div>
        <div className="title">
          <span>{item.name}</span>
        </div>
      </div>
      <div className="divider mb-10" />
      <div className="content mb-10">
        <div className="item-label d-flex align-items-center justify-content-between">
          <span>{t('managerJob.position')}</span>
          <span>{mapPosition[item.position_id]}</span>
        </div>
        <div className="item-label d-flex align-items-center justify-content-between">
          <span>{t('managerJob.level')}</span>
          <Tooltip
            title={
              <div className="tooltip--level">
                {item.level_id?.map((val) => getLevelName(val, uDataLevel)).join(', ')}
              </div>
            }
            arrow
          >
            <span className="text-response-level">
              {item.level_id?.map((val) => getLevelName(val, uDataLevel)).join(', ')}
            </span>
          </Tooltip>
        </div>
        <div className="item-label d-flex align-items-center justify-content-between">
          <span>{t('managerJob.skill')}</span>
          <span>{mapSkill[item.skill_id]}</span>
        </div>
        <div className="item-label d-flex align-items-center justify-content-between">
          <span>{t('managerJob.totalOrder')}</span>
          <span>{formatNullToZero(item.total_order)}</span>
        </div>
        <div className="item-label d-flex align-items-center justify-content-between">
          <span>{t('managerJob.totalRecruit')}</span>
          <span>{formatNullToZero(item.total_recruited) + '/' + formatNullToZero(item.total_target)}</span>
        </div>
      </div>
      <div className="divider mb-10" />
      <div className="footer d-flex align-items-center justify-content-between">
        <div className="rtl-left">
          <span>{t('managerJob.status')}</span>
        </div>
        <div className="rtl-right">
          <span>{getTextStatus(t, item.status)}</span>
        </div>
      </div>
    </div>
  );
};

export default BoxJobItem;
