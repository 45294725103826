import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Grid, InputBase, TablePagination, Paper } from '@mui/material';
import { debounce } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';

import moment from 'moment';

import iconLoading from 'assets/img/icons/loading.png';
import iconExport from 'assets/img/icons/export-blue.svg';

import styles from './styles.module.scss';
import { Div } from './styled';

import Button from 'components/Buttons/index';
import ButtonComponent from 'components/Buttons';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import PopupAreYouSure from 'components/PopupAreYouSure';
import SearchComponent from 'components/Search';

import TableForHrm from './TableForHrm';
import TableForLeader from './TableForLeader';

import { apiExportCostHrm, apiConfirmCost } from 'api/managerCost';

import { useGetListCostLeader, useGetListCostHrm } from 'hook/useManageCost';
import { useGetListDivision } from 'hook/useDivision';
import { useListSkill, useListLevel, useListPosition } from 'hook/useMasterData';

import { setDataAllModalWithStatus, getValueInputSearchAll, showAlert, showAlertError } from 'helpers/modal';
import { displayLabelTablePagination } from 'helpers/table';
import { sendPost } from 'helpers/axios';

import * as Constants from 'constants/index.js';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20, PER_PAGE_OPTION_20 } from 'constants/index';
import { ZERO } from 'constants/MasterData';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_EXPECTATION_FAILED } from 'constants/StatusCode';
import { ThemContext } from 'layouts/Admin';
import { LIST_COST_RECRUIT_PERMISSION } from 'constants/permission';
import EditLayout from 'components/PermissionPage/EditLayout';

const CHANGE_MONTH = {
  NEXT: 1,
  PREVIOUS: -1,
};

const FILTER_DEFAULT = {
  page: DEFAULT_PAGE_MUI,
  limit: DEFAULT_LIMIT_20,
  date: moment().startOf('month'),
};

const ListCostRecruitLeader = () => {
  const { t } = useTranslation();

  const query = useQueryClient();

  const [isModals, setIsModals] = useState({ status: false });
  const [filter, setFilter] = useState(FILTER_DEFAULT);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [dataLevel, setDataLevel] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [filterDivision, setFilterDivision] = useState([]);
  const [openPopupErrorConfirm, setPopupErrorConfirm] = useState(false);
  const mapLevel = useRef({});
  const mapSkill = useRef({});
  const mapPosition = useRef({});
  const mapDivision = useRef({});

  const { isLeader } = useContext(ThemContext);

  const { data: dataListCostLeader, isLoading: isLoadingCostLeader } = useGetListCostLeader(
    {
      ...filter,
      page: filter.page + 1,
      date: filter.date.format('YYYY-MM'),
    },
    { enabled: isLeader || false },
  );
  const { data: dataListCostHrm, isLoading: isLoadingCostHrm } = useGetListCostHrm(
    {
      ...filter,
      page: filter.page + 1,
      date: filter.date.format('YYYY-MM'),
    },
    { enabled: Object.keys(mapLevel.current).length > 0 && !isLeader },
  );
  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });
  const { data: uDataLevel } = useListLevel({ key_word: '' });
  const { data: uListDivision } = useGetListDivision();

  const { mutate: handleHrmConfirmed } = useMutation((payload) => apiConfirmCost(payload), {
    onSuccess: () => {
      query.refetchQueries([Constants.MANAGE_LIST_COST_LEADER.getListCostHrm]);
    },
    onError: () => {
      showAlert(t('common.anUnknownError'));
    },
  });
  const { mutate: handleExportCost, isLoading: exportLoading } = useMutation((payload) => apiExportCostHrm(payload), {
    onSuccess: (res) => {
      if (res.status === 200) {
        const encodedUri = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'recruit_cost.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        if (res.status === HTTP_UNPROCESSABLE_ENTITY) {
          showAlertError(t('common.notExportNowMonthAndFutureMonth'));
        } else if (res.status === HTTP_EXPECTATION_FAILED) {
          showAlertError(t('common.noListNotExport'));
        } else {
          showAlert(t('common.anUnknownError'));
        }
      }
    },
    onError: (error) => {
      if (error?.message) {
        showAlert(error?.message);
      } else if (error?.data?.message) {
        showAlert(error?.data?.message);
      } else {
        showAlert(t('common.anUnknownError'));
      }
    },
  });
  const { mutate: handleLeaderConfirmed } = useMutation(
    (payload) => sendPost('/api/masterdata/recruit-cost/leader/confirm', payload),
    {
      onSuccess: () => {
        query.refetchQueries([Constants.MANAGE_LIST_COST_LEADER.getListCostLeader]);
      },
      onError: () => {
        showAlert(t('common.anUnknownError'));
      },
    },
  );

  const hrmConfirmed = dataListCostHrm?.data?.is_hrm_confirmation === 1;
  const leaderConfirmed = dataListCostLeader?.data?.is_leader_confirmation === 1;
  const total = isLeader
    ? dataListCostLeader?.data?.candidate_divisions?.total
    : dataListCostHrm?.data?.candidate_divisions?.total;

  useEffect(() => {
    if (uDataSkill) {
      const skill = uDataSkill.map((item) => {
        mapSkill.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataSkill([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...skill,
      ]);
    }
  }, [uDataSkill, t]);

  useEffect(() => {
    if (uDataLevel) {
      const level = uDataLevel.map((item) => {
        mapLevel.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataLevel([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...level,
      ]);
    }
  }, [uDataLevel, t]);

  useEffect(() => {
    if (uDataPosition) {
      const position = uDataPosition.map((item) => {
        mapPosition.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataPosition([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...position,
      ]);
    }
  }, [uDataPosition, t]);

  useEffect(() => {
    if (uListDivision) {
      setFilterDivision(setDataAllModalWithStatus(uListDivision));
      setDataDivision(setDataAllModalWithStatus(uListDivision));
      uListDivision.forEach((item) => {
        mapDivision.current[item.id] = item.name;
      });
    }
  }, [uListDivision]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeCurrentDate = useCallback(
    debounce((value) => {
      const dateSearch = moment(filter.date).add(value, 'month');
      setFilter({
        ...filter,
        date: dateSearch,
        page: DEFAULT_PAGE_MUI,
      });
    }),
  );

  function handleCheck(items, index) {
    items.forEach(function (item, i) {
      if (index === i) {
        item.status = !item.status;
      } else {
        item.status = false;
      }
    });

    return [...items];
  }

  const changeSelectPosition = (item) => {
    setDataPosition(function (listStatus) {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  const changeSelectSkill = (item) => {
    setDataSkill(function (listStatus) {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  const handleReset = () => {
    const _dataDivision = structuredClone(dataDivision);
    const _filterDivision = structuredClone(filterDivision);
    const _dataPosition = structuredClone(dataPosition);
    const _dataLevel = structuredClone(dataLevel);
    const _dataSkill = structuredClone(dataSkill);
    setFilter({ ...FILTER_DEFAULT, date: filter.date });

    _dataDivision?.forEach((item) => {
      item.status = false;
    });
    _dataPosition?.forEach((item) => {
      item.status = false;
    });
    _dataLevel?.forEach((item) => {
      item.status = false;
    });
    _dataSkill?.forEach((item) => {
      item.status = false;
    });
    _filterDivision?.forEach((item) => {
      item.status = false;
    });
    setDataPosition(_dataPosition);
    setDataLevel(_dataLevel);
    setDataSkill(_dataSkill);
    setFilterDivision(_filterDivision);
    setDataDivision(_dataDivision);
  };

  const changeSelectLevel = (item) => {
    setDataLevel(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, limit: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleFilterDivision = (query) => {
    const filter = dataDivision.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterDivision(filter);
  };

  const changeSelectDivision = (item) => {
    setFilterDivision(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const queryDivision = () => {
    let datas = [];
    filterDivision.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      division_id: !datas.join() ? null : datas,
      page: Constants.DEFAULT_PAGE_MUI,
    });
    filterDivision?.forEach((item) => {
      item.status = Number(datas.join()) === Number(item?.id);
    });
    dataDivision?.forEach((item) => {
      item.status = Number(datas.join()) === Number(item?.id);
    });
    setIsModals((prev) => {
      return { ...prev, division: false };
    });
    setFilterDivision(dataDivision);
  };

  const toggleDivision = () => {
    setIsModals((prev) => {
      return { ...prev, division: !prev.division };
    });
    if (filter?.division_order_id) {
      filterDivision?.forEach((item) => {
        Number(filter?.division_order_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterDivision([...dataDivision]);
    } else {
      filterDivision?.forEach((item) => {
        item.status = false;
      });
      setFilterDivision([...dataDivision]);
    }
  };

  const handleCancelPopupError = () => {
    setPopupErrorConfirm(false);
  };

  return (
    <Div>
      <div className={styles.managerCost}>
        <Grid container className="pt-0 position-relative z-99">
          <div className={styles.headTitle}>
            <p className={styles.title}>
              {t(`manageCostRecruitLeader.${isLeader ? 'manageCostRecruitLeader' : 'listCostRecruit'}`)}
            </p>
            <div className="d-flex align-items-center">
              <div className={styles.schedule}>
                <div className={styles.schedule__navigator}>
                  <button className={styles.previous} onClick={() => handleChangeCurrentDate(CHANGE_MONTH.PREVIOUS)}>
                    <i className="fas fa-angle-left" />
                  </button>
                  <div className={styles.currentDate}>{filter.date.format(`[${t('common.month')}] M / YYYY`)}</div>
                  <button className={styles.next} onClick={() => handleChangeCurrentDate(CHANGE_MONTH.NEXT)}>
                    <i className="fas fa-angle-right" />
                  </button>
                </div>
              </div>
              {dataListCostLeader?.data?.hrm_confirmation?.created_at && isLeader && (
                <div className="hrm-confirmed d-flex align-items-center">
                  <span>{dataListCostLeader.data.hrm_confirmation.user_name}</span>&nbsp;-&nbsp;
                  <span>
                    {dataListCostLeader.data.hrm_confirmation.position_name}/
                    {dataListCostLeader.data.hrm_confirmation.division_name}
                  </span>
                  &nbsp;-&nbsp;
                  <span>{t('manageCostRecruitLeader.confirmed')}</span>&nbsp;-&nbsp;
                  <span>{moment(dataListCostLeader.data.hrm_confirmation.created_at).format('DD/MM/YYYY HH:mm')}</span>
                </div>
              )}
              {!dataListCostLeader?.data?.hrm_confirmation?.created_at &&
                isLeader &&
                filter.date?.month() < moment().month() && (
                  <div className="hrm-need-confirm">
                    <span>{t('manageCostRecruitLeader.listCostNeedConfirm')}</span>
                  </div>
                )}
            </div>
          </div>
        </Grid>

        <Grid container className="pt-2" spacing={3}>
          <Grid item md={9} sm={8}>
            <div className="table-border-show">
              <Paper>
                {isLeader && (
                  <TableForLeader
                    isLoadingCostLeader={isLoadingCostLeader}
                    dataListCostLeader={dataListCostLeader?.data?.candidate_divisions?.items}
                    filter={filter}
                    mapLevel={mapLevel.current}
                    mapPosition={mapPosition.current}
                    mapSkill={mapSkill.current}
                    total={dataListCostLeader?.data?.candidate_divisions?.total}
                  />
                )}
                {!isLeader && (
                  <TableForHrm
                    isLoadingCostHrm={isLoadingCostHrm}
                    dataListCostHrm={dataListCostHrm?.data?.candidate_divisions?.items}
                    filter={filter}
                    mapLevel={mapLevel.current}
                    mapPosition={mapPosition.current}
                    mapSkill={mapSkill.current}
                    mapDivision={mapDivision.current}
                    total={dataListCostHrm?.data?.candidate_divisions?.total}
                  />
                )}
                {total > 0 && (
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTION_20}
                    component="div"
                    count={total}
                    rowsPerPage={filter?.limit}
                    page={filter.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="table-pagination"
                    labelDisplayedRows={displayLabelTablePagination}
                  />
                )}
              </Paper>
            </div>
          </Grid>

          <Grid item md={3} sm={4}>
            {(isLoadingCostHrm || isLoadingCostLeader) && (
              <div
                className={`${
                  isLoadingCostHrm || isLoadingCostLeader ? 'status-loading-confirm' : 'status-leader-confirm'
                } d-flex align-items-center justify-content-center ${
                  hrmConfirmed || leaderConfirmed ? 'confirmed' : ''
                }`}
              >
                <Button
                  addClass={`text-center fw-500 color-icon-loading`}
                  hasDisabled={true}
                  isLoading={true}
                  text=""
                />
              </div>
            )}

            {(dataListCostHrm?.data || dataListCostLeader?.data) && (!isLoadingCostHrm || !isLoadingCostLeader) && (
              <EditLayout permissionName={LIST_COST_RECRUIT_PERMISSION}>
                <div
                  className={`status-leader-confirm d-flex align-items-center justify-content-center ${
                    hrmConfirmed || leaderConfirmed ? 'confirmed' : ''
                  }`}
                >
                  <Button
                    addClass={`text-center fw-500 `}
                    hasDisabled={
                      hrmConfirmed || leaderConfirmed || filter.date.diff(moment().subtract(1, 'months'), 'days') >= 1
                    }
                    handleClick={() => {
                      if (!hrmConfirmed && !isLeader) {
                        confirmAlert({
                          title: t('manageCostRecruitLeader.message.confirm'),
                          overlayClassName: 'confirm-info-bonus',
                          buttons: [
                            {
                              label: t('common.cancel'),
                              className: 'btn-alert-cancel text-left',
                            },
                            {
                              label: t('common.ok'),
                              className: 'btn-alert-ok',
                              onClick: () => {
                                handleHrmConfirmed({ confirmation_date: filter.date.format('YYYY-MM') });
                              },
                            },
                          ],
                        });
                      } else if (dataListCostLeader?.data?.hrm_confirmation && !leaderConfirmed) {
                        confirmAlert({
                          title: t('manageCostRecruitLeader.message.confirm'),
                          overlayClassName: 'confirm-info-bonus',
                          buttons: [
                            {
                              label: t('common.cancel'),
                              className: 'btn-alert-cancel text-left',
                            },
                            {
                              label: t('common.ok'),
                              className: 'btn-alert-ok',
                              onClick: () => {
                                handleLeaderConfirmed({ confirmation_date: filter.date.format('YYYY-MM') });
                              },
                            },
                          ],
                        });
                      } else {
                        setPopupErrorConfirm(true);
                      }
                    }}
                    text={t(
                      `manageCostRecruitLeader.${hrmConfirmed || leaderConfirmed ? 'confirmed' : 'confirmInformation'}`,
                    )}
                  />
                </div>
              </EditLayout>
            )}
            {!isLeader && (
              <div className="export">
                <ButtonComponent
                  text={t('common.export')}
                  imgIcon={iconExport}
                  addClass={styles.buttonExport}
                  hasDisabled={exportLoading}
                  isLoading={exportLoading}
                  handleClick={() =>
                    handleExportCost({
                      ...filter,
                      page: filter.page + 1,
                      date: filter.date.format('YYYY-MM'),
                    })
                  }
                />
              </div>
            )}
            <SearchComponent
              txtPlaceholder={t('manageCostRecruitLeader.candidate')}
              addClass="input-search-box mt-3"
              defaultValue={filter?.candidate_name}
              handleSearch={(val) => {
                setFilter({ ...filter, candidate_name: val.trim(), page: DEFAULT_PAGE_MUI });
              }}
              maxLength={Constants.MAX_LENGTH_50}
              showIconSearch={false}
            />
            <InputBase
              disabled
              type="text"
              name="position_id"
              className="input-select-multi mt-3"
              onClick={() => {
                setIsModals(() => {
                  return { position: true };
                });
              }}
              value={
                dataPosition &&
                (!dataPosition[0]?.status
                  ? dataPosition
                      .map((item, i) =>
                        item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                      )
                      .filter((it) => it)
                  : t('managerTest.all'))
              }
              placeholder={t('managerTest.position')}
            />
            <ModalCommon
              items={dataPosition}
              onSubmit={() => {
                let datas = [];
                dataPosition.forEach((it) => {
                  it.status && it.id && datas.push(it.id);
                });
                setFilter({
                  ...filter,
                  position_id: !datas.join() ? null : datas,
                  page: Constants.DEFAULT_PAGE_MUI,
                });
                setIsModals((prev) => {
                  return { ...prev, position: false };
                });
              }}
              onClick={changeSelectPosition}
              toggle={() => {
                setIsModals((prev) => {
                  return { ...prev, position: !prev.position };
                });
                if (filter?.position_id) {
                  dataPosition?.forEach((item) => {
                    Number(filter?.position_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
                  });
                  setDataPosition([...dataPosition]);
                } else {
                  dataPosition?.forEach((item) => {
                    item.status = false;
                  });
                  setDataPosition([...dataPosition]);
                }
              }}
              modal={isModals.position}
              title={t('managerTest.choosePosition')}
              footer={t('managerTest.selected')}
            />
            <InputBase
              disabled
              type="text"
              name="level_id"
              className="input-select-multi mt-3"
              onClick={() => {
                setIsModals(() => {
                  return { level: true };
                });
              }}
              value={
                dataLevel &&
                (!dataLevel[0]?.status
                  ? dataLevel
                      .map((item, i) =>
                        item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                      )
                      .filter((it) => it)
                  : t('manageCostRecruitLeader.all'))
              }
              placeholder={t('manageCostRecruitLeader.officialLevel')}
            />
            <ModalCommon
              items={dataLevel}
              onSubmit={() => {
                let datas = [];
                dataLevel.forEach((it) => {
                  it.status && it.id && datas.push(it.id);
                });
                setFilter({
                  ...filter,
                  level_id: !datas.join() ? null : datas,
                  page: Constants.DEFAULT_PAGE_MUI,
                });
                setIsModals((prev) => {
                  return { ...prev, level: false };
                });
              }}
              onClick={changeSelectLevel}
              toggle={() => {
                setIsModals((prev) => {
                  return { ...prev, level: !prev.level };
                });
                if (filter?.level_id) {
                  dataLevel?.forEach((item) => {
                    Number(filter?.level_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
                  });
                  setDataLevel([...dataLevel]);
                } else {
                  dataLevel?.forEach((item) => {
                    item.status = false;
                  });
                  setDataLevel([...dataLevel]);
                }
              }}
              modal={isModals.level}
              title={t('manageCostRecruitLeader.chooseLevelOfficial')}
              footer={t('managerTest.selected')}
            />
            <InputBase
              disabled
              type="text"
              name="skill_id"
              className="input-select-multi mt-3"
              onClick={() => {
                setIsModals(() => {
                  return { skill: true };
                });
              }}
              value={
                dataSkill &&
                (!dataSkill[0]?.status
                  ? dataSkill
                      .map((item, i) =>
                        item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                      )
                      .filter((it) => it)
                  : t('manageCostRecruitLeader.all'))
              }
              placeholder={t('managerTest.skill')}
            />
            <ModalCommon
              items={dataSkill}
              onSubmit={() => {
                let datas = [];
                dataSkill.forEach((it) => {
                  it.status && it.id && datas.push(it.id);
                });
                setFilter({
                  ...filter,
                  skill_id: !datas.join() ? null : datas,
                  page: Constants.DEFAULT_PAGE_MUI,
                });
                setIsModals((prev) => {
                  return { ...prev, skill: false };
                });
              }}
              onClick={changeSelectSkill}
              toggle={() => {
                setIsModals((prev) => {
                  return { ...prev, skill: !prev.skill };
                });
                if (filter?.skill_id) {
                  dataSkill?.forEach((item) => {
                    Number(filter?.skill_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
                  });
                  setDataSkill([...dataSkill]);
                } else {
                  dataSkill?.forEach((item) => {
                    item.status = false;
                  });
                  setDataSkill([...dataSkill]);
                }
              }}
              modal={isModals.skill}
              title={t('managerTest.chooseSkill')}
              footer={t('managerTest.selected')}
            />
            {!isLeader && (
              <InputBase
                disabled
                type="text"
                name="division_id"
                className="input-select-multi mt-3"
                onClick={() => {
                  setIsModals(() => {
                    return { division: true };
                  });
                }}
                value={getValueInputSearchAll(filterDivision)}
                placeholder={t('manageCostRecruitLeader.division')}
              />
            )}
            {!isLeader && (
              <ModalCommon
                items={filterDivision}
                onSubmit={queryDivision}
                onClick={changeSelectDivision}
                toggle={toggleDivision}
                modal={isModals.division}
                title={t('manageCostRecruitLeader.chooseDivision')}
                footer={t('common.select')}
                search={
                  <SearchComponent
                    txtPlaceholder={t('manageCostRecruitLeader.division')}
                    addClass="input-search-box"
                    handleSearch={handleFilterDivision}
                    maxLength={Constants.MAX_LENGTH_50}
                    size={'small'}
                  />
                }
              />
            )}
            <div className="btn-reset">
              <ButtonBase onClick={() => handleReset()} className="reset mt-3" size="lg">
                <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                {t('common.reset')}
              </ButtonBase>
            </div>
          </Grid>
        </Grid>
      </div>
      <PopupAreYouSure
        active={openPopupErrorConfirm}
        handleCancel={handleCancelPopupError}
        messages={t('manageCostRecruitLeader.listCostNeedConfirm')}
        footer={
          <div className="fnc-btn d-flex align-items-center">
            <Button
              text={t(`manageCostRecruitLeader.ok`)}
              addClass="btn-ok h-36"
              handleClick={handleCancelPopupError}
            />
          </div>
        }
      />
    </Div>
  );
};

export default ListCostRecruitLeader;
