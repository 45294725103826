/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/Buttons';
import './styles.scss';
import InputField from 'components/Input/InputField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import classnames from 'classnames';

import styles from './styles.module.scss';
import OrderManagement from 'assets/img/icons/iconDecentralization/order-management.svg';
import JobManagement from 'assets/img/icons/iconDecentralization/job-management.svg';
import CandidateManagement from 'assets/img/icons/iconDecentralization/candidate-management.svg';
import JDManagement from 'assets/img/icons/iconDecentralization/jd-management.svg';
import QuestionInterviewManagement from 'assets/img/icons/iconDecentralization/question-interview-management.svg';
import TestManagement from 'assets/img/icons/iconDecentralization/test-management.svg';
import StandardManagement from 'assets/img/icons/iconDecentralization/standard-management.svg';
import ExpenseEmployeeManagement from 'assets/img/icons/iconDecentralization/expense-employee-management.svg';
import KPIManagement from 'assets/img/icons/iconDecentralization/kpi-management.svg';
import ListRewardRefIcon from 'assets/img/icons/iconDecentralization/list-reward-ref.svg';
import ListCostRecruitIcon from 'assets/img/icons/iconDecentralization/list-cost-recruit.svg';
import DecentralizationMngIcon from 'assets/img/icons/iconDecentralization/decentralizationMngIcon.svg';
import CostRecruitManagementIcon from 'assets/img/icons/iconDecentralization/cost-recruit-management.svg';
import ListSchoolManagementIcon from 'assets/img/icons/iconDecentralization/list-school-management.svg';
import SourceCVManagementIcon from 'assets/img/icons/iconDecentralization/source-cv-management.svg';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { sendPut } from 'helpers/axios';
import { useGroupRoleDetail } from 'hook/useManagerDecentralization';
import { useQueryClient } from 'react-query';
import { managerDecentralization } from 'constants/index.js';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import { LIST_ROLE_ID, ROLE_VIEW } from 'constants/permission';
import RadioGroupRole from './components/RadioGroupRole';
import { getNameViewRole } from './helpers/getText';
import EditLayout from 'components/PermissionPage/EditLayout';
import { GROUP_PERMISSION } from 'constants/permission';

const Permissions = ({ control, index, formData, setFormData, status, isUnCheck, viewRole }) => {
  const { t } = useTranslation();

  const handleTickCheckbox = (event, permissionType) => {
    let newPermission = formData.role[index]?.permission;
    switch (permissionType) {
      case 'read':
        if (event.target.checked) newPermission = [true, newPermission?.[1], newPermission?.[2]];
        else newPermission = [];
        break;
      case 'update':
        if (event.target.checked) newPermission = [true, true, newPermission?.[2]];
        else newPermission = [newPermission?.[0], false, newPermission?.[2]];
        break;
      case 'delete':
        if (event.target.checked) newPermission = [true, newPermission?.[1], true];
        else newPermission = [newPermission?.[0], newPermission?.[1], false];
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      role: formData.role.map((item, indexRole) => {
        if (indexRole !== index) return item;
        return { ...item, permission: newPermission };
      }),
    });
  };

  const CheckboxCustom = useCallback(
    ({ _index, permission }) => {
      return (
        <Controller
          control={control}
          name={`role.${index}.permission.${_index}`}
          defaultValue={isUnCheck ? false : formData.role[index]?.permission?.[_index]}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              control={
                <Checkbox
                  defaultChecked={isUnCheck ? false : formData.role[index]?.permission?.[_index]}
                  name={t(`managerDecentralization.${permission}`)}
                  onChange={(e) => handleTickCheckbox(e, permission)}
                />
              }
              disabled={status === 'read' || isUnCheck}
              label={t(`managerDecentralization.${permission}`)}
            />
          )}
        />
      );
    },
    [formData, status, isUnCheck],
  );
  return (
    <>
      <div className="permission">
        <CheckboxCustom _index={0} permission="read" />
      </div>
      {!formData.role[index]?.hideUpdateBox && !formData.role[index]?.hideCheckboxUpdateWithRole?.includes(viewRole) && (
        <div className="permission">
          <CheckboxCustom _index={1} permission="update" />
        </div>
      )}
      {!formData.role[index]?.hideDeleteBox && !formData.role[index]?.hideCheckboxDeleteWithRole?.includes(viewRole) && (
        <div className="permission">
          <CheckboxCustom _index={2} permission="delete" />
        </div>
      )}
    </>
  );
};

const OnlyMenu = ({ item, formData, setFormData, control, index, status, viewRole }) => {
  const isDisable = formData.role[index]?.disableWithRole?.includes(viewRole);

  return (
    <>
      <div
        className={classnames(`title d-flex align-items-end`, {
          [styles.disableLine]: isDisable,
        })}
      >
        <div className="icon d-flex align-items-end">
          <img src={item.icon} alt="icon" />
        </div>
        <span>{item.name}</span>
      </div>
      <div className={classnames(`d-flex`, { [styles.disableGroupCheckBox]: isDisable })}>
        <Permissions
          formData={formData}
          setFormData={setFormData}
          control={control}
          index={index}
          status={status}
          isUnCheck={isDisable}
          viewRole={viewRole}
        />
      </div>
    </>
  );
};

const TabManagerRole = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const loading = useRef(true);
  const message = useRef(null);
  const [viewRole, setViewRole] = useState(ROLE_VIEW.HRM);
  const [openModal, setOpenModal] = useState({ status: 200, active: false });
  const [status, setStatus] = useState('read');
  const { data, isLoading } = useGroupRoleDetail(id);
  const defaultState = useRef({
    name: '',
    name_older: '',
    role: [
      {
        role_id: 39,
        permission: [],
        check_permission: 9,
        hideUpdateBox: true,
        hideDeleteBox: true,
      },
      {
        role_id: 8,
        permission: [],
        check_permission: 9,
        hideDeleteBox: true,
      },
      {
        role_id: 9,
        permission: [],
        check_permission: 9,
        disableWithRole: [ROLE_VIEW.LEADER, ROLE_VIEW.ASSIGNER_BY_LEADER],
      },
      {
        role_id: 10,
        permission: [],
        check_permission: 9,
        // hideDeleteBox: true,
      },
      {
        role_id: 15,
        permission: [],
        check_permission: 9,
      },
      {
        role_id: 17,
        permission: [],
        check_permission: 9,
      },
      {
        role_id: 16,
        permission: [],
        check_permission: 9,
      },
      {
        role_id: 40,
        permission: [],
        check_permission: 9,
        disableWithRole: [ROLE_VIEW.LEADER, ROLE_VIEW.ASSIGNER_BY_LEADER],
        hideCheckboxUpdateWithRole: [ROLE_VIEW.HR],
        hideCheckboxDeleteWithRole: [ROLE_VIEW.HR],
      },
      {
        role_id: 41,
        permission: [],
        check_permission: 9,
        hideDeleteBox: true,
        disableWithRole: [ROLE_VIEW.LEADER, ROLE_VIEW.ASSIGNER_BY_LEADER, ROLE_VIEW.HR],
      },
      {
        role_id: 44,
        permission: [],
        check_permission: 9,
        hideDeleteBox: true,
        disableWithRole: [ROLE_VIEW.HR, ROLE_VIEW.ASSIGNER_BY_LEADER],
      },
      { role_id: 21, permission: [], check_permission: 9 },
      { role_id: 18, permission: [], check_permission: 9, hideDeleteBox: true },
      { role_id: 19, permission: [], check_permission: 9 },
      { role_id: 45, permission: [], check_permission: 9 },
      { role_id: 42, permission: [], check_permission: 9 },
      { role_id: 43, permission: [], check_permission: 9 },
    ],
    system_id: 2,
  });
  const cloneDefaultState = structuredClone(defaultState.current);
  const [formData, setFormData] = useState(cloneDefaultState);

  const settingDecentralization = [
    { name: t('managerDecentralization.dashboard'), icon: OrderManagement },
    { name: t('managerDecentralization.managerOrder'), icon: OrderManagement },
    { name: t('managerDecentralization.managerJob'), icon: JobManagement },
    { name: t('managerDecentralization.managerCandidate'), icon: CandidateManagement },
    { name: t('managerDecentralization.managerJD'), icon: JDManagement },
    { name: t('managerDecentralization.managerTestQuestionInterview'), icon: QuestionInterviewManagement },
    { name: t('managerDecentralization.managerInterview'), icon: TestManagement },
    { name: t('managerDecentralization.managerKPI'), icon: KPIManagement },
    { name: t('managerDecentralization.listRewardRef'), icon: ListRewardRefIcon },
    { name: t('managerDecentralization.listCostRecruit'), icon: ListCostRecruitIcon },
    { name: t('managerDecentralization.decentralizedManagement'), icon: DecentralizationMngIcon },
    { name: t('managerDecentralization.managerStandard'), icon: StandardManagement },
    { name: t('managerDecentralization.managerRewardRef'), icon: ExpenseEmployeeManagement },
    { name: t('managerDecentralization.managerCostRecruit'), icon: CostRecruitManagementIcon },
    { name: t('managerDecentralization.listSchoolManagement'), icon: ListSchoolManagementIcon },
    { name: t('managerDecentralization.listSourceCvManagement'), icon: SourceCVManagementIcon },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().min(2, t('managerDecentralization.minLength2')),
      }),
    ),
    defaultValues: formData,
    mode: 'onChange',
  });

  const name = useWatch({
    control,
    name: 'name',
  });

  const role = useWatch({
    control,
    name: 'role',
  });

  const InputName = useCallback(() => {
    return (
      <Controller
        control={control}
        name="name"
        valueDefault={formData.name}
        render={({ field }) => (
          <InputField
            field={field}
            value={field.value}
            label={t('managerDecentralization.positionName')}
            variant="outlined"
            onHandleInput={() => {}}
            error={errors?.['name']?.message}
            onKeyPress={handleKeyPress}
            maxLength={30}
          />
        )}
      />
    );
  }, [formData]);

  useEffect(() => {
    if (data) {
      reset(cloneDefaultState);
      const newRole = structuredClone(cloneDefaultState.role);
      data?.permission.forEach((item) => {
        const newPermission = [];
        if (item.role_id === LIST_ROLE_ID.RECRUIT_VIEW) {
          setViewRole(item.permission[0]);
          return;
        }
        for (const _item of item.permission) {
          if (_item === 2) {
            newPermission[0] = true;
          } else if (_item === 3) {
            newPermission[1] = true;
          } else {
            newPermission[2] = true;
          }
        }
        const indexOfRoleId = newRole?.map((item) => item.role_id).indexOf(item.role_id);
        if (indexOfRoleId !== -1) newRole[indexOfRoleId].permission = newPermission;
      });
      setValue('name', data.group_role?.name);
      setValue('role', newRole);
      setFormData({
        ...formData,
        name: data.group_role?.name,
        role: newRole,
        name_older: data.group_role?.name,
        viewRoleName: getNameViewRole(
          t,
          data?.permission?.find((item) => item.role_id === LIST_ROLE_ID.RECRUIT_VIEW)?.permission[0],
        ),
      });
    }
    return () => {
      queryClient.setQueryData([managerDecentralization.communityAddMemberCustom], null);
    };
  }, [data]);

  useEffect(() => {
    setValue('role', formData.role);
  }, [formData]);

  const handleCancel = () => {
    setOpenModal({ ...openModal, active: false });
    setStatus('read');
  };

  const onSubmit = async (data) => {
    if (loading.current) {
      loading.current = false;
      for (const element of data.role) {
        const item = element;
        const newPermission = [];
        for (let j = 0; j < item.permission.length; j++) {
          if (item.permission[j]) {
            newPermission.push(j + 2);
          }
        }
        element.permission = newPermission;
        element.check_permission = newPermission.reduce((a, b) => a + b, 0);
      }
      if (Array.isArray(data.role))
        data.role.push({ role_id: LIST_ROLE_ID.RECRUIT_VIEW, permission: [viewRole], check_permission: viewRole });

      data.role = data.role.map((item) => {
        if (item?.disableWithRole?.includes(viewRole)) {
          return { ...item, permission: [] };
        }
        return item;
      });

      data.role = JSON.stringify(data.role.filter((item) => item.permission.some((_item) => _item >= 2)));

      const res = await sendPut(`api/group-role/${id}`, { ...data, name_older: formData.name_older });
      message.current = res.data.messages?.name?.[0] || res.data.messages;
      setOpenModal({ status: res.data.status, active: true });
      queryClient.refetchQueries([managerDecentralization.communityAddMemberCustom]);
      loading.current = true;
    }
  };

  const handleKeyPress = (e) => {
    if (e.target.value.length >= 30) {
      e.preventDefault();
    }
  };

  const isValidSubmit = useMemo(() => {
    return !!role.find((item) =>
      item.permission?.length > 0 ? item.permission.find((item) => item !== undefined && item !== false) : false,
    );
  }, [role]);
  return (
    <Grid container className="content create-position management-decentralization pb-5">
      <AlertAfterSubmit
        isShowAlert={openModal.active}
        toggle={() => handleCancel()}
        labelOnOk={t(
          `managerDecentralization.${openModal.status !== 422 && openModal.status !== 417 ? 'done' : 'see'}`,
        )}
      >
        {openModal.status !== 422 && openModal.status !== 417 ? (
          <p>{t(`managerDecentralization.editRoleSuccess`)}</p>
        ) : (
          <p>{message.current}</p>
        )}
      </AlertAfterSubmit>
      <Grid item xs={12} className="content-component">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            item
            xs={12}
            className="d-flex align-items-center justify-content-between title-outside edit-role-title"
          >
            <h6 className="mb-4 mt-15 fw-bold page-title">
              {t(
                `managerDecentralization.${
                  status === 'read' ? 'positionAndDecentralization' : 'editPositionAndDecentralization'
                }`,
              )}
            </h6>
            <div className="fnc-btn">
              {status === 'update' && (
                <Button
                  text={t('managerDecentralization.cancel')}
                  addClass="btn-bg-yellow2 mr-3 w-92i padding-btn"
                  handleClick={() => {
                    queryClient.invalidateQueries([managerDecentralization.communityAddMemberCustom]);
                    setStatus('read');
                  }}
                />
              )}
              {formData?.name !== 'System Admin' && (
                <EditLayout permissionName={GROUP_PERMISSION}>
                  <Button
                    text={t(`managerDecentralization.${status === 'read' ? 'managerRoleRead' : 'save'}`)}
                    addClass="btn-bg-yellow1 w-92i padding-btn"
                    type="submit"
                    handleClick={() => {
                      if (status === 'read') setStatus('update');
                    }}
                    hasDisabled={
                      isLoading || (status === 'update' && name?.length < 2) || (status === 'update' && !isValidSubmit)
                    }
                  />
                </EditLayout>
              )}
            </div>
          </Grid>

          <Paper className="p-20">
            <Grid item xs={3}>
              {status === 'update' ? (
                <>
                  <InputName />
                  <h4 className="setting-decentralization-content">{t('managerDecentralization.viewOfRole')}</h4>
                  <RadioGroupRole setViewRole={setViewRole} viewRole={viewRole} />
                </>
              ) : (
                <div className="fw-500 setting-decentralization-title">
                  <div>
                    <span className="fw-400">{t('managerDecentralization.position')}</span>: {formData.name}
                  </div>
                  <div className="mt-15">
                    <span className="fw-400 ">{t('managerDecentralization.viewOfRole')}</span>: {formData.viewRoleName}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <h4 className="setting-decentralization-content">
                {t('managerDecentralization.settingDecentralization')}
              </h4>
            </Grid>
            <div className="divider" />
            {settingDecentralization.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Grid item xs={12} className="d-flex align-items-center parent-menu pt-2 pb-2">
                  <OnlyMenu
                    item={item}
                    formData={formData}
                    setFormData={setFormData}
                    control={control}
                    index={index}
                    status={status}
                    viewRole={viewRole}
                  />
                </Grid>
                {index !== settingDecentralization.length - 1 && <div className="divider" />}
              </Grid>
            ))}
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
};

export default TabManagerRole;
