import React, { useCallback, useRef, useState } from 'react';
import { ModalFooter } from 'reactstrap';

import iconChecked from 'assets/img/icons/checked.png';
import { useGetListQuestion } from 'hook/useManagerGroupTest';
import { MAX_LENGTH_50 } from 'constants/index';

import SearchComponent from 'components/Search/index.js';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';

const ModalWareHouseQuestion = ({
  t,
  openChooseTest,
  setOpenChooseTest,
  positionId,
  skillId,
  levelId,
  listQuestionIdCurrent,
  mapLevel,
  setListQuestionIds,
  setListQuestionPreview,
}) => {
  const [questionSelected, setQuestionSelected] = useState([]);
  const [questionOtherSelected, setQuestionOtherSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key_word, setKeyword] = useState('');
  const timerId = useRef();

  const { data: dataListQuestion, isLoading } = useGetListQuestion({
    position_id: positionId,
    skill_id: skillId <= 0 ? null : skillId,
    level_id: levelId,
    key_word,
    list_question_ids: listQuestionIdCurrent,
  });

  const listQuestion = dataListQuestion?.list_question ? dataListQuestion.list_question : [];
  const listQuestionOther = dataListQuestion?.list_question_other ? dataListQuestion.list_question_other : [];

  const handleAddQuestion = (item, setQuestion, question) => {
    const newQuestionSelected = structuredClone(question);
    const index = newQuestionSelected.findIndex((_item) => _item.id === item.id);
    if (index !== -1) {
      newQuestionSelected.splice(index, 1);
      setQuestion([...newQuestionSelected]);
    } else {
      newQuestionSelected.push(item);
      setQuestion([...newQuestionSelected]);
    }
  };

  const formatQuestionSelected = (dataQuestion) => {
    if (!dataQuestion.length) return [];
    return dataQuestion.map((item) => {
      const res = {
        ...item,
        img_bonus: typeof item.img_bonus === 'string' ? JSON.parse(item.img_bonus) : item.img_bonus,
        list_answer: [],
        id_old: item.id,
      };
      if (item.type > 1) {
        res.list_answer = typeof item.list_answer === 'string' ? JSON.parse(item.list_answer) : item.list_answer;
      }
      return res;
    });
  };

  const handleCreateQuestion = useCallback(() => {
    if (!loading) {
      setLoading(true);

      if (questionSelected.length) {
        setListQuestionIds((prev) => {
          return [...prev, ...questionSelected.map((item) => item.id)];
        });
        setListQuestionPreview((prev) => {
          return [...prev, ...formatQuestionSelected(questionSelected)];
        });
      }

      if (questionOtherSelected.length) {
        setListQuestionIds((prev) => {
          return [...prev, ...questionOtherSelected.map((item) => item.id)];
        });
        setListQuestionPreview((prev) => {
          return [...prev, ...formatQuestionSelected(questionOtherSelected)];
        });
      }

      setOpenChooseTest(false);
      setLoading(false);
    }
  }, [questionSelected, questionOtherSelected, loading, setOpenChooseTest, setListQuestionIds, setListQuestionPreview]);

  const handleSearch = (val) => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      setKeyword(val);
    }, 500);
  };

  return (
    <ModalComponent
      title={t('managerTest.swichQuestionFromWareHouse')}
      isShowModal={openChooseTest}
      toggle={() => setOpenChooseTest((prev) => !prev)}
      classNameAdd="switch-question-from-ware-house"
      renderModalFooter={() => (
        <ModalFooter>
          <ButtonComponent
            addClass="w-100 btn-bg-yellow1"
            text={t('managerTest.choose')}
            isLoading={loading}
            hasDisabled={loading}
            handleClick={handleCreateQuestion}
          />
        </ModalFooter>
      )}
    >
      <div className="search mb-14">
        <SearchComponent
          txtPlaceholder={t('managerTest.search')}
          addClass="input-search"
          handleSearch={handleSearch}
          maxLength={MAX_LENGTH_50}
        />
      </div>
      {isLoading && (
        <div className="loading">
          <Loading addClass="mt-3 mb-3" />
        </div>
      )}
      {!isLoading && (
        <div className="list-question-from-ware-house">
          <div className="list-question-content">
            {!!listQuestion.length && (
              <div className="question-item mt-4">
                <div className="level">
                  <span>{t('managerTest.level')}: </span>
                  <span className="fw-500">{mapLevel[levelId]}</span>
                </div>
                <ul className="pl-0">
                  {listQuestion?.map((_item, _index) => (
                    <li className="d-block" key={_index}>
                      <div
                        className={`mh-36 d-flex align-items-center justify-content-between cursor position-relative z-9 `}
                        onClick={handleAddQuestion.bind(this, _item, setQuestionSelected, questionSelected)}
                      >
                        <div
                          className={`${questionSelected.some((item) => item.id === _item.id) ? 'active' : 'd-none'}`}
                        />
                        <span className="pl-15 text-question-test">{_item.content}</span>
                        {questionSelected.some((item) => item.id === _item.id) && (
                          <div className="checked">
                            <img src={iconChecked} alt="checked" />
                          </div>
                        )}
                      </div>
                      <div className="divider w-100" />
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {listQuestionOther?.length > 0 && (
              <div className="question-item">
                <div className="question-other-title">
                  <span className="d-block fw-bold fs-20">{t('managerTest.questionOtherLevel')}</span>
                </div>
                {listQuestionOther?.map((questionOther, indexOther) => {
                  if (questionOther?.list_data)
                    return (
                      <div key={indexOther}>
                        <div className="level mt-2">
                          <span>{t('managerTest.level')}: </span>
                          <span className="fw-500">{mapLevel[questionOther.level_id]}</span>
                        </div>
                        <ul className="pl-0">
                          {questionOther.list_data.map((_item, _index) => (
                            <li className="d-block" key={_index}>
                              <div
                                className={`mh-36 d-flex align-items-center justify-content-between cursor position-relative z-9 `}
                                onClick={handleAddQuestion.bind(
                                  this,
                                  _item,
                                  setQuestionOtherSelected,
                                  questionOtherSelected,
                                )}
                              >
                                <div
                                  className={`${
                                    questionOtherSelected.some((item) => item.id === _item.id) ? 'active' : 'd-none'
                                  }`}
                                />
                                <span className="pl-15 text-question-test">{_item.content}</span>
                                {questionOtherSelected.some((item) => item.id === _item.id) && (
                                  <div className="checked">
                                    <img src={iconChecked} alt="checked" />
                                  </div>
                                )}
                              </div>
                              <div className="divider w-100" />
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  else return <div key={indexOther}></div>;
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

export default ModalWareHouseQuestion;
