import styled from 'styled-components';

export const Div = styled.div`
  .text-center {
    text-align: center;
  }
  .color-icon-loading {
    color: #fea628 !important;
  }
  .z-99 {
    z-index: 99;
  }
  .fw-500 {
    font-weight: 500;
  }
  .status-leader-confirm button {
    height: 40px;
    background: #fea628 !important;
    color: white !important;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #fea628 !important;
  }
  .status-loading-confirm button {
    height: 40px;
    background: #fff8ee !important;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    border: 0px solid #fea628 !important;
  }
  .status-leader-confirm.confirmed button {
    background: #fff8ee !important;
    color: #fea628 !important;
    border: 1px solid #fff8ee !important;
  }
  .MuiInputBase-inputTypeSearch {
    -webkit-text-fill-color: #bdbdbd;
  }
  .input-search-box .MuiInputBase-root {
    background: white;
  }
  .hrm-confirmed {
    color: #2d99ff;
    font-weight: 700;
    margin-left: 10px;
  }
  .hrm-need-confirm {
    color: gray;
    font-weight: 700;
    margin-left: 10px;
  }
  .wrapper-top {
    width: 100%;
    overflow-x: scroll;
    height: 5px;
    cursor: pointer;
    overflow-y: hidden;
  }
  @media (min-width: 1529px) {
    .scroll-of-dl {
      display: none;
    }
  }
  .scroll-custom,
  .wrapper-top {
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #223354;
    }
    &::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
  }
  table {
    th {
      white-space: nowrap;
    }
  }

  table td {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
    white-space: nowrap;
  }
  .table-border-show td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
  .export {
    padding-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
    button {
      width: 100%;
      margin-top: 10px;
      background: #dfeaff;
      border: 1px solid #dfeaff;
      color: #2d99ff;
      &:focus,
      &:active,
      &:visited,
      &:disabled {
        background: #dfeaff !important;
        border: none !important;
        color: #2d99ff !important;
        outline: none !important;
      }
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
`;
