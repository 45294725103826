import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';

import { ButtonBase, Grid, InputBase, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { debounce } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';

import configs from 'config';
import './styles.scss';
import styles from './styles.module.scss';
import { useGetListOrderLeader } from 'hook/useManagerOrder';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20 } from 'constants/index.js';
import { ZERO } from 'constants/MasterData';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';
import * as Constants from 'constants/index.js';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import Loading from 'components/Loading';
import { getLevelName, getPositionName, getSkillName } from 'helpers/masterData';
import { displayLabelTablePagination } from 'helpers/table';
import iconLoading from 'assets/img/icons/loading.png';
import SearchComponent from 'components/Search';
import {
  WAIT_BOD_ACCEPT,
  PROCESSING,
  DEADLINE_LATE,
  CANCEL_ORDER,
  COMPLETED,
  BOD_DONT_ACCEPT,
  CANCEL_ACTION,
  COMPLETED_ACTION,
} from 'constants/ManagerOrder.js';
import { customDate } from 'helpers/formatDate';
import { handleCheckAll, changNameLevel } from 'helpers/modal';

import ModalOrder from './ModalOrder';
import ModalReason from 'components/ModalReason';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import { USE_LIST_ORDER_LEADER, USE_DETAIL_LEADER_ORDER } from 'constants/ManagerOrder';
import FilterOrderByDeadline from '../ManagerOrderHr/FilterOrderByDeadline';
import { apiChangeStatusOrder, apiPostCancelOrderLeader } from 'api/managerOrder';
import ButtonComponent from 'components/Buttons';
import EditLayout from 'components/PermissionPage/EditLayout';
import { ORDER_HR_PERMISSION } from 'constants/permission';

const ManagerOrderLeader = () => {
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const { orderId } = useParams();
  const [detailOrder, setOpenDetailOrder] = useState(false);

  const [status, setStatus] = useState([
    { name: t('managerOrderHr.all'), value: null, status: false },
    { name: t('managerOrderHr.waitBodAccept'), value: WAIT_BOD_ACCEPT, status: false },
    { name: t('managerOrderHr.bodDontAccept'), value: BOD_DONT_ACCEPT, status: false },
    { name: t('managerOrderHr.processing'), value: PROCESSING, status: false },
    { name: t('managerOrderHr.deadlineLate'), value: DEADLINE_LATE, status: false },
    { name: t('managerOrderHr.cancelOrder'), value: CANCEL_ORDER, status: false },
    { name: t('managerOrderHr.completed'), value: COMPLETED, status: false },
  ]);

  const [filter, setFilter] = useState({
    level_id: null,
    position_id: null,
    skill_id: null,
    page: DEFAULT_PAGE_MUI,
    limit: DEFAULT_LIMIT_20,
    from_time: null,
    to_time: null,
  });

  const { data: dataListOrders, isLoading: isLoadingDataListOrders } = useGetListOrderLeader({
    ...filter,
    page: filter.page + 1,
    per_page: filter.limit,
    from_time: filter.to_time && filter.from_time && moment(filter.from_time, 'YYYY/MM/DD').format('YYYY/MM/DD'),
    to_time: filter.to_time && filter.from_time && moment(filter.to_time, 'YYYY/MM/DD').format('YYYY/MM/DD'),
  });

  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();

  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [idDetailModal, setIdDetailModal] = useState();
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalComplete, setShowModalComplete] = useState(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState(false);
  const [isCompletedSuccess, setIsCompletedSuccess] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [isModals, setIsModals] = useState({ position: false, skill: false, level: false });

  useEffect(() => {
    if (orderId) {
      setIdDetailModal(orderId);
      setOpenDetailOrder(true);
    }
  }, [orderId]);

  useEffect(() => {
    if (uDataLevel) {
      const level = uDataLevel.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataLevel([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...level,
      ]);
    }
  }, [uDataLevel, t]);

  useEffect(() => {
    if (uDataSkill) {
      const skill = uDataSkill.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataSkill([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...skill,
      ]);
    }
  }, [uDataSkill, t]);

  useEffect(() => {
    if (uDataPosition) {
      const position = uDataPosition.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataPosition([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...position,
      ]);
    }
  }, [uDataPosition, t]);

  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${ZERO}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  const changeSelectPosition = (item) => {
    setDataPosition(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectLevel = (item) => {
    setDataLevel(function (prev) {
      const index = prev.indexOf(item);
      return handleCheckAll(prev, index);
    });
  };

  const changeSelectSkill = (item) => {
    setDataSkill(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectStatus = (item) => {
    setStatus(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  function handleCheck(items, index) {
    items.forEach(function (item, i) {
      if (index === i) {
        item.status = !item.status;
      } else {
        item.status = false;
      }
    });

    return [...items];
  }

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, limit: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataSkill?.forEach((item) => {
      item.status = false;
    });
    dataLevel?.forEach((item) => {
      item.status = false;
    });
    status?.forEach((item) => {
      item.status = false;
    });
    setIsReset(true);
    setFilter({
      level_id: null,
      position_id: null,
      skill_id: null,
      keyword: null,
      from_time: null,
      to_time: null,
      page: DEFAULT_PAGE_MUI,
      limit: DEFAULT_LIMIT_20,
    });
    setDataPosition([...dataPosition]);
    setDataLevel([...dataLevel]);
    setDataSkill([...dataSkill]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        keyword: value,
        page: DEFAULT_PAGE_MUI,
        limit: DEFAULT_LIMIT_20,
      });
    }),
  );

  const getClassStatus = (value) => {
    switch (value) {
      case WAIT_BOD_ACCEPT:
        return 'wait';
      case BOD_DONT_ACCEPT:
        return 'cancel';
      case PROCESSING:
        return 'processing';
      case DEADLINE_LATE:
        return 'lateDeadline';
      case CANCEL_ORDER:
        return 'cancel';
      default:
        return 'completed';
    }
  };

  const handleClickDetailRow = (id) => {
    setOpenDetailOrder(true);
    setIdDetailModal(id);
  };

  const handleClickDoneAlert = () => {
    setIsCancelSuccess(false);
    setIsCompletedSuccess(false);
    QueryClient.invalidateQueries([USE_LIST_ORDER_LEADER], { refetchActive: true });
    QueryClient.invalidateQueries([USE_DETAIL_LEADER_ORDER], { refetchActive: true });
    setOpenDetailOrder(true);
  };

  $(function () {
    var running = false;
    $('.wrapper-temp').scroll(function () {
      if (running) {
        running = false;
        return;
      }
      running = true;
      $('.table-container').scrollLeft($('.wrapper-temp').scrollLeft());
    });
    $('.table-container').scroll(function () {
      if (running) {
        running = false;
        return;
      }
      running = true;
      $('.wrapper-temp').scrollLeft($('.table-container').scrollLeft());
    });
  });

  return (
    <Grid container className="manager-order-leader">
      <ModalOrder
        modal={detailOrder}
        toggle={() => {
          setOpenDetailOrder((prev) => !prev);
          window.history.replaceState(null, '', '/admin/manager-order');
        }}
        title={t('modalOrder.title')}
        id={idDetailModal}
        dataPosition={dataPosition}
        dataLevel={dataLevel}
        dataSkill={dataSkill}
        openModalCancel={setShowModalCancel}
        openModalComplete={setShowModalComplete}
      />
      <ModalReason
        title={t('modalOrder.reasonCancel')}
        label={t('modalOrder.typeReasonCancel')}
        id={idDetailModal}
        isShowModal={showModalCancel}
        setIsShowModal={setShowModalCancel}
        returnAfterClose={() => setOpenDetailOrder(true)}
        newStatus={CANCEL_ACTION}
        onSuccess={() => setIsCancelSuccess(true)}
        api={apiPostCancelOrderLeader}
      />
      <AlertAfterSubmit
        toggle={handleClickDoneAlert}
        isShowAlert={isCancelSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('modalOrder.cancelOrderSuccess')}</p>
      </AlertAfterSubmit>
      <ModalReason
        title={t('modalOrder.reasonComplete')}
        label={<span dangerouslySetInnerHTML={{ __html: t('modalOrder.typeReasonComplete') }}></span>}
        id={idDetailModal}
        isShowModal={showModalComplete}
        setIsShowModal={setShowModalComplete}
        returnAfterClose={() => setOpenDetailOrder(true)}
        newStatus={COMPLETED_ACTION}
        onSuccess={() => setIsCompletedSuccess(true)}
        api={apiChangeStatusOrder}
      />
      <AlertAfterSubmit
        toggle={handleClickDoneAlert}
        isShowAlert={isCompletedSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('modalOrder.changeCompleteSuccess')}</p>
      </AlertAfterSubmit>
      <Grid container className="pt-0">
        <Grid item xs sm md lg xl className="header-left color-text up-case">
          {t('menu.managerOrder')}
        </Grid>
      </Grid>
      <Grid container className="pt-4">
        <Grid item md={9} sm={8}>
          <div className="wrapper-temp">
            <div className="div-temp"></div>
          </div>
          <div className="table-border-show">
            <Paper>
              <TableContainer className="table-container">
                <Table aria-label="sticky table" className="table-scroll">
                  <TableHead className="asset-header">
                    <TableRow className="asset-header-row headTable">
                      <TableCell>{t('common.STT')}</TableCell>
                      <TableCell>{t('managerOrderLeader.nameOrder')}</TableCell>
                      <TableCell>{t('masterData.StandardIndex.position')}</TableCell>
                      <TableCell>{t('masterData.StandardIndex.skill')}</TableCell>
                      <TableCell>{t('masterData.StandardIndex.level')}</TableCell>
                      <TableCell>{t('managerOrderHr.status')}</TableCell>
                      <TableCell>{t('managerOrderLeader.deadline')}</TableCell>
                      <TableCell className="text-center">{t('modalOrder.totalRecruit')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingDataListOrders && (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Loading addClass="mt-3 mb-3" />
                        </TableCell>
                      </TableRow>
                    )}
                    {!isLoadingDataListOrders &&
                      Array.isArray(dataListOrders?.list_data?.data) &&
                      dataListOrders?.list_data.data.map((item, index) => {
                        return (
                          <TableRow key={index} onClick={() => handleClickDetailRow(item.id)}>
                            <TableCell className="table-content id-standard">
                              {getSTT(index + filter.page * filter.limit)}
                            </TableCell>
                            <TableCell className="table-content name-create">
                              {item.name?.length > Constants.MAX_LENGTH_20
                                ? item.name.slice(0, Constants.MAX_LENGTH_20) + '...'
                                : item.name}
                            </TableCell>
                            <TableCell className="table-content same-color">
                              {item.position_id
                                ? getPositionName(item?.position_id, dataPosition)
                                : t('common.notDataContract')}
                            </TableCell>
                            <TableCell className="table-content same-color">
                              {item.skill_id ? getSkillName(item?.skill_id, dataSkill) : t('common.notDataContract')}
                            </TableCell>
                            <Tooltip
                              title={
                                <div className="tooltip-division-order">
                                  {item.level_id?.map((val, index) => (
                                    <div className="text-division" key={index}>
                                      {getLevelName(val, dataLevel)}
                                    </div>
                                  ))}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                sx: {
                                  '& 	.MuiTooltip-tooltipArrow': {
                                    top: '-30px !important',
                                    '&::before': {
                                      backgroundColor: 'blue',
                                    },
                                  },
                                },
                              }}
                            >
                              <TableCell className="table-content same-color text-level-leader">
                                <span>
                                  {item.level_id
                                    ? item?.level_id.map((val) => getLevelName(val, dataLevel)).join(', ')
                                    : t('common.notDataContract')}
                                </span>
                              </TableCell>
                            </Tooltip>
                            <TableCell className={`${getClassStatus(item.status)} table-content`}>
                              {status.find((el) => el.value === Number(item.status))?.name}
                            </TableCell>
                            <TableCell className="table-content same-color">
                              {customDate(item.deadline, 'DD/MM/YYYY')}
                            </TableCell>
                            <TableCell className="table-content same-color text-center">
                              {(item?.total_recruited ? item?.total_recruited : 0) + '/' + item?.target}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {dataListOrders?.total_records === ZERO && (
                      <tr>
                        <td colSpan={8} className="error-no-data">
                          {t('common.notData')}
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {dataListOrders?.total_records > ZERO && (
                <TablePagination
                  rowsPerPageOptions={Constants.PER_PAGE_OPTION_20}
                  component="div"
                  count={dataListOrders?.total_records}
                  rowsPerPage={filter?.limit}
                  page={filter.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagination"
                  labelDisplayedRows={displayLabelTablePagination}
                />
              )}
            </Paper>
          </div>
        </Grid>
        <Grid item md={3} sm={4} className={'filter'}>
          <EditLayout permissionName={ORDER_HR_PERMISSION}>
            <Link to={{ pathname: configs.DOMAIN_AMS_WORKFLOW + '/request' }} target="_blank">
              <ButtonComponent
                icon={<AddIcon />}
                addClass={styles.createRequestButton}
                text={t('managerOrderLeader.createRequest')}
              />
            </Link>
          </EditLayout>
          <div className="info-menu-right-no-image selected">
            <div className="info-menu-righ-title">{t('managerOrderHr.totalOrder')}</div>
            <div className="info-menu-righ-total">{dataListOrders?.total_records}</div>
          </div>
          <div className={`filter-search`}>
            <SearchComponent
              txtPlaceholder={t('managerOrderHr.nameOrder')}
              addClass="input-search-box search-leader"
              isReset={isReset}
              setIsReset={setIsReset}
              handleSearch={handleSearch}
              maxLength={Constants.MAX_LENGTH_100}
            />
          </div>
          <InputBase
            disabled
            type="text"
            name="position_id"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { position: true };
              });
            }}
            value={
              dataPosition &&
              (!dataPosition[ZERO]?.status
                ? dataPosition
                    .map((item, i) =>
                      item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                    )
                    .filter((it) => it)
                : t('common.all'))
            }
            placeholder={t('masterData.StandardIndex.position')}
          />
          <ModalCommon
            items={dataPosition}
            onSubmit={() => {
              let datas = [];
              dataPosition.forEach((it) => {
                it.status && it.id && datas.push(it.id);
              });

              setFilter({
                ...filter,
                position_id: datas,
                page: Constants.DEFAULT_PAGE_MUI,
              });
              setIsModals((prev) => {
                return { ...prev, position: false };
              });
            }}
            onClick={changeSelectPosition}
            toggle={() => {
              setIsModals((prev) => {
                return { ...prev, position: !prev.position };
              });
              if (filter?.position_id) {
                dataPosition?.forEach((item) => {
                  Number(filter?.position_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
                });
                setDataPosition([...dataPosition]);
              } else {
                dataPosition?.forEach((item) => {
                  item.status = false;
                });
                setDataPosition([...dataPosition]);
              }
            }}
            modal={isModals.position}
            title={t('masterData.StandardIndex.choosePosition')}
            footer={t('common.select')}
            className="modal-position"
          />
          <InputBase
            disabled
            type="text"
            name="skill_id"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { skill: true };
              });
            }}
            value={
              dataSkill &&
              (!dataSkill[ZERO]?.status
                ? dataSkill
                    .map((item, i) =>
                      item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                    )
                    .filter((it) => it)
                : t('common.all'))
            }
            placeholder={t('masterData.StandardIndex.skill')}
          />
          <ModalCommon
            items={dataSkill}
            onSubmit={() => {
              let datas = [];
              dataSkill.forEach((it) => {
                it.status && it.id && datas.push(it.id);
              });
              setFilter({
                ...filter,
                skill_id: datas,
                page: Constants.DEFAULT_PAGE_MUI,
              });
              setIsModals((prev) => {
                return { ...prev, skill: false };
              });
            }}
            onClick={changeSelectSkill}
            toggle={() => {
              setIsModals((prev) => {
                return { ...prev, skill: !prev.skill };
              });
              if (filter?.skill_id) {
                dataSkill?.forEach((item) => {
                  Number(filter?.skill_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
                });
                setDataSkill([...dataSkill]);
              } else {
                dataSkill?.forEach((item) => {
                  item.status = false;
                });
                setDataSkill([...dataSkill]);
              }
            }}
            modal={isModals.skill}
            title={t('masterData.StandardIndex.chooseSkill')}
            footer={t('common.select')}
          />
          <InputBase
            disabled
            type="text"
            name="level_id"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { level: true };
              });
            }}
            value={changNameLevel(dataLevel)}
            placeholder={t('masterData.StandardIndex.level')}
          />
          <ModalCommon
            items={dataLevel}
            onSubmit={() => {
              let datas = [];
              dataLevel.forEach((it) => {
                it.status && it.id && datas.push(it.id);
              });
              setFilter({
                ...filter,
                level_id: datas,
                page: Constants.DEFAULT_PAGE_MUI,
              });
              setIsModals((prev) => {
                return { ...prev, level: false };
              });
            }}
            onClick={changeSelectLevel}
            toggle={() => {
              setIsModals((prev) => {
                return { ...prev, level: !prev.level };
              });
              if (filter?.level_id) {
                dataLevel?.forEach((item) => {
                  filter?.level_id.includes(item?.id) ? (item.status = true) : (item.status = false);
                });
                setDataLevel([...dataLevel]);
              } else {
                dataLevel?.forEach((item) => {
                  item.status = false;
                });
                setDataLevel([...dataLevel]);
              }
            }}
            modal={isModals.level}
            title={t('masterData.StandardIndex.chooseLevel')}
            footer={t('common.select')}
          />
          <InputBase
            disabled
            type="text"
            name="status"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { status: true };
              });
            }}
            value={status
              .map((item, index) =>
                item.status && item.value !== Constants.ALL ? (index !== 1 ? ' ' : '') + item.name : '',
              )
              .filter((it) => it)}
            placeholder={t('managerOrderHr.status')}
          />
          <ModalCommon
            items={status}
            onSubmit={() => {
              let datas = [];
              status.forEach((it) => {
                it.status && it.value && datas.push(it.value);
              });
              setFilter({
                ...filter,
                status: datas,
                page: Constants.DEFAULT_PAGE_MUI,
              });
              setIsModals((prev) => {
                return { ...prev, status: false };
              });
            }}
            onClick={changeSelectStatus}
            toggle={() => {
              setIsModals((prev) => {
                return { ...prev, status: !prev.status };
              });
              if (filter?.status) {
                status?.forEach((item) => {
                  Number(filter?.status) === Number(item?.value) ? (item.status = true) : (item.status = false);
                });
                setStatus([...status]);
              } else {
                status?.forEach((item) => {
                  item.status = false;
                });
                setStatus([...status]);
              }
            }}
            modal={isModals.status}
            title={t('managerOrderHr.status')}
            footer={t('common.select')}
          />
          <FilterOrderByDeadline
            fromTime={filter.from_time}
            toTime={filter.to_time}
            onChangeFromTime={(dateSearch) => {
              setFilter({ ...filter, from_time: dateSearch });
            }}
            onChangeToTime={(dateSearch) => {
              setFilter({ ...filter, to_time: dateSearch });
            }}
          />
          <ButtonBase onClick={() => handleReset()} className="reset mt-3" size="lg">
            <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
            {t('common.reset')}
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManagerOrderLeader;
