import React, { useMemo, useState, useContext, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid } from '@mui/material';
import moment from 'moment';

import './styles.scss';
import styles from './styles.module.scss';

import AverageRating from '../RattingApply';
import ModalToDivision from './ModalToDivision';
import ModalEvaluateAllotmentDivision from './ModalEvaluateAllotmentDivision';
// import ModalCandidateAssessment from './ModalCandidateAssessment';
import ModalBookInterview from './ModalBookInterview';
// import ModalSettingDivision from './ModalSettingDivision';

import AlertAfterSubmit from 'components/AlertAfterSubmit';
import ModalRefuse from 'components/ModalRefuse';
import Loading from 'components/Loading';
import ButtonComponent from 'components/Buttons';
import PopupAreYouSure from 'components/PopupAreYouSure';

import StatusQuestion from './Components/StatusQuestion';
import StatusTest from './Components/StatusTest';
import StatusHistoryInterView from './Components/StatusHistoryInterView';
import StatusInterView from './Components/StatusInterView';
import DesiredCandidate from './Components/DesiredCandidate';
import InfoCandidate from './InfoCandidate/InfoCandidate';
import BaseInformation from './Components/BaseInformation';

import ModalCandidateAssessment from './ModalCandidateAssessment';

import { ThemContext } from 'layouts/Admin';
import { useGetListDivision } from 'hook/useDivision';
import { useGetAverageAssesCandidate } from 'hook/useDetailApplyLeader';
import { useGetApplyCandidate } from 'hook/useCandidateApply';
import { useGetListTest } from 'hook/useDetailTest';
import { useGetListAssess } from 'hook/useGetListAssess';
import { useGetHistoryInterView } from 'hook/useHistoryInterView';
import { useListLevel, useListPosition, useListSkill } from 'hook/useMasterData';
import { useGetQuestionsInterview } from 'hook/useManagerQuestionInterview';
import { apiCandidateChangeStatusApply } from 'api/managerCandidate';

import { getName, getNameStatus } from 'helpers/index';
import { sendPut } from 'helpers/axios';
import { getSkillName, getPositionName, getLevelName } from 'helpers/masterData';

import {
  LIST_STATUS,
  STATUS_CANDIDATE,
  TYPE_CANDIDATE_LEADER,
  USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL,
  USE_GET_LIST_LEADER_ASSESS_CANDIDATE,
  LIST_STATUS_TEST,
  STATUS_TEST,
} from 'constants/ManagerCandidate';
import { LOADING_SM } from 'constants/index';
import * as CANDIDATE from 'constants/ManagerCandidate';
import ModalOffer from './Components/ModalOffer';
import classNames from 'classnames';
import { HTTP_OK } from 'constants/StatusCode';
import EditLayout from 'components/PermissionPage/EditLayout';
import { CANDIDATE_PERMISSION } from 'constants/permission';
import CandidateEvaluation from './Components/CandidateEvaluation';

function LayoutLeader({ children, tab }) {
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const { applyId } = useParams();
  const { dataInfoUser, isAssigner } = useContext(ThemContext);

  const { data: uDataPosition } = useListPosition();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataLevel } = useListLevel();
  const { data: uListDivision } = useGetListDivision();

  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalCancelInterView, setShowModalCancelInterView] = useState(false);
  const [showModalBookInterview, setShowModalBookInterview] = useState(false);
  const [showModalReasonForRefusal, setShowModalReasonForRefusal] = useState(false);
  const [showModalAllocationToDivision, setShowModalAllocationToDivision] = useState(false);
  const [showModalSettingDivision] = useState(false);
  // const [showModalSettingDivision, setShowModalSettingDivision] = useState(false);
  const [showModalCandidateAssessment, setShowModalCandidateAssessment] = useState(false);
  const [showModalOffer, setShowModalOffer] = useState(false);
  const [showModalCandidateCancelOffer, setShowModalCandidateCancelOffer] = useState(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState();
  const [confirmCancelOffer, setConfirmCancelOffer] = useState(false);

  const { data: uApplyCandidate, isLoading: isLoadingApplyCandidate } = useGetApplyCandidate(applyId, {
    type: TYPE_CANDIDATE_LEADER,
  });
  const { data: uAverageAssess } = useGetAverageAssesCandidate(applyId);

  const { data: dataListTest } = useGetListTest({ applyId: applyId });
  const { data: uHistoryInterview } = useGetHistoryInterView({ apply_id: applyId, type: TYPE_CANDIDATE_LEADER });
  const { data: uGetListAssess } = useGetListAssess(applyId);
  const { data: dataQuestionsResponse, isLoading: isLoadingQuestionInterview } = useGetQuestionsInterview(applyId);

  useEffect(() => {
    if (uApplyCandidate?.candidate && applyId) {
      const pathArr = window.location.pathname.split('/');
      const tailURL = pathArr[pathArr.length - 1];
      window.history.replaceState(
        null,
        '',
        `/admin/candidate-detail/${uApplyCandidate?.candidate}/apply-detail/${applyId}/${!!+tailURL ? '' : tailURL}`,
      );
    }
  }, [uApplyCandidate, applyId]);

  const getContentHistory = () => {
    if (uHistoryInterview?.length !== undefined) return uHistoryInterview.length + ' ' + t('managerCandidate.mark');
    return 0 + ' ' + t('managerCandidate.mark');
  };

  const getBaseInfo = (data) => {
    if (!data) return;
    return {
      apply_id: data.id,
      fullName: data?.full_name,
      positionName: data?.position_id ? getPositionName(data.position_id, uDataPosition) : t('common.notDataContract'),
      skillName: data?.skill_id ? getSkillName(data.skill_id, uDataSkill) : t('common.notDataContract'),
      levelName: data?.level_id ? getLevelName(data.level_id, uDataLevel) : t('common.notDataContract'),
    };
  };

  const { mutate: beginInterview, isLoading: isLoadingBeginInterview } = useMutation(
    async () => {
      return sendPut(`/api/candidate/apply/${applyId}/status`, {
        status: STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE,
        user_id: uApplyCandidate?.id,
      });
    },
    {
      onSuccess: async () => {
        QueryClient.refetchQueries(USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL);
      },
      onError: () => {
        // setError(FORM_FIELDS.reason, { type: 'custom', message: t('common.anUnknownError') });
      },
    },
  );

  const ButtonCancelInterView = () => {
    return (
      <Grid container className="button-accept-interview">
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button
              className="button-refuse-interview"
              onClick={() => {
                setShowModalCancelInterView(true);
              }}
            >
              {t('managerCandidate.cancelPV')}
            </button>
          </EditLayout>
        </Grid>
        <Grid xs={0.6}></Grid>
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <ButtonComponent
              handleClick={beginInterview}
              addClass="button-book-interview"
              hasDisabled={isLoadingBeginInterview || moment(uApplyCandidate?.interview_date, 'YYYY-MM-DD HH:mm').isAfter(moment(), 'minute')}
              isLoading={isLoadingBeginInterview}
              text={t('managerCandidate.beginPV')}
            />
          </EditLayout>
        </Grid>
      </Grid>
    );
  };

  const ButtonCandidateRating = () => {
    return (
      <Grid className="button-accept-interview">
        <Grid xs={12}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button onClick={() => setShowModalCandidateAssessment(true)} className="button-book-interview">
              {t('managerCandidate.candidateAssetment')}
            </button>
          </EditLayout>
        </Grid>
      </Grid>
    );
  };

  const ButtonOfferCandidate = () => {
    return (
      <Grid container className="button-accept-interview">
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button
              className="button-refuse-interview"
              onClick={() => {
                setConfirmCancelOffer(true);
              }}
            >
              {t('managerCandidate.LeaderNotOffer')}
            </button>
          </EditLayout>
        </Grid>
        <Grid xs={0.6}></Grid>
        <Grid xs={5.7}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <button onClick={() => setShowModalOffer(true)} className="button-offer">
              {t('managerCandidate.offer')}
            </button>
          </EditLayout>
        </Grid>
      </Grid>
    );
  };

  const { mutate: changeStatusCandidate, isLoading: isLoadingChangeStatus } = useMutation(
    (status) =>
      apiCandidateChangeStatusApply({
        applyId: applyId,
        status: status,
        user_id: dataInfoUser.user_id,
        is_leader_interrupt: uApplyCandidate?.is_leader_interrupt ? true : null,
      }),
    {
      onSuccess: (response) => {
        if (response.status === HTTP_OK) {
          QueryClient.invalidateQueries([USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL], { refetchActive: true });
          QueryClient.invalidateQueries([USE_GET_LIST_LEADER_ASSESS_CANDIDATE], { refetchActive: true });
          setConfirmCancelOffer(false);
        }
      },
      onError: () => {},
    },
  );

  const getTextStatusTest = (listQuestion, timeForTest, statusTest) => {
    if (!listQuestion) return t('managerCandidate.positionNotHaveTest');
    if (!timeForTest) return t('managerCandidate.positionNotHaveTest');
    return getName(LIST_STATUS_TEST, statusTest);
  };

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => {
    if (isLoadingChangeStatus)
      return (
        <div className={styles.loadingIcon}>
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <ButtonComponent
          addClass={classNames(styles.popupBtn, styles.popupBtn__cancel)}
          text={t('common.cancel')}
          handleClick={onClickCancel}
        />
        <ButtonComponent
          addClass={classNames(styles.popupBtn, styles.popupBtn__ok)}
          text={t('common.ok')}
          handleClick={onClickOk}
        />
      </>
    );
  };

  return (
    <div>
      <PopupAreYouSure
        active={confirmCancelOffer}
        handleCancel={() => setConfirmCancelOffer(false)}
        messages={t('managerCandidate.confirmNotOfferForCandidate')}
        footer={
          <FooterPopupConfirm
            onClickCancel={() => setConfirmCancelOffer(false)}
            onClickOk={() => changeStatusCandidate(STATUS_CANDIDATE.LEADER_REFUSE_CANDIDATE)}
          />
        }
      />
      <Grid container className="detail-candidate">
        <Grid item className="header-back d-flex">
          {uApplyCandidate?.candidate && (
            <NavLink
              to={{
                pathname: `/admin/candidate-detail/${uApplyCandidate?.candidate}`,
              }}
              className={'link-back'}
            >
              <ArrowBackIosIcon />
            </NavLink>
          )}
          <div item className="title-back">
            {t('managerCandidate.detail')}
          </div>
        </Grid>
        <Grid item container spacing={2} className="pt-0 ">
          <Grid item xs={9} className="main-content">
            <div className="parent d-flex">
              <StatusInterView
                dataCandidateApply={uApplyCandidate}
                txtContent={getNameStatus(
                  LIST_STATUS,
                  uApplyCandidate?.status,
                  uApplyCandidate?.division_id,
                  uListDivision,
                )}
                type="leader"
                isLoading={isLoadingApplyCandidate}
                candidateId={uApplyCandidate?.candidate}
                applyId={applyId}
                isActive={tab === CANDIDATE.LEADER_TAB_INFO}
                uListDivision={uListDivision}
                isAssigner={isAssigner}
              />
              <StatusTest
                dataCandidateApply={uApplyCandidate}
                txtStatus={getTextStatusTest(
                  dataListTest?.post_test?.list_questions,
                  dataListTest?.post_test?.time_for_test,
                  dataListTest?.post_test?.status,
                )}
                type="leader"
                isLoading={isLoadingApplyCandidate}
                candidateId={uApplyCandidate?.candidate}
                applyId={applyId}
                isActive={tab === CANDIDATE.LEADER_TAB_TEST}
              />
              <StatusQuestion
                dataCandidateApply={uApplyCandidate}
                answer={dataQuestionsResponse?.total_question_has_ask}
                totalQuestion={dataQuestionsResponse?.total_question}
                type="leader"
                isLoading={isLoadingQuestionInterview}
                candidateId={uApplyCandidate?.candidate}
                applyId={applyId}
                isActive={tab === CANDIDATE.LEADER_TAB_QUESTION}
              />
              <StatusHistoryInterView
                txtContent={getContentHistory()}
                type="leader"
                isLoading={isLoadingApplyCandidate}
                candidateId={uApplyCandidate?.candidate}
                applyId={applyId}
                isActive={tab === CANDIDATE.LEADER_TAB_HISTORY_INTERVIEW}
              />
            </div>
            <div className="">{children}</div>
          </Grid>
          <Grid item xs={3}>
            {useMemo(
              () => isLoadingApplyCandidate && <Loading addClass="mt-0 mb-1" type={LOADING_SM} />,
              [isLoadingApplyCandidate],
            )}

            {uApplyCandidate && (
              <>
                {uApplyCandidate?.status === STATUS_CANDIDATE.WAITING_INTERVIEW && <ButtonCancelInterView />}
                {uApplyCandidate?.status === STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE && (
                  <ButtonCandidateRating />
                )}
                {uApplyCandidate?.status === STATUS_CANDIDATE.LEADER_RATED_AND_HAS_NOT_CONFIRMED_OFFER &&
                  !isAssigner && <ButtonOfferCandidate />}

                <div className="profileBoxInfo">
                  <InfoCandidate
                    uApplyCandidate={uApplyCandidate}
                    isShowModal={showModalSettingDivision}
                    uDataLevel={uDataLevel}
                  />
                </div>

                {uApplyCandidate?.status >= STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE && (
                  <div>
                    <DesiredCandidate applyDetail={uApplyCandidate} isLeader />
                    <CandidateEvaluation applyDetail={uApplyCandidate} isLeader />
                    <AverageRating list_stats={uAverageAssess} />
                  </div>
                )}

                <BaseInformation isLoadingApplyCandidate={isLoadingApplyCandidate} uApplyCandidate={uApplyCandidate} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <ModalRefuse
        title={t('managerDecentralization.reasonReject')}
        label={t('managerCandidate.reasonForTheRefusal')}
        isShowModal={showModalCancel}
        setIsShowModal={setShowModalCancel}
        listApply={{ id: applyId }}
        status={STATUS_CANDIDATE.CANDIDATE_REFUSE_INTERVIEW}
        required={false}
      />
      <ModalRefuse
        title={t('interviewSchedule.cancelModal.ReasonCancelingInterview')}
        label={t('interviewSchedule.cancelModal.reason.placeholder')}
        isShowModal={showModalCancelInterView}
        setIsShowModal={setShowModalCancelInterView}
        listApply={{ id: applyId }}
        status={STATUS_CANDIDATE.LEADER_CANCEL_INTERVIEW}
        required
        confirmMessage={t('managerCandidate.cancelInterviewSuccess')}
      />
      <ModalRefuse
        title={t('managerDecentralization.reasonReject')}
        label={t('managerCandidate.reasonForTheRefusal')}
        isShowModal={showModalCandidateCancelOffer}
        setIsShowModal={setShowModalCandidateCancelOffer}
        listApply={{ id: applyId }}
        status={STATUS_CANDIDATE.REFUSE_OFFER}
        required
      />
      <ModalToDivision
        title={t('modalOrder.allocateToDivision')}
        isShowModal={showModalAllocationToDivision}
        setIsShowModal={setShowModalAllocationToDivision}
        onSuccess={() => setIsCancelSuccess(true)}
      />
      <ModalBookInterview
        isShowModal={showModalBookInterview}
        setShowModal={setShowModalBookInterview}
        candidateData={getBaseInfo(uApplyCandidate)}
      />
      <ModalEvaluateAllotmentDivision
        isShowModal={showModalReasonForRefusal}
        setIsShowModal={setShowModalReasonForRefusal}
        title={t('managerDecentralization.reasonReject')}
        applyId={uApplyCandidate?.id}
        userId={dataInfoUser?.id}
      />
      {/* <ModalSettingDivision
        isShowModal={showModalSettingDivision}
        setIsShowModal={setShowModalSettingDivision}
        title={t('modalOrder.allocateToDivision')}
        applyId={uApplyCandidate?.id}
        dataDivision={uListDivision}
        onSuccess={() => setIsCancelSuccess(true)}
      /> */}
      <ModalCandidateAssessment
        isShowModal={showModalCandidateAssessment}
        setIsShowModal={setShowModalCandidateAssessment}
        title={t('managerCandidate.candidateAssetment')}
        dataListAsset={uGetListAssess?.data?.list_stats}
        applyId={uApplyCandidate?.id}
        isRatingTest={!!STATUS_TEST.COMPLETE.includes(uApplyCandidate?.candidate_post_test?.status)}
        // userId={dataInfoUser?.user_id}
      />
      {showModalOffer && (
        <ModalOffer
          isShowModal={showModalOffer}
          setShowModal={setShowModalOffer}
          isLeaderInterrupt={uApplyCandidate?.is_leader_interrupt}
        />
      )}
      <AlertAfterSubmit
        toggle={() => setIsCancelSuccess(false)}
        isShowAlert={isCancelSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('managerCandidate.successfulDistribution')}</p>
      </AlertAfterSubmit>
    </div>
  );
}

export default LayoutLeader;
