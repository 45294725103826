export const GET_INTERVIEW_LIST = "GET_INTERVIEW_LIST"
export const GET_LOCATIONS = "GET_LOCATIONS"
export const GET_INTERVIEWERS = "GET_INTERVIEWERS"

export const INTERVIEW_TYPE = {
  ONLINE: 1,
  OFFLINE: 2,
}
export const INTERVIEW_STATUS = {
  WAITING: 1,
  CANCELED: 2,
  DONE: 3,
}