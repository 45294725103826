import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import styles from './styles.module.scss';

import { addOrUpdateSchool } from 'api/masterData';

import { HTTP_OK } from 'constants/StatusCode.js';
import { MAX_LENGTH_100, MAX_LENGTH_10 } from 'constants/index.js';
import * as Constants from 'constants/ManagerCandidate';

import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';

import { showAlert } from 'helpers/modal';
import { yupResolver } from '@hookform/resolvers/yup';

const CreateOrUpdateSchool = ({
  setShowModal,
  isShowModal,
  formAdd,
  textModal,
  setShowAlertUpdate,
  setShowAlertCreate,
  setMessage,
  queryClient,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setError,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(t('managerSchool.nameSchoolRequired')),
      }),
    ),
    defaultValues: formAdd,
  });

  const { mutate: submit, isLoading: isLoadingAddSchool } = useMutation(
    (values) => {
      let payload = { name: values.name };
      if (values.code) payload.code = values.code;
      if (formAdd.id) payload.id = formAdd.id;
      return addOrUpdateSchool(payload);
    },
    {
      onSuccess: (response) => {
        if (response?.data?.status === HTTP_OK) {
          formAdd.id
            ? setMessage(t('managerSchool.editSchoolSuccess'))
            : setMessage(t('managerSchool.addSchoolSuccess'));
          formAdd.id ? setShowAlertUpdate(true) : setShowAlertCreate(true);
          queryClient.invalidateQueries(Constants.USE_GET_LIST_SCHOOL);
          setShowModal(false);
        } else {
          setError('code', { message: response?.data?.messages?.code?.[0] });
          setError('name', { message: response?.data?.messages?.name?.[0] });
        }
      },
      onError: (error) => {
        showAlert(t('messages.systemError'));
      },
    },
  );

  return (
    <>
      <ModalComponent
        toggle={() => setShowModal(!isShowModal)}
        isShowModal={isShowModal}
        title={textModal.title}
        classNameAdd={styles.modalAddSchool}
      >
        <form>
          <Grid container rowSpacing={2.5}>
            <Grid item xs={12}>
              <CustomInputYup
                label={t('managerSchool.nameSchool')}
                name={`name`}
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                required
                maxLength={MAX_LENGTH_100}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputYup
                label={t('managerSchool.nameCodeSchool')}
                name={`code`}
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                maxLength={MAX_LENGTH_10}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="btn-submit mt-3">
            <Grid item xs={6}>
              <ButtonComponent
                text={t('common.cancel')}
                addClass="btn-bg-yellow2 mr-3 w-100"
                handleClick={() => setShowModal(false)}
              />
            </Grid>
            <Grid item xs={6}>
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="btn-bg-yellow1 w-100"
                hasDisabled={false}
                type="submit"
                handleClick={handleSubmit(submit)}
                isLoading={isLoadingAddSchool}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </>
  );
};

export default CreateOrUpdateSchool;
