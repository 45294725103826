import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconHeader from 'assets/img/icons/dashboard/groupPeople.svg';
import LayoutBlock from '../components/layoutBlock';
import { useGetStatisticsByCandidateOffer } from 'hook/useDashboard';
import Loading from 'components/Loading';
import { useGetListSchool, useGetListSourceCV } from 'hook/useManagerCandidate';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';
import { displayLabelTablePagination } from 'helpers/table';
import { setDataAllModalWithStatus } from 'helpers/modal';
import * as Constants from 'constants/index.js';
import ListCandidateOffer from '../components/ListCandidateOffer';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useGetListDivision } from 'hook/useDivision';
import styles from './styles.module.scss';
import { DatePicker } from 'antd';
import CalendarIcon from '@mui/icons-material/Event';
import { customDate } from 'helpers/formatDate';
import moment from 'moment';

const StatisticsByCandidateOffer = ({ filterDate, filter, setFilter }) => {
  const { t } = useTranslation();
  const { data: dataStatisticsCandidateOffer, isLoading: isLoadingCandidateOfferTable } =
    useGetStatisticsByCandidateOffer({
      ...filterDate,
      ...filter,
    });

  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();
  const { data: divisions } = useGetListDivision();
  const { data: uDataListSchool } = useGetListSchool();
  const { data: uDataListSourceCV } = useGetListSourceCV();

  useEffect(() => {
    if (uDataLevel) setDataLevel(setDataAllModalWithStatus(uDataLevel));
  }, [uDataLevel]);

  useEffect(() => {
    if (uDataSkill) setDataSkill(setDataAllModalWithStatus(uDataSkill));
  }, [uDataSkill]);

  useEffect(() => {
    if (uDataPosition) setDataPosition(setDataAllModalWithStatus(uDataPosition));
  }, [uDataPosition]);

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
  };

  return (
    <LayoutBlock title={t('dashboard.statisticsCvByCandidateOffer')} icon={iconHeader}>
      <div className={styles.candidatePassFilter}>
        <FormControl style={{ flex: 1, marginRight: '10px' }}>
          <InputLabel className={styles.divisionLabel} id="select-division-label">
            Division
          </InputLabel>
          <Select
            labelId="select-division-label"
            id="select-division"
            label="divisions"
            value={filter.division || ''}
            onChange={(value) => {
              setFilter({ ...filter, division: value.target.value });
            }}
            className={styles.candidatePassFilterBtn}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {divisions?.map((division, index) => (
              <MenuItem value={division.id} key={index}>
                {division.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DatePicker
          placeholder={t('dashboard.fromLabel')}
          size="middle"
          onChange={(value) => {
            setFilter({ ...filter, from_time: customDate(value, 'YYYY-MM-DD') });
          }}
          format="DD/MM/YYYY"
          className="date-picker-ant-custom mr-10 w-200"
          suffixIcon={<CalendarIcon />}
        />
        <DatePicker
          placeholder={t('dashboard.toLabel')}
          size="middle"
          onChange={(value) => {
            setFilter({ ...filter, to_time: customDate(value, 'YYYY-MM-DD') });
          }}
          format="DD/MM/YYYY"
          className="date-picker-ant-custom mr-10 w-200"
          suffixIcon={<CalendarIcon />}
        />
      </div>
      {isLoadingCandidateOfferTable && <Loading />}
      {!isLoadingCandidateOfferTable && (
        <>
          <ListCandidateOffer
            dataCandidates={dataStatisticsCandidateOffer}
            isLoadingDataStandardIndex={isLoadingCandidateOfferTable}
            dataPosition={dataPosition}
            dataLevel={dataLevel}
            dataSkill={dataSkill}
            dataListSchool={uDataListSchool}
            dataListSourceCV={uDataListSourceCV}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            className="table-pagination"
            labelDisplayedRows={displayLabelTablePagination}
            filter={filter}
            setFilter={setFilter}
            filterDate={filterDate}
          />
        </>
      )}
    </LayoutBlock>
  );
};

export default StatisticsByCandidateOffer;
