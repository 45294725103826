import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import styles from './styles.module.scss';
import { TAB_COMPARE_CANDIDATE, TAB_COMPARE_INTERVIEW } from 'constants/ManagerCandidate';

import ModalComponent from 'components/Modal';
import ColumnInfoCandidate from './ColumnInfoCandidate';
import ColumnInfoInterview from './ColumnInfoInterview';

const CompareCandidate = (props) => {
  const { showCompare, setShowCompare, candidateId1, candidateId2, type } = props;
  const { t } = useTranslation();

  const [tabActive, setTabActive] = useState(TAB_COMPARE_CANDIDATE);

  const handleChangeTab = (e, newValue) => {
    setTabActive(newValue);
  };

  return (
    <ModalComponent
      isShowModal={showCompare}
      title={t('managerCandidate.compareCandidate')}
      toggle={() => {
        setShowCompare(false);
        setTabActive(TAB_COMPARE_CANDIDATE);
      }}
      classNameAdd={styles.modalCompare}
    >
      <Grid container>
        <TabContext value={tabActive}>
          <Grid item xs={12}>
            <TabList onChange={handleChangeTab}>
              <Tab label={t('managerCandidate.infoCandidate')} value={TAB_COMPARE_CANDIDATE} />
              <Tab label={t('managerCandidate.infoPV')} value={TAB_COMPARE_INTERVIEW} />
            </TabList>
          </Grid>
          <TabPanel value={TAB_COMPARE_CANDIDATE}>
            <Grid container item xs={12}>
              <Grid item xs={6} className={styles.columnCandidate1}>
                <ColumnInfoCandidate candidateId={candidateId1} type={type} />
              </Grid>
              <Grid item xs={6} className={styles.columnCandidate2}>
                <ColumnInfoCandidate candidateId={candidateId2} type={type} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={TAB_COMPARE_INTERVIEW}>
            <Grid container>
              <Grid item xs={6} className={styles.columnCandidate1}>
                <ColumnInfoInterview candidateId={candidateId1} type={type} />
              </Grid>
              <Grid item xs={6} className={styles.columnCandidate2}>
                <ColumnInfoInterview candidateId={candidateId2} type={type} />
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </ModalComponent>
  );
};

export default CompareCandidate;
