export const USE_GET_STATISTICS_BY_SOURCE_CV = 'USE_GET_STATISTICS_BY_SOURCE_CV';
export const USE_GET_STATISTICS_BY_ORDER = 'USE_GET_STATISTICS_BY_ORDER';
export const USE_GET_STATISTICS_BY_JOB = 'USE_GET_STATISTICS_BY_JOB';
export const USE_GET_STATISTICS_BY_LEVEL = 'USE_GET_STATISTICS_BY_LEVEL';
export const USE_GET_STATISTICS_BY_CANDIDATE_OFFER = 'USE_GET_STATISTICS_BY_CANDIDATE_OFFER';
export const USE_GET_HR_METRICS = 'USE_GET_HR_METRICS';

export const TYPE_REPORT = {
  ORDER: 1,
  JOB: 2,
  SOURCE_CV: 3,
  LEVEL: 4,
  POSITION: 5,
};
