import { useEffect, useState, useCallback } from 'react';

import { Grid } from '@mui/material';
import { debounce } from '@material-ui/core';

import './styles.scss';
import RightCandidateSearch from './components/RightCandidateSearch.js';
import TopInfoStatus from './components/TopInfoStatus';
import ListCandidate from './components/ListCandidate';

import { useGetListCandidate } from 'hook/useManagerCandidate';
import { useGetListSchool, useGetListSourceCV, useGetListLanguage } from 'hook/useManagerCandidate';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';

import { setDataAllModalWithStatus } from 'helpers/modal';

import * as Constants from 'constants/index.js';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20, INVALID_DATE } from 'constants/index.js';
import { ZERO } from 'constants/MasterData';
import { TYPE_CANDIDATE_LEADER } from 'constants/ManagerCandidate.js';
import { customDate } from 'helpers/formatDate.js';

const DEFAULT_FILTER = {
  keyword: null,
  level_id: null,
  position_id: null,
  skill_id: null,
  created_date: null,
  school: null,
  source_cv: null,
  page: DEFAULT_PAGE_MUI,
  per_page: DEFAULT_LIMIT_20,
  type: TYPE_CANDIDATE_LEADER,
  experience: null,
  status: null,
  interview_date: null,
};

const HrCandidate = () => {
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [isModals, setIsModals] = useState({ position: false, skill: false, level: false });

  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();
  const { data: uDataListSchool } = useGetListSchool();
  const { data: uDataListSourceCV } = useGetListSourceCV();
  const { data: uDataListLanguage } = useGetListLanguage();
  const [candidateCheckedBox, setCandidateCheckedBox] = useState([]);

  const {
    data: dataCandidates,
    isLoading: isLoadingDataStandardIndex,
    refetch: refetchDataCandidate,
  } = useGetListCandidate({
    ...filter,
    interview_date:
      customDate(filter?.interview_date, 'DD/MM/YYYY') !== INVALID_DATE
        ? customDate(filter.interview_date, 'DD/MM/YYYY')
        : null,
    page: filter.page + 1,
  });

  useEffect(() => {
    if (uDataLevel) setDataLevel(setDataAllModalWithStatus(uDataLevel));
  }, [uDataLevel]);

  useEffect(() => {
    if (uDataSkill) setDataSkill(setDataAllModalWithStatus(uDataSkill));
  }, [uDataSkill]);

  useEffect(() => {
    if (uDataPosition) setDataPosition(setDataAllModalWithStatus(uDataPosition));
  }, [uDataPosition]);

  const handleCountCheckedBox = (id) => {
    if (candidateCheckedBox.includes(id)) {
      setCandidateCheckedBox(() => candidateCheckedBox.filter((i) => i !== id));
    } else setCandidateCheckedBox(() => [...candidateCheckedBox, id]);
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        keyword: value,
        page: DEFAULT_PAGE_MUI,
        per_page: DEFAULT_LIMIT_20,
      });
    }),
  );

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataSkill?.forEach((item) => {
      item.status = false;
    });
    dataLevel?.forEach((item) => {
      item.status = false;
    });
    setFilter({
      keyword: null,
      level_id: null,
      position_id: null,
      skill_id: null,
      created_date: null,
      school: null,
      source_cv: null,
      experience: null,
      status: null,
      interview_date: null,
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT_20,
      type: TYPE_CANDIDATE_LEADER,
    });
    setDataPosition([...dataPosition]);
    setDataLevel([...dataLevel]);
    setDataSkill([...dataSkill]);
    setCandidateCheckedBox([]);
  };

  return (
    <div>
      <Grid container className="content-manager-candidate">
        <TopInfoStatus totalCandidate={dataCandidates?.total} type={TYPE_CANDIDATE_LEADER} />

        <Grid item md={9}>
          <ListCandidate
            type="leader"
            dataCandidates={dataCandidates}
            isLoadingDataStandardIndex={isLoadingDataStandardIndex}
            handleCountCheckedBox={handleCountCheckedBox}
            filter={filter}
            dataPosition={dataPosition}
            dataLevel={dataLevel}
            dataSkill={dataSkill}
            dataListSchool={uDataListSchool}
            dataListLanguage={uDataListLanguage}
            dataListSourceCV={uDataListSourceCV}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            candidateCheckedBox={candidateCheckedBox}
            refetchDataCandidate={refetchDataCandidate}
          />
        </Grid>

        <Grid item md={3} className={'filter-leader'}>
          <RightCandidateSearch
            type="leader"
            dataPosition={dataPosition}
            dataLevel={dataLevel}
            dataSkill={dataSkill}
            isModals={isModals}
            setIsModals={setIsModals}
            filter={filter}
            setFilter={setFilter}
            setDataPosition={setDataPosition}
            setDataLevel={setDataLevel}
            setDataSkill={setDataSkill}
            handleSearch={handleSearch}
            handleReset={handleReset}
            candidateCheckedBox={candidateCheckedBox}
            dataListLanguage={uDataListLanguage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HrCandidate;
