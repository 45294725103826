import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InputBase, ButtonBase } from '@mui/material';
import { debounce } from '@material-ui/core';
import iconLoading from 'assets/img/icons/loading.png';

import { useListPosition, useListLevel, useListSkill } from 'hook/useMasterData';
import { useGetListDivision } from 'hook/useDivision';
import { setDataAllModalWithStatus, handleCheck } from 'helpers/modal';
import {
  LIST_YEAR_EXPERIENCE,
  LIST_STATUS,
  STATUS_CANDIDATE,
  TYPE_CANDIDATE_HR,
  TYPE_CANDIDATE_LEADER,
} from 'constants/ManagerCandidate';
import { IS_FREELANCER, IS_NOT_FREELANCER } from 'constants/manageJob';
import { ThemContext } from 'layouts/Admin';
import { getMessageErrorFormatDate } from 'helpers/formatDate';
import { getValueInputSearchAll } from 'helpers/modal';
import * as Constants from 'constants/index.js';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20 } from 'constants/index.js';
import { CANDIDATE_PERMISSION, EDIT_PERMISSION } from 'constants/permission';

import ModalCommon from 'components/ModalCommon/ModalCommon';
import ButtonComponent from 'components/Buttons';
import SearchComponent from 'components/Search';
import DateDayComponent from 'components/DateTime/DateDay';
import ModalCompareCandidate from '../CompareCandidate';
import ModalAssignToAnotherHR from '../AssignModal';
import EditLayout from 'components/PermissionPage/EditLayout';

const RightCandidate = ({
  type,
  dataPosition,
  dataLevel,
  dataSkill,
  isModals,
  setIsModals,
  filter,
  setFilter,
  setDataLevel,
  setDataPosition,
  setDataSkill,
  handleSearch,
  handleReset,
  candidateCheckedBox,
  setCandidateCheckedBox,
  dataListLanguage,
}) => {
  const { t } = useTranslation();
  const { data: uDataPosition } = useListPosition();
  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uListDivision } = useGetListDivision();

  const listStatusCandidate = LIST_STATUS.filter(
    (status) =>
      status.id >= STATUS_CANDIDATE.FAIL_PRE_QUALIFICATION &&
      status.id <= STATUS_CANDIDATE.END_PROCESS_RECRUIT &&
      status.id !== STATUS_CANDIDATE.ORDER_SUCCESS &&
      status.id !== STATUS_CANDIDATE.LATE_DEADLINE,
  );

  const FREELANCER_DEFAULT = [
    {
      id: IS_NOT_FREELANCER,
      value: IS_NOT_FREELANCER,
      name: t('masterData.StandardIndex.candidateNotFreelancer'),
      status: false,
    },
    {
      id: IS_FREELANCER,
      value: IS_FREELANCER,
      name: t('masterData.StandardIndex.candidateFreelancer'),
      status: false,
    }
  ];

  const [dataDivision, setDataDivision] = useState([]);
  const [filterDivision, setFilterDivision] = useState([]);
  const [dataLanguage, setDataLanguage] = useState([]);
  const [filterLanguage, setFilterLanguage] = useState([]);
  const [filterPosition, setFilterPosition] = useState([]);
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterSkill, setFilterSkill] = useState([]);
  const [filterStatus, setFilterStatus] = useState(listStatusCandidate);
  const [showCompare, setShowCompare] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const { isHRM, hasPermission } = useContext(ThemContext);
  const [dataFreelancer, setDataFreelancer] = useState(FREELANCER_DEFAULT);
  useEffect(() => {
    LIST_YEAR_EXPERIENCE?.forEach((item) => {
      item.status = false;
    });
    LIST_STATUS?.forEach((item) => {
      item.status = false;
    });
  }, [type]);

  useEffect(() => {
    if (uListDivision) setFilterDivision(setDataAllModalWithStatus(uListDivision));
    if (uListDivision) setDataDivision(setDataAllModalWithStatus(uListDivision));
  }, [uListDivision]);

  useEffect(() => {
    if (dataListLanguage) setFilterLanguage(setDataAllModalWithStatus(dataListLanguage));
    if (dataListLanguage) setDataLanguage(setDataAllModalWithStatus(dataListLanguage));
  }, [dataListLanguage]);

  useEffect(() => {
    if (uDataPosition) setFilterPosition(setDataAllModalWithStatus(uDataPosition));
  }, [uDataPosition]);

  useEffect(() => {
    if (uDataLevel) setFilterLevel(setDataAllModalWithStatus(uDataLevel));
  }, [uDataLevel]);

  useEffect(() => {
    if (uDataSkill) setFilterSkill(setDataAllModalWithStatus(uDataSkill));
  }, [uDataSkill]);

  const handleFilterDivision = (query) => {
    const filter = dataDivision.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterDivision(filter);
  };

  const changeSelectDivision = (item) => {
    setFilterDivision(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectFreelancer = (item) => {
    setDataFreelancer(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const queryDivision = () => {
    let datas = [];
    filterDivision.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      division_order_id: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    filterDivision?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    dataDivision?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, division: false };
    });
    setFilterDivision(dataDivision);
  };

  const toggleDivision = () => {
    setIsModals((prev) => {
      return { ...prev, division: !prev.division };
    });
    if (filter?.division_order_id) {
      filterDivision?.forEach((item) => {
        Number(filter?.division_order_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterDivision([...dataDivision]);
    } else {
      filterDivision?.forEach((item) => {
        item.status = false;
      });
      setFilterDivision([...dataDivision]);
    }
  };

  const handleFilterPosition = (query) => {
    const filter = dataPosition.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterPosition(filter);
  };

  const changeSelectPosition = (item) => {
    setFilterPosition(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const queryPosition = () => {
    let datas = [];
    filterPosition.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      position_id: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    filterPosition?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    dataPosition?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, position: false };
    });
    setFilterPosition(dataPosition);
  };

  const togglePosition = () => {
    setIsModals((prev) => {
      return { ...prev, position: !prev.position };
    });
    if (filter?.position_id) {
      filterPosition?.forEach((item) => {
        Number(filter?.position_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterPosition([...dataPosition]);
    } else {
      filterPosition?.forEach((item) => {
        item.status = false;
      });
      setFilterPosition([...dataPosition]);
    }
  };

  const handleFilterLevel = (query) => {
    const filter = dataLevel.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterLevel(filter);
  };

  const changeSelectLevel = (item) => {
    setFilterLevel(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const queryLevel = () => {
    let datas = [];
    filterLevel.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      level_id: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    filterLevel?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    dataLevel?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, level: false };
    });
    setFilterLevel(dataLevel);
  };

  const toggleLevel = () => {
    setIsModals((prev) => {
      return { ...prev, level: !prev.level };
    });
    if (filter?.level_id) {
      filterLevel?.forEach((item) => {
        Number(filter?.level_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterLevel([...dataLevel]);
    } else {
      filterLevel?.forEach((item) => {
        item.status = false;
      });
      setFilterLevel([...dataLevel]);
    }
  };

  const handleFilterSkill = (query) => {
    const filter = dataSkill.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterSkill(filter);
  };

  const changeSelectSkill = (item) => {
    setFilterSkill(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const querySkill = () => {
    let datas = [];
    filterSkill.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      skill_id: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    dataSkill?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    filterSkill?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, skill: false };
    });
    setFilterSkill(dataSkill);
  };

  const toggleSkill = () => {
    setIsModals((prev) => {
      return { ...prev, skill: !prev.skill };
    });
    if (filter?.skill_id) {
      filterSkill?.forEach((item) => {
        Number(filter?.skill_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterSkill([...dataSkill]);
    } else {
      filterSkill?.forEach((item) => {
        item.status = false;
      });
      setFilterSkill([...dataSkill]);
    }
  };

  const handleFilterStatus = (query) => {
    const filter = listStatusCandidate.filter((item) =>
      item['name'].toLowerCase().includes(query.toLowerCase().trim()),
    );
    setFilterStatus(filter);
  };

  const changeSelectStatus = (item) => {
    setFilterStatus(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const queryStatus = () => {
    let datas = [];
    filterStatus.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      status: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    filterStatus?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    filterStatus?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, status: false };
    });
    setFilterStatus(filterStatus);
  };

  const toggleStatus = () => {
    setIsModals((prev) => {
      return { ...prev, status: !prev.status };
    });
    if (filter?.status) {
      filterStatus?.forEach((item) => {
        Number(filter?.status) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterStatus([...listStatusCandidate]);
    } else {
      filterStatus?.forEach((item) => {
        item.status = false;
      });
      setFilterStatus([...listStatusCandidate]);
    }
  };

  const handleFilterLanguage = (query) => {
    const filter = dataLanguage.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterLanguage(filter);
  };

  const changeSelectLanguage = (item) => {
    setFilterLanguage(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const queryLanguage = () => {
    let datas = [];
    filterLanguage.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      language_id: !datas.join() ? null : datas.join(),
      page: Constants.DEFAULT_PAGE_MUI,
    });
    filterLanguage?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    filterLanguage?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setIsModals((prev) => {
      return { ...prev, language: false };
    });
    setFilterLanguage(filterLanguage);
  };

  const toggleLanguage = () => {
    setIsModals((prev) => {
      return { ...prev, language: !prev.language };
    });
    if (filter?.language_id) {
      filterLanguage?.forEach((item) => {
        Number(filter?.language_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterLanguage([...dataLanguage]);
    } else {
      filterLanguage?.forEach((item) => {
        item.status = false;
      });
      setFilterLanguage([...dataLanguage]);
    }
  };

  const handleResetFilter = () => {
    handleReset();
    dataDivision?.forEach((item) => {
      item.status = false;
    });
    setDataDivision(dataDivision);
    listStatusCandidate?.forEach((item) => {
      item.status = false;
    });
    setFilterStatus(listStatusCandidate);
    setDataFreelancer(FREELANCER_DEFAULT);
    dataLanguage?.forEach((item) => {
      item.status = false;
    });
    setFilterLanguage(dataLanguage);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchHR = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        hr_name: value,
        page: DEFAULT_PAGE_MUI,
        per_page: DEFAULT_LIMIT_20,
      });
    }),
  );

  return (
    <div>
      {isHRM && type === 'hr' && (
        <>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <ButtonComponent
              addClass={'button-add-menu-right button-assign'}
              text={t('managerCandidate.assignEn')}
              handleClick={() => setShowAssign(true)}
              hasDisabled={candidateCheckedBox.length === 0 || !hasPermission(CANDIDATE_PERMISSION, EDIT_PERMISSION)}
            />
          </EditLayout>
          <ModalAssignToAnotherHR
            showModal={showAssign}
            setShowModal={setShowAssign}
            listCandidatePicked={candidateCheckedBox}
            onSuccessAssign={() => setCandidateCheckedBox([])}
          />
        </>
      )}
      <ButtonComponent
        addClass={type === 'hr' ? 'button-add-menu-right button-compare' : 'button-add-menu-right'}
        text={t('managerCandidate.compare')}
        handleClick={() => setShowCompare(true)}
        hasDisabled={candidateCheckedBox.length !== 2}
      />
      {showCompare && (
        <ModalCompareCandidate
          showCompare={showCompare}
          setShowCompare={setShowCompare}
          candidateId1={candidateCheckedBox?.at(0)}
          candidateId2={candidateCheckedBox?.at(1)}
          type={type === 'hr' ? TYPE_CANDIDATE_HR : TYPE_CANDIDATE_LEADER}
        />
      )}
      <div className={`text padding-element-filter filter-search`}>
        <SearchComponent
          txtPlaceholder={t('managerCandidate.searchName')}
          addClass="input-search-box search"
          defaultValue={filter?.keyword}
          handleSearch={handleSearch}
          maxLength={Constants.MAX_LENGTH_50}
          size={'medium'}
        />
      </div>

      {(type === 'hr' || isHRM) && (
        <div className={`text padding-element-filter filter-search`}>
          <SearchComponent
            txtPlaceholder={t('managerCandidate.searchHR')}
            addClass="input-search-box search"
            defaultValue={filter?.hr_name}
            handleSearch={handleSearchHR}
            maxLength={Constants.MAX_LENGTH_50}
            size={'medium'}
          />
        </div>
      )}

      {type !== 'leader' && (
        <>
          <InputBase
            // disabled
            type="text"
            name="division_id"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { division: true };
              });
            }}
            value={getValueInputSearchAll(filterDivision)}
            placeholder={t('masterData.StandardIndex.division')}
          />
          <ModalCommon
            items={filterDivision}
            onSubmit={queryDivision}
            onClick={changeSelectDivision}
            toggle={toggleDivision}
            modal={isModals.division}
            title={t('masterData.StandardIndex.chooseDivision')}
            footer={t('common.select')}
            search={
              <SearchComponent
                txtPlaceholder={t('masterData.StandardIndex.division')}
                addClass="input-search-box"
                handleSearch={handleFilterDivision}
                maxLength={Constants.MAX_LENGTH_50}
                size={'small'}
              />
            }
          />
        </>
      )}

      <InputBase
        // disabled
        type="text"
        name="position_id"
        className="input-select-multi mt-3"
        onClick={() => {
          setIsModals(() => {
            return { position: true };
          });
        }}
        value={getValueInputSearchAll(filterPosition)}
        placeholder={t('masterData.StandardIndex.position')}
      />
      <ModalCommon
        items={filterPosition}
        onSubmit={queryPosition}
        onClick={changeSelectPosition}
        toggle={togglePosition}
        modal={isModals.position}
        title={t('masterData.StandardIndex.choosePosition')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('masterData.StandardIndex.position')}
            addClass="input-search-box"
            handleSearch={handleFilterPosition}
            maxLength={Constants.MAX_LENGTH_50}
            size={'small'}
          />
        }
      />

      <InputBase
        // disabled
        type="text"
        name="level_id"
        className="input-select-multi mt-3"
        onClick={() => {
          setIsModals(() => {
            return { level: true };
          });
        }}
        value={getValueInputSearchAll(filterLevel)}
        placeholder={t('masterData.StandardIndex.levelApply')}
      />
      <ModalCommon
        items={filterLevel}
        onSubmit={queryLevel}
        onClick={changeSelectLevel}
        toggle={toggleLevel}
        modal={isModals.level}
        title={t('masterData.StandardIndex.chooseLevelApply')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('masterData.StandardIndex.levelApply')}
            addClass="input-search-box"
            handleSearch={handleFilterLevel}
            maxLength={Constants.MAX_LENGTH_50}
            size={'small'}
          />
        }
      />

      <InputBase
        // disabled
        type="text"
        name="skill_id"
        className="input-select-multi mt-3"
        onClick={() => {
          setIsModals(() => {
            return { skill: true };
          });
        }}
        value={getValueInputSearchAll(filterSkill)}
        placeholder={t('masterData.StandardIndex.skill')}
      />
      <ModalCommon
        items={filterSkill}
        onSubmit={querySkill}
        onClick={changeSelectSkill}
        toggle={toggleSkill}
        modal={isModals.skill}
        title={t('masterData.StandardIndex.chooseSkill')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('masterData.StandardIndex.skill')}
            addClass="input-search-box"
            handleSearch={handleFilterSkill}
            maxLength={Constants.MAX_LENGTH_50}
            size={'small'}
          />
        }
      />

      <DateDayComponent
        label={t('masterData.StandardIndex.interviewDate')}
        addClass="date-search-box mt-3"
        valueDefault={filter.interview_date}
        onChangeHandle={(dateSearch) => {
          setFilter({ ...filter, interview_date: dateSearch });
        }}
        sizeDate="small"
        error={getMessageErrorFormatDate(filter.interview_date, t('managerOrderHr.pleaseEnterTheCorrectFormat'))}
      />

      <div className="d-flex">
        <DateDayComponent
          label={t('masterData.StandardIndex.applyStartDate')}
          addClass="date-search-box mr-3"
          valueDefault={filter.apply_start_date}
          max={filter.apply_end_date ? filter.apply_end_date : null}
          onChangeHandle={(dateSearch) => {
            setFilter({ ...filter, apply_start_date: dateSearch });
          }}
          sizeDate="small"
          error={getMessageErrorFormatDate(filter.apply_start_date, t('managerOrderHr.pleaseEnterTheCorrectFormat'))}
        />
        <DateDayComponent
          label={t('masterData.StandardIndex.applyEndDate')}
          addClass="date-search-box"
          valueDefault={filter.apply_end_date}
          min={filter.apply_start_date ? filter.apply_start_date : null}
          onChangeHandle={(dateSearch) => {
            setFilter({ ...filter, apply_end_date: dateSearch });
          }}
          sizeDate="small"
          error={getMessageErrorFormatDate(filter.apply_end_date, t('managerOrderHr.pleaseEnterTheCorrectFormat'))}
        />
      </div>

      <InputBase
        // disabled
        type="text"
        name="status"
        className="input-select-multi mt-3"
        onClick={() => {
          setIsModals(() => {
            return { status: true };
          });
        }}
        value={getValueInputSearchAll(filterStatus)}
        placeholder={t('masterData.StandardIndex.status')}
      />
      <ModalCommon
        items={filterStatus}
        onSubmit={queryStatus}
        onClick={changeSelectStatus}
        toggle={toggleStatus}
        modal={isModals.status}
        title={t('masterData.StandardIndex.chooseStatus')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('masterData.StandardIndex.status')}
            addClass="input-search-box"
            handleSearch={handleFilterStatus}
            maxLength={Constants.MAX_LENGTH_50}
            size={'small'}
          />
        }
      />

      <InputBase
        type="text"
        name="is_freelancer"
        className="input-select-multi mt-3"
        onClick={() => {
          setIsModals(() => {
            return { freelancer: true };
          });
        }}
        value={
          dataFreelancer &&
          (!dataFreelancer[0]?.status
            ? dataFreelancer
                .map((item, i) => (item.status && item.value !== IS_NOT_FREELANCER ? (i !== 1 ? ' ' : '') + item.name : ''))
                .filter((it) => it)
            : t('masterData.StandardIndex.candidateNotFreelancer'))
        }
        placeholder={t('masterData.StandardIndex.filterFreelancerCandidates')}
      />
      <ModalCommon
        items={dataFreelancer}
        onSubmit={() => {
          let datas = [];
          dataFreelancer.forEach((it) => {
            it.status && (it.id || it.id == 0) && datas.push(it.id);
          });
          setFilter({
            ...filter,
            is_freelancer: !datas.join() ? null : datas.join(),
            page: DEFAULT_PAGE_MUI,
          });
          setIsModals((prev) => {
            return { ...prev, freelancer: false };
          });
        }}
        onClick={changeSelectFreelancer}
        toggle={() => {
          setIsModals((prev) => {
            return { ...prev, freelancer: !prev.freelancer };
          });
          if (filter?.is_freelancer) {
            dataFreelancer?.forEach((item) => {
              Number(filter?.is_freelancer) === Number(item?.id) ? (item.status = true) : (item.status = false);
            });
            setDataFreelancer([...dataFreelancer]);
          } else {
            dataFreelancer?.forEach((item) => {
              item.status = false;
            });
            setDataFreelancer([...dataFreelancer]);
          }
        }}
        modal={isModals.freelancer}
        title={t('masterData.StandardIndex.candidateType')}
        footer={t('common.select')}
      />

      <InputBase
        type="text"
        name="language_id"
        className="input-select-multi mt-3"
        onClick={() => {
          setIsModals(() => {
            return { language: true };
          });
        }}
        value={getValueInputSearchAll(filterLanguage)}
        placeholder={t('managerCandidate.language')}
      />
      <ModalCommon
        items={filterLanguage}
        onSubmit={queryLanguage}
        onClick={changeSelectLanguage}
        toggle={toggleLanguage}
        modal={isModals.language}
        title={t('managerCandidate.chooseLanguage')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('managerCandidate.language')}
            addClass="input-search-box"
            handleSearch={handleFilterLanguage}
            maxLength={Constants.MAX_LENGTH_50}
            size={'small'}
          />
        }
      />

      <ButtonBase onClick={() => handleResetFilter()} className="reset mt-3" size="lg">
        <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
        {t('common.reset')}
      </ButtonBase>
    </div>
  );
};

export default RightCandidate;
