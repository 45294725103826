import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import Loading from 'components/Loading';

import { useListAllStatusCandidate } from 'hook/useManagerCandidate';
import { LOADING_SM } from 'constants/index';
import { STATUS_CANDIDATE } from 'constants/ManagerCandidate';

const TopInfoStatus = ({ totalCandidate, type }) => {
  const { t } = useTranslation();

  const { data: dataStatus, isLoading } = useListAllStatusCandidate({ type });

  const getNumberStatus = (status) => {
    if (dataStatus) {
      return dataStatus[status] ? dataStatus[status] : 0;
    }
    return 0;
  };

  return (
    <Grid container className="content-manager-candidate">
      <Grid container className="pt-0">
        <Grid item xs sm md lg xl className="header-left color-text">
          <span className="text-up">{t('menu.managerCandidate')}</span>
        </Grid>
      </Grid>
      <Grid item md={12} className="block">
        <div className="block-total">
          <p className="title">{t('managerCandidate.totalUv')}</p>
          <p className="value">
            {useMemo(() => isLoading && <Loading type={LOADING_SM} />, [isLoading])}
            {!isLoading && totalCandidate}
          </p>
        </div>
        <div className="block-wait same-width">
          {useMemo(() => isLoading && <Loading type={LOADING_SM} />, [isLoading])}
          {!isLoading && (
            <>
              <p>
                {t('managerCandidate.throughPreQualification')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.PASS_PRE_QUALIFICATION)})</span>
              </p>
              <p>
                {t('managerCandidate.waitingForLeaderToReviewCV')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.WAITING_FOR_LEADER_APPROVE_CV)})</span>
              </p>
              <p>
                {t('managerCandidate.waitingForLeaderToBrowseUV')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.WAITING_LEADER_CONFIRM_CANDIDATE)})</span>
              </p>
              <p>
                {t('managerCandidate.waitForUvToRespondToTheInvitation')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.WAIT_CANDIDATE_RESPONSE)})</span>
              </p>
            </>
          )}
        </div>
        <div className="block-wait same-width">
          {useMemo(() => isLoading && <Loading type={LOADING_SM} />, [isLoading])}
          {!isLoading && (
            <>
              <p>
                {t('managerCandidate.waitingForPV')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.WAITING_INTERVIEW)})</span>
              </p>
              <p>
                {t('managerCandidate.leaderRatedAndHasNotComfirmedOffer')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.LEADER_RATED_AND_HAS_NOT_CONFIRMED_OFFER)})</span>
              </p>
              <p>
                {t('managerCandidate.waitForUvToRespondOffer')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.WAITING_CANDIDATE_RESPONSE_OFFER)})</span>
              </p>
            </>
          )}
        </div>
        <div className="block-success same-width">
          {useMemo(() => isLoading && <Loading type={LOADING_SM} />, [isLoading])}
          {!isLoading && (
            <>
              <p>
                {t('managerCandidate.waitForAllocation')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.WAIT_FOR_ALLOCATION)})</span>
              </p>
              <p>
                {t('managerCandidate.candidateAcceptOffer')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.CANDIDATE_AGREE_OFFER)})</span>
              </p>
              <p>
                {t('managerCandidate.onboard')} <span>({getNumberStatus(STATUS_CANDIDATE.ONBOARD)})</span>
              </p>
            </>
          )}
        </div>

        <div className="block-refuse same-width">
          {useMemo(() => isLoading && <Loading type={LOADING_SM} />, [isLoading])}
          {!isLoading && (
            <>
              <p>
                {t('managerCandidate.noPreQualification')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.FAIL_PRE_QUALIFICATION)})</span>
              </p>
              <p>
                {t('managerCandidate.leaderDoesNotApproveCV')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.CANCEL_CV)})</span>
              </p>
              <p>
                {t('managerCandidate.uvRejectPV')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.CANDIDATE_REFUSE_INTERVIEW)})</span>
              </p>
              <p>
                {t('managerCandidate.leaderDoesNotEnjoyPV')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.LEADER_CANCEL_INTERVIEW)})</span>
              </p>
            </>
          )}
        </div>
        <div className="block-refuse same-width">
          {useMemo(() => isLoading && <Loading type={LOADING_SM} />, [isLoading])}
          {!isLoading && (
            <>
              <p>
                {t('managerCandidate.cancelTheCalendar')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.HR_CANCEL_INTERVIEW)})</span>
              </p>
              <p>
                {t('managerCandidate.leaderDoesNotBrowseUV')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.LEADER_REFUSE_CANDIDATE)})</span>
              </p>
              <p>
                {t('managerCandidate.refuseOffer')} <span>({getNumberStatus(STATUS_CANDIDATE.REFUSE_OFFER)})</span>
              </p>
              <p>
                {t('managerCandidate.candidateNotOnBoard')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.CANDIDATE_CANCEL_ONBOARD)})</span>
              </p>
              <p>
                {t('managerCandidate.endProcessRecruit')}{' '}
                <span>({getNumberStatus(STATUS_CANDIDATE.END_PROCESS_RECRUIT)})</span>
              </p>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default TopInfoStatus;
