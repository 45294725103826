import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { MIN_LENGTH_2, MIN_LENGTH_7, MIN_LENGTH_100, MAX_LENGTH_5000 } from 'constants/index.js';

export const Validate = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    position_id: Yup.string().required(t('messages.positionRequired')).nullable(),
    level_id: Yup.string().required(t('managerJob.levelRequired')).nullable(),
    type_interview: Yup.string().required(t('managerJD.typeInterviewRequired')).nullable(),
    title: Yup.string().min(MIN_LENGTH_2, t('messages.pleaseEnterTwoToHundredCharacters')).trim(),
    salary: Yup.string().min(MIN_LENGTH_7, t('messages.pleaseEnterSevenToThirtyCharacters')).trim(),
    address: Yup.string().min(MIN_LENGTH_2, t('messages.pleaseEnterTwoToHundredCharacters')).trim(),
    experience: Yup.string().min(MIN_LENGTH_2, t('messages.pleaseEnterTwoToThirtyCharacters')).trim(),
    limit: Yup.string()
      .required(t('messages.pleaseEnterTheRequiredQuantity'))
      .matches(/^[0-9]+$/, t('messages.numberIntegerGreaterThanZero'))
      .test('min', t('messages.numberIntegerGreaterThanZero'), (val) => {
        return Number(val) < 1 ? false : true;
      })
      .trim(),
    content: Yup.string()
      .required(t('managerJD.errorLengthContent'))
      .min(MIN_LENGTH_100, t('managerJD.errorLengthContent'))
      .max(MAX_LENGTH_5000, t('managerJD.errorLengthContent'))
      .trim(),
  });
  return validationSchema;
};

export default Validate;
