import React, { useState, useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { MdDelete } from 'react-icons/md';
import { HiPencil } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';
import classNames from 'classnames';

import Loading from 'components/Loading';
import PopupAreYouSure from 'components/PopupAreYouSure';
import ButtonComponent from 'components/Buttons';
import AlertComponent from 'components/AlertAfterSubmit/index.js';

import { apiDeleteCost } from 'api/managerCost';

import CreatOrUpdateCost from './CreatOrUpdateCost';
import styles from '../MasterData/Candidate/styles.module.scss';

import { Div } from './styled';
import { useListLevel } from 'hook/useMasterData';
import { useGetListCostMasterData } from 'hook/useManageCost';
import { STATUS_CANDIDATE, DEFAULT_TYPE_CANDIDATE, DEFAULT_MONTH_BONUS } from 'constants/MasterData';
import { getLevelName } from 'helpers/masterData';
import { MANAGE_LIST_COST_LEADER } from 'constants/index.js';
import { LOADING_SM } from 'constants/index';

import { showAlert } from 'helpers/modal';
import EditLayout from 'components/PermissionPage/EditLayout';
import { MASTER_COST_PERMISSION } from 'constants/permission';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';

const DEFAULT_FORM_DATA = {
  id: null,
  level_id: null,
  rate: null,
  milestone: DEFAULT_MONTH_BONUS,
  type: DEFAULT_TYPE_CANDIDATE,
};

const ListCostRecruitHrm = () => {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [idBonus, setIdBonus] = useState();
  const [idLevel, setIdLevel] = useState();
  const [isShowAlertCreate, setShowAlertCreate] = useState(false);
  const [isShowAlertUpdate, setShowAlertUpdate] = useState(false);
  const [isShowAlertDelete, setShowAlertDelete] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [formAdd, setFormAdd] = useState(DEFAULT_FORM_DATA);
  const mapLevel = useRef({});
  const queryClient = useQueryClient();
  const [textModal, setTextModal] = useState({
    title: t('masterData.Candidate.payBonus'),
    btn_submit: t('common.add'),
  });
  const { data: dataListCostHrm, isLoading: isLoadingDataCost } = useGetListCostMasterData();
  const { data: uDataLevel } = useListLevel({ key_word: '' });

  useEffect(() => {
    if (uDataLevel) {
      uDataLevel.forEach((item) => {
        mapLevel.current[item.id] = item.name;
      });
    }
  }, [uDataLevel]);

  const getStatusName = (type) => {
    let statusCandidate = STATUS_CANDIDATE.find((item) => item.id === type);
    return statusCandidate ? statusCandidate.name : t('infoEmployee.notUpdate');
  };

  const openAddModal = (item) => {
    setFormAdd({ ...DEFAULT_FORM_DATA, level_id: item.id });
    setShowModal(true);
    setTextModal({
      title: t('manageCostRecruitHrm.addCost') + item.name,
      btn_submit: t('common.add'),
    });
  };

  const openEditModal = (item) => {
    setShowModal(true);
    setFormAdd({
      ...formAdd,
      id: item.id,
      level_id: item.level_id,
      milestone: item.milestone,
      rate: item.rate,
      type: item.type,
    });
    setTextModal({
      title: t('manageCostRecruitHrm.editCost') + getLevelName(item.level_id, uDataLevel),
      btn_submit: t('common.save'),
    });
  };

  const openPopupConfirmDelete = (item) => {
    setConfirmDelete(true);
    setIdBonus(item.id);
    setIdLevel(item.level_id);
  };

  const { mutate: deleteCostLevel, isLoading: isLoadingDelete } = useMutation(() => apiDeleteCost({ id: idBonus }), {
    onSuccess: (response) => {
      if (response.status === 200) {
        queryClient.invalidateQueries([MANAGE_LIST_COST_LEADER.listCostMasterData], { refetchActive: true });
        setConfirmDelete(false);
        setShowAlertDelete(true);
        setMessage(
          t('manageCostRecruitHrm.deleteCost') +
            getLevelName(idLevel, uDataLevel) +
            t('manageCostRecruitHrm.Successfully'),
        );
      } else {
        if (response?.data?.message) {
          showAlert(response.data.message);
        } else {
          showAlert(t('messages.systemError'));
        }
      }
    },
    onError: (error) => {
      showAlert(t('messages.systemError'));
    },
  });

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => {
    if (isLoadingDelete)
      return (
        <div className={styles.loadingIcon}>
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <ButtonComponent
          addClass={classNames(styles.btnConfirm, styles.btnConfirm__cancel)}
          text={t('common.cancel')}
          handleClick={onClickCancel}
        />
        <ButtonComponent
          addClass={classNames(styles.btnConfirm, styles.btnConfirm__ok)}
          text={t('common.ok')}
          handleClick={onClickOk}
        />
      </>
    );
  };
  return (
    <Div>
      <div className="page-title">
        <span>{t('manageCostRecruitHrm.manageCostRecruitHrm')}</span>
      </div>
      <div className="page-content pb-30">
        {uDataLevel?.map((item) => {
          const cost = dataListCostHrm?.find((costLevel) => costLevel.level_id === item.id);
          if (!cost)
            return (
              <div className="box-item-create d-flex justify-content-between align-items-center">
                <span className="level fw-500">{item.name}</span>
                <EditLayout permissionName={MASTER_COST_PERMISSION}>
                  <div
                    className="open-modal-create-cost d-flex align-items-center justify-content-center cursor"
                    onClick={() => openAddModal(item)}
                  >
                    <AddIcon />
                  </div>
                </EditLayout>
              </div>
            );
          else
            return (
              <div className="box-item-info">
                <div className="box-header d-flex justify-content-between align-items-center">
                  <span className="level fw-500">{item.name}</span>
                  <div className="btn-actions d-flex align-items-center justify-content-center">
                    <EditLayout permissionName={MASTER_COST_PERMISSION}>
                      <div
                        className="edit d-flex align-items-center justify-content-center cursor"
                        onClick={() => openEditModal(cost)}
                      >
                        <HiPencil />
                      </div>
                    </EditLayout>
                    <DeleteLayout permissionName={MASTER_COST_PERMISSION}>
                      <div
                        className="delete d-flex align-items-center justify-content-center cursor"
                        onClick={() => openPopupConfirmDelete(cost)}
                      >
                        <MdDelete />
                      </div>
                    </DeleteLayout>
                  </div>
                </div>
                <div className="box-content d-flex align-items-center">
                  <div className="cost">
                    <span className="d-block fw-700">{t('manageCostRecruitHrm._cost')}</span>
                    <span className="d-block">
                      {cost?.rate}% - {t('manageCostRecruitHrm.officialSalary')}
                    </span>
                  </div>
                  <div className="time">
                    <span className="d-block fw-700">{t('common.time')}</span>
                    <span className="d-block">
                      {cost.milestone}
                      &nbsp; {t('masterData.Candidate.timeAfterOnboard')}
                      {cost.type === DEFAULT_TYPE_CANDIDATE ? t('masterData.Candidate.become') : ''}
                      {getStatusName(cost.type)}
                    </span>
                  </div>
                </div>
              </div>
            );
        })}
        {isLoadingDataCost && <Loading addClass="mt-10 loading" />}
        {isShowModal && (
          <CreatOrUpdateCost
            setShowModal={setShowModal}
            isShowModal={isShowModal}
            textModal={textModal}
            formAdd={formAdd}
            dataBonus={dataListCostHrm}
            queryClient={queryClient}
            setMessage={setMessage}
            setShowAlertCreate={setShowAlertCreate}
            setShowAlertUpdate={setShowAlertUpdate}
            uDataLevel={uDataLevel}
          />
        )}
        <AlertComponent toggle={() => setShowAlertUpdate(false)} isShowAlert={isShowAlertUpdate}>
          <p>{message}</p>
        </AlertComponent>
        <AlertComponent toggle={() => setShowAlertCreate(false)} isShowAlert={isShowAlertCreate}>
          {message}
        </AlertComponent>
        <AlertComponent toggle={() => setShowAlertDelete(false)} isShowAlert={isShowAlertDelete}>
          {message}
        </AlertComponent>
        <PopupAreYouSure
          active={confirmDelete}
          handleCancel={() => setConfirmDelete(false)}
          messages={t('manageCostRecruitHrm.confirmDelete') + getLevelName(idLevel, uDataLevel) + ' ?'}
          footer={
            <FooterPopupConfirm onClickCancel={() => setConfirmDelete(false)} onClickOk={() => deleteCostLevel()} />
          }
        />
      </div>
    </Div>
  );
};

export default ListCostRecruitHrm;
