import { Grid, MenuItem } from '@mui/material';
import SelectComponent from 'components/Select/Select.js';
import ModalComponent from 'components/Modal';
import { DEFAULT_LIMIT_10, DEFAULT_PAGE_MUI, managerDecentralization } from 'constants/index.js';
import { useGetListManagerDecentralization } from 'hook/useManagerDecentralization';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Buttons';
import { sendPost } from 'helpers/axios';
import { useQueryClient } from 'react-query';

const PopupUpdateRole = ({ isShowModal, setShowModal, id }) => {
  const { t } = useTranslation();
  const [role, setRole] = useState(id);
  const loading = useRef(true);
  const roleMap = {};
  const [filter] = useState({
    system_id: 2,
    page: DEFAULT_PAGE_MUI,
    limit: DEFAULT_LIMIT_10,
  });
  const queryClient = useQueryClient();
  const { data: listRole } = useGetListManagerDecentralization({
    ...filter,
    page: filter.page + 1,
  });

  const updateValue = (e) => {
    setRole(e.target.value);
  };

  const handleUpdateRole = async () => {
    if (loading.current) {
      loading.current = false;
      await sendPost(`api/group-role/user`, {
        group_role_id: role,
        system_id: 2,
        list_user: [isShowModal.user.user_id],
      });
      await queryClient.refetchQueries([managerDecentralization.getListGroupRole]);
      setRole(id);
      loading.current = true;
      setShowModal({ ...isShowModal, success: { isShowAlert: true, roleName: roleMap[role] }, active: false });
    }
  };

  return (
    <ModalComponent
      isShowModal={isShowModal.active}
      title={t('managerDecentralization.editPosition')}
      toggle={() => {
        setShowModal({ ...isShowModal, active: !isShowModal.active });
      }}
      classNameAdd="no-padding-modal-body edit-role-modal management-decentralization"
    >
      {listRole && (
        <form className="popup-edit-role">
          <Grid item xs={12} className="d-flex align-items-center name-user">
            <span className="fs-16">{t('managerDecentralization.nameEmployee')} : &nbsp;</span>
            <span className="fs-16">{isShowModal.user.name}</span>
          </Grid>
          <div className="divider" />
          <SelectComponent
            isRequired
            addClass="w-100"
            label={t('managerDecentralization.position')}
            valueDefault={role}
            onHandleChangeSelect={updateValue}
          >
            {listRole.map((item, index) => {
              roleMap[item.id] = item.name;
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </SelectComponent>
          <div className="fnc-btn w-100 d-flex align-items-center btn-fnc-edit-role">
            <Button
              text={t('managerDecentralization.cancel')}
              addClass="btn-bg-yellow2 mr-3 w-50"
              handleClick={() => {
                setShowModal({ ...isShowModal, active: false });
              }}
            />
            <Button
              text={t('managerDecentralization.save')}
              addClass="btn-bg-yellow1 w-50"
              type="button"
              handleClick={handleUpdateRole}
              hasDisabled={+role === +id}
            />
          </div>
        </form>
      )}
    </ModalComponent>
  );
};

export default PopupUpdateRole;
