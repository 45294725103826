/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'components/Breadcrumb';

import './styles.scss';
import TabListManager from './TabListManager';
import TabManagerRole from './TabManagerRole';
import { useHistory, useParams } from 'react-router-dom';

const ManagerDecentralization = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { push } = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [tabActive, setTabActive] = useState(params.get('tab') || 'managerRole');

  const breadcrumb = [
    {
      link: '/admin/master-data/manager-decentralization',
      name: t('managerDecentralization.backManagementDecentralization'),
    },
  ];

  const handleTab = (e, newValue) => {
    push(`/admin/master-data/manager-decentralization/list-position/${id}?tab=${newValue}`);
    setTabActive(newValue);
  };

  return (
    <Grid container className="content management-decentralization">
      <Breadcrumb
        data={breadcrumb}
        onChangeUrl
        onClick={() => {
          push(breadcrumb[0].link);
        }}
      />
      <Grid container className="pt-0 tabs">
        <Tabs value={tabActive} onChange={handleTab}>
          <Tab label={t('managerDecentralization.roleManager')} value="managerRole" />
          <Tab
            label={t('managerDecentralization.listManager')}
            value="managerList"
            className={tabActive === 'managerRole' ? 'pl-0' : ''}
          />
        </Tabs>
      </Grid>

      {tabActive === 'managerList' && <TabListManager />}

      {tabActive === 'managerRole' && <TabManagerRole />}
    </Grid>
  );
};

export default ManagerDecentralization;
