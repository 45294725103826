import React from 'react';
import classNames from 'classnames';

import { Grid } from '@mui/material';

import styles from './styles.module.scss';

const ListTotal = (props) => {
  const { list, spacing, isJob } = props;
  return (
    <Grid container spacing={spacing} className={styles.container}>
      {list?.map((item, index) => (
        <Grid
          item
          xs={isJob ? 4 : 12}
          key={index}
          className={classNames(styles.rowInList, { [styles.rowJob]: isJob, [styles.borderJob]: isJob && index !== 0 })}
        >
          <span className={styles.name}>{item.name}</span>
          <span className={styles.value}>{item.value ? item.value : 0}</span>
        </Grid>
      ))}
    </Grid>
  );
};

export default ListTotal;
