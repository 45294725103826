import Dashboard from 'views/pages/Dashboard';
import ManagerJD from 'views/pages/ManagerJD';
import ManagerQuestionInterview from 'views/pages/ManagerQuestionInterview';
import ManagerOrder from 'views/pages/ManagerOrder';
import InterviewSchedule from 'views/pages/InterviewSchedule';
import ManagerKpi from 'views/pages/ManagerKpi';
import ReferralBonus from 'views/pages/ReferralBonus';

import Candidate from 'views/pages/MasterData/Candidate';
import StandardIndex from 'views/pages/MasterData/StandardIndex';
import ManagerCandidate from 'views/pages/ManagerCandidate';
import DetailCandidate from 'views/pages/ManagerCandidate/DetailCandidate';
import DetailApply from 'views/pages/ManagerCandidate/DetailApply';

import ManagerJob from 'views/pages/ManagerJob/index';

import iconMenuDashboard from 'assets/img/icons/icon-menu-dashboard.svg';
import iconMenuBonus from 'assets/img/icons/icon-menu-bonus.svg';
import iconMenuCalendar from 'assets/img/icons/icon-menu-calendar.svg';
import iconMenuCandidate from 'assets/img/icons/icon-menu-candidate.svg';
import iconMenuJd from 'assets/img/icons/icon-menu-jd.svg';
import iconMenuJob from 'assets/img/icons/icon-menu-job.svg';
import iconMenuManagerIndex from 'assets/img/icons/icon-menu-manager-index.svg';
import iconMenuMasterData from 'assets/img/icons/icon-menu-master-data.svg';
import iconMenuOrder from 'assets/img/icons/icon-menu-order.svg';
import iconMenuQuestion from 'assets/img/icons/icon-menu-question.svg';
import iconMenuTest from 'assets/img/icons/icon-menu-test.svg';

import iconMenuDashboardActive from 'assets/img/icons/icon-menu-dashboard-active.svg';
import iconMenuBonusActive from 'assets/img/icons/icon-menu-bonus-active.svg';
import iconMenuCalendarActive from 'assets/img/icons/icon-menu-calendar-active.svg';
import iconMenuCandidateActive from 'assets/img/icons/icon-menu-candidate-active.svg';
import iconMenuJdActive from 'assets/img/icons/icon-menu-jd-active.svg';
import iconMenuJobActive from 'assets/img/icons/icon-menu-job-active.svg';
import iconMenuManagerIndexActive from 'assets/img/icons/icon-menu-manager-index-active.svg';
import iconMenuMasterDataActive from 'assets/img/icons/icon-menu-master-data-active.svg';
import iconMenuOrderActive from 'assets/img/icons/icon-menu-order-active.svg';
import iconMenuQuestionActive from 'assets/img/icons/icon-menu-question-active.svg';
import iconMenuTestActive from 'assets/img/icons/icon-menu-test-active.svg';
import managerDecentralization from 'views/pages/MasterData/managerDecentralization';
import ManagerTest from 'views/pages/ManagerTest/index';
import ManagerGroupTest from 'views/pages/ManagerGroupTest/index';
import ManagerSchool from 'views/pages/MasterData/ManagerSchool';
import ManagerSourceCV from 'views/pages/MasterData/ManagerSourceCV';

import ListCostRecruitLeader from 'views/pages/ManagerListCostRecruitLeader';
import ListCostRecruitHrm from 'views/pages/ManagerCostRecruitHrm';

import {
  ORDER_HR_PERMISSION,
  JOB_PERMISSION,
  CANDIDATE_PERMISSION,
  JD_PERMISSION,
  TEST_PERMISSION,
  INTERVIEW_QUESTIONS_PERMISSION,
  COMMISSION_PERMISSION,
  STANDARD_PERMISSION,
  DASHBOARD_PERMISSION,
  KPI_PERMISSION,
  LIST_REWARD_REF_PERMISSION,
  LIST_COST_RECRUIT_PERMISSION,
  GROUP_PERMISSION,
  MASTER_COST_PERMISSION,
  SCHOOL_RECRUIT,
  SOURCE_CV_RECRUIT,
} from 'constants/permission';

const routesMenu = [
  {
    path: '/index',
    name: 'menu.dashboard',
    icon: iconMenuDashboard,
    iconActive: iconMenuDashboardActive,
    component: Dashboard,
    layout: '/admin',
    permission: DASHBOARD_PERMISSION,
  },
  {
    path: '/interview-schedule',
    name: 'menu.managerCalendarInterview',
    icon: iconMenuCalendar,
    iconActive: iconMenuCalendarActive,
    component: InterviewSchedule,
    layout: '/admin',
    permission: CANDIDATE_PERMISSION,
  },
  {
    path: '/manager-order',
    name: 'menu.managerOrder',
    icon: iconMenuOrder,
    iconActive: iconMenuOrderActive,
    component: ManagerOrder,
    layout: '/admin',
    links: ['/manager-order/:orderId'],
    permission: ORDER_HR_PERMISSION,
    type: 'leader',
  },
  {
    path: '/manager-job',
    name: 'menu.managerJob',
    icon: iconMenuJob,
    iconActive: iconMenuJobActive,
    component: ManagerJob,
    layout: '/admin',
    links: ['/admin/manager-job/:jobId'],
    permission: JOB_PERMISSION,
  },
  {
    path: '/candidate-management',
    name: 'menu.managerCandidate',
    icon: iconMenuCandidate,
    iconActive: iconMenuCandidateActive,
    component: ManagerCandidate,
    layout: '/admin',
    links: ['/admin/candidate-detail/*', '/admin/apply-detail/*'],
    permission: CANDIDATE_PERMISSION,
  },
  {
    path: '/managerJD',
    name: 'menu.managerJb',
    icon: iconMenuJd,
    iconActive: iconMenuJdActive,
    component: ManagerJD,
    layout: '/admin',
    permission: JD_PERMISSION,
  },
  {
    path: '/manager-test',
    name: 'menu.managerTest',
    icon: iconMenuTest,
    iconActive: iconMenuTestActive,
    component: ManagerTest,
    layout: '/admin',
    permission: TEST_PERMISSION,
    links: ['/admin/manager-test/*'],
  },
  {
    path: '/manager-question-interview',
    name: 'menu.managerQuestionInterview',
    icon: iconMenuQuestion,
    iconActive: iconMenuQuestionActive,
    component: ManagerQuestionInterview,
    layout: '/admin',
    permission: INTERVIEW_QUESTIONS_PERMISSION,
  },
  {
    path: '/manager-kpi',
    name: 'menu.managerKPI',
    icon: iconMenuQuestion,
    iconActive: iconMenuQuestionActive,
    component: ManagerKpi,
    layout: '/admin',
    permission: KPI_PERMISSION,
    links: ['/manager-kpi/:kpiId'],
  },
  {
    path: '/list-referral-bonus',
    name: 'menu.listReferralBonus',
    icon: iconMenuTest,
    iconActive: iconMenuTestActive,
    component: ReferralBonus,
    layout: '/admin',
    permission: LIST_REWARD_REF_PERMISSION,
  },
  {
    path: '/list-cost-recruit',
    name: 'menu.listCostRecruit',
    icon: iconMenuTest,
    iconActive: iconMenuTestActive,
    component: ListCostRecruitLeader,
    layout: '/admin',
    permission: LIST_COST_RECRUIT_PERMISSION,
  },
  {
    name: 'menu.masterData',
    icon: iconMenuMasterData,
    iconActive: iconMenuMasterDataActive,
    component: '',
    layout: '/admin',
    subMenu: [
      {
        path: '/master-data/manager-decentralization',
        name: 'menu.masterDataManagerRole',
        icon: iconMenuCandidate,
        iconActive: iconMenuCandidateActive,
        component: managerDecentralization,
        layout: '/admin',
        permission: GROUP_PERMISSION,
        systemAdmin: 'System Admin',
      },
      {
        path: '/master-data/standard-index',
        name: 'menu.masterDataManagerStand',
        icon: iconMenuManagerIndex,
        iconActive: iconMenuManagerIndexActive,
        component: StandardIndex,
        layout: '/admin',
        permission: STANDARD_PERMISSION,
      },
      {
        path: '/master-data/reward-management',
        name: 'menu.masterDataCandidate',
        icon: iconMenuBonus,
        iconActive: iconMenuBonusActive,
        component: Candidate,
        layout: '/admin',
        permission: COMMISSION_PERMISSION,
      },
      {
        path: '/master-data/cost-management',
        name: 'menu.masterDataCost',
        icon: iconMenuBonus,
        iconActive: iconMenuBonusActive,
        component: ListCostRecruitHrm,
        layout: '/admin',
        permission: MASTER_COST_PERMISSION,
      },
      {
        path: '/master-data/manager-group-test',
        name: 'menu.masterDataGroupTest',
        icon: iconMenuTest,
        iconActive: iconMenuBonusActive,
        component: ManagerGroupTest,
        layout: '/admin',
        permission: TEST_PERMISSION,
        links: ['/master-data/manager-group-test/*'],
      },
      {
        path: '/master-data/manager-school',
        name: 'menu.masterDataSchool',
        icon: iconMenuJob,
        iconActive: iconMenuJobActive,
        component: ManagerSchool,
        layout: '/admin',
        permission: SCHOOL_RECRUIT,
      },
      {
        path: '/master-data/manager-source-cv',
        name: 'menu.masterDataSourceCV',
        icon: iconMenuTest,
        iconActive: iconMenuBonusActive,
        component: ManagerSourceCV,
        layout: '/admin',
        permission: SOURCE_CV_RECRUIT,
      },
    ],
  },
];

const routeNav = [
  {
    path: `/candidate-detail/:candidateId/apply-detail/:applyId`,
    name: 'menu.managerCandidate',
    component: DetailApply,
    layout: '/admin',
    class: 'hidden',
    permission: CANDIDATE_PERMISSION,
  },
  {
    path: `/candidate-detail/:candidateId`,
    name: 'menu.managerCandidate',
    component: DetailCandidate,
    layout: '/admin',
    class: 'hidden',
    permission: CANDIDATE_PERMISSION,
  },
];

const routes = [...routesMenu, ...routeNav];

export default routes;
