import React, { useState, useImperativeHandle, useCallback, useEffect } from 'react';
import { Checkbox, Radio, TextareaAutosize } from '@mui/material';
// import { HiPencil } from 'react-icons/hi';
// import { MdDelete } from 'react-icons/md';

import PreviewImage from 'components/PreviewImage';

import { TYPE_QUESTION_TEST } from 'constants/managerTest';
import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';

const ListPreviewQuestion = ({ listQuestionPreview, t, keyLocal }, ref) => {
  const [formData, setFormData] = useState([]);
  const [previewImage, setShowPreview] = useState('');

  const key = 'datatestingtest';
  useImperativeHandle(ref, () => ({
    formData,
  }));

  useEffect(() => {
    Object.keys(localStorage).forEach((element) => {
      if (element.search(key) >= 0 && element !== keyLocal) {
        localStorage.removeItem(element);
      }
    });
    const data = localStorage.getItem(keyLocal);
    if (data) setFormData(JSON.parse(data));

    return () => {
      localStorage.removeItem(keyLocal);
    };
  }, [keyLocal]);

  useEffect(() => {
    localStorage.setItem(keyLocal, JSON.stringify(formData));
  }, [formData, keyLocal]);

  const getItemKey = (type) => {
    switch (type) {
      case TYPE_QUESTION_TEST.GUIDE:
        return 'answer_text';
      case TYPE_QUESTION_TEST.CHECKBOX:
        return 'list_answer_id';
      case TYPE_QUESTION_TEST.RADIO:
        return 'answer_id';
      default:
        return null;
    }
  };

  const handleChange = (index, type, e) => {
    const valueQuestion = e.target.value;

    setFormData((prev) => {
      const newFormData = structuredClone(prev);
      if (type === 'list_answer_id') {
        if (newFormData[index][type]?.split(',')?.includes(valueQuestion)) {
          newFormData[index][type] = newFormData[index][type].split(valueQuestion);
          newFormData[index][type] = newFormData[index][type].join('').replace(/^(,)+|(,)+$/g, '');
        } else {
          if (newFormData[index][type] === null) newFormData[index][type] = '';
          newFormData[index][type] = newFormData[index][type]?.split(',');
          newFormData[index][type].push(valueQuestion);
          newFormData[index][type] = newFormData[index][type].join(',').replace(/^(,)+|(,)+$/g, '');
        }
      } else if (type === 'answer_id') {
        newFormData[index][type] = +valueQuestion;
      } else {
        newFormData[index][type] = valueQuestion;
      }

      return newFormData;
    });
  };
  const CheckboxComp = useCallback(
    ({ _index, index }) => (
      <Checkbox
        value={_index + 1}
        className="p-0"
        checked={formData[index]?.list_answer_id?.split(',')?.includes((_index + 1).toString())}
        onChange={handleChange.bind(this, index, 'list_answer_id')}
        sx={{
          color: COLOR_CHECKBOX_NOT_ACTIVE,
          '&.Mui-checked': {
            color: COLOR_CHECKBOX_CUSTOM,
          },
        }}
      />
    ),
    [formData],
  );

  return (
    <div className="list-test">
      <ul className="pl-0">
        {listQuestionPreview.map((item, index) => {
          if (!formData[index])
            formData[index] = { question_no: index, [getItemKey(item.type)]: null, type: item.type };
          return (
            <li className="align-items-start item-test mb-2" data-screen={index <= 1 ? 1 : 0} key={index}>
              <div className="content-l">
                <span className="fw-bold pb-8">
                  {t('managerTest.question')} {index + 1}: &nbsp;
                </span>
                <span className="fw-bold">{item.content}</span>
                <div className="d-flex flex-wrap">
                  {(typeof item.img_bonus === 'string' ? JSON.parse(item.img_bonus) : item.img_bonus)?.map(
                    (_item, _index) => (
                      <div className="img-list-testing" key={_index}>
                        <img src={_item} alt="img" key={_index} onClick={(event, url = _item) => setShowPreview(url)} />
                      </div>
                    ),
                  )}
                </div>
                {item.type === TYPE_QUESTION_TEST.CHECKBOX || item.type === TYPE_QUESTION_TEST.RADIO ? (
                  <div className="list-answer">
                    {(typeof item?.list_answer === 'string' ? JSON.parse(item?.list_answer) : item?.list_answer)?.map(
                      (_item, _index) => (
                        <div className="d-flex align-items-start item-question" key={_index}>
                          <div className="checkbox mr-8">
                            {item.type === TYPE_QUESTION_TEST.CHECKBOX ? (
                              <CheckboxComp _index={_index} index={index} />
                            ) : (
                              <Radio
                                value={_index + 1}
                                checked={formData[index]?.answer_id === _index + 1}
                                onChange={handleChange.bind(this, index, 'answer_id')}
                                className="p-0"
                                sx={{
                                  color: COLOR_CHECKBOX_NOT_ACTIVE,
                                  '&.Mui-checked': {
                                    color: COLOR_CHECKBOX_CUSTOM,
                                  },
                                }}
                              />
                            )}
                          </div>
                          <span className="question">{_item}</span>
                        </div>
                      ),
                    )}
                  </div>
                ) : (
                  <TextareaAutosize
                    minRows={5}
                    onChange={handleChange.bind(this, index, 'answer_text')}
                    className="type-guide w-100 item-question pl-10 pt-10 textarea-auto-testing"
                    placeholder={t('managerTest.answerOfYou')}
                    defaultValue={formData[index]?.answer_text}
                  />
                )}
              </div>
              {/* ========= candidate testing alway not enableActionQuestion ========= */}
              {/* {enableActionQuestion && (
                <div className="content-r d-flex align-items-center justify-content-center">
                  <div
                    className={`edit-delete-button color-button`}
                    onClick={handleEditQuestion.bind(this, item, index + 1)}
                  >
                    <HiPencil />
                  </div>
                  <div className="edit-delete-button color-button m-0" onClick={handleDeleteQuestion.bind(this, index + 1)}>
                    <MdDelete />
                  </div>
                </div>
              )} */}
            </li>
          );
        })}
      </ul>
      <PreviewImage toggle={() => setShowPreview('')} isShowAlert={!!previewImage}>
        <img src={previewImage} alt={previewImage} />
      </PreviewImage>
    </div>
  );
};

export default React.forwardRef(ListPreviewQuestion);
