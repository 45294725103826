import React from 'react';

import { Grid, MenuItem } from '@mui/material';

import styles from './styles.module.scss';
import SelectComponent from 'components/Select/Select.js';

const SelectedGroupIds = ({
  t,
  Controller,
  control,
  formData,
  errors,
  uDataPosition,
  setValue,
  mapPosition,
  newSkill,
  uDataLevel,
  mapLevel,
}) => {

  return (
    <Grid container spacing={4} className="mt-20">
      <Grid item xs={12} className="pt-0">
        <span className={styles.infoGeneral}>{t('managerTest.infoGeneral')}</span>
      </Grid>
      <Grid item xs={6} className="mt-20 pt-1">
        <Controller
          control={control}
          name="position_id"
          valueDefault={formData.position_id}
          render={({ field }) => (
            <SelectComponent
              field={field}
              addClass="select"
              label={t('masterData.StandardIndex.position')}
              isRequired={true}
              error={errors?.['position_id']?.message}
              onChange={(e) => {
                setValue('skill_id', -1);
                setValue('position_id', e.target.value);
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: {
                      xs: 350,
                    },
                  },
                },
              }}
            >
              {uDataPosition &&
                uDataPosition?.map((item, index) => {
                  mapPosition.current[item.id] = item.name;
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </SelectComponent>
          )}
        />
      </Grid>
      <Grid item xs={6} className="mt-20 pt-1">
        <Controller
          control={control}
          name="skill_id"
          valueDefault={formData.skill_id}
          render={({ field }) => (
            <SelectComponent
              field={field}
              addClass="select"
              label={t('masterData.StandardIndex.skill')}
              isRequired={true}
              disabled={!newSkill?.length > 0}
              error={errors?.['skill_id']?.message}
            >
              {newSkill &&
                newSkill?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </SelectComponent>
          )}
        />
      </Grid>
      <Grid item xs={6} className="mt-20 pt-1">
        <Controller
          control={control}
          name="level_id"
          valueDefault={formData.level_id}
          render={({ field }) => (
            <SelectComponent
              field={field}
              addClass="select"
              label={t('masterData.StandardIndex.levelRecruit')}
              error={errors?.['level_id']?.message}
              isRequired={true}
            >
              {uDataLevel &&
                uDataLevel?.map((item, index) => {
                  mapLevel.current[item.id] = item.name;
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </SelectComponent>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SelectedGroupIds;
