import { ROLE_VIEW } from 'constants/permission';

export const getNameViewRole = (t, rolePermission) => {
  switch (rolePermission) {
    case ROLE_VIEW.HRM:
      return t('managerDecentralization.role.HRM');
    case ROLE_VIEW.HR:
      return t('managerDecentralization.role.HR');
    case ROLE_VIEW.LEADER:
      return t('managerDecentralization.role.leader');
    case ROLE_VIEW.ASSIGNER_BY_LEADER:
      return t('managerDecentralization.role.assignerByLeader');
    default:
      return 'N/A';
  }
};
