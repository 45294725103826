/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import './styles.scss';
import styles from './styles.module.scss';

import avatarDefault from 'assets/img/icons/default-avatar.svg';
import { LEADER_ASSESS_INTERVIEW_FAIL, LEADER_ASSESS_INTERVIEW_PASS } from 'constants/ManagerCandidate';
import { handleOnError } from 'helpers/index';

const DetailApplyTest = ({ dataListTest, setShowLeaderMarks }) => {
  const statusTestListTest = dataListTest?.status;

  const { t } = useTranslation();

  return (
    <div>
      {statusTestListTest?.length &&
        statusTestListTest?.map((item) => {
          if (item?.status_post_test) setShowLeaderMarks(true);
          else return <></>;
          return (
            <Grid container className="div-leader-evaluate-status-test">
              <div className="div-avatar-leader">
                <img
                  className="avatar-leader-notRate"
                  alt="avatar"
                  src={item?.user_info?.avatar ? item?.user_info.avatar : avatarDefault}
                  onError={handleOnError}
                />
              </div>
              <div>
                <div className="div-name-leader">{item?.user_info.name}</div>
                <div className="div-name-division">
                  {item?.user_info.position_name} - {item.division_name}
                </div>
              </div>
              {item?.status_post_test === LEADER_ASSESS_INTERVIEW_PASS && (
                <div className={styles.divButtonReviewPrompt}>
                  <div className={styles.resultLeaderTestPass}>{t('managerTest.pass')}</div>
                </div>
              )}
              {item?.status_post_test === LEADER_ASSESS_INTERVIEW_FAIL && (
                <div className={styles.divButtonReviewPrompt}>
                  <div className={styles.resultLeaderTestFail}>{t('managerTest.fail')}</div>
                </div>
              )}
            </Grid>
          );
        })}
    </div>
  );
};
export default DetailApplyTest;
