import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import classNames from 'classnames';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { BackgroundImage } from 'react-image-and-background-image-fade';

// import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid, List, ListItem, ListItemButton, ListItemText, Checkbox, MenuItem } from '@mui/material';

import styles from './styles.module.scss';
// import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import CheckedIconCheckbox from 'assets/img/icons/check_circle.svg';
import avatarDefault from 'assets/img/icons/avatar_default.svg';

// import { ThemContext } from 'layouts/Admin';
import { apiAssignUser } from 'api/managerOrder';

import { useListCommunityAddMemberCustom } from 'hook/useManagerDecentralization';
import { MAX_LENGTH_30 } from 'constants/index';
import { USE_DETAIL_LEADER_ORDER } from 'constants/ManagerOrder';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import ButtonComponent from 'components/Buttons';
import AlertComponent from 'components/AlertAfterSubmit';
import { showAlert } from 'helpers/modal';
import { isArrayContainAnotherArray } from 'helpers/index';
import { ModalFooter } from 'reactstrap';
import SelectField from 'components/ReactForm/SelectField';
import { useGetListDivision } from 'hook/useDivision';
import { useListPosition } from 'hook/useMasterData';

const CheckedIcon = () => (
  <span
    style={{ backgroundImage: `url(${CheckedIconCheckbox})`, fontSize: '14px', width: '27px', height: '27px' }}
  ></span>
);
const AssignModal = (props) => {
  const { openModal, setOpenModal, detailOrder } = props;
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  const [checkedValues, setCheckedValues] = useState([]);
  const queryClient = useQueryClient();
  //   const { dataInfoUser } = useContext(ThemContext);
  const [isAssignSuccess, setIsAssignSuccess] = useState(false);

  const [filter, setFilter] = useState({
    key_word: '',
    division_id: [],
    position_id: [],
  });

  const { data: uListDivision } = useGetListDivision();
  const { data: uListPosition } = useListPosition();

  const { data: uListCommunityAddMemberCustom, isLoading: isLoadingListCommunityAddMemberCustom } =
    useListCommunityAddMemberCustom(filter);

  const [listUserEmployee, setListUserEmployee] = useState([]);

  useEffect(() => {
    if (uListCommunityAddMemberCustom) {
      const listId = detailOrder?.user_assigned_infos?.map((item) => item.user_id);
      const listUserNotPicked = uListCommunityAddMemberCustom?.filter((item) => !listId?.includes(item.user_id));
      const listUserPicked = uListCommunityAddMemberCustom.filter((item) => listId?.includes(item.user_id));
      const listUserAssigned = Array.isArray(listUserPicked) ? listUserPicked : [];

      setListUserEmployee([...listUserAssigned, ...listUserNotPicked]);
    }
  }, [detailOrder, uListCommunityAddMemberCustom]);

  const watchDivisionId = useWatch({
    control,
    name: 'division_id',
  });
  // Watch value select position
  const watchPositionId = useWatch({
    control,
    name: 'position_id',
  });

  useEffect(() => {
    if (detailOrder?.user_assigned_infos) {
      setCheckedValues(detailOrder?.user_assigned_infos?.map((item) => item.user_id));
    }
  }, [detailOrder]);

  useEffect(() => {
    let dataFilter = [];
    if (watchPositionId) {
      dataFilter.push(watchPositionId);
    }
    setFilter({ ...filter, position_id: dataFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPositionId]);

  useEffect(() => {
    let dataFilter = [];
    if (watchDivisionId) {
      dataFilter.push(watchDivisionId);
    }
    setFilter({ ...filter, division_id: dataFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDivisionId]);

  const handleSearchEmployee = (value) => {
    setFilter({ ...filter, key_word: value });
  };

  const handleSelectAssignUser = (AssignUserId) => {
    if (checkedValues?.includes(AssignUserId)) {
      setCheckedValues(() => checkedValues.filter((i) => i !== AssignUserId));
    } else {
      setCheckedValues(() => [...checkedValues, AssignUserId]);
    }
  };

  const { mutate: assignUserInterview, isLoading: isLoadingAssign } = useMutation(
    () =>
      apiAssignUser({
        id: detailOrder?.id,
        user_assigned_ids: checkedValues.length !== 0 ? checkedValues : null,
      }),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setIsAssignSuccess(true);

          queryClient.invalidateQueries([USE_DETAIL_LEADER_ORDER], { refetchActive: true });
        }
      },
      onError: () => {
        showAlert(t('common.anUnknownError'));
      },
    },
  );

  const disabledAssign = () => {
    if (checkedValues?.length === 0 && !detailOrder?.user_assigned_infos) {
      return true;
    } else {
      return (
        isLoadingAssign ||
        (checkedValues?.length === detailOrder?.user_assigned_infos?.length &&
          isArrayContainAnotherArray(
            checkedValues,
            detailOrder?.user_assigned_infos.map((item) => item.user_id),
          ))
      );
    }
    
  };

  const checkedAll = () => {
    if (checkedValues?.length === uListCommunityAddMemberCustom?.length) {
      setCheckedValues([]);
    } else {
      setCheckedValues(uListCommunityAddMemberCustom.map((item) => item.user_id));
    }
  };

  let conditionalCheckAllWhenNodata =
    checkedValues?.length === uListCommunityAddMemberCustom?.length && uListCommunityAddMemberCustom?.length > 0;

  return (
    <>
      {openModal && (
        <ModalComponent
          isShowModal={openModal}
          title={t('modalOrder.userAssignByLeader')}
          toggle={() => {
            setOpenModal(!openModal);
          }}
          classNameAdd={styles.modalAssignOrder}
          renderModalFooter={() => (
            <ModalFooter className={styles.modalFooter}>
              <ButtonComponent
                text={t('managerCandidate.assignEn')}
                addClass={classNames(styles.btn, styles.btn__action)}
                isLoading={isLoadingAssign}
                hasDisabled={disabledAssign()}
                handleClick={() => {
                  assignUserInterview();
                }}
              />
            </ModalFooter>
          )}
        >
          <Grid container rowSpacing={2} className="popup-add-member-custom">
            <Grid item xs={12}>
              <SearchComponent
                txtPlaceholder={t('managerDecentralization.nameEmployee')}
                addClass="search-employee"
                defaultValue={''}
                handleSearch={handleSearchEmployee}
                size={'medium'}
                maxLength={MAX_LENGTH_30}
              />
            </Grid>
            <Grid container item xs={12} columnSpacing={2}>
              <Grid item xs={6}>
                <SelectField
                  name="division_id"
                  label={t('managerDecentralization.divisionClass')}
                  errors={errors?.division_id?.message}
                  control={control}
                  register={register}
                  useWatch={useWatch}
                  setValue={setValue}
                  isShowModal={openModal}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: {
                          xs: 350,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">{t('managerJob.None')}</MenuItem>
                  {uListDivision?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={6}>
                <SelectField
                  name="position_id"
                  label={t('managerDecentralization.divisionPosition')}
                  errors={errors?.position_id?.message}
                  control={control}
                  register={register}
                  useWatch={useWatch}
                  setValue={setValue}
                  isShowModal={openModal}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: {
                          xs: 350,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">{t('managerJob.None')}</MenuItem>
                  {uListPosition?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12} className="list-member-custom">
                <p className="text-bold title d-inline fs-16">{t('managerDecentralization.listMember')}</p>
                <span
                  className={`float-right ${
                    conditionalCheckAllWhenNodata ? 'not-select-all-option' : 'select-all-option'
                  }`}
                  onClick={checkedAll}
                >
                  {conditionalCheckAllWhenNodata
                    ? t('managerDecentralization.notSelectAll')
                    : t('managerDecentralization.selectAll')}
                </span>
                <List className="list-member-checkbox mt-1">
                  {isLoadingListCommunityAddMemberCustom && <Loading />}
                  {!isLoadingListCommunityAddMemberCustom &&
                    listUserEmployee?.map((item, index) => (
                      <ListItem
                        className={classNames(
                          'w-100 item-selected disable-checkbox',
                          { 'selected-item': checkedValues?.includes(item?.user_id) },

                          //   dataOld && dataOld.find((it) => it.user_id === item.user_id) && 'selected-item',
                        )}
                        key={index}
                      >
                        <ListItemButton>
                          <ListItemText>
                            <Controller
                              render={({ field: { onChange } }) => (
                                <FormControlLabel
                                  label={
                                    <div className="d-flex align-items-center info-wrapper">
                                      <BackgroundImage
                                        element="section"
                                        src={item?.avatar ? item?.avatar : avatarDefault}
                                        lazyLoad
                                        wrapperClassName="avatar p-0"
                                      />
                                      <div>
                                        <span className="d-block user-name fs-14">
                                          {item?.user_name ? item.user_name : item?.name}
                                        </span>
                                        <span className="bottom-content">
                                          {item?.email + ' - ' + item?.division_name + ' - ' + item?.position_name}
                                        </span>
                                      </div>
                                    </div>
                                  }
                                  control={
                                    <Checkbox
                                      checked={checkedValues?.includes(item?.user_id)}
                                      checkedIcon={<CheckedIcon />}
                                      onChange={() => handleSelectAssignUser(item?.user_id)}
                                    />
                                  }
                                />
                              )}
                              name="names"
                              control={control}
                            />
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  {!isLoadingListCommunityAddMemberCustom && listUserEmployee && listUserEmployee.length < 1 && (
                    <p class="error">{t('common.notData')}</p>
                  )}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </ModalComponent>
      )}
      <AlertComponent
        toggle={() => {
          setOpenModal(false);
          setIsAssignSuccess(!isAssignSuccess);
        }}
        isShowAlert={isAssignSuccess}
      >
        <p>{t('modalOrder.assignUserSuccess')}</p>
      </AlertComponent>
    </>
  );
};

export default AssignModal;
