import classNames from 'classnames';
import React from 'react';
import ReactLoading from 'react-loading';

import { LOADING_MD, LOADING_SM } from './../../constants';

import styles from './style.module.scss';

const Loading = (props) => {
  const { addClass, type } = props;

  const TagLoading = () => {
    if (type === LOADING_MD) {
      return <ReactLoading type="spinningBubbles" color={'#ffdba9'} height={80} width={80} />;
    }
    if (type === LOADING_SM) {
      return <ReactLoading type="spinningBubbles" color={'#ffdba9'} height={30} width={30} />;
    }
    return <ReactLoading type="spinningBubbles" color={'#ffdba9'} height={50} width={50} />;
  };

  return (
    <>
      <div className={classNames(`${styles.iconLoading} ${addClass}`)}>
        <TagLoading />
      </div>
    </>
  );
};

export default Loading;
