import { sendGet, sendGetForExport, sendPost, sendDelete } from 'helpers/axios';

export const apiGetListCostLeader = (params) => sendGet(`/api/masterdata/recruit-cost/leader/list`, params);
export const apiConfirmCost = (params) => sendPost(`/api/masterdata/recruit-cost/hrm/confirm`, params);
export const apiGetListCostHrm = (params) => sendGet(`/api/masterdata/recruit-cost/hrm/list`, params);
export const apiGetListCostMasterData = (params) => sendGet(`/api/masterdata/recruit-cost/get-list`, params);
export const apiExportCostHrm = (params) => sendGetForExport(`/api/masterdata/recruit-cost/hrm/export`, params);
export const apiAddOrUpdateCost = (params) => sendPost(`/api/masterdata/recruit-cost/save`, params);
export const apiDeleteCost = (params) => sendDelete(`/api/masterdata/recruit-cost/delete`, params);

