import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';
import { debounce } from '@material-ui/core';
import { IoIosAdd } from 'react-icons/io';

import CreateOrUpdateModal from './CreateCandidateModal.js';

import './styles.scss';
import ButtonComponent from 'components/Buttons';
import RightCandidateSearch from './components/RightCandidateSearch.js';
import ModalImportEmployee from './ModalImportEmployee.js';

import TopInfoStatus from './components/TopInfoStatus';
import { permissions } from 'components/Permission/Index';
import ListCandidate from './components/ListCandidate';

import { useGetListCandidate } from 'hook/useManagerCandidate';
import { useGetListSchool, useGetListSourceCV, useGetListLanguage } from 'hook/useManagerCandidate';
import { useInforAuth } from 'hook/useAuth';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';

import { setDataAllModalWithStatus } from 'helpers/modal';

import * as Constants from 'constants/index.js';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20, INVALID_DATE } from 'constants/index.js';
import { LIST_YEAR_EXPERIENCE } from 'constants/ManagerCandidate';
import { ZERO } from 'constants/MasterData';
import { TYPE_CANDIDATE_HR } from 'constants/ManagerCandidate.js';
import { CANDIDATE_PERMISSION } from 'constants/permission';
import { isArrayContainAnotherArray } from 'helpers/index.js';
import { customDate } from 'helpers/formatDate.js';
import EditLayout from 'components/PermissionPage/EditLayout';

const DEFAULT_FILTER = {
  keyword: null,
  hr_name: null,
  division_order_id: null,
  level_id: null,
  position_id: null,
  skill_id: null,
  created_date: null,
  school: null,
  source_cv: null,
  page: DEFAULT_PAGE_MUI,
  per_page: DEFAULT_LIMIT_20,
  type: TYPE_CANDIDATE_HR,
  experience: null,
  status: null,
  interview_date: null,
  apply_start_date: null,
  apply_end_date: null,
};

const HrCandidate = () => {
  const { t } = useTranslation();

  const { permission } = useInforAuth();

  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [isShowModalAddCandidate, setShowModalAddCandidate] = useState(false);
  const [isShowModalImport, setShowModalImport] = useState(false);
  const [isModals, setIsModals] = useState({
    division: false,
    position: false,
    skill: false,
    level: false,
    school: false,
    source_cv: false,
    experience: false,
    status: false,
  });

  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();
  const { data: uDataListSchool } = useGetListSchool();
  const { data: uDataListSourceCV } = useGetListSourceCV();
  const { data: uDataListLanguage } = useGetListLanguage();
  const { notAllowPermissionUpdate } = useMemo(() => permissions(permission, 'candidate_permission'), [permission]);
  const [candidateCheckedBox, setCandidateCheckedBox] = useState([]);

  const {
    data: dataCandidates,
    isLoading: isLoadingDataStandardIndex,
    refetch: refetchDataCandidate,
  } = useGetListCandidate({
    ...filter,
    interview_date:
      customDate(filter?.interview_date, 'DD/MM/YYYY') !== INVALID_DATE
        ? customDate(filter.interview_date, 'DD/MM/YYYY')
        : null,
    apply_start_date:
      customDate(filter?.apply_start_date, 'DD/MM/YYYY') !== INVALID_DATE
        ? customDate(filter.apply_start_date, 'DD/MM/YYYY')
        : null,
    apply_end_date:
      customDate(filter?.apply_end_date, 'DD/MM/YYYY') !== INVALID_DATE
        ? customDate(filter.apply_end_date, 'DD/MM/YYYY')
        : null,
    page: filter.page + 1,
  });

  useEffect(() => {
    if (uDataLevel) setDataLevel(setDataAllModalWithStatus(uDataLevel));
  }, [uDataLevel]);

  useEffect(() => {
    if (uDataSkill) setDataSkill(setDataAllModalWithStatus(uDataSkill));
  }, [uDataSkill]);

  useEffect(() => {
    if (uDataPosition) setDataPosition(setDataAllModalWithStatus(uDataPosition));
  }, [uDataPosition]);

  const handleCountCheckedBox = (id) => {
    if (candidateCheckedBox.includes(id)) {
      setCandidateCheckedBox(() => candidateCheckedBox.filter((i) => i !== id));
    } else setCandidateCheckedBox(() => [...candidateCheckedBox, id]);
  };

  const handleCheckedAll = (listId) => {
    if (isArrayContainAnotherArray(candidateCheckedBox, listId)) {
      setCandidateCheckedBox([]);
    } else {
      setCandidateCheckedBox(Array.from(new Set([...candidateCheckedBox, ...listId])));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        keyword: value,
        page: DEFAULT_PAGE_MUI,
        per_page: DEFAULT_LIMIT_20,
      });
    }),
  );

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataSkill?.forEach((item) => {
      item.status = false;
    });
    dataLevel?.forEach((item) => {
      item.status = false;
    });
    setFilter({
      keyword: null,
      level_id: null,
      position_id: null,
      skill_id: null,
      created_date: null,
      hr_name: null,
      division_order_id: null,
      status: null,
      interview_date: null,
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT_20,
      type: TYPE_CANDIDATE_HR,
    });
    setDataPosition([...dataPosition]);
    setDataLevel([...dataLevel]);
    setDataSkill([...dataSkill]);
    setCandidateCheckedBox([]);
  };

  return (
    <div>
      <Grid container className="content-manager-candidate">
        <TopInfoStatus totalCandidate={dataCandidates?.total} type={TYPE_CANDIDATE_HR} />

        <Grid item md={9}>
          <ListCandidate
            type="hr"
            dataCandidates={dataCandidates}
            isLoadingDataStandardIndex={isLoadingDataStandardIndex}
            handleCountCheckedBox={handleCountCheckedBox}
            handleCheckedAll={handleCheckedAll}
            filter={filter}
            dataPosition={dataPosition}
            dataLevel={dataLevel}
            dataSkill={dataSkill}
            dataListSchool={uDataListSchool}
            dataListLanguage={uDataListLanguage}
            dataListSourceCV={uDataListSourceCV}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            candidateCheckedBox={candidateCheckedBox}
            refetchDataCandidate={refetchDataCandidate}
          />
        </Grid>

        <Grid item md={3} className={'filter-hr'}>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <ButtonComponent
              addClass="button-add-menu-right"
              handleClick={() => setShowModalAddCandidate(true)}
              hasDisabled={notAllowPermissionUpdate}
              text={t('managerCandidate.addCandidate')}
            />
          </EditLayout>
          <EditLayout permissionName={CANDIDATE_PERMISSION}>
            <ButtonComponent
              addClass="button-add-menu-right button-import"
              handleClick={() => setShowModalImport(true)}
              hasDisabled={notAllowPermissionUpdate}
              text={t('managerCandidate.import')}
              icon={<IoIosAdd />}
            />
          </EditLayout>

          <RightCandidateSearch
            type="hr"
            dataPosition={dataPosition}
            dataLevel={dataLevel}
            dataSkill={dataSkill}
            isModals={isModals}
            setIsModals={setIsModals}
            filter={filter}
            setFilter={setFilter}
            setDataPosition={setDataPosition}
            setDataLevel={setDataLevel}
            setDataSkill={setDataSkill}
            handleSearch={handleSearch}
            handleReset={handleReset}
            candidateCheckedBox={candidateCheckedBox}
            setCandidateCheckedBox={setCandidateCheckedBox}
            dataListLanguage={uDataListLanguage}
          />
        </Grid>
      </Grid>

      {isShowModalAddCandidate && (
        <CreateOrUpdateModal
          isShowModal={isShowModalAddCandidate}
          setShowModal={setShowModalAddCandidate}
          dataPosition={uDataPosition}
          dataLevel={uDataLevel}
          dataListSchool={uDataListSchool}
          dataExperience={LIST_YEAR_EXPERIENCE}
          dataListSourceCV={uDataListSourceCV}
        />
      )}

      <ModalImportEmployee isShowModal={isShowModalImport} setShowModal={setShowModalImport} />
    </div>
  );
};

export default HrCandidate;
