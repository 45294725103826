import { useQuery } from 'react-query';
import { HTTP_OK } from 'constants/StatusCode';
import { apiGetListCostLeader, apiGetListCostHrm, apiExportCostHrm, apiGetListCostMasterData } from 'api/managerCost';
import { MANAGE_LIST_COST_LEADER } from 'constants/index';

export function useGetListCostLeader(params, options) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [MANAGE_LIST_COST_LEADER.getListCostLeader, params],
    async () => {
      const response = await apiGetListCostLeader(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    options,
  );
  return { data: response, error, isLoading };
}

export function useGetListCostHrm(params, options) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [MANAGE_LIST_COST_LEADER.getListCostHrm, params],
    async () => {
      const response = await apiGetListCostHrm(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    options,
  );
  return { data: response, error, isLoading };
}

export function useExportCostHrm(params, options) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [MANAGE_LIST_COST_LEADER.exportCostHrm, params],
    async () => {
      const response = await apiExportCostHrm(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    options,
  );
  return { data: response, error, isLoading };
}

export function useGetListCostMasterData(params, options) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [MANAGE_LIST_COST_LEADER.listCostMasterData, params],
    async () => {
      const response = await apiGetListCostMasterData(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    options,
  );
  return { ...response, error, isLoading };
}
