import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Radar } from '@ant-design/plots';

const DemoRadar = ({ list_stats }) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (list_stats) {
      const listStats = list_stats.map((item) => {
        let newType = '';
        if (item.type === 'score_stand') newType = t('masterData.StandardIndex.standardPoint');
        if (item.type === 'score_avg') newType = t('masterData.StandardIndex.assessPoint');
        return {
          ...item,
          type: newType,
        };
      });
      setData(listStats);
    }
  }, [list_stats, t]);

  const config = {
    data,
    xField: 'name',
    yField: 'score',
    seriesField: 'type',
    legend: false,
    meta: {
      score: {
        alias: 'score',
        min: 0,
        max: 10,
      },
    },
    xAxis: {
      line: null,
      tickLine: null,
      label: null,
      grid: {
        line: {
          style: {
            lineDash: null,
            stroke: '#CFD8DC',
          },
        },
      },
    },
    yAxis: {
      line: null,
      tickLine: null,
      grid: {
        line: {
          type: 'line',
          style: {
            lineDash: null,
            stroke: '#CFD8DC',
          },
        },
      },
    },
    area: { color: ['rgba(254, 166, 40, 1)', 'rgb(45, 153, 255,0.24)'] },
    point: {
      size: 5,
      shape: 'circle',
      color: (datum) => {
        return datum?.type === t('masterData.StandardIndex.standardPoint') ? '#FEA628' : '#5A6882';
      }
    },
    lineStyle: (x) => {
      return {
        stroke: x?.type === t('masterData.StandardIndex.standardPoint') ? '#FEA628' : '#5A6882',
        lineWidth: 3,
      };
    },
  };

  return <Radar {...config} />;
};
export default DemoRadar;
