import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { CssSelectField } from 'components/InputYupAndMui/constant';
import { FormControl } from '@mui/material';
import classNames from 'classnames';

const SelectField = (props) => {
  const {
    children,
    control,
    error,
    isRequired,
    label,
    name,
    setValue,
    sizeSelect,
    defaultValue,
    useWatch,
    register,
    isShowModal,
    disabled,
    onClick,
    onHandleChangeSelect,
    ..._props
  } = props;

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, setValue, name]);

  const valueWatch = useWatch({
    control,
    name: name,
    defaultValue: defaultValue,
  });

  useEffect(() => {
    if (onHandleChangeSelect) onHandleChangeSelect(valueWatch);
  }, [valueWatch, onHandleChangeSelect]);

  return (
    <>
      <CssSelectField labelspace={valueWatch ? 0 : '-1px'} onClick={onClick}>
        {isShowModal ? (
          <FormControl fullWidth className={classNames(`${disabled && 'Mui-disabled-parent'}`)}>
            <Controller
              name={name}
              {...register(name)}
              control={control}
              render={({ field }) => {
                if (label) {
                  return (
                    <>
                      <InputLabel
                        id={'demo-simple-select-label' + name}
                        size={sizeSelect ? sizeSelect : ''}
                        error={error ? true : false}
                        required={isRequired}
                      >
                        {label}
                      </InputLabel>
                      <Select
                        {...field}
                        value={valueWatch}
                        labelId={'demo-simple-select-label' + name}
                        label={label}
                        error={error ? true : false}
                        disabled={disabled || false}
                        {..._props}
                      >
                        {children}
                      </Select>
                    </>
                  );
                } else {
                  return (
                    <>
                      <InputLabel
                        id={'demo-simple-select-label' + name}
                        size={sizeSelect ? sizeSelect : ''}
                        error={error ? true : false}
                      >
                        .
                      </InputLabel>
                      <Select
                        {...field}
                        value={valueWatch}
                        labelId={'demo-simple-select-label' + name}
                        label="."
                        name={name}
                        error={error ? true : false}
                        disabled={disabled || false}
                        {..._props}
                      >
                        {children}
                      </Select>
                    </>
                  );
                }
              }}
            />
          </FormControl>
        ) : (
          ''
        )}
      </CssSelectField>
      <p className="error-input error">{error}</p>
    </>
  );
};

export default SelectField;
