/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { ButtonBase, Dialog, DialogActions, DialogContent, DialogContentText, Grid, InputBase } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import AddIcon from '@mui/icons-material/Add';

import './styles.scss';
import { displayLabelTablePagination } from 'helpers/table';
import { getMessageErrorDateInFilter, customDate } from 'helpers/formatDate';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20, PER_PAGE_OPTION_20 } from 'constants/index.js';
import { useListSkill, useListPosition, useListLevel } from 'hook/useMasterData';
import ButtonComponent from 'components/Buttons';
import * as Constants from 'constants/index.js';
import { managerTest } from 'constants/index';
import { TEST_PERMISSION } from 'constants/permission';

import EditLayout from 'components/PermissionPage/EditLayout';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import Loading from 'components/Loading';
import iconLoading from 'assets/img/icons/loading.png';
import DateDayComponent from 'components/DateTime/DateDay';
import { useGetListGroupTest } from 'hook/useManagerGroupTest';

const ManagerGroupTest = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(DEFAULT_PAGE_MUI);
  const history = useHistory();
  const query = useQueryClient();
  const [filter, setFilter] = useState({
    level_id: null,
    position_id: null,
    skill_id: null,
    created_at: null,
    page: DEFAULT_PAGE_MUI,
    limit: DEFAULT_LIMIT_20,
  });
  const mapLevel = useRef({});
  const mapSkill = useRef({});
  const mapPosition = useRef({});

  const [isModals, setIsModals] = useState({ position: false, skill: false, level: false });

  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });
  const { data: uDataLevel } = useListLevel({ key_word: '' });

  const {
    data: listManagerGroupTest,
    isLoading,
    total_records,
    total_questions,
  } = useGetListGroupTest({
    ...filter,
    page: filter.page + 1,
    created_at: customDate(filter.created_at, 'YYYY-MM-DD'),
  });
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [deleteQuestionSuccess, setDeleteQuestionSuccess] = useState(false);
  const [dataLevel, setDataLevel] = useState([]);

  // const { permission } = useInforAuth();
  // const { notAllowPermissionUpdate, notAllowPermissionDelete } = useMemo(
  //   () => permissions(permission, 'interview_questions_permission'),
  //   [permission],
  // );
  query.refetchQueries(managerTest.USE_LIST_MANAGER_DETAIL_QUESTION);

  useEffect(() => {
    if (uDataSkill) {
      const skill = uDataSkill.map((item) => {
        mapSkill.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataSkill([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...skill,
      ]);
    }
  }, [uDataSkill, t]);

  useEffect(() => {
    if (uDataLevel) {
      const level = uDataLevel.map((item) => {
        mapLevel.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataLevel([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...level,
      ]);
    }
  }, [uDataLevel]);

  useEffect(() => {
    if (uDataPosition) {
      const position = uDataPosition.map((item) => {
        mapPosition.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataPosition([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...position,
      ]);
    }
  }, [uDataPosition, t]);

  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${0}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  const changeSelectPosition = (item) => {
    setDataPosition(function (listStatus) {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  const changeSelectSkill = (item) => {
    setDataSkill(function (listStatus) {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  function handleCheck(items, index) {
    items.forEach(function (item, i) {
      if (index === i) {
        item.status = !item.status;
      } else {
        item.status = false;
      }
    });

    return [...items];
  }

  const changeSelectLevel = (item) => {
    setDataLevel(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, limit: +event.target.value });
    setPage(DEFAULT_PAGE_MUI);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataSkill?.forEach((item) => {
      item.status = false;
    });
    dataLevel?.forEach((item) => {
      item.status = false;
    });
    setFilter({
      position_id: null,
      skill_id: null,
      level_id: null,
      created_at: null,
      page: DEFAULT_PAGE_MUI,
      limit: DEFAULT_LIMIT_20,
    });
    setDataPosition([...dataPosition]);
    setDataSkill([...dataSkill]);
    setDataLevel([...dataLevel]);
  };

  const handleCreateQuestion = () => {
    history.push(`/admin/master-data/manager-group-test/create`);
  };

  const detailGroupTest = (id) => {
    history.push(`/admin/master-data/manager-group-test/detail/${id}`);
  };

  return (
    <Grid container className="content manager-group-test">
      <Dialog open={deleteQuestionSuccess} onClose={() => setDeleteQuestionSuccess(false)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t(`managerTest.deleteSuccess`)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <div class="w-100 d-flex align-items-center justify-content-end">
            <button className="btn-transparent color-yellow fs-18" onClick={() => setDeleteQuestionSuccess(false)}>
              {t('managerTest.done')}
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Grid container className="pt-0">
        <Grid item xs sm md lg xl className="header-left color-text">
          {t('managerTest.managerGroupTest')}
        </Grid>
      </Grid>
      <Grid item md={10} className="content-component">
        <div className="table-border-show">
          <Paper>
            <TableContainer className="table-list">
              <Table aria-label="sticky table">
                <TableHead className="asset-header">
                  <TableRow className="asset-header-row">
                    <TableCell>{t('managerTest.STT')}</TableCell>
                    <TableCell>{t('managerTest.position')}</TableCell>
                    <TableCell>{t('managerTest.skill')}</TableCell>
                    <TableCell>{t('managerTest.level')}</TableCell>
                    <TableCell>{t('managerTest.createdAt')}</TableCell>
                    <TableCell className="text-center">{t('managerTest.quantityQuestion')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Loading addClass="mt-3 mb-3" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!isLoading &&
                    listManagerGroupTest?.map((item, index) => {
                      return (
                        <TableRow key={index} onClick={detailGroupTest.bind(this, item.id)}>
                          <TableCell className="stt-table cursor">
                            {getSTT(index + filter.page * filter.limit)}
                          </TableCell>
                          <TableCell className="cell-table cursor fw-500i">
                            {mapPosition.current[item.position_id]}
                          </TableCell>
                          <TableCell className="cell-table cursor">{mapSkill.current[item.skill_id]}</TableCell>
                          <TableCell className="cell-table cursor">{mapLevel.current[item.level_id]}</TableCell>
                          <TableCell className="cell-table cursor">
                            {moment(item.created_at).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell className="cell-table cursor text-center">{item.total_questions}</TableCell>
                        </TableRow>
                      );
                    })}

                  {listManagerGroupTest?.length === 0 && (
                    <tr>
                      <td colSpan={9} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {listManagerGroupTest?.length > 0 && (
              <TablePagination
                rowsPerPageOptions={PER_PAGE_OPTION_20}
                component="div"
                count={total_records || 0}
                rowsPerPage={filter?.limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
                labelDisplayedRows={displayLabelTablePagination}
              />
            )}
          </Paper>
        </div>
      </Grid>
      <Grid item md={2} className="filter">
        <EditLayout permissionName={TEST_PERMISSION}>
          <ButtonComponent
            addClass="button-add-menu-right menu-right"
            icon={<AddIcon />}
            // hasDisabled={notAllowPermissionUpdate}
            text={t('managerTest.createQuestion')}
            handleClick={handleCreateQuestion}
          />
        </EditLayout>

        <div className="menu-right info-menu-right-no-image selected mt-3">
          <div className="info-menu-righ-title">{t('managerTest.totalQuestion')}</div>
          <div className="info-menu-righ-total">{total_questions || 0}</div>
        </div>

        <InputBase
          disabled
          type="text"
          name="position_id"
          className="input-select-multi menu-right mt-3"
          onClick={() => {
            setIsModals(() => {
              return { position: true };
            });
          }}
          value={
            dataPosition &&
            (!dataPosition[0]?.status
              ? dataPosition
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('managerTest.all'))
          }
          placeholder={t('managerTest.position')}
        />
        <ModalCommon
          items={dataPosition}
          onSubmit={() => {
            let datas = [];
            dataPosition.forEach((it) => {
              it.status && it.id && datas.push(it.id);
            });
            setPage(Constants.DEFAULT_PAGE_MUI);
            setFilter({
              ...filter,
              position_id: !datas.join() ? null : datas.join(),
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, position: false };
            });
          }}
          onClick={changeSelectPosition}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, position: !prev.position };
            });
            if (filter?.position_id) {
              dataPosition?.forEach((item) => {
                Number(filter?.position_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
              });
              setDataPosition([...dataPosition]);
            } else {
              dataPosition?.forEach((item) => {
                item.status = false;
              });
              setDataPosition([...dataPosition]);
            }
          }}
          modal={isModals.position}
          title={t('managerTest.choosePosition')}
          footer={t('managerTest.selected')}
        />
        <InputBase
          disabled
          type="text"
          name="level_id"
          className="input-select-multi menu-right mt-3"
          onClick={() => {
            setIsModals(() => {
              return { level: true };
            });
          }}
          value={
            dataLevel &&
            (!dataLevel[0]?.status
              ? dataLevel
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('managerTest.all'))
          }
          placeholder={t('managerTest.level')}
        />
        <ModalCommon
          items={dataLevel}
          onSubmit={() => {
            let datas = [];
            dataLevel.forEach((it) => {
              it.status && it.id && datas.push(it.id);
            });
            setPage(Constants.DEFAULT_PAGE_MUI);
            setFilter({
              ...filter,
              level_id: !datas.join() ? null : datas.join(),
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, level: false };
            });
          }}
          onClick={changeSelectLevel}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, level: !prev.level };
            });
            if (filter?.level_id) {
              dataLevel?.forEach((item) => {
                Number(filter?.level_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
              });
              setDataLevel([...dataLevel]);
            } else {
              dataLevel?.forEach((item) => {
                item.status = false;
              });
              setDataLevel([...dataLevel]);
            }
          }}
          modal={isModals.level}
          title={t('managerTest.chooseLevel')}
          footer={t('managerTest.selected')}
        />
        <InputBase
          disabled
          type="text"
          name="skill_id"
          className="input-select-multi menu-right mt-3"
          onClick={() => {
            setIsModals(() => {
              return { skill: true };
            });
          }}
          value={
            dataSkill &&
            (!dataSkill[0]?.status
              ? dataSkill
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('managerTest.all'))
          }
          placeholder={t('managerTest.skill')}
        />
        <ModalCommon
          items={dataSkill}
          onSubmit={() => {
            let datas = [];
            dataSkill.forEach((it) => {
              it.status && it.id && datas.push(it.id);
            });
            setPage(Constants.DEFAULT_PAGE_MUI);
            setFilter({
              ...filter,
              skill_id: !datas.join() ? null : datas.join(),
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, skill: false };
            });
          }}
          onClick={changeSelectSkill}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, skill: !prev.skill };
            });
            if (filter?.skill_id) {
              dataSkill?.forEach((item) => {
                Number(filter?.skill_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
              });
              setDataSkill([...dataSkill]);
            } else {
              dataSkill?.forEach((item) => {
                item.status = false;
              });
              setDataSkill([...dataSkill]);
            }
          }}
          modal={isModals.skill}
          title={t('managerTest.chooseSkill')}
          footer={t('managerTest.selected')}
        />
        <div className="date-search menu-right mt-3">
          <DateDayComponent
            label={t('managerTest.createAt')}
            addClass="input-search-box mt-3"
            valueDefault={filter.created_at}
            onChangeHandle={(dateSearch) => {
              setFilter({ ...filter, created_at: dateSearch });
            }}
            sizeDate="small"
            max={moment()}
            error={getMessageErrorDateInFilter(
              filter.created_at,
              t('messages.pleaseEnterTheCorrectFormatDate'),
              t('messages.pleaseEnteraDateThatIsLessThanTheCurrentDate'),
            )}
          />
        </div>
        <ButtonBase onClick={handleReset} className="reset mt-3 menu-right" size="lg">
          <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
          {t('common.reset')}
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

export default ManagerGroupTest;
