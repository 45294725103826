export const STATUS_PROCESSING = 1;
export const STATUS_COMPLETED = 2;
export const STATUS_UNCOMPLETED = 3;
export const STATUS_PENDING = 4;

export const MIN_KPI_FOR_HR = 1;
export const MAX_KPI_FOR_HR = 100;

export const USE_LIST_KPI = 'USE_LIST_KPI';
export const USE_LIST_HR_NO_KPI = 'USE_LIST_HR_NO_KPI';
export const USE_GET_LIST_HR_HAS_RECRUITED = 'USE_GET_LIST_HR_HAS_RECRUITED';
export const USE_GET_DETAIL_KPI = 'USE_GET_DETAIL_KPI';

export const TAB_OFFICIAL = 'TAB_OFFICIAL';
export const TAB_OTHERS = 'TAB_OTHERS';

export const TYPE_TIME_REPORT = {
  PER_MONTH: 0,
  CUSTOM: 1,
};

export const TYPE_QUANTITY_CANDIDATE = {
  PERSONAL: 0,
  ALL_HR: 1,
};

export const LIST_STATUS_KPI = [
  { name: 'managerKPI.status.processing', id: 1, class: 'status__processing' },
  { name: 'managerKPI.status.completed', id: 2, class: 'status__completed' },
  { name: 'managerKPI.status.uncompleted', id: 3, class: 'status__uncompleted' },
  { name: 'managerKPI.status.pending', id: 4, class: 'status__pending' },
];
