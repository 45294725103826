import styled from 'styled-components';

export const Div = styled.div`
  .text-center {
    text-align: center;
  }
  .fw-700 {
    font-weight: 700;
  }
  .pb-30 {
    padding-bottom: 30px;
  }
  .page-title span {
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: -4px;
    display: block;
  }
  .box-item-create {
    height: 67px;
    background: #ffffff;
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
    border-radius: 5px;
    margin: 10px 0;
    .level {
      color: #333333;
      margin-left: 24px;
      font-size: 20px;
    }
    .open-modal-create-cost {
      border: 2px dashed #e7e7e7;
      color: #fea628;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }
  }
  .box-item-info {
    background: #ffffff;
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
    border-radius: 5px;
    margin: 10px 0;
    .box-header {
      height: 55px;
      border-bottom: 1px solid #e7e7e7;
      padding-left: 14px;
      margin: 0px 10px;
      .level {
        color: #333333;
        font-size: 20px;
      }
      .btn-actions {
        color: #8d8d8d;
        padding-right: 10px;
        .edit {
          margin-right: 10px;
          font-size: 16px;
        }
        .edit,
        .delete {
          width: 25px;
          height: 25px;
          border: 1px solid #e7e7e7;
          border-radius: 50%;
        }
      }
    }
    .box-content {
      padding: 10px 0 20px 25px;
      .cost {
        width: 30%;
      }
      .time,
      .cost {
        color: #333333;
        span:first-child {
          padding-bottom: 15px;
        }
      }
    }
  }
`;
