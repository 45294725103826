import { Route, Switch } from 'react-router-dom';
import ManagerKPI from './ListKPI';

const Index = () => {
  return (
    <>
      <Switch>
        <Route path="/admin/manager-kpi" exact render={() => <ManagerKPI />} />
        <Route path="/admin/manager-kpi/:kpiId" exact render={() => <ManagerKPI />} />
      </Switch>
    </>
  );
};

export default Index;
