import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Table, TableCell, TableContainer, TableRow, TableBody, TableHead } from '@mui/material';

import styles from './styles.module.scss';

const TableCountPosition = ({ data }) => {
  let counter = 0;
  const { t } = useTranslation();

  const [total, setTotal] = useState({
    candidateRecruit: 0,
    CV: 0,
    passCV: 0,
    interviewed: 0,
    passInterview: 0,
    acceptOffer: 0,
    onBoarded: 0,
    residual: 0,
  });

  const getTotal = (data, value) => {
    if (data) value += Number(data);
    return value;
  }

  useEffect(() => {
    if (data) {
      let candidateRecruit = 0;
      let CV = 0;
      let passCV = 0;
      let interviewed = 0;
      let passInterview = 0;
      let acceptOffer = 0;
      let onBoarded = 0;
      let residual = 0;
      data.forEach((item) => {
        candidateRecruit = getTotal(item.candidate_recruit, candidateRecruit);
        CV = getTotal(item.cv_total, CV);
        passCV = getTotal(item.pass_cv, passCV);
        interviewed = getTotal(item.interviewed, interviewed);
        passInterview = getTotal(item.pass_interview, passInterview);
        acceptOffer = getTotal(item.accept_offer, acceptOffer);
        onBoarded = getTotal(item.on_boarded, onBoarded);
        residual = getTotal(item.residual, residual);
      });
      setTotal({ candidateRecruit, CV, passCV, interviewed, passInterview, acceptOffer, onBoarded, residual });
    }
  }, [data]);

  const duplicatePositions = data.reduce((array, item) => {
    array[item.position] = (array[item.position] || 0) + 1;
    return array;
  }, {});

  const positions = [];
  data = data.map(item => {
    if (positions.includes(item.position)) {
      return item;
    } else {
      positions.push(item.position);
      return {
        ...item,
        row_span: duplicatePositions[item.position]
      };
    }
  });

  const listHeader = [
    t('common.STT'),
    t('dashboard.position'),
    t('dashboard.skill'),
    t('dashboard.quantityNeeded'),
    t('dashboard.quantityCvSent'),
    t('dashboard.passCvRate'),
    t('dashboard.interviewedRate'),
    t('dashboard.passInterviewRate'),
    t('dashboard.receivedOfferRate'),
    t('dashboard.recruited'),
    t('dashboard.remain'),
    t('dashboard.completedRate'),
  ];

  const getPercentValue = (number1, number2) => {
    if (!number1 || !number2) return 0;
    return ((number1 * 100) / number2).toFixed(0);
  };

  const getValue = (number) => {
    return number ? parseFloat(number).toFixed(0) : 0;
  };

  return (
    <div className="table-border-show">
      <TableContainer className={styles.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={styles.rowHeader}>
              {listHeader?.map((titleCell, index) => (
                <TableCell className={styles.cellHeader} key={index}>
                  {titleCell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              if (item.row_span) {
                counter += 1;
              }

              return (
                <TableRow
                  key={index}
                  className={classNames(styles.rowBody, {
                    [styles.indexEvenRowBody]: !(index % 2),
                  })}
                >
                  {item.row_span && <TableCell className={classNames(styles.cellBody, styles.colSpan)} rowSpan={item.row_span}>{counter}</TableCell>}
                  {item.row_span && <TableCell className={classNames(styles.cellBody, styles.colSpan)} rowSpan={item.row_span}>{item.position}</TableCell>}
                  <TableCell className={classNames(styles.cellBody, styles.colSpan)}>{item.skill}</TableCell>
                  <TableCell className={styles.cellBody}>{getValue(item?.candidate_recruit)}</TableCell>
                  <TableCell className={styles.cellBody}>{getValue(item?.cv_total)}</TableCell>
                  <TableCell className={styles.cellBody}>{getValue(item?.ratio_pass_cv)}%</TableCell>
                  <TableCell className={styles.cellBody}>{getValue(item?.ratio_interviewed)}%</TableCell>
                  <TableCell className={styles.cellBody}>{getValue(item?.ratio_pass_interview)}%</TableCell>
                  <TableCell className={styles.cellBody}>{getValue(item?.ratio_accept_offer)}%</TableCell>
                  <TableCell className={styles.cellBody}>{getValue(item?.on_boarded)}</TableCell>
                  <TableCell className={styles.cellBody}>{getValue(item?.residual)}</TableCell>
                  <TableCell className={styles.cellBody}>{getValue(item?.ratio_complete)}%</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.totalRow}>
        <div className={styles.totalCellTitle} colSpan={3}>{t('common.total')}</div>
        <div className={styles.totalCell}>{total.candidateRecruit}</div>
        <div className={styles.totalCell}>{total.CV}</div>
        <div className={styles.totalCell}>{getPercentValue(total.passCV, total.CV)}%</div>
        <div className={styles.totalCell}>{getPercentValue(total.interviewed, total.passCV)}%</div>
        <div className={styles.totalCell}>{getPercentValue(total.passInterview, total.interviewed)}%</div>
        <div className={styles.totalCell}>{getPercentValue(total.acceptOffer, total.passInterview)}%</div>
        <div className={styles.totalCell}>{total.onBoarded}</div>
        <div className={styles.totalCell}>{total.residual}</div>
        <div className={styles.totalCell}>{getPercentValue(total.onBoarded, total.candidateRecruit)}%</div>
      </div>
    </div>
  );
};

export default TableCountPosition;
