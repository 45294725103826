import { sendPostForExport, sendGet } from 'helpers/axios';

export const apiGetStatisticsBySourceCv = (params) => sendGet(`api/dashboard/statistical-source-cv`, params);
export const apiGetStatisticsByOrder = (params) => sendGet(`api/dashboard/statistical-orders-status`, params);
export const apiGetStatisticsByJob = (params) => sendGet(`api/dashboard/statistical-jobs`, params);
export const apiGetStatisticsByLevel = (params) => sendGet(`api/dashboard/statistical-candidate-level`, params);
export const apiGetStatisticsByPosition = (params) => sendGet(`api/dashboard/statistical-positions`, params);
export const apiGetStatisticsByCandidateOffer = (params) => sendGet('api/dashboard/statistical-candidate-pass-interview', params);
export const apiExportStatisticsBySourceCv = (params) =>
  sendPostForExport(`api/dashboard/export-statistical-source-cv`, params);
export const apiExportStatisticsByLevel = (params) =>
  sendPostForExport(`api/dashboard/export-statistical-candidate-level`, params);
export const apiExportStatisticsByPosition = (params) =>
  sendPostForExport(`api/dashboard/export-statistical-positions`, params);
export const apiExportStatisticsByOrder = (params) =>
  sendPostForExport(`api/dashboard/export-statistical-orders`, params);
export const apiExportStatisticsByJob= (params) => sendPostForExport(`api/dashboard/export-statistical-jobs`, params);
export const apiGetHrMetrics = (params) => sendGet(`api/dashboard/statistical-hr`, params);

