import { useQuery } from 'react-query';

import * as Constants from './../constants/kpi';
import { HTTP_OK } from 'constants/StatusCode';
import { apiGetListHRNoKPI, apiGetListKPI, apiGetDetailKPI, apiGetListHrHasRecruited } from '../api/managerKPI';

export function useGetListKPI(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_KPI, params], async () => {
    const response = await apiGetListKPI(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListHRNoKPI(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_HR_NO_KPI, params], async () => {
    const response = await apiGetListHRNoKPI(params);
    if (response.status === HTTP_OK) {
      return response.data.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetDetailKPI(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_DETAIL_KPI, params],
    async () => {
      const response = await apiGetDetailKPI(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: !!params },
  );

  return { ...response, error, isLoading };
}

export function useGetListHrHasRecruit(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_LIST_HR_HAS_RECRUITED, params],
    async () => {
      const response = await apiGetListHrHasRecruited(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
  );
  return { ...response, error, isLoading };
}
