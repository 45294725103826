import { LIST_STATUS as STATUS_FROM_CANDIDATE } from './ManagerCandidate';

export const USE_LIST_ORDER_LEADER = 'USE_LIST_ORDER_LEADER';
export const USE_LIST_ORDER_HR = 'USE_LIST_ORDER_HR';
export const USE_DETAIL_LEADER_ORDER = 'USE_DETAIL_LEADER_ORDER';
export const USE_DETAIL_HR_ORDER = 'USE_DETAIL_HR_ORDER';
export const USE_GET_LIST_JOB_AVAILABLE_FOR_ORDER = 'USE_GET_LIST_JOB_AVAILABLE_FOR_ORDER';

export const SKILL = 'skill';
export const POSITION = 'position';
export const LEVEL = 'level';
export const WAIT_BOD_ACCEPT = 1;
export const BOD_DONT_ACCEPT = 2;
export const PROCESSING = 3;
export const DEADLINE_LATE = 4;
export const CANCEL_ORDER = 5;
export const COMPLETED = 6;
export const MALE = 1;
export const FEMALE = 2;
export const CANCEL_ACTION = 4;
export const COMPLETED_ACTION = 8;

export const ORDER_LEADER_PERMISSION = 'order_leader_permission';
export const ORDER_HR_PERMISSION = 'order_hr_permission';

export const LIST_STATUS = STATUS_FROM_CANDIDATE;

export const LIST_STATUS_ORDER = [
  { name: 'managerOrderHr.processing', id: PROCESSING },
  { name: 'managerOrderHr.completed', id: COMPLETED },
  { name: 'managerOrderHr.deadlineLate', id: DEADLINE_LATE },
  { name: 'managerOrderHr.waitBodAccept', id: WAIT_BOD_ACCEPT },
  { name: 'managerOrderHr.bodDontAccept', id: BOD_DONT_ACCEPT },
  { name: 'managerOrderHr.cancelOrder', id: CANCEL_ORDER },
];

export const LENGTH_NAME_ASSIGNER = 75;
