import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import iconHeader from 'assets/img/icons/dashboard/groupPeople.svg';
import LayoutBlock from '../components/layoutBlock';
import TableCountPosition from '../components/TableCountPosition';
import styles from './styles.module.scss';
import { useGetStatisticsByPosition } from 'hook/useDashboard';
import { useGetListDivision } from 'hook/useDivision';
import { useListPosition } from 'hook/useMasterData';
import { setDataAllModalWithStatus, handleCheck, getValueInputSearchAll } from 'helpers/modal';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import Loading from 'components/Loading';
import SearchComponent from 'components/Search';

const StatisticsCandidateByPosition = ({ filterDate, filter, setFilter }) => {
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState({ position_id: false, division_id: false });
  const [dataDivision, setDataDivision] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [filterDivision, setFilterDivision] = useState([]);
  const [filterPosition, setFilterPosition] = useState([]);

  const { data: uListDivision } = useGetListDivision();
  const { data: uDataPosition } = useListPosition();

  const { data: dataStatisticsPosition, isLoading: isLoadingPositionTable } = useGetStatisticsByPosition({
    ...filterDate,
    ...filter,
  });

  useEffect(() => {
    if (uDataPosition) setFilterPosition(setDataAllModalWithStatus(uDataPosition));
    if (uDataPosition) setDataPosition(setDataAllModalWithStatus(uDataPosition));
  }, [uDataPosition]);

  useEffect(() => {
    if (uListDivision) setFilterDivision(setDataAllModalWithStatus(uListDivision));
    if (uListDivision) setDataDivision(setDataAllModalWithStatus(uListDivision));
  }, [uListDivision]);

  const handleFilter = (query, dataList, setFilterArray) => {
    const filter = dataList.filter((item) => item['name'].toLowerCase().includes(query.toLowerCase().trim()));
    setFilterArray(filter);
  };

  const changeSelect = (item, setFilterArray) => {
    setFilterArray(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const query = (filterArray, setFilterArray, dataList, fieldKey) => {
    let datas = [];
    filterArray.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      [fieldKey]: !datas.join() ? null : datas.join(),
    });
    filterArray?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    dataList?.forEach((item) => {
      Number(datas.join()) === Number(item?.id) ? (item.status = true) : (item.status = false);
    });
    setOpenModal({ ...isOpenModal, [fieldKey]: false });
    setFilterArray(dataList);
  };

  const toggleModal = (filterArray, setFilterArray, dataList, fieldKey) => {
    setOpenModal({ ...isOpenModal, [fieldKey]: false });
    if (filter?.[fieldKey]) {
      filterArray?.forEach((item) => {
        Number(filter?.[fieldKey]) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setFilterArray([...dataList]);
    } else {
      filterArray?.forEach((item) => {
        item.status = false;
      });
      setFilterArray([...dataList]);
    }
  };

  return (
    <LayoutBlock title={t('dashboard.statisticsCvByPosition')} icon={iconHeader}>
      {/* <div className={styles.filterButtonGroup}>
        <div className={styles.filterBtn} onClick={() => setOpenModal({ ...isOpenModal, division_id: true })}>
          {getValueInputSearchAll(filterDivision)?.length === 0
            ? t('dashboard.division')
            : getValueInputSearchAll(filterDivision)}
        </div>
        <div className={styles.filterBtn} onClick={() => setOpenModal({ ...isOpenModal, position_id: true })}>
          {getValueInputSearchAll(filterPosition)?.length === 0
            ? t('dashboard.position')
            : getValueInputSearchAll(filterPosition)}
        </div>
      </div> */}

      {isLoadingPositionTable && <Loading />}
      {!isLoadingPositionTable && <TableCountPosition data={dataStatisticsPosition} />}

      <ModalCommon
        items={filterDivision}
        onSubmit={() => query(filterDivision, setFilterDivision, dataDivision, 'division_id')}
        onClick={(item) => changeSelect(item, setFilterDivision)}
        toggle={() => toggleModal(filterDivision, setFilterDivision, dataDivision, 'division_id')}
        modal={isOpenModal?.division_id}
        title={t('dashboard.chooseDivision')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('dashboard.division')}
            addClass="input-search-box"
            handleSearch={(query) => handleFilter(query, dataDivision, setFilterDivision)}
            size={'small'}
          />
        }
      />
      <ModalCommon
        items={filterPosition}
        onSubmit={() => query(filterPosition, setFilterPosition, dataPosition, 'position_id')}
        onClick={(item) => changeSelect(item, setFilterPosition)}
        toggle={() => toggleModal(filterPosition, setFilterPosition, dataPosition, 'position_id')}
        modal={isOpenModal?.position_id}
        title={t('masterData.StandardIndex.choosePosition')}
        footer={t('common.select')}
        search={
          <SearchComponent
            txtPlaceholder={t('dashboard.position')}
            addClass="input-search-box"
            handleSearch={(query) => handleFilter(query, dataPosition, setFilterPosition)}
            size={'small'}
          />
        }
      />
    </LayoutBlock>
  );
};

export default StatisticsCandidateByPosition;
