import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ThemContext } from 'layouts/Admin';
import LeaderQuestion from './leader';
import HrQuestion from './hr';

const Question = () => {
  const { isLeader, isAssigner } = useContext(ThemContext);
  const { applyId } = useParams();
  if (isLeader || isAssigner) return <LeaderQuestion applyId={applyId} />;
  return <HrQuestion applyId={applyId} />;
};

export default Question;
