const configs = {
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  API_DOMAIN_RECRUIT: process.env.REACT_APP_RECRUIT_API_DOMAIN,
  API_DOMAIN_AMS: process.env.REACT_APP_API_DOMAIN_AMS,
  API_TOKEN_AMS: process.env.REACT_APP_API_TOKEN_AMS,
  API_DOMAIN_BWF: process.env.REACT_APP_API_DOMAIN_BWF,
  API_DOMAIN_LOGIN: process.env.REACT_APP_DOMAIN_LOGIN,
  API_DOMAIN_SSO: process.env.REACT_APP_SSO_API_DOMAIN,
  API_DOMAIN_NOTIFICATIONS: process.env.REACT_APP_API_NOTIFICATIONS,
  SUBDOMAIN_TOKEN: process.env.REACT_APP_CLIENT_SUBDOMAIN_TOKEN,
  DOMAIN_WORK_FOLLOW: process.env.REACT_APP_DOMAIN_AMS_WORKFLOW,
  DOMAIN_APP_DOMAIN_AMS_BO: process.env.REACT_APP_DOMAIN_AMS_BO,
  DOMAIN_AMS_ME: process.env.REACT_APP_DOMAIN_AMS_ME,
  DOMAIN_AMS_RECRUIT: process.env.REACT_APP_DOMAIN_AMS_RECRUIT,
  DOMAIN_AMS_WORKFLOW: process.env.REACT_APP_DOMAIN_AMS_WORKFLOW,
  DOMAIN_AMS_ACCOUNTING: process.env.REACT_APP_DOMAIN_AMS_ACCOUNTING,
  DOMAIN_AMS_WIKI: process.env.REACT_APP_WIKI,
  DOMAIN_AMS_PROJECT: process.env.REACT_APP_DOMAIN_AMS_PROJECT,
  POSITION_ID_HR: process.env.REACT_APP_POSITION_ID_HR,
  POSITION_ID_HRM: process.env.REACT_APP_POSITION_ID_HRM,
  POSITION_ID_HR_ASSISTANT: process.env.REACT_APP_POSITION_ID_HR_ASSISTANT,
  POSITION_ID_LEADER: process.env.REACT_APP_POSITION_ID_LEADER,
  POSITION_ID_VICE_LEADER: process.env.REACT_APP_POSITION_ID_VICE_LEADER,
  ID_ONESIGNAL: process.env.REACT_APP_ONESIGNAL_RECRUIT_ID,
  ID_SAFARI_ID: process.env.REACT_APP_ONESIGNAL_SAFARI_RECRUIT_ID,
  API_DOWNLOAD_TEMPLATE_IMPORT: process.env.REACT_APP_API_DOWNLOAD_TEMPLATE_IMPORT,
  DOMAIN_AMS_DIVISION: process.env.REACT_APP_DOMAIN_AMS_DIVISION,
};

export default configs;
