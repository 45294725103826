import React, { useState, useEffect, useMemo } from 'react';

import { ButtonBase, Grid, InputBase } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';

import './style.scss';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20, INVALID_DATE } from 'constants/index.js';
import { INDEX_DISPLAY_RULE } from 'constants/ManagerJD';
import AlertComponent from 'components/Alert';
import { ZERO } from 'constants/MasterData';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import * as Constants from 'constants/index.js';
import { JD_PERMISSION } from 'constants/permission';

import ModalCommon from 'components/ModalCommon/ModalCommon';
import { useListLevel, useListPosition } from 'hook/useMasterData';
import { useGetListJD } from 'hook/useManagerJD';
import { getPositionName, getLevelName } from 'helpers/masterData';
import CreateOrUpdateModal from './CreateOrUpdateModal';
import { customDate, getMessageErrorDateInFilter } from 'helpers/formatDate';

import { displayLabelTablePagination } from 'helpers/table';

import iconLoading from 'assets/img/icons/loading.png';
import DetailModal from './DetailModal';
import { useInforAuth } from 'hook/useAuth';
import { permissions } from 'components/Permission/Index.js';
import DateDayComponent from 'components/DateTime/DateDay';
import { formatSalary } from 'helpers/format';
import EditLayout from 'components/PermissionPage/EditLayout';

const ListJD = () => {
  const { t } = useTranslation();
  const [isShowModal, setShowModal] = useState(false);
  const [isNewOrUpdateData, setIsNewOrUpdateData] = useState(false);
  const [textModal, setTextModal] = useState({
    title: t('managerJD.createJDIndex'),
    btn_submit: t('common.save'),
  });
  const getSTT = (index) => {
    let stt = index + 1 < INDEX_DISPLAY_RULE ? `${ZERO}${index + 1}` : <>{index + 1}</>;
    return stt;
  };
  const [formAdd, setFormAdd] = useState({
    id: null,
    title: '',
    level_id: ZERO,
    position_id: ZERO,
    type_interview: '',
    experience: ZERO,
    limit: ZERO,
    salary: '',
    address: '',
    status: ZERO,
    content: '',
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT_20,
  });

  const [filter, setFilter] = useState({
    created_at: null,
    position_id: null,
    level_id: null,
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT_20,
  });

  const { data: uListJD, isLoading: isLoadingJD } = useGetListJD({
    ...filter,
    created_at:
      customDate(filter?.created_at, 'YYYY-MM-DD') !== INVALID_DATE
        ? customDate(filter.created_at, 'YYYY-MM-DD')
        : null,
    page: filter.page + 1,
  });
  const { data: uDataLevel } = useListLevel({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });

  const [dataLevel, setDataLevel] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);

  const [isModals, setIsModals] = useState({ position: false, level: false });
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);

  const { permission } = useInforAuth();

  useEffect(() => {
    if (uDataLevel) {
      const level = uDataLevel.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataLevel([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...level,
      ]);
    }
  }, [uDataLevel, t]);

  useEffect(() => {
    if (uDataPosition) {
      const position = uDataPosition.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataPosition([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...position,
      ]);
    }
  }, [uDataPosition, t]);

  const openModalCreate = () => {
    setFormAdd({
      id: null,
      title: '',
      level_id: ZERO,
      position_id: ZERO,
      type_interview: '',
      experience: ZERO,
      limit: ZERO,
      salary: '',
      address: '',
      status: ZERO,
      content: '',
    });
    setShowModal(true);
    setTextModal({
      title: t('managerJD.createJDIndex'),
      btn_submit: t('common.save'),
    });
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const changeSelectPosition = (item) => {
    setDataPosition((listStatus) => {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  const changeSelectLevel = (item) => {
    setDataLevel((listStatus) => {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  function handleCheck(items, index) {
    items.forEach((item, i) => {
      if (index === i) {
        item.status = !item.status;
      } else {
        item.status = false;
      }
    });

    return [...items];
  }

  const [isShowModalDetail, setShowModalDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState();

  const openModalDetail = (item) => {
    setShowModalDetail(true);
    setDataDetail({ ...item });
    setFormAdd({ ...item });
    setTextModal({
      title: t('managerJD.detail'),
    });
  };

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataLevel?.forEach((item) => {
      item.status = false;
    });
    setFilter({
      level_id: null,
      position_id: null,
      created_at: null,
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT_20,
    });
    setDataPosition([...dataPosition]);
    setDataLevel([...dataLevel]);
  };

  const { notAllowPermissionUpdate, notAllowPermissionDelete } = useMemo(
    () => permissions(permission, 'JD_permission'),
    [permission],
  );

  useEffect(() => {
    if (dataDetail?.id && isShowModal === false && isNewOrUpdateData) {
      setDataDetail(uListJD?.list_data.find((item) => item?.id === dataDetail?.id));
      setFormAdd(uListJD?.list_data.find((item) => item?.id === dataDetail?.id));
      setShowModalDetail(true);
      setIsNewOrUpdateData(false);
    }
    // eslint-disable-next-line
  }, [isNewOrUpdateData, isShowModal]);

  return (
    <Grid container gap={2} className="list-JD content">
      <Grid container className="pt-0">
        <Grid item xs sm md lg xl className="header-left color-text">
          {t('managerJD.managerJD')}
        </Grid>
      </Grid>
      <Grid item flex={10} className="content-component">
        <div className="profile-box table-list table-border-show">
          <Paper>
            <TableContainer>
              <Table aria-label="sticky table">
                <TableHead className="asset-header">
                  <TableRow className="asset-header-row headTable">
                    <TableCell>{t('managerJD.STT')}</TableCell>
                    <TableCell>{t('managerJD.title')}</TableCell>
                    <TableCell>{t('managerJD.position')}</TableCell>
                    <TableCell>{t('managerJD.level')}</TableCell>
                    <TableCell>{t('managerJD.salary')}</TableCell>
                    <TableCell>{t('managerJD.createdAt')}</TableCell>
                    <TableCell>{t('managerJD.status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingJD && (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Loading addClass="mt-3 mb-3" />
                      </TableCell>
                    </TableRow>
                  )}
                  {uListJD &&
                    uListJD?.list_data?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell onClick={() => openModalDetail(item)} className="table-content id-standard">
                            {getSTT(index + filter.page * filter.per_page)}
                          </TableCell>
                          <TableCell onClick={() => openModalDetail(item)} className="table-content text-title-jd">
                            {item.title}
                          </TableCell>
                          <TableCell onClick={() => openModalDetail(item)} className="table-content">
                            {item.position_id
                              ? getPositionName(item?.position_id, dataPosition)
                              : t('common.notDataContract')}
                          </TableCell>
                          <TableCell onClick={() => openModalDetail(item)} className="table-content">
                            {item.level_id ? getLevelName(item?.level_id, dataLevel) : t('common.notDataContract')}
                          </TableCell>
                          <TableCell onClick={() => openModalDetail(item)} className="table-content">
                            {formatSalary(item.salary)}
                          </TableCell>
                          <TableCell onClick={() => openModalDetail(item)} className="table-content">
                            {customDate(item.created_at, 'DD/MM/YYYY')}
                          </TableCell>
                          <TableCell onClick={() => openModalDetail(item)} className="table-content">
                            <div>{item?.status ? t('managerJD.active') : t('managerJD.inactive')}</div>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {uListJD?.total_records === ZERO && (
                    <tr>
                      <td colSpan={7} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {uListJD?.total_records > ZERO && (
              <TablePagination
                rowsPerPageOptions={Constants.PER_PAGE_OPTION_20}
                component="div"
                count={uListJD?.total_records}
                rowsPerPage={filter?.per_page}
                page={filter?.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
                labelDisplayedRows={displayLabelTablePagination}
              />
            )}
          </Paper>
        </div>
      </Grid>
      <Grid flex={2} className="filter">
        <EditLayout permissionName={JD_PERMISSION}>
          <ButtonComponent
            addClass="button-add-menu-right"
            icon={<AddIcon />}
            text={t('managerJD.buttonCreateJD')}
            hasDisabled={notAllowPermissionUpdate}
            handleClick={openModalCreate}
          />
        </EditLayout>

        <div className="info-menu-right-no-image selected mt-3">
          <div className="info-menu-righ-title">{t('managerJD.totalJD')}</div>
          <div className="info-menu-righ-total">{uListJD?.total_records}</div>
        </div>

        <InputBase
          disabled
          type="text"
          name="position_id"
          className="input-select-multi mt-3"
          onClick={() => {
            setIsModals(() => {
              return { position: true };
            });
          }}
          value={
            dataPosition &&
            (!dataPosition[ZERO]?.status
              ? dataPosition
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('common.all'))
          }
          placeholder={t('managerJD.position')}
        />
        <ModalCommon
          items={dataPosition}
          onSubmit={() => {
            let data = [];

            dataPosition.forEach((it) => {
              it.status && it.id && data.push(it.id);
            });

            setFilter({
              ...filter,
              position_id: data.join() ? data.join() : null,
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, position: false };
            });
          }}
          onClick={changeSelectPosition}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, position: !prev.position };
            });
            dataPosition?.forEach((item) => {
              Number(filter?.position_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
            });
            setDataPosition([...dataPosition]);
          }}
          modal={isModals.position}
          title={t('managerJD.choosePosition')}
          footer={t('managerJD.choose')}
        />
        <InputBase
          disabled
          type="text"
          name="level_id"
          className="input-select-multi mt-3"
          onClick={() => {
            setIsModals(() => {
              return { level: true };
            });
          }}
          value={
            dataLevel &&
            (!dataLevel[ZERO]?.status
              ? dataLevel
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('common.all'))
          }
          placeholder={t('masterData.StandardIndex.level')}
        />
        <ModalCommon
          items={dataLevel}
          onSubmit={() => {
            let data = [];
            dataLevel.forEach((it) => {
              it.status && it.id && data.push(it.id);
            });
            setFilter({
              ...filter,
              level_id: data.join() ? data.join() : null,
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, level: false };
            });
          }}
          onClick={changeSelectLevel}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, level: !prev.level };
            });
            dataLevel?.forEach((item) => {
              Number(filter?.level_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
            });
            setDataLevel([...dataLevel]);
          }}
          modal={isModals.level}
          title={t('managerJD.chooseLevel')}
          footer={t('managerJD.choose')}
        />
        <DateDayComponent
          label={t('managerJD.createdAt')}
          addClass="date-search-box mt-3"
          valueDefault={filter.created_at}
          onChangeHandle={(dateSearch) => {
            setFilter({
              ...filter,
              created_at: dateSearch,
              page: Constants.DEFAULT_PAGE_MUI,
            });
          }}
          max={moment()}
          sizeDate="small"
          error={getMessageErrorDateInFilter(
            filter.created_at,
            t('managerOrderHr.pleaseEnterTheCorrectFormat'),
            t('messages.pleaseEnteraDateThatIsLessThanTheCurrentDate'),
          )}
        />
        <ButtonBase onClick={() => handleReset()} className="reset mt-3" size="lg">
          <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
          {t('common.reset')}
        </ButtonBase>
      </Grid>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        <p className="">{message}</p>
      </AlertComponent>
      {isShowModal && (
        <CreateOrUpdateModal
          isShowModal={isShowModal}
          setShowModal={setShowModal}
          textModal={textModal}
          dataPosition={uDataPosition}
          dataLevel={uDataLevel}
          formAdd={formAdd}
          setIsNewOrUpdateData={setIsNewOrUpdateData}
          setShowAlert={setShowAlert}
          setMessage={setMessage}
        />
      )}
      <DetailModal
        isShowModal={isShowModalDetail}
        setShowModal={setShowModalDetail}
        textModal={textModal}
        dataDetail={dataDetail}
        dataPosition={dataPosition}
        dataLevel={dataLevel}
        uDataPosition={uDataPosition}
        uDataLevel={uDataLevel}
        setShowModalCreateAndEdit={setShowModal}
        notAllowPermissionUpdate={notAllowPermissionUpdate}
        notAllowPermissionDelete={notAllowPermissionDelete}
      />
    </Grid>
  );
};

export default ListJD;
