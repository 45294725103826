import React from 'react';
import { useTranslation } from 'react-i18next';
import iconHeader from 'assets/img/icons/dashboard/groupPeople.svg';
import LayoutBlock from '../components/layoutBlock';
import styles from './styles.module.scss';
import Loading from 'components/Loading';
import RecruitMetrics from '../components/RecruitMetrics';
import { useGetHrMetrics } from 'hook/useDashboard';

const StatisticsByRecruiter = ({ filter }) => {
  const { t } = useTranslation();

  const { data: hrMetrics, isLoading } = useGetHrMetrics({
    ...filter,
  });

  return (
    <LayoutBlock title={t('dashboard.statisticsByRecruiter')} icon={iconHeader} classNameAdd={styles.blockSourceCv}>
      {isLoading && <Loading />}
      {!isLoading && <RecruitMetrics data={hrMetrics} filter={filter}></RecruitMetrics>}
    </LayoutBlock>
  );
};

export default StatisticsByRecruiter;
