import { useQuery } from 'react-query';
import {
  apiGetStatisticsBySourceCv,
  apiGetStatisticsByOrder,
  apiGetStatisticsByJob,
  apiGetStatisticsByLevel,
  apiGetStatisticsByPosition,
} from 'api/dashboard';

import * as Constants from 'constants/dashboard';
import { HTTP_OK } from 'constants/StatusCode';
import { apiGetStatisticsByCandidateOffer } from 'api/dashboard';
import { apiGetHrMetrics } from 'api/dashboard';

export function useGetStatisticsBySourceCv(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_STATISTICS_BY_SOURCE_CV, params], async () => {
    const response = await apiGetStatisticsBySourceCv(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  const result = { ...response, error, isLoading, data: response?.data };
  return result;
}

export function useGetStatisticsByOrder(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_STATISTICS_BY_ORDER, params], async () => {
    const response = await apiGetStatisticsByOrder(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  const result = { ...response, error, isLoading, data: response?.data };
  return result;
}

export function useGetStatisticsByJob(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_STATISTICS_BY_JOB, params], async () => {
    const response = await apiGetStatisticsByJob(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  const result = { ...response, error, isLoading };
  return result;
}

export function useGetStatisticsByLevel(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_STATISTICS_BY_LEVEL, params], async () => {
    const response = await apiGetStatisticsByLevel(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  const result = { ...response, error, isLoading, data: response?.data };
  return result;
}

export function useGetStatisticsByPosition(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_STATISTICS_BY_LEVEL, params], async () => {
    const response = await apiGetStatisticsByPosition(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  const result = { ...response, error, isLoading, data: response?.data };
  return result;
}

export function useGetHrMetrics(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_HR_METRICS, params], async () => {
    const response = await apiGetHrMetrics(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  const result = { ...response, error, isLoading, data: response?.data };
  return result;
}

export function useGetStatisticsByCandidateOffer(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_STATISTICS_BY_CANDIDATE_OFFER, params], async () => {
    const response = await apiGetStatisticsByCandidateOffer(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading, data: response?.data };
}
