import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Grid, Tooltip, ClickAwayListener } from '@mui/material';

import styles from './styles.module.scss';
import squareIcon from 'assets/img/icons/candidate/square.svg';
import noteAssessIcon from 'assets/img/icons/candidate/noteAssess.svg';

import { getTextLabelResultAssess } from 'helpers/manageCandidate';
import { customDate } from 'helpers/formatDate';
import { formatSalary } from 'helpers/format';
import { getName } from 'helpers/index';
import { useListLevel, useListPersonnel, useListContractCategory } from 'hook/useMasterData';

import {
  CANDIDATE_NOT_RATED_YET,
  CANDIDATE_RATING_PASS,
  CANDIDATE_RATING_FAIL,
  CANDIDATE_RATING_WAIT_OFFER,
} from 'constants/ManagerCandidate';

import InfoCard from 'components/InfoCard';
import DemoRadar from 'views/pages/ManagerCandidate/RattingApply/chartRada';
import AvgPoint from './AvgPoint';

const AssessTable = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [openNoteAssess, setOpenNoteAssess] = useState([]);

  const { data: uDataLevel } = useListLevel();
  const { data: uDataPersonnel } = useListPersonnel();
  const { data: uDataContractCategory } = useListContractCategory();

  useEffect(() => {
    if (data?.list_stats) {
      setOpenNoteAssess(Array(JSON.parse(data?.list_stats)?.length).fill(false));
    }
  }, [data]);

  const handleShowDetail = () => {
    if (!data?.result) return;
    setShowDetail(true);
  };

  const formatFieldKeyListStats = (list) => {
    let listStatsCustom = [];
    list?.map((item) => {
      const { name, avg_point, standar_point, coefficient } = item;
      const score = { name, type: 'score_avg', score: avg_point, coefficient };
      const scoreStand = { name, type: 'score_stand', score: standar_point, coefficient };
      listStatsCustom.push(score);
      listStatsCustom.push(scoreStand);
      return item;
    });
    return listStatsCustom;
  };

  return (
    <>
      {showDetail && data?.result !== CANDIDATE_RATING_WAIT_OFFER && (
        <Grid container className={styles.assessTableContainer}>
          <Grid
            item
            container
            xs={12}
            className={classNames(styles.offerLeaderContainer, {
              [styles.backgroundPass]: data?.result === CANDIDATE_RATING_PASS,
              [styles.backgroundFail]: data?.result !== CANDIDATE_RATING_PASS,
            })}
          >
            <Grid item xs={12} className={styles.headerInfo} onClick={() => setShowDetail(false)}>
              <InfoCard
                avatar={data?.user_info?.avatar}
                name={data?.user_info?.name}
                position={data?.user_info?.position_name}
                division={data?.division_name}
              />
              <span className={styles.dateAssess}>
                {t('managerCandidate.dateAssess') + ': ' + customDate(data?.date_offer, 'DD/MM/YYYY')}
              </span>
              {data?.result !== CANDIDATE_RATING_WAIT_OFFER && (
                <span
                  className={classNames(styles.labelDecision, {
                    [styles.labelPass]: data?.result === CANDIDATE_RATING_PASS,
                    [styles.labelFail]: data?.result === CANDIDATE_RATING_FAIL,
                  })}
                >
                  {getTextLabelResultAssess(data?.result)}
                </span>
              )}
            </Grid>
            {data?.result === CANDIDATE_RATING_PASS && data?.salary && (
              <Grid item container xs={12} className={styles.offerInfo}>
                <Grid item container xs={6} className={styles.itemContainer}>
                  <Grid item xs={6} className={styles.titleItem}>
                    {t('managerCandidate.officialLevel')}
                  </Grid>
                  <Grid item xs={6} className={styles.contentItem}>
                    {getName(uDataLevel, data?.level_official_id)}
                  </Grid>
                </Grid>
                <Grid item container xs={6} className={styles.itemContainer} pl={2}>
                  <Grid item xs={8} className={styles.titleItem}>
                    {t('managerCandidate.startingSalary')}
                  </Grid>
                  <Grid item xs={4} className={styles.contentItem}>
                    {formatSalary(data?.starting_salary)}
                  </Grid>
                </Grid>
                <Grid item container xs={6} className={styles.itemContainer}>
                  <Grid item xs={6} className={styles.titleItem}>
                    {t('managerCandidate.Personnel')}
                  </Grid>
                  <Grid item xs={6} className={styles.contentItem}>
                    {getName(uDataPersonnel, data?.group_id)}
                  </Grid>
                </Grid>
                <Grid item container xs={6} className={styles.itemContainer} pl={2}>
                  <Grid item xs={8} className={styles.titleItem}>
                    {t('managerCandidate.salaryOfficial')}
                  </Grid>
                  <Grid item xs={4} className={styles.contentItem}>
                    {formatSalary(data?.salary)}
                  </Grid>
                </Grid>
                <Grid item container xs={6} className={styles.itemContainer}>
                  <Grid item xs={6} className={styles.titleItem}>
                    {t('managerCandidate.proposalToApply')}
                  </Grid>
                  <Tooltip
                    title={
                      <span
                        style={{
                          backgroundColor: '#F3F7FB',
                          color: '#223354',
                          paddingTop: '5px',
                          fontSize: 14,
                          borderRadius: '5px',
                          wordBreak: 'break-word',
                        }}
                      >
                        {data?.apply_suggest}
                      </span>
                    }
                    followCursor
                    PopperProps={{
                      sx: {
                        maxWidth: 300,
                        '& 	.MuiTooltip-tooltip': {
                          marginTop: '20px !important',
                          backgroundColor: '#F3F7FB !important',
                        },
                      },
                    }}
                  >
                    <Grid item xs={6} className={classNames(styles.contentItem, styles.overflowText)}>
                      {data?.apply_suggest}
                    </Grid>
                  </Tooltip>
                </Grid>
                <Grid item container xs={6} className={styles.itemContainer} pl={2}>
                  <Grid item xs={8} className={styles.titleItem}>
                    {t('managerCandidate.salaryBonus')}
                  </Grid>
                  <Grid item xs={4} className={styles.contentItem}>
                    {formatSalary(data?.salary_bonus)}
                  </Grid>
                </Grid>
                <Grid item container xs={6} className={styles.itemContainer}>
                  <Grid item xs={6} className={styles.titleItem}>
                    {t('managerCandidate.Contract')}
                  </Grid>
                  <Grid item xs={6} className={styles.contentItem}>
                    {getName(uDataContractCategory, data?.contract_category_id)}
                  </Grid>
                </Grid>
                <Grid item container xs={6} className={styles.itemContainer} pl={2}>
                  <Grid item xs={8} className={styles.titleItem}>
                    {t('managerCandidate.expectUVOnboardDate')}
                  </Grid>
                  <Grid item xs={4} className={styles.contentItem}>
                    {customDate(data?.expect_date, 'DD/MM/YYYY')}
                  </Grid>
                </Grid>
                <Grid item container xs={6} className={styles.itemContainer} pl={2}></Grid>
                <Grid item container xs={6} className={styles.itemContainer} pl={2}>
                  <Grid item xs={8} className={styles.titleItem}>
                    {t('managerCandidate.contractTerm')}
                  </Grid>
                  <Tooltip
                    title={
                      <span
                        style={{
                          paddingTop: '5px',
                          backgroundColor: '#F3F7FB',
                          color: '#223354',
                          fontSize: 14,
                          borderRadius: '5px',
                        }}
                      >
                        {data?.contract_term}
                      </span>
                    }
                    PopperProps={{
                      sx: {
                        maxWidth: 300,
                        '& 	.MuiTooltip-tooltip': {
                          marginTop: '20px !important',
                          backgroundColor: '#F3F7FB !important',
                        },
                      },
                    }}
                    followCursor
                  >
                    <Grid item xs={4} className={classNames(styles.contentItem, styles.overflowText)}>
                      {data?.contract_term}
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </Grid>
          {data.user_assigned_id ? (
            <Grid item container xs={12} className={styles.offerLeaderContainer}>
              <Grid item xs={12} className={styles.headerInfo} onClick={() => setShowDetail(false)}>
                <InfoCard
                  avatar={data?.user_assigned_info?.avatar}
                  name={
                    data?.user_assigned_info?.name +
                    ' -' +
                    t('managerCandidate.authorityName', { nameLeader: data?.user_info?.name })
                  }
                  position={data?.user_assigned_info?.position_name}
                  division={data?.division_name}
                  nameAssigned={data?.user_info?.name}
                />
                <span className={styles.dateAssess}>
                  {t('managerCandidate.dateAssess') + ': ' + customDate(data?.date_assess, 'DD/MM/YYYY')}
                </span>
              </Grid>
            </Grid>
          ) : (
            <Grid item container xs={12} className={styles.offerLeaderContainer}>
              <Grid item xs={12} className={styles.headerAssess} onClick={() => setShowDetail(false)}>
                <span className={styles.dateAssess}>
                  {t('managerCandidate.dateAssess') + ': ' + customDate(data?.date_assess, 'DD/MM/YYYY')}
                </span>
              </Grid>
            </Grid>
          )}
          {!!JSON.parse(data?.list_stats)?.length && (
            <Grid item container xs={12} className={styles.ratingChartContainer}>
              <Grid item xs={6}>
                <DemoRadar list_stats={formatFieldKeyListStats(JSON.parse(data?.list_stats))} />
              </Grid>
              <Grid item container xs={6} className={styles.listStats}>
                <Grid item xs={12}>
                  {JSON.parse(data?.list_stats)?.map((item, index) => {
                    return (
                      <div className={styles.rowStat} key={item.key}>
                        <div>
                          <img alt={squareIcon} src={squareIcon} />
                          <span className={styles.titleStat}>{item.name}</span>
                          {item?.note && (
                            <ClickAwayListener
                              onClickAway={() =>
                                setOpenNoteAssess(
                                  openNoteAssess.map((item, subIndex) => {
                                    if (subIndex === index) return false;
                                    return item;
                                  }),
                                )
                              }
                            >
                              <div className="d-inline">
                                <Tooltip
                                  open={openNoteAssess[index]}
                                  title={
                                    <div className={styles.noteAssessContainer}>
                                      <div className={styles.title}>{item.name}</div>
                                      <div className={styles.content}>{item.note}</div>
                                    </div>
                                  }
                                >
                                  <img
                                    alt={noteAssessIcon}
                                    src={noteAssessIcon}
                                    className={styles.noteAssessBtn}
                                    onClick={() =>
                                      setOpenNoteAssess(
                                        openNoteAssess.map((item, subIndex) => {
                                          if (subIndex === index) return !item;
                                          return item;
                                        }),
                                      )
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                          )}
                        </div>
                        <div>
                          <span className={styles.score}>{item.avg_point + '/'}</span>
                          <span className={styles.scoreStand}>{item.standar_point}</span>
                        </div>
                      </div>
                    );
                  })}
                </Grid>
                <AvgPoint data={data} />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} className={styles.generalAssessment}>
            <span className={styles.title}>{t('managerCandidate.assessLevel')}</span>
            <span className={styles.content}>{getName(uDataLevel, data?.level_assess_id)}</span>
          </Grid>
          {data?.note && (
            <Grid item xs={12} className={styles.generalAssessment}>
              <div className={styles.title}>{t('managerCandidate.generalAssessment')}</div>
              <p className={styles.content}>{data.note}</p>
            </Grid>
          )}
        </Grid>
      )}
      {showDetail && data?.result === CANDIDATE_RATING_WAIT_OFFER && (
        <Grid container className={styles.assessTableContainer}>
          <Grid
            item
            container
            xs={12}
            className={classNames(styles.offerLeaderContainer, {
              [styles.backgroundPass]: data?.result === CANDIDATE_RATING_PASS,
              [styles.backgroundFail]: data?.result !== CANDIDATE_RATING_PASS,
            })}
          >
            <Grid item xs={12} className={styles.headerInfo} onClick={() => setShowDetail(false)}>
              {!data.user_assigned_id ? (
                <InfoCard
                  avatar={data?.user_info?.avatar}
                  name={data?.user_info?.name}
                  position={data?.user_info?.position_name}
                  division={data?.division_name}
                />
              ) : (
                <InfoCard
                  avatar={data?.user_assigned_info?.avatar}
                  name={
                    data?.user_assigned_info?.name +
                    ' -' +
                    t('managerCandidate.authorityName', { nameLeader: data?.user_info?.name })
                  }
                  position={data?.user_assigned_info?.position_name}
                  division={data?.division_name}
                  nameAssigned={data?.user_info?.name}
                />
              )}
              <span className={styles.dateAssess}>
                {t('managerCandidate.dateAssess') + ': ' + customDate(data?.date_assess, 'DD/MM/YYYY')}
              </span>
            </Grid>
          </Grid>
          {!!JSON.parse(data?.list_stats)?.length && (
            <Grid item container xs={12} className={styles.ratingChartContainer}>
              <Grid item xs={6}>
                <DemoRadar list_stats={formatFieldKeyListStats(JSON.parse(data?.list_stats))} />
              </Grid>
              <Grid item container xs={6} className={styles.listStats}>
                <Grid item xs={12}>
                  {JSON.parse(data?.list_stats)?.map((item, index) => {
                    return (
                      <div className={styles.rowStat} key={item.key}>
                        <div>
                          <img alt={squareIcon} src={squareIcon} />
                          <span className={styles.titleStat}>{item.name}</span>
                          {item?.note && (
                            <ClickAwayListener
                              onClickAway={() =>
                                setOpenNoteAssess(
                                  openNoteAssess.map((item, subIndex) => {
                                    if (subIndex === index) return false;
                                    return item;
                                  }),
                                )
                              }
                            >
                              <div className="d-inline">
                                <Tooltip
                                  open={openNoteAssess[index]}
                                  title={
                                    <div className={styles.noteAssessContainer}>
                                      <div className={styles.title}>{item.name}</div>
                                      <div className={styles.content}>{item.note}</div>
                                    </div>
                                  }
                                >
                                  <img
                                    alt={noteAssessIcon}
                                    src={noteAssessIcon}
                                    className={styles.noteAssessBtn}
                                    onClick={() =>
                                      setOpenNoteAssess(
                                        openNoteAssess.map((item, subIndex) => {
                                          if (subIndex === index) return !item;
                                          return item;
                                        }),
                                      )
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                          )}
                        </div>
                        <div>
                          <span className={styles.score}>{item.avg_point + '/'}</span>
                          <span className={styles.scoreStand}>{item.standar_point}</span>
                        </div>
                      </div>
                    );
                  })}
                </Grid>
                <AvgPoint data={data} />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} className={styles.generalAssessment}>
            <span className={styles.title}>{t('managerCandidate.assessLevel')}</span>
            <span className={styles.content}>{getName(uDataLevel, data?.level_assess_id)}</span>
          </Grid>
          {data?.note && (
            <Grid item xs={12} className={styles.generalAssessment}>
              <div className={styles.title}>{t('managerCandidate.generalAssessment')}</div>
              <p className={styles.content}>{data.note}</p>
            </Grid>
          )}
        </Grid>
      )}
      {!showDetail && (
        <div
          className={classNames(styles.rowCollapseContainer, {
            [styles.rowCollapseNotRated]: data?.result === CANDIDATE_NOT_RATED_YET,
          })}
          onClick={() => handleShowDetail()}
        >
          {!data.user_assigned_id ? (
            <InfoCard
              avatar={data?.user_info?.avatar}
              name={data?.user_info?.name}
              position={data?.user_info?.position_name}
              division={data?.division_name}
            />
          ) : (
            <InfoCard
              avatar={data?.user_assigned_info?.avatar}
              name={
                data?.user_assigned_info?.name +
                ' -' +
                t('managerCandidate.authorityName', { nameLeader: data?.user_info?.name })
              }
              position={data?.user_assigned_info?.position_name}
              division={data?.division_name}
              nameAssigned={data?.user_info?.name}
            />
          )}
          {data?.result !== CANDIDATE_RATING_WAIT_OFFER && (
            <span
              className={classNames(styles.labelDecision, {
                [styles.labelPass]: data?.result === CANDIDATE_RATING_PASS,
                [styles.labelFail]: data?.result === CANDIDATE_RATING_FAIL,
                [styles.labelNotRated]: data?.result === CANDIDATE_NOT_RATED_YET,
              })}
            >
              {getTextLabelResultAssess(data?.result)}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default AssessTable;
