import React from 'react';
import classNames from 'classnames';

import styles from './stylesLayout.module.scss';

const layoutBlock = (props) => {
  const { icon, title, children, titleRef, classNameAdd } = props;
  return (
    <div className={classNames(styles.container, { [classNameAdd]: classNameAdd })}>
      <div ref={titleRef}>
        <img src={icon} alt={icon} className={styles.icon} />
        <span className={styles.title}>{title}</span>
      </div>
      {children}
    </div>
  );
};

export default layoutBlock;
