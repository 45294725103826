import React, { useContext } from 'react';
import { ThemContext } from 'layouts/Admin';
import ManagerCandidateLeader from './leader';
import ManagerCandidateHR from './hr';

const ManagerCandidate = () => {
  const { isLeader, isAssigner } = useContext(ThemContext);
  if (isLeader || isAssigner) return <ManagerCandidateLeader />;
  return <ManagerCandidateHR />;
};

export default ManagerCandidate;
