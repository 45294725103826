export const USE_LIST_JD = 'USE_LIST_JD';
export const INDEX_DISPLAY_RULE = 10;
export const ACTIVE = 1;
export const DEACTIVE = 0;
export const CODE_DUPLICATE = 1;
export const TYPE_INTERVIEW = [
  { name: 'FullTime', id: 1 },
  { name: 'Part time', id: 2 },
  { name: 'Intern', id: 3 },
  { name: 'Cộng tác viên', id: 4 },
];