import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import './styles.scss';

import DetailApplyLeader from './DetailApplyLeader/detailApplyLeader';
import DetailApplyLeaderPassFail from './DetailApplyLeaderPass/detailApplyLeaderPassFail';

import { useGetListLeaderAssessCandidate } from 'hook/useDetailApplyLeader';
import { TYPE_CANDIDATE_HR } from 'constants/ManagerCandidate';
import { ThemContext } from 'layouts/Admin';

const HrDetailApply = () => {
  const { applyId } = useParams();
  const { dataInfoUser } = useContext(ThemContext);
  const [dataLeaderAssess, setDataLeaderAssess] = useState();

  const { data: uLeaderAssess } = useGetListLeaderAssessCandidate(applyId, TYPE_CANDIDATE_HR);

  useEffect(() => {
    if (uLeaderAssess && Object.keys(uLeaderAssess).length !== 0) {
      setDataLeaderAssess(Object.values(uLeaderAssess));
    }
  }, [uLeaderAssess]);

  return (
    <div className="mt-3">
      {dataLeaderAssess &&
        dataLeaderAssess.map((itemAssess) => {
          if (!itemAssess?.result) {
            return <DetailApplyLeader dataStatus={itemAssess} type="hr" userId={dataInfoUser.id} applyId={applyId} />;
          } else {
            return (
              <DetailApplyLeaderPassFail
                dataStatusNotRate={itemAssess}
                dataStatusPass={itemAssess}
                dataStatusFail={itemAssess}
              />
            );
          }
        })}
    </div>
  );
};

export default HrDetailApply;
