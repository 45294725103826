export const permissions = (permission, page) => {
  let notAllowPermissionRead = true;
  if (permission?.[page]?.includes(2)) {
    notAllowPermissionRead = false;
  }

  let notAllowPermissionUpdate = true;
  if (permission?.[page]?.includes(3)) {
    notAllowPermissionUpdate = false;
  }

  let notAllowPermissionDelete = true;
  if (permission?.[page]?.includes(4)) {
    notAllowPermissionDelete = false;
  }

  return { notAllowPermissionRead, notAllowPermissionUpdate, notAllowPermissionDelete };
};
