import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import './styles.scss';

import { apiTakeStart } from 'api/testing';

import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';

import { useGetTestingInfo } from 'hook/useManagerGroupTest';
import { useListSkill, useListLevel, useListPosition } from 'hook/useMasterData';

import { getSkillName } from 'helpers/masterData';
import { getDivisionName } from 'helpers/masterData';
import { getPositionName } from 'helpers/masterData';
import { STATUS_TESTING } from 'constants/managerTest';

const InfoTesting = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const [isDisableSubmit, setIsDisableSubmit] = useState(false);

  const { data, isLoading, code } = useGetTestingInfo({ identity_id: id });
  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });
  const { data: uDataLevel } = useListLevel({ key_word: '' });

  useEffect(() => {
    if (data) {
      if (data.status === STATUS_TESTING.FINISHED || data.status === STATUS_TESTING.CANCELED) {
        history.push(`${window.location.pathname.replace(/\/$/, '')}/start/notify`);
      } else if (data.status === STATUS_TESTING.STARTED) {
        history.push(`${window.location.pathname.replace(/\/$/, '')}/start`);
      } else {
      }
    }
  }, [data, history]);

  useEffect(() => {
    if (code && !isLoading) {
      history.push(`${window.location.pathname.replace(/\/$/, '')}/start/notify`);
    }
  }, [code, history, isLoading]);

  const { mutate: submitStartTesting } = useMutation(() => apiTakeStart({ identity_id: id }), {
    onSuccess: (res) => {
      if (res.status === 200) {
        window.open(`${window.location.pathname.replace(/\/$/, '')}/start`, '_self');
      }
    },
    onError: () => {
      setIsDisableSubmit(false);
    },
  });

  const getTimeUntil = (timeMinute) => {
    if (timeMinute > 0) {
      let distance = timeMinute * 60000;

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      return `${hours >= 10 ? hours : '0' + hours}:${minutes >= 10 ? minutes : '0' + minutes}`;
    } else {
      return '00:00';
    }
  };

  const handleSubmitStartTesting = () => {
    submitStartTesting();
    setIsDisableSubmit(true);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="info-testing">
          <div className="box-info-testing text-center d-flex align-item-center justify-content-center h-100vh flex-column">
            <span className="d-block">{t('managerTest.titleForTimeTest')}</span>
            <span className="d-block time">{getTimeUntil(data?.time_for_test)}</span>
            <span className="testing-for-develop">{data?.title}</span>
            <div className="info-candidate">
              <span>{t('managerTest.nameCandidate')}: </span>
              <span>{data?.full_name}</span>
            </div>
            <div className="info-candidate">
              <span>{t('managerTest.position')}: </span>
              <span>{getPositionName(data?.position_id, uDataPosition)}</span>
            </div>
            <div className="info-candidate">
              <span>{t('managerTest.level')}: </span>
              <span>{getDivisionName(data?.level_id, uDataLevel)}</span>
            </div>
            <div className="info-candidate">
              <span>{t('managerTest.skill')}: </span>
              <span>{getSkillName(data?.skill_id, uDataSkill)}</span>
            </div>
            <div className="mt-70">
              {data && data.status === STATUS_TESTING.PENDING && (
                <ButtonComponent
                  hasDisabled={isDisableSubmit}
                  isLoading={isDisableSubmit}
                  text={t('managerTest.startTest')}
                  addClass="btn-bg-yellow h-45 w-368"
                  handleClick={handleSubmitStartTesting}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoTesting;
