import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';

import { ButtonBase, Grid, Button, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { debounce } from '@material-ui/core';

import './styles.scss';
import styles from './styles.module.scss';
import { useGetListOrderHr } from 'hook/useManagerOrder';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20 } from 'constants/index.js';
import { ZERO } from 'constants/MasterData';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';
import * as Constants from 'constants/index.js';
import Loading from 'components/Loading';
import { getLevelName, getPositionName, getSkillName } from 'helpers/masterData';
import { handleCheckAll } from 'helpers/modal';

import { displayLabelTablePagination } from 'helpers/table';
import iconLoading from 'assets/img/icons/loading.png';
import ModalOrder from './ModalOrder';
import ModalReason from 'components/ModalReason';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import Popup from 'components/PopupAreYouSure';
import { useMutation, useQueryClient } from 'react-query';
import { sendPost } from 'helpers/axios';
import { USE_LIST_ORDER_HR, USE_DETAIL_HR_ORDER, USE_GET_LIST_JOB_AVAILABLE_FOR_ORDER } from 'constants/ManagerOrder';
import ModalAddJob from './ModalAddJob';
import FilterOrder from './FilterOrder';
import FilterOrderByStatus from './FilterOrderByStatus';
import FilterOrderByDivision from './FilterOrderByDivision';
import SearchOrder from './SearchOrder';
import FilterOrderByDeadline from './FilterOrderByDeadline';
import { apiChangeStatusOrder } from 'api/managerOrder';
import { getClassStatus } from 'helpers/managerOrder';

import {
  WAIT_BOD_ACCEPT,
  PROCESSING,
  DEADLINE_LATE,
  CANCEL_ORDER,
  COMPLETED,
  BOD_DONT_ACCEPT,
  CANCEL_ACTION,
  COMPLETED_ACTION,
  SKILL,
  LEVEL,
  POSITION,
} from 'constants/ManagerOrder.js';

const ManagerOrderHr = () => {
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const { orderId } = useParams();

  const [status, setStatus] = useState([
    { name: t('managerOrderHr.all'), value: null, status: false },
    { name: t('managerOrderHr.waitBodAccept'), value: WAIT_BOD_ACCEPT, status: false },
    { name: t('managerOrderHr.bodDontAccept'), value: BOD_DONT_ACCEPT, status: false },
    { name: t('managerOrderHr.processing'), value: PROCESSING, status: false },
    { name: t('managerOrderHr.deadlineLate'), value: DEADLINE_LATE, status: false },
    { name: t('managerOrderHr.cancelOrder'), value: CANCEL_ORDER, status: false },
    { name: t('managerOrderHr.completed'), value: COMPLETED, status: false },
  ]);

  const [filter, setFilter] = useState({
    level_id: null,
    position_id: null,
    skill_id: null,
    division_id: null,
    page: DEFAULT_PAGE_MUI,
    limit: DEFAULT_LIMIT_20,
    to_time: null,
    from_time: null,
  });

  const { data: dataListOrders, isLoading: isLoadingDataListOrders } = useGetListOrderHr({
    ...filter,
    page: filter.page + 1,
    per_page: filter.limit,
    from_time: filter.to_time && filter.from_time && moment(filter.from_time, 'YYYY/MM/DD').format('YYYY/MM/DD'),
    to_time: filter.to_time && filter.from_time && moment(filter.to_time, 'YYYY/MM/DD').format('YYYY/MM/DD'),
  });

  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();

  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [detailOrder, setOpenDetailOrder] = useState(false);
  const [idDetailModal, setIdDetailModal] = useState();
  const [payloadAddJob, setPayloadAddJob] = useState({ ownerId: null, division_id: null });

  const [isModals, setIsModals] = useState({ position: false, skill: false, level: false, hrCharge: false });

  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalComplete, setShowModalComplete] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [showModalRefuse, setShowModalRefuse] = useState(false);
  const [showModalAddJob, setShowModalAddJob] = useState(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState(false);
  const [isCompletedSuccess, setIsCompletedSuccess] = useState(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [isRefuseSuccess, setIsRefuseSuccess] = useState(false);
  const [isAddJobSuccess, setIsAddJobSuccess] = useState(false);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (orderId) {
      setIdDetailModal(orderId);
      setOpenDetailOrder(true);
    }
  }, [orderId]);

  useEffect(() => {
    if (uDataLevel) {
      const level = uDataLevel.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataLevel([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...level,
      ]);
    }
  }, [uDataLevel, t]);

  useEffect(() => {
    if (uDataSkill) {
      const skill = uDataSkill.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataSkill([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...skill,
      ]);
    }
  }, [uDataSkill, t]);

  useEffect(() => {
    if (uDataPosition) {
      const position = uDataPosition.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataPosition([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...position,
      ]);
    }
  }, [uDataPosition, t]);

  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${ZERO}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  const changeSelectPosition = (item) => {
    setDataPosition(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectLevel = (item) => {
    setDataLevel(function (prev) {
      const index = prev.indexOf(item);
      return handleCheckAll(prev, index);
    });
  };

  const changeSelectSkill = (item) => {
    setDataSkill(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectStatus = (item) => {
    setStatus(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  function handleCheck(items, index) {
    items.forEach(function (item, i) {
      if (index === i) {
        item.status = !item.status;
      } else {
        item.status = false;
      }
    });

    return [...items];
  }

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, limit: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataSkill?.forEach((item) => {
      item.status = false;
    });
    dataLevel?.forEach((item) => {
      item.status = false;
    });
    status?.forEach((item) => {
      item.status = false;
    });
    setIsReset(true);
    setFilter({
      level_id: null,
      position_id: null,
      skill_id: null,
      keyword: null,
      page: DEFAULT_PAGE_MUI,
      limit: DEFAULT_LIMIT_20,
      from_time: null,
      to_time: null,
      division_id: null,
    });
    setDataPosition([...dataPosition]);
    setDataLevel([...dataLevel]);
    setDataSkill([...dataSkill]);
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        keyword: value,
        page: DEFAULT_PAGE_MUI,
        limit: DEFAULT_LIMIT_20,
      });
    }, 500),
  );

  const getNameDivisonById = (id) => {
    return dataListOrders.list_total_by_division?.find((item) => item.id === id)?.name;
  };

  const handleChangeRadio = (value) => {
    if (Number(value) === 0) {
      setFilter({ ...filter, division_id: null });
      return;
    }
    setFilter({ ...filter, division_id: [value] });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleClickDetailRow = (id) => {
    setOpenDetailOrder(true);
    setIdDetailModal(id);
  };

  const handleClickDoneAlert = () => {
    setIsCancelSuccess(false);
    setIsCompletedSuccess(false);
    setIsDeleteSuccess(false);
    setIsRefuseSuccess(false);
    setIsAddJobSuccess(false);
    QueryClient.invalidateQueries([USE_LIST_ORDER_HR], { refetchActive: true });
    QueryClient.invalidateQueries([USE_DETAIL_HR_ORDER], { refetchActive: true });
    QueryClient.refetchQueries(USE_GET_LIST_JOB_AVAILABLE_FOR_ORDER);
    setOpenDetailOrder(true);
  };

  const TotalOrder = () => (
    <div className="info-menu-right-no-image selected">
      <div className={styles.titleTotalOrder}>{t('managerOrderHr.totalOrder')}</div>
      <div className={styles.totalOrder}>{dataListOrders?.total_records}</div>
    </div>
  );

  const ModalCancelOrder = () => (
    <>
      <ModalReason
        title={t('modalOrder.reasonCancel')}
        label={t('modalOrder.typeReasonCancel')}
        id={idDetailModal}
        isShowModal={showModalCancel}
        setIsShowModal={setShowModalCancel}
        returnAfterClose={() => setOpenDetailOrder(true)}
        newStatus={CANCEL_ACTION}
        onSuccess={() => setIsCancelSuccess(true)}
        api={apiChangeStatusOrder}
      />
      <AlertAfterSubmit
        toggle={handleClickDoneAlert}
        isShowAlert={isCancelSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('modalOrder.cancelOrderSuccess')}</p>
      </AlertAfterSubmit>
    </>
  );

  const ModalCompleteOrder = () => (
    <>
      <ModalReason
        title={t('modalOrder.reasonComplete')}
        label={<span dangerouslySetInnerHTML={{ __html: t('modalOrder.typeReasonComplete') }}></span>}
        id={idDetailModal}
        isShowModal={showModalComplete}
        setIsShowModal={setShowModalComplete}
        returnAfterClose={() => setOpenDetailOrder(true)}
        newStatus={COMPLETED_ACTION}
        onSuccess={() => setIsCompletedSuccess(true)}
        api={apiChangeStatusOrder}
      />
      <AlertAfterSubmit
        toggle={handleClickDoneAlert}
        isShowAlert={isCompletedSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('modalOrder.changeCompleteSuccess')}</p>
      </AlertAfterSubmit>
    </>
  );

  const { mutate: deleteOrder } = useMutation(
    async () => {
      return sendPost('api/orders-hr/remove-job', {
        id: idDetailModal,
      });
    },
    {
      onSuccess: async () => {
        setShowAlertDelete(false);
        setIsDeleteSuccess(true);
      },
      onError: () => {},
    },
  );

  const handleClickCancelAlertDelete = () => {
    setShowAlertDelete(false);
    setOpenDetailOrder(true);
  };

  const FooterPopupConfirm = () => (
    <>
      <Button
        sx={{
          fontWeight: 400,
          fontSize: 14,
          lineHeight: `16px`,
          letterSpacing: 1.25,
          textTransform: `uppercase`,
          color: `#8d8d8d`,
        }}
        onClick={() => handleClickCancelAlertDelete()}
      >
        {t('modalOrder.cancel')}
      </Button>
      <Button
        sx={{
          fontWeight: 400,
          fontSize: 14,
          lineHeight: `16px`,
          letterSpacing: 1.25,
          textTransform: `uppercase`,
          color: `#fea628`,
        }}
        onClick={() => deleteOrder()}
      >
        {t('modalOrder.ok')}
      </Button>
    </>
  );

  const ModalRefuseOrder = () => (
    <>
      <ModalReason
        title={t('modalOrder.reasonRefuse')}
        label={t('modalOrder.typeReasonRefuse')}
        id={idDetailModal}
        isShowModal={showModalRefuse}
        setIsShowModal={setShowModalRefuse}
        returnAfterClose={() => setOpenDetailOrder(true)}
        newStatus={CANCEL_ACTION}
        onSuccess={() => setIsRefuseSuccess(true)}
        api={apiChangeStatusOrder}
      />
      <AlertAfterSubmit
        toggle={handleClickDoneAlert}
        isShowAlert={isRefuseSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('modalOrder.refuseOrderSuccess')}</p>
      </AlertAfterSubmit>
    </>
  );

  const ModalAddToJob = () => (
    <>
      <ModalAddJob
        id={idDetailModal}
        payloadAddJob={payloadAddJob}
        isShowModal={showModalAddJob}
        setIsShowModal={setShowModalAddJob}
        returnAfterClose={() => setOpenDetailOrder(true)}
        onSuccess={() => setIsAddJobSuccess(true)}
      />
      <AlertAfterSubmit
        toggle={handleClickDoneAlert}
        isShowAlert={isAddJobSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('modalOrder.addJobSuccess')}</p>
      </AlertAfterSubmit>
    </>
  );

  $(function () {
    var running = false;
    $('.wrapper-temp').scroll(function () {
      if (running) {
        running = false;
        return;
      }
      running = true;
      $('.table-container').scrollLeft($('.wrapper-temp').scrollLeft());
    });
    $('.table-container').scroll(function () {
      if (running) {
        running = false;
        return;
      }
      running = true;
      $('.wrapper-temp').scrollLeft($('.table-container').scrollLeft());
    });
  });

  return (
    <Grid container className="manager-order-hr">
      <ModalOrder
        modal={detailOrder}
        toggle={() => {
          setOpenDetailOrder((prev) => !prev);
          window.history.replaceState(null, '', '/admin/manager-order');
        }}
        title={t('modalOrder.title')}
        id={idDetailModal}
        dataPosition={dataPosition}
        dataLevel={dataLevel}
        dataSkill={dataSkill}
        openModalCancel={setShowModalCancel}
        openModalComplete={setShowModalComplete}
        openAlertDelete={setShowAlertDelete}
        openModalRefuse={setShowModalRefuse}
        openModalAddJob={setShowModalAddJob}
        setPayloadAddJob={setPayloadAddJob}
      />
      <ModalCancelOrder />
      <ModalCompleteOrder />
      <ModalRefuseOrder />
      {(showModalAddJob || isAddJobSuccess) && <ModalAddToJob />}

      <Popup
        active={showAlertDelete}
        handleCancel={() => handleClickCancelAlertDelete()}
        messages={t('modalOrder.areYouSureDelete')}
        footer={<FooterPopupConfirm />}
      />
      <AlertAfterSubmit
        toggle={handleClickDoneAlert}
        isShowAlert={isDeleteSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('modalOrder.deleteOrderSuccess')}</p>
      </AlertAfterSubmit>

      <Grid container className="pt-0">
        <Grid item xs sm md lg xl className="header-left color-text up-case">
          {t('menu.managerOrder')}
        </Grid>
      </Grid>
      <Grid container className="pt-4">
        <Grid item md={9} sm={8}>
          <div className="wrapper-temp">
            <div className="div-temp"></div>
          </div>
          <div className="table-border-show">
            <Paper>
              <TableContainer className="table-container">
                <Table aria-label="sticky table" className="table-scroll">
                  <TableHead className="asset-header">
                    <TableRow className="asset-header-row headTable">
                      <TableCell>{t('common.STT')}</TableCell>
                      <TableCell>{t('managerOrderHr.nameUserCreate')}</TableCell>
                      <TableCell>{t('managerOrderHr.division')}</TableCell>
                      <TableCell>{t('masterData.StandardIndex.position')}</TableCell>
                      <TableCell>{t('masterData.StandardIndex.skill')}</TableCell>
                      <TableCell>{t('masterData.StandardIndex.level')}</TableCell>
                      <TableCell>{t('managerOrderHr.status')}</TableCell>
                      <TableCell className="text-center">{t('modalOrder.totalRecruit')}</TableCell>
                      <TableCell>{t('managerOrderHr.nameJob')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingDataListOrders && (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Loading addClass="mt-3 mb-3" />
                        </TableCell>
                      </TableRow>
                    )}
                    {!isLoadingDataListOrders &&
                      Array.isArray(dataListOrders?.list_data?.data) &&
                      dataListOrders?.list_data.data.map((item, index) => {
                        return (
                          <TableRow key={index} onClick={() => handleClickDetailRow(item.id)}>
                            <TableCell className="table-content id-standard">
                              {getSTT(index + filter.page * filter.limit)}
                            </TableCell>
                            <TableCell className="table-content name-create">
                              {item.owner_name?.length > Constants.MAX_LENGTH_20
                                ? item.owner_name.slice(0, Constants.MAX_LENGTH_20) + '...'
                                : item.owner_name}
                            </TableCell>
                            <TableCell className="table-content division">
                              {getNameDivisonById(item.division_id)}
                            </TableCell>
                            <TableCell className="table-content same-color">
                              {item.position_id
                                ? getPositionName(item?.position_id, dataPosition)
                                : t('common.notDataContract')}
                            </TableCell>
                            <TableCell className="table-content same-color">
                              {item.skill_id ? getSkillName(item?.skill_id, dataSkill) : t('common.notDataContract')}
                            </TableCell>
                            <Tooltip
                              title={
                                <div className="tooltip-division-order">
                                  {item.level_id?.map((val, index) => (
                                    <div className="text-division" key={index}>
                                      {getLevelName(val, dataLevel)}
                                    </div>
                                  ))}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                sx: {
                                  '& 	.MuiTooltip-tooltipArrow': {
                                    top: '-30px !important',
                                    '&::before': {
                                      backgroundColor: 'blue',
                                    },
                                  },
                                },
                              }}
                            >
                              <TableCell className="table-content same-color text-level-hr">
                                <span>
                                  {item.level_id
                                    ? item?.level_id.map((val) => getLevelName(val, dataLevel)).join(', ')
                                    : t('common.notDataContract')}
                                </span>
                              </TableCell>
                            </Tooltip>
                            <TableCell className={`${getClassStatus(item.status)} table-content`}>
                              {status.find((el) => el.value === Number(item.status))?.name}
                            </TableCell>
                            <TableCell className="table-content same-color text-center">
                              {(item?.total_recruited ? item?.total_recruited : 0) + '/' + item?.target}
                            </TableCell>
                            <TableCell className="table-content same-color">
                              {item.job_name?.length > Constants.MAX_LENGTH_30
                                ? item.job_name.slice(0, Constants.MAX_LENGTH_30) + '...'
                                : item.job_name}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                    {dataListOrders?.total_records === ZERO && (
                      <tr>
                        <td colSpan={8} className="error-no-data">
                          {t('common.notData')}
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {dataListOrders?.total_records > ZERO && (
                <TablePagination
                  rowsPerPageOptions={Constants.PER_PAGE_OPTION_20}
                  component="div"
                  count={dataListOrders?.total_records}
                  rowsPerPage={filter?.limit}
                  page={filter.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagination"
                  labelDisplayedRows={displayLabelTablePagination}
                />
              )}
            </Paper>
          </div>
        </Grid>
        <Grid item md={3} sm={4} className="filter">
          <TotalOrder />
          <SearchOrder
            isReset={isReset}
            setIsReset={setIsReset}
            handleSearch={handleSearch}
            maxLength={Constants.MAX_LENGTH_50}
          />
          <FilterOrder
            setIsModals={setIsModals}
            setFilter={setFilter}
            data={dataPosition}
            setData={setDataPosition}
            isModals={isModals}
            filter={filter}
            changeSelect={changeSelectPosition}
            type={POSITION}
          />
          <FilterOrder
            setIsModals={setIsModals}
            setFilter={setFilter}
            data={dataSkill}
            setData={setDataSkill}
            isModals={isModals}
            filter={filter}
            changeSelect={changeSelectSkill}
            type={SKILL}
          />
          <FilterOrder
            setIsModals={setIsModals}
            setFilter={setFilter}
            data={dataLevel}
            setData={setDataLevel}
            isModals={isModals}
            filter={filter}
            changeSelect={changeSelectLevel}
            type={LEVEL}
          />
          <FilterOrderByStatus
            setIsModals={setIsModals}
            setFilter={setFilter}
            data={status}
            setData={setStatus}
            isModals={isModals}
            filter={filter}
            changeSelect={changeSelectStatus}
          />
          <FilterOrderByDeadline
            fromTime={filter.from_time}
            toTime={filter.to_time}
            onChangeFromTime={(dateSearch) => {
              setFilter({ ...filter, from_time: dateSearch });
            }}
            onChangeToTime={(dateSearch) => {
              setFilter({ ...filter, to_time: dateSearch });
            }}
          />
          <FilterOrderByDivision
            handleChange={handleChangeRadio}
            listOrders={dataListOrders}
            isLoading={isLoadingDataListOrders}
            value={filter?.division_id === null ? 0 : filter.division_id}
          />
          <ButtonBase onClick={() => handleReset()} className="reset mt-3" size="lg">
            <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
            {t('common.reset')}
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManagerOrderHr;
