import { useQuery } from 'react-query';
import * as Constants from 'constants/ManagerCandidate';
import { HTTP_OK } from 'constants/StatusCode';
import { apiGetHistoryInterView } from 'api/managerCandidate';

export function useGetHistoryInterView(params) {
  const { data: response, error, isLoading } = useQuery([Constants.USE_GET_DETAIL_CANDIDATE, params], async () => {
    const response = await apiGetHistoryInterView(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return { data: response, error, isLoading };
}
