import React from 'react';

import styles from './styles.module.scss';

const TotalNumberLine = (props) => {
  const { text, number } = props;
  return (
    <div className={styles.container}>
      <span className={styles.text}>{text}</span>
      <span className={styles.number}>{number}</span>
    </div>
  );
};

export default TotalNumberLine;
