import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import LayoutHr from './layoutHr';
import InterviewInfoHR from './hr';
import HistoryTab from './History';
import QuestionTab from './Question';
import TestTab from './StatusTest';

import LayoutLeader from './layoutLeader';
import InterviewInfoLeader from './leader';

import * as CANDIDATE from 'constants/ManagerCandidate';
import { ThemContext } from 'layouts/Admin';

function DetailApply() {
  const { isLeader, isAssigner } = useContext(ThemContext);

  const LayoutByRole = (props) => {
    const { children } = props;
    if (isLeader || isAssigner) return <LayoutLeader {...props}>{children}</LayoutLeader>;
    return <LayoutHr {...props}>{children}</LayoutHr>;
  };

  return (
    <>
      <Switch>
        <Route
          path="/admin/candidate-detail/:candidateId/apply-detail/:applyId"
          exact
          render={() => (
            <LayoutByRole tab={CANDIDATE.HR_TAB_INFO}>
              {isLeader || isAssigner ? <InterviewInfoLeader /> : <InterviewInfoHR />}
            </LayoutByRole>
          )}
        />
        <Route
          path="/admin/candidate-detail/:candidateId/apply-detail/:applyId/test"
          exact
          render={() => (
            <LayoutByRole tab={CANDIDATE.HR_TAB_TEST}>
              <TestTab />
            </LayoutByRole>
          )}
        />
        <Route
          path="/admin/candidate-detail/:candidateId/apply-detail/:applyId/questions"
          exact
          render={() => (
            <LayoutByRole tab={CANDIDATE.HR_TAB_QUESTION}>
              <QuestionTab />
            </LayoutByRole>
          )}
        />
        <Route
          path="/admin/candidate-detail/:candidateId/apply-detail/:applyId/history"
          exact
          render={() => (
            <LayoutByRole tab={CANDIDATE.HR_TAB_HISTORY_INTERVIEW}>
              <HistoryTab />
            </LayoutByRole>
          )}
        />
      </Switch>
    </>
  );
}

export default DetailApply;
