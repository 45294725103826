import { useTranslation } from 'react-i18next';

import React, { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Grid } from '@mui/material';
import { useQueryClient, useMutation } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import 'styles/variable.scss';
import ButtonComponent from 'components/Buttons';
import { ACTIVE } from 'constants/ManagerJD';
import { JD_PERMISSION } from 'constants/permission';

import { apiDeleteJD, apiExportJD } from 'api/managerJD';
import { USE_LIST_JD, TYPE_INTERVIEW } from 'constants/ManagerJD';
import { ZERO } from 'constants/MasterData';
import { HTTP_OK, HTTP_EXPECTATION_FAILED } from 'constants/StatusCode.js';
import { getLevelName, getPositionName, getTypeInterView } from 'helpers/masterData';
import ModalComponent from 'components/ModalDoubleCol';
import EditLayout from 'components/PermissionPage/EditLayout';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';
import { formatSalary } from 'helpers/format';
import { showAlert } from 'helpers/modal';

const DetailModal = (props) => {
  const {
    isShowModal,
    setShowModal,
    setShowModalCreateAndEdit,
    dataDetail,
    dataPosition,
    dataLevel,
    notAllowPermissionUpdate,
    notAllowPermissionDelete,
  } = props;

  const { t } = useTranslation();
  const [, setShowModalEdit] = useState(false);
  const [, setShowAlert] = useState(false);
  const [, setMessage] = useState('');

  const queryClient = useQueryClient();

  const [formDataDetail, setFormDataDetail] = useState({
    id: null,
    title: '',
    level_id: ZERO,
    position_id: ZERO,
    type_interview: '',
    experience: '',
    limit: '',
    salary: '',
    address: '',
    status: ZERO,
    content: '',
  });

  /* eslint-disable */
  const modules = {
    toolbar: [],
  };
  /* eslint-enable */

  useEffect(() => {
    if (isShowModal) {
      setFormDataDetail({
        position: getPositionName(dataDetail.position_id, dataPosition),
        level: getLevelName(dataDetail.level_id, dataLevel),
        type_interview: getTypeInterView(dataDetail?.type_interview, TYPE_INTERVIEW),
        title: dataDetail?.title,
        limit: dataDetail?.limit,
        salary: dataDetail?.salary,
        status: dataDetail?.status,
        address: dataDetail?.address,
        content: dataDetail?.content,
        experience: dataDetail?.experience,
      });
    }
    // eslint-disable-next-line
  }, [isShowModal]);

  const openEditJD = () => {
    setShowModalCreateAndEdit(true);
    setShowModal(false);
  };

  const alertConfirm = (id) => {
    confirmAlert({
      title: t('managerJD.confirmDeleteJD'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteJD({ id: id });
            if (response?.data.status === HTTP_OK || response?.data.status === HTTP_EXPECTATION_FAILED) {
              setMessage(response.data.messages);
              setShowAlert(true);
              setShowModalEdit(false);
              setShowModal(false);
              alertDeleteResult(response?.data.status);
              queryClient.invalidateQueries(USE_LIST_JD);
            }
          },
        },
      ],
    });
  };

  const alertDeleteResult = (status) => {
    if (status === HTTP_OK) {
      confirmAlert({
        title: t('managerJD.deleteJDSuccess'),
        buttons: [
          {
            label: t('common.done'),
            className: 'btn-alert-ok',
            onClick: () => {
              setShowModalEdit(false);
              setShowModal(false);
              queryClient.invalidateQueries(USE_LIST_JD);
            },
          },
        ],
      });
    }
  };

  const { mutate: handleExportJD } = useMutation(() => apiExportJD(dataDetail.id), {
    onSuccess: (res) => {
      if (res.status === 200) {
        const encodedUri = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'Amela - JD -' + getPositionName(dataDetail.position_id, dataPosition) + '.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        showAlert(t('messages.systemError'));
      }
    },
    onError: (error) => {
      if (error?.message) {
        showAlert(error?.message);
      } else if (error?.data?.message) {
        showAlert(error?.data?.message);
      } else {
        showAlert(t('common.anUnknownError'));
      }
    },
  });

  return (
    <>
      <ModalComponent
        toggle={() => setShowModal(!isShowModal)}
        isShowModal={isShowModal}
        title={t('managerJD.detailJD')}
        classNameAdd="modal-create-update-JD modal-body-custom list-JD"
        classNameBody="body-custom"
      >
        <div className="information">
          <div className="header">
            <div className="text-modal text-header-create-JD"> {formDataDetail.title}</div>
            <div className="header-left">
              <ButtonComponent
                text={t('managerJD.download')}
                addClass="button-download-custom"
                handleClick={handleExportJD}
              />
              <DeleteLayout permissionName={JD_PERMISSION}>
                <ButtonComponent
                  text={t('managerJD.delete')}
                  addClass="button-cancel-custom btn-bg-yellow2"
                  handleClick={() => alertConfirm(dataDetail.id)}
                  hasDisabled={notAllowPermissionDelete}
                />
              </DeleteLayout>

              <EditLayout permissionName={JD_PERMISSION}>
                <ButtonComponent
                  text={t('managerJD.edit')}
                  addClass="button-edit-custom btn-bg-yellow1"
                  hasDisabled={notAllowPermissionUpdate}
                  handleClick={() => openEditJD(dataDetail?.id)}
                />
              </EditLayout>
            </div>
          </div>
          <div className="modal-body-custom">
            <div>
              <div className="text-modal text-header-body-modal">{t('managerJD.information')}</div>
              <Grid className="mt-4 d-flex">
                <Grid className="table-content" md={4}>
                  {t('managerJD.typeInterview')}
                </Grid>
                <Grid className="table-text text-detail-data-jd" md={8}>
                  {formDataDetail?.type_interview ? formDataDetail?.type_interview : t('common.notDataContract')}
                </Grid>
              </Grid>
              <Grid className="mt-4 d-flex">
                <Grid className="table-content" md={4}>
                  {t('managerJD.positionRecruit')}
                </Grid>
                <Grid className="table-text text-detail-data-jd" md={8}>
                  {formDataDetail?.position ? formDataDetail?.position : t('common.notDataContract')}
                </Grid>
              </Grid>
              <Grid className="mt-4 d-flex">
                <Grid className="table-content" md={4}>
                  {t('managerJD.levelRecruit')}
                </Grid>
                <Grid className="table-text text-detail-data-jd" md={8}>
                  {formDataDetail?.level ? formDataDetail?.level : t('common.notDataContract')}
                </Grid>
              </Grid>
              <Grid className="mt-4 d-flex">
                <Grid className="table-content" md={4}>
                  {t('managerJD.experience')}
                </Grid>
                <Grid className="table-text text-detail-data-jd" md={8}>
                  {formDataDetail?.experience ? formDataDetail?.experience : t('common.notDataContract')}
                </Grid>
              </Grid>
              <Grid className="mt-4 d-flex">
                <Grid className="table-content" md={4}>
                  {t('managerJD.salary')}
                </Grid>
                <Grid className="table-text text-detail-data-jd" md={8}>
                  {formDataDetail?.salary ? formatSalary(formDataDetail.salary) : t('common.notDataContract')}
                </Grid>
              </Grid>
              <Grid className="mt-4 d-flex">
                <Grid className="table-content" md={4}>
                  {t('managerJD.quantityRecruit')}
                </Grid>
                <Grid className="table-text text-detail-data-jd" md={8}>
                  {formDataDetail?.limit ? formDataDetail?.limit : t('common.notDataContract')}
                </Grid>
              </Grid>
              <Grid className="mt-4 d-flex">
                <Grid className="table-content" md={4}>
                  {t('managerJD.address')}
                </Grid>
                <Grid className="table-text text-detail-data-jd" md={8}>
                  {formDataDetail?.address ? formDataDetail?.address : t('common.notDataContract')}
                </Grid>
              </Grid>
              <Grid className="mt-4 d-flex">
                <Grid className="table-content" md={4}>
                  {t('managerJD.status')}
                </Grid>
                <Grid className="table-text text-detail-data-jd" md={8}>
                  {formDataDetail?.status === ACTIVE ? t('managerJD.active') : t('managerJD.inactive')}
                </Grid>
              </Grid>
            </div>
            <hr />
            <div>
              <div className="text-modal text-header-body-modal">{t('managerJD.mainInfo')}</div>
              <ReactQuill
                value={formDataDetail?.content ? formDataDetail?.content : ''}
                modules={modules}
                readOnly={true}
                theme={'bubble'}
              />
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default DetailModal;
