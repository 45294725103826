import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DoneTested from './DoneTested';
import InfoTesting from './InfoTesting';
import StartTesting from './StartTesting';

const index = () => {
  return (
    <>
      <Switch>
        <Route path="/user/testing/:id" exact render={() => <InfoTesting />} />
        <Route path="/user/testing/:id/start" exact render={() => <StartTesting />} />
        <Route path="/user/testing/:id/start/notify" exact render={() => <DoneTested />} />
      </Switch>
    </>
  );
};

export default index;
