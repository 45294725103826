import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ThemContext } from 'layouts/Admin';
import LeaderTest from './leader';
import HrTest from './hr';

const Test = () => {
  const { isLeader, isAssigner } = useContext(ThemContext);
  const { applyId } = useParams();
  if (isLeader || isAssigner) return <LeaderTest applyId={applyId} />;
  return <HrTest applyId={applyId} />;
};

export default Test;
