import ModalComponent from 'components/Modal';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BackgroundImage } from 'react-image-and-background-image-fade';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';

import { Checkbox, Grid, List, ListItem, ListItemButton, ListItemText, MenuItem } from '@mui/material';
import { ModalFooter } from 'reactstrap';

import styles from './styles.module.scss';
import SearchComponent from 'components/Search';
import { MAX_LENGTH_30 } from 'constants/index.js';
import ButtonComponent from 'components/Buttons';
import CheckedIconCheckbox from '../../../../assets/img/icons/check_circle.svg';
import Loading from 'components/Loading';
import avatarDefault from 'assets/img/icons/avatar_default.svg';
import { useCheckUserRole, useListCommunityAddMemberCustom } from 'hook/useManagerDecentralization';
import { useGetListDivision } from 'hook/useDivision';
import { useListPosition } from 'hook/useMasterData';
import SelectField from 'components/ReactForm/SelectField';

const CheckedIcon = () => (
  <span
    style={{ backgroundImage: `url(${CheckedIconCheckbox})`, fontSize: '14px', width: '27px', height: '27px' }}
  ></span>
);

function PopupGroupCustom({ isShowModal, setShowModal, setDataToSave, dataOld }) {
  const {
    control,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();
  const [checkedValues, setCheckedValues] = useState([]);
  const [userId, setUserId] = useState();
  // Searching
  const [filter, setFilter] = useState({
    key_word: '',
    division_id: [],
    position_id: [],
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchEmployee = (value) => {
    setFilter({ ...filter, key_word: value });
  };

  const { data: uListDivision } = useGetListDivision();
  const { data: uListPosition } = useListPosition();

  const { data: uCheckUserRole } = useCheckUserRole({ user_id: userId });
  const { data: uListCommunityAddMemberCustom, isLoading: isLoadingListCommunityAddMemberCustom } =
    useListCommunityAddMemberCustom(filter);

  const watchDivisionId = useWatch({
    control,
    name: 'division_id',
  });
  // Watch value select position
  const watchPositionId = useWatch({
    control,
    name: 'position_id',
  });

  useEffect(() => {
    let dataFilter = [];
    if (watchPositionId) {
      dataFilter.push(watchPositionId);
    }
    setFilter({ ...filter, position_id: dataFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPositionId]);

  useEffect(() => {
    let dataFilter = [];
    if (watchDivisionId) {
      dataFilter.push(watchDivisionId);
    }
    setFilter({ ...filter, division_id: dataFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDivisionId]);

  useEffect(() => {
    if (uCheckUserRole) {
      confirmAlert({
        title:
          t('managerDecentralization.thisEmployeeAlreadyHasAnotherAuthorization') +
          uCheckUserRole +
          t('managerDecentralization.doYouWantToChangePermissions'),
        buttons: [
          {
            label: t('managerDecentralization.cancel'),
            className: 'btn-alert-cancel',
            onClick: () => {
              let dataUsers = [];
              checkedValues.forEach((item) => {
                if (item.user_id !== userId) {
                  dataUsers.push(item);
                }
              });
              setCheckedValues([...dataUsers]);
              setUserId(null);
            },
          },
          {
            label: t('managerDecentralization.ok'),
            className: 'btn-alert-ok',
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uCheckUserRole]);

  function handleSelect(checkedName) {
    if (!dataOld.find((it) => it.user_id === checkedName.user_id)) {
      if (!checkedValues.find((it) => it.user_id === checkedName.user_id)) {
        setUserId(checkedName.user_id);
      }
      const newNames = checkedValues?.includes(checkedName)
        ? checkedValues?.filter((name) => name !== checkedName)
        : [...(checkedValues ?? []), checkedName];
      setCheckedValues(newNames);
    }
  }

  const handleClearAllData = () => {
    setFilter({
      key_word: '',
      division_id: [],
      position_id: [],
    });
    setCheckedValues([]);
    reset();
  };

  return (
    <ModalComponent
      isShowModal={isShowModal}
      title={t('managerDecentralization.addEmployee')}
      toggle={() => {
        setShowModal(!isShowModal);
        handleClearAllData();
      }}
      classNameAdd={styles.modalAddEmployee}
      renderModalFooter={() => (
        <ModalFooter className={styles.modalFooter}>
          <ButtonComponent
            text={t('managerDecentralization.addMember')}
            addClass={classNames(styles.btn, styles.btn__action)}
            hasDisabled={checkedValues.length === 0}
            handleClick={() => {
              setDataToSave(checkedValues);
              setShowModal(false);
            }}
          />
        </ModalFooter>
      )}
    >
      <Grid container rowSpacing={2} className="popup-add-member-custom">
        <Grid item xs={12}>
          <SearchComponent
            txtPlaceholder={t('managerDecentralization.nameEmployee')}
            addClass="search-employee"
            defaultValue={''}
            handleSearch={handleSearchEmployee}
            size={'medium'}
            maxLength={MAX_LENGTH_30}
          />
        </Grid>
        <Grid item xs={12} container columnSpacing={2}>
          <Grid item xs={6}>
            <SelectField
              name="division_id"
              label={t('managerDecentralization.divisionClass')}
              errors={errors?.division_id?.message}
              control={control}
              register={register}
              useWatch={useWatch}
              setValue={setValue}
              isShowModal={isShowModal}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: {
                      xs: 350,
                    },
                  },
                },
              }}
            >
              <MenuItem value="">{t('managerJob.None')}</MenuItem>
              {uListDivision?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={6}>
            <SelectField
              name="position_id"
              label={t('managerDecentralization.divisionPosition')}
              errors={errors?.position_id?.message}
              control={control}
              register={register}
              useWatch={useWatch}
              setValue={setValue}
              isShowModal={isShowModal}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: {
                      xs: 350,
                    },
                  },
                },
              }}
            >
              <MenuItem value="">{t('managerJob.None')}</MenuItem>
              {uListPosition?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={12} className="list-member-custom">
            <p className="text-bold title d-inline fs-16">{t('managerDecentralization.listMember')}</p>
            <List className="list-member-checkbox mt-1">
              {isLoadingListCommunityAddMemberCustom && <Loading addClass="mt-3 mb-3" />}
              {uListCommunityAddMemberCustom?.map((item, index) => (
                <ListItem
                  className={classNames(
                    'w-100 item-selected disable-checkbox',
                    checkedValues && checkedValues.find((it) => it.user_id === item.user_id) && 'selected-item',
                    dataOld && dataOld.find((it) => it.user_id === item.user_id) && 'selected-item',
                  )}
                  key={index}
                >
                  <ListItemButton>
                    <ListItemText>
                      <Controller
                        render={({ field: { onChange } }) => (
                          <FormControlLabel
                            label={
                              <div className="d-flex align-items-center info-wrapper">
                                <BackgroundImage
                                  element="section"
                                  src={item?.avatar ? item?.avatar : avatarDefault}
                                  lazyLoad
                                  wrapperClassName="avatar p-0"
                                />
                                <div>
                                  <span className="d-block user-name fs-14">{item?.user_name}</span>
                                  <span className="bottom-content">
                                    {item?.email} - {item?.position_name}
                                  </span>
                                </div>
                              </div>
                            }
                            control={
                              <Checkbox
                                checked={
                                  checkedValues.find((it) => it.user_id === item.user_id)
                                    ? true
                                    : false || dataOld.find((it) => it.user_id === item.user_id)
                                    ? true
                                    : false
                                }
                                checkedIcon={<CheckedIcon />}
                                onChange={() => handleSelect(item)}
                              />
                            }
                          />
                        )}
                        name="names"
                        control={control}
                      />
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
              {uListCommunityAddMemberCustom && uListCommunityAddMemberCustom.length < 1 && (
                <p class="error">{t('common.notData')}</p>
              )}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </ModalComponent>
  );
}

export default PopupGroupCustom;
