import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from 'react-query';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from 'reactstrap';
import classNames from 'classnames';

import { Autocomplete, TextField, debounce } from '@mui/material';

import styles from './styles.module.scss';
import * as Constants from 'constants/index.js';
import { sendPost } from 'helpers/axios';
import { useGetListJobAvailableForOrder } from 'hook/useManagerOrder';

import ModalComponent from 'components/Modal';

const ModalAddJob = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isShowModal, setIsShowModal, id, onSuccess, returnAfterClose, payloadAddJob } = props;
  const [idJob, setIdJob] = useState();
  const [error, setError] = useState();
  const [filter, setFilter] = useState({
    page: Constants.DEFAULT_PAGE,
    per_page: Constants.DEFAULT_LIMIT_20,
    stopScroll: false,
    keyword: '',
  });

  const { data: dataJobList, isLoading: isLoadingListJob } = useGetListJobAvailableForOrder({
    per_page: filter.per_page,
    page: filter.page,
    keyword: filter.keyword,
    ...payloadAddJob,
  });

  const [listJob, setListJob] = useState([]);

  useEffect(() => {
    if (!isLoadingListJob) {
      setListJob([...listJob, ...dataJobList?.data]);

      if (dataJobList?.data?.length === 0) setFilter({ ...filter, stopScroll: true });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dataJobList]);
  const handleChange = useCallback(
    debounce((e) => {
      setListJob([]);
      setFilter({ ...filter, keyword: e.target.value ?? '' });
    }),
  );
  const handleCloseModal = () => {
    setIsShowModal(false);
    returnAfterClose();
  };

  const { mutate: submitAddToJob } = useMutation(
    async () => {
      return sendPost('/api/orders-hr/add-job', {
        id: id,
        job_id: idJob,
      });
    },
    {
      onSuccess: async () => {
        setIsShowModal(false);
        onSuccess();
      },
      onError: () => {},
    },
  );

  const validateAndSubmit = () => {
    if (idJob) submitAddToJob();
    else setError(t('modalOrder.pleaseChooseJob'));
  };

  const handleOpenCreateJob = () => {
    history.push({
      pathname: '/admin/manager-job',
      state: {
        show_popup_create_job: 1,
        orderId: id,
      },
    });
  };

  const onScroll = (event) => {
    if (isLoadingListJob) return;
    const listBoxNode = event.currentTarget;
    if (
      Math.abs(listBoxNode.scrollTop + listBoxNode.clientHeight - listBoxNode.scrollHeight) <
        Constants.DEFAULT_LIMIT_5 &&
      !filter.stopScroll
    ) {
      setFilter((prev) => {
        return { ...prev, page: prev.page + 1 };
      });
    }
  };
  const CustomLabelOption = (option) => (
    <div key={option.id}>
      <div>
        <NavLink
          to={{
            pathname: `/admin/manager-job/${option.id}`,
          }}
          target="_blank"
        >
          {option.name?.length > Constants.MAX_LENGTH_45
            ? option.name.slice(0, Constants.MAX_LENGTH_45) + '...'
            : option.name}
        </NavLink>
      </div>
    </div>
  );
  return (
    <ModalComponent
      title={t('modalOrder.addJob')}
      isShowModal={isShowModal}
      toggle={handleCloseModal}
      renderModalFooter={() => (
        <ModalFooter className={styles.modalFooter}>
          <button type="button" className={classNames(styles.btn, styles.btn__cancel)} onClick={handleOpenCreateJob}>
            {t('modalOrder.addNewJob')}
          </button>
          <button type="button" className={classNames(styles.btn, styles.btn__action)} onClick={validateAndSubmit}>
            {t('modalOrder.add')}
          </button>
        </ModalFooter>
      )}
      classNameAdd={styles.modalAddJob}
    >
      <Autocomplete
        getOptionLabel={(option) => option?.name}
        options={listJob}
        onChange={(event, value) => {
          setError('');
          setIdJob(value?.id);
          setFilter({...filter, keyword: value?.name})
        }}
        renderInput={(params) => (
          <TextField {...params} label={t('modalOrder.chooseJob')} onChange={(e) => handleChange(e)} />
        )}
        renderOption={(props, option) => {
          return <li {...props}>{CustomLabelOption(option)}</li>;
        }}
        ListboxProps={{ onScroll: onScroll }}
        onBlur={() => {
          filter.keyword !== '' && setListJob([]);
          setFilter({
            page: Constants.DEFAULT_PAGE,
            per_page: Constants.DEFAULT_LIMIT_20,
            stopScroll: false,
            keyword: '',
            
          });
        }}
      />
      {error && <div className={styles.errorMessage}>{error}</div>}
    </ModalComponent>
  );
};

export default ModalAddJob;
