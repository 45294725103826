import { Grid, Table, TableCell, TableRow, tableCellClasses } from '@mui/material';
import styles from './styles.module.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import classNames from 'classnames';
import { useQueryClient, useMutation } from 'react-query';
import './styles.scss';
import { useListLevel, useListBonus } from 'hook/useMasterData';
import { deleteBonus } from 'api/masterData';
import CreateOrUpdateBonus from './CreateOrUpdateBonus.js';
import AlertComponent from 'components/AlertAfterSubmit/index.js';
import Loading from 'components/Loading';
import PopupAreYouSure from 'components/PopupAreYouSure';
import ButtonComponent from 'components/Buttons';
import { formatNumberCurrency } from 'helpers/format';
import { LOADING_SM } from 'constants/index';
import * as Constants from 'constants/MasterData.js';
import { DEFAULT_BONUS, STATUS_CANDIDATE, DEFAULT_TYPE_CANDIDATE, DEFAULT_MONTH_BONUS } from 'constants/MasterData';
import { showAlert } from 'helpers/modal';
import EditLayout from 'components/PermissionPage/EditLayout';
import { COMMISSION_PERMISSION } from 'constants/permission';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';

const DEFAULT_FORM_DATA = {
  id: null,
  level_id: DEFAULT_BONUS,
  milestone: [{ type: DEFAULT_TYPE_CANDIDATE, bonus: null, month_number: DEFAULT_MONTH_BONUS }],
};

function Candidate() {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [idBonus, setIdBonus] = useState();
  const [idLevel, setIdLevel] = useState();
  const [isShowAlertCreate, setShowAlertCreate] = useState(false);
  const [isShowAlertUpdate, setShowAlertUpdate] = useState(false);
  const [isShowAlertDelete, setShowAlertDelete] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const queryClient = useQueryClient();
  const [textModal, setTextModal] = useState({
    title: t('masterData.Candidate.payBonus'),
    btn_submit: t('common.add'),
  });

  const [formAdd, setFormAdd] = useState(DEFAULT_FORM_DATA);
  const { data: dataBonus, isLoading: isLoadingDataBonus } = useListBonus();
  const { data: uDataLevel } = useListLevel({ key_word: '' });
  const [uDataBonus, setDataBonus] = useState([]);
  const [dataLevel, setDataLevel] = useState([]);

  useEffect(() => {
    if (uDataLevel) {
      setDataLevel(uDataLevel);
    }
  }, [uDataLevel]);

  useEffect(() => {
    if (uDataBonus) {
      setDataBonus(uDataBonus);
    }
  }, [uDataBonus]);

  const getLevelName = (level_id) => {
    let level = dataLevel.find((c) => c.id === level_id);
    return level ? level.name : t('infoEmployee.notUpdate');
  };

  const getStatusName = (type) => {
    let statusCandidate = STATUS_CANDIDATE.find((item) => item.id === type);
    return statusCandidate ? statusCandidate.name : t('infoEmployee.notUpdate');
  };

  const openAddModal = (item) => {
    setFormAdd({ ...DEFAULT_FORM_DATA, level_id: item.id });
    setShowModal(true);
    setTextModal({
      title: t('masterData.Candidate.payBonus') + getLevelName(item?.id),
      btn_submit: t('common.add'),
    });
  };

  const openEditModal = (item) => {
    setShowModal(true);
    setFormAdd({
      ...formAdd,
      id: item.id,
      level_id: item.level_id,
      milestone: item.milestone,
    });
    setTextModal({
      title: t('masterData.Candidate.editPayBonus') + getLevelName(item?.level_id),
      btn_submit: t('common.save'),
    });
  };

  const getBonusTable = (item) => {
    return item?.map((value, id) => (
      <TableRow key={id}>
        <TableCell className="text-table-title">
          {t('masterData.Candidate.timeOrder')}
          {id + 1}
        </TableCell>
        <TableCell className="text-table-element">{formatNumberCurrency(value.bonus)}</TableCell>
        <TableCell className="text-table-element">
          {value.month_number}
          {t('masterData.Candidate.timeAfterOnboard')}
          {value.type === DEFAULT_TYPE_CANDIDATE ? t('masterData.Candidate.become') : ''}
          {getStatusName(value.type)}
        </TableCell>
      </TableRow>
    ));
  };

  const openPopupConfirmDelete = (item) => {
    setConfirmDelete(true);
    setIdBonus(item.id);
    setIdLevel(item.level_id);
  };

  const { mutate: deleteBonusLevel, isLoading: isLoadingDelete } = useMutation(() => deleteBonus({ id: idBonus }), {
    onSuccess: (response) => {
      if (response.status === 200) {
        queryClient.invalidateQueries([Constants.USE_LIST_BONUS], { refetchActive: true });
        setConfirmDelete(false);
        setShowAlertDelete(true);
        setMessage(
          t('masterData.Candidate.deleteBonus') + getLevelName(idLevel) + t('masterData.Candidate.Successfully'),
        );
      } else {
        if (response?.data?.message) {
          showAlert(response.data.message);
        } else {
          showAlert(t('messages.systemError'));
        }
      }
    },
    onError: (error) => {
      showAlert(t('messages.systemError'));
    },
  });

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => {
    if (isLoadingDelete)
      return (
        <div className={styles.loadingIcon}>
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <ButtonComponent
          addClass={classNames(styles.btnConfirm, styles.btnConfirm__cancel)}
          text={t('common.cancel')}
          handleClick={onClickCancel}
        />
        <ButtonComponent
          addClass={classNames(styles.btnConfirm, styles.btnConfirm__ok)}
          text={t('common.ok')}
          handleClick={onClickOk}
        />
      </>
    );
  };

  return (
    <>
      <PopupAreYouSure
        active={confirmDelete}
        handleCancel={() => setConfirmDelete(false)}
        messages={t('messages.areYouSureDeleteThisBonusLevel') + getLevelName(idLevel) + ' ?'}
        footer={
          <FooterPopupConfirm onClickCancel={() => setConfirmDelete(false)} onClickOk={() => deleteBonusLevel()} />
        }
      />
      <Grid container className="content">
        <Grid container className="pt-0">
          <Grid item pb={2.5} xs sm md lg xl className="header-left color-text">
            {t('masterData.Candidate.masterDataCandidate')}
          </Grid>
        </Grid>
        {dataLevel?.map((item, index) => {
          const bonus = dataBonus?.find((bonusLevel) => bonusLevel.level_id === item.id);
          if (!bonus)
            return (
              <Grid container className="box-info" key={index}>
                <Grid item md={10} lg={10} xl={10} className="title-box text-title color-text">
                  {getLevelName(item?.id)}
                </Grid>
                <Grid item xs sm md lg xl className={styles.groupIcon}>
                  <EditLayout permissionName={COMMISSION_PERMISSION}>
                    <div className="edit-delete-button" onClick={() => openAddModal(item)}>
                      <IoIosAdd />
                    </div>
                  </EditLayout>
                </Grid>
              </Grid>
            );
          else
            return (
              <>
                <Grid container className="box-info" key={index}>
                  <Grid item md={10} lg={10} xl={10} className="title-box text-title color-text">
                    {getLevelName(item?.id)}
                  </Grid>

                  <Grid item xs sm md lg xl className={styles.groupIcon}>
                    <EditLayout permissionName={COMMISSION_PERMISSION}>
                      <div className="circle-delete-button color-button" onClick={() => openEditModal(bonus)}>
                        <HiPencil />
                      </div>
                    </EditLayout>
                    <DeleteLayout permissionName={COMMISSION_PERMISSION}>
                      <div className="circle-delete-button color-button" onClick={() => openPopupConfirmDelete(bonus)}>
                        <MdDelete />
                      </div>
                    </DeleteLayout>
                  </Grid>
                  <Grid item md={12} lg={12} xl={12} className="border-bottom-detail"></Grid>
                  <Grid item md={12} lg={12} xl={12} className="table-area color-text">
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: 'none',
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell className="text-table-title w-150">{t('masterData.Candidate.timeOrder')}</TableCell>
                        <TableCell className="text-table-title w-300">{t('masterData.Candidate.bonuses')}</TableCell>
                        <TableCell className="text-table-title">{t('masterData.Candidate.time')}</TableCell>
                      </TableRow>
                      {getBonusTable(bonus.milestone)}
                    </Table>
                  </Grid>
                </Grid>
              </>
            );
        })}
        {isLoadingDataBonus && <Loading addClass="mt-10 loading" />}

        {isShowModal && (
          <CreateOrUpdateBonus
            setShowModal={setShowModal}
            isShowModal={isShowModal}
            textModal={textModal}
            formAdd={formAdd}
            dataBonus={dataBonus}
            queryClient={queryClient}
            setMessage={setMessage}
            setShowAlertCreate={setShowAlertCreate}
            setShowAlertUpdate={setShowAlertUpdate}
            uDataLevel={uDataLevel}
          />
        )}
        <AlertComponent toggle={() => setShowAlertUpdate(false)} isShowAlert={isShowAlertUpdate}>
          <p>{message}</p>
        </AlertComponent>
        <AlertComponent toggle={() => setShowAlertCreate(false)} isShowAlert={isShowAlertCreate}>
          {message}
        </AlertComponent>
        <AlertComponent toggle={() => setShowAlertDelete(false)} isShowAlert={isShowAlertDelete}>
          {message}
        </AlertComponent>
      </Grid>
    </>
  );
}

export default Candidate;
