import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiPencil } from 'react-icons/hi';
import { useQueryClient } from 'react-query';

import { Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import iconInfo from 'assets/img/icons/info.png';

import AddApplyModal from './AddApplyModal.js';
import EditCandidateModal from './EditCandidateModal.js';
import EditApplyModal from './EditApplyModal.js';

import './styles.scss';
import InfoCandidate from './components/InfoCandidate';
import ListApplyJobOrder from './components/ListApplyJobOrder';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import { permissions } from 'components/Permission/Index';
import ModalRefuse from 'components/ModalRefuse';

import { setDataAllModalWithStatus } from 'helpers/modal';

import { useInforAuth } from 'hook/useAuth';
import { useGetListSchool, useGetDetailCandidate, useGetListSourceCV, useGetListLanguage } from 'hook/useManagerCandidate';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';
import { useGetListDivision } from 'hook/useDivision';

import {
  ACTIVE_LIST_APPLIES,
  STATUS_CANDIDATE,
  TYPE_CANDIDATE_HR,
  USE_GET_DETAIL_CANDIDATE,
} from 'constants/ManagerCandidate';

import { CANDIDATE_PERMISSION } from 'constants/permission';
import EditLayout from 'components/PermissionPage/EditLayout';
import NotAcceptPermission from 'views/pages/NotAcceptPermission.js';
import { HTTP_NOT_ACCESS } from 'constants/StatusCode.js';

function HrDetailCandidate() {
  const { t } = useTranslation();
  const { candidateId } = useParams();
  const queryClient = useQueryClient();

  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [apply, setApply] = useState({});
  const [isShowModal, setShowModal] = useState({ edit_candidate: false, edit_apply: false, add_apply: false });
  const [checkApplyActive, setCheckApplyActive] = useState(false);
  const [confirmEndProcess, setConfirmEndProcess] = useState(false);
  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();
  const { data: uDataListSchool } = useGetListSchool();
  const { data: uDataListLanguage } = useGetListLanguage();
  const { data: uDataListSourceCV } = useGetListSourceCV();
  const { data: uListDivision } = useGetListDivision();
  const { permission, data: auth } = useInforAuth();

  const { data: uDetailCandidate, isLoading: isLoadingDetailCandidate } = useGetDetailCandidate({
    id: candidateId,
    type: TYPE_CANDIDATE_HR,
  });

  const { notAllowPermissionUpdate } = useMemo(() => permissions(permission, 'candidate_permission'), [permission]);

  useEffect(() => {
    setCheckApplyActive(false);
    if (uDetailCandidate?.list_applies?.length === 0) {
      return;
    }
    uDetailCandidate?.list_applies?.forEach((element) => {
      if (element.is_active === ACTIVE_LIST_APPLIES) {
        setCheckApplyActive(true);
      }
    });
  }, [uDetailCandidate]);

  useEffect(() => {
    if (uDataLevel) setDataLevel(setDataAllModalWithStatus(uDataLevel));
  }, [uDataLevel]);

  useEffect(() => {
    if (uDataSkill) setDataSkill(setDataAllModalWithStatus(uDataSkill));
  }, [uDataSkill]);

  useEffect(() => {
    if (uDataPosition) setDataPosition(setDataAllModalWithStatus(uDataPosition));
  }, [uDataPosition]);

  if (!isLoadingDetailCandidate && uDetailCandidate?.status === HTTP_NOT_ACCESS) return <NotAcceptPermission />;

  return (
    <div>
      <Grid container className="detail-candidate ">
        <Grid item container className="header-detail">
          <Grid item className="header-back-to-list header-left" md={6}>
            <NavLink
              to={{
                pathname: '/admin/candidate-management',
              }}
              className={'link-back'}
            >
              <ArrowBackIosIcon />
            </NavLink>
            {t('managerCandidate.detail')}
          </Grid>
          {!checkApplyActive && uDetailCandidate && (
            <Grid item md={6}>
              <div className="button-add-info-apply">
                <EditLayout permissionName={CANDIDATE_PERMISSION}>
                  <ButtonComponent
                    handleClick={() =>
                      setShowModal((prev) => {
                        return { ...prev, add_apply: true };
                      })
                    }
                    hasDisabled={notAllowPermissionUpdate}
                    addClass="button-add-menu-right"
                    text={t('managerCandidate.addInfoApply')}
                  />
                </EditLayout>
              </div>
            </Grid>
          )}
          {checkApplyActive && uDetailCandidate && (
            <Grid item md={6}>
              <div className="button-add-info-apply">
                <EditLayout permissionName={CANDIDATE_PERMISSION}>
                  <ButtonComponent
                    handleClick={() => setConfirmEndProcess(true)}
                    hasDisabled={notAllowPermissionUpdate}
                    addClass="button-add-menu-right"
                    text={t('managerCandidate.endProcessRecruit')}
                  />
                </EditLayout>
              </div>
            </Grid>
          )}
        </Grid>
        <ModalRefuse
          title={t('managerCandidate.reasonRejectEndProcess')}
          label={t('managerCandidate.reasonForTheRefusalEndProcess')}
          isShowModal={confirmEndProcess}
          setIsShowModal={setConfirmEndProcess}
          listApply={{ id: uDetailCandidate?.list_applies?.at(0).id }}
          status={STATUS_CANDIDATE.END_PROCESS_RECRUIT}
          required
          confirmMessage={t('managerCandidate.confirmSuccess')}
          onSuccess={() => {
            queryClient.invalidateQueries([USE_GET_DETAIL_CANDIDATE], { refetchActive: true });
          }}
        />
        <Grid item container className="header-detail text-info-main d-flex">
          <Grid item md={11}>
            <img alt={iconInfo} src={iconInfo} className="pr-2" />
            {t('managerCandidate.infoCandidate')}
          </Grid>
          <Grid item className="edit-candidate-button color-silver position-button-edit-recruit">
            <EditLayout permissionName={CANDIDATE_PERMISSION}>
              <HiPencil
                onClick={() => {
                  if (!notAllowPermissionUpdate) {
                    setShowModal((prev) => {
                      return { ...prev, edit_candidate: true };
                    });
                  }
                }}
              />
            </EditLayout>
          </Grid>
        </Grid>

        <Grid item md={12}>
          {!isLoadingDetailCandidate && uDetailCandidate && (
            <InfoCandidate dataDetailCandidate={uDetailCandidate} dataListSchool={uDataListSchool} dataListLanguage={uDataListLanguage}/>
          )}
          {isLoadingDetailCandidate && <Loading />}
        </Grid>

        <Grid item container className="header-detail text-info-main">
          <Grid item md={12}>
            <img alt={iconInfo} src={iconInfo} className="pr-2" />
            {t('managerCandidate.infoApply')}
          </Grid>
        </Grid>

        {!isLoadingDetailCandidate && uDetailCandidate && uDetailCandidate?.list_applies && (
          <ListApplyJobOrder
            listApplyOrder={uDetailCandidate.list_applies}
            candidateId={candidateId}
            dataPosition={dataPosition}
            dataListSourceCV={uDataListSourceCV}
            dataLevel={dataLevel}
            dataSkill={dataSkill}
            dataDivision={uListDivision}
            setApply={setApply}
            setShowModal={setShowModal}
            auth={auth}
            type="hr"
          />
        )}
      </Grid>
      {isShowModal?.edit_candidate && (
        <EditCandidateModal
          dataListSchool={uDataListSchool}
          isShowModal={isShowModal?.edit_candidate}
          setShowModal={setShowModal}
          uDetailCandidate={uDetailCandidate}
        />
      )}
      {isShowModal?.edit_apply && (
        <EditApplyModal
          dataListSchool={uDataListSchool}
          isShowModal={isShowModal?.edit_apply}
          setShowModal={setShowModal}
          uDetailCandidate={uDetailCandidate}
          uDataLevel={uDataLevel}
          uDataSkill={uDataSkill}
          uDataPosition={uDataPosition}
          uDataListSourceCV={uDataListSourceCV}
          apply={apply}
        />
      )}
      {isShowModal?.add_apply && (
        <AddApplyModal
          dataListSchool={uDataListSchool}
          isShowModal={isShowModal?.add_apply}
          setShowModal={setShowModal}
          uDataLevel={uDataLevel}
          uDataSkill={uDataSkill}
          uDataPosition={uDataPosition}
          uDataListSourceCV={uDataListSourceCV}
          uDetailCandidate={uDetailCandidate}
        />
      )}
    </div>
  );
}

export default HrDetailCandidate;
