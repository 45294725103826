import { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { debounce } from '@mui/material';

import './styles.scss';
import Loading from 'components/Loading';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_10 } from 'constants/index.js';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import { handleOnError } from 'helpers/index';

const AutoComplete = ({
  options,
  setFilter,
  label,
  setValueField,
  name,
  total,
  error,
  isLoading,
  setDataUser,
  maxLength,
  required,
  setNamePresenter,
  namePresenter,
  defaultValue,
  register,
  onHandleSelect,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [value, setValue] = useState(defaultValue ?? '');

  const autocompleteRef = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  // eslint-disable-next-line
  const handleChange = useCallback(
    debounce((event) => {
      setFilter({
        page: DEFAULT_PAGE_MUI,
        limit: DEFAULT_LIMIT_10,
        key_word: event.target.value ? event.target.value : '',
        clear_data: true,
      });
    }, 1000),
  );

  const handleSuggestionClick = (suggetion) => {
    onHandleSelect && onHandleSelect();
    setValue(suggetion.user_name);
    setNamePresenter(suggetion.user_name);
    setValueField(name, suggetion.user_id);
    setShowSuggestions(false);
    clearErrors && clearErrors(name);
  };

  const onScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight && total > options.length) {
      setFilter((prev) => {
        return { ...prev, page: prev.page + 1, clear_data: false };
      });
    }
  };

  const handleBlur = (text) => {
    if (!text) {
      setValueField(name, '');
      setNamePresenter('');
    }
    else setValue(namePresenter ?? '');
  };

  return (
    <div className="autoCompleteCustom" ref={autocompleteRef}>
      <div className={`form-field ${error && 'error'}`}>
        <input
          type="text"
          value={value}
          required={required}
          // {...register(name)}
          className={`form-input ${error && 'error'}`}
          maxlength={maxLength}
          onChange={(e) => {
            setValue(e.target.value);
            handleChange(e);
          }}
          onFocus={() => setShowSuggestions(true)}
          class="form-input"
          autocomplete="off"
          placeholder=" "
          onBlur={(e) => handleBlur(e.target.value)}
        />
        <label for="name" className={`form-label ${error && 'error'}`}>
          {label}
          {required && ' *'}
        </label>
      </div>
      {showSuggestions && (
        <ul className="suggestions" onScroll={onScroll}>
          {options.map((suggestion) => (
            <li
              onClick={() => {
                handleSuggestionClick(suggestion);
              }}
              key={suggestion.user_id}
            >
              <div>
                <img className="avatar" src={suggestion.avatar || defaultAvatar} alt="" onError={handleOnError} />
              </div>
              <div>
                <p>{suggestion.user_name}</p>
                <p>{suggestion.email}</p>
              </div>
            </li>
          ))}
          {!isLoading && options.length === 0 && <li className="no-options-label">{t('common.noOptions')}</li>}
          {isLoading && (
            <li className="center">
              <Loading addClass="mt-3 mb-3" />
            </li>
          )}
        </ul>
      )}
      <p className="error-input error">{error && error}</p>
    </div>
  );
};

export default AutoComplete;
