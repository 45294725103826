import React from 'react';
import { useTranslation } from 'react-i18next';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import './styles.scss';
import styles from './styles.module.scss';

import Loading from 'components/Loading';

import { useGetHistoryInterView } from 'hook/useHistoryInterView';
import { useGetListDivision } from 'hook/useDivision';

import { getNameStatus } from 'helpers/index';
import { customDate } from 'helpers/formatDate';

import { LIST_STATUS, STATUS_CANDIDATE } from 'constants/ManagerCandidate';
import { useGetApplyCandidate } from 'hook/useCandidateApply';

const BodyHistory = ({ applyId, type, interviewDate }) => {
  const { t } = useTranslation();

  const { data: uHistoryInterview, isLoading: isLoadingHistory } = useGetHistoryInterView({
    type: type,
    apply_id: applyId,
  });
  const { data: uApplyCandidate } = useGetApplyCandidate(applyId, {
    type: type,
  });
  const { data: uListDivision } = useGetListDivision();

  return (
    <div className={styles.historyContainer}>
      <span className={styles.titleTimeline}>{t('managerCandidate.infoHistoryInterview')}</span>
      {!isLoadingHistory && (
        <Timeline position="left" className="time-line">
          {Array.isArray(uHistoryInterview) &&
            uHistoryInterview.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector>
                    <div className="detail-action">
                      <div>
                        <div className={styles.actionName}>
                          {getNameStatus(LIST_STATUS, item.status, uApplyCandidate.division_id, uListDivision)}
                        </div>
                        <div className={styles.reasonContent}>
                          {item.status === STATUS_CANDIDATE.WAITING_INTERVIEW
                            ? customDate(uApplyCandidate?.interview_date, 'DD/MM/YYYY - HH:mm')
                            : item.refusal_reason}
                        </div>
                      </div>
                      <div className={styles.leaderName}>
                        {item.user_assigned_id
                          ? item.user_assigned_info?.name +
                            '-' +
                            t('managerCandidate.authorityName', { nameLeader: item.user_info?.name })
                          : item.user_info?.name}
                      </div>
                    </div>
                  </TimelineConnector>
                </TimelineSeparator>
                <TimelineContent>{customDate(item.created_at, 'DD/MM/YYYY - HH:mm')}</TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      )}
      {isLoadingHistory && <Loading addClass="mt-3 mb-3" />}
    </div>
  );
};

export default BodyHistory;
