import React from 'react';

import { Dialog } from '@mui/material';
import styles from './styles.module.scss';

const PreviewCV = (props) => {
  const { urlFileCv, open, onClose } = props;

  const getTypeFile = (url) => {
    if (url) {
      let arrDot = url?.split('.');
      return arrDot[arrDot?.length - 1];
    }
    return '';
  };

  const PreviewLayout = ({ url }) => {
    const typeFile = getTypeFile(url);
    if (['jpg', 'jpeg', 'png'].includes(typeFile)) return <img src={url} alt={url} />;
    if (['doc', 'docx', 'xlsx'].includes(typeFile))
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}&amp;embedded=true`}
          title="previewCV"
          className={styles.iframePreview}
        />
      );
    return <iframe src={url} title="previewCV" className={styles.iframePreview} />;
  };

  return (
    <Dialog open={open} onClose={onClose} className={styles.previewContainer}>
      <PreviewLayout url={urlFileCv} />
    </Dialog>
  );
};

export default PreviewCV;
