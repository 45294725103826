import React from 'react';
import styled from 'styled-components';
import forbiddenImg from 'assets/img/screenForbidden.svg'

const Background = styled.img`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

const Forbidden = () => {
   return <Background src={forbiddenImg} alt="Forbidden" />;
};

export default Forbidden;
