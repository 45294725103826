import React, { useContext } from 'react';
import { ThemContext } from 'layouts/Admin';
import DetailCandidateLeader from './leader';
import DetailCandidateHr from './hr';

const DetailCandidate = () => {
  const { isLeader, isAssigner } = useContext(ThemContext);
  if (isLeader || isAssigner) return <DetailCandidateLeader />;
  return <DetailCandidateHr />;
};

export default DetailCandidate;
