import React from 'react';
import { useTranslation } from 'react-i18next';

import iconHeader from 'assets/img/icons/dashboard/groupPeople.svg';
import LayoutBlock from '../components/layoutBlock';
import TotalNumberLine from '../components/TotalNumberLine';
import ListTotal from '../components/ListTotal';

import { LIST_STATUS_ORDER } from 'constants/ManagerOrder';
import { sumValueOfArrayObject } from 'helpers/index';
import { useGetStatisticsByOrder } from 'hook/useDashboard';

import Loading from 'components/Loading';
import { ZERO } from 'constants/MasterData';

const StatisticsByOrder = ({ filterDate }) => {
  const { t } = useTranslation();

  const { data: dataStatisticsOrder, isLoading } = useGetStatisticsByOrder(filterDate);

  const formatFieldKeyList = (list) => {
    let newList = LIST_STATUS_ORDER.map((statusOrder) => {
      const obj = list.find((item) => item.status === statusOrder.id);
      return { ...statusOrder, name: t(statusOrder.name), value: obj ? obj.value : ZERO };
    });
    return newList;
  };

  return (
    <LayoutBlock title={t('dashboard.statisticsByOrder')} icon={iconHeader}>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <TotalNumberLine text={t('common.total')} number={sumValueOfArrayObject(dataStatisticsOrder, 'value')} />
          <ListTotal list={formatFieldKeyList(dataStatisticsOrder)} spacing={3} />
        </>
      )}
    </LayoutBlock>
  );
};

export default StatisticsByOrder;
