import { ALL } from 'constants/index';
import { t } from 'i18next';
import { confirmAlert } from 'react-confirm-alert';

export const handleCheckAll = (arrayStatus, index) => {
  let cloneArray = arrayStatus.map((item) => ({ ...item }));
  if (index === 0) {
    const isCheckAll = !cloneArray[0].status;
    cloneArray.forEach((item) => (item.status = isCheckAll));
    return cloneArray;
  }

  let countTrue = 0;
  cloneArray.forEach(function (item, i) {
    if (index === i) {
      item.status = !item.status;
    }
    if (item.status) {
      ++countTrue;
    }
    if (!item.status && countTrue) {
      --countTrue;
    }
  });
  if (countTrue === cloneArray.length - 1) {
    cloneArray[0].status = true;
  } else {
    cloneArray[0].status = false;
  }
  return cloneArray;
};

export const changNameLevel = (dataLevel) => {
  const nameLevel = dataLevel
    .map((item, i) => (item.status && item.value !== ALL ? (i !== 1 ? ' ' : '') + item.name : ''))
    .filter((it) => it);
  if (!dataLevel[0]?.status && nameLevel.length > 1) return nameLevel.length + t('managerJob.levels');
  if (!dataLevel[0]?.status && nameLevel.length === 1) return nameLevel;
  if (!dataLevel[0]?.status && nameLevel.length === 0) return '';
  if (dataLevel[0]?.status) return t('managerJob.all');
};

export const handleCheck = (items, index) => {
  items.forEach(function (item, i) {
    if (index === i) {
      item.status = !item.status;
    } else {
      item.status = false;
    }
  });

  return [...items];
};

export const getValueInputSearchAll = (arrFilerStatus) => {
  return arrFilerStatus
    .map((item, index) => (item.status && item.value !== ALL ? (index !== 1 ? ' ' : '') + item.name : ''))
    .filter((it) => it);
};

export const setDataAllModalWithStatus = (dataIdName) => {
  let data = [{ id: undefined, name: t('common.all'), status: false, value: ALL }];
  if (dataIdName && dataIdName?.length) {
    dataIdName.forEach((item) =>
      data.push({
        id: item.id,
        name: item.name,
        status: false,
      }),
    );
  }
  return data;
};

export const showAlert = (message) => {
  confirmAlert({
    title: message,
    buttons: [
      {
        label: t('common.done'),
        className: 'btn-alert-ok',
      },
    ],
  });
};

export const showAlertError = (message) => {
  confirmAlert({
    title: message,
    buttons: [
      {
        label: t('common.ok'),
        className: 'btn-alert-ok',
      },
    ],
  });
};
