import React from 'react';
import { Grid } from '@mui/material';

import './styles.scss';
import styles from './styles.module.scss';

import squareIcon from 'assets/img/icons/candidate/square.svg';
import { useTranslation } from 'react-i18next';
import DemoRadar from './chartRada';

const AverageRating = ({ list_stats }) => {
  const { t } = useTranslation();
  const dataScore = list_stats?.data;
  const CountAvgScore = () => {
    let sumScoreAvg = 0;
    let countScoreAvg = 0;
    for (let e = 0; e < list_stats?.data.length; e++) {
      const element = list_stats?.data[e];
      if (element.type === 'score_avg') {
        const sumType = element.score * element.coefficient;
        sumScoreAvg += sumType;
        countScoreAvg += element.coefficient;
      }
    }
    const CountAvgScoreStandard = () => {
      let sumScoreAvg = 0;
      let countScoreAvg = 0;
      for (let e = 0; e < list_stats?.data.length; e++) {
        const element = list_stats?.data[e];
        if (element.type === 'score_stand') {
          const sumType = element.score * element.coefficient;
          sumScoreAvg += sumType;
          countScoreAvg += element.coefficient;
        }
      }
      const result = sumScoreAvg / countScoreAvg;
      return <span> {result.toFixed(1)}</span>;
    };
    const result = sumScoreAvg / countScoreAvg;
    return (
      <Grid container className={styles.contentScore}>
        <div className="div-ratting-index">
          <span className={styles.avgScore}>{t('managerCandidate.avgPoint')}</span>
        </div>
        <div className={styles.ScoreIndex}>
          <span className={styles.avgScoreRating}>{result.toFixed(1)}</span>
          <span className={styles.avgScoreStand}>
            /<CountAvgScoreStandard />
          </span>
        </div>
      </Grid>
    );
  };

  if (!dataScore?.length) {
    return <></>;
  }

  return (
    <Grid className={styles.containerRatting}>
      <div className={styles.titleHeader}>{t('managerCandidate.avgRatting')}</div>
      <div>
        <DemoRadar list_stats={dataScore} />
      </div>
      {dataScore &&
        dataScore.map((item, index) => {
          if (index % 2 === 0)
            return (
              <Grid container className="div-content-average">
                <Grid item xs={10} className="div-ratting-index">
                  <img alt="icon" className="icon-square" src={squareIcon} />
                  <span> {item.name}</span>
                </Grid>
                <Grid item xs={2} className="div-score-index">
                  <span className="span-score-candidate-avg">{item.score.toFixed(1)}</span>
                  <span className="span-score-index-stand">/{dataScore.at(index + 1).score}</span>
                </Grid>
              </Grid>
            );
          return null;
        })}
      <CountAvgScore />
    </Grid>
  );
};
export default AverageRating;
