import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import iconCvDefault from 'assets/img/icons/cv-default.svg';
import iconDownload from 'assets/img/icons/download.png';
import iconPreview from 'assets/img/icons/candidate/preview-icon.svg';

import styles from './styles.module.scss';
import { apiDownloadCV } from 'api/managerCandidate';

import { useListLevel, useListPosition, useListSkill } from 'hook/useMasterData';
import { useGetListSourceCV, useGetListSchool, useGetListLanguage } from 'hook/useManagerCandidate';

import Loading from 'components/Loading';

import { MALE, FEMALE, LIST_YEAR_EXPERIENCE } from 'constants/ManagerCandidate';

import { getNameFile, getValidHttpsUrl, getText, getCandidateLanguages } from 'helpers/index';
import { customDate } from 'helpers/formatDate';
import storageFC from 'helpers/storage';
import { getPositionName, getLevelName, getSkillName, getTextFreelancer } from 'helpers/masterData';
import { getTitleSourceCVText, getNameSourceCVOther, getNameSourceCV } from 'helpers/manageCandidate';
import PreviewCV from '../../components/PreviewCV';

const BaseInformation = ({ uApplyCandidate, isLoadingApplyCandidate }) => {
  const { t } = useTranslation();
  const [urlFilePreview, setUrlFilePreview] = useState(null);

  const { data: uDataPosition } = useListPosition();
  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataListSourceCV } = useGetListSourceCV();
  const { data: uDataListSchool } = useGetListSchool();
  const { data: uDataListLanguage } = useGetListLanguage();

  const getExt = (str) => {
    if (str) {
      let arr = str?.split('.');
      return arr[arr?.length - 1];
    }
    return '';
  };

  const downloadCV = (linkCV, name) => {
    fetch(apiDownloadCV(linkCV), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${storageFC.getToken()}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const RenderCvOffline = ({ cv }) => {
    return (
      <Grid container className={styles.renderCvContainer}>
        <Grid className="contentCV">
          <div className="fixParent iconCV-container">
            <img src={iconCvDefault} alt="pdf" width="70" height="70" className="pdfThumb" />
            <div className="fix"></div>
            <div className="fixBottom"></div>
          </div>
          <div className="infoCV-container">
            <div className="profileContentInfo md-12 cv-text-name">{getNameFile(JSON.parse(cv)?.name)}</div>
            <div className="profileContentInfo sub-description-cv">
              {getExt(JSON.parse(cv)?.name).toUpperCase()} {JSON.parse(cv)?.size + ' kB'}
            </div>
            <div className={styles.groupButtonCV}>
              <div className="editDeleteCV">
                <img
                  className="icon-download"
                  alt={iconDownload}
                  src={iconDownload}
                  onClick={() => downloadCV(JSON.parse(cv)?.url, JSON.parse(cv)?.name)}
                />
              </div>
              {getExt(JSON.parse(cv)?.name) !== 'csv' && (
                <div className={styles.previewButton}>
                  <img
                    className="icon-download"
                    alt={iconPreview}
                    src={iconPreview}
                    onClick={() => setUrlFilePreview(JSON.parse(cv)?.url)}
                  />
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  const getName = (listItem, value) => {
    if (value) {
      return listItem?.find((item) => item?.id === value)?.name;
    }
    return t('common.notDataContract');
  };

  const getTextGender = () => {
    if (uApplyCandidate?.sex === MALE) {
      return t('managerCandidate.male');
    }
    if (uApplyCandidate?.sex === FEMALE) {
      return t('managerCandidate.female');
    }
    return t('managerCandidate.sexThird');
  };

  return (
    <Grid container spacing={1} className={styles.containerBaseInfo}>
      <Grid className={styles.containerInfo}>
        <div className={styles.titleHeader}>{t('managerCandidate.inforBasic')}</div>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <span className={styles.titleItem}>{t('common.fullName')}</span>
        <span className={styles.contentItem}>{uApplyCandidate?.full_name}</span>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <span className={styles.titleItem}>{t('common.birthday')}</span>
        <span className={styles.contentItem}>
          {uApplyCandidate?.birthday ? customDate(uApplyCandidate.birthday, 'DD/MM/YYYY') : t('common.notDataContract')}
        </span>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <span className={styles.titleItem}>{t('managerCandidate.email')}</span>
        <span className={styles.contentItemOverflow}>{uApplyCandidate?.email}</span>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <span className={styles.titleItem}>{t('managerCandidate.phoneNumber')}</span>
        <span className={styles.contentItem}>{uApplyCandidate?.mobile}</span>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <span className={styles.titleItem}>{t('common.sex')}</span>
        <span className={styles.contentItem}>{getTextGender()}</span>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <span className={styles.titleItem}>{t('managerCandidate.school')}</span>
        <span className={styles.contentItem}>{getName(uDataListSchool, uApplyCandidate?.school)}</span>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <span className={styles.titleItem}>{t('managerCandidate.candidateType')}</span>
        <span className={styles.contentItem}>{getTextFreelancer(uApplyCandidate?.is_freelancer, t('managerCandidate.candidateFreelancer'), t('managerCandidate.candidateNotFreelancer'))}</span>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <span className={styles.titleItem}>{t('managerCandidate.language')}</span>
        <span className={styles.contentItem}>{getCandidateLanguages(uApplyCandidate?.candidate_languages, uDataListLanguage)}</span>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <span className={styles.titleItem}>{t('managerCandidate.note')}</span>
        <span className={styles.contentItem}>{getText(uApplyCandidate?.note)}</span>
      </Grid>
      <Grid md={12}>
        <hr />
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <div className={styles.titleItem}>{t('managerCandidate.position')}</div>
        <div className={styles.contentItem}>
          {uApplyCandidate?.position_id
            ? getPositionName(uApplyCandidate?.position_id, uDataPosition)
            : t('common.notDataContract')}
        </div>
      </Grid>
      {!!uApplyCandidate?.skill_id && (
        <Grid className={styles.containerInfo} md={12} lg={12}>
          <div className={styles.titleItem}>{t('managerCandidate.skill')}</div>
          <div className={styles.contentItem}>{getSkillName(uApplyCandidate?.skill_id, uDataSkill)}</div>
        </Grid>
      )}

      <Grid className={styles.containerInfo} md={12} lg={12}>
        <div className={styles.titleItem}>{t('managerCandidate.applyLevel')}</div>
        <div className={styles.contentItem}>
          {uApplyCandidate?.level_id
            ? getLevelName(uApplyCandidate?.level_id, uDataLevel)
            : t('common.notDataContract')}
        </div>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <div className={styles.titleItem}>{t('managerCandidate.experience')}</div>
        <div className={styles.contentItem}>{getName(LIST_YEAR_EXPERIENCE, uApplyCandidate?.experience)}</div>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <div className={styles.titleItem}>{t('managerCandidate.sourceCv')}</div>
        <div className={styles.contentItem}> {getNameSourceCV(uDataListSourceCV, uApplyCandidate?.source_cv)}</div>
      </Grid>
      {getTitleSourceCVText(uDataListSourceCV, uApplyCandidate) && (
        <Grid className={styles.containerInfo} md={12} lg={12}>
          <div className={styles.titleItem}>{getTitleSourceCVText(uDataListSourceCV, uApplyCandidate)}</div>
          <div className={styles.contentItem}>{getNameSourceCVOther(uApplyCandidate)}</div>
        </Grid>
      )}

      <Grid className={styles.containerInfo} md={12} lg={12}>
        <div className={styles.titleItem}>{t('managerCandidate.dayApply')}</div>
        <div className={styles.contentItem}>
          {uApplyCandidate?.apply_date
            ? customDate(uApplyCandidate?.apply_date, 'DD/MM/YYYY')
            : t('common.notDataContract')}
        </div>
      </Grid>
      <Grid className={styles.containerInfo} md={12} lg={12}>
        <div className={styles.titleItem}>{t('managerCandidate.linkCVOnl')}</div>
        <span className={styles.contentItemOverflow}>
          {uApplyCandidate?.link_cv_online && (
            <a href={getValidHttpsUrl(uApplyCandidate.link_cv_online)} target="_blank" rel="noreferrer">
              {uApplyCandidate.link_cv_online}
            </a>
          )}
        </span>
      </Grid>
      {!isLoadingApplyCandidate && uApplyCandidate?.cv_offline_info && (
        <>
          <RenderCvOffline cv={uApplyCandidate.cv_offline_info} />
          <PreviewCV open={!!urlFilePreview} onClose={() => setUrlFilePreview(null)} urlFileCv={urlFilePreview} />
        </>
      )}
      {isLoadingApplyCandidate && <Loading />}
    </Grid>
  );
};

export default BaseInformation;
