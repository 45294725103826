import { useQuery } from "react-query";

import { apiGetListJD } from "api/managerJD";
import * as Constants from './../constants/ManagerJD';
import { HTTP_OK } from 'constants/StatusCode';

export function useGetListJD(params) {
  const { data: response, error, isLoading } = useQuery([Constants.USE_LIST_JD, params], async () => {
    const response = await apiGetListJD(params);
    if (response.status === HTTP_OK) {
        return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading};
}
