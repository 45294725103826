import React from 'react';

import { TYPE_CANDIDATE_HR } from 'constants/ManagerCandidate';
import BodyHistory from './BodyHistory';

const HrHistory = ({ applyId }) => {
  return (
    <div>
      <BodyHistory applyId={applyId} type={TYPE_CANDIDATE_HR} />
    </div>
  );
};

export default HrHistory;
