import React from 'react';
import moment from 'moment';

import { LIST_YEAR_EXPERIENCE } from 'constants/ManagerCandidate';

import { getClassBox } from 'helpers/manageJob';
import { getTextStatus } from 'helpers/manageJob';
import { formatSalary, formatNullToZero } from 'helpers/format';
import { getLevelName, getTextFreelancer } from 'helpers/masterData';
import { useListLevel } from 'hook/useMasterData';

const InfoGeneral = ({ data, t, mapPosition, mapSkill, mapLevel }) => {
  const timeActiveBonus = () => {
    return data?.start_date && data?.end_date
      ? `${moment(data?.start_date).format('DD/MM/YYYY')} - ${moment(data?.end_date).format('DD/MM/YYYY')}`
      : '';
  };

  const getExperience = (id) => {
    return LIST_YEAR_EXPERIENCE.find((item) => item.id === id)?.name;
  };
  const { data: uDataLevel } = useListLevel();
  return (
    <div className="info-general">
      <div className="item">
        <span>{t('managerJob.positionApply')}</span>
        <span>{mapPosition?.[data?.position_id]}</span>
      </div>
      <div className="item">
        <span>{t('common.isFreelancer')}</span>
        <span>{getTextFreelancer(data?.is_freelancer, t('common.yesEN'), t('common.noEN'))}</span>
      </div>
      <div className="item">
        <span>{t('managerJob.levelApply')}</span>
        <span>{data?.level_id?.map((val) => getLevelName(val, uDataLevel)).join(', ')}</span>
      </div>
      <div className="item">
        <span>{t('managerJob.skill')}</span>
        <span>{mapSkill?.[data?.skill_id]}</span>
      </div>
      <div className="item">
        <span>{t('managerJob.experience')}</span>
        <span>{getExperience(data?.experience)}</span>
      </div>
      <div className="item">
        <span>{t('managerJob.maxSalary')}</span>
        <span>{formatSalary(data?.max_salary)}</span>
      </div>
      <div className="item">
        <span>{t('managerJob.quantityApplied')}</span>
        <span>{formatNullToZero(data?.total_recruited) + '/' + formatNullToZero(data?.total_target)}</span>
      </div>
      <div className="item">
        <span>{t('managerJob.status')}</span>
        <span className={getClassBox(data?.status)}>{getTextStatus(t, data?.status)}</span>
      </div>
      <div className="item">
        <span>{t('managerJob.bonusCandidate')}</span>
        <span>{data?.bonus}</span>
      </div>
      <div className="item">
        <span>{t('managerJob.timeActiveBonus')}</span>
        <span>{timeActiveBonus()}</span>
      </div>
      <div className="item">
        <span>{t('managerJob.description')}</span>
        <span className="text-break">{data?.description}</span>
      </div>
    </div>
  );
};

export default InfoGeneral;
