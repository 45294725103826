import { Route, Switch } from 'react-router-dom';
import CreatePosition from './CreatePosition';
import ManagerDecentralization from './ManagerDecentralization';
import ListManagerDecentralization from './ListManagerDecentralization';

const Index = () => {
  return (
    <>
      <Switch>
        <Route path="/admin/master-data/manager-decentralization" exact render={() => <ManagerDecentralization />} />
        <Route
          path="/admin/master-data/manager-decentralization/create-position"
          exact
          render={() => <CreatePosition />}
        />
        <Route
          path="/admin/master-data/manager-decentralization/list-position/:id"
          exact
          render={() => <ListManagerDecentralization />}
        />
      </Switch>
    </>
  );
};

export default Index;
