import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CreateTest from './CreateTest';
import ManagerTest from './ManagerTest';

const index = () => {
  return (
    <>
      <Switch>
        <Route path="/admin/manager-test" exact render={() => <ManagerTest />} />
        <Route path="/admin/manager-test/create" exact render={() => <CreateTest />} />
        <Route path="/admin/manager-test/detail/:id" exact render={() => <CreateTest />} />
      </Switch>
    </>
  );
};

export default index;
