import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { MIN_LENGTH_2 } from 'constants/index.js';

export const YupValidate = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    position_id: Yup.string().required(t('messages.positionRequired')),
    skill_id: Yup.string()
      .nullable()
      .when(['position_id', 'is_skill'], {
        is: (idPosition, isSkill) => idPosition > 0 && isSkill,
        then: Yup.string().required(t('messages.skillRequired')).nullable(),
      }),
    level_id: Yup.string().required(t('messages.levelRequired')),
    list_stats: Yup.array().of(
      Yup.object().shape({
        coefficient: Yup.string()
          .required(t('masterData.StandardIndex.pleaseEnterTheCoefficient'))
          .test('min', t('masterData.StandardIndex.coefficientGreaterThanZero'), (value) => {
            return Number(value) > 0;
          }),
        name: Yup.string()
          .trim()
          .required(t('masterData.StandardIndex.pleaseEnterTheNameStandardIndex'))
          .min(MIN_LENGTH_2, t('messages.pleaseEnterTwoToThirtyCharacters')),
        avg_point: Yup.string().required(t('masterData.StandardIndex.pleaseSelectTheStandardIndexPoint')),
      }),
    ),
  });
  return validationSchema;
};

export default YupValidate;
