import moment from 'moment';
import React, { useEffect, useState } from 'react';

const TimerCountDown = ({ start_time, time_for_test, setTimeEnd, timeNow }) => {
  const [numberTimeEnd, setNumberTimeEnd] = useState(0);

  const timeNowM = timeNow ? moment(timeNow) : moment();
  const [time, setTime] = useState({ hours: '00', minutes: '00', seconds: '00' });
  const [timeEnd] = useState(start_time ? moment(start_time).add(time_for_test, 'minutes') : moment().add(24, 'hours'));

  const getTimeUntil = (time) => {
    const distance = timeEnd.diff(time, 'milliseconds');
    if (distance > 0) {
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setTime({
        ...time,
        hours: hours >= 10 ? hours : '0' + hours,
        minutes: minutes >= 10 ? minutes : '0' + minutes,
        seconds: seconds >= 10 ? seconds : '0' + seconds,
      });
    } else {
      setTime({ hours: '00', minutes: '00', seconds: '00' });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getTimeUntil(timeNowM.add('seconds', 1));
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (timeEnd.diff(moment(), 'milliseconds') <= 0 && numberTimeEnd < 1) {
    setTimeEnd(true);
    setNumberTimeEnd(1);
  }

  return (
    <div className="timer-count-down-testing">
      <span className="time">
        {time.hours}:{time.minutes}:{time.seconds}
      </span>
    </div>
  );
};

export default TimerCountDown;
