import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import React from 'react';
import classNames from 'classnames';
import { ModalBody } from 'reactstrap';

import { IoMdClose } from 'react-icons/io';

import styles from './styles.module.scss';
import './styles.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} direction="up" />;
});

const ModalComponent = (props) => {
  const { children, toggle, isShowModal, classNameAdd,classNameBody } = props;
  return (
    <>
      <Dialog
        open={isShowModal}
        onClose={toggle}
        TransitionComponent={Transition}
        keepMounted
        className={classNames(`${styles.ctDialog} ${classNameAdd && classNameAdd}`)}
      >
        <div className={styles.headModal}>
          <div> {props.title}</div>
          <div className={styles.boxClose} onClick={props.toggle}>
            <div className={styles.iconClose}>
              <IoMdClose />
            </div>
          </div>
        </div>
        <ModalBody className={classNameBody && classNameBody}>{children}</ModalBody>
      </Dialog>
    </>
  );
};

export default ModalComponent;
