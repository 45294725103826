import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import { debounce } from '@material-ui/core';

import { IoIosAdd } from 'react-icons/io';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { useQueryClient, useMutation } from 'react-query';
import classNames from 'classnames';

import styles from './styles.module.scss';
import stylesTable from 'styles/table.module.scss';
import CreateOrUpdateSourceCV from './CreateOrUpdateSourceCV.js';
import { apiDeleteSourceCV } from 'api/masterData';
import { useGetListSourceCV } from 'hook/useMasterData';

import { SOURCE_CV_RECRUIT } from 'constants/permission';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20, PER_PAGE_OPTION_20, MAX_LENGTH_50, LOADING_SM } from 'constants/index.js';
import { ZERO, INDEX_DISABLE_BUTTON_SOURCECV } from 'constants/MasterData';
import * as Constants from 'constants/ManagerCandidate';
import { HTTP_OK } from 'constants/StatusCode.js';

import PopupAreYouSure from 'components/PopupAreYouSure';
import AlertComponent from 'components/AlertAfterSubmit/index.js';
import SearchComponent from 'components/Search';
import ButtonComponent from 'components/Buttons';
import EditLayout from 'components/PermissionPage/EditLayout';
import Loading from 'components/Loading';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';
import AlertAfterSubmit from 'components/AlertAfterSubmit';

import { getSTT, displayLabelTablePagination } from 'helpers/table';
import { showAlert } from 'helpers/modal';

const DEFAULT_FILTER = {
  keyword: null,
  page: DEFAULT_PAGE_MUI,
  per_page: DEFAULT_LIMIT_20,
};

const DEFAULT_FORM_DATA = {
  id: null,
  name: '',
};

function ManagerSourceCV() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [idSourceCV, setIdSourceCV] = useState();
  const [isShowModalAddSourceCV, setShowModalAddSourceCV] = useState(false);
  const [message, setMessage] = useState('');
  const [isShowAlertCreate, setShowAlertCreate] = useState(false);
  const [isShowAlertUpdate, setShowAlertUpdate] = useState(false);
  const [isShowAlertDelete, setShowAlertDelete] = useState(false);
  const [isShowAlertSubmit, setShowAlertSubmit] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [formAdd, setFormAdd] = useState(DEFAULT_FORM_DATA);
  const [textModal, setTextModal] = useState({
    title: t('managerSourceCV.addSourceCV'),
    btn_submit: t('common.add'),
  });
  const { data: dataListSourceCV, isLoading: isLoadingListSourceCV } = useGetListSourceCV({
    ...filter,
    page: filter.page + 1,
  });

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        keyword: value,
        page: DEFAULT_PAGE_MUI,
        per_page: DEFAULT_LIMIT_20,
      });
    }),
  );
  const openAddModal = () => {
    setFormAdd({
      ...formAdd,
      id: null,
      name: '',
    });
    setTextModal({ ...textModal, title: t('managerSourceCV.addSourceCV'), btn_submit: t('common.add') });
    setShowModalAddSourceCV(true);
  };

  const openEditModal = (item) => {
    setFormAdd({
      ...formAdd,
      id: item.id,
      name: item.name,
    });
    setTextModal({ ...textModal, title: t('managerSourceCV.editSourceCV'), btn_submit: t('common.save') });
    setShowModalAddSourceCV(true);
  };

  const openPopupConfirmDelete = (item) => {
    setConfirmDelete(true);
    setIdSourceCV(item.id);
  };

  const { mutate: deleteSourceCV, isLoading: isLoadingDelete } = useMutation(
    () => apiDeleteSourceCV({ id: idSourceCV }),
    {
      onSuccess: (response) => {
        if (response.data.status === HTTP_OK) {
          queryClient.invalidateQueries([Constants.USE_GET_LIST_SOURCE_CV], { refetchActive: true });
          setConfirmDelete(false);
          setShowAlertDelete(true);
          setMessage(t('managerSourceCV.deleteSourceCVSuccess'));
        } else {
          setMessage(response.data.message);
          setConfirmDelete(false);
          setShowAlertSubmit(true);
        }
      },
      onError: (error) => {
        showAlert(t('messages.systemError'));
      },
    },
  );

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => {
    if (isLoadingDelete)
      return (
        <div className={styles.loadingIcon}>
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <ButtonComponent
          addClass={classNames(styles.btnConfirm, styles.btnConfirm__cancel)}
          text={t('common.cancel')}
          handleClick={onClickCancel}
        />
        <ButtonComponent
          addClass={classNames(styles.btnConfirm, styles.btnConfirm__ok)}
          text={t('common.ok')}
          handleClick={onClickOk}
        />
      </>
    );
  };

  const enableButton = (index) => {
    return dataListSourceCV?.data.length - INDEX_DISABLE_BUTTON_SOURCECV > index;
  };

  return (
    <>
      <AlertAfterSubmit
        isShowAlert={isShowAlertSubmit}
        toggle={() => setShowAlertSubmit(false)}
        labelOnOk={t(`common.ok`)}
      >
        <p>{message}</p>
      </AlertAfterSubmit>
      <PopupAreYouSure
        active={confirmDelete}
        handleCancel={() => setConfirmDelete(false)}
        messages={t('managerSourceCV.confirmDeleteSourceCV')}
        footer={<FooterPopupConfirm onClickCancel={() => setConfirmDelete(false)} onClickOk={() => deleteSourceCV()} />}
      />
      <Grid container mb={2}>
        <Grid item xs={8} className={styles.headerTitle}>
          {t('managerSourceCV.listSourceCVManagement')}
        </Grid>
        <Grid container item xs={4} align="end" columnSpacing={2}>
          <Grid item xs={8}>
            <SearchComponent
              addClass={styles.inputSearchBox}
              txtPlaceholder={t('managerSourceCV.nameSourceCV')}
              defaultValue={filter?.keyword}
              handleSearch={handleSearch}
              maxLength={MAX_LENGTH_50}
              size={'small'}
            />
          </Grid>
          <Grid item xs={4}>
            <EditLayout permissionName={SOURCE_CV_RECRUIT}>
              <ButtonComponent
                addClass={styles.buttonAddSourceCV}
                handleClick={() => openAddModal()}
                text={t('common.add')}
                icon={<IoIosAdd />}
              />
            </EditLayout>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={styles.listContainer}>
        <div className="table-border-show">
          <Paper>
            <TableContainer className={stylesTable.tableCommon}>
              <Table aria-label="sticky table">
                <TableHead className="asset-header">
                  <TableRow className="asset-header-row headTable">
                    <TableCell className={styles.colorHeader}>{t('common.STT')}</TableCell>
                    <TableCell className={styles.colorHeader}>{t('managerSourceCV.nameSourceCV')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingListSourceCV && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Loading addClass="mt-3 mb-3" />
                      </TableCell>
                    </TableRow>
                  )}

                  {!isLoadingListSourceCV &&
                    dataListSourceCV?.data.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell className={classNames(stylesTable.sttCell, styles.textSTT, styles.sourceCVIndex)}>
                          {getSTT(index + filter.page * filter.per_page)}
                        </TableCell>
                        <TableCell className={classNames(stylesTable.sttCell, styles.colorText, styles.sourceCVName)}>
                          {item?.name}
                        </TableCell>
                        {enableButton(index) ? (
                          <TableCell>
                            <div className={styles.flexIcon}>
                              <EditLayout permissionName={SOURCE_CV_RECRUIT}>
                                <div className="edit-button mr-15" onClick={() => openEditModal(item)}>
                                  <HiPencil />
                                </div>
                              </EditLayout>
                              <DeleteLayout permissionName={SOURCE_CV_RECRUIT}>
                                <div className="edit-button" onClick={() => openPopupConfirmDelete(item)}>
                                  <MdDelete />
                                </div>
                              </DeleteLayout>
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </TableRow>
                    ))}

                  {(!dataListSourceCV && !isLoadingListSourceCV) ||
                    (dataListSourceCV?.total === 0 && (
                      <tr>
                        <td colSpan={6} className="error-no-data">
                          {t('common.notData')}
                        </td>
                      </tr>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {dataListSourceCV?.total > 0 && (
              <TablePagination
                rowsPerPageOptions={PER_PAGE_OPTION_20}
                component="div"
                count={dataListSourceCV?.total}
                rowsPerPage={filter?.per_page}
                page={filter.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
                labelDisplayedRows={displayLabelTablePagination}
              />
            )}
          </Paper>
        </div>
      </Grid>
      {isShowModalAddSourceCV && (
        <CreateOrUpdateSourceCV
          isShowModal={isShowModalAddSourceCV}
          setShowModal={setShowModalAddSourceCV}
          formAdd={formAdd}
          textModal={textModal}
          queryClient={queryClient}
          setMessage={setMessage}
          setShowAlertCreate={setShowAlertCreate}
          setShowAlertUpdate={setShowAlertUpdate}
        />
      )}
      <AlertComponent toggle={() => setShowAlertUpdate(false)} isShowAlert={isShowAlertUpdate}>
        <p>{message}</p>
      </AlertComponent>
      <AlertComponent toggle={() => setShowAlertCreate(false)} isShowAlert={isShowAlertCreate}>
        {message}
      </AlertComponent>
      <AlertComponent toggle={() => setShowAlertDelete(false)} isShowAlert={isShowAlertDelete}>
        {message}
      </AlertComponent>
    </>
  );
}

export default ManagerSourceCV;
