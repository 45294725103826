import { useQuery } from 'react-query';
import * as Constants from 'constants/ManagerCandidate';
import { HTTP_OK } from 'constants/StatusCode';
import { apiGetListAssess } from 'api/managerCandidate';

export function useGetListAssess(applyId) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_ASSESS, applyId], async () => {
    const response = await apiGetListAssess(applyId);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return { data: response, error, isLoading };
}
