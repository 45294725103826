import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import styles from './styles.module.scss';

import iconTest from 'assets/img/icons/candidate/test.png';

import Loading from 'components/LoadingMemo';

import { LOADING_SM } from 'constants/index';

const StatusTest = ({ txtStatus, isActive = false, isLoading, candidateId, applyId }) => {
  const { t } = useTranslation();

  return (
    <Grid spacing={1} xs={3} className={styles.boxStatusHeader}>
      <div className={isActive ? styles.boxActive : ''}>
        <NavLink
          to={{
            pathname: `/admin/candidate-detail/${candidateId}/apply-detail/${applyId}/test`,
          }}
        >
          <Grid className="border-content-test">
            <Grid container spacing={1} className="styleInfoMui">
              <img className="img-icon" src={iconTest} alt={iconTest} width="16.67px" height="16.67px" />
              <p className="p-content-test">{t('managerCandidate.test')}</p>
            </Grid>
            {<Loading type={LOADING_SM} addClass={styles.statusLoading} isLoading={isLoading} />}
            {!isLoading && <p className="p-content-status">{txtStatus}</p>}
          </Grid>
        </NavLink>
      </div>
    </Grid>
  );
};

export default StatusTest;
