import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  InputBase,
  TablePagination,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import './style.scss';
import iconLoading from 'assets/img/icons/loading.png';
import {
  DEFAULT_PAGE_MUI,
  DEFAULT_LIMIT_12,
  PER_PAGE_OPTION_JOB,
  MAX_LENGTH_50,
  ALL,
  managerJob,
  CODE_DATA_EXISTS,
} from 'constants/index.js';
import {
  STATUS_PROCESSING,
  STATUS_COMPLETED,
  STATUS_CANCELED,
  INFO_GENERAL,
  INFO_ORDER,
  QUANTITY_CANDIDATE,
  IS_FREELANCER,
  IS_NOT_FREELANCER,
} from 'constants/manageJob';
import { JOB_PERMISSION } from 'constants/permission';

import { errorSubmit } from 'helpers';
import { sendPost, sendDelete } from 'helpers/axios';
import { displayLabelTablePagination } from 'helpers/table';
import { formatNullToZero } from 'helpers/format';
import { handleCheckAll, changNameLevel } from 'helpers/modal';

import { useListSkill, useListLevel, useListPosition, useListHr } from 'hook/useMasterData';
import { useGetListJob } from 'hook/useManagerJob';
import { apiDeleteOrderInJob, apiDeleteCandidateInJob } from 'api/managerJob';

import BoxJobItem from './BoxJobItem';
import ModalDetailJob from './ModalDetailJob';
import ModalCreateJob from './ModalCreateJob';
import ModalAddOrder from './ModalAddOrder';
import AddApplies from './AddApplies';

import Loading from 'components/Loading';
import SearchComponent from 'components/Search/index.js';
import ButtonComponent from 'components/Buttons';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import PopupAreYouSure from 'components/PopupAreYouSure';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import EditLayout from 'components/PermissionPage/EditLayout';

const ManagerJob = () => {
  const { t } = useTranslation();

  const STATUS_DEFAULT = [
    {
      id: undefined,
      name: t('common.all'),
      status: false,
      value: ALL,
    },
    {
      id: STATUS_PROCESSING,
      value: STATUS_PROCESSING,
      name: t('common.statusProcessing'),
      status: false,
    },
    {
      id: STATUS_COMPLETED,
      value: STATUS_COMPLETED,
      name: t('common.statusCompleted'),
      status: false,
    },
    {
      id: STATUS_CANCELED,
      value: STATUS_CANCELED,
      name: t('manageKPI.canceled'),
      status: false,
    },
    // {
    //   id: STATUS_LATE_DEADLINE,
    //   value: STATUS_LATE_DEADLINE,
    //   name: t('manageKPI.lateDeadline'),
    //   status: false,
    // },
  ];
  const FREELANCER_DEFAULT = [
    {
      id: IS_NOT_FREELANCER,
      value: IS_NOT_FREELANCER,
      name: t('managerJob.jobNotFreelancer'),
      status: false,
    },
    {
      id: IS_FREELANCER,
      value: IS_FREELANCER,
      name: t('managerJob.jobFreelancer'),
      status: false,
    }
  ];
  const DEFAULT_FILTER = {
    position_id: null,
    skill_id: null,
    level_id: null,
    status: null,
    keyword: null,
    page: DEFAULT_PAGE_MUI,
    limit: DEFAULT_LIMIT_12,
  };
  const [addJob, setAddJob] = useState(false);
  const [tabDetailJob, setTabDetailJob] = useState(INFO_GENERAL);
  const [, setPage] = useState(DEFAULT_PAGE_MUI);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const query = useQueryClient();
  const params = useParams();
  const mapLevel = useRef({});
  const mapSkill = useRef({});
  const mapPosition = useRef({});
  const message = useRef('');
  const [isModals, setIsModals] = useState({ position: false, skill: false, level: false });
  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });
  const { data: uDataLevel } = useListLevel({ key_word: '' });
  const { data: listHr } = useListHr();
  const [dataSkill, setDataSkill] = useState([]);
  const [dataStatus, setDataStatus] = useState(STATUS_DEFAULT);
  const [dataFreelancer, setDataFreelancer] = useState(FREELANCER_DEFAULT);
  const [dataPosition, setDataPosition] = useState([]);
  const [dataLevel, setDataLevel] = useState([]);
  const [dataHrs, setDataHrs] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [createJobError, setCreateJobError] = useState(false);
  const [openDetailJob, setOpenDetailJob] = useState({ active: !!params?.jobId, data: { id: params?.jobId } });
  const [deleteJob, setDeleteJob] = useState({ status: 200, active: false });
  const [editJob, setEditJob] = useState({});
  const [confirmDeleteJob, setConfirmDeleteJob] = useState(false);
  const [openAddJob, setOpenAddJob] = useState(false);
  const [openAddApplies, setOpenAddApplies] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isDeleteOrderSuccess, setIsDeleteOrderSuccess] = useState(false);
  const [openPopupDeleteOrder, setOpenPopupDeleteOrder] = useState(false);
  const [idOrderDelete, setIdOrderDelete] = useState();
  const [isDeleteCandidateSuccess, setIsDeleteCandidateSuccess] = useState(false);
  const [openPopupDeleteCandidate, setOpenPopupDeleteCandidate] = useState(false);
  const [idCandidateDelete, setIdCandidateDelete] = useState();
  const [orderIdAddToJob, setOrderIdAddToJob] = useState();

  const { data, isLoading, total_records, total_canceled, total_complete, total_processing } = useGetListJob({
    ...filter,
    per_page: filter.limit,
    page: filter.page + 1,
  });

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.show_popup_create_job) {
      setOrderIdAddToJob(location?.state?.orderId);
      setAddJob(true);
    }
  }, [location]);

  useEffect(() => {
    if (uDataSkill) {
      const skill = uDataSkill.map((item) => {
        mapSkill.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataSkill([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: ALL,
        },
        ...skill,
      ]);
    }
  }, [uDataSkill, t]);

  useEffect(() => {
    if (uDataPosition) {
      const position = uDataPosition.map((item) => {
        mapPosition.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataPosition([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: ALL,
        },
        ...position,
      ]);
    }
  }, [uDataPosition, t]);

  useEffect(() => {
    if (uDataLevel) {
      const level = uDataLevel.map((item) => {
        mapLevel.current[item.id] = item.name;
        return {
          id: item.id,
          name: item.name,
          status: false,
        };
      });
      setDataLevel([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: ALL,
        },
        ...level,
      ]);
    }
  }, [uDataLevel, t]);

  useEffect(() => {
    if (listHr) {
      const hrs = listHr.map((item) => {
        return {
          id: item.user_id,
          name: item.name,
          status: false,
        };
      });
      setDataHrs([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: ALL,
        },
        ...hrs,
      ]);
    }
  }, [listHr, t]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, limit: +event.target.value });
    setPage(DEFAULT_PAGE_MUI);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSearch = (value) => {
    setFilter({ ...filter, keyword: value, page: DEFAULT_PAGE_MUI, per_page: DEFAULT_LIMIT_12 });
  };
  function handleCheck(items, index) {
    items.forEach(function (item, i) {
      if (index === i) {
        item.status = !item.status;
      } else {
        item.status = false;
      }
    });

    return [...items];
  }
  const changeSelectPosition = (item) => {
    setDataPosition(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectSkill = (item) => {
    setDataSkill(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectStatus = (item) => {
    setDataStatus(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectFreelancer = (item) => {
    setDataFreelancer(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectLevel = (item) => {
    setDataLevel(function (prev) {
      const index = prev.indexOf(item);
      return handleCheckAll(prev, index);
    });
  };

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataSkill?.forEach((item) => {
      item.status = false;
    });
    dataHrs?.forEach((item) => {
      item.status = false;
    });
    dataLevel?.forEach((item) => {
      item.status = false;
    });
    setIsReset(true);
    setFilter({
      position_id: null,
      skill_id: null,
      level_id: null,
      status: null,
      language_id: null,
      page: DEFAULT_PAGE_MUI,
      limit: DEFAULT_LIMIT_12,
      keyword: null,
    });
    setDataPosition([...dataPosition]);
    setDataSkill([...dataSkill]);
    setDataLevel([...dataLevel]);
    setDataHrs([...dataHrs]);
    setDataStatus(STATUS_DEFAULT);
    setDataFreelancer(FREELANCER_DEFAULT);
  };

  const onSubmit = async (data) => {
    let payload = {
      id: data.id,
      user_assigned: data.user_assigned,
      name: data.name,
      level_id: data.level_id,
      position_id: data.position_id,
      skill_id: data.skill_id,
      experience: data.experience,
      max_salary: data.max_salary,
      bonus: data.bonus,
      start_date: data.start_date,
      end_date: data.end_date,
      description: data.description,
      is_freelancer: data.is_freelancer
    };
    if (orderIdAddToJob) payload.order_id = orderIdAddToJob;
    const res = await sendPost(`/api/job/save`, payload);
    if (errorSubmit(res?.data?.code) || res?.data?.status === 500) {
      if (res?.data?.code === CODE_DATA_EXISTS) {
        message.current = t('managerJob.createJobAlreadyExists');
      } else {
        message.current = t(errorSubmit(res?.data?.code));
      }
      setCreateJobError(true);
    } else {
      setOrderIdAddToJob(null);
      setOpenModal(true);
      setAddJob(false);
    }
  };

  const handleCloseDetailJob = (tab) => {
    window.history.replaceState(null, '', '/admin/manager-job');
    setOpenDetailJob({ ...openDetailJob, active: false });
    setTabDetailJob(tab);
  };

  const handleOpenDetailJob = (tab) => {
    setOpenDetailJob({ ...openDetailJob, active: true });
    setTabDetailJob(tab);
  };

  const handleDoneDeleteJob = () => {
    if (deleteJob.status === 200) query.refetchQueries(managerJob.USE_LIST_MANAGER_JOB);
    handleCloseDetailJob(INFO_GENERAL);
    setDeleteJob({ ...deleteJob, active: false });
  };

  const handleDeleteJob = async () => {
    const res = await sendDelete(`/api/job/delete`, { id: openDetailJob.data.id });
    setConfirmDeleteJob(false);
    setDeleteJob({ status: res.data.status, active: true });
  };

  const handleClickCancelPopupDeleteJob = () => {
    setConfirmDeleteJob(false);
    handleOpenDetailJob(tabDetailJob);
  };

  const { mutate: handleDeleteOrder } = useMutation(
    async () => {
      await apiDeleteOrderInJob({ id: idOrderDelete });
    },
    {
      onSuccess: async () => {
        setOpenPopupDeleteOrder(false);
        setIsDeleteOrderSuccess(true);
        query.invalidateQueries([managerJob.USE_DETAIL_JOB], { refetchActive: true });
        query.invalidateQueries([managerJob.USE_LIST_MANAGER_JOB], { refetchActive: true });
      },
      onError: () => {},
    },
  );

  const { mutate: handleDeleteCandidate } = useMutation(
    async () => {
      await apiDeleteCandidateInJob({ id: idCandidateDelete });
    },
    {
      onSuccess: async () => {
        setOpenPopupDeleteCandidate(false);
        setIsDeleteCandidateSuccess(true);
        query.invalidateQueries([managerJob.USE_DETAIL_JOB], { refetchActive: true });
        query.invalidateQueries([managerJob.USE_LIST_MANAGER_JOB], { refetchActive: true });
      },
      onError: () => {},
    },
  );

  const handleClickCancelPopupDeleteOrder = () => {
    setOpenPopupDeleteOrder(false);
    handleOpenDetailJob(tabDetailJob);
  };

  const handleClickCancelPopupDeleteCandidate = () => {
    setOpenPopupDeleteCandidate(false);
    handleOpenDetailJob(tabDetailJob);
  };

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => (
    <>
      <Button
        sx={{
          fontWeight: 400,
          fontSize: 14,
          lineHeight: `16px`,
          letterSpacing: 1.25,
          textTransform: `uppercase`,
          color: `#8d8d8d`,
        }}
        onClick={onClickCancel}
      >
        {t('managerJob.cancelVN')}
      </Button>
      <Button
        sx={{
          fontWeight: 400,
          fontSize: 14,
          lineHeight: `16px`,
          letterSpacing: 1.25,
          textTransform: `uppercase`,
          color: `#fea628`,
        }}
        onClick={onClickOk}
      >
        {t('managerJob.delete')}
      </Button>
    </>
  );

  const handleClickDoneAlertOrder = () => {
    setIsDeleteOrderSuccess(false);
    handleOpenDetailJob(INFO_ORDER);
  };

  const handleClickDoneAlertCandidate = () => {
    setIsDeleteCandidateSuccess(false);
    handleOpenDetailJob(QUANTITY_CANDIDATE);
  };

  const handleClickDeleteOrder = (id) => {
    setIdOrderDelete(id);
    handleCloseDetailJob(INFO_ORDER);
    setOpenPopupDeleteOrder(true);
  };

  const handleClickDeleteCandidate = (id) => {
    setIdCandidateDelete(id);
    handleCloseDetailJob(QUANTITY_CANDIDATE);
    setOpenPopupDeleteCandidate(true);
  };

  const handleClickDoneAlertEditJob = () => {
    setOpenModal(false);
    if (editJob.id) handleOpenDetailJob(tabDetailJob);
    setAddJob(false);
    setEditJob({});
    query.refetchQueries(managerJob.USE_LIST_MANAGER_JOB);
  };

  return (
    <Grid container className="content manager-job pb-5">
      <AlertAfterSubmit toggle={handleDoneDeleteJob} isShowAlert={deleteJob.active}>
        <p> {t(`managerJob.${deleteJob.status === 200 ? 'deleteJobSuccess' : 'deleteJobError'}`)}</p>
      </AlertAfterSubmit>
      <PopupAreYouSure
        active={confirmDeleteJob}
        handleCancel={() => handleClickCancelPopupDeleteJob()}
        messages={t('managerJob.confirmDeleteJob')}
        footer={
          <FooterPopupConfirm
            onClickCancel={() => handleClickCancelPopupDeleteJob()}
            onClickOk={() => handleDeleteJob()}
          />
        }
      />
      <AlertAfterSubmit toggle={handleClickDoneAlertOrder} isShowAlert={isDeleteOrderSuccess}>
        <p>{t('managerJob.deleteOrderSuccess')}</p>
      </AlertAfterSubmit>
      <PopupAreYouSure
        active={openPopupDeleteOrder}
        handleCancel={() => handleClickCancelPopupDeleteOrder()}
        messages={t('managerJob.confirmDeleteOrder')}
        footer={
          <FooterPopupConfirm
            onClickCancel={() => handleClickCancelPopupDeleteOrder()}
            onClickOk={() => handleDeleteOrder()}
          />
        }
      />
      <AlertAfterSubmit toggle={handleClickDoneAlertCandidate} isShowAlert={isDeleteCandidateSuccess}>
        <p>{t('managerJob.deleteCandidateSuccess')}</p>
      </AlertAfterSubmit>
      <PopupAreYouSure
        active={openPopupDeleteCandidate}
        handleCancel={() => handleClickCancelPopupDeleteCandidate()}
        messages={t('managerJob.confirmDeleteCandidate')}
        footer={
          <FooterPopupConfirm
            onClickCancel={() => handleClickCancelPopupDeleteCandidate()}
            onClickOk={() => handleDeleteCandidate()}
          />
        }
      />
      <AlertAfterSubmit toggle={handleClickDoneAlertEditJob} isShowAlert={openModal}>
        <p>{t(`managerJob.${editJob.id ? 'editJobSuccess' : 'createJobSuccess'}`)}</p>
      </AlertAfterSubmit>
      <Dialog open={createJobError} onClose={() => setCreateJobError(false)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message.current}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn-transparent color-yellow fs-18" onClick={() => setCreateJobError(false)}>
            {t('managerJob.ok')}
          </button>
        </DialogActions>
      </Dialog>
      {openAddApplies && (
        <AddApplies
          setOpenAddApplies={setOpenAddApplies}
          openAddApplies={openAddApplies}
          t={t}
          data={openDetailJob.data}
          mapPosition={mapPosition.current}
          mapLevel={mapLevel.current}
          mapSkill={mapSkill.current}
          useListSkill={uDataSkill}
          useListLevel={uDataLevel}
          useListPosition={uDataPosition}
          query={query}
          handleOpenDetailJob={handleOpenDetailJob}
        />
      )}
      {openAddJob && (
        <ModalAddOrder
          t={t}
          openAddJob={openAddJob}
          setOpenAddJob={setOpenAddJob}
          handleOpenDetailJob={handleOpenDetailJob}
          jobId={openDetailJob.data?.id}
          mapPosition={mapPosition.current}
          mapLevel={mapLevel.current}
          mapSkill={mapSkill.current}
          useListSkill={uDataSkill}
          useListLevel={uDataLevel}
          useListPosition={uDataPosition}
          query={query}
        />
      )}
      {addJob && (
        <ModalCreateJob
          addJob={addJob}
          setAddJob={setAddJob}
          onSubmit={onSubmit}
          useListSkill={uDataSkill}
          useListLevel={uDataLevel}
          useListPosition={uDataPosition}
          editJob={editJob}
          setEditJob={setEditJob}
          setOpenDetailJob={setOpenDetailJob}
          openDetailJob={openDetailJob}
        />
      )}
      {openDetailJob.active && (
        <ModalDetailJob
          openDetailJob={openDetailJob}
          setOpenDetailJob={setOpenDetailJob}
          handleCloseDetailJob={handleCloseDetailJob}
          mapPosition={mapPosition.current}
          mapLevel={mapLevel.current}
          mapSkill={mapSkill.current}
          setDeleteJob={setDeleteJob}
          setEditJob={setEditJob}
          setAddJob={setAddJob}
          setConfirmDeleteJob={setConfirmDeleteJob}
          setOpenAddJob={setOpenAddJob}
          setOpenAddApplies={setOpenAddApplies}
          defaultTab={tabDetailJob}
          handleClickDeleteOrder={handleClickDeleteOrder}
          handleClickDeleteCandidate={handleClickDeleteCandidate}
        />
      )}
      <Grid container className="pt-0">
        <Grid item xs sm md lg xl className="header-left color-text">
          <span className="text-up">{t('managerJob.managerJob')}</span>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2.5}>
        <Grid item xs={10} className="content-component">
          {!isLoading && formatNullToZero(total_records) === 0 && (
            <tr>
              <td colSpan={10} className="error-no-data w-100vw">
                {t('common.notData')}
              </td>
            </tr>
          )}
          <Grid container spacing={2}>
            {isLoading && <Loading addClass="mt-3 mb-3" />}
            {data &&
              data.map((item, index) => (
                <Grid
                  item
                  xs={4}
                  key={index}
                  className="cursor"
                  onClick={() => setOpenDetailJob({ active: true, data: item })}
                >
                  <BoxJobItem
                    item={item}
                    mapPosition={mapPosition.current}
                    mapLevel={mapLevel.current}
                    mapSkill={mapSkill.current}
                  />
                </Grid>
              ))}
          </Grid>

          {formatNullToZero(total_records) > 0 && (
            <TablePagination
              rowsPerPageOptions={PER_PAGE_OPTION_JOB}
              component="div"
              count={total_records}
              rowsPerPage={filter?.limit}
              page={filter.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="table-pagination"
              labelDisplayedRows={displayLabelTablePagination}
              labelRowsPerPage={'Jobs per page'}
            />
          )}
        </Grid>
        <Grid item md={2} className="filter">
          <EditLayout permissionName={JOB_PERMISSION}>
            <ButtonComponent
              addClass="button-add-menu-right"
              icon={<AddIcon />}
              text={t('managerJob.createJob')}
              handleClick={() => setAddJob(true)}
            />
          </EditLayout>
          <div className="info-menu-right-no-image mt-3 total-job">
            <div className="info-menu-righ-title">{t('managerJob.totalJob')}</div>
            <div className="info-menu-righ-total">{formatNullToZero(total_records)}</div>
          </div>
          <div className="info-menu-right-no-image mt-3 charge-of">
            <div className="info-menu-righ-title">{t('managerJob.doing')}</div>
            <div className="info-menu-righ-total">{formatNullToZero(total_processing)}</div>
          </div>
          <div className="info-menu-right-no-image mt-3 done">
            <div className="info-menu-righ-title">{t('managerJob.done')}</div>
            <div className="info-menu-righ-total">{formatNullToZero(total_complete)}</div>
          </div>
          <div className="info-menu-right-no-image selected mt-3 late-deadline">
            <div className="info-menu-righ-title">{t('managerJob.canceled')}</div>
            <div className="info-menu-righ-total">{formatNullToZero(total_canceled)}</div>
          </div>
          {/* <div className="info-menu-right-no-image selected mt-3 late-deadline">
            <div className="info-menu-righ-title">{t('managerJob.lateDeadline')}</div>
            <div className="info-menu-righ-total">{formatNullToZero(total_late_deadline)}</div>
          </div> */}
          <hr />
          <div className="info-menu-right-no-image p-0 mt-3">
            <SearchComponent
              txtPlaceholder={t('managerJob.titelJob')}
              addClass="input-search-job"
              handleSearch={handleSearch}
              maxLength={MAX_LENGTH_50}
              isReset={isReset}
              setIsReset={setIsReset}
            />
          </div>

          <InputBase
            disabled
            type="text"
            name="position_id"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { position: true };
              });
            }}
            value={
              dataPosition &&
              (!dataPosition[0]?.status
                ? dataPosition
                    .map((item, i) => (item.status && item.value !== ALL ? (i !== 1 ? ' ' : '') + item.name : ''))
                    .filter((it) => it)
                : t('managerJob.all'))
            }
            placeholder={t('managerJob.position')}
          />
          <ModalCommon
            items={dataPosition}
            onSubmit={() => {
              let datas = [];
              dataPosition.forEach((it) => {
                it.status && it.id && datas.push(it.id);
              });
              setFilter({
                ...filter,
                position_id: datas,
                page: DEFAULT_PAGE_MUI,
              });
              setIsModals((prev) => {
                return { ...prev, position: false };
              });
            }}
            onClick={changeSelectPosition}
            toggle={() => {
              setIsModals((prev) => {
                return { ...prev, position: !prev.position };
              });
              if (filter?.position_id) {
                dataPosition?.forEach((item) => {
                  Number(filter?.position_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
                });
                setDataPosition([...dataPosition]);
              } else {
                dataPosition?.forEach((item) => {
                  item.status = false;
                });
                setDataPosition([...dataPosition]);
              }
            }}
            modal={isModals.position}
            title={t('managerJob.choosePosition')}
            footer={t('common.select')}
          />

          <InputBase
            disabled
            type="text"
            name="level_id"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { level: true };
              });
            }}
            value={changNameLevel(dataLevel)}
            placeholder={t('managerJob.level')}
          />
          <ModalCommon
            items={dataLevel}
            onSubmit={() => {
              let datas = [];
              dataLevel.forEach((it) => {
                it.status && it.id && datas.push(it.id);
              });
              setFilter({
                ...filter,
                level_id: datas,
                page: DEFAULT_PAGE_MUI,
              });
              setIsModals((prev) => {
                return { ...prev, level: false };
              });
            }}
            onClick={changeSelectLevel}
            toggle={() => {
              setIsModals((prev) => {
                return { ...prev, level: !prev.level };
              });
              if (filter?.level_id) {
                dataLevel?.forEach((item) => {
                  filter?.level_id.includes(item?.id) ? (item.status = true) : (item.status = false);
                });
                setDataLevel([...dataLevel]);
              } else {
                dataLevel?.forEach((item) => {
                  item.status = false;
                });
                setDataLevel([...dataLevel]);
              }
            }}
            modal={isModals.level}
            title={t('managerJob.chooseLevel')}
            footer={t('common.select')}
          />

          <InputBase
            disabled
            type="text"
            name="skill_id"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { skill: true };
              });
            }}
            value={
              dataSkill &&
              (!dataSkill[0]?.status
                ? dataSkill
                    .map((item, i) => (item.status && item.value !== ALL ? (i !== 1 ? ' ' : '') + item.name : ''))
                    .filter((it) => it)
                : t('managerJob.all'))
            }
            placeholder={t('managerJob.skill')}
          />
          <ModalCommon
            items={dataSkill}
            onSubmit={() => {
              let datas = [];
              dataSkill.forEach((it) => {
                it.status && it.id && datas.push(it.id);
              });
              setFilter({
                ...filter,
                skill_id: datas,
                page: DEFAULT_PAGE_MUI,
              });
              setIsModals((prev) => {
                return { ...prev, skill: false };
              });
            }}
            onClick={changeSelectSkill}
            toggle={() => {
              setIsModals((prev) => {
                return { ...prev, skill: !prev.skill };
              });
              if (filter?.skill_id) {
                dataSkill?.forEach((item) => {
                  Number(filter?.skill_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
                });
                setDataSkill([...dataPosition]);
              } else {
                dataSkill?.forEach((item) => {
                  item.status = false;
                });
                setDataSkill([...dataSkill]);
              }
            }}
            modal={isModals.skill}
            title={t('managerJob.chooseSkill')}
            footer={t('common.select')}
          />

          <InputBase
            disabled
            type="text"
            name="status"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { status: true };
              });
            }}
            value={
              dataStatus &&
              (!dataStatus[0]?.status
                ? dataStatus
                    .map((item, i) => (item.status && item.value !== ALL ? (i !== 1 ? ' ' : '') + item.name : ''))
                    .filter((it) => it)
                : t('managerJob.all'))
            }
            placeholder={t('managerJob.status')}
          />
          <ModalCommon
            items={dataStatus}
            onSubmit={() => {
              let datas = [];
              dataStatus.forEach((it) => {
                it.status && it.id && datas.push(it.id);
              });
              setFilter({
                ...filter,
                status: !datas.join() ? null : datas.join(),
                page: DEFAULT_PAGE_MUI,
              });
              setIsModals((prev) => {
                return { ...prev, status: false };
              });
            }}
            onClick={changeSelectStatus}
            toggle={() => {
              setIsModals((prev) => {
                return { ...prev, status: !prev.status };
              });
              if (filter?.status) {
                dataStatus?.forEach((item) => {
                  Number(filter?.status) === Number(item?.id) ? (item.status = true) : (item.status = false);
                });
                setDataStatus([...dataStatus]);
              } else {
                dataStatus?.forEach((item) => {
                  item.status = false;
                });
                setDataStatus([...dataStatus]);
              }
            }}
            modal={isModals.status}
            title={t('managerJob.status')}
            footer={t('common.select')}
          />

          <InputBase
            type="text"
            name="is_freelancer"
            className="input-select-multi mt-3"
            onClick={() => {
              setIsModals(() => {
                return { freelancer: true };
              });
            }}
            value={
              dataFreelancer &&
              (!dataFreelancer[0]?.status
                ? dataFreelancer
                    .map((item, i) => (item.status && item.value !== IS_NOT_FREELANCER ? (i !== 1 ? ' ' : '') + item.name : ''))
                    .filter((it) => it)
                : t('managerJob.jobNotFreelancer'))
            }
            placeholder={t('managerJob.freelancerFilter')}
          />
          <ModalCommon
            items={dataFreelancer}
            onSubmit={() => {
              let datas = [];
              dataFreelancer.forEach((it) => {
                it.status && (it.id || it.id == 0) && datas.push(it.id);
              });
              setFilter({
                ...filter,
                is_freelancer: !datas.join() ? null : datas.join(),
                page: DEFAULT_PAGE_MUI,
              });
              setIsModals((prev) => {
                return { ...prev, freelancer: false };
              });
            }}
            onClick={changeSelectFreelancer}
            toggle={() => {
              setIsModals((prev) => {
                return { ...prev, freelancer: !prev.freelancer };
              });
              if (filter?.is_freelancer) {
                dataFreelancer?.forEach((item) => {
                  Number(filter?.is_freelancer) === Number(item?.id) ? (item.status = true) : (item.status = false);
                });
                setDataFreelancer([...dataFreelancer]);
              } else {
                dataFreelancer?.forEach((item) => {
                  item.status = false;
                });
                setDataFreelancer([...dataFreelancer]);
              }
            }}
            modal={isModals.freelancer}
            title={t('managerJob.jobType')}
            footer={t('common.select')}
          />

          <ButtonBase onClick={handleReset} className="reset mt-3" size="lg">
            <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
            {t('common.reset')}
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManagerJob;
