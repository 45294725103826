import React from 'react';

import './../styles.scss';

import { TYPE_CANDIDATE_LEADER } from 'constants/ManagerCandidate';
import BodyHistory from './BodyHistory';

const LeaderHistory = ({ applyId }) => {
  return (
    <div>
      <BodyHistory applyId={applyId} type={TYPE_CANDIDATE_LEADER} />
    </div>
  );
};

export default LeaderHistory;
