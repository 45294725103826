import { useListStandardIndex } from 'hook/useMasterData';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonBase, Grid, InputBase } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import AddIcon from '@mui/icons-material/Add';

import './styles.scss';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20 } from 'constants/index.js';
import { STANDARD_PERMISSION } from 'constants/permission';

import { ZERO } from 'constants/MasterData';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';
import ButtonComponent from 'components/Buttons';
import * as Constants from 'constants/index.js';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import Loading from 'components/Loading';
import CreateOrUpdateModal from './CreateOrUpdateModal.js';
import DetailModal from './DetailModal';
import { getLevelName, getPositionName, getSkillName, parseJson } from 'helpers/masterData';
import iconLoading from 'assets/img/icons/loading.png';
import { useInforAuth } from 'hook/useAuth';
import { permissions } from 'components/Permission/Index.js';
import { displayLabelTablePagination } from 'helpers/table';
import EditLayout from 'components/PermissionPage/EditLayout';

const StandardIndex = () => {
  const { t } = useTranslation();
  const [isShowModal, setShowModal] = useState(false);
  const [isNewOrUpdateData, setIsNewOrUpdateData] = useState(false);
  const [filter, setFilter] = useState({
    level_id: null,
    position_id: null,
    skill_id: null,
    page: DEFAULT_PAGE_MUI,
    limit: DEFAULT_LIMIT_20,
  });

  const { permission } = useInforAuth();

  const { data: dataStandardIndex, isLoading: isLoadingDataStandardIndex } = useListStandardIndex({
    ...filter,
    page: filter.page + 1,
  });

  useEffect(() => {
    if (dataDetail?.id && isShowModal === false && isNewOrUpdateData) {
      setDataDetail(dataStandardIndex?.list_data.find((item) => item?.id === dataDetail?.id));
      setShowModalDetail(true);
      setIsNewOrUpdateData(false);
    }
    // eslint-disable-next-line
  }, [isNewOrUpdateData, isShowModal]);

  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();

  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);

  const [isModals, setIsModals] = useState({ position: false, skill: false, level: false });

  useEffect(() => {
    if (uDataLevel) {
      const level = uDataLevel.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataLevel([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...level,
      ]);
    }
  }, [uDataLevel, t]);

  useEffect(() => {
    if (uDataSkill) {
      const skill = uDataSkill.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataSkill([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...skill,
      ]);
    }
  }, [uDataSkill, t]);

  useEffect(() => {
    if (uDataPosition) {
      const position = uDataPosition.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));
      setDataPosition([
        {
          id: undefined,
          name: t('common.all'),
          status: false,
          value: Constants.ALL,
        },
        ...position,
      ]);
    }
  }, [uDataPosition, t]);

  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${ZERO}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  const changeSelectPosition = (item) => {
    setDataPosition(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectLevel = (item) => {
    setDataLevel(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  const changeSelectSkill = (item) => {
    setDataSkill(function (prev) {
      const index = prev.indexOf(item);
      return handleCheck(prev, index);
    });
  };

  function handleCheck(items, index) {
    items.forEach(function (item, i) {
      if (index === i) {
        item.status = !item.status;
      } else {
        item.status = false;
      }
    });

    return [...items];
  }

  const getName = (item) => {
    return parseJson(item?.list_stats) !== null
      ? parseJson(item?.list_stats)?.length + ' ' + t('masterData.StandardIndex.index')
      : t('common.notDataContract');
  };

  const openModalCreate = () => {
    setShowModal(true);
    setDataDetail();
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, limit: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const [isShowModalDetail, setShowModalDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState();

  const openModalDetail = (item) => {
    setShowModalDetail(true);
    setDataDetail({ ...item });
  };

  const avgIndex = (data) => {
    let total = 0;
    let totalCoefficient = 0;
    parseJson(data)?.forEach((item) => {
      if (item.avg_point !== null) {
        total += item.avg_point * item.coefficient;
        totalCoefficient += item.coefficient;
      }
    });
    let avgIndex = total / totalCoefficient;
    return avgIndex ? avgIndex.toFixed(1) + ' ' + t('masterData.StandardIndex.point') : 'N/A';
  };

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataSkill?.forEach((item) => {
      item.status = false;
    });
    dataLevel?.forEach((item) => {
      item.status = false;
    });
    setFilter({
      level_id: null,
      position_id: null,
      skill_id: null,
      page: DEFAULT_PAGE_MUI,
      limit: DEFAULT_LIMIT_20,
    });
    setDataPosition([...dataPosition]);
    setDataLevel([...dataLevel]);
    setDataSkill([...dataSkill]);
  };

  const { notAllowPermissionUpdate } = useMemo(() => permissions(permission, 'standard_permission'), [permission]);

  return (
    <Grid container gap={2} className="content-standard-index">
      <Grid item xs={12} className="title-header">
        {t('masterData.StandardIndex.standardIndexManagement')}
      </Grid>
      <Grid item flex={10}>
        <div className="table-border-show">
          <Paper>
            <TableContainer>
              <Table aria-label="sticky table">
                <TableHead className="asset-header">
                  <TableRow className="asset-header-row headTable">
                    <TableCell>{t('common.STT')}</TableCell>
                    <TableCell className="title">{t('masterData.StandardIndex.position')}</TableCell>
                    <TableCell className="title">{t('masterData.StandardIndex.skill')}</TableCell>
                    <TableCell className="title">{t('masterData.StandardIndex.level')}</TableCell>
                    <TableCell className="title">{t('masterData.StandardIndex.appreciateIndex')}</TableCell>
                    <TableCell>{t('masterData.StandardIndex.avgPoint')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingDataStandardIndex && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Loading addClass="mt-3 mb-3" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!isLoadingDataStandardIndex &&
                    dataStandardIndex?.list_data &&
                    dataStandardIndex?.list_data.map((item, index) => {
                      return (
                        <TableRow key={index} onClick={() => openModalDetail(item)}>
                          <TableCell className="table-content id-standard">
                            {getSTT(index + filter.page * filter.limit)}
                          </TableCell>
                          <TableCell className="table-content">
                            {item.position_id
                              ? getPositionName(item?.position_id, dataPosition)
                              : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content">
                            {item.skill_id ? getSkillName(item?.skill_id, dataSkill) : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content">
                            {item.level_id ? getLevelName(item?.level_id, dataLevel) : t('common.notDataContract')}
                          </TableCell>
                          <TableCell className="table-content">{getName(item)}</TableCell>
                          <TableCell className="table-content">{avgIndex(item?.list_stats)}</TableCell>
                        </TableRow>
                      );
                    })}

                  {dataStandardIndex?.total_records === ZERO && (
                    <tr>
                      <td colSpan={9} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {dataStandardIndex?.total_records > ZERO && (
              <TablePagination
                rowsPerPageOptions={Constants.PER_PAGE_OPTION_20}
                component="div"
                count={dataStandardIndex?.total_records}
                rowsPerPage={filter?.limit}
                page={filter.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
                labelDisplayedRows={displayLabelTablePagination}
              />
            )}
          </Paper>
        </div>
      </Grid>
      <Grid item flex={2} className={'filter'}>
        <EditLayout permissionName={STANDARD_PERMISSION}>
          <ButtonComponent
            addClass="button-add-menu-right button-create-standard"
            icon={<AddIcon />}
            text={t('masterData.StandardIndex.createStandardIndex')}
            hasDisabled={notAllowPermissionUpdate}
            handleClick={openModalCreate}
          />
        </EditLayout>
        <div className="info-menu-right-no-image selected">
          <div className="info-menu-righ-title">{t('masterData.StandardIndex.totalIndex')}</div>
          <div className="info-menu-righ-total">{dataStandardIndex?.total_records}</div>
        </div>
        <InputBase
          disabled
          type="text"
          name="position_id"
          className="input-select-multi mt-3"
          onClick={() => {
            setIsModals(() => {
              return { position: true };
            });
          }}
          value={
            dataPosition &&
            (!dataPosition[ZERO]?.status
              ? dataPosition
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('common.all'))
          }
          placeholder={t('masterData.StandardIndex.position')}
        />
        <ModalCommon
          items={dataPosition}
          onSubmit={() => {
            let datas = [];
            dataPosition.forEach((it) => {
              it.status && it.id && datas.push(it.id);
            });

            setFilter({
              ...filter,
              position_id: !datas.join() ? null : datas.join(),
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, position: false };
            });
          }}
          onClick={changeSelectPosition}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, position: !prev.position };
            });
            if (filter?.position_id) {
              dataPosition?.forEach((item) => {
                Number(filter?.position_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
              });
              setDataPosition([...dataPosition]);
            } else {
              dataPosition?.forEach((item) => {
                item.status = false;
              });
              setDataPosition([...dataPosition]);
            }
          }}
          modal={isModals.position}
          title={t('masterData.StandardIndex.choosePosition')}
          footer={t('common.select')}
        />

        <InputBase
          disabled
          type="text"
          name="level_id"
          className="input-select-multi mt-3"
          onClick={() => {
            setIsModals(() => {
              return { level: true };
            });
          }}
          value={
            dataLevel &&
            (!dataLevel[ZERO]?.status
              ? dataLevel
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('common.all'))
          }
          placeholder={t('masterData.StandardIndex.level')}
        />
        <ModalCommon
          items={dataLevel}
          onSubmit={() => {
            let datas = [];
            dataLevel.forEach((it) => {
              it.status && it.id && datas.push(it.id);
            });
            setFilter({
              ...filter,
              level_id: !datas.join() ? null : datas.join(),
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, level: false };
            });
          }}
          onClick={changeSelectLevel}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, level: !prev.level };
            });
            if (filter?.level_id) {
              dataLevel?.forEach((item) => {
                Number(filter?.level_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
              });
              setDataLevel([...dataLevel]);
            } else {
              dataLevel?.forEach((item) => {
                item.status = false;
              });
              setDataLevel([...dataLevel]);
            }
          }}
          modal={isModals.level}
          title={t('masterData.StandardIndex.chooseLevel')}
          footer={t('common.select')}
        />
        <InputBase
          disabled
          type="text"
          name="skill_id"
          className="input-select-multi mt-3"
          onClick={() => {
            setIsModals(() => {
              return { skill: true };
            });
          }}
          value={
            dataSkill &&
            (!dataSkill[ZERO]?.status
              ? dataSkill
                  .map((item, i) =>
                    item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                  )
                  .filter((it) => it)
              : t('common.all'))
          }
          placeholder={t('masterData.StandardIndex.skill')}
        />
        <ModalCommon
          items={dataSkill}
          onSubmit={() => {
            let datas = [];
            dataSkill.forEach((it) => {
              it.status && it.id && datas.push(it.id);
            });
            setFilter({
              ...filter,
              skill_id: !datas.join() ? null : datas.join(),
              page: Constants.DEFAULT_PAGE_MUI,
            });
            setIsModals((prev) => {
              return { ...prev, skill: false };
            });
          }}
          onClick={changeSelectSkill}
          toggle={() => {
            setIsModals((prev) => {
              return { ...prev, skill: !prev.skill };
            });
            if (filter?.skill_id) {
              dataSkill?.forEach((item) => {
                Number(filter?.skill_id) === Number(item?.id) ? (item.status = true) : (item.status = false);
              });
              setDataSkill([...dataSkill]);
            } else {
              dataSkill?.forEach((item) => {
                item.status = false;
              });
              setDataPosition([...dataSkill]);
            }
          }}
          modal={isModals.skill}
          title={t('masterData.StandardIndex.chooseSkill')}
          footer={t('common.select')}
        />
        <ButtonBase onClick={() => handleReset()} className="reset mt-3" size="lg">
          <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
          {t('common.reset')}
        </ButtonBase>
      </Grid>
      {isShowModal && (
        <CreateOrUpdateModal
          isShowModal={isShowModal}
          setShowModal={setShowModal}
          dataPosition={uDataPosition}
          dataLevel={uDataLevel}
          dataEdit={dataDetail}
          setIsNewOrUpdateData={setIsNewOrUpdateData}
        />
      )}
      <DetailModal
        isShowModal={isShowModalDetail}
        setShowModal={setShowModalDetail}
        dataDetail={dataDetail}
        dataPosition={dataPosition}
        dataLevel={dataLevel}
        dataSkill={dataSkill}
        uDataPosition={uDataPosition}
        uDataLevel={uDataLevel}
        uDataSkill={uDataSkill}
        notAllowPermissionUpdate={notAllowPermissionUpdate}
        permission={permission}
        setShowModalCreateAndEdit={setShowModal}
      />
    </Grid>
  );
};

export default StandardIndex;
