import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import iconInfo from 'assets/img/icons/info.png';

import './styles.scss';
import InfoCandidate from './components/InfoCandidate';
import ListApplyJobOrder from './components/ListApplyJobOrder';
import Loading from 'components/Loading';

import { setDataAllModalWithStatus } from 'helpers/modal';

import { useInforAuth } from 'hook/useAuth';
import { useGetListSchool, useGetDetailCandidate, useGetListLanguage } from 'hook/useManagerCandidate';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';
import { useGetListSourceCV } from 'hook/useManagerCandidate';
import { useGetListDivision } from 'hook/useDivision';

import { TYPE_CANDIDATE_LEADER } from 'constants/ManagerCandidate';

const HrDetailCandidate = () => {
  const { t } = useTranslation();
  const { candidateId } = useParams();
  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);

  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();
  const { data: uDataListSchool } = useGetListSchool();
  const { data: uDataListLanguage } = useGetListLanguage();
  const { data: uDataListSourceCV } = useGetListSourceCV();
  const { data: uListDivision } = useGetListDivision();
  const { data: auth } = useInforAuth();

  const { data: uDetailCandidate, isLoading: isLoadingDetailCandidate } = useGetDetailCandidate({
    id: candidateId,
    type: TYPE_CANDIDATE_LEADER
  });

  useEffect(() => {
    if (uDataLevel) setDataLevel(setDataAllModalWithStatus(uDataLevel));
  }, [uDataLevel]);

  useEffect(() => {
    if (uDataSkill) setDataSkill(setDataAllModalWithStatus(uDataSkill));
  }, [uDataSkill]);

  useEffect(() => {
    if (uDataPosition) setDataPosition(setDataAllModalWithStatus(uDataPosition));
  }, [uDataPosition]);

  return (
    <div>
      <Grid container className="detail-candidate ">
        <Grid item container className="header-detail">
          <Grid item className="header-back-to-list header-left" md={12}>
            <NavLink
              to={{
                pathname: '/admin/candidate-management',
              }}
              className={'link-back'}
            >
              <ArrowBackIosIcon />
            </NavLink>
            {t('managerCandidate.detail')}
          </Grid>
        </Grid>

        <Grid item container className="header-detail text-info-main d-flex">
          <Grid item md={11}>
            <img alt={iconInfo} src={iconInfo} className="pr-2" />
            {t('managerCandidate.infoCandidate')}
          </Grid>
        </Grid>

        <Grid item md={12}>
          {!isLoadingDetailCandidate && uDetailCandidate && (
            <InfoCandidate dataDetailCandidate={uDetailCandidate} dataListSchool={uDataListSchool} dataListLanguage={uDataListLanguage}/>
          )}
          {isLoadingDetailCandidate && <Loading />}
        </Grid>

        <Grid item container className="header-detail text-info-main">
          <Grid item md={12}>
            <img alt={iconInfo} src={iconInfo} className="pr-2" />
            {t('managerCandidate.infoApply')}
          </Grid>
        </Grid>

        {!isLoadingDetailCandidate && uDetailCandidate && uDetailCandidate?.list_applies && (
          <ListApplyJobOrder
            listApplyOrder={uDetailCandidate.list_applies}
            dataListSourceCV={uDataListSourceCV}
            candidateId={candidateId}
            dataPosition={dataPosition}
            dataLevel={dataLevel}
            dataSkill={dataSkill}
            dataDivision={uListDivision}
            auth={auth}
            type="leader"
          />
        )}
      </Grid>
    </div>
  );
};

export default HrDetailCandidate;
