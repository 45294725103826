import React from 'react';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import Grow from '@mui/material/Grow';

import styles from './styles.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} timeout={2000}  {...props} />;
});

const PreviewImage = (props) => {
  const { children, toggle, isShowAlert, classNameAdd,} = props;

  return (
    <>
      <Dialog
        open={isShowAlert}
        onClose={toggle}
        TransitionComponent={Transition}
        keepMounted
        className={classNames(`${styles.modalPreview} ${classNameAdd && classNameAdd}`)}
      >
        <div className={styles.modalPreviewImage}>{children}</div>
      </Dialog>
    </>
  );
};

export default PreviewImage;
