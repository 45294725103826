import React from 'react';

import BodyStatusTest from './BodyStatusTest';

import { useGetListTest } from 'hook/useDetailTest';

const LeaderTest = ({ applyId }) => {
  const { data: dataListTest } = useGetListTest({ applyId: applyId });

  return (
    <div>
      <BodyStatusTest dataListTest={dataListTest} />
    </div>
  );
};

export default LeaderTest;
