import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

import styles from './styles.module.scss';
import { ModalFooter } from 'reactstrap';
import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import AlertAfterSubmit from 'components/AlertAfterSubmit';

import {
  apiExportStatisticsBySourceCv,
  apiExportStatisticsByLevel,
  apiExportStatisticsByPosition,
  apiExportStatisticsByOrder,
  apiExportStatisticsByJob,
} from 'api/dashboard';
import { showAlert } from 'helpers/modal';
import { TYPE_REPORT } from 'constants/dashboard';
import { HTTP_OK, HTTP_EXPECTATION_FAILED } from 'constants/StatusCode';

const ModalExport = (props) => {
  const { isOpen, setOpen, filterDate, yearFilter, filter } = props;
  const { t } = useTranslation();
  const [typeReportSelected, setTypeReportSelected] = useState('');
  const [isShowExportSuccess, setShowExportSuccess] = useState(false);

  const getApiExport = (typeReport) => {
    switch (typeReport) {
      case TYPE_REPORT.ORDER:
        return apiExportStatisticsByOrder(filterDate);
      case TYPE_REPORT.JOB:
        return apiExportStatisticsByJob();
      case TYPE_REPORT.SOURCE_CV:
        return apiExportStatisticsBySourceCv(filterDate);
      case TYPE_REPORT.LEVEL:
        return apiExportStatisticsByLevel({ year: yearFilter.getFullYear() });
      case TYPE_REPORT.POSITION:
        return apiExportStatisticsByPosition({ ...filterDate, ...filter });
      default:
        return null;
    }
  };

  const getFileName = (typeReport) => {
    switch (typeReport) {
      case TYPE_REPORT.ORDER:
        return t('dashboard.statisticsByOrder');
      case TYPE_REPORT.JOB:
        return t('dashboard.statisticsByJob');
      case TYPE_REPORT.SOURCE_CV:
        return t('dashboard.statisticsBySourceCv');
      case TYPE_REPORT.LEVEL:
        return t('dashboard.statisticsCandidateByLevel');
      case TYPE_REPORT.POSITION:
        return t('dashboard.statisticsCvByPosition');
      default:
        return null;
    }
  };

  const { mutate: handleExport, isLoading: isLoadingExport } = useMutation(() => getApiExport(typeReportSelected), {
    onSuccess: (res) => {
      if (res?.status === HTTP_OK) {
        const encodedUri = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', getFileName(typeReportSelected) + '.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setOpen(false);
        setTypeReportSelected('');
        setShowExportSuccess(true);
      } else if (res?.status === HTTP_EXPECTATION_FAILED) {
        showAlert(t('messages.emptyListErrorForExport'));
      } else {
        showAlert(t('messages.systemError'));
      }
    },
    onError: (error) => {
      if (error?.message) {
        showAlert(error?.message);
      } else if (error?.data?.message) {
        showAlert(error?.data?.message);
      } else {
        showAlert(t('common.anUnknownError'));
      }
    },
  });

  const FooterModalExport = () => (
    <ModalFooter className={styles.modalFooter}>
      <button type="button" className={classNames(styles.btn, styles.btn__cancel)} onClick={() => setOpen(false)}>
        {t('modalOrder.cancel')}
      </button>
      <ButtonComponent
        text={t('common.export')}
        addClass={classNames(styles.btn, styles.btn__action)}
        handleClick={() => handleExport()}
        isLoading={isLoadingExport}
        hasDisabled={!typeReportSelected || isLoadingExport}
      />
    </ModalFooter>
  );

  return (
    <>
      <AlertAfterSubmit isShowAlert={isShowExportSuccess} toggle={() => setShowExportSuccess(false)}>
        <p>{t('dashboard.exportReportSuccess')}</p>
      </AlertAfterSubmit>
      {isOpen && (
        <ModalComponent
          title={t('common.export')}
          isShowModal={isOpen}
          toggle={() => {
            setTypeReportSelected('');
            setOpen(false);
          }}
          renderModalFooter={FooterModalExport}
          classNameAdd={styles.modalExport}
        >
          <FormControl fullWidth className={styles.formSelect}>
            <InputLabel id="type-report-label">{t('dashboard.selectTypeReport')}</InputLabel>
            <Select
              value={typeReportSelected}
              onChange={(e) => setTypeReportSelected(+e.target.value)}
              labelId="type-report-label"
              label={t('dashboard.selectTypeReport')}
            >
              <MenuItem value={TYPE_REPORT.ORDER}>{t('dashboard.statisticsByOrder')}</MenuItem>
              <MenuItem value={TYPE_REPORT.JOB}>{t('dashboard.statisticsByJob')}</MenuItem>
              <MenuItem value={TYPE_REPORT.SOURCE_CV}>{t('dashboard.statisticsBySourceCv')}</MenuItem>
              <MenuItem value={TYPE_REPORT.LEVEL}>{t('dashboard.statisticsCandidateByLevel')}</MenuItem>
              <MenuItem value={TYPE_REPORT.POSITION}>{t('dashboard.statisticsCvByPosition')}</MenuItem>
            </Select>
          </FormControl>
        </ModalComponent>
      )}
    </>
  );
};

export default ModalExport;
