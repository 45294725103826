import * as Yup from 'yup';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';

import { FormControlLabel, Grid, MenuItem, Paper, Radio, RadioGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import styles from './styles.module.scss';
import stylesPopupAreYouSure from 'components/PopupAreYouSure/styles.module.scss';

import AlertAfterSubmit from 'components/AlertAfterSubmit';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Buttons/index';
import InputField from 'components/Input/InputField';
import Loading from 'components/Loading';
import Popup from 'components/popup/index';
import PopupAreYouSure from 'components/PopupAreYouSure';
import SelectComponent from 'components/Select/Select.js';
import CustomInputNumber from 'components/InputYupAndMui/CusromInputNumber';

import BoxItemAddQuestion from '../ManagerGroupTest/BoxItemAddQuestion';
import FormCreateQuestion from '../ManagerGroupTest/FormCreateQuestion';
import ListPreviewQuestion from '../ManagerGroupTest/ListPreviewQuestion';
import ModalWareHouseQuestion from './ModalWareHouseQuestion';

import { sendPost, sendDelete } from 'helpers/axios';
import { errorSubmit } from 'helpers';

import { useListSkill, useListLevel, useListPosition } from 'hook/useMasterData';
import { useGetPostTestDetail } from 'hook/useManagerGroupTest';

import { CODE_DATA_EXISTS, managerTest, MIN_LENGTH_5, MAX_LENGTH_30 } from 'constants/index';
import { ACTIVE, INACTIVE } from 'constants/managerTest';
import { MIN_LENGTH_2 } from 'constants/index';
import { TEST_PERMISSION } from 'constants/permission';

import { uniqueArr } from 'helpers/index';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';
import EditLayout from 'components/PermissionPage/EditLayout';
import ButtonComponent from 'components/Buttons';

const PrepareData = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: postTest, isLoading } = useGetPostTestDetail({ id }, { enabled: Boolean(id) });
  const { data: uDataSkill } = useListSkill({ key_word: '' });
  const { data: uDataPosition } = useListPosition({ key_word: '' });
  const { data: uDataLevel } = useListLevel({ key_word: '' });
  const formData = {};

  if (postTest) {
    formData.status = postTest.status;
    formData.position_id = postTest.position_id;
    formData.skill_id = postTest.skill_id;
    formData.level_id = postTest.level_id;
    formData.title = postTest.title;
    formData.hours = postTest.time_for_test / 60 >= 1 ? postTest.time_for_test / 60 : 0;
    formData.minutes = postTest.time_for_test % 60;
  }

  return (
    <>
      {!isLoading ? (
        <CreateTest
          defaultFormData={postTest ? formData : {}}
          pageEdit={Boolean(id)}
          id={id}
          postTest={postTest}
          uDataSkill={uDataSkill}
          uDataPosition={uDataPosition}
          uDataLevel={uDataLevel}
          t={t}
        />
      ) : (
        <div className="h-100vh d-flex align-items-center justify-content-center">
          <Loading />
        </div>
      )}
    </>
  );
};

const CreateTest = ({
  postTest,
  pageEdit,
  id,
  defaultFormData,
  uDataLevel,
  uDataPosition,
  uDataSkill,
  breadcrumbs,
  t,
}) => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const viewEdit = params.get('view') === 'edit';

  const query = useQueryClient();
  const breadcrumb = useMemo(
    () => [
      { link: '/admin/manager-test', name: t('managerTest.managerTestBr') },
      {
        link: `/admin/manager-test/detail/${id}`,
        name: t(`managerTest.${pageEdit ? 'detailQuestionTest' : 'createTest'}`),
      },
    ],
    [t, id, pageEdit],
  );
  if (viewEdit) {
    breadcrumb[2] = { link: window.location.pathname, name: t('managerTest.editQuestionTestBrc') };
  } else {
    breadcrumb.splice(2, 1);
  }
  const mapPosition = useRef({});
  const mapLevel = useRef({});
  const mapSkill = useRef({});
  const filesUpload = useRef([]);
  const tabFormData = useRef('checkbox');
  const message = useRef('');
  const isDetelePostTest = useRef(false);
  const counterQUestion = useRef({ type: 'counter', value: 1 });
  const actionType = useRef(null);
  const refUploadFile = useRef();
  const [loading, setLoading] = useState(false);
  const [openChooseTest, setOpenChooseTest] = useState(false);
  const [addPostTestSuccess, setPostTestSuccess] = useState(false);
  const [isEditQuestion, setEditQuestion] = useState(false);
  const [isAddQuestion, setAddQuestion] = useState(viewEdit || !pageEdit);
  const [isShowBtnQuestion, setIsShowBtnQuestion] = useState(viewEdit);

  const [listQuestionPreview, setListQuestionPreview] = useState(postTest?.list_questions || []);
  const [list_question_ids, setListQuestionIds] = useState([]);
  const [createQuestion, setCreateQuestion] = useState(true);
  const [addPostTestError, setAddPostTestError] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deletePostSuccess, setDeletePostSuccess] = useState(false);
  const [deletePostError, setDeletePostError] = useState(false);

  const [imgDelete, setImgDelete] = useState([]);

  const onChangeDeleteImg = (index) => {
    setImgDelete((prev) => {
      const newData = structuredClone(prev);
      newData.push(index);
      return newData;
    });
  };

  const [formData] = useState({
    group_question: [{ 'content-answer': '' }],
    contentQuestion: '',
    status: 1,
    position_id: -1,
    skill_id: -1,
    level_id: -1,
    title: '',
    ...defaultFormData,
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    resetField,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        hours: Yup.string()
          .test('required', t('managerTest.requiredTimeForTest'), (val) => {
            return actionType.current === 'submit' && !Number(getValues('minutes')) ? val?.length > 0 : true;
          })
          .test('max', t('messages.inputValueHours'), (val) => {
            return Number(val) >= 24 ? false : true;
          })
          .test('min', t('messages.inputValueHours'), (val) => {
            return Number(val) < 0 ? false : true;
          })
          .test('check', t('messages.SumHoursAndMinutes'), (val) => {
            return Number(getValues('minutes')) === 0 && Number(val) === 0 ? false : true;
          }),
        minutes: Yup.string()
          .test('required', t('managerTest.requiredTimeForTest'), (val) => {
            return actionType.current === 'submit' && !Number(getValues('hours')) ? val?.length > 0 : true;
          })
          .test('max', t('messages.inputValueMinutes'), (val) => {
            return Number(val) > 59 ? false : true;
          })
          .test('min', t('messages.inputValueMinutes'), (val) => {
            return Number(val) < 0 ? false : true;
          })
          .test('check', t('messages.SumHoursAndMinutes'), (val) => {
            return Number(getValues('hours')) === 0 && Number(val) === 0 ? false : true;
          }),
        title: Yup.string()
          .min(MIN_LENGTH_5, t('messages.pleaseEnterFiveToThirtyCharacters'))
          .max(MAX_LENGTH_30, t('messages.pleaseEnterFiveToThirtyCharacters'))
          .test('required', t('managerTest.requiredTitle'), (val) => {
            return actionType.current === 'submit' ? val && val.length >= 5 && val.length <= 30 : true;
          })
          .trim(),
        position_id: Yup.mixed().test('required', t('managerTest.positionRequired'), (val) => {
          return actionType.current === 'submit' ? val && +val >= 0 : true;
        }),
        skill_id: Yup.mixed().test('required', t('managerTest.skillRequired'), (val) => {
          return actionType.current === 'submit' && newSkill.length > 0 ? val && +val >= 0 : true;
        }),
        level_id: Yup.mixed().test('required', t('managerTest.levelRequired'), (val) => {
          return actionType.current === 'submit' ? val && +val >= 0 : true;
        }),
        contentQuestion: Yup.string()
          .test('minmax', t('managerTest.requiredContentQuestion'), (val) => {
            return (actionType.current === 'submit' && list_question_ids.length > 0) ||
              (actionType.current === 'submit' && viewEdit)
              ? true
              : val.trim().length >= 10 && val.trim().length <= 200;
          })
          .trim(),
        group_question: Yup.array()
          .of(
            Yup.object().shape({
              'content-answer': Yup.string()
                .trim()
                .test('min', t('managerTest.minContentAnswer'), (val) => {
                  return tabFormData.current === 'guide' || actionType.current === 'submit'
                    ? true
                    : val && val.trim().length > 0;
                }),
            }),
          )
          .test('min', t('managerTest.minArrContentAnswer'), (val) => {
            return (actionType.current === 'submit' && list_question_ids.length > 0) ||
              (actionType.current === 'submit' && viewEdit) ||
              tabFormData.current === 'guide'
              ? true
              : val.length >= 2;
          }),
      }),
    ),
    defaultValues: formData,
    mode: 'onChange',
  });
  const {
    fields: group_question,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'group_question',
  });
  const positionId = useWatch({
    control,
    name: 'position_id',
  });
  const skillId = useWatch({
    control,
    name: 'skill_id',
  });
  const levelId = useWatch({
    control,
    name: 'level_id',
  });
  const newSkill = useMemo(
    () => uDataSkill?.filter((item) => item.position_id === positionId),
    [uDataSkill, positionId],
  );

  useCallback(() => {
    if (uDataSkill) {
      uDataSkill.map((item) => {
        mapSkill.current[item.id] = item.name;
        return item;
      });
    }
  }, [uDataSkill])();
  useCallback(() => {
    if (uDataLevel) {
      uDataLevel.map((item) => {
        mapLevel.current[item.id] = item.name;
        return item;
      });
    }
  }, [uDataLevel])();

  useCallback(() => {
    if (uDataPosition) {
      uDataPosition.map((item) => {
        mapPosition.current[item.id] = item.name;
        return item;
      });
    }
  }, [uDataPosition])();

  const resetForm = () => {
    resetField('group_question');
    resetField('contentQuestion');
    refUploadFile.current.clearUploadFile();
    setError('group_question', null);
  };

  const handleError = (res) => {
    if (errorSubmit(res?.data?.code) || res?.data?.status === 422) {
      if (res.data.status === 409) {
        message.current = t('managerTest.sameWithTheExistingTest');
      } else if (res?.data?.code === CODE_DATA_EXISTS || res.data.status === 422) {
        message.current = t('managerTest.reconfirmContentQuestion');
      } else {
        message.current = t(res?.data?.code ? errorSubmit(res?.data?.code) : 'managerTest.somethingWenWrong');
      }
      setAddPostTestError(true);
      setLoading(false);
      return true;
    }
  };

  // handle submit new test duplicate
  const alertConfirmDuplicate = (payload) => {
    confirmAlert({
      title: t('managerTest.sameWithTheExistingTest'),
      overlayClassName: 'manage-test-justify',
      closeOnClickOutside: false,
      buttons: [
        {
          label: t('common.back'),
          className: 'btn-alert-cancel text-left',
          onClick: async () => {
            setLoading(false);
          },
        },
        {
          label: t('managerTest.activeThisTest'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const res = await sendPost(`/api/post-test/save`, { ...payload, important: 1 });
            if (handleError(res)) return;
            setPostTestSuccess(true);
            resetForm();
          },
        },
      ],
    });
  };

  const onSubmit = async (data) => {
    if (!loading) {
      setLoading(true);
      if (actionType.current === 'add') {
        const group_question = getValues('group_question');
        const contentQuestion = getValues('contentQuestion');
        const tab = {
          guide: 1,
          checkbox: 2,
          radio: 3,
        };
        const payload = {
          type: tab[tabFormData.current],
          content: contentQuestion,
          img_bonus: [],
        };

        // add image old in list question
        if (counterQUestion.current.id) {
          listQuestionPreview.forEach((item) => {
            if (counterQUestion.current.id === item.id) {
              if (typeof item.img_bonus === 'string') {
                JSON.parse(item.img_bonus)?.forEach((pathImg) => {
                  payload.img_bonus.push(pathImg);
                });
              } else {
                item.img_bonus?.forEach((pathImg) => {
                  payload.img_bonus.push(pathImg);
                });
              }

              // check deleted
              if (imgDelete) {
                payload.img_bonus?.forEach((pathImg) => {
                  if (imgDelete.includes(pathImg)) {
                    const index = payload.img_bonus.indexOf(pathImg);
                    delete payload.img_bonus[index];
                  }
                });
              }
            }
          });
        }

        payload.img_bonus = [...payload.img_bonus, ...filesUpload.current];
        payload.img_bonus = uniqueArr(payload.img_bonus);

        if (tab[tabFormData.current] > 1) {
          payload.list_answer = group_question.map((item) => item['content-answer']);
        }
        if (counterQUestion.current.id) {
          payload.id = counterQUestion.current.id;
        }
        // edit question choose group test
        if (counterQUestion.current.id_old) {
          payload.id = null;
        }
        const res = await sendPost(`/api/question/save`, payload);
        payload.id = res.data.id ? res.data.id : counterQUestion.current.id;
        if (payload.img_bonus) {
          payload.img_bonus = payload.img_bonus.map((itemImage) =>
            itemImage.replace('temp', itemImage.slice(itemImage.lastIndexOf('.') + 1)),
          );
          filesUpload.current = filesUpload.current.map((itemImage) =>
            itemImage.replace('temp', itemImage.slice(itemImage.lastIndexOf('.') + 1)),
          );
        }
        if (handleError(res)) return;
        filesUpload.current = [];
        const resId = res.data.id ? res.data.id : payload.id;
        payload.id = res.data.id ? res.data.id : counterQUestion.current.id;
        if (counterQUestion.current.type === 'edit') {
          const newData = structuredClone(listQuestionPreview);
          const newIds = structuredClone(list_question_ids);
          newData[counterQUestion.current.value - 1] = payload;
          newIds[counterQUestion.current.value - 1] = resId;
          setListQuestionPreview([...newData]);
          setListQuestionIds([...newIds]);
          resetForm();
          counterQUestion.current = { value: listQuestionPreview.length + 1, type: 'counter' };
          setLoading(false);
          setAddQuestion(true);

          // query.refetchQueries([managerTest.USE_LIST_GET_POST_TEST_DETAIL, { id: id }]);

          return setCreateQuestion(false);
        }
        resetForm();
        setListQuestionIds((prev) => {
          const newData = structuredClone(prev);
          newData.push(resId);
          return newData;
        });
        setListQuestionPreview((prev) => {
          const newData = structuredClone(prev);
          newData.push(payload);
          return newData;
        });
        setCreateQuestion(false);
      } else {
        let time_for_test = 0;
        if (!isNaN(data.hours)) time_for_test += +data.hours * 60;
        if (!isNaN(data.minutes)) time_for_test += +data.minutes;
        const payload = {
          level_id: data.level_id,
          position_id: data.position_id,
          skill_id: data.skill_id <= 0 ? null : data.skill_id,
          title: data.title,
          status: +data.status,
          time_for_test,
        };
        // if (list_question_ids.length > 0) {
        //   payload.list_question_ids = list_question_ids;
        // }
        // if (isDetelePostTest.current) {
        //   payload.list_question_ids = listQuestionPreview.map((item) => item.id);
        // }
        const listQuestionId = [];
        listQuestionPreview.forEach((item) => {
          if (item.id) return listQuestionId.push(item.id);
        });
        payload.list_question_ids = listQuestionId;
        if (id) payload.id = id;
        const res = await sendPost(`/api/post-test/save`, payload);
        if (res?.data?.status === 409) {
          alertConfirmDuplicate(payload);
          return;
        }
        if (handleError(res)) return;
        setPostTestSuccess(true);
        resetForm();
      }
      setLoading(false);
      setIsShowBtnQuestion(true);
      filesUpload.current = [];
    }
  };

  const handleSubmitData = (data) => {
    if (actionType.current === 'submit' && (getValues('contentQuestion') || counterQUestion.value)) {
      confirmAlert({
        title: t('managerTest.confirmSaveManagerTest', {
          type_name: isEditQuestion ? t('common.update') : t('common.add'),
        }),
        overlayClassName: 'confirm-saveManager-test',
        buttons: [
          {
            label: t('common.back'),
            className: 'btn-alert-cancel text-left',
          },
          {
            label: t('managerTest.save'),
            className: 'btn-alert-ok',
            onClick: async () => {
              onSubmit(data);
            },
          },
        ],
      });
    } else {
      onSubmit(data);
    }
  };

  const handleDeleteQuestion = (index) => {
    isDetelePostTest.current = true;
    setListQuestionPreview((prev) => {
      const newData = structuredClone(prev);
      newData.splice(index, 1);
      return newData;
    });
    setListQuestionIds((prev) => {
      const newData = structuredClone(prev);
      newData.splice(index, 1);
      return newData;
    });
  };

  const handleEditQuestion = useCallback(
    (item, index) => {
      setCreateQuestion(true);
      counterQUestion.current = { value: index, type: 'edit', id: item.id, id_old: item?.id_old };
      tabFormData.current = item.type === 1 ? 'guide' : item.type === 2 ? 'checkbox' : 'radio';
      if (item.content?.length > 9) {
        clearErrors('contentQuestion');
      }
      setValue('contentQuestion', item.content);
      if (item.list_answer) {
        item.list_answer.forEach((value) => {
          if (value.trim().length > 0) return clearErrors('group_question');
        });
        setValue(
          'group_question',
          item.list_answer.map((item) => ({ 'content-answer': item })),
        );
      }
      if (item.img_bonus.length) refUploadFile.current.updateListImgUpload(item.img_bonus);
      else refUploadFile.current.updateListImgUpload([]);
      refUploadFile.current.setErrorFile('');
    },
    [setValue, clearErrors],
  );

  const handleUpdateQuestion = (index) => {
    if (group_question.length - 1 === index) {
      append({ content: '' });
      setError('group_question', null);
    } else {
      remove(index);
    }
  };

  const handleAppendQuestion = () => {
    actionType.current = 'add';
  };

  const handleActionSubmit = () => {
    actionType.current = 'submit';
  };

  const handleSubmitEdit = () => {
    if (viewEdit) {
      actionType.current = 'submit';
    } else {
      setIsShowBtnQuestion(true);
      history.push(window.location.pathname + '?view=edit');
    }
  };

  const handleDeleteOrCancel = useCallback(() => {
    if (viewEdit) {
      setIsShowBtnQuestion(false);
      setEditQuestion(false);
      setAddQuestion(false);
      history.push(window.location.pathname);
      setListQuestionPreview(postTest?.list_questions);
    } else {
      setOpenConfirmDelete(true);
    }
  }, [history, viewEdit, postTest]);

  const FormCreateQuestionComp = useCallback(
    ({ errors }) => (
      <FormCreateQuestion
        Controller={Controller}
        t={t}
        handleUpdateQuestion={handleUpdateQuestion}
        group_question={group_question}
        control={control}
        errors={errors}
        tabFormData={tabFormData}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [group_question],
  );

  const handleCreateQuestion = () => {
    counterQUestion.current = { type: 'counter', value: 1 };
    setEditQuestion(false);
    setAddQuestion(true);
    setCreateQuestion(true);
    setIsShowBtnQuestion(false);
    resetForm();
    refUploadFile.current.setErrorFile('');
  };

  const handleSetEditQuestion = () => {
    setEditQuestion(true);
    setAddQuestion(false);
    setIsShowBtnQuestion(true);
  };

  const handlePostTestSuccess = () => {
    query.refetchQueries(managerTest.USE_LIST_MANAGER_POST_TEST);
    query.refetchQueries(managerTest.USE_LIST_GET_POST_TEST_DETAIL);
    history.push(breadcrumb[0].link);
    setPostTestSuccess(false);
  };

  const handleDeletePostTest = async () => {
    if (!loading) {
      setLoading(true);
      const res = await sendDelete(`/api/post-test/delete`, { id });
      if (res.status === 200) {
        setDeletePostSuccess(true);
      } else {
        setDeletePostError(false);
      }
      setLoading(false);
      setOpenConfirmDelete(false);
    }
  };

  const handleDeletePostSuccess = useCallback(() => {
    if (!loading) {
      setLoading(true);
      query.refetchQueries(managerTest.USE_LIST_MANAGER_POST_TEST);
      setDeletePostSuccess(false);
      history.push(breadcrumb[0].link);
      setLoading(false);
    }
  }, [breadcrumb, history, query, loading]);

  const disabledSwitchQuestion = useMemo(() => {
    if (newSkill?.length) {
      return !(skillId >= 0 && positionId >= 0 && levelId >= 0);
    }
    return !(positionId >= 0 && levelId >= 0);
  }, [newSkill, skillId, positionId, levelId]);

  const getTextHeader = () => {
    if (pageEdit && viewEdit) return t('managerTest.editQuestionTest');
    if (pageEdit) return t('managerTest.detailQuestionTest');
    return t('managerTest.createTest');
  };

  return (
    <Grid container className={`content manager-group-test manager-test pb-5 ${styles.mainContainer}`}>
      <AlertAfterSubmit isShowAlert={deletePostSuccess} toggle={handleDeletePostSuccess}>
        <p>{t('managerTest.deletePostTestSuccess')}</p>
      </AlertAfterSubmit>
      <Popup
        active={deletePostError}
        handleCancel={() => setDeletePostError(false)}
        footer={
          <>
            <button
              className={`btn-transparent color-yellow ${stylesPopupAreYouSure.btnOk}`}
              onClick={() => {
                setDeletePostError(false);
              }}
            >
              {t('managerTest.done')}
            </button>
          </>
        }
        messages={t('managerTest.somethingWenWrong')}
      />
      <PopupAreYouSure
        active={openConfirmDelete}
        handleCancel={() => setOpenConfirmDelete(false)}
        footer={
          <>
            <button className={stylesPopupAreYouSure.btnCancel} onClick={() => setOpenConfirmDelete(false)}>
              {t('common.cancel')}
            </button>
            <button className={stylesPopupAreYouSure.btnOk} onClick={handleDeletePostTest}>
              {t('common.ok')}
            </button>
          </>
        }
        messages={t('managerTest.confirmDeleteQuestion')}
      />
      <Popup
        active={addPostTestSuccess}
        handleCancel={() => setPostTestSuccess(false)}
        footer={
          <>
            <button
              className={`btn-transparent color-yellow ${stylesPopupAreYouSure.btnOk}`}
              onClick={handlePostTestSuccess}
            >
              {t('managerTest.done')}
            </button>
          </>
        }
        messages={t(`managerTest.${viewEdit ? 'updatePostTestSuccess' : 'addPosttestSuccess'}`)}
      />
      <Popup
        active={addPostTestError}
        handleCancel={() => setAddPostTestError(false)}
        footer={
          <>
            <button
              className={`btn-transparent color-yellow ${stylesPopupAreYouSure.btnOk}`}
              onClick={() => {
                setAddPostTestError(false);
              }}
            >
              {t('managerTest.done')}
            </button>
          </>
        }
        messages={message.current}
      />
      <Breadcrumb data={breadcrumb} />
      <Grid item xs={12} className="content-component">
        <form novalidate onSubmit={handleSubmit(handleSubmitData)}>
          <Grid item xs={12} className="d-flex align-items-center justify-content-between title-outside">
            <h3 className={styles.headerTitle}>{getTextHeader()}</h3>
          </Grid>
          <div className="table-border-show">
            <Paper className="p-20">
              <Grid item xs={12} className="d-flex align-items-center justify-content-between">
                <h4 className={styles.infoTest}>{t('managerTest.infoTest')}</h4>
                <div className="fnc-btn flex-button">
                  {pageEdit ? (
                    <DeleteLayout permissionName={TEST_PERMISSION}>
                      <Button
                        text={t(`managerTest.${viewEdit ? 'cancel' : 'delete'}`)}
                        addClass="btn-bg-yellow2 mr-3 h-36 width-92 btn-cancel-manage-test"
                        handleClick={handleDeleteOrCancel}
                      />
                    </DeleteLayout>
                  ) : (
                    <DeleteLayout permissionName={TEST_PERMISSION}>
                      <Button
                        text={t(`managerTest.cancel`)}
                        addClass="btn-bg-yellow2 mr-3 h-36 width-92 btn-cancel-manage-test"
                        handleClick={() => history.push(breadcrumb[0].link)}
                      />
                    </DeleteLayout>
                  )}
                  {pageEdit ? (
                    <EditLayout permissionName={TEST_PERMISSION}>
                      <Button
                        text={t(`managerTest.${viewEdit ? 'save' : 'edit'}`)}
                        addClass="btn-bg-yellow1 h-36 btn-cancel-manage-test width-92"
                        type="submit"
                        isLoading={loading}
                        hasDisabled={loading || !listQuestionPreview.length}
                        handleClick={handleSubmitEdit}
                        // hasDisabled={name?.length < 2 || !isValidSubmit}
                      />
                    </EditLayout>
                  ) : (
                    <Button
                      text={t('managerTest.create')}
                      addClass="btn-bg-yellow1 h-36 width-92 btn-cancel-manage-test"
                      type="submit"
                      isLoading={loading}
                      hasDisabled={loading || !listQuestionPreview.length}
                      handleClick={handleActionSubmit}
                      // hasDisabled={name?.length < 2 || !isValidSubmit}
                    />
                  )}
                </div>
              </Grid>
              <div className="divider mt-15 ml-mr-20" />
              <Grid container spacing={2} className="mt-20 info">
                {/* page edit and no show edit */}
                {pageEdit && !viewEdit ? (
                  <>
                    <Grid item xs={12} className="d-flex align-items-center">
                      <Grid item xs={2}>
                        <span className="fw-500">{t('managerTest.title')}</span>
                      </Grid>
                      <Grid item xs={4}>
                        <span>{postTest?.title}</span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className="fw-500">{t('managerTest.position')}</span>
                      </Grid>
                      <Grid item xs={4}>
                        <span>{mapPosition.current[postTest?.position_id]}</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="d-flex align-items-center mt-15">
                      <Grid item xs={2}>
                        <span className="fw-500"> {t('masterData.StandardIndex.levelRecruit')}</span>
                      </Grid>
                      <Grid item xs={4}>
                        <span>{mapLevel.current[postTest?.level_id]}</span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className="fw-500">{t('managerTest.skill')}</span>
                      </Grid>
                      <Grid item xs={4}>
                        <span>{mapSkill.current[postTest?.skill_id]}</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="d-flex align-items-center mt-15">
                      <Grid item xs={2}>
                        <span className="fw-500">{t('managerTest.status')}</span>
                      </Grid>
                      <Grid item xs={4}>
                        <span>{postTest?.status ? 'Active' : 'Inactive'}</span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className="fw-500">{t('managerTest.timeForTest')}</span>
                      </Grid>
                      <Grid item xs={4}>
                        <span>
                          {postTest?.time_for_test} {t('managerTest.minutes')}
                        </span>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  // show edit (page edit, page)
                  <>
                    <Grid item xs={12} className="pt-0">
                      <span className={styles.infoGeneral}>{t('managerTest.infoGeneral')}</span>
                    </Grid>
                    <Grid item xs={6} className="pt-30">
                      <Controller
                        control={control}
                        name="title"
                        valueDefault={formData['title']}
                        render={({ field }) => (
                          <InputField
                            field={field}
                            label={t('managerTest.titleTest')}
                            variant="outlined"
                            isRequired={true}
                            onHandleInput={(e) => {}}
                            error={errors?.['title']?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} className="pt-30">
                      <Controller
                        control={control}
                        name="position_id"
                        valueDefault={formData.position_id}
                        render={({ field }) => (
                          <SelectComponent
                            field={field}
                            addClass="select"
                            label={t('masterData.StandardIndex.position')}
                            error={errors?.['position_id']?.message}
                            isRequired={true}
                            onChange={(e) => {
                              setValue('skill_id', -1);
                              setValue('position_id', e.target.value);
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: {
                                    xs: 350,
                                  },
                                },
                              },
                            }}
                          >
                            {uDataPosition &&
                              uDataPosition?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </SelectComponent>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} className="pt-30">
                      <Controller
                        control={control}
                        name="level_id"
                        valueDefault={formData.level_id}
                        render={({ field }) => (
                          <SelectComponent
                            field={field}
                            addClass="select"
                            label={t('masterData.StandardIndex.levelRecruit')}
                            error={errors?.['level_id']?.message}
                            isRequired={true}
                          >
                            {uDataLevel &&
                              uDataLevel?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </SelectComponent>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} className="pt-30 select-skill-test">
                      <Controller
                        control={control}
                        name="skill_id"
                        valueDefault={formData.skill_id}
                        render={({ field }) => (
                          <SelectComponent
                            field={field}
                            addClass="select"
                            label={t('masterData.StandardIndex.skill')}
                            placeholder={t('masterData.StandardIndex.skill')}
                            disabled={!newSkill?.length > 0}
                            error={errors?.['skill_id']?.message}
                            isRequired={true}
                          >
                            {newSkill &&
                              newSkill?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </SelectComponent>
                        )}
                      />
                    </Grid>
                  </>
                )}

                {(viewEdit || !pageEdit) && (
                  <Grid item xs={6} className="pt-30">
                    <Grid container spacing={4} className="pt-0 mt-0">
                      <Grid item xs={6} className="pt-0">
                        <CustomInputNumber
                          name="hours"
                          label={
                            <React.Fragment>
                              {t('managerTest.hours')} {<sup>*</sup>}
                            </React.Fragment>
                          }
                          defaultValue={formData.hours}
                          maxLength={MIN_LENGTH_2}
                          errors={errors}
                          control={control}
                          useWatch={useWatch}
                          setValue={setValue}
                          register={register}
                          isInteger
                        />
                      </Grid>
                      <Grid item xs={6} className="pt-0">
                        <CustomInputNumber
                          name="minutes"
                          label={
                            <React.Fragment>
                              {t('managerTest.minutes')} {<sup>*</sup>}
                            </React.Fragment>
                          }
                          defaultValue={formData.minutes}
                          maxLength={MIN_LENGTH_2}
                          errors={errors}
                          control={control}
                          useWatch={useWatch}
                          setValue={setValue}
                          register={register}
                          isInteger
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {(viewEdit || !pageEdit) && (
                <Grid item xs={12}>
                  <p className={styles.labelStatus}>{t('managerTest.status')}</p>
                  <Controller
                    control={control}
                    name="status"
                    valueDefault={formData.status}
                    render={({ field }) => (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        value={formData.status}
                        className="d-flex align-items-center justify-content-start flex-row"
                        {...field}
                      >
                        <FormControlLabel
                          value={ACTIVE}
                          control={<Radio />}
                          label={t('managerTest.active')}
                          disabled={viewEdit && formData.status === ACTIVE ? true : false}
                        />
                        <FormControlLabel
                          value={INACTIVE}
                          control={<Radio />}
                          label={t('managerTest.inactive')}
                          disabled={viewEdit && formData.status === ACTIVE ? true : false}
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} className="pt-0">
                <p className={styles.infoGeneral}>{t('managerTest.infoTest')}</p>
              </Grid>
              {listQuestionPreview.length > 0 && (
                <Grid item xs={12} className={`${styles.boxPreview} mt-15`}>
                  <ListPreviewQuestion
                    t={t}
                    listQuestionPreview={listQuestionPreview.map((item) => {
                      const res = {
                        ...item,
                        img_bonus:
                          pageEdit && typeof item.img_bonus === 'string' ? JSON.parse(item.img_bonus) : item.img_bonus,
                        list_answer: [],
                      };
                      if (item.type > 1) {
                        res.list_answer =
                          pageEdit && typeof item.list_answer === 'string'
                            ? JSON.parse(item.list_answer)
                            : item.list_answer;
                      }
                      return res;
                    })}
                    handleDeleteQuestion={handleDeleteQuestion}
                    handleEditQuestion={handleEditQuestion}
                    enableActionQuestion={true}
                    setEditQuestion={handleSetEditQuestion}
                    viewEdit={viewEdit}
                    pageEdit={pageEdit}
                    filesUpload={filesUpload}
                  />
                </Grid>
              )}

              <div
                className={`${styles.boxQuestion} ${
                  isEditQuestion || isAddQuestion ? styles.showQuestion : styles.hiddenQuestion
                } `}
              >
                <BoxItemAddQuestion
                  Controller={Controller}
                  createQuestion={createQuestion}
                  counterQUestion={counterQUestion}
                  control={control}
                  formData={formData}
                  errors={errors}
                  loading={loading}
                  handleAppendQuestion={handleAppendQuestion}
                  t={t}
                  filesUpload={filesUpload}
                  refUploadFile={refUploadFile}
                  setLoading={setLoading}
                  FormCreateQuestionComp={FormCreateQuestionComp}
                  nextQuestion={listQuestionPreview.length + 1}
                  isEditQuestion={isEditQuestion}
                  handleCreateQuestion={handleCreateQuestion}
                  onChangeDeleteImg={onChangeDeleteImg}
                />
              </div>
              <div className="d-flex align-items-center mt-20">
                {isShowBtnQuestion ? (
                  <div
                    className="append-question mr-25 d-flex align-items-center cursor"
                    onClick={handleCreateQuestion}
                  >
                    <AddIcon />
                    <span>{t('managerTest.addQuestion')}</span>
                  </div>
                ) : (
                  (viewEdit || !pageEdit) && (
                    <div className="disabled-append-question mr-25 d-flex align-items-center cursor">
                      <AddIcon />
                      <span>{t('managerTest.addQuestion')}</span>
                    </div>
                  )
                )}
                {(viewEdit || isAddQuestion) && (
                  <ButtonComponent
                    text={t('managerTest.switchFromWarehouseTest')}
                    addClass="btn-bg-yellow1 mr-3 h-36 pl-3 pr-3"
                    hasDisabled={disabledSwitchQuestion}
                    handleClick={() => {
                      setOpenChooseTest(true);
                    }}
                  />
                )}
              </div>
            </Paper>
          </div>
        </form>
      </Grid>

      {openChooseTest && (
        <ModalWareHouseQuestion
          setOpenChooseTest={setOpenChooseTest}
          openChooseTest={openChooseTest}
          t={t}
          positionId={positionId}
          skillId={skillId}
          levelId={levelId}
          listQuestionIdCurrent={listQuestionPreview.map((item) => item.id)}
          mapLevel={mapLevel.current}
          setListQuestionIds={setListQuestionIds}
          setListQuestionPreview={setListQuestionPreview}
        />
      )}
    </Grid>
  );
};

export default PrepareData;
