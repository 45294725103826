import { useQuery, useQueryClient } from 'react-query';

import {
  apiGetListCandidate,
  apiGetListYearExperience,
  apiGetListSchool,
  apiGetListSourceCV,
  apiGetListLanguage,
  apiGetDetailCandidate,
  apiGetRoomsListByLocationId,
  apiListAllStatusCandidate,
  apiGetAllInterviewCandidate,
  apiGetListHr,
  apiGetListLeaderBookInterview,
} from 'api/managerCandidate';
import { getLocations } from 'api/interviewScheduleManagement';
import * as Constants from 'constants/ManagerCandidate';
import { GET_LOCATIONS } from 'constants/InterviewScheduleManagement';
import { HTTP_OK } from 'constants/StatusCode';

export function useGetListCandidate(params) {
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useQuery([Constants.USE_GET_LIST_CANDIDATE, params], async () => {
    const response = await apiGetListCandidate(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading, refetch };
}

export function useGetListYearExperience(params) {
  const queryClient = useQueryClient();
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_YEAR_EXPERIENCE, params], async () => {
    let response = {};
    if (queryClient.getQueryData(Constants.USE_GET_LIST_YEAR_EXPERIENCE)) {
      response = queryClient.getQueryData(Constants.USE_GET_LIST_YEAR_EXPERIENCE);
    } else {
      response = await apiGetListYearExperience({ system_id: 1 });
    }
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListSchool(params) {
  const queryClient = useQueryClient();
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_SCHOOL, params], async () => {
    let response = {};
    if (queryClient.getQueryData(Constants.USE_GET_LIST_SCHOOL)) {
      response = queryClient.getQueryData(Constants.USE_GET_LIST_SCHOOL);
    } else {
      response = await apiGetListSchool(params);
    }
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListSourceCV(params) {
  const queryClient = useQueryClient();
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_SOURCE_CV, params], async () => {
    let response = {};
    if (queryClient.getQueryData(Constants.USE_GET_LIST_SOURCE_CV)) {
      response = queryClient.getQueryData(Constants.USE_GET_LIST_SOURCE_CV);
    } else {
      response = await apiGetListSourceCV({ system_id: 1 });
    }
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListLanguage(params) {
  const queryClient = useQueryClient();
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_LANGUAGE, params], async () => {
    let response = {};
    if (queryClient.getQueryData(Constants.USE_GET_LIST_LANGUAGE)) {
      response = queryClient.getQueryData(Constants.USE_GET_LIST_LANGUAGE);
    } else {
      response = await apiGetListLanguage(params);
    }
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetDetailCandidate(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_DETAIL_CANDIDATE, params],
    async () => {
      const response = await apiGetDetailCandidate(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return response;
    },
    {
      enabled: !!params?.id,
    },
  );

  return { ...response, error, isLoading };
}

export function useGetRoomsList(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_ROOM_LIST, params],
    async () => {
      const response = await apiGetRoomsListByLocationId(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    {
      enabled: !!params?.location_id,
    },
  );
  return { ...response, error, isLoading };
}

export function useGetLocationsList(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([GET_LOCATIONS, params], async () => {
    const response = await getLocations(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return { ...response, error, isLoading };
}

export function useListAllStatusCandidate(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_ALL_STATUS_CANDIDATE, params], async () => {
    const response = await apiListAllStatusCandidate(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return { ...response, error, isLoading };
}

export function useGetAllDetailInterviewCandidate(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_ALL_DETAIL_INTERVIEW_CANDIDATE, params], async () => {
    const response = await apiGetAllInterviewCandidate(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return { ...response, error, isLoading };
}

export function useGetListHr(params, isEnabled) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_LIST_HR, params],
    async () => {
      const response = await apiGetListHr(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    {
      enabled: !!isEnabled,
    },
  );
  return { ...response, error, isLoading };
}

export function useGetListLeaderBookInterview(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_LIST_LEADER_BOOK_INTERVIEW, params],
    async () => {
      const response = await apiGetListLeaderBookInterview(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return [];
    },
    {
      enabled: !!(params.apply_id && params.job_id),
    },
  );
  return { ...response, error, isLoading };
}
