/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@mui/material';
import { MdDelete } from 'react-icons/md';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';

import './styles.scss';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { useGetListManagerDecentralization } from 'hook/useManagerDecentralization';
import { sendDelete } from 'helpers/axios';
import { useQueryClient } from 'react-query';
import { managerDecentralization } from 'constants/index.js';
import EditLayout from 'components/PermissionPage/EditLayout';
import { GROUP_PERMISSION } from 'constants/permission';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';

const ManagerDecentralization = () => {
  const { t } = useTranslation();
  const [filter] = useState({
    system_id: 2,
  });
  const loading = useRef(true);
  const queryClient = useQueryClient();
  const [openModalDeleteRole, setOpenModal] = useState({ status: 200, active: false });
  const history = useHistory();

  const {
    data: listManagerDecentralization,
    isLoading,
    totalMembers,
  } = useGetListManagerDecentralization({
    ...filter,
  });
  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${0}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  const createAt = (item) => {
    return moment(item.createdAt).format('DD/MM/YYYY');
  };

  const handleRedirect = (id) => {
    history.push(`/admin/master-data/manager-decentralization/list-position/${id}`);
  };

  const openModal = useCallback(() => {
    history.push('/admin/master-data/manager-decentralization/create-position');
  }, []);

  const handleDeleteRole = useCallback(
    async (item) => {
      if (loading.current) {
        loading.current = false;
        const res = await sendDelete(`/api/group-role/${item.id}?name=${item.name}`);
        setOpenModal({ status: res.data.status, active: true });
        loading.current = true;
      }
    },
    [loading.current],
  );

  const handleDoneDeleteRole = async () => {
    if (openModalDeleteRole.status !== 417 && openModalDeleteRole.status !== 422) {
      await queryClient.refetchQueries([managerDecentralization.getListManagerDecentralization]);
    }
    setOpenModal({ ...openModalDeleteRole, active: false });
  };

  return (
    <Grid container gap={2} className="content management-decentralization">
      <Dialog
        open={openModalDeleteRole.active}
        onClose={() => setOpenModal({ ...openModalDeleteRole, active: false })}
        className="dialog-tab-list-manager management-decentralization-delete-role"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="title-update-role-success">
            {t(
              `managerDecentralization.${
                openModalDeleteRole.status !== 417 && openModalDeleteRole.status !== 422
                  ? 'deleteRoleSuccess'
                  : 'deleteRoleFail'
              }`,
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn-transparent btn-delete-success color-yellow fs-14" onClick={handleDoneDeleteRole}>
            {t(`managerDecentralization.done`)}
          </button>
        </DialogActions>
      </Dialog>
      <Grid container className="pt-0">
        <Grid item xs sm md lg xl className="header-left color-text">
          {t('managerDecentralization.managerDecentralization')}
        </Grid>
      </Grid>
      <Grid item flex={10} className="content-component mb-5">
        <div className="table-border-show">
          <Paper>
            <TableContainer className="table-list table-list-manager">
              <Table aria-label="sticky table">
                <TableHead className="asset-header">
                  <TableRow className="asset-header-row">
                    <TableCell>{t('managerDecentralization.STT')}</TableCell>
                    <TableCell>{t('managerDecentralization.position')}</TableCell>
                    <TableCell>{t('managerDecentralization.quantityStaff')}</TableCell>
                    <TableCell>{t('managerDecentralization.createdAt')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Loading addClass="mt-3 mb-3" />
                      </TableCell>
                    </TableRow>
                  )}
                  {listManagerDecentralization &&
                    listManagerDecentralization?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className="table-content id-standard">{getSTT(index)}</TableCell>
                          <TableCell className="table-content cursor" onClick={handleRedirect.bind(this, item.id)}>
                            {item.name}
                          </TableCell>
                          <TableCell className="table-content cursor" onClick={handleRedirect.bind(this, item.id)}>
                            {item.total_member}
                          </TableCell>
                          <TableCell className="table-content cursor" onClick={handleRedirect.bind(this, item.id)}>
                            {createAt(item)}
                          </TableCell>
                          <TableCell className="table-content">
                            {item.name !== 'System Admin' && (
                              <DeleteLayout permissionName={GROUP_PERMISSION}>
                                <div
                                  className="edit-delete-button color-button m-0"
                                  onClick={handleDeleteRole.bind(this, item)}
                                >
                                  <MdDelete />
                                </div>
                              </DeleteLayout>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {listManagerDecentralization?.length === 0 && (
                    <tr>
                      <td colSpan={9} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Grid>
      <Grid item flex={2} className={'filter-decentralization'}>
        <EditLayout permissionName={GROUP_PERMISSION}>
          <ButtonComponent
            addClass="button-add-menu-right"
            icon={<AddIcon />}
            text={t('managerDecentralization.createPosition')}
            handleClick={openModal}
          />
        </EditLayout>
        <div className="info-menu-right-no-image selected">
          <div className="info-menu-righ-title">{t('managerDecentralization.totalStaff')}</div>
          <div className="info-menu-righ-total">{totalMembers}</div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ManagerDecentralization;
