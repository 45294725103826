import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ManagerJob from './ManagerJob';

const Index = () => {
  useEffect(() => {
    document.body.style.background = '#F9FBFC';
    return () => (document.body.style.background = 'transparent');
  }, []);
  return (
    <>
      <Switch>
        <Route path="/admin/manager-job" exact render={() => <ManagerJob />} />
        <Route path="/admin/manager-job/:jobId" exact render={() => <ManagerJob />} />
      </Switch>
    </>
  );
};

export default Index;
