import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import classNames from 'classnames';

import Avatar from '@mui/material/Avatar';
import { Grid } from '@mui/material';

import styles from './styles.module.scss';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';

import { ThemContext } from 'layouts/Admin';
import { apiAssignHr } from 'api/managerCandidate';
import { useGetListHr } from 'hook/useManagerCandidate';
import { MAX_LENGTH_50, LOADING_SM } from 'constants/index';
import { USE_GET_LIST_CANDIDATE } from 'constants/ManagerCandidate';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import ButtonComponent from 'components/Buttons';
import PopupAreYouSure from 'components/PopupAreYouSure';
import AlertAfterSubmit from 'components/AlertAfterSubmit';

const AssignModal = (props) => {
  const { showModal, setShowModal, listCandidatePicked, onSuccessAssign } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { dataInfoUser } = useContext(ThemContext);

  const [nameHrSearch, setNameHrSearch] = useState(null);
  const [hrPicked, setHrPicked] = useState({ id: null, name: null });
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false);
  const [isAssignSuccess, setIsAssignSuccess] = useState(false);
  const [isAssignFailure, setIsAssignFailure] = useState(false);

  const { data: listHr, isLoading: isLoadingListHr } = useGetListHr(
    {
      candidate_ids: listCandidatePicked,
      name: nameHrSearch,
    },
    showModal,
  );

  const { mutate: assignHr, isLoading: isLoadingAssign } = useMutation(
    () =>
      apiAssignHr({
        user_id: dataInfoUser.user_id,
        candidate_ids: listCandidatePicked,
        hr_id: hrPicked.user_id,
      }),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setOpenPopupConfirm(false);
          setIsAssignSuccess(true);
          setShowModal(false);
          queryClient.invalidateQueries([USE_GET_LIST_CANDIDATE], { refetchActive: true });
          onSuccessAssign();
          setNameHrSearch(null);
          setHrPicked({ id: null, name: null });
        } else {
          setOpenPopupConfirm(false);
          setIsAssignFailure(true);
        }
      },
    },
  );
  const handleClickHR = (hr) => {
    if (hr.disable) return;
    if (hrPicked.user_id === hr.user_id) setHrPicked({ id: null, name: null });
    else setHrPicked(hr);
  };

  const renderModalFooter = () => (
    <Grid container spacing={2} className={styles.buttonModalGroup}>
      <Grid item xs={6}>
        <ButtonComponent
          text={t('managerCandidate.cancel')}
          addClass={styles.buttonCancel}
          handleClick={() => setShowModal(false)}
        />
      </Grid>
      <Grid item xs={6}>
        <ButtonComponent
          text={t('managerCandidate.assignEn')}
          addClass={styles.buttonAssign}
          type="submit"
          handleClick={() => setOpenPopupConfirm(true)}
          hasDisabled={!hrPicked?.user_name}
        />
      </Grid>
    </Grid>
  );

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => {
    if (isLoadingAssign)
      return (
        <div className={styles.loadingIcon}>
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <ButtonComponent addClass={styles.buttonCancelConfirm} text={t('common.cancel')} handleClick={onClickCancel} />
        <ButtonComponent addClass={styles.buttonSubmitConfirm} text={t('common.ok')} handleClick={onClickOk} />
      </>
    );
  };

  return (
    <>
      {showModal && (
        <ModalComponent
          isShowModal={showModal}
          title={t('managerCandidate.assignHRCharge')}
          toggle={() => setShowModal(false)}
          classNameAdd={styles.modalAssign}
          renderModalFooter={renderModalFooter}
        >
          <SearchComponent
            txtPlaceholder={t('managerCandidate.nameHR')}
            maxLength={MAX_LENGTH_50}
            size="medium"
            addClass={styles.searchHr}
            handleSearch={(value) => setNameHrSearch(value)}
          />
          <p className={styles.titleListHR}>{t('manageKPI.listHR')}</p>
          {isLoadingListHr && <Loading />}
          {!isLoadingListHr && (
            <div className={styles.listHr}>
              {listHr &&
                listHr?.map((item) => (
                  <div
                    key={item.user_id}
                    onClick={() => handleClickHR(item)}
                    className={classNames(styles.rowItem, {
                      [styles.isPickedRow]: item.user_id === hrPicked.user_id,
                      [styles.disabledRow]: !!item?.disable,
                    })}
                  >
                    <div className={styles.avatar}>
                      <Avatar src={item.avatar ? item.avatar : defaultAvatar} />
                    </div>
                    <div>
                      <span className={styles.name}>{item.user_name}</span>
                      <span className={styles.emailAndPosition}>{item.email + ' - ' + item.position_name}</span>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {!isLoadingListHr && !listHr?.length && <div className="error-no-data"> {t('common.notData')}</div>}
        </ModalComponent>
      )}
      <PopupAreYouSure
        active={openPopupConfirm}
        handleCancel={() => setOpenPopupConfirm(false)}
        messages={`${t('messages.doYouWantAssignThisCandidateToHr')} ${hrPicked.user_name}?`}
        footer={<FooterPopupConfirm onClickCancel={() => setOpenPopupConfirm(false)} onClickOk={() => assignHr()} />}
      />
      <AlertAfterSubmit
        toggle={() => setIsAssignSuccess(false)}
        isShowAlert={isAssignSuccess}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('managerCandidate.assignSuccess')}</p>
      </AlertAfterSubmit>
      <AlertAfterSubmit
        toggle={() => setIsAssignFailure(false)}
        isShowAlert={isAssignFailure}
        classNameAdd={styles.alertAfterSubmit}
      >
        <p>{t('common.anUnknownError')}</p>
      </AlertAfterSubmit>
    </>
  );
};

export default AssignModal;
