export const USE_LIST_QUESTIONINTERVIEW = 'USE_LIST_QUESTIONINTERVIEW';
export const ADD_QUESTION_INTERVIEW = 'ADD_QUESTION_INTERVIEW';
export const GET_ITEM_QUESTION_DETAIL = 'GET_ITEM_QUESTION_DETAIL';
export const QUESTION_INTERVIEW_ID = 'QUESTION_INTERVIEW_ID';
export const QUESTION_FORMDATA = 'QUESTION_FORMDATA';
export const GET_QUESTION_INTERVIEW = 'GET_QUESTION_INTERVIEW';

export const TYPE_SOFT_QUESTION = 1;
export const TYPE_MAJOR_QUESTION = 2;

export const ACTIVE_STATUS_QUESTION = 1;
export const INACTIVE_STATUS_QUESTION = 0;
