import { useQuery } from 'react-query';
import * as Constants from 'constants/ManagerCandidate';
import { HTTP_OK } from 'constants/StatusCode';
import { apiGetListLeaderAssesCandidate, apiGetAverageAssesCandidate } from 'api/managerCandidate';

export function useGetListLeaderAssessCandidate(applyId, type) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_LEADER_ASSESS_CANDIDATE, applyId], async () => {
    const response = await apiGetListLeaderAssesCandidate(applyId, type);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });
  return { ...response, error, isLoading };
}

export function useGetAverageAssesCandidate(applyId) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_AVERAGE_ASSESS_CANDIDATE, applyId], async () => {
    const response = await apiGetAverageAssesCandidate(applyId);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });
  return { ...response, error, isLoading };
}
