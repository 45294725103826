import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './styles.scss';

// import DetailApplyLeader from './DetailApplyLeader/detailApplyLeader';
import DetailApplyLeaderPassFail from './DetailApplyLeaderPass/detailApplyLeaderPassFail';

import { useGetListAssess } from 'hook/useGetListAssess';
import { useGetListLeaderAssessCandidate } from 'hook/useDetailApplyLeader';
import { TYPE_CANDIDATE_LEADER } from 'constants/ManagerCandidate';

const LeaderDetailApply = () => {
  const { applyId } = useParams();
  const [dataLeaderAssess, setDataLeaderAssess] = useState();

  const { data: uLeaderAssess } = useGetListLeaderAssessCandidate(applyId, TYPE_CANDIDATE_LEADER);
  const { data: uGetListAssess } = useGetListAssess(applyId);

  useEffect(() => {
    if (uLeaderAssess && Object.keys(uLeaderAssess).length !== 0) {
      setDataLeaderAssess(Object.values(uLeaderAssess));
    }
  }, [uLeaderAssess]);

  return (
    <div>
      {dataLeaderAssess &&
        dataLeaderAssess.map((itemAssess) => {
          if (!itemAssess?.result) {
            return <> </>;
            // return <DetailApplyLeader dataStatus={itemAssess} type="leader" userId={auth.id} />;
          } else {
            return (
              <DetailApplyLeaderPassFail
                dataStatusNotRate={itemAssess}
                dataStatusPass={itemAssess}
                dataStatusFail={itemAssess}
                applyId={applyId}
                uGetListAssess={uGetListAssess}
              />
            );
          }
        })}
    </div>
  );
};

export default LeaderDetailApply;
