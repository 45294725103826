import React from 'react';
import stylesTable from 'styles/table.module.scss';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Loading from 'components/Loading';
import { getSTT } from 'helpers/table';
import { useTranslation } from 'react-i18next';
import { formatNumberOfVi } from 'helpers/format';
import $ from 'jquery';

const TableForLeader = ({
  isLoadingCostLeader,
  dataListCostLeader,
  filter,
  mapPosition,
  mapSkill,
  mapLevel,
  total,
}) => {
  const { t } = useTranslation();
  $('.wrapper-top').scroll(function () {
    $('.scroll-custom').scrollLeft($('.wrapper-top').scrollLeft());
  });
  $('.scroll-custom').scroll(function () {
    $('.wrapper-top').scrollLeft($('.scroll-custom').scrollLeft());
  });
  return (
    <>
      <div className="wrapper-top scroll-of-dl">
        <div className="top opacity-0">
          <Table aria-label="sticky table">
            <TableHead className="asset-header">
              <TableRow className="asset-header-row headTable">
                <TableCell>{t('common.STT')}</TableCell>
                <TableCell>{t('manageCostRecruitLeader.fullName')}</TableCell>
                <TableCell>{t('manageCostRecruitLeader.employeeCode')}</TableCell>
                <TableCell>{t('manageCostRecruitLeader.position')}</TableCell>
                <TableCell>{t('manageCostRecruitLeader.skill')}</TableCell>
                <TableCell>{t('manageCostRecruitLeader.officialLevel')}</TableCell>
                <TableCell>{t('manageCostRecruitLeader.officialCreated')}</TableCell>
                <TableCell>{t('manageCostRecruitLeader.officialSalary')}</TableCell>
                <TableCell>{t('manageCostRecruitLeader.moneyHaveToPay')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingCostLeader && (
                <TableRow>
                  <TableCell colSpan={12}>
                    <Loading addClass="mt-3 mb-3" />
                  </TableCell>
                </TableRow>
              )}

              {dataListCostLeader &&
                dataListCostLeader.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell className={stylesTable.sttCell}>{getSTT(index + filter.page * filter.limit)}</TableCell>
                    <TableCell className={stylesTable.normalCell}>{item.apply_info?.candidate?.full_name}</TableCell>
                    <TableCell className={stylesTable.normalCell}>{item.user_code}</TableCell>
                    <TableCell className={stylesTable.normalCell}>{mapPosition[item.position_id]}</TableCell>
                    <TableCell className={stylesTable.normalCell}>{mapSkill[item.skill_id]}</TableCell>
                    <TableCell className={stylesTable.normalCell}>{mapLevel[item.level_id]}</TableCell>
                    <TableCell className={stylesTable.normalCell}>{item.official_date}</TableCell>
                    <TableCell className={stylesTable.normalCell}>{formatNumberOfVi(item.salary)}</TableCell>
                    <TableCell className={stylesTable.normalCell}>{formatNumberOfVi(item.recruit_cost)}</TableCell>
                  </TableRow>
                ))}

              {(!dataListCostLeader && !isLoadingCostLeader) ||
                (total === 0 && (
                  <tr>
                    <td colSpan={12} className="error-no-data">
                      {t('common.notData')}
                    </td>
                  </tr>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <TableContainer className={stylesTable.tableCommon + ' table-border-show scroll-custom'}>
        <Table aria-label="sticky table">
          <TableHead className="asset-header">
            <TableRow className="asset-header-row headTable">
              <TableCell>{t('common.STT')}</TableCell>
              <TableCell>{t('manageCostRecruitLeader.fullName')}</TableCell>
              <TableCell>{t('manageCostRecruitLeader.employeeCode')}</TableCell>
              <TableCell>{t('manageCostRecruitLeader.position')}</TableCell>
              <TableCell>{t('manageCostRecruitLeader.skill')}</TableCell>
              <TableCell>{t('manageCostRecruitLeader.officialLevel')}</TableCell>
              <TableCell>{t('manageCostRecruitLeader.officialCreated')}</TableCell>
              <TableCell>{t('manageCostRecruitLeader.officialSalary')}</TableCell>
              <TableCell>{t('manageCostRecruitLeader.moneyHaveToPay')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingCostLeader && (
              <TableRow>
                <TableCell colSpan={12}>
                  <Loading addClass="mt-3 mb-3" />
                </TableCell>
              </TableRow>
            )}

            {dataListCostLeader &&
              dataListCostLeader.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell className={stylesTable.sttCell}>{getSTT(index + filter.page * filter.limit)}</TableCell>
                  <TableCell className={stylesTable.normalCell}>{item.apply_info?.candidate?.full_name}</TableCell>
                  <TableCell className={stylesTable.normalCell}>{item.user_code}</TableCell>
                  <TableCell className={stylesTable.normalCell}>{mapPosition[item.position_id]}</TableCell>
                  <TableCell className={stylesTable.normalCell}>{mapSkill[item.skill_id]}</TableCell>
                  <TableCell className={stylesTable.normalCell}>{mapLevel[item.level_id]}</TableCell>
                  <TableCell className={stylesTable.normalCell}>{item.official_date}</TableCell>
                  <TableCell className={stylesTable.normalCell}>{formatNumberOfVi(item.salary)}</TableCell>
                  <TableCell className={stylesTable.normalCell}>{formatNumberOfVi(item.recruit_cost)}</TableCell>
                </TableRow>
              ))}

            {(!dataListCostLeader && !isLoadingCostLeader) ||
              (total === 0 && (
                <tr>
                  <td colSpan={12} className="error-no-data">
                    {t('common.notData')}
                  </td>
                </tr>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableForLeader;
