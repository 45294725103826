import configs from 'config';
import { TYPE_LINK_WIKI, TAB_GROUP, TYPE_LINK_RECRUIT } from 'constants/notification';

const getIdCommentParent = (item) => {
  if (!item?.comment) {
    return;
  }
  const postComment = JSON.parse(item.comment);
  return postComment?.grand_id || postComment?.parent_id || postComment?.id;
};

const getIdScrollTo = (item) => {
  if (!item?.comment) {
    return;
  }
  const postComment = JSON.parse(item.comment);
  return postComment?.id || postComment?.parent_id || postComment?.grand_id;
};

export const getUrlNotificationToWiki = (type, notification) => {
  const WIKI_URL = configs.DOMAIN_AMS_WIKI;
  const { group_id: groupId, post_id: postId, series_id: seriesId } = notification;
  switch (type) {
    case TYPE_LINK_WIKI.DETAIL_POST:
    case TYPE_LINK_WIKI.DETAIL_REPORT:
      if (groupId) {
        return WIKI_URL + `/group/detail-group/${groupId}/${postId}`;
      }
      return WIKI_URL + `/home/detail-post/${postId}`;
    case TYPE_LINK_WIKI.DETAIL_COMMENT:
      if (groupId) {
        return (
          WIKI_URL +
          `/group/detail-group/${groupId}/${postId}?idNotice=${getIdCommentParent(
            notification,
          )}&idScroll=${getIdScrollTo(notification)}`
        );
      }
      return (
        WIKI_URL +
        `/home/detail-post/${postId}?idNotice=${getIdCommentParent(notification)}&idScroll=${getIdScrollTo(
          notification,
        )}`
      );
    case TYPE_LINK_WIKI.DETAIL_POST_GROUP:
      return WIKI_URL + `/group/classification/${groupId}`;
    case TYPE_LINK_WIKI.SERIES:
      if (groupId) {
        return WIKI_URL + `/group/list-series/${groupId}/${seriesId}`;
      }
      return WIKI_URL + `/home/list-series/${seriesId}`;
    case TYPE_LINK_WIKI.GROUP_APPROVE_MEMBER:
      if (groupId) {
        return WIKI_URL + `/group/user-manage-group/${groupId}?tab=${TAB_GROUP.REQUEST_JOIN}`;
      }
      break;
    case TYPE_LINK_WIKI.GROUP_APPROVE_POST:
      if (groupId) {
        return WIKI_URL + `/group/user-manage-group/${groupId}?tab=${TAB_GROUP.LIST_WAITING}`;
      }
      break;
    case TYPE_LINK_WIKI.GROUP_LIST_REPORT:
      if (groupId) {
        return WIKI_URL + `/group/user-manage-group/${groupId}?tab=${TAB_GROUP.LIST_REPORT}`;
      }
      break;
    default:
      return '';
  }
};

export const getUrlNotificationToRecruit = (type, notification) => {
  let detail = JSON.parse(notification?.detail);
  if (!detail) detail = {};
  const { interview_date, interview_id, job_id, order_id, candidate_id, apply_id, kpi_id } = detail;
  switch (type) {
    case TYPE_LINK_RECRUIT.DETAIL_APPLY_INTERVIEW_LEADER:
      return `/admin/candidate-detail/${candidate_id}/apply-detail/${apply_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_INTERVIEW_SCHEDULE:
      return `/admin/interview-schedule/${interview_date}/${interview_id}`;
    case TYPE_LINK_RECRUIT.LIST_INTERVIEW_SCHEDULE:
      return `/admin/interview-schedule`;
    case TYPE_LINK_RECRUIT.DETAIL_ORDER_LEADER:
      return `/admin/manager-order/${order_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_JOB:
      return `/admin/manager-job/${job_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_ORDER_HR:
      return `/admin/manager-order/${order_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_APPLY_INTERVIEW_HR:
      return `/admin/candidate-detail/${candidate_id}/apply-detail/${apply_id}`;
    case TYPE_LINK_RECRUIT.DETAIL_APPLY_TEST_HR:
      return `/admin/candidate-detail/${candidate_id}/apply-detail/${apply_id}/test`;
    case TYPE_LINK_RECRUIT.LIST_KPI:
      return `/admin/manager-kpi`;
    case TYPE_LINK_RECRUIT.DETAIL_KPI:
      return `/admin/manager-kpi/${kpi_id}`;
    case TYPE_LINK_RECRUIT.LIST_REF_BONUS:
      return `/admin/list-referral-bonus`;
    case TYPE_LINK_RECRUIT.LIST_COST_RECRUIT:
      return `/admin/list-cost-recruit`;
    case TYPE_LINK_RECRUIT.DETAIL_CANDIDATE_HR:
      return `/admin/candidate-detail/${candidate_id}`;
    default:
      return '';
  }
};

export const getUrlNotificationToProject = (type, notification) => {
  const detail = notification?.detail;
  if (detail.length) {
    const detailPath = JSON.parse(detail);
    if (detailPath.path.length) return detailPath.path;
  }

  return `/projects/detail/${notification?.project_id}`;
};

