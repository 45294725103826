export const STATUS_PROCESSING = 1;
export const STATUS_COMPLETED = 3;
export const STATUS_LATE_DEADLINE = 2;
export const STATUS_CANCELED = 4;

export const IS_FREELANCER = 1;
export const IS_NOT_FREELANCER = 0;

export const INFO_GENERAL = 'infoGeneral';
export const INFO_ORDER = 'infoOrder';
export const QUANTITY_CANDIDATE = 'quantityCandidate';

export const APPLIES_AVAILABLE = 'appliesAvailable';
export const CREATE_NEW_APPLIES = 'createNewApplies';

export const LIST_STATUS_JOB = [
  { name: 'managerJob.doing', id: STATUS_PROCESSING },
  { name: 'managerJob.done', id: STATUS_COMPLETED },
  { name: 'managerJob.lateDeadline', id: STATUS_LATE_DEADLINE },
];

export const LIST_STATUS_ORDER = [
  { status: 1, name: 'managerOrderHr.waitBodAccept' },
  { status: 2, name: 'managerOrderHr.bodDontAccept' },
  { status: 3, name: 'managerOrderHr.processing' },
  { status: 4, name: 'managerOrderHr.deadlineLate' },
  { status: 5, name: 'managerOrderHr.cancelOrder' },
  { status: 6, name: 'managerOrderHr.completed' },
];
