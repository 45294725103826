import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import moment from 'moment';
import classNames from 'classnames';

import { Grid, FormControlLabel, Radio, RadioGroup, MenuItem, Avatar, debounce } from '@mui/material';
import Box from '@mui/material/Box';
import { ModalFooter } from 'reactstrap';

import './styles.scss';
import styles from './styles.module.scss';
import { customDate, checkInvalidDateByDiagonalLine, formatDateIgnoreDiagonalLine } from 'helpers/formatDate';
import { useGetLocationsList, useGetRoomsList } from 'hook/useManagerCandidate';
import { useListUsers as useGetInterviewersList } from 'hook/useMasterData';
import { apiCreateScheduleInterview } from 'api/managerCandidate';
import { INTERVIEW_TYPE } from 'constants/InterviewScheduleManagement';
import { ROWS_TEXT_AREA_3, MAX_LENGTH_50, MAX_LENGTH_150, DEFAULT_PAGE_MUI, MIN_LENGTH_1 } from 'constants/index';
import { USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL } from 'constants/ManagerCandidate';

import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import CustomAutoComplete from 'components/InputYupAndMui/CustomAutoComplete';
import DateDay from 'components/ReactForm/DateDay';
import SelectField from 'components/ReactForm/SelectField';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import TimePickerCustom from 'components/DateTime/TimePickerCustom';
import Loading from 'components/Loading';

const FORM_FIELDS = {
  title: 'title',
  date: 'date',
  type: 'type',
  attendees: 'attendees',
  location: 'location',
  room_id: 'room_id',
  link: 'link',
  note: 'note',
  start_time: 'start_time',
  end_time: 'end_time',
};

const ModalBookInterview = (props) => {
  const { isShowModal, setShowModal, candidateData, listLeaderAutoFill } = props;
  const { t } = useTranslation();
  const query = useQueryClient();

  const [showLinkField, setShowLinkField] = useState(false);
  const [interviewerKeyword, setInterviewerKeyword] = useState('');
  const { data: interviewersList } = useGetInterviewersList({
    key_word: interviewerKeyword,
    page: DEFAULT_PAGE_MUI,
  });
  const [locationId, setLocationId] = useState(null);
  const [isCreateSuccess, setIsCreateSuccess] = useState(false);
  const { data: locationsList } = useGetLocationsList();
  const [timeInterview, setTimeInterview] = useState({ date: null, start_time: null, end_time: null });

  const handleGetDateTime = (keyField) => {
    if (locationId && timeInterview.date && timeInterview.start_time && timeInterview.end_time) {
      return (
        moment(timeInterview.date, 'DD/MM/YYYY').format('YYYY-MM-DD') +
        ' ' +
        customDate(timeInterview[keyField], 'HH:mm:ss')
      );
    }
  };

  const { data: roomsList, isLoading: isLoadingRoom } = useGetRoomsList({
    location_id: timeInterview.start_time && timeInterview.end_time && locationId,
    startTime: handleGetDateTime('start_time'),
    endTime: handleGetDateTime('end_time'),
  });

  const DEFAULT_VALUES = {
    [FORM_FIELDS.title]: '',
    [FORM_FIELDS.link]: '',
    [FORM_FIELDS.attendees]: listLeaderAutoFill || [],
    [FORM_FIELDS.location]: '',
    [FORM_FIELDS.room_id]: '',
    [FORM_FIELDS.note]: '',
    [FORM_FIELDS.type]: 2,
    [FORM_FIELDS.start_time]: '',
    [FORM_FIELDS.end_time]: '',
  };
  
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        [FORM_FIELDS.title]: Yup.string().required(t('messages.pleaseEnterOneToFiftyCharacters')),
        [FORM_FIELDS.attendees]: Yup.array().min(MIN_LENGTH_1, t('messages.pleaseEnterInterviewerName')),
        [FORM_FIELDS.date]: Yup.string()
          .required(t('messages.pleaseEnterInterviewDate'))
          .test('format', t('messages.pleaseEnterTheCorrectFormatDate'), (value) => {
            return checkInvalidDateByDiagonalLine(value);
          })
          .test('min', t('messages.pleaseEnterTheDateInFuture'), (date) => {
            return !moment(formatDateIgnoreDiagonalLine(date), 'DD/MM/YYYY').isBefore(moment(), 'day');
          })
          .nullable(),
        [FORM_FIELDS.location]: Yup.string().required(t('messages.pleaseEnterLocation')),
        [FORM_FIELDS.room_id]: Yup.string().required(t('messages.pleaseEnterRoom')),
        [FORM_FIELDS.link]: showLinkField && Yup.string().required(t('messages.pleaseEnterInterviewLink')),
        [FORM_FIELDS.start_time]: Yup.string()
          .required(t('messages.pleaseEnterInterviewTime'))
          .test('min', t('messages.pleaseEnterInterviewTimeMoreNow'), (val) => {
            return timeInterview.date === moment().format('DD/MM/YYYY') ? moment().isBefore(moment(val)) : true;
          })
          .nullable(),
        [FORM_FIELDS.end_time]: Yup.string()
          .required(t('messages.pleaseEnterInterviewTime'))
          .test('min', t('messages.pleaseEnterInterviewTimeMoreStartTime'), (val) => {
            return timeInterview.start_time ? moment(val).isAfter(moment(timeInterview.start_time)) : true;
          })
          .nullable(),
        [FORM_FIELDS.note]: Yup.string().max(MAX_LENGTH_150, t('messages.pleaseEnterInterviewerName')),
      }),
    ), // add validate
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const { mutate: submitCreateScheduleInterview, isLoading } = useMutation(
    async (data) => {
      delete data.location;
      if (data.type === INTERVIEW_TYPE.OFFLINE) delete data.link;
      await apiCreateScheduleInterview({
        ...data,
        start_time: customDate(data.start_time, 'HH:mm:ss'),
        end_time: customDate(data.end_time, 'HH:mm:ss'),
        apply_id: candidateData.apply_id,
        type: Number(data.type),
        date: moment(data.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      });
    },
    {
      onSuccess: async () => {
        query.invalidateQueries([USE_GET_DETAIL_CANDIDATE_APPLY_DETAIL], { refetchActive: true });
        setIsCreateSuccess(true);
      },
    },
  );

  const InfoBasic = ({ title, value }) => (
    <>
      <div className={styles.titleInfoBasic}>{title}</div>
      <div className={styles.valueInfoBasic}>{value}</div>
      <div className="divider w-100" />
    </>
  );

  const addNameObjectKey = (arr) => {
    if (!arr) return;
    return arr.filter((object) => {
      object.name = object.user_name;
      return object;
    });
  };

  const CustomLabelOption = ({ option }) => (
    <div key={option.user_id} className={styles.customLabel}>
      <div className={styles.avatarLabel}>
        <Avatar src={option.avatar} />
      </div>
      <div className={styles.infoLabel}>
        <div className={styles.name}>{option.name}</div>
        <div>{option.email}</div>
      </div>
    </div>
  );

  const handleSetValueDate = (name, value) => {
    setTimeInterview({ ...timeInterview, [name]: value });
    setValue(name, value);
  };

  const watchStartTime = useWatch({
    control,
    name: FORM_FIELDS.start_time,
  });

  const watchEndTime = useWatch({
    control,
    name: FORM_FIELDS.end_time,
  });

  const watchDate = useWatch({
    control,
    name: FORM_FIELDS.date,
  });

  useEffect(() => {
    setTimeInterview({ ...timeInterview, start_time: watchStartTime });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStartTime])

  useEffect(() => {
    setTimeInterview({ ...timeInterview, end_time: watchEndTime });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEndTime]);

  useEffect(() => {
    if (
      timeInterview.date === moment().format('DD/MM/YYYY') &&
      watchStartTime &&
      moment(watchStartTime).isValid() &&
      moment(watchStartTime).isBefore(moment())
    ) {
      setError(FORM_FIELDS.start_time, {
        type: 'required',
        message: t('messages.pleaseEnterInterviewTimeMoreNow'),
      });
    }
    if (watchDate && timeInterview.date !== moment().format('DD/MM/YYYY')) {
      setError(FORM_FIELDS.start_time, {
        type: 'required',
        message: null,
      });
    }
    if (watchStartTime && watchEndTime && moment(watchStartTime).isValid()) {
      const month = watchStartTime.month();
      const date = watchStartTime.date();
      watchEndTime.set('month', month);
      watchEndTime.set('date', date);
      if (watchEndTime && watchStartTime && watchEndTime.isBefore(watchStartTime)) {
        setError(FORM_FIELDS.end_time, {
          type: 'required',
          message: t('messages.pleaseEnterInterviewTimeMoreStartTime'),
        });
      } else {
        setError(FORM_FIELDS.end_time, '');
      }
    } else {
      if (watchEndTime && watchStartTime && watchEndTime.isBefore(watchStartTime)) {
        setError(FORM_FIELDS.end_time, {
          type: 'required',
          message: t('messages.pleaseEnterInterviewTimeMoreStartTime'),
        });
      }
    }
    // eslint-disable-next-line
  }, [watchStartTime, watchEndTime, watchDate]);

  // eslint-disable-next-line
  const handleSearchKeyword = useCallback(
    debounce((keyword) => {
      setInterviewerKeyword(keyword);
    }, 500),
  );

  return (
    <>
      <AlertAfterSubmit
        isShowAlert={isCreateSuccess}
        toggle={() => {
          setIsCreateSuccess(false);
          setShowModal(false);
        }}
      >
        <p>{t('managerJob.createInterviewScheduleSuccess')}</p>
      </AlertAfterSubmit>
      <ModalComponent
        toggle={() => setShowModal(false)}
        isShowModal={isShowModal}
        title={t('managerCandidate.createInterviewSchedule')}
        renderModalFooter={() => (
          <ModalFooter className={styles.modalFooter}>
            <ButtonComponent
              text={t('managerJD.cancel')}
              addClass={classNames(styles.btn, styles.btn__cancel)}
              handleClick={() => setShowModal(false)}
            />
            <ButtonComponent
              text={t('common.create')}
              addClass={classNames(styles.btn, styles.btn__action)}
              type="submit"
              handleClick={handleSubmit(submitCreateScheduleInterview)}
              hasDisabled={isLoading}
              isLoading={isLoading}
            />
          </ModalFooter>
        )}
        classNameAdd="modal-book-interview"
      >
        <Box autoComplete="off">
          <Grid container spacing={3} className={styles.gridContainer}>
            <Grid item xs={12} className={styles.infoTitle}>
              {t('managerCandidate.infoCandidate')}
            </Grid>
            <Grid item xs={12}>
              <InfoBasic title={t('managerCandidate.fullNameCandidate')} value={candidateData?.fullName} />
            </Grid>
            <Grid item xs={4}>
              <InfoBasic title={t('masterData.StandardIndex.position')} value={candidateData?.positionName} />
            </Grid>
            <Grid item xs={4}>
              <InfoBasic title={t('masterData.StandardIndex.levelApply')} value={candidateData?.levelName} />
            </Grid>
            <Grid item xs={4}>
              <InfoBasic title={t('masterData.StandardIndex.skill')} value={candidateData?.skillName} />
            </Grid>
            <Grid xs={12} className={styles.infoTitle}>
              {t('interviewSchedule.editModal.form')}
            </Grid>
            <Grid item xs={12}>
              <CustomInputYup
                label={t('common.title')}
                name={FORM_FIELDS.title}
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                required
                maxLength={MAX_LENGTH_50}
              />
            </Grid>
            <Grid item xs={12} className="interviewer">
              <CustomAutoComplete
                name={FORM_FIELDS.attendees}
                label={t('managerCandidate.enterTagName') + '*'}
                control={control}
                setValue={setValue}
                setError={setError}
                register={register}
                errors={errors}
                valueAutoFill={addNameObjectKey(interviewersList)}
                CustomLabelOption={CustomLabelOption}
                handleSearch={(keyword) => handleSearchKeyword(keyword)}
                defaultValue={listLeaderAutoFill}
              />
            </Grid>
            <Grid item xs={12}>
              <DateDay
                label={t('managerCandidate.dateInterview')}
                name={FORM_FIELDS.date}
                control={control}
                error={errors[FORM_FIELDS.date]?.message}
                setValue={handleSetValueDate}
                register={register}
                setError={setError}
                isShowModal={isShowModal}
                isRequired
                minDate={moment()}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs={6} className="date-label">
              <TimePickerCustom
                placeholder={t('interviewSchedule.detailModal.label.startTime')}
                name={FORM_FIELDS.start_time}
                value={watchStartTime}
                format="HH:mm"
                setValue={setValue}
                setError={setError}
                handleChangeTime={(time) => {
                  handleSetValueDate(
                    FORM_FIELDS.start_time,
                    moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null,
                  );
                }}
                error={errors[FORM_FIELDS.start_time]?.message}
                disabled={!timeInterview.date}
              />
            </Grid>
            <Grid item xs={6} className="date-label">
              <TimePickerCustom
                placeholder={t('interviewSchedule.detailModal.label.endTime')}
                name={FORM_FIELDS.end_time}
                value={watchEndTime}
                format="HH:mm"
                setValue={setValue}
                setError={setError}
                handleChangeTime={(time) => {
                  handleSetValueDate(
                    FORM_FIELDS.end_time,
                    moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null,
                  );
                }}
                error={errors[FORM_FIELDS.end_time]?.message}
                disabled={!timeInterview.date}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                name={FORM_FIELDS.location}
                label={t('interviewSchedule.detailModal.label.location')}
                error={errors[FORM_FIELDS.location]?.message}
                control={control}
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
              >
                {locationsList?.map((item) => (
                  <MenuItem value={item.id} key={item.id} onClick={() => setLocationId(item.id)}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12}>
              <SelectField
                name={FORM_FIELDS.room_id}
                label={t('interviewSchedule.detailModal.label.room')}
                error={errors[FORM_FIELDS.room_id]?.message}
                control={control}
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                disabled={!locationId || !timeInterview.start_time || !timeInterview.end_time}
                onClick={() => {
                  if (!locationId && timeInterview.start_time && timeInterview.end_time) {
                    setError(FORM_FIELDS.location, { type: 'custom', message: t('messages.pleaseEnterLocation') });
                  }
                }}
              >
                {isLoadingRoom && <Loading />}
                {!isLoadingRoom &&
                  roomsList?.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </SelectField>
            </Grid>
            <Grid item xs={12} className={styles.infoTitle}>
              {t('managerCandidate.interviewType')}
            </Grid>
            <Grid item xs={12}>
              <RadioGroup row className={styles.radioGroup} defaultValue={INTERVIEW_TYPE.OFFLINE}>
                <FormControlLabel
                  value={INTERVIEW_TYPE.OFFLINE}
                  onChange={(e) => {
                    setValue(FORM_FIELDS.type, e.target.value);
                    setShowLinkField(false);
                  }}
                  control={<Radio />}
                  label={t('interviewSchedule.editModal.interviewType.offline')}
                />
                <FormControlLabel
                  value={INTERVIEW_TYPE.ONLINE}
                  onChange={(e) => {
                    setValue(FORM_FIELDS.type, e.target.value);
                    setShowLinkField(true);
                  }}
                  control={<Radio />}
                  label={t('interviewSchedule.editModal.interviewType.online')}
                />
              </RadioGroup>
            </Grid>
            {showLinkField && (
              <Grid item xs={12}>
                <CustomInputYup
                  label={t('interviewSchedule.detailModal.label.interviewLink')}
                  name={FORM_FIELDS.link}
                  control={control}
                  register={register}
                  errors={errors}
                  useWatch={useWatch}
                  required
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CustomInputYup
                label={t('interviewSchedule.detailModal.label.note')}
                name={FORM_FIELDS.note}
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                maxLength={MAX_LENGTH_150}
                multiline
                rows={ROWS_TEXT_AREA_3}
              />
            </Grid>
          </Grid>
        </Box>
      </ModalComponent>
    </>
  );
};

export default ModalBookInterview;
