import React from 'react';
import { InputBase } from '@mui/material';
import { ZERO } from 'constants/MasterData';
import * as Constants from 'constants/index.js';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import { useTranslation } from 'react-i18next';
import { changNameLevel } from 'helpers/modal';

const FilterOrder = ({ setIsModals, setFilter, data, setData, isModals, filter, changeSelect, type }) => {
  const { t } = useTranslation();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getValueInput = () => {
    return (
      data &&
      (!data[ZERO]?.status
        ? data
            .map((item, i) => (item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : ''))
            .filter((it) => it)
        : t('common.all'))
    );
  };

  const handleSubmitSearch = () => {
    let datas = [];
    data.forEach((it) => {
      it.status && it.id && datas.push(it.id);
    });
    setFilter({
      ...filter,
      [type + '_id']: datas,
      page: Constants.DEFAULT_PAGE_MUI,
    });
    setIsModals((prev) => {
      return { ...prev, [type]: false };
    });
  };

  const handleChangeSelected = () => {
    setIsModals((prev) => {
      return { ...prev, [type]: !prev[type] };
    });
    if (filter[type + '_id']) {
      data?.forEach((item) => {
        Number(filter[type + '_id']) === Number(item?.id) ? (item.status = true) : (item.status = false);
      });
      setData([...data]);
    } else {
      data?.forEach((item) => {
        item.status = false;
      });
      setData([...data]);
    }
  };
  const handleChangeLevelSelected = () => {
    setIsModals((prev) => {
      return { ...prev, [type]: !prev[type] };
    });
    if (filter[type + '_id']) {
      data?.forEach((item) => {
        filter[type + '_id'].includes(item?.id) ? (item.status = true) : (item.status = false);
      });
      setData([...data]);
    } else {
      data?.forEach((item) => {
        item.status = false;
      });
      setData([...data]);
    }
  };

  return (
    <>
      <InputBase
        disabled
        type="text"
        name={type + '_id'}
        className="input-select-multi mt-3"
        onClick={() => {
          setIsModals(() => {
            return { [type]: true };
          });
        }}
        value={type === 'level' ? changNameLevel(data) : getValueInput()}
        placeholder={t('masterData.StandardIndex.' + type)}
      />
      <ModalCommon
        items={data}
        onSubmit={handleSubmitSearch}
        onClick={changeSelect}
        toggle={type === 'level' ? handleChangeLevelSelected : handleChangeSelected}
        modal={isModals[type]}
        title={t('masterData.StandardIndex.choose' + capitalizeFirstLetter(type))}
        footer={t('common.select')}
      />
    </>
  );
};

export default FilterOrder;
