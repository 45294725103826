import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import iconUpdateWishes from 'assets/img/icons/candidate/updateWhisesCandidate.svg';
import { customDate } from 'helpers/formatDate';
import ModalDesired from './ModalDesired/index';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import EditLayout from 'components/PermissionPage/EditLayout';
import { CANDIDATE_PERMISSION } from 'constants/permission';
import { ThemContext } from 'layouts/Admin';
import { useContext } from 'react';

const DesiredCandidate = ({ applyDetail, isLeader }) => {
  const { isAssigner } = useContext(ThemContext);
  const { t } = useTranslation();
  const [showModalDesired, setShowModalDesired] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  return (
    !isAssigner && (
      <Grid className="bodyDesiredCandidate">
        <ModalDesired
          isShowModal={showModalDesired}
          setShowModal={setShowModalDesired}
          onSuccess={() => setIsUpdateSuccess(true)}
          dataDesired={applyDetail}
        />
        <AlertAfterSubmit toggle={() => setIsUpdateSuccess(false)} isShowAlert={isUpdateSuccess}>
          <p>{t('managerCandidate.updateDesiredSuccess')}</p>
        </AlertAfterSubmit>
        <Grid xs={12} container className="titleDesiredCandidate">
          <div className="titleInfoWishes">{t('managerCandidate.desireCandidate')}</div>
          {!isLeader && (
            <EditLayout permissionName={CANDIDATE_PERMISSION}>
              <button className="buttonSettingDesired" onClick={() => setShowModalDesired(true)}>
                <img className="imgIconUpdateWishes" alt="updateWishesCandidate" src={iconUpdateWishes} />
              </button>
            </EditLayout>
          )}

          <Grid className="bodyContentDesiredCandidate" xs={12}>
            <div className="titleDesiredCandidate">{t('managerCandidate.salary')}</div>
            <div className="contentDesiredCandidate">
              {applyDetail?.salary_expect &&
                new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'VND' }).format(
                  applyDetail.salary_expect,
                )}
            </div>
          </Grid>
          <Grid className="bodyContentDesiredCandidate" xs={12}>
            <div className="titleDesiredCandidate">{t('managerCandidate.startDate')}</div>
            <div className="contentDesiredCandidate">{customDate(applyDetail?.date_work_expect, 'DD/MM/YYYY')}</div>
          </Grid>
          <Grid className="bodyContentDesiredCandidate flex-column" xs={12}>
            <div className="titleDesiredCandidate">{t('managerCandidate.otherWishes')}</div>
            <div className="mt-2">
              {applyDetail?.expect_other?.split('\n').map((item) => (
                <p className="contentDesiredCandidate text-left mb-0"> - {item}</p>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  );
};
export default DesiredCandidate;
