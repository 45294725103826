/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from 'react-query';

import { HTTP_OK } from 'constants/StatusCode';
import { sendGet } from 'helpers/axios';
import { managerJob } from 'constants/index';

export function useGetListJob(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerJob.USE_LIST_MANAGER_JOB, params], async () => {
    const response = await sendGet(`/api/job/get-list`, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return {
    ...response,
    data: response?.data?.data,
    error,
    isLoading,
    total_records: response?.total_job,
    total_complete: response?.total_complete,
    total_late_deadline: response?.total_late_deadline,
    total_processing: response?.total_processing,
  };
}

export function useGetJobDetail(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([managerJob.USE_DETAIL_JOB, params], async () => {
    const response = await sendGet(`/api/job/get-detail`, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return {
    ...response,
    data: response?.data,
    error,
    isLoading,
  };
}

export function useGetListOrderByJob(params, option) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [managerJob.USE_LIST_ORDER_BY_JOB, params],
    async () => {
      const response = await sendGet(`/api/job/get-list-orders`, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    option,
  );
  return {
    ...response,
    data: response?.data,
    error,
    isLoading,
  };
}

export function useGetListOrderAvailable(params, option) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [managerJob.USE_LIST_ORDER_AVAILABLE, params],
    async () => {
      const response = await sendGet(`/api/job/get-orders-available`, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    option,
  );
  return {
    ...response,
    data: response?.data,
    error,
    isLoading,
  };
}

export function useGetListAppliesByJob(params, option) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [managerJob.USE_LIST_APPLIES_AVAILABLE_BY_JOB, params],
    async () => {
      const response = await sendGet(`/api/job/get-list-applies`, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    option,
  );
  return {
    ...response,
    data: response?.data,
    error,
    isLoading,
  };
}

export function useGetListAppliesAvailable(params, option) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [managerJob.USE_LIST_APPLIES_AVAILABLE, params],
    async () => {
      const response = await sendGet(`/api/job/get-applies-available`, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    option,
  );
  return {
    ...response,
    data: response?.data,
    error,
    isLoading,
  };
}
