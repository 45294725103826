import React, { useContext } from 'react';

import styles from './styles.module.scss';
import { ThemContext } from 'layouts/Admin';
import { DELETE_PERMISSION } from 'constants/permission';

const DeleteLayout = (props) => {
  const { children, permissionName } = props;
  const { hasPermission } = useContext(ThemContext);

  if (
    (Array.isArray(permissionName) && permissionName.every((item) => hasPermission(item, DELETE_PERMISSION))) ||
    hasPermission(permissionName, DELETE_PERMISSION)
  )
    return children;
  return (
    <div className={styles.container}>
      {children}
      <div className={styles.disableLayer}></div>
    </div>
  );
};

export default DeleteLayout;
