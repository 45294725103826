import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { MdDelete } from 'react-icons/md';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { getClassStatus } from 'helpers/managerOrder';
import { getName } from 'helpers/index';
import { formatNumberOne } from 'helpers/format';

import { INFO_ORDER } from 'constants/manageJob';
import { LIST_STATUS_ORDER } from 'constants/ManagerOrder';
import Loading from 'components/Loading';
import { JOB_PERMISSION, ORDER_HR_PERMISSION } from 'constants/permission';
import EditLayout from 'components/PermissionPage/EditLayout';

const InfoOrder = ({ t, listOrder, orderLoading, setOpenAddJob, handleCloseDetailJob, handleClickDeleteOrder }) => {
  const deadline = (data) => {
    return data.deadline ? moment(data.deadline).format('DD/MM/YYYY') : '-';
  };

  return (
    <div className="info-order">
      <div className="d-flex align-items-center justify-content-between mt-10">
        <span className="fw-bold total-order">
          {t('managerJob.totalOrder')}: {listOrder?.length}
        </span>
        <div className="add-order">
          <EditLayout permissionName={[JOB_PERMISSION, ORDER_HR_PERMISSION]}>
            <button
              className="btn-add-order d-flex align-items-center justify-content-center"
              onClick={() => {
                setOpenAddJob((prev) => !prev);
                handleCloseDetailJob(INFO_ORDER);
              }}
            >
              <AddIcon />
            </button>
          </EditLayout>
        </div>
      </div>
      <Paper className="mt-22">
        <TableContainer className="table-list table-container">
          <Table aria-label="sticky table" stickyHeader>
            <TableHead className="asset-header">
              <TableRow className="asset-header-row">
                <TableCell>{t('managerJob.STT')}</TableCell>
                <TableCell>{t('managerJob.orderName')}</TableCell>
                <TableCell>{t('managerJob.peopleOrder')}</TableCell>
                <TableCell>{t('managerJob.deadline')}</TableCell>
                <TableCell>{t('managerJob.status')}</TableCell>
                <TableCell>{t('managerJob.hasApplies')}</TableCell>
                <TableCell>{t('managerJob.linkOrder')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderLoading && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Loading addClass="mt-3 mb-3" />
                  </TableCell>
                </TableRow>
              )}
              {listOrder &&
                listOrder.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="stt-table cursor">{formatNumberOne(index + 1)}</TableCell>
                      <TableCell className="cell-table-title cursor info-order-text-name-order">{item.name}</TableCell>
                      <TableCell className="cell-table cursor">{item.owner_info?.name}</TableCell>
                      <TableCell className="cell-table cursor">{deadline(item)}</TableCell>
                      <TableCell className={`cell-table-status cursor ${getClassStatus(item.status)}`}>
                        {t(getName(LIST_STATUS_ORDER, item.status))}
                      </TableCell>
                      <TableCell className="cell-table cursor text-center">
                        {item.total_recruited + '/' + item.target}
                      </TableCell>
                      <TableCell className="cell-table text-center">
                        <NavLink
                          to={`/admin/manager-order/${item.id}`}
                          target="_blank"
                          className="link-order"
                        >
                          {t('managerJob.linkOrder')}
                        </NavLink>
                      </TableCell>
                      {item.can_be_remove_from_job ? (
                        <EditLayout permissionName={[JOB_PERMISSION, ORDER_HR_PERMISSION]}>
                          <TableCell className="table-content text-center">
                            <div
                              className="edit-delete-button color-button m-0"
                              onClick={() => handleClickDeleteOrder(item.id)}
                            >
                              <MdDelete />
                            </div>
                          </TableCell>
                        </EditLayout>
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  );
                })}

              {listOrder?.length === 0 && (
                <tr>
                  <td colSpan={9} className="error-no-data">
                    {t('common.notData')}
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default InfoOrder;
