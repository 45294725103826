import { sendGet, sendPost, sendPut } from 'helpers/axios';

export const apiGetListOrdersLeader = (params) => sendGet(`/api/orders-leader/get-list`, params);
export const apiGetListOrdersHr = (params) => sendGet(`/api/orders-hr/get-list`, params);
export const apiGetDetailLeaderOrders = (params) => sendGet(`/api/orders-leader/get-detail`, params);
export const apiGetDetailHrOrders = (params) => sendGet(`/api/orders-hr/get-detail`, params);
export const apiPostCancelOrderLeader = (params) => sendPost(`/api/orders/cancel`, params);
export const apiChangeStatusOrder = (params) => sendPost(`api/orders/change-status`, params);
export const apiGetListJobAvailableForOrder = (params) =>
  sendGet(`/api/orders-hr/get-list-jobs-available/${params.ownerId}`, params);
export const apiAssignUser = (params) => sendPut('api/orders/update', params);
