import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { IoMdClose } from 'react-icons/io';

import stylesModalCommon from 'components/ModalCommon/styles.module.scss';
import styles from './styles.module.scss';
import './styles.scss';
import { useGetDetailHrOrder } from 'hook/useManagerOrder';
import { useGetListDivision } from 'hook/useDivision';
import { ThemContext } from 'layouts/Admin';
import { formatSalary } from 'helpers/format';
import { customDate } from 'helpers/formatDate';
import { getLevelName, getPositionName, getSkillName, getDivisionName, getTextFreelancer } from 'helpers/masterData';
import { MALE, LENGTH_NAME_ASSIGNER, LIST_STATUS } from 'constants/ManagerOrder';
import { ORDER_HR_PERMISSION, JOB_PERMISSION } from 'constants/permission';
import {
  WAIT_BOD_ACCEPT,
  PROCESSING,
  DEADLINE_LATE,
  CANCEL_ORDER,
  COMPLETED,
  BOD_DONT_ACCEPT,
} from 'constants/ManagerOrder.js';
import iconCheckCircle from 'assets/img/icons/check_green_circle.svg';
import Loading from 'components/Loading';
import InfoCard from 'components/InfoCard';
import DetailStatusCard from 'components/DetailStatusCard';
import ButtonComponent from 'components/Buttons';
import EditLayout from 'components/PermissionPage/EditLayout';

const ModalOrder = ({
  modal,
  toggle,
  className,
  title,
  id,
  dataPosition,
  dataLevel,
  dataSkill,
  openModalCancel,
  openModalComplete,
  openModalAddJob,
  openAlertDelete,
  setPayloadAddJob,
}) => {
  const { t } = useTranslation();
  const { data: dataDetailOrder, isLoading } = useGetDetailHrOrder({ id });
  const { data: uListDivision } = useGetListDivision();
  const { dataInfoUser, isHRM } = useContext(ThemContext);
  const getClassStatus = (value) => {
    switch (value) {
      case WAIT_BOD_ACCEPT:
        return 'wait';
      case BOD_DONT_ACCEPT:
        return 'error';
      case PROCESSING:
        return 'processing';
      case DEADLINE_LATE:
        return 'lateDeadline';
      case CANCEL_ORDER:
        return 'cancel';
      default:
        return 'completed';
    }
  };

  const status = [
    { name: t('managerOrderHr.waitBodAccept'), value: WAIT_BOD_ACCEPT, status: false },
    { name: t('managerOrderHr.bodDontAccept'), value: BOD_DONT_ACCEPT, status: false },
    { name: t('managerOrderHr.processing'), value: PROCESSING, status: false },
    { name: t('managerOrderHr.deadlineLate'), value: DEADLINE_LATE, status: false },
    { name: t('managerOrderHr.cancelOrder'), value: CANCEL_ORDER, status: false },
    { name: t('managerOrderHr.completed'), value: COMPLETED, status: false },
  ];

  useEffect(() => {
    if (isOrderNotAddJob())
      setPayloadAddJob({ ownerId: dataDetailOrder?.owner_id, division_id: dataDetailOrder?.division_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailOrder]);

  const getColorStatusUV = (value) => {
    return LIST_STATUS?.find((item) => item.id === value)?.class;
  };

  const getName = (listItem, value) => {
    if (value) {
      return listItem?.find((item) => item.id === value)?.name;
    }
    return 'N/A';
  };

  const handleOpenModalCancel = () => {
    toggle();
    openModalCancel(true);
  };

  const handleOpenModalComplete = () => {
    toggle();
    openModalComplete(true);
  };

  const handleOpenModalAddJob = () => {
    toggle();
    openModalAddJob(true);
  };

  const handleOpenModalDelete = () => {
    toggle();
    openAlertDelete(true);
  };

  const formatFieldKeyList = (list) => {
    return list?.map((item) => {
      const { user_info } = item;
      return {
        status_id: item.status,
        status: getName(LIST_STATUS, item.status),
        name: user_info.name,
        avatar: user_info.avatar,
      };
    });
  };

  const GroupButtonWhenOrderNotAddJob = () => (
    <div className={styles.groupBtn}>
      <EditLayout permissionName={[ORDER_HR_PERMISSION, JOB_PERMISSION]}>
        <Button className={styles.addJob} onClick={handleOpenModalAddJob}>
          {t('modalOrder.addJob')}
        </Button>
      </EditLayout>
    </div>
  );
  const GroupButtonWhenOrderAddJob = ({ showButtonDelete }) => (
    <div className={styles.groupBtn}>
      <EditLayout permissionName={ORDER_HR_PERMISSION}>
        <Button className={styles.cancelButton} onClick={handleOpenModalCancel}>
          {t('modalOrder.cancelOrder')}
        </Button>
      </EditLayout>
      {showButtonDelete && (
        <EditLayout permissionName={[ORDER_HR_PERMISSION, JOB_PERMISSION]}>
          <ButtonComponent
            addClass={styles.deleteButton}
            handleClick={handleOpenModalDelete}
            text={t('modalOrder.deleteOrderFromJob')}
          />
        </EditLayout>
      )}
      {dataDetailOrder?.total_recruited >= 1 && (
        <EditLayout permissionName={ORDER_HR_PERMISSION}>
          <Button className={styles.completeButton} onClick={handleOpenModalComplete}>
            {t('modalOrder.completed')}
          </Button>
        </EditLayout>
      )}
    </div>
  );

  const MainInforOrder = () => (
    <>
      <div className="title-mid-content">
        <span className={styles.mainInfo}>{t('modalOrder.mainInfo')}</span>
      </div>
      <Grid container className="mid-content">
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.createDate')}
        </Grid>
        <Grid item xs={9} className={styles.contentItem}>
          {customDate(dataDetailOrder?.created_at, 'HH:mm DD/MM/YYYY')}
        </Grid>
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.endDate')}
        </Grid>
        <Grid item xs={9} className={styles.contentItem}>
          {customDate(dataDetailOrder?.deadline, 'DD/MM/YYYY')}
        </Grid>
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.status')}
        </Grid>
        <Grid item xs={9} className={`${getClassStatus(dataDetailOrder?.status)} ${styles.contentItem}`}>
          {status.find((el) => el.value === Number(dataDetailOrder?.status))?.name}
        </Grid>
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.position')}
        </Grid>
        <Grid item xs={9} className={styles.contentItem}>
          {dataDetailOrder?.position_id
            ? getPositionName(dataDetailOrder?.position_id, dataPosition)
            : t('common.notDataContract')}
        </Grid>
        <Grid item xs={3} className={styles.titleItem}>
          {t('common.isFreelancer')}
        </Grid>
        <Grid item xs={9} className={styles.contentItem}>
          {getTextFreelancer(dataDetailOrder?.is_freelancer, t('common.yesEN'), t('common.noEN'))}
        </Grid>
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.skill')}
        </Grid>
        <Grid item xs={9} className={styles.contentItem}>
          {dataDetailOrder?.skill_id ? getSkillName(dataDetailOrder?.skill_id, dataSkill) : t('common.notDataContract')}
        </Grid>
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.level')}
        </Grid>
        <Grid item xs={9} className={styles.contentItem}>
          {dataDetailOrder?.level_id
            ? dataDetailOrder.level_id.map((item) => getLevelName(item, dataLevel)).join(', ')
            : t('common.notDataContract')}
        </Grid>
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.salary')}
        </Grid>
        <Grid item xs={9} className={styles.contentItem}>
          {dataDetailOrder && formatSalary(dataDetailOrder.max_salary)}
        </Grid>
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.totalRecruit')}
        </Grid>
        <Grid item xs={9} className={styles.contentItem}>
          {(dataDetailOrder?.total_recruited ? dataDetailOrder?.total_recruited : 0) + '/' + dataDetailOrder?.target}
        </Grid>
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.userAssignByLeader')}
        </Grid>
        <Tooltip
          title={
            dataDetailOrder?.user_assigned_infos?.map((item) => item?.name).join(', ').length > LENGTH_NAME_ASSIGNER && (
              <div className="tooltip-assigner">
                {dataDetailOrder?.user_assigned_infos
                  ? dataDetailOrder?.user_assigned_infos.map((item) => item?.name).join(', ')
                  : t('common.notDataContract')}
              </div>
            )
          }
          arrow={
            dataDetailOrder?.user_assigned_infos?.map((item) => item?.name).join(', ').length > LENGTH_NAME_ASSIGNER
          }
          placement="bottom-start"
          PopperProps={{
            sx: {
              maxWidth: 700,
              '& 	.MuiTooltip-tooltipArrow': {
                top: '-30px !important',
                '&::before': {
                  backgroundColor: 'blue',
                },
              },
              '& 	.MuiTooltip-tooltip': {
                marginTop: '20px !important',
              },
            },
          }}
        >
          <Grid
            item
            xs={8.5}
            className={classNames(styles.contentItem, styles.titleAssignerName, styles.pointerAssigner)}
          >
            {dataDetailOrder?.user_assigned_infos
              ? dataDetailOrder?.user_assigned_infos.map((item) => item?.name).join(', ')
              : t('common.notDataContract')}
          </Grid>
        </Tooltip>
        <Grid item xs={3} className={styles.titleItem}>
          {t('modalOrder.note')}
        </Grid>
        <Grid item xs={9} className={styles.contentItem}>
          {dataDetailOrder?.description ? (
            <div
              className="detailOrder-text-note"
              dangerouslySetInnerHTML={{
                __html: dataDetailOrder?.description.replaceAll('<p><br></p>', ''),
              }}
            />
          ) : (
            t('common.notDataContract')
          )}
        </Grid>
      </Grid>
    </>
  );

  const linkDetail = (idCandidate) => {
    return `/admin/candidate-detail/${idCandidate}`;
  };
  const TableInforUV = () => (
    <>
      <div className={styles.candidateInfo}>
        <span>{t('modalOrder.infoCandidate')}</span>
      </div>
      <div className="table-border-show">
        <Paper>
          <TableContainer className={styles.tableContainer}>
            <Table aria-label="sticky table" stickyHeader>
              <TableHead className="asset-header">
                <TableRow className="asset-header-row headTable">
                  <TableCell>{t('modalOrder.nameCandidate')}</TableCell>
                  <TableCell>{t('modalOrder.gender')}</TableCell>
                  <TableCell>{t('modalOrder.status')}</TableCell>
                  <TableCell>{t('modalOrder.division')}</TableCell>
                  <TableCell>{t('modalOrder.HRCharge')}</TableCell>
                  <TableCell>{t('modalOrder.linkUV')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Loading addClass="mt-3 mb-3" />
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading &&
                  Array.isArray(dataDetailOrder?.list_applies) &&
                  dataDetailOrder?.list_applies.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className="table-content name-create">{item.full_name}</TableCell>
                        <TableCell className="table-content same-color">
                          {item.sex === MALE ? t('modalOrder.male') : t('modalOrder.female')}
                        </TableCell>
                        <TableCell className={`${getColorStatusUV(item?.status)}`}>
                          <Tooltip
                            title={
                              <DetailStatusCard
                                list={formatFieldKeyList(item?.list_action_leader)}
                                fieldKey={'status'}
                                fieldKeyClass={'status_id'}
                              />
                            }
                            arrow
                          >
                            <span>{getName(LIST_STATUS, item?.status)}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className="table-content">
                          {getDivisionName(item?.division_id, uListDivision)}
                        </TableCell>
                        <TableCell className="table-content same-color">{item.hr_assigned.name}</TableCell>
                        {isHRM ||
                        dataInfoUser?.user_id === item.hr_assigned_id ||
                        dataInfoUser?.user_id === dataDetailOrder.owner_id ? (
                          <TableCell className="table-content link-detail-UV">
                            <Link to={() => linkDetail(item.candidate_id)} target="_blank" className="">
                              {t('managerJob.watchDetail')}
                            </Link>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </TableRow>
                    );
                  })}

                {dataDetailOrder?.list_applies.length === 0 && (
                  <tr>
                    <td colSpan={8} className="error-no-data">
                      {t('common.notData')}
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
  const CreatorAndApprover = () => (
    <div className={styles.subContentTop}>
      <span className={styles.subTitle}>{t('modalOrder.creator')}</span>
      <InfoCard
        avatar={dataDetailOrder?.owner_info.avatar}
        name={dataDetailOrder?.owner_info.name}
        position={dataDetailOrder?.owner_info.position_name}
        division={dataDetailOrder?.owner_info.division_name}
      />
      <span className={styles.subTitle}>{t('modalOrder.approver')}</span>
      {dataDetailOrder?.assigned_info ? (
        <InfoCard
          avatar={dataDetailOrder.assigned_info?.avatar}
          name={dataDetailOrder.assigned_info?.name}
          position={dataDetailOrder.assigned_info?.position_name}
          division={dataDetailOrder.assigned_info?.division_name}
        />
      ) : (
        <div className={styles.spaceDiv}></div>
      )}
    </div>
  );
  const TimeLineActivity = () => (
    <div>
      <span className={styles.subTitle}>{t('modalOrder.timeline')}</span>
      <div className={styles.timeLine}>
        <Timeline>
          {dataDetailOrder?.list_status.map((item, index) => {
            return (
              <TimelineItem key={index}>
                <TimelineOppositeContent sx={{ display: 'none' }} />
                <TimelineSeparator>
                  <TimelineDot className={styles.timelineDot}>
                    <img src={iconCheckCircle} alt="" />
                  </TimelineDot>
                  <TimelineConnector className={styles.connector} />
                </TimelineSeparator>
                <TimelineContent className={styles.timelineContent}>
                  <span className={styles.timeCreate}>{customDate(item.created_at, 'HH:mm DD/MM/YYYY')}</span>
                  <span className={styles.action}>
                    {(item?.full_name ? item.full_name + ' - ' : '') +
                      LIST_STATUS.find((el) => el.id === Number(item.status))?.name}
                  </span>
                  {item?.note && <span className={styles.noteReason}>{item.note}</span>}
                  <InfoCard
                    avatar={item?.user_assigned_info ? item.user_assigned_info?.avatar : item.user_info?.avatar}
                    name={item?.user_assigned_info ? item.user_assigned_info?.name : item.user_info?.name}
                    position={
                      item?.user_assigned_info ? item.user_assigned_info?.position_name : item.user_info?.position_name
                    }
                    division={
                      item?.user_assigned_info ? item.user_assigned_info?.division_name : item.user_info?.division_name
                    }
                  />
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </div>
    </div>
  );

  const isOrderNotAddJob = () => {
    return !!(
      (dataDetailOrder?.status === PROCESSING || dataDetailOrder?.status === DEADLINE_LATE) &&
      !dataDetailOrder?.job_id
    );
  };

  const isOrderAddJob = () => {
    return !!(
      (dataDetailOrder?.status === PROCESSING || dataDetailOrder?.status === DEADLINE_LATE) &&
      dataDetailOrder?.job_id
    );
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={`${styles.modalCommon} modal-dialog-centered manager-order-leader ${className}`}
      >
        <div className={stylesModalCommon.headModal}>
          <div className="modal-title-head">{title}</div>
          <div className={stylesModalCommon.boxClose} onClick={toggle}>
            <div className={stylesModalCommon.iconClose}>
              <IoMdClose />
            </div>
          </div>
        </div>
        <ModalBody className={styles.modalBody}>
          <Grid container className="px-4 pt-3">
            <Grid item xs={8.5} className={styles.mainContent}>
              <div className="header-content-left d-flex align-items-center justify-content-between">
                <span
                  className={classNames(styles.orderName, {
                    [styles.wrapNameOrderNotInJob]: isOrderNotAddJob(),
                    [styles.wrapNameOrderInJob]: isOrderAddJob(),
                    [styles.wrapNameOrderInJobCantRemove]: isOrderAddJob() && !dataDetailOrder?.can_be_remove_from_job,
                  })}
                >
                  {dataDetailOrder?.name}
                </span>
                {isOrderNotAddJob() && <GroupButtonWhenOrderNotAddJob />}
                {isOrderAddJob() && (
                  <GroupButtonWhenOrderAddJob showButtonDelete={dataDetailOrder?.can_be_remove_from_job} />
                )}
              </div>
              {!!dataDetailOrder?.job_id && (
                <>
                  <div className="divider w-100" />
                  <Grid container className={styles.jobNameLine}>
                    <Grid item xs={3}>
                      {t('managerOrderHr.nameJob')}
                    </Grid>
                    <Grid item xs={9}>
                      {dataDetailOrder?.job_name}
                    </Grid>
                  </Grid>
                </>
              )}
              <div className="divider w-100" />
              <MainInforOrder />
              {dataDetailOrder?.status >= PROCESSING && dataDetailOrder?.job_id && <TableInforUV />}
            </Grid>
            <Grid item xs={3.5} className={styles.subContent}>
              <CreatorAndApprover />
              <div className="divider w-100" />
              <TimeLineActivity />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalOrder;
