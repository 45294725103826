/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import React, { useCallback, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { HiPencil } from 'react-icons/hi';

import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';

import './styles.scss';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import { useGetListGroupRole } from 'hook/useManagerDecentralization';
import PopupGroupCustom from './PopupGroupCustom';
import { sendPost } from 'helpers/axios';
import { managerDecentralization, MAX_LENGTH_30, LOADING_SM } from 'constants/index.js';
import SearchComponent from 'components/Search/index.js';
import PopupUpdateRole from './PopupUpdateRole';
import PopupAreYouSure from 'components/PopupAreYouSure';
import { apiDeleteMemberOutOfGroup } from 'api/managerDecentralization';
import { HTTP_OK } from 'constants/StatusCode';
import { showAlert } from 'helpers/modal';
import AlertAfterSubmit from 'components/AlertAfterSubmit';
import { GROUP_PERMISSION } from 'constants/permission';
import EditLayout from 'components/PermissionPage/EditLayout';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';

const TabListManager = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [openModalConfirm, setModalConfirm] = useState(false);
  const [isModalPopupCustom, setIsOpenPopupCustom] = useState(false);
  const [openModalDeleteMemberOutOfGroup, setModalDeleteMemberOutOfGroup] = useState({ user: {}, active: false });
  const [isShowModalUpdateRole, setShowModalUpdateRole] = useState({
    user: {},
    active: false,
    success: { isShowAlert: false, roleName: '' },
  });
  const queryClient = useQueryClient();

  const [filter, setFilter] = useState({
    group_role_id: id,
    system_id: 2,
  });

  const { data: uListUserDetailRole, isLoading } = useGetListGroupRole({
    ...filter,
  });

  const getSTT = (index) => {
    let stt = index + 1 < 10 ? `${0}${index + 1}` : <>{index + 1}</>;
    return stt;
  };

  const createAt = (item) => {
    return moment(item.createdAt).format('DD/MM/YYYY');
  };

  const openModal = useCallback(() => {
    setIsOpenPopupCustom(true);
  }, []);

  const setDataToSave = async (data) => {
    await sendPost(`api/group-role/user`, {
      group_role_id: id,
      system_id: 2,
      list_user: data.map((item) => item.user_id),
    });
    setModalConfirm(true);
    await queryClient.refetchQueries([managerDecentralization.getListGroupRole]);
    setIsOpenPopupCustom(!isModalPopupCustom);
  };

  const handleSearch = (value) => {
    if (value.length > MAX_LENGTH_30) {
      return;
    }
    setFilter({ ...filter, key_word: value });
  };

  const { mutate: deleteMemberOutOfGroup, isLoading: isLoadingDeleteMember } = useMutation(
    () => apiDeleteMemberOutOfGroup({ user_id: openModalDeleteMemberOutOfGroup.user.user_id, system_id: 2 }),
    {
      onSuccess: (response) => {
        if (response.status === HTTP_OK) {
          queryClient.refetchQueries([managerDecentralization.getListGroupRole]);
          setModalDeleteMemberOutOfGroup({ ...openModalDeleteMemberOutOfGroup, active: false });
        } else {
          if (response?.data?.message) {
            showAlert(response.data.message);
          } else {
            showAlert(t('messages.systemError'));
          }
        }
      },
      onError: () => {
        showAlert(t('messages.systemError'));
      },
    },
  );

  const FooterPopupConfirm = ({ onClickCancel, onClickOk }) => {
    if (isLoadingDeleteMember)
      return (
        <div className="icon--loading">
          <Loading type={LOADING_SM} />
        </div>
      );
    return (
      <>
        <ButtonComponent addClass="btnConfirm--cancel" text={t('common.cancel')} handleClick={onClickCancel} />
        <ButtonComponent addClass="btnConfirm--ok" text={t('common.ok')} handleClick={onClickOk} />
      </>
    );
  };

  return (
    <Grid container className="tab-list-manager">
      <PopupAreYouSure
        active={openModalDeleteMemberOutOfGroup.active}
        handleCancel={() => setModalDeleteMemberOutOfGroup({ ...openModalDeleteMemberOutOfGroup, active: false })}
        messages={t(`managerDecentralization.deleteMemberOutOfGroup`)}
        footer={
          <FooterPopupConfirm
            onClickCancel={() => setModalDeleteMemberOutOfGroup({ ...openModalDeleteMemberOutOfGroup, active: false })}
            onClickOk={() => deleteMemberOutOfGroup()}
          />
        }
      />
      <Grid item md={9.5} className="content-component mb-5">
        <Paper>
          <TableContainer className="table-list table-list-manager">
            <Table aria-label="sticky table">
              <TableHead className="asset-header">
                <TableRow className="asset-header-row">
                  <TableCell>{t('managerDecentralization.STT')}</TableCell>
                  <TableCell>{t('managerDecentralization.fullName')}</TableCell>
                  <TableCell>{t('managerDecentralization.position')}</TableCell>
                  <TableCell>{t('managerDecentralization.createdAt')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Loading addClass="mt-3 mb-3" />
                    </TableCell>
                  </TableRow>
                )}
                {uListUserDetailRole &&
                  uListUserDetailRole?.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className="table-content id-standard">{getSTT(index)}</TableCell>
                        <TableCell className="table-content">
                          <span className="d-block fw-bold">{item.name}</span>
                          <span>{item.email}</span>
                        </TableCell>
                        <TableCell className="table-content">{item.position}</TableCell>
                        <TableCell className="table-content">{createAt(item)}</TableCell>
                        <TableCell className="table-content">
                          <div className="d-flex align-items-center justify-content-center">
                            <EditLayout permissionName={GROUP_PERMISSION}>
                              <div
                                className={`employee-decentralization-btn mr-15`}
                                onClick={() =>
                                  setShowModalUpdateRole({ ...isShowModalUpdateRole, user: item, active: true })
                                }
                              >
                                <HiPencil />
                              </div>
                            </EditLayout>
                            <DeleteLayout permissionName={GROUP_PERMISSION}>
                              <div
                                className={`employee-decentralization-btn`}
                                onClick={() => setModalDeleteMemberOutOfGroup({ user: item, active: true })}
                              >
                                <MdDelete />
                              </div>
                            </DeleteLayout>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {uListUserDetailRole?.length === 0 && (
                  <tr>
                    <td colSpan={9} className="error-no-data">
                      {t('common.notData')}
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item md={2.5} className="filter">
        <EditLayout permissionName={GROUP_PERMISSION}>
          <ButtonComponent
            addClass="button-add-menu-right menu-right"
            icon={<AddIcon />}
            text={t('managerDecentralization.createEmployee')}
            handleClick={openModal}
          />
        </EditLayout>
        <div className="menu-right info-menu-right-no-image p-0 mt-3">
          <SearchComponent
            txtPlaceholder={t('managerDecentralization.searchNameMember')}
            addClass="input-search"
            handleSearch={handleSearch}
            maxLength={MAX_LENGTH_30}
          />
        </div>
      </Grid>
      {isModalPopupCustom && (
        <PopupGroupCustom
          dataOld={uListUserDetailRole}
          setDataToSave={setDataToSave}
          isShowModal={isModalPopupCustom}
          setShowModal={setIsOpenPopupCustom}
        />
      )}
      {isShowModalUpdateRole.active && (
        <PopupUpdateRole isShowModal={isShowModalUpdateRole} setShowModal={setShowModalUpdateRole} id={id} />
      )}
      <AlertAfterSubmit
        isShowAlert={isShowModalUpdateRole.success.isShowAlert}
        toggle={() =>
          setShowModalUpdateRole({ ...isShowModalUpdateRole, success: { isShowAlert: false, roleName: '' } })
        }
      >
        <p>
          {t(`managerDecentralization.alertUpdateRole`) +
            ' ' +
            isShowModalUpdateRole.user.name +
            ' ' +
            t(`managerDecentralization.become`) +
            ' ' +
            isShowModalUpdateRole.success.roleName}
        </p>
      </AlertAfterSubmit>
      <AlertAfterSubmit isShowAlert={openModalConfirm} toggle={() => setModalConfirm(false)}>
        <p> {t(`managerDecentralization.createEmployeeSuccess`)}</p>
      </AlertAfterSubmit>
    </Grid>
  );
};

export default TabListManager;
