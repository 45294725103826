import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import styles from './styles.module.scss';
import { getScoreAvg } from 'helpers/manageCandidate';

const AvgPoint = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} className={styles.avgScore}>
        <span className={styles.titleAvgScore}>{t('managerCandidate.avgPoint')}</span>
        <div>
          <span className={styles.avgPoint}>
            {getScoreAvg(JSON.parse(data?.list_stats), 'avg_point').toFixed(1) + '/'}
          </span>
          <span className={styles.avgPointStand}>
            {getScoreAvg(JSON.parse(data?.list_stats), 'standar_point').toFixed(1)}
          </span>
        </div>
      </Grid>
    </>
  );
};

export default AvgPoint;
