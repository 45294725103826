import { Col, Row } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import $ from 'jquery';
import configs from 'config/index.js';

import AlertComponent from 'components/AlertAfterSubmit';
import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import ProgressBar from 'components/ProgressBar/index.js';

import Import from 'assets/img/icons/import.png';
import IconImport from 'assets/img/icons/icon-import.png';
import Invalid from 'assets/img/icons/file-import-invalid.png';
import Illegal from 'assets/img/icons/file-import-illegal.png';

import { apiImportEmployees } from 'api/managerCandidate';

import { USE_GET_LIST_CANDIDATE, PROGRESS_BAR_INDEX } from 'constants/ManagerCandidate';
import {
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_OK,
  HTTP_EXPECTATION_FAILED,
} from 'constants/StatusCode';

const ModalImportEmployee = (props) => {
  const queryClient = useQueryClient();
  const { setShowModal, isShowModal } = props;
  const { t } = useTranslation();
  const [textModal] = useState({
    titleEdit: t('managerCandidate.importEmployees'),
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState([]);

  const importText = t('common.import');
  const [progress, setProgress] = useState(0);
  const [isShowAlert, setShowAlert] = useState(false);
  const [notificationUpload, setNotificationUpload] = useState({ status: importText, name: '', file: '' });
  const [stateButton, setStateButton] = useState(false);

  useEffect(() => {
    setProgress(0);
    setError([]);
    setNotificationUpload({ status: importText, name: '', file: '' });
    $('#imageImport').val('');
  }, [isShowModal, importText]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStateButton(true);
    if (!notificationUpload.file) {
      setStateButton(false);
      setError(t('common.pleaseFillOutThisFieldImport'));
      return;
    }
    var regex = new RegExp('(.*?).(xlsx)$');
    if (!regex.test(notificationUpload.name.toLowerCase())) {
      setError(t('messages.pleaseUploadFileXLSX'));
      setStateButton(false);
      return;
    }
    setNotificationUpload({ ...notificationUpload, status: t('common.importing') });
    const fileData = new FormData();
    fileData.append('file', notificationUpload.file);
    let response = {};
    response = await apiImportEmployees(fileData);
    if (
      Number(response?.status) === HTTP_UNPROCESSABLE_ENTITY ||
      Number(response?.status) === HTTP_INTERNAL_SERVER_ERROR ||
      Number(response?.status) === HTTP_EXPECTATION_FAILED
    ) {
      Number(response?.status) === HTTP_EXPECTATION_FAILED
        ? setError(response?.data?.message)
        : setError(response?.data?.error);
      setNotificationUpload({ ...notificationUpload, status: importText });
      $('#imageImport').val('');
      setStateButton(false);
      setProgress(0);
      return;
    }
    if (response?.status === HTTP_OK) {
      setProgress(PROGRESS_BAR_INDEX);
      $('#imageImport').val('');
      setShowAlert(true);
      setNotificationUpload({ status: importText, name: '', file: '' });
      setShowModal(false);
      setMessage(t('managerCandidate.importFileSuccess'));
      setStateButton(false);
      queryClient.invalidateQueries(USE_GET_LIST_CANDIDATE);
    }
    setStateButton(false);
  };

  const handleUpload = (event) => {
    var regex = new RegExp('(.*?).(xlsx)$');
    if (!regex.test(event.target.files[0].name.toLowerCase())) {
      setError(t('messages.pleaseUploadFileXLSX'));
      setNotificationUpload({
        ...notificationUpload,
        name: event.target.files[0].name,
        file: event.target.files[0],
        error_file: '',
      });
      return;
    } else if (event.target.files[0]) {
      setError([]);
      setNotificationUpload({
        ...notificationUpload,
        name: event.target.files[0].name,
        file: event.target.files[0],
        error_file: '',
      });
      setProgress(PROGRESS_BAR_INDEX);
    }
  };

  return (
    <>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>

      {isShowModal && (
        <ModalComponent
          toggle={() => setShowModal(!isShowModal)}
          isShowModal={isShowModal}
          classNameAdd="import-contract"
          title={textModal.titleEdit}
        >
          <form className="mt-3" onSubmit={handleSubmit}>
            <div className={`fieldset-group mt-4 import`}>
              <input
                type="file"
                id="imageImport"
                name="file_import[]"
                onChange={(event) => handleUpload(event)}
                style={{ display: 'none' }}
                accept=".xlsx"
              />
              <label htmlFor="imageImport" className="labelImport">
                <img src={IconImport} alt="Import" className="iconImport info" />
                <p className="notificationUpload info">{t('managerCandidate.notificationImport')}</p>
                <p className="format info">{t('managerCandidate.formatImport')}</p>
                <img src={Import} alt="Import" className="imgImport" />
              </label>
            </div>
            <a className="download-template" href={configs.API_DOWNLOAD_TEMPLATE_IMPORT}>
              {t('managerCandidate.notificationImportUser')}
            </a>
            <div className="fieldset-group mt-3">
              <label className="infoImport">{' ' + notificationUpload.status + ': ' + notificationUpload.name} </label>
              <span className="progress-percent">{progress}%</span>
              <ProgressBar classNameAdd="progress-import" bgcolor="#2ECC71" completed={progress} />
            </div>
            {notificationUpload?.error_file && <p className="error">{notificationUpload?.error_file}</p>}
            {Array.isArray(error) &&
              error.length > 0 &&
              error.map((item, i) => {
                return (
                  <p key={i} className="error">
                    {
                      <img
                        src={Array.isArray(error) && error.length > 0 ? Illegal : Invalid}
                        alt="FileInvalid"
                        className="fileInvalid"
                      />
                    }
                    {item.line &&
                      item.message &&
                      t('common.line') +
                        ' ' +
                        item.line +
                        ', ' +
                        t('common.column') +
                        ' ' +
                        item.column +
                        ': ' +
                        item.message}
                    {!item.line && !item.message && item}
                  </p>
                );
              })}
            {!Array.isArray(error) && error?.message && (
              <p className="error">
                {<img src={Illegal} alt="FileInvalid" className="fileInvalid" />}
                {error.message}
              </p>
            )}
            {!Array.isArray(error) && !error?.message && (
              <p className="error">
                <img src={Illegal} alt="FileInvalid" className="fileInvalid" />
                {error}
              </p>
            )}
            <Row className="mt-5">
              <Col className="md-6">
                <ButtonComponent
                  text={t('managerTest.cancel')}
                  addClass="w-100 btn-bg-yellow2"
                  handleClick={() => setShowModal(!isShowModal)}
                />
              </Col>
              <Col className="md-6">
                <ButtonComponent
                  text={t('common.import')}
                  addClass="button-submit btn-bg-yellow1 w-100"
                  type="submit"
                  onClick={handleSubmit}
                  isLoading={stateButton}
                  hasDisabled={stateButton}
                />
              </Col>
            </Row>
          </form>
        </ModalComponent>
      )}
    </>
  );
};

export default ModalImportEmployee;
