/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { createContext, useEffect, useState } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminFooter from 'components/Footers/AdminFooter.js';
import Header from './../components/Headers/Header.js';
import { useInforAuth } from 'hook/useAuth';
import routes from 'routes.js';
import Grid from '@mui/material/Grid';
import Menu from 'components/Menu';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotAcceptPermission from 'views/pages/NotAcceptPermission.js';
import PrivateRoute from 'components/PrivateRoute';

import { HTTP_OK } from 'constants/StatusCode.js';
import Forbidden from 'views/Forbidden.js';
import { VIEW_PERMISSION, ROLE_VIEW } from 'constants/permission.js';

export const ThemContext = createContext();

const Admin = () => {
  const mainContent = React.useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const { data: dataInfoUser, status, isLoading: isLoadingAuth } = useInforAuth();
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  const getRoutes = (routes) => {
    if (isLoadingAuth) return;
    let dataRoutes = [];
    routes.forEach((prop) => {
      if (prop.layout === '/admin') {
        if (prop.subMenu) {
          prop.subMenu.forEach((element) => {
            dataRoutes.push(
              <PrivateRoute
                permissionName={element?.permission}
                path={element.layout + element.path}
                component={element.component}
                ThemContext={ThemContext}
              />,
            );
          });
        } else {
          dataRoutes.push(
            <PrivateRoute
              permissionName={prop?.permission}
              path={prop.layout + prop.path}
              component={prop.component}
              ThemContext={ThemContext}
            />,
          );
        }
      }
    });
    return dataRoutes;
  };

  useEffect(() => {
    if (status === HTTP_OK && dataInfoUser && routes) {
      const data = [];
      routes.forEach((item) => {
        data.push({ ...item, name: t(item.name) });
      });
      setMenu(data);
    }
  }, [dataInfoUser, status, t]);

  const hasPermission = (permissionName, permissionType) => {
    if (isLoadingAuth) return false;
    if (permissionName) {
      return dataInfoUser?.hr_permission?.[permissionName]?.includes(permissionType);
    }
    return true;
  };

  const hasPermissionInRecruitSystem = (routes) => {
    return routes.some((item) => {
      if (item?.subMenu) return hasPermissionInRecruitSystem(item?.subMenu);
      return hasPermission(item?.permission, VIEW_PERMISSION);
    });
  };

  const getFirstPathByPermission = (routes) => {
    let firstPath;
    routes?.every((item) => {
      if (item?.subMenu && getFirstPathByPermission(item?.subMenu)) {
        firstPath = getFirstPathByPermission(item?.subMenu);
        return false;
      }
      if (hasPermission(item?.permission, VIEW_PERMISSION)) {
        firstPath = item.layout + item.path;
        return false;
      }
      return true;
    });
    return firstPath;
  };

  const isHRM = dataInfoUser?.hr_permission?.view_recruit?.[0] === ROLE_VIEW.HRM;
  const isHR = dataInfoUser?.hr_permission?.view_recruit?.[0] === ROLE_VIEW.HR;
  const isLeader = dataInfoUser?.hr_permission?.view_recruit?.[0] === ROLE_VIEW.LEADER;
  const isAssigner = dataInfoUser?.hr_permission?.view_recruit?.[0] === ROLE_VIEW.ASSIGNER_BY_LEADER;

  return (
    <>
      <ThemContext.Provider value={{ dataInfoUser, hasPermission, isHRM, isHR, isLeader, isAssigner }}>
        {!hasPermissionInRecruitSystem(routes) && !isLoadingAuth && <Forbidden />}
        {hasPermissionInRecruitSystem(routes) && (
          <>
            <Grid item xs={12}>
              <Header />
            </Grid>
            <div className="layout-secruit">
              <div className="sc-menu">
                <Menu routes={menu} />
              </div>
              <div className="view-render">
                <div className="main-content" ref={mainContent}>
                  <Container className="" fluid>
                    <Switch>
                      {getRoutes(routes)}
                      <Route
                        exact
                        path="/admin/page-not-access"
                        render={(props) => <NotAcceptPermission {...props} />}
                      />
                      <Redirect from="*" to={getFirstPathByPermission(routes)} />
                    </Switch>
                  </Container>
                  <Container fluid className="mb-5">
                    <AdminFooter />
                  </Container>
                </div>
              </div>
            </div>
          </>
        )}
      </ThemContext.Provider>
    </>
  );
};

export default Admin;
