import { useTranslation } from 'react-i18next';
import ModalComponent from 'components/ModalDoubleCol';
import React, { useEffect, useState } from 'react';
import { BACKGROUND_COLOR_DIAGRAM, ZERO } from 'constants/MasterData';
import { STANDARD_PERMISSION } from 'constants/permission';

import { getLevelName, getPositionName, getSkillName, parseJson } from 'helpers/masterData';
import { Col, Row } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
import { Radar } from 'react-chartjs-2';

import 'styles/variable.scss';
import EditLayout from 'components/PermissionPage/EditLayout';

const DetailModal = (props) => {
  const {
    isShowModal,
    setShowModal,
    dataDetail,
    dataPosition,
    dataSkill,
    dataLevel,
    notAllowPermissionUpdate,
    setShowModalCreateAndEdit,
  } = props;
  const { t } = useTranslation();
  const [dataCharrtRender, setDataCharrtRender] = useState({
    labels: [],
    datas: [],
  });

  const dataChart = {
    labels: dataCharrtRender.labels,
    datasets: [
      {
        data: dataCharrtRender.datas,
        backgroundColor: 'rgb(255 185 99 / 20%)',
        borderColor: BACKGROUND_COLOR_DIAGRAM,
        pointBackgroundColor: BACKGROUND_COLOR_DIAGRAM,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    scale: {
      ticks: {
        max: 10,
        min: 0,
        stepSize: 1,
        z: 10,
      },
    },
    tooltips: {
      displayColors: false,
      backgroundColor: '#FFDBA9',
      borderColor: BACKGROUND_COLOR_DIAGRAM,
      borderWidth: 1,
      callbacks: {
        title: () => null,
        label: function (tooltipItem) {
          return tooltipItem.value + '/10';
        },
        labelTextColor: () => '#223354',
        labelBorderColor: () => BACKGROUND_COLOR_DIAGRAM,
      },
    },
  };

  const [formDataDetail, setFormDataDetail] = useState({
    position: ZERO,
    level: ZERO,
    skill: ZERO,
    avgPoint: ZERO,
    list_stats: [],
  });

  useEffect(() => {
    if (isShowModal && dataDetail?.list_stats) {
      let name = [];
      let avgPoint = [];
      JSON.parse(dataDetail?.list_stats).forEach((item) => {
        name.push(item?.name);
        avgPoint.push(item?.avg_point);
      });
      setDataCharrtRender({
        labels: name,
        datas: avgPoint,
      });
      setFormDataDetail({
        position: getPositionName(dataDetail.position_id, dataPosition),
        level: getLevelName(dataDetail.level_id, dataLevel),
        skill: getSkillName(dataDetail.skill_id, dataSkill),
        avg_point: avgIndex(parseJson(dataDetail?.list_stats)),
        list_stats: parseJson(dataDetail?.list_stats),
      });
    }
    // eslint-disable-next-line
  }, [isShowModal]);

  const avgIndex = (data) => {
    let total = 0;
    let totalCoefficient = 0;

    data?.forEach((item) => {
      if (item.avg_point !== null) {
        total += item.avg_point * item.coefficient;
        totalCoefficient += item.coefficient;
      }
    });
    let avgIndex = total / totalCoefficient;
    return avgIndex ? avgIndex.toFixed(1) : 'N/A';
  };

  const openEditStandardIndex = () => {
    if (!notAllowPermissionUpdate) {
      setShowModalCreateAndEdit(true);
      setShowModal(false);
    }
  };

  return (
    <>
      <ModalComponent
        toggle={() => setShowModal(!isShowModal)}
        isShowModal={isShowModal}
        title={t('masterData.StandardIndex.detail')}
        classNameAdd="modal-detail-standard-index"
      >
        <div className="mt-2 pb-3">
          <div className="title-text">{t('masterData.StandardIndex.infomation')}</div>
          <Row className="mt-4">
            <Col className="title-item" md={3}>
              {t('masterData.StandardIndex.position')}
            </Col>
            <Col className="content-item" md={3}>
              {formDataDetail.position ? formDataDetail.position : t('common.notDataContract')}
            </Col>
            <Col className="title-item" md={3}>
              {t('masterData.StandardIndex.skill')}
            </Col>
            <Col className="content-item" md={3}>
              {formDataDetail.skill ? formDataDetail.skill : t('common.notDataContract')}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="title-item" md={3}>
              {t('masterData.StandardIndex.level')}
            </Col>
            <Col className="content-item" md={3}>
              {formDataDetail.level ? formDataDetail.level : t('common.notDataContract')}
            </Col>
            <Col className="title-item" md={3}>
              {t('masterData.StandardIndex.avgPointToSuccess')}
            </Col>
            <Col className="content-item" md={3}>
              {formDataDetail?.avg_point ? formDataDetail?.avg_point : t('common.notDataContract')}
            </Col>
          </Row>
        </div>
        <div className="diagram-detail mt-4">
          <Row className="w-100">
            <Col md={10}>
              <div className="title-text">{t('masterData.StandardIndex.informationDiagram')}</div>
            </Col>
            <Col md={2}>
              <div className={`edit-delete-button mr--15 color-silver`}>
                <EditLayout permissionName={STANDARD_PERMISSION}>
                  <HiPencil onClick={() => openEditStandardIndex(dataDetail?.id)} />
                </EditLayout>
              </div>
            </Col>
          </Row>
        </div>
        <div className="diagram-detail mt-4">
          <Radar options={options} data={dataChart} height={458} width={458} />
        </div>
      </ModalComponent>
    </>
  );
};

export default DetailModal;
