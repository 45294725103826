import Cookies from 'js-cookie';
import config from 'config';
export const USER_TOKEN = 'token';
export const USER_REFRESH_TOKEN = 'refreshToken';
export const EXPIRES = 2;

const setItem = (key, value) => {
  Cookies.set(key, value, { expires: EXPIRES, path: '/', domain: config.SUBDOMAIN_TOKEN });
};

const getItem = (key) => {
  return Cookies.get(key) || null;
};

const removeItem = (key) => {
  return Cookies.remove(key, { path: '/', domain: config.SUBDOMAIN_TOKEN });
};

const setToken = (value, key = '') => {
  if (!key) {
    setItem(USER_TOKEN, value);
  } else {
    setItem(key, value);
  }
};

const clearToken = () => {
  Cookies.remove(USER_TOKEN, { path: '/' });
};

const getToken = (key = '') => {
  if (key) {
    return getItem(key);
  } else {
    return getItem(USER_TOKEN);
  }
};
const getRefreshToken = () => getItem(USER_REFRESH_TOKEN);
const storageFC = { setItem, getItem, setToken, clearToken, getToken, getRefreshToken, removeItem };

export default storageFC;
