export const USE_MONEY_LATE = 'master_data_money_late';
export const USE_CATEGORY_ASSET = 'master_data_category_asset';
export const USE_CATEGORY_CERTIFICATE = 'master_data_category_certificate';
export const USE_WAREHOUSE_LIST = 'master_data_warehouse_list';
export const USE_CATEGORY_CONTRACT = 'list_category_contract';
export const USE_CATEGORY_POSITION = 'list_category_position';
export const USE_LIST_CATEGORY_EMPLOYEES = 'list_category_employees';
export const USE_LIST_SKILL = 'master_data_list_skill';
export const USE_LIST_SKILL_STANDARD_INDEX = 'USE_LIST_SKILL_STANDARD_INDEX';
export const USE_LIST_SCHEDULE = 'master_data_list_schedule';
export const USE_LIST_BLOCK_TIME = 'master_data_show_block_time';
export const USE_CERTIFICATE = 'master_data_list_certificate';
export const USE_LIST_LEVEL = 'MASTER_DATA_LIST_LEVEL';
export const USE_LIST_Personnel = 'MASTER_DATA_LIST_Personnel';
export const USE_LIST_ContractCategory = 'MASTER_DATA_LIST_ContractCategory';
export const USE_LIST_HOLIDAY = 'master_data_list_holiday';
export const USE_LIST_OVER_TIME = 'master_data_list_over_time';
export const USE_LIST_SPECIAL_TIME_KEEPING = 'master_data_list_time_keeping';
export const USE_LIST_USER_POSITION = 'master_data_list_user_position';
export const USE_LIST_GROUP_ACCOUNT = 'master_data_list_group_account';
export const USE_LIST_ALL_DIVISION = 'master_data_list_all_division';
export const USE_LIST_ALL_DIVISION_POSITION = 'master_data_list_all_division_position';
export const USE_LIST_ALL_MEMBER_COMMUNITY_CUSTOM = 'master_data_list_all_community_member_custom';
export const USE_LIST_MAPPING_REQUEST = 'master_data_list_mapping_request';
export const USE_LIST_WORKFLOW = 'master_data_list_worlflow';
export const USE_LIST_CATEGORY = 'master_data_list_category';
export const USE_LIST_HR_LIST_TYPE = 'master_data_list_mapping_request_type';
export const USE_LIST_HR_LIST_CATEGORY = 'master_data_list_mapping_request_category';
export const USE_LIST_ROLE = 'master_data_list_role';
export const USE_LIST_USER_DETAIL_ROLE = 'master_data_list_user_detail_role';
export const USE_GET_DETAIL_ROLE = 'USE_GET_DETAIL_ROLE';
export const USE_CHECK_USER_ROLE = 'USE_CHECK_USER_ROLE';
export const USE_LIST_BONUS = 'USE_LIST_BONUS';
export const USE_LIST_STANDARD_INDEX = 'USE_LIST_STANDARD_INDEX';
export const USE_LIST_POSITION = 'master_data_list_position';
export const USE_GET_LIST_USERS = 'USE_GET_LIST_USERS';
export const USE_LIST_HR_CHARGE = 'USE_LIST_HR_CHARGE';
export const USE_GET_LIST_HR = 'USE_GET_LIST_HR';

export const USE_LIST_MEMBER_CREATE_GROUP_ACCOUNT_FILTER = 'master_data_list_member_create_group_account_filter';
export const USE_LIST_DIVISION_CREATE_GROUP_ACCOUNT_FILTER = 'master_data_list_division_create_group_account_filter';
export const USE_LIST_POSITION_CREATE_GROUP_ACCOUNT_FILTER = 'master_data_list_position_create_group_account_filter';
export const USE_CREATE_GROUP_ACCOUNT_FILTER = 'master_data_create_group_account_filter';
export const USE_DETAIL_GROUP_ACCOUNT_FILTER = 'master_data_detail_group_account_filter';
export const IS_DISABLE_CHECKBOX_INFINITY = 0;
export const IS_NOT_DISABLE_CHECKBOX_INFINITY = 1;
export const IS_CHECK_RADIO_INDEPENDENCE = 0;
export const IS_CHECK_RADIO_DEPENDENCE = 1;
export const CATEGORY_CERTIFICATE = 1;
export const MANAGER_CERTIFICATE = 2;
export const ZERO = 0;
export const DEFAULT_MONTH_BONUS = 1;
export const DEFAULT_TYPE_CANDIDATE = 2;

export const WORKFLOW = 1;
export const CATEGORY = 2;

export const IS_INPUT_SEARCH = true;

export const TIME_DATA_OTHER = {
  hour_start_morning: '08:00',
  hour_end_morning: '12:00',
  hour_start_afternoon: '13:30',
  hour_end_afternoon: '17:30',
};

export const USE_TYPE_GROUP = {
  filter: 1,
  custom: 2,
};

export const ROLE_VIEW_DIAGRAM = 1;
export const ROLE_VIEW_EMPLOYEE = 2;
export const ROLE_VIEW_CONTRACT = 3;
export const ROLE_VIEW_TIMESHEET = 4;
export const ROLE_VIEW_ASSETS = 5;
export const ROLE_VIEW_MASTER_DATA = 6;
export const ROLE_VIEW_INFOR_EMPLOYEE = 7;

export const SYSTEM_ACMS_HR = 1;

export const NOT_HAVE_ACCESS = 1;
export const VIEW = 2;
export const EDIT = 3;

export const ID_SYSTEM_ADMIN = 1;

export const DEFAULT_BONUS = 0;
export const DEFAULT_MONTH_FROM_ONBOARD = '1';
export const STEP_REMOVE_TIME_BONUS = 1;
export const LENGTH_ONE = 1;

export const ONE_POINT = 'Trung bình - 1 điểm';
export const TWO_POINT = 'Trung bình - 2 điểm';
export const THREE_POINT = 'Trung bình - 3 điểm';
export const FOUR_POINT = 'Trung bình - 4 điểm';
export const FIVE_POINT = 'Khá – 5 điểm';
export const SIX_POINT = 'Khá – 6 điểm';
export const SEVEN_POINT = 'Khá – 7 điểm';
export const EIGHT_POINT = 'Tốt – 8 điểm';
export const NINE_POINT = 'Tốt – 9 điểm';
export const TEN_POINT = 'Tốt – 10 điểm';

export const BACKGROUND_COLOR_DIAGRAM = '#fea628';

export const MONTH_BONUS = [
  { id: 1, value: '1' },
  { id: 2, value: '2' },
  { id: 3, value: '3' },
  { id: 4, value: '4' },
  { id: 5, value: '5' },
  { id: 6, value: '6' },
  { id: 7, value: '7' },
  { id: 8, value: '8' },
  { id: 9, value: '9' },
  { id: 10, value: '10' },
  { id: 11, value: '11' },
  { id: 12, value: '12' },
];
export const STATUS_CANDIDATE = [
  { id: 1, name: 'Onboard' },
  { id: 2, name: 'Chính thức' },
];

export const INDEX_DISABLE_BUTTON_SOURCECV = 4
