import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, InputBase } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import styles from './styles.module.scss';
import iconLoading from 'assets/img/icons/loading.png';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import { ZERO } from 'constants/MasterData';
import * as Constants from 'constants/index.js';
import { TEST_PERMISSION } from 'constants/permission';

import EditLayout from 'components/PermissionPage/EditLayout';

export const SEARCH_ITEM_KEY = {
  CREATE_BUTTON: 'createButton',
  TOTAL: 'total',
  POSITION: 'position',
  LEVEL: 'level',
  SKILL: 'skill',
  RESET_BUTTON: 'resetButton',
};

export const FIELD_KEY = {
  POSITION: 'position_id',
  LEVEL: 'level_id',
  SKILL: 'skill_id',
};

const SearchForm = (props) => {
  const { t } = useTranslation();
  const {
    configs,
    dataPosition,
    dataLevel,
    dataSkill,
    setDataPosition,
    setDataLevel,
    setDataSkill,
    filter,
    setFilter,
  } = props;
  const [isModals, setIsModals] = useState({ position: false, skill: false, level: false });

  const renderCreateButton = (_props) => {
    const { onClick } = _props;
    return (
      <EditLayout permissionName={TEST_PERMISSION}>
        <Button startIcon={<AddIcon />} className={styles.createBtn} onClick={onClick}>
          {t('managerTest.createTest')}
        </Button>
      </EditLayout>
    );
  };

  const renderResetButton = (_props) => {
    const { onClick } = _props;

    const reset = () => {
      onClick?.();
      dataPosition?.forEach((item) => {
        item.status = false;
      });
      dataSkill?.forEach((item) => {
        item.status = false;
      });
      dataLevel?.forEach((item) => {
        item.status = false;
      });
    };

    return (
      <Button startIcon={<img src={iconLoading} alt="" />} className={styles.resetBtn} onClick={reset}>
        {t('common.reset')}
      </Button>
    );
  };

  const renderTotal = (_props) => {
    const { label, value } = _props;
    return (
      <Grid container className={styles.total}>
        <Grid item>{label}</Grid>
        <Grid item>{value}</Grid>
      </Grid>
    );
  };

  const renderField = (_props) => {
    const { name, fieldKey, options, setData } = _props;

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getValueInput = () => {
      return (
        options &&
        (!options[ZERO]?.status
          ? options
              .map((item, i) => (item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : ''))
              .filter((it) => it)
          : t('common.all'))
      );
    };

    const handleSubmitSearch = () => {
      let datas = [];
      options.forEach((it) => {
        it.status && it.id && datas.push(it.id);
      });
      setFilter({
        ...filter,
        [fieldKey]: datas,
        page: Constants.DEFAULT_PAGE_MUI,
      });
      setIsModals((prev) => {
        return { ...prev, [name]: false };
      });
    };

    const handleChangeSelected = () => {
      setIsModals((prev) => {
        return { ...prev, [name]: !prev[name] };
      });
      if (filter[fieldKey]) {
        options?.forEach((item) => {
          Number(filter[fieldKey]) === Number(item?.id) ? (item.status = true) : (item.status = false);
        });
        setData([...options]);
      } else {
        options?.forEach((item) => {
          item.status = false;
        });
        setData([...options]);
      }
    };

    function handleCheck(items, index) {
      items.forEach(function (item, i) {
        if (index === i) {
          item.status = !item.status;
        } else {
          item.status = false;
        }
      });

      return [...items];
    }

    const changeSelect = (item) => {
      setData(function (prev) {
        const index = prev.indexOf(item);
        return handleCheck(prev, index);
      });
    };

    return (
      <>
        <InputBase
          disabled
          type="text"
          name={fieldKey}
          className="input-select-multi mt-3"
          onClick={() => {
            setIsModals(() => {
              return { [name]: true };
            });
          }}
          value={getValueInput()}
          placeholder={t('masterData.StandardIndex.' + name)}
        />
        <ModalCommon
          items={options}
          onSubmit={handleSubmitSearch}
          onClick={changeSelect}
          toggle={handleChangeSelected}
          modal={isModals[name]}
          title={t('masterData.StandardIndex.choose' + capitalizeFirstLetter(name))}
          footer={t('common.select')}
        />
      </>
    );
  };

  const defaultConfigs = [
    {
      name: SEARCH_ITEM_KEY.CREATE_BUTTON,
      render: renderCreateButton,
      md: 12,
      xs: 12,
    },
    {
      name: SEARCH_ITEM_KEY.TOTAL,
      render: renderTotal,
      md: 12,
      xs: 12,
    },
    {
      name: SEARCH_ITEM_KEY.POSITION,
      render: renderField,
      md: 12,
      xs: 12,
      fieldKey: FIELD_KEY.POSITION,
      options: dataPosition || [],
      setData: setDataPosition,
    },
    {
      name: SEARCH_ITEM_KEY.LEVEL,
      render: renderField,
      md: 12,
      xs: 12,
      fieldKey: FIELD_KEY.LEVEL,
      options: dataLevel || [],
      setData: setDataLevel,
    },
    {
      name: SEARCH_ITEM_KEY.SKILL,
      render: renderField,
      md: 12,
      xs: 12,
      fieldKey: FIELD_KEY.SKILL,
      options: dataSkill || [],
      setData: setDataSkill,
    },
    {
      name: SEARCH_ITEM_KEY.RESET_BUTTON,
      render: renderResetButton,
      md: 12,
      xs: 12,
    },
  ];

  const combinedConfigs = configs?.map((item) => {
    const customizedItem = defaultConfigs?.find((e) => e.name === item.name);
    if (customizedItem) {
      return { ...item, ...customizedItem };
    }
    return item;
  });

  return (
    <>
      <Grid container className={styles.container}>
        {combinedConfigs.map((config) => {
          const { md, xs, render, ...others } = config;
          return (
            <Grid item key={config.name} md={md} xs={xs}>
              {render(others)}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SearchForm;
