import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';

import iconStatus from 'assets/img/icons/candidate/statusInterView.png';

import Loading from 'components/LoadingMemo';
import DetailStatusCard from 'components/DetailStatusCard';

import styles from './styles.module.scss';
import { LOADING_SM } from 'constants/index';
import { STATUS_CANDIDATE, LIST_STATUS } from 'constants/ManagerCandidate';
import { customDate } from 'helpers/formatDate';
import { getNameStatus } from 'helpers/index';

const StatusInterView = ({
  txtContent,
  dataCandidateApply,
  type,
  isActive,
  isLoading,
  candidateId,
  applyId,
  uListDivision,
  isAssigner,
}) => {
  const { t } = useTranslation();

  const formatFieldKeyList = (list) => {
    return list?.map((item) => {
      const { user_info, status } = item;
      return {
        status_id: status,
        status: getNameStatus(LIST_STATUS, status, dataCandidateApply.division_id, uListDivision),
        name: user_info.name,
        avatar: user_info.avatar,
      };
    });
  };

  return (
    <Grid spacing={2} xs={3} className={styles.boxStatusHeader}>
      <div className={isActive ? styles.boxActive : ''}>
        <NavLink
          to={{
            pathname: `/admin/candidate-detail/${candidateId}/apply-detail/${applyId}`,
          }}
        >
          <Grid className="border-content-status">
            <Grid container spacing={1} className="styleInfoMui">
              <img className="img-icon" src={iconStatus} alt={iconStatus} width="16.67px" height="16.67px" />
              <p className="p-content-candidate">{t('managerCandidate.interviewInformation')}</p>
            </Grid>
            {<Loading type={LOADING_SM} addClass={styles.statusLoading} isLoading={isLoading} />}
            {!isLoading && (
              <>
                <Tooltip
                  title={
                    (isAssigner || type === 'hr') && (
                      <DetailStatusCard
                        list={formatFieldKeyList(dataCandidateApply.list_action_leader)}
                        fieldKey={'status'}
                        fieldKeyClass={'status_id'}
                      />
                    )
                  }
                  arrow={type === 'hr' || isAssigner}
                >
                  <p className="p-content-status">{txtContent}</p>
                </Tooltip>
                <div className="container-date">
                  {dataCandidateApply?.status === STATUS_CANDIDATE.WAITING_INTERVIEW && (
                    <p className="p-interview-date">
                      {customDate(dataCandidateApply?.interview_date, 'DD/MM/YYYY HH:mm')}
                    </p>
                  )}
                </div>
              </>
            )}
          </Grid>
        </NavLink>
      </div>
    </Grid>
  );
};

export default StatusInterView;
