import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import { Button } from '@mui/material';

import checkBoxFail from 'assets/img/icons/candidate/checkBoxFail.svg';
import checkBoxPass from 'assets/img/icons/candidate/checkBoxPass.svg';
import radioChecked from 'assets/img/icons/candidate/radioChecked.svg';
import radioUncheck from 'assets/img/icons/candidate/radioUncheck.svg';
import copyLinkIcon from 'assets/img/icons/candidate/copyLink.svg';
import syncIcon from 'assets/img/icons/candidate/sync.svg';

import styles from './styles.module.scss';
import config from 'config/index';
import {
  STATUS_TEST,
  USE_GET_DETAIL_CANDIDATE,
  USE_GET_LIST_TEST,
  STATUS_HAVE_NOT_STARTED_TEST,
} from 'constants/ManagerCandidate';
import { TYPE_QUESTION_TEST } from 'constants/managerTest';
import { apiSyncPostTest } from 'api/managerCandidate';

import DetailApplyTest from './DetailApplyTest/DetailApplyTest';
import PreviewImage from 'components/PreviewImage';
import EditLayout from 'components/PermissionPage/EditLayout';
import { CANDIDATE_PERMISSION } from 'constants/permission';

const BodyStatusTest = ({ dataListTest, isHr }) => {
  const { t } = useTranslation();
  const { applyId } = useParams();
  const QueryClient = useQueryClient();

  const listPostTest = dataListTest?.post_test;
  const listQuestion = listPostTest?.list_questions ? JSON.parse(listPostTest?.list_questions) : [];

  const getMinuteCompleteTest = (startTime, endTime) => {
    if (startTime && endTime) {
      return moment(endTime).diff(moment(startTime), 'minutes') + ' ' + t('common.minute');
    }
    return '';
  };
  function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {}
    document.body.removeChild(textArea);
  }

  const { mutate: syncPostTest, isLoading: isLoadingSync } = useMutation(() => apiSyncPostTest({ applyId }), {
    onSuccess: (response) => {
      QueryClient.invalidateQueries([USE_GET_LIST_TEST], { refetchActive: true });
      QueryClient.invalidateQueries([USE_GET_DETAIL_CANDIDATE], { refetchActive: true });
    },
    onError: () => {},
  });

  const checkHaveTest = () => {
    if (!listPostTest?.list_questions) return false;
    if (!listPostTest?.time_for_test) return false;
    return true;
  };

  const InformationGeneral = () => {
    return (
      <div className={styles.containerInfoGeneral}>
        <div className={styles.titleInformation}>{t('masterData.StandardIndex.infomation')}</div>
        <div className={styles.formbody}>
          <div className={styles.homeworkTime}>{t('managerTest.titleForTimeTest') + ':'}&nbsp;</div>
          <span className={styles.timeTest}>
            {listPostTest?.time_for_test && listPostTest.time_for_test + ' ' + t('common.minute')}
          </span>
          <div className={styles.homeworkTime}>{t('managerTest.timeCandidateFinish') + ':'}&nbsp;</div>
          <span className={styles.timeTest}>
            {getMinuteCompleteTest(listPostTest?.start_time, listPostTest?.end_time)}
          </span>
        </div>
        {listPostTest?.status === STATUS_HAVE_NOT_STARTED_TEST && checkHaveTest() && (
          <div className={styles.formbody}>
            <div className={styles.linkTestTitle}>{t('managerTest.linkTestOnline') + ':'} </div>
            <div className={styles.linkTest}>
              <NavLink
                to={{
                  pathname: `/user/testing/${listPostTest?.identity_guid}`,
                }}
              >
                testdocs.google.com
              </NavLink>
            </div>
            {isHr && (
              <div className={styles.groupButtonLinkTest}>
                <Button
                  className={styles.buttonLinkTest}
                  startIcon={<img src={copyLinkIcon} alt="" />}
                  onClick={() => {
                    const url = config.DOMAIN_AMS_RECRUIT + `/user/testing/${listPostTest?.identity_guid}`;
                    if (window.isSecureContext && navigator.clipboard) {
                      navigator.clipboard.writeText(url);
                    } else {
                      unsecuredCopyToClipboard(url);
                    }
                  }}
                >
                  {t('managerCandidate.copyLink')}
                </Button>
                <EditLayout permissionName={CANDIDATE_PERMISSION}>
                  <Button
                    className={classNames(styles.buttonLinkTest, { [styles.buttonSyncActive]: isLoadingSync })}
                    startIcon={<img src={syncIcon} alt="" className={isLoadingSync && styles.iconSyncActive} />}
                    onClick={() => syncPostTest()}
                    disabled={isLoadingSync}
                  >
                    {t('managerCandidate.sync')}
                  </Button>
                </EditLayout>
              </div>
            )}
          </div>
        )}
        {listPostTest?.status === STATUS_HAVE_NOT_STARTED_TEST && !checkHaveTest() && (
          <div className={styles.formbody}>
            <div className={styles.linkTestTitle}>{t('managerTest.linkTestOnline') + ':'} </div>
            {isHr && (
              <div className={styles.groupButtonLinkTest}>
                <EditLayout permissionName={CANDIDATE_PERMISSION}>
                  <Button
                    className={classNames(styles.buttonLinkTest, { [styles.buttonSyncActive]: isLoadingSync })}
                    startIcon={<img src={syncIcon} alt="" className={isLoadingSync && styles.iconSyncActive} />}
                    onClick={() => syncPostTest()}
                    disabled={isLoadingSync}
                  >
                    {t('managerCandidate.sync')}
                  </Button>
                </EditLayout>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  const LeaderMarksTheTest = () => {
    const [show, setShow] = useState(false);

    return (
      <div className={styles.leaderMarksContainer}>
        {show && <div className={styles.titleInformation}>{t('managerTest.leaderMarkTheTest')}</div>}
        <DetailApplyTest dataListTest={dataListTest} setShowLeaderMarks={setShow} />
      </div>
    );
  };

  const RenderAnswerGuide = ({ answerContent }) => (
    <>
      <div className={styles.answer}>{t('managerJob.answer')}</div>
      <div className={styles.answerContent}>{answerContent}</div>
    </>
  );

  const RenderAnswerCheckBox = ({ listAnswers, listCorrectAnswers }) => {
    if (!listAnswers) return <></>;
    if (!Array.isArray(listAnswers)) listAnswers = JSON.parse(listAnswers);
    if (!Array.isArray(listCorrectAnswers) && listCorrectAnswers) listCorrectAnswers = listCorrectAnswers.split(',');
    return (
      <div className={styles.listBoxAnswer}>
        {listAnswers?.map((answer, index) => {
          return (
            <div>
              {Array.isArray(listCorrectAnswers) && listCorrectAnswers?.includes((index + 1).toString()) ? (
                <img className={styles.icon} alt={checkBoxPass} src={checkBoxPass} />
              ) : (
                <img className={styles.icon} alt={checkBoxFail} src={checkBoxFail} />
              )}
              <span className={styles.answerContent}>{answer}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const RenderAnswerRadio = ({ listAnswers, correctAnswer }) => {
    if (!listAnswers) return <></>;
    if (!Array.isArray(listAnswers)) listAnswers = JSON.parse(listAnswers);
    return (
      <div className={styles.listBoxAnswer}>
        {listAnswers?.map((answer, index) => {
          return (
            <div>
              {correctAnswer === index + 1 ? (
                <img className={styles.icon} alt={radioChecked} src={radioChecked} />
              ) : (
                <img className={styles.icon} alt={radioUncheck} src={radioUncheck} />
              )}
              <span className={styles.answerContent}>{answer}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const InformationTest = () => {
    const [previewImage, setShowPreview] = useState('');
    return (
      <div>
        <div className={styles.titleInformation}>{t('managerTest.informationTest')}</div>
        <div className={styles.bodyInfomationTest}>
          {listQuestion?.map((item, index) => {
            return (
              <div>
                <div>
                  <div>
                    <span className={styles.question}>{`${t('manageKPI.sentence')} ${index + 1}: `}</span>
                    <span className={styles.contentQuestion}>{item.content}</span>
                  </div>
                </div>
                {!!JSON.parse(item?.img_bonus)?.length && (
                  <div className={styles.imgPreviewGroup}>
                    {JSON.parse(item.img_bonus).map((img, indexImg) => (
                      <div key={indexImg} className={styles.imgPreview}>
                        <img className={styles.imageQuestion} alt={img} src={img} onClick={() => setShowPreview(img)} />
                      </div>
                    ))}
                  </div>
                )}
                {item.type === TYPE_QUESTION_TEST.GUIDE && <RenderAnswerGuide answerContent={item?.answer_text} />}
                {item.type === TYPE_QUESTION_TEST.CHECKBOX && (
                  <RenderAnswerCheckBox listAnswers={item?.list_answer} listCorrectAnswers={item?.list_answer_id} />
                )}
                {item.type === TYPE_QUESTION_TEST.RADIO && (
                  <RenderAnswerRadio listAnswers={item?.list_answer} correctAnswer={item?.answer_id} />
                )}
                <div className="divider"></div>
              </div>
            );
          })}
        </div>
        <PreviewImage toggle={() => setShowPreview('')} isShowAlert={!!previewImage}>
          <img src={previewImage} alt={previewImage} />
        </PreviewImage>
      </div>
    );
  };

  return (
    <div className={styles.bodyTest}>
      <InformationGeneral />
      {STATUS_TEST.COMPLETE.includes(listPostTest?.status) && (
        <>
          {!!dataListTest?.status?.length && <LeaderMarksTheTest />}
          <InformationTest />
        </>
      )}
    </div>
  );
};

export default BodyStatusTest;
