import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Table, TableCell, TableContainer, TableRow, TableBody, TableHead } from '@mui/material';
import styles from './styles.module.scss';

const RecruitMetrics = ({ data, filter }) => {
  const { t } = useTranslation();
  const [total, setTotal] = useState({
    received: 0,
    cancel: 0,
    interviewed: 0,
    considerOffer: 0,
    acceptOffer: 0,
    rejectOffer: 0,
  });

  const listHeader = [
    '',
    t('dashboard.position'),
    t('dashboard.level'),
    t('dashboard.CvReceived'),
    t('dashboard.CvCancel'),
    t('dashboard.CvInterview'),
    t('dashboard.CvOffered'),
    t('dashboard.CvOfferedSuccess'),
    t('dashboard.CvRejectedOffer'),
  ];

  useEffect(() => {
    data.forEach((item) => {
      total.received += Number(item.cv_received) || 0;
      total.cancel += Number(item.cv_cancel_interview) || 0;
      total.interviewed += Number(item.cv_interviewed) || 0;
      total.considerOffer += Number(item.consider_offer) || 0;
      total.acceptOffer += Number(item.take_offer) || 0;
      total.rejectOffer += Number(item.refuse_offer) || 0;
    });
    setTotal({ ...total });
  }, [data]);

  return (
    <div className="table-border-show">
      <TableContainer className={styles.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={styles.rowHeader}>
              {listHeader?.map((titleCell, index) => (
                <TableCell className={styles.cellHeader} key={index}>
                  {titleCell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              return (
                <TableRow
                  key={index}
                  className={classNames(styles.rowBody, {
                    [styles.indexEvenRowBody]: !(index % 2),
                  })}
                >
                  <TableCell className={styles.cellBody}>{item.recruiter}</TableCell>
                  <TableCell className={styles.cellBody}>{item.position}</TableCell>
                  <TableCell className={styles.cellBody}>{item.level}</TableCell>
                  <TableCell className={styles.cellBody}>{item?.cv_received}</TableCell>
                  <TableCell className={styles.cellBody}>{item?.cv_cancel_interview}</TableCell>
                  <TableCell className={styles.cellBody}>{item?.cv_interviewed}</TableCell>
                  <TableCell className={styles.cellBody}>{item?.consider_offer}</TableCell>
                  <TableCell className={styles.cellBody}>{item?.take_offer}</TableCell>
                  <TableCell className={styles.cellBody}>{item?.refuse_offer}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.totalRow}>
        <div className={styles.totalCell}>{t('common.total')}</div>
        <div className={styles.totalCell}></div>
        <div className={styles.totalCell}></div>
        <div className={styles.totalCell}>{total.received}</div>
        <div className={styles.totalCell}>{total.cancel}</div>
        <div className={styles.totalCell}>{total.interviewed}</div>
        <div className={styles.totalCell}>{total.considerOffer}</div>
        <div className={styles.totalCell}>{total.acceptOffer}</div>
        <div className={styles.totalCell}>{total.rejectOffer}</div>
      </div>
    </div>
  );
};

export default RecruitMetrics;
