import { WAIT_BOD_ACCEPT, PROCESSING, DEADLINE_LATE, CANCEL_ORDER, BOD_DONT_ACCEPT } from 'constants/ManagerOrder.js';

export const getClassStatus = (value) => {
  switch (value) {
    case WAIT_BOD_ACCEPT:
      return 'wait';
    case BOD_DONT_ACCEPT:
      return 'cancel';
    case PROCESSING:
      return 'processing';
    case DEADLINE_LATE:
      return 'lateDeadline';
    case CANCEL_ORDER:
      return 'cancel';
    default:
      return 'completed';
  }
};
