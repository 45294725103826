import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import moment from 'moment';
import _groupBy from 'lodash/groupBy';

import { Grid, MenuItem, Checkbox } from '@mui/material';

import ModalComponent from 'components/Modal';
import SelectMultipleComponent from 'components/Select/MultipleSelect.js';
import SelectComponent from 'components/Select/Select.js';
import InputField from 'components/Input/InputField';
import ButtonComponent from 'components/Buttons';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import DateDay from 'components/ReactForm/DateDay';
import { getLevelName } from 'helpers/masterData';
import { LIST_YEAR_EXPERIENCE } from 'constants/ManagerCandidate';
import { MAX_LENGTH_250, ROWS_TEXT_AREA_3, MAX_LENGTH_10 } from 'constants/index.js';
import { checkInvalidDateByDiagonalLine, formatDateIgnoreDiagonalLine } from 'helpers/formatDate';

const ModalCreateJob = ({
  addJob,
  setAddJob,
  onSubmit,
  useListSkill,
  useListPosition,
  useListLevel,
  editJob,
  setEditJob,
  setOpenDetailJob,
  openDetailJob,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(
    editJob.id
      ? editJob
      : {
          name: '',
          position_id: null,
          max_salary: '',
          level_id: null,
          skill_id: null,
          bonus: '',
          start_date: '',
          end_date: '',
          experience: '',
          description: '',
          is_freelancer: 0
        },
  );
  const [valueSelect, setValueSelect] = useState(editJob.id ? editJob.level_id : []);
  const [loadingCreateJob, setLoadingCreateJob] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
    setError,
    register,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string()
          .required(t('managerJob.titleJobRequired'))
          .min(1, t('managerJob.titleJobRequired'))
          .max(50, t('managerJob.titleJobRequired'))
          .trim(),
        position_id: Yup.mixed().test('min', t('managerJob.positionRequired'), (value) => {
          return value && value >= 0;
        }),
        level_id: Yup.mixed().test('min', t('managerJob.levelRequired'), () => {
          if (!editJob.level_id) return valueSelect.length > 0 ? true : false;
          return valueSelect.length > 0 ? true : false;
        }),
        skill_id: Yup.mixed().test('skill_id', t('managerJob.skillRequired'), (value) => {
          return positionId && listSkills[positionId]
            ? value && listSkills[positionId].filter((item) => item.id === value)
            : true;
        }),
        experience: Yup.string().test('min', t('managerJob.experienceRequired'), (value) => {
          return !!value;
        }),
        max_salary: Yup.string()
          .required(t('managerJob.maxSalaryRequired'))
          .test('min', t('managerJob.minSalaryMin'), (value) => {
            return +value >= 100000;
          })
          .max(MAX_LENGTH_10, t('managerJob.maxTenIndex')),
        bonus: Yup.string()
          .nullable()
          .test('bonus', t('managerJob.bonusCandidateRequired'), (value) => {
            return !!formData.start_date || !!formData.end_date ? !!value : true;
          })
          .trim(),
        start_date: Yup.string()
          .nullable()
          .test('startDate', t('managerJob.startBonusRequired'), (startDate) => {
            return getValues('bonus')?.trim() ? !!startDate : true;
          })
          .test('startDate', t('messages.pleaseEnterTheCorrectFormatDate'), function validateDate(startDate) {
            return getValues('bonus')?.trim() ? checkInvalidDateByDiagonalLine(startDate) : true;
          }),
        end_date: Yup.string()
          .nullable()
          .test('endDate', t('managerJob.endBonusRequired'), (endDate) => {
            return getValues('bonus')?.trim() ? !!endDate : true;
          })
          .test('endDate', t('messages.pleaseEnterTheCorrectFormatDate'), function validateDate(endDate) {
            return getValues('bonus')?.trim() ? checkInvalidDateByDiagonalLine(endDate) : true;
          })
          .test('min', t('messages.pleaseEnterEndDateAfterStartDate'), (endDate) => {
            return getValues('bonus')?.trim()
              ? !moment(getValues('start_date'), 'DD/MM/YYYY').isAfter(
                  moment(formatDateIgnoreDiagonalLine(endDate), 'DD/MM/YYYY'),
                )
              : true;
          }),
      }),
    ),
    defaultValues: formData,
    mode: 'onChange',
  });
  const positionId = useWatch({ control, name: 'position_id' });
  const inserOnlyNumber = (e) => {
    ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();
  };
  const listSkills = _groupBy(useListSkill, 'position_id');

  useEffect(() => {
    if (!positionId || !listSkills[positionId]) {
      setValue('skill_id', null);
    } else if (formData.skill_id && positionId === formData.position_id) {
      let skill = listSkills[positionId].find((item) => item.id === formData.skill_id);
      if (skill) setValue('skill_id', skill.id);
      else setValue('skill_id', null);
    } else {
      setValue('skill_id', listSkills[positionId][0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]);
  const handleChangeValue = (event) => {
    const {
      target: { value },
    } = event;
    setValueSelect(typeof value === 'string' ? value.split(',') : value);
    clearErrors('level_id');
  };
  return (
    <ModalComponent
      isShowModal={addJob}
      title={t(`managerJob.${editJob.id ? 'editJob' : 'createJob'}`)}
      toggle={() => {
        setEditJob({});
        setAddJob(!addJob);
        editJob.id && setOpenDetailJob({ ...openDetailJob, active: true });
      }}
      classNameAdd="no-padding-modal-body edit-role-modal manager-job"
    >
      <form
        onSubmit={handleSubmit((data) => {
          setLoadingCreateJob(true);
          const payload = {
            ...data,
            level_id: valueSelect,
            is_freelancer: data?.is_freelancer ? 1 : 0,
          };
          payload.start_date = formData.start_date ? moment(formData.start_date).format('YYYY-MM-DD') : null;
          payload.end_date = formData.end_date ? moment(formData.end_date).format('YYYY-MM-DD') : null;
          onSubmit(payload);
        })}
      >
        <Grid container className="pt-2 mt-2">
          <div className="mb-20 mt-3" />
          <Grid item xs={12} className="title-create-job mb-20">
            <span className="fw-bold">{t('managerJob.infoGeneral')}</span>
          </Grid>
          <Grid item xs={12} className="mb-15">
            <Controller
              control={control}
              name="name"
              valueDefault={formData.name}
              render={({ field }) => (
                <InputField
                  field={field}
                  addClass="w-100"
                  label={t('managerJob.titleJob')}
                  variant="outlined"
                  onHandleInput={() => {}}
                  isRequired={true}
                  error={errors?.['name']?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className="title-create-job mb-1">
            <span className="fw-bold">{t('common.isFreelancer')}</span>
          </Grid>
          <Grid item xs={12} className="mb-15">
            <Controller
              control={control}
              name="is_freelancer"
              render={({ field }) => (
                <Grid className="freelancer-create-job">
                  <Checkbox
                    {...field}
                    className="p-0"
                    defaultChecked={formData.is_freelancer ? true : false}
                  />
                  <span className="ml-1">{t('common.yesEN')}</span>
                </Grid>
              )}
            />
          </Grid>
          <Grid container spacing={2} className="mb-15">
            <Grid item xs={6} className="mb-15">
              <Controller
                control={control}
                name="position_id"
                valueDefault={formData.position_id}
                render={({ field }) => (
                  <SelectComponent
                    field={field}
                    addClass="w-100"
                    label={t('managerJob.position')}
                    onHandleChangeSelect={() => {}}
                    isRequired={true}
                    error={errors?.['position_id']?.message}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: {
                            xs: 350,
                          },
                        },
                      },
                    }}
                  >
                    {useListPosition &&
                      useListPosition?.map((item, index) => {
                        return (
                          <MenuItem key={`position_${index}`} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </SelectComponent>
                )}
              />
            </Grid>
            <Grid item xs={6} className="mb-15 select-box-skill">
              <Controller
                control={control}
                name="skill_id"
                valueDefault={formData.skill_id}
                render={({ field }) => (
                  <SelectComponent
                    field={field}
                    addClass="w-100 select-box"
                    label={!positionId || !listSkills[positionId] ? t('managerJob.skill') : ''}
                    onHandleChangeSelect={() => {}}
                    error={errors?.['skill_id']?.message}
                    disabled={!positionId || !listSkills[positionId]}
                  >
                    {listSkills && positionId && listSkills[positionId]
                      ? listSkills[positionId].map((item, index) => {
                          return (
                            <MenuItem key={`skill_${index}`} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </SelectComponent>
                )}
              />
            </Grid>
            <Grid item xs={6} className="mb-15 pt-0">
              <Controller
                control={control}
                name="level_id"
                valueDefault={formData.level_id}
                render={({ field }) => (
                  <SelectMultipleComponent
                    field={field}
                    addClass="w-100"
                    label={t('managerJob.level')}
                    onHandleChangeSelect={(event) => handleChangeValue(event)}
                    isRequired={true}
                    valueDefault={valueSelect}
                    setValueSelect={setValueSelect}
                    error={errors?.['level_id']?.message}
                    renderValue={(value) =>
                      value.length > 1 ? (
                        <span>
                          {value.length} {t('managerJob.levels')}
                        </span>
                      ) : (
                        getLevelName(value, useListLevel)
                      )
                    }
                  >
                    {useListLevel &&
                      useListLevel?.map((item, index) => {
                        return (
                          <MenuItem key={`level_${index}`} value={item.id}>
                            <Checkbox checked={valueSelect.includes(item.id)} />
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </SelectMultipleComponent>
                )}
              />
            </Grid>
            <Grid item xs={6} className="mb-15 pt-0">
              <Controller
                control={control}
                name="experience"
                valueDefault={formData.experience}
                render={({ field }) => (
                  <SelectComponent
                    field={field}
                    addClass="w-100"
                    label={t('managerJob.experience')}
                    onHandleChangeSelect={() => {}}
                    isRequired={true}
                    error={errors?.['experience']?.message}
                  >
                    {LIST_YEAR_EXPERIENCE.map((item, index) => {
                      return (
                        <MenuItem key={`experience_${index}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </SelectComponent>
                )}
              />
            </Grid>
            <Grid item xs={12} className="pt-0">
              <Controller
                control={control}
                name="max_salary"
                valueDefault={formData.max_salary}
                render={({ field }) => (
                  <InputField
                    field={field}
                    onKeyPress={inserOnlyNumber}
                    type="number"
                    label={t('managerJob.maxSalary')}
                    variant="outlined"
                    onHandleInput={() => {}}
                    isRequired={true}
                    error={errors?.['max_salary']?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="title-create-job mb-20">
            <span className="fw-bold">{t('managerJob.infoBonus')}</span>
          </Grid>
          <Grid item xs={12} className="mb-15">
            <Controller
              control={control}
              name="bonus"
              valueDefault={formData.bonus || ''}
              render={({ field }) => (
                <InputField
                  field={field}
                  maxLength={50}
                  label={t('managerJob.bonusCandidate')}
                  variant="outlined"
                  onHandleInput={() => {}}
                  error={errors?.bonus?.message}
                />
              )}
            />
          </Grid>
          <Grid container spacing={2} className="mb-15">
            <Grid item xs={6}>
              <DateDay
                label={t('managerJob.startBonus')}
                name="start_date"
                error={errors?.start_date?.message}
                control={control}
                setValue={setValue}
                register={register}
                setError={setError}
                defaultValue={formData?.start_date}
                maxDate={formData.end_date}
                sizeDate="small"
                onChangeHandle={(value) => {
                  setFormData({ ...formData, start_date: value });
                  setError('start_date', null);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DateDay
                label={t('managerJob.endBonus')}
                name="end_date"
                error={errors?.end_date?.message}
                control={control}
                setValue={setValue}
                register={register}
                setError={setError}
                defaultValue={formData.end_date}
                minDate={moment(formData.start_date || new Date())}
                sizeDate="small"
                onChangeHandle={(value) => {
                  setFormData({ ...formData, end_date: value });
                  setError('end_date', null);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="title-create-job mb-20">
            <span className="fw-bold">{t('managerJob.generalDescription')}</span>
          </Grid>
          <Grid item xs={12}>
            <CustomInputYup
              label={t('managerJob.generalDescription')}
              name="description"
              addClass="w-100 description pl-15 pt-10 overflow-auto"
              control={control}
              errors={errors}
              useWatch={useWatch}
              maxLength={MAX_LENGTH_250}
              multiline
              rows={ROWS_TEXT_AREA_3}
              defaultValue={formData.description}
              register={register}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="btn-submit mt-3">
          <Grid item xs={6}>
            <ButtonComponent
              text={t('managerJob.cancel')}
              addClass="btn-bg-yellow2 mr-3 w-100"
              handleClick={() => {
                setAddJob(false);
                editJob.id && setOpenDetailJob({ ...openDetailJob, active: true });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ButtonComponent
              text={t('managerJob.save')}
              addClass="btn-bg-yellow1 w-100"
              hasDisabled={loadingCreateJob}
              isLoading={loadingCreateJob}
              type="submit"
            />
          </Grid>
        </Grid>
      </form>
    </ModalComponent>
  );
};

export default ModalCreateJob;
