import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, Tab, Tabs, Tooltip } from '@mui/material';

import quantityCV from 'assets/img/icons/quantity-cv.svg';
import quantityInterview from 'assets/img/icons/quantity-interview.svg';
import offerSuccess from 'assets/img/icons/offer-success.svg';
import rejectOffer from 'assets/img/icons/reject-offer.svg';

import { useGetJobDetail } from 'hook/useManagerJob';
import { INFO_GENERAL, INFO_ORDER, QUANTITY_CANDIDATE } from 'constants/manageJob';
import { JOB_PERMISSION } from 'constants/permission';
import ModalComponent from 'components/Modal';
import Loading from 'components/Loading';
import QuantityCandidate from './QuantityCandidate';
import InfoGeneral from './InfoGeneral';
import InfoOrder from './InfoOrder';
import DetailQuantityCvCard from 'components/DetailStatusCard';
import EditLayout from 'components/PermissionPage/EditLayout';
import DeleteLayout from 'components/PermissionPage/DeleteLayout';

const ModalDetailJob = ({
  openDetailJob,
  setOpenDetailJob,
  handleCloseDetailJob,
  mapPosition,
  mapSkill,
  mapLevel,
  setEditJob,
  setAddJob,
  setConfirmDeleteJob,
  setOpenAddJob,
  setOpenAddApplies,
  defaultTab,
  handleClickDeleteOrder,
  handleClickDeleteCandidate,
}) => {
  const { t } = useTranslation();
  const data = openDetailJob.data;
  const [tabActive, setTabActive] = useState(defaultTab);
  const [detailJob, setDetailJob] = useState();
  const [orderJob, setOrderJob] = useState();
  const [candidateJob, setCandidateJob] = useState();
  const [listTotalCv, setListTotalCv] = useState();
  const [listTotalCvHR, setListTotalCvHR] = useState();

  const { data: detailData, isLoading } = useGetJobDetail({ id: data.id });

  useEffect(() => {
    if (defaultTab) setTabActive(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (detailData) {
      setDetailJob(detailData.detail_job);
      setOrderJob(detailData.order_job);
      setCandidateJob(detailData.candidate_job);
      setListTotalCv(detailData.list_total_cv);
      setListTotalCvHR(detailData.list_total_cv_hr);
      setOpenDetailJob({ ...openDetailJob, data: { ...openDetailJob.data, jobName: detailData.detail_job.name } });
    }
    // eslint-disable-next-line
  }, [detailData]);

  const handleTab = (e, tab) => {
    setTabActive(tab);
  };
  const ContentComponent = {
    infoGeneral: (
      <InfoGeneral data={detailJob} t={t} mapPosition={mapPosition} mapLevel={mapLevel} mapSkill={mapSkill} />
    ),
    infoOrder: (
      <InfoOrder
        t={t}
        listOrder={orderJob}
        orderLoading={isLoading}
        setOpenAddJob={setOpenAddJob}
        handleCloseDetailJob={handleCloseDetailJob}
        handleClickDeleteOrder={handleClickDeleteOrder}
      />
    ),
    quantityCandidate: (
      <QuantityCandidate
        t={t}
        mapPosition={mapPosition}
        mapLevel={mapLevel}
        mapSkill={mapSkill}
        listApplies={candidateJob}
        appliesLoading={isLoading}
        setOpenAddApplies={setOpenAddApplies}
        handleCloseDetailJob={handleCloseDetailJob}
        handleClickDeleteCandidate={handleClickDeleteCandidate}
      />
    ),
  };

  const handleDeleteJob = () => {
    handleCloseDetailJob(tabActive);
    setConfirmDeleteJob(true);
  };

  return (
    <ModalComponent
      isShowModal={openDetailJob.active}
      title={t('managerJob.detailJob')}
      toggle={() => {
        handleCloseDetailJob(INFO_GENERAL);
      }}
      classNameAdd="no-padding-modal-body detail-job manager-job"
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div className="header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="h-left">
                <h4 className="d-block">{detailJob?.name}</h4>
                <div className="people-assigned">
                  <span className="mr-3">
                    {t('managerJob.userCreated')}: {detailJob?.assigned_info?.name}
                  </span>
                </div>
              </div>
              <div className="h-right">
                {!orderJob?.length && tabActive === INFO_GENERAL && (
                  <DeleteLayout permissionName={JOB_PERMISSION}>
                    <Button className="delete-job" onClick={() => handleDeleteJob()}>
                      {t('managerJob.delete')}
                    </Button>
                  </DeleteLayout>
                )}

                {tabActive === INFO_GENERAL && (
                  <EditLayout permissionName={JOB_PERMISSION}>
                    <Button
                      className="edit-job"
                      onClick={() => {
                        setEditJob(detailJob);
                        handleCloseDetailJob(tabActive);
                        setAddJob(true);
                      }}
                    >
                      {t('managerJob.edit')}
                    </Button>
                  </EditLayout>
                )}
              </div>
            </div>
            <div className="divider w-100 mt-3" />
            <Grid container className="box-info">
              <Grid item xs={2} className="box-item quantity-cv">
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltipArrow': {
                        top: '-14px',
                      },
                    },
                  }}
                  title={<DetailQuantityCvCard fieldKey={'total_cv'} list={listTotalCvHR} />}
                  arrow
                >
                  <div className="padding-10 border-box d-flex cursor">
                    <div className="icon">
                      <img src={quantityCV} alt="quantity cv" />
                    </div>
                    <div className="content">
                      <span className="d-block">{t('managerJob.quantityCV')}</span>
                      <span className="total-cv">{listTotalCv?.total_cv}</span>
                    </div>
                  </div>
                </Tooltip>
              </Grid>

              <Grid item xs={2} className="box-item quantity-interview ">
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltipArrow': {
                        top: '-14px',
                      },
                    },
                  }}
                  title={<DetailQuantityCvCard fieldKey={'accept_interview'} list={listTotalCvHR} />}
                  arrow
                >
                  <div className="padding-10 border-box d-flex cursor">
                    <div className="icon">
                      <img src={quantityInterview} alt="quantity interview" />
                    </div>
                    <div className="content">
                      <span className="d-block">{t('managerJob.approveInterview')}</span>
                      <span className="total-cv">{`${listTotalCv?.accept_interview | 0}/${
                        listTotalCv?.total_cv | 0
                      }`}</span>
                    </div>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={2} className="box-item offer-success ">
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltipArrow': {
                        top: '-14px',
                      },
                    },
                  }}
                  title={<DetailQuantityCvCard fieldKey={'interviewed'} list={listTotalCvHR} />}
                  arrow
                >
                  <div className="padding-10 border-box d-flex cursor">
                    <div className="icon">
                      <img src={offerSuccess} alt="offer-success" />
                    </div>
                    <div className="content">
                      <span className="d-block">{t('managerJob.interviewed')}</span>
                      <span className="total-cv">{listTotalCv?.interviewed + '/' + listTotalCv?.total_cv}</span>
                    </div>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={2} className="box-item reject-offer ">
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltipArrow': {
                        top: '-14px',
                      },
                    },
                  }}
                  title={<DetailQuantityCvCard fieldKey={'pass_interview'} list={listTotalCvHR} />}
                  arrow
                >
                  <div className="padding-10 border-box d-flex cursor">
                    <div className="icon">
                      <img src={offerSuccess} alt="reject-offer" />
                    </div>
                    <div className="content">
                      <span className="d-block">{t('managerJob.passedInterview')}</span>
                      <span className="total-cv">{`${listTotalCv?.pass_interview | 0}/${
                        listTotalCv?.total_cv | 0
                      }`}</span>
                    </div>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={2} className="box-item reject-offer ">
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltipArrow': {
                        top: '-14px',
                      },
                    },
                  }}
                  title={<DetailQuantityCvCard fieldKey={'accept_offer'} list={listTotalCvHR} />}
                  arrow
                >
                  <div className="padding-10 border-box d-flex">
                    <div className="icon">
                      <img src={offerSuccess} alt="reject-offer" />
                    </div>
                    <div className="content">
                      <span className="d-block">{t('managerJob.receiveOffer')}</span>
                      <span className="total-cv">{`${listTotalCv?.accept_offer | 0}/${
                        listTotalCv?.total_cv | 0
                      }`}</span>
                    </div>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={2} className="box-item reject-offer position-relative ">
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltipArrow': {
                        top: '-14px',
                      },
                    },
                  }}
                  title={<DetailQuantityCvCard fieldKey={'refuse_offer'} list={listTotalCvHR} />}
                  arrow
                >
                  <div className="padding-10 border-box d-flex cursor">
                    <div className="icon">
                      <img src={rejectOffer} alt="reject-offer" />
                    </div>
                    <div className="content">
                      <span className="d-block">{t('managerJob.rejectOffer')}</span>
                      <span className="total-cv">
                        {`${listTotalCv?.refuse_offer | 0}/${listTotalCv?.total_cv | 0}`}
                      </span>
                    </div>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
          <div className="divider w-100 mt-3" />
          <div className="tab">
            <Tabs value={tabActive} onChange={handleTab}>
              <Tab label={t('managerJob.infoGeneralTab')} value={INFO_GENERAL} />
              <Tab label={t('managerJob.infoOrder')} value={INFO_ORDER} />
              <Tab label={t('managerJob.quantityCandidate')} value={QUANTITY_CANDIDATE} />
            </Tabs>
          </div>
          <div className="content">{ContentComponent[tabActive]}</div>
        </>
      )}
    </ModalComponent>
  );
};

export default ModalDetailJob;
