import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import './styles.scss';

const Index = ({ active, handleCancel, messages, footer, ...props }) => {
  return (
    <Dialog className="popup-container" open={active} onClose={handleCancel} {...props}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{messages}</DialogContentText>
      </DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Dialog>
  );
};

export default Index;
