import { InputAdornment } from '@mui/material';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import { CssTextField, IS_INPUT_SEARCH } from './constant';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const {
    onChange,
    phone,
    thousandSeparator,
    name,
    setValue,
    decimalSeparator,
    valueNumber,
    setValueNumber,
    ...other
  } = props;

  if (phone) {
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.formattedValue,
            },
          });
          setValue(name, values.formattedValue);
        }}
        format="###########"
      />
    );
  }

  return decimalSeparator ? (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
        setValue(name, values.floatValue);
      }}
      value={valueNumber}
      onChange={(e) => {
        if (e.target.value.indexOf('.') >= 0) {
          if (e.target.value.slice(e.target.value.indexOf('.') + 1) > 9) {
            return;
          }
        }
        setValueNumber(e.target.value);
      }}
      decimalSeparator="."
      displayType="input"
      type="text"
      allowNegative={true}
    />
  ) : (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
        setValue(name, values.formattedValue);
      }}
      thousandSeparator={thousandSeparator ? true : false}
      isNumericString
      decimalScale={0}
    />
  );
});

function CustomInputNumber({
  label,
  maxLength,
  defaultValue,
  name,
  placeholder,
  control,
  register,
  errors,
  addErrors,
  useWatch,
  required,
  setValue,
  type,
  setError,
  handleSearch,
  onHandelChange,
  thousandSeparator,
  disabled,
  isInteger,
  decimalSeparator,
  phone,
  autoComplete,
}) {
  const showError = errors[name];
  // Style for custom input label

  // Watch value when typing --> change classnames
  const titleWatch = useWatch({
    control,
    name: name,
  });

  const [valueNumber, setValueNumber] = useState(defaultValue);

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, setValue, name]);

  useEffect(() => {
    if (type === IS_INPUT_SEARCH) {
      handleSearch(titleWatch);
    }
    if (titleWatch && onHandelChange) {
      onHandelChange(titleWatch);
    }
    if (!decimalSeparator) setValueNumber(titleWatch);
    // eslint-disable-next-line
  }, [titleWatch]);

  return (
    <div>
      <Controller
        render={({ field }) => (
          <CssTextField
            autoComplete={autoComplete ? autoComplete : 'off'}
            inputProps={{
              maxLength: maxLength,
              thousandSeparator: thousandSeparator,
              name: name,
              setValue: setValue,
              decimalSeparator: decimalSeparator,
              phone: phone,
              valueNumber: valueNumber,
              setValueNumber: setValueNumber,
            }}
            InputProps={{
              startAdornment: type === IS_INPUT_SEARCH && (
                <InputAdornment position="start">
                  <i className={classNames(`fa fa-search`)} style={{ color: COLOR_CHECKBOX_CUSTOM }} />
                </InputAdornment>
              ),
              inputComponent: NumberFormatCustom,
            }}
            placeholder={type === IS_INPUT_SEARCH ? placeholder : undefined}
            {...register(name)}
            label={<React.Fragment>{placeholder && !titleWatch ? placeholder : label}</React.Fragment>}
            variant="outlined"
            required={required}
            error={showError || addErrors ? true : false}
            name={name}
            value={titleWatch || ''}
            {...field}
            disabled={disabled ? true : false}
            onKeyDown={(evt) => {
              if (isInteger) return evt.key === '-' && evt.preventDefault();
            }}
            // CSS
            labelspace={titleWatch === null || titleWatch === '' ? '-1px' : 0}
          />
        )}
        control={control}
        name={name}
      />
      <p className="error-input error">{addErrors && addErrors}</p>
      <p className="error-input error">{showError && errors[`${name}`]?.message}</p>
    </div>
  );
}

export default CustomInputNumber;
