import { useQuery } from 'react-query';

import {
  apiGetListOrdersLeader,
  apiGetListOrdersHr,
  apiGetDetailLeaderOrders,
  apiGetDetailHrOrders,
  apiGetListJobAvailableForOrder,
} from 'api/managerOrder';
import * as Constants from './../constants/ManagerOrder';
import { HTTP_OK } from 'constants/StatusCode';

export function useGetListOrderLeader(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_ORDER_LEADER, params], async () => {
    const response = await apiGetListOrdersLeader(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListOrderHr(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_ORDER_HR, params], async () => {
    const response = await apiGetListOrdersHr(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetDetailLeaderOrder(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DETAIL_LEADER_ORDER, params], async () => {
    const response = await apiGetDetailLeaderOrders(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetDetailHrOrder(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DETAIL_HR_ORDER, params], async () => {
    const response = await apiGetDetailHrOrders(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListJobAvailableForOrder(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_LIST_JOB_AVAILABLE_FOR_ORDER, params], async () => {
    const response = await apiGetListJobAvailableForOrder(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  }, {
    enabled: !!params.ownerId
  });

  return { ...response, error, isLoading };
}
