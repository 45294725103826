import { sendGet, sendPost, sendDelete } from './../helpers/axios';
import { sendGetHR } from './../helpers/axiosSSo';

export const getListBonus = (params) => sendGet('/api/reward/get-list', params);
export const addOrUpdateLBonus = (params) => sendPost('/api/reward/save', params);
export const deleteBonus = (params) => sendDelete(`/api/reward/delete`, params);
export const getListStandardIndex = (params) => sendGet('/api/masterdata/stats/get-list', params);
export const addOrUpdateLStandardIndex = (params) => sendPost('/api/masterdata/stats/save', params);

export const getListUser = (params) => sendGet('/api/candidate/add-member-custom-candidate', params);

export const getListLevel = (params) => sendGet('/api/masterdata/level/get-list', params);
export const getListPosition = (params) => sendGet('/api/masterdata/position/get-list', params);
export const getListSkill = (params) => sendGet('/api/masterdata/skill/get-list', params);
export const getListHrCharge = (params) => sendGet('/api/masterdata/hr-charge/get-list', params);

export const getListPersonnel = (params) => sendGetHR('/api/group', params);
export const getListContractCategory = (params) => sendGetHR('/api/contract-category', params);

export const apiGetListSchool = (params) => sendGet(`/api/schools/get-list`, params);
export const addOrUpdateSchool = (params) => sendPost('/api/schools/save', params);
export const apiDeleteSchool = (params) => sendDelete(`/api/schools/delete`, params);

export const apiGetListSourceCV = (params) => sendGet(`/api/source-cv/get-list`, params);
export const addOrUpdateSourceCV = (params) => sendPost('/api/source-cv/save', params);
export const apiDeleteSourceCV = (params) => sendDelete(`/api/source-cv/delete`, params);
