import React from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';

import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import styles from './styles.module.scss';
import { getColorStatus } from 'helpers/index';
import { LIST_STATUS } from 'constants/ManagerCandidate';

const DetailStatusCard = (props) => {
  const { list, fieldKey, fieldKeyClass } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.contentCard}>
      <div className={styles.titleCard}>{t('masterData.StandardIndex.detail')}</div>
      <div className={styles.list}>
        {list?.map((item, index) => (
          <div key={index} className={styles.infoRow}>
            <Avatar src={item.avatar ? item.avatar : defaultAvatar} alt="avt" className={styles.avatar} />
            <span className={styles.name}>{item.name + ':'}</span>
            <span className={`field-key ${fieldKeyClass && getColorStatus(LIST_STATUS, item[fieldKeyClass])}`}>
              {item[fieldKey]}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailStatusCard;
