import React, { useCallback, useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import classNames from 'classnames';
import $ from 'jquery';
import moment from 'moment';
import { debounce } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import styled from 'styled-components';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';

import ButtonComponent from 'components/Buttons';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';

import styles from './styles.module.scss';
import './styles_table.scss';
import Search from './Search';

import { apiConfirmReferralBonus } from 'api/referralBonus';

import { useGetListDivision } from 'hook/useDivision';
import { useListLevel, useListSkill, useListPosition } from 'hook/useMasterData';
import { useListReferralBonus } from 'hook/useReferralBonus';

import { customDate } from 'helpers/formatDate';
import { displayLabelTablePagination } from 'helpers/table';
import { formatNumberOne } from 'helpers/format';
import { formatSalary, formatNumberOfVi } from 'helpers/format';
import { getLevelName, getPositionName, getSkillName, getDivisionName } from 'helpers/masterData';
import { setDataAllModalWithStatus } from 'helpers/modal';
import { showAlert, showAlertError } from 'helpers/modal';

import * as Constants from 'constants/index.js';
import { USE_LIST_REFERRAL_BONUS } from 'constants/ReferralBonus';
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT_20 } from 'constants/index';
import { ZERO } from 'constants/MasterData';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_EXPECTATION_FAILED } from 'constants/StatusCode';

import { apiExportReferralBonus } from 'api/referralBonus';
import EditLayout from 'components/PermissionPage/EditLayout';
import { LIST_REWARD_REF_PERMISSION } from 'constants/permission';

const CHANGE_MONTH = {
  NEXT: 1,
  PREVIOUS: -1,
};

const FILTER_DEFAULT = {
  key_word: null,
  page: DEFAULT_PAGE_MUI,
  per_page: DEFAULT_LIMIT_20,
  date: moment().startOf('month'),
};

const IconExport = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 16H2V2H9V0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V9H16V16ZM11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z"
        fill="#2D99FF"
      />
    </svg>
  );
};

export default function ListReferralBonus() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [dataLevel, setDataLevel] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);

  const { data: uDataLevel } = useListLevel();
  const { data: uDataSkill } = useListSkill();
  const { data: uDataPosition } = useListPosition();
  const { data: uDataDivision } = useGetListDivision();

  const [totalMark, setTotalMark] = useState(2);
  const [dataReferralBonus, setDataListBonus] = useState({});
  const [filter, setFilter] = useState(FILTER_DEFAULT);
  const [isModals, setIsModals] = useState({
    division: false,
    position: false,
    skill: false,
    level: false,
    school: false,
    source_cv: false,
    experience: false,
    status: false,
  });

  const {
    data: uListReferralBonus,
    isLoading: isLoadingDataReferralBonus,
    is_confirm: isConfirm,
  } = useListReferralBonus({
    ...filter,
    page: filter.page + 1,
    month: filter.date.format('MM-YYYY'),
    keyword: filter.key_word,
    division_id: filter.division_order_id,
    key_word: null,
    division_order_id: null,
    date: null,
  });

  useEffect(() => {
    setDataListBonus(uListReferralBonus);
  }, [uListReferralBonus]);

  useEffect(() => {
    if (uDataLevel) setDataLevel(setDataAllModalWithStatus(uDataLevel));
  }, [uDataLevel]);
  useEffect(() => {
    if (uDataSkill) setDataSkill(setDataAllModalWithStatus(uDataSkill));
  }, [uDataSkill]);
  useEffect(() => {
    if (uDataPosition) setDataPosition(setDataAllModalWithStatus(uDataPosition));
  }, [uDataPosition]);
  useEffect(() => {
    if (uDataDivision) setDataDivision(setDataAllModalWithStatus(uDataDivision));
  }, [uDataDivision]);

  useEffect(() => {
    if (dataReferralBonus?.data) {
      let lengthReward = 1;
      dataReferralBonus.data.forEach((item) => {
        if (item?.milestone_reward?.length && item.milestone_reward.length > lengthReward) {
          lengthReward = item.milestone_reward.length;
        }
      });
      setTotalMark(lengthReward);
    }
  }, [dataReferralBonus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeCurrentDate = useCallback(
    debounce((value) => {
      const dateSearch = moment(filter.date).add(value, 'month');
      setFilter({
        ...filter,
        date: dateSearch,
        page: DEFAULT_PAGE_MUI,
      });
    }),
  );

  $(function scroll() {
    $('.wrapper-top').scroll(function () {
      $('.scroll-custom').scrollLeft($('.wrapper-top').scrollLeft());
    });
    $('.scroll-custom').scroll(function () {
      $('.wrapper-top').scrollLeft($('.scroll-custom').scrollLeft());
    });
  });
  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: ZERO, behavior: 'smooth' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({
        ...filter,
        key_word: value.trim(),
        page: DEFAULT_PAGE_MUI,
        per_page: DEFAULT_LIMIT_20,
      });
    }),
  );

  const handleReset = () => {
    dataPosition?.forEach((item) => {
      item.status = false;
    });
    dataSkill?.forEach((item) => {
      item.status = false;
    });
    dataLevel?.forEach((item) => {
      item.status = false;
    });
    dataDivision?.forEach((item) => {
      item.status = false;
    });
    setFilter({
      key_word: null,
      level_official_id: null,
      position_id: null,
      skill_id: null,
      division_order_id: null,
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT_20,
      date: filter.date,
    });

    setDataPosition([...dataPosition]);
    setDataLevel([...dataLevel]);
    setDataSkill([...dataSkill]);
    setDataDivision([...dataDivision]);
  };

  function getTotalBonus(data) {
    if (!data) return 0;
    return data.reduce((total, item) => total + (+item.bonus || 0), 0);
  }

  function getDisableBtnConfirm() {
    if (isConfirm || isConfirm === undefined) {
      return true;
    }
    return filter.date.diff(moment().subtract(1, 'months'), 'days') >= 1;
  }

  function getRowTableCel(data) {
    let lengthData = 0;
    if (data && Array.isArray(data)) {
      lengthData = data.length;
    }
    let numberArr = totalMark - lengthData;
    if (numberArr > 0)
      return Array(numberArr)
        .fill(1)
        .map((el, i) => (
          <React.Fragment key={i}>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </React.Fragment>
        ));
    return;
  }

  const { mutate: submitConfirmInfo, isLoading: isLoadingConfirm } = useMutation(
    async (status) => {
      await apiConfirmReferralBonus({
        confirmation_date: filter.date.format('YYYY-MM'),
      });
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([USE_LIST_REFERRAL_BONUS], { refetchActive: true });
      },
      onError: () => {
        showAlert(t('common.anUnknownError'));
      },
    },
  );

  const handleConfirmInfo = () => {
    confirmAlert({
      title: t('referralBonus.confirmInfoBonus'),
      overlayClassName: 'confirm-info-bonus',
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel text-left',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: () => {
            submitConfirmInfo();
          },
        },
      ],
    });
  };

  const { mutate: handleExportReferralBonus, isLoading: exportLoading } = useMutation(
    () =>
      apiExportReferralBonus({
        month: filter.date.format('MM-YYYY'),
        position_id: filter.position_id,
        level_id: filter.level_id,
        skill_id: filter.skill_id,
        division_id: filter.division_order_id,
        keyword: filter.key_word,
      }),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          const encodedUri = URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', 'bonus_' + filter.date.format('MM-YYYY') + '.xlsx');
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (res.status === HTTP_UNPROCESSABLE_ENTITY) {
            showAlertError(t('common.notExportNowMonthAndFutureMonth'));
          } else if (res.status === HTTP_EXPECTATION_FAILED) {
            showAlertError(t('common.noListNotExport'));
          } else {
            showAlert(t('common.anUnknownError'));
          }
        }
      },
      onError: (error) => {
        if (error?.message) {
          showAlert(error?.message);
        } else if (error?.data?.message) {
          showAlert(error?.data?.message);
        } else {
          showAlert(t('common.anUnknownError'));
        }
      },
    },
  );

  const ScrollTop = styled.div`
    margin-top: -25px;
    font-size: 16px;
    width: ${totalMark * 215 + 1300}px;
  `;

  return (
    <div className={styles.listReferralBonus}>
      <Grid container className="pt-0 mb-2">
        <div className={styles.headTitle}>
          <p className={styles.title}>{t('referralBonus.referralBonusList')}</p>
          <div className={styles.schedule}>
            <div className={styles.schedule__navigator}>
              <button className={styles.previous} onClick={() => handleChangeCurrentDate(CHANGE_MONTH.PREVIOUS)}>
                <i className="fas fa-angle-left" />
              </button>
              <div className={styles.currentDate}>{filter.date.format(`[${t('common.month')}] M / YYYY`)}</div>
              <button className={styles.next} onClick={() => handleChangeCurrentDate(CHANGE_MONTH.NEXT)}>
                <i className="fas fa-angle-right" />
              </button>
            </div>
          </div>
        </div>
      </Grid>

      <Grid container className="pt-2" spacing={3}>
        <Grid item md={9} sm={9}>
          <div className="box-table-referral-bonus-scroll">
            <div className="wrapper-top">
              <ScrollTop>
                <span>{t('common.export')}</span>
              </ScrollTop>
            </div>
            <div className={classNames('table-border-show', styles.tableBonus)}>
              <Paper>
                <TableContainer className="scroll-custom">
                  <Table aria-label="sticky table" className="table-scroll">
                    <TableHead className="asset-header">
                      <TableRow className="asset-header-row headTable">
                        <TableCell className={styles.colSTT}>{t('common.STT')}</TableCell>
                        <TableCell className={styles.colName}>{t('referralBonus.candidateName')}</TableCell>
                        <TableCell className={styles.colCodeEmployee}>{t('referralBonus.candidateCode')}</TableCell>
                        <TableCell className={styles.colDivision}>{t('referralBonus.division')}</TableCell>
                        <TableCell className={styles.colPosition}>{t('referralBonus.position')}</TableCell>
                        <TableCell className={styles.colSkill}>{t('referralBonus.skill')}</TableCell>
                        <TableCell className={styles.colLevel}>{t('referralBonus.level')}</TableCell>
                        <TableCell className={styles.colDate}>{t('referralBonus.onboardDate')}</TableCell>
                        <TableCell className={styles.colDate}>{t('referralBonus.officialDate')}</TableCell>
                        <TableCell className={styles.colSalaryBonus}>{t('referralBonus.officialSalary')}</TableCell>
                        <TableCell className={styles.colName}>{t('referralBonus.CBNVReferral')}</TableCell>
                        <TableCell className={styles.colCodeEmployee}>{t('referralBonus.presenterCode')}</TableCell>
                        <TableCell className={styles.colSalaryBonus}>{t('referralBonus.totalBonus')}</TableCell>
                        {Array(totalMark)
                          .fill(1)
                          .map((el, i) => (
                            <React.Fragment key={i}>
                              <TableCell className={styles.colMark}>
                                {t('referralBonus.mark', { number: i + 1 })}
                              </TableCell>
                              <TableCell className={styles.colSalaryBonus}>{t('referralBonus.bonus')}</TableCell>
                            </React.Fragment>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingDataReferralBonus && (
                        <TableRow>
                          <TableCell colSpan={10 + totalMark}>
                            <Loading addClass="mt-3 mb-3" />
                          </TableCell>
                        </TableRow>
                      )}

                      {!isLoadingDataReferralBonus &&
                        dataReferralBonus?.data?.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="text-color-STT">
                              {formatNumberOne(dataReferralBonus?.from + i)}
                            </TableCell>
                            <TableCell className="text-color-data">{item.candidate_name}</TableCell>
                            <TableCell className="text-color-data">{item.candidate_code}</TableCell>
                            <TableCell className="text-color-data">
                              {getDivisionName(item?.division_id, uDataDivision)}
                            </TableCell>
                            <TableCell className="text-color-data">
                              {getPositionName(item.position_id, uDataPosition)}
                            </TableCell>
                            <TableCell className="text-color-data">
                              {getSkillName(item?.skill_id, uDataSkill)}
                            </TableCell>
                            <TableCell className="text-color-data">
                              {getLevelName(item.level_official_id, uDataLevel)}
                            </TableCell>
                            <TableCell className="text-color-data">
                              {customDate(item.date_onboard, 'DD/MM/YYYY')}
                            </TableCell>
                            <TableCell className="text-color-data">
                              {customDate(item.date_official, 'DD/MM/YYYY')}
                            </TableCell>
                            <TableCell className="text-color-data">{formatSalary(item.salary)}</TableCell>
                            <TableCell className="text-color-data">{item.presenter_name}</TableCell>
                            <TableCell className="text-color-data">{item.presenter_code}</TableCell>
                            <TableCell className="text-color-data">
                              {formatNumberOfVi(getTotalBonus(item?.milestone_reward))}
                            </TableCell>
                            {item?.milestone_reward?.map((reward, index) => (
                              <React.Fragment key={index}>
                                <TableCell className="text-color-data">
                                  {customDate(reward.date, 'DD/MM/YYYY')}
                                </TableCell>
                                <TableCell className="text-color-data">{formatSalary(reward.bonus)}</TableCell>
                              </React.Fragment>
                            ))}
                            {getRowTableCel(item?.milestone_reward)}
                          </TableRow>
                        ))}
                      {dataReferralBonus?.total === ZERO && (
                        <tr>
                          <td colSpan={10 + totalMark} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {dataReferralBonus?.total > ZERO && (
                  <TablePagination
                    rowsPerPageOptions={Constants.PER_PAGE_OPTION_20}
                    component="div"
                    count={dataReferralBonus?.total}
                    rowsPerPage={filter?.per_page}
                    page={filter.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="table-pagination"
                    labelDisplayedRows={displayLabelTablePagination}
                  />
                )}
              </Paper>
            </div>
          </div>
        </Grid>

        <Grid item md={3} sm={3}>
          {isLoadingDataReferralBonus ? (
            <ButtonComponent
              addClass={classNames('button-add-menu-right', styles.btnConfirmInfo, {
                [styles.btnConfirmInfoDisable]: getDisableBtnConfirm(),
              })}
              hasDisabled={true}
              text=""
              isLoading={true}
            />
          ) : (
            <EditLayout permissionName={LIST_REWARD_REF_PERMISSION}>
              <ButtonComponent
                addClass={classNames('button-add-menu-right', styles.btnConfirmInfo, {
                  [styles.btnConfirmInfoDisable]: getDisableBtnConfirm(),
                })}
                hasDisabled={getDisableBtnConfirm()}
                text={
                  isConfirm === true ? t('referralBonus.confirmedInfomation') : t('referralBonus.confirmInfomation')
                }
                handleClick={handleConfirmInfo}
                isLoading={isLoadingConfirm}
              />
            </EditLayout>
          )}

          <ButtonComponent
            addClass={styles.export}
            handleClick={handleExportReferralBonus}
            hasDisabled={exportLoading}
            icon={<IconExport />}
            isLoading={exportLoading}
            text={t('common.export')}
          />

          <div className={styles.searchNameCandidate}>
            <SearchComponent
              txtPlaceholder={t('referralBonus.candidate')}
              addClass="input-search-box search"
              defaultValue={filter?.key_word}
              handleSearch={handleSearch}
              maxLength={Constants.MAX_LENGTH_50}
              size={'medium'}
            />

            <Search
              isModals={isModals}
              setIsModals={setIsModals}
              setFilter={setFilter}
              filter={filter}
              dataPosition={dataPosition}
              dataDivision={dataDivision}
              dataSkill={dataSkill}
              dataLevel={dataLevel}
              uDataPosition={uDataPosition}
              uDataLevel={uDataLevel}
              uDataSkill={uDataSkill}
              uDataDivision={uDataDivision}
              handleReset={handleReset}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
